import { NgModule } from '@angular/core';
import { CommonModule, PlatformLocation } from '@angular/common';
import { ClientAuthguard } from './guards/client.authguard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BaseHrefApiInterceptor } from './interceptors/base-href-api.interceptor';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from './layout/header/header/header.component';
import { RouterModule, UrlHandlingStrategy } from '@angular/router';
import { SecondaryMenuComponent } from './layout/header/secondary-menu/secondary-menu.component';
import { PrimaryMenuComponent } from './layout/header/primary-menu/primary-menu.component';
import { FooterComponent } from './layout/footer/footer.component';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { AnalyticsModule } from '../shared/modules/analytics/analytics.module';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { SharedModule } from '../shared/shared.module';
import { PreserveSpecificQueryParamUrlHandlingStrategy } from '@app/core/service/preserve-search-url-handling-strategy.service';
import { BearerInterceptor } from '@app/core/interceptors/bearer.interceptor';

@NgModule({
  declarations: [HeaderComponent, PrimaryMenuComponent, SecondaryMenuComponent, FooterComponent],
  imports: [CommonModule, TranslateModule, RouterModule, AnalyticsModule, SharedModule],
  providers: [
    ClientAuthguard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseHrefApiInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BearerInterceptor,
      multi: true,
    },
    {
      provide: UrlHandlingStrategy,
      useFactory: (platformLocation: PlatformLocation) =>
        new PreserveSpecificQueryParamUrlHandlingStrategy(platformLocation),
      deps: [PlatformLocation],
    },
  ],
  exports: [HeaderComponent, FooterComponent],
})
export class CoreModule {}

_('header.primaryMenu.consumptions.myAnnualConsumption');
_('header.primaryMenu.consumptions.myProduction');
_('header.secondaryMenu.helloUser.myAccount');
_('header.secondaryMenu.helloUser.myRequests');
