<div *ngIf="currentUsage" class="usage-popup-wrapper fixed top-0 left-0 w-screen h-screen">
  <div class="usage-popup-container absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
    <span class="bg-white close btn-close" #close></span>
    <div class="usage-popup-content bg-white p-12 box-shadow-small rounded-4xl overflow-y-auto">
      <div class="flex mb-6">
        <div class="navigation-arrow__box">
          <div
            (click)="getOtherMonthData(-1)"
            class="navigation-arrow navigation-arrow--left bg-white"
            [ngClass]="(previousDataAvailable$ | async) && !previousDataLoading ? 'cursor-pointer' : 'pointer-events-none'"
          >
            <img
              class="w-4"
              [src]="'./assets/img/tevc-monitoring/arrow-' + ((previousDataAvailable$ | async) && !previousDataLoading ? 'orange' : 'disabled') + '.svg'"
              alt="Retour"
            />
          </div>
        </div>

        <div class="flex border border-solid font-bold text-base rounded-2xl py-1 px-4 flex-1">
          <div class="relative flex w-full items-center">
            <div class="w-full text-center">
              {{ currentUsage?.from | date : 'MMMM y' : undefined : facade.translate.currentLang + '-BE' | titlecase }}
            </div>
          </div>
        </div>

        <div class="navigation-arrow__box">
          <div
            (click)="getOtherMonthData(1)"
            class="navigation-arrow bg-white"
            [ngClass]="nextDataAvailable ? 'cursor-pointer' : 'pointer-events-none'"
          >
            <img
              class="w-4"
              [src]="'./assets/img/tevc-monitoring/arrow-' + (nextDataAvailable ? 'orange' : 'disabled') + '.svg'"
              alt="Suivant"
            />
          </div>
        </div>
      </div>
      <div class="mb-8 inline-block">
        <h3 class="text-2xl"></h3>
      </div>
      <div #list class="relative">
        <ul *ngIf="!loadNewData; else loader">
          <li
            *ngFor="let data of currentUsage?.breakdown; let i = index"
            class="flex border-b border-grey-light pb-4 mb-4"
          >
            <img
              [src]="
                './assets/img/icons/standalone/' +
                (activeContract.type === energyType.ELECTRICITY ? 'light' : 'dark') +
                '-blue/' +
                data.category +
                '.svg'
              "
              [attr.alt]="'components.usageCategoryPopup.categories.' + data.category | translate"
            />
            <span class="flex-1 px-8">
              <span class="inline-block w-full font-medium text-sm">
                {{ 'components.usageCategoryPopup.categories.' + data.category | translate }}:
                <span *ngIf="data.value / 1000 < 1; then pureValueBlock; else roundedValueBlock"></span>kWh
                <ng-template #pureValueBlock>
                  {{ data.value / 1000 | number : '1.2-2' : facade.translate.currentLang + '-BE' }}
                </ng-template>
                <ng-template #roundedValueBlock>
                  {{ math.round(data.value / 1000) }}
                </ng-template>
              </span>
              <span
                class="inline-block h-4 rounded-2xl"
                [ngClass]="activeContract.type === energyType.ELECTRICITY ? 'bg-blue-light' : 'bg-blue-dark'"
                [ngStyle]="{ width: data.percentage + 20 + '%' }"
              ></span>
            </span>
            <span class="font-medium">
              {{ data.percentage < 1 ? (data.percentage | number : '1.2-2' : facade.translate.currentLang + '-BE') : math.round(data.percentage) }}%
            </span>
          </li>
        </ul>
        <ng-template #loader>
          <app-loader-widget [showBorder]="false"></app-loader-widget>
        </ng-template>
      </div>
      <div class="flex mt-8 items-center text-sm">
        <div>
          {{ 'components.usageCategoryPopup.info.description' | translate }}
          <span class="text-primary font-bold inline-block w-full mt-4 cursor-pointer" (click)="goToHomeProfile()">
            {{ 'components.usageCategoryPopup.info.link' | translate }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div #close class="absolute left-0 top-0 w-full h-full backdrop-blur"></div>
</div>
