<div class="rounded-2xl bg-white p-8 mb-42 border border-grey mb-8">
  <div *ngIf="!simulation" class="loader-container">
    <app-loader></app-loader>
  </div>

  <div *ngIf="simulation">
    <div class="simulation">
      <div class="">
        <h2 class="font-medium text-xl font-sans mb-8">
          {{ 'general.upsell.boiler_one_shot.simulation.title' | translate }}
        </h2>
        <ul>
          <li class="mb-4">
            <ng-template [ngTemplateOutlet]="paidPicto"></ng-template>
            {{ 'general.upsell.boiler_one_shot.simulation.boiler.' + (bundleCode | lowercase) | translate }}
          </li>
          <li class="mb-4">
            <ng-template [ngTemplateOutlet]="paidPicto"></ng-template>
            {{ 'general.upsell.boiler_one_shot.simulation.boiler.' + (taxEligibility | lowercase) | translate }}
          </li>
          <li class="mb-4">
            <ng-template [ngTemplateOutlet]="paidPicto"></ng-template>
            {{ 'general.upsell.boiler_one_shot.simulation.boiler.payment' | translate }}
          </li>
          <li *ngIf="promotionActive" class="mb-4">
            <ng-template [ngTemplateOutlet]="paidPicto"></ng-template>
            {{ 'general.upsell.boiler_one_shot.simulation.active_promo' | translate }}
            <span class="font-medium">{{ promoCode }}</span>
          </li>
        </ul>
      </div>

      <div class="">
        <div class="price-box text-center md:text-right">
          <h1 class="inline-block font-display font-normal text-5xl te-text-gradient-home">
            <span class="inline-block"> {{ price }} €</span>
          </h1>
          <h4
            class="font-normal text-base text-grey-dark inline-block w-full line-through italic"
            *ngIf="promotionActive"
          >
            {{ originalPrice }} € {{ 'general.upsell.boiler_one_shot.simulation.without_promo' | translate }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #paidPicto>
  <svg
    class="hidden lg:inline-block lg:mr-2"
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9.5" cy="9.5" r="9" fill="url(#paint0_linear_144:4776)" stroke="#F7F9FA" />
    <path d="M14 7L8.5 12L6 9.72727" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <defs>
      <linearGradient
        id="paint0_linear_144:4776"
        x1="9.5004"
        y1="1.01243"
        x2="9.5004"
        y2="17.5432"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#40A900" />
        <stop offset="1" stop-color="#00884A" />
      </linearGradient>
    </defs>
  </svg>
</ng-template>
