<div class="my-16 pb-8 px-8 bg-white">
  <div class="md:flex justify-center">
    <h2 class="mt-16 mb-12 font-display font-normal text-5xl te-text-gradient-home inline-block md:my-12">
      {{ 'components.consumption.powerPeak.questionsAndAnswersTitle' | translate }}
    </h2>
  </div>
  <div class="flex flex-col items-center mb-16">
    <div class="px-16 py-8 bg-grey-light rounded-4xl mt-2 max-w-6xl md:w-3/5 overflow-hidden cursor-pointer">
      <div
        class="font-medium text-base title"
        [ngClass]="activeBox ? 'title--close' : 'title--open'"
        (click)="toogle()"
      >
        {{ textPath + '.title' | translate }}
      </div>
      <div class="font-normal text-base mt-8" *ngIf="activeBox" [@inOutAnimation]>
        {{ textPath + '.description' | translate }}
      </div>
    </div>
  </div>
</div>
