import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PrivateContactInfo } from '../../../models/privateContactInfo.interface';
import { Alert } from '../../../../../shared/components/alert/alert.interface';

@Component({
  selector: 'app-send-code',
  templateUrl: './send-code.component.html',
  styleUrls: ['./send-code.component.scss'],
})
export class SendCodeComponent {
  @Input() sendingError: Alert | null;
  @Input() privateContactInfo: PrivateContactInfo;
  @Input() isAcceptedReference: boolean;
  @Output() sendingModeEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() goBackEmitter: EventEmitter<any> = new EventEmitter<any>();

  public emitSendingMode(sendingMode: string) {
    this.sendingModeEmitter.emit(sendingMode);
  }

  public emitGoBackRequest() {
    this.goBackEmitter.emit();
  }
}
