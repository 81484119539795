import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ContentService } from '@app/shared/services/content/content.service';
import { Content } from '@app/shared/models/content.interface';
import { Observable } from 'rxjs';
import { AnalyticsService } from '@app/shared/modules/analytics/services/analytics.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public year;
  public currentLanguage: string;
  public content$: Observable<Content>;

  constructor(
    private translate: TranslateService,
    private contentService: ContentService,
    private analyticsService: AnalyticsService
  ) {
    this.year = new Date().getFullYear();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => (this.currentLanguage = event.lang));
  }

  ngOnInit() {
    this.content$ = this.contentService.getActiveContent();
  }

  public switchLanguage(lang: string) {
    this.translate.use(lang);
    this.translate.setDefaultLang(lang);
    this.currentLanguage = lang;
  }

  public openCookies() {
    this.analyticsService.openConsent();
  }
}
