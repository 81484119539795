import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eliq-sync-in-progress',
  templateUrl: './eliq-sync-in-progress.component.html',
  styleUrls: ['./eliq-sync-in-progress.component.scss'],
})
export class EliqSyncInProgressComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
