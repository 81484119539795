import { RegisterType } from '../../../../models/consumption.interface';
import { ChartColor } from '@app/modules/chart/models/chart.enums';

export const defaultDataset = {
  [RegisterType.LOW]: {
    data: [],
    label: '',
    pointBorderColor: ChartColor.turquoise,
    backgroundColor: ChartColor.turquoise,
    borderColor: ChartColor.turquoise,
    pointBackgroundColor: ChartColor.turquoise,
    pointRadius: 5,
    pointBorderWidth: 1,
    fill: false,
  },
  [RegisterType.HIGH]: {
    data: [],
    label: '',
    pointBorderColor: ChartColor.lightBlue,
    backgroundColor: ChartColor.lightBlue,
    borderColor: ChartColor.lightBlue,
    pointBackgroundColor: ChartColor.lightBlue,
    pointRadius: 5,
    pointBorderWidth: 1,
  },
  [RegisterType.TOTAL_HOUR]: {
    data: [],
    label: '',
    pointBorderColor: ChartColor.darkBlue,
    backgroundColor: ChartColor.darkBlue,
    borderColor: ChartColor.darkBlue,
    pointBackgroundColor: ChartColor.darkBlue,
  },
  [RegisterType.NIGHT_EXCLUSIVE]: {
    data: [],
    label: '',
    pointBorderColor: ChartColor.darkBlue,
    backgroundColor: ChartColor.darkBlue,
    borderColor: ChartColor.darkBlue,
    pointBackgroundColor: ChartColor.darkBlue,
  },
  [RegisterType.MONO]: {
    data: [],
    label: '',
    pointBorderColor: ChartColor.darkBlue,
    backgroundColor: ChartColor.darkBlue,
    borderColor: ChartColor.darkBlue,
    pointBackgroundColor: ChartColor.darkBlue,
  },
  [RegisterType.PRODUCTION_LOW]: {
    data: [],
    label: '',
    pointBorderColor: ChartColor.orange,
    backgroundColor: ChartColor.orange,
    borderColor: ChartColor.orange,
    pointBackgroundColor: ChartColor.orange,
  },
  [RegisterType.PRODUCTION_HIGH]: {
    data: [],
    label: '',
    pointBorderColor: ChartColor.yellow,
    backgroundColor: ChartColor.yellow,
    borderColor: ChartColor.yellow,
    pointBackgroundColor: ChartColor.yellow,
  },
  [RegisterType.PRODUCTION_TOTAL_HOUR]: {
    data: [],
    label: '',
    pointBorderColor: ChartColor.yellow,
    backgroundColor: ChartColor.yellow,
    borderColor: ChartColor.yellow,
    pointBackgroundColor: ChartColor.yellow,
  },
};
