<div *ngIf="showEncodingBox" class="rounded-2xl te-border-gradient-home">
  <div class="encode-index rounded-2xl">
    <ng-container *ngIf="version === encodeIndexCtaVersion.box">
      <div class="p-8 lg:p-16">
        <h5 class="text-base font-medium text-grey-dark mb-8">{{ copy?.title }}</h5>
        <p>{{ copy?.content }}</p>
        <button
          (click)="openPopup()"
          [analyticsEvent]="'orangeBlock:cta-click:meter-reading'"
          class="btn btn-primary font-bold inline-block mt-8 w-full md:w-auto"
        >
          {{ copy?.button }}
        </button>
      </div>
    </ng-container>
    <ng-container *ngIf="version === encodeIndexCtaVersion.inline">
      <div class="inline-block py-6 px-8 md:flex md:justify-between">
        <div class="mb-6 md:mb-0 md:self-center md:flex-1 md:pr-8">
          <h5 class="text-base font-medium text-grey-dark mb-8 md:mb-4">{{ copy?.title }}</h5>
          <p class="mb-0">{{ copy?.content }}</p>
        </div>
        <div class="w-full md:w-auto md:self-center">
          <button
            (click)="openPopup()"
            [analyticsEvent]="'orangeBlock:cta-click:meter-reading'"
            class="btn btn-primary font-bold inline-block"
          >
            {{ copy?.button }}
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
