import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { myEnergyEvents } from '../../../../../../../../../../../core/analytics/models/event-defaults';
import { AnalyticsService } from '../../../../../../../../../../../shared/modules/analytics/services/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { Contract } from '../../../../../../../../../contracts/models/contract.interface';
import { EnergyType } from '@app/modules/customer-zone/consumption/models/consumption.interface';

@Component({
  selector: 'if-update-success',
  templateUrl: './update-success.component.html',
  styleUrls: ['./update-success.component.scss'],
})
export class UpdateSuccessComponent implements OnInit {
  @Input() contract: Contract;
  @Input() energy: EnergyType;
  @Input() previousAmount: number;
  @Input() newAmount: string;
  @Output() formReady = new EventEmitter();

  constructor(private analyticsService: AnalyticsService, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.analytics();
  }

  continue() {
    this.formReady.emit();
  }

  private analytics() {
    this.analyticsService.push({
      ...myEnergyEvents(`${this.translateService.currentLang}/myenergy-flow/deposit-modify/success`),
      component: { name: 'Deposit Modification' },
      deposit: {
        submittedAmount: this.newAmount,
      },
      myEnergy: {
        energyType: this.contract.type,
        ean: this.contract.deliveryPointReference,
      },
    });
  }
}
