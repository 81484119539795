import { ContractDtoCuzoApi } from '@app/shared/models/cuzo-be-contract';

/* eslint-disable */
export interface Tariff {
  peak: number;
  offPeak: number;
  nightTimeOnly: number;
  mono: number;
  type: string;
}

export interface Promotion {
  name: string;
  endDate: Date;
}

export interface Translation {
  fr: string;
  nl: string;
  en?: string;
}

export interface Contract {
  tariff: Tariff;
  type: string;
  name: string;
  forseenEndDate: Date;
  startDate: Date;
  subscription: number;
  promotion: Promotion;
  meterNumber: string;
  indeterminateDuration: boolean;
  i18n?: Translation;
  deliveryPointReference: string;
  tariffCards?: string;
}

export enum ContractTypes {
  ELECTRICITY = 'ELECTRICITY',
  GAS = 'GAS',
  ASSISTANCE = 'ASSISTANCE',
  BOILER_MAINTENANCE = 'BOILER_MAINTENANCE',
}

export type CustomerReference = string;

export interface ContractsPerReference {
  [reference: string]: ContractsPerSite[] | {};
}

export interface ContractsPerSite {
  [site: string]: ContractDtoCuzoApi[];
}
