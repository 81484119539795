export const eventDefaults = {
  contact: {
    event: 'contact-interaction',
    eventCategory: `Contact`,
    component: {
      name: 'Contact',
    },
  },
  navigation: {
    event: 'navigation-interaction',
    eventCategory: 'Navigation',
    component: {
      name: 'Navigation',
    },
  },
  footer: {
    event: 'footer-interaction',
    eventCategory: 'Navigation',
    component: {
      name: 'Footer',
    },
  },
  personalBanner: {
    event: 'personalBanner-interaction',
    eventCategory: 'Cuzo',
    component: {
      name: 'Personal banner',
    },
  },
  directLink: {
    event: 'directLink-interaction',
    eventCategory: 'Cuzo',
    component: {
      name: 'Direct link',
    },
  },
  directLinkMyEV: {
    event: 'directLinkMy-EV-interaction',
    eventCategory: 'Cuzo',
    component: {
      name: 'Direct link my EV',
    },
  },
  services: {
    event: 'services-interaction',
    eventCategory: 'Cuzo',
    component: {
      name: 'Services popup',
    },
  },
  mgm: {
    event: 'mgmLinkGenerator-interaction',
    eventCategory: 'MGM Link generator',
    component: {
      name: 'MGM - Link generator',
    },
  },
  balance: {
    event: 'balance-interaction',
    eventCategory: 'Cuzo',
    component: {
      name: 'Balance',
    },
  },
  invoices: {
    event: 'invoice-interaction',
    eventCategory: 'Cuzo',
    component: {
      name: 'Invoice',
    },
  },
  advancePayment: {
    event: 'advancePayment-interaction',
    eventCategory: 'Cuzo',
    component: {
      name: 'Advance payment',
    },
  },
  paymentPreferences: {
    event: 'paymentPreferences-interaction',
    eventCategory: 'Cuzo',
    component: {
      name: 'Payment preferences',
    },
  },
  building: {
    event: 'Building-interaction',
    eventCategory: 'Cuzo',
    component: {
      name: 'Building',
    },
  },
  estimatedIndex: {
    event: 'estimatedIndex-interaction',
    eventCategory: 'Cuzo',
    component: {
      name: 'Estimated indexes',
    },
  },
  addressChange: {
    event: 'addressChange-interaction',
    eventCategory: 'Cuzo',
    component: {
      name: 'Address change popup',
    },
  },
  emailChange: {
    event: 'emailChange-interaction',
    eventCategory: 'Cuzo',
    component: {
      name: 'Email change popup',
    },
  },
  greenCertificate: {
    event: 'greenCertificate-interaction',
    eventCategory: 'Cuzo',
    component: {
      name: 'Green certificates',
    },
  },
  contract: {
    event: 'contract-interaction',
    eventCategory: 'Cuzo',
    component: {
      name: 'Contract',
    },
  },
  move: {
    event: 'move-interaction',
    eventCategory: 'Cuzo',
    component: {
      name: 'Move',
    },
  },
  consent: {
    event: 'consent-interaction',
    eventCategory: 'Cuzo',
    component: {
      name: 'Consent',
    },
  },
  references: {
    event: 'references-interaction',
    eventCategory: 'Cuzo',
    component: {
      name: 'References',
    },
  },
  registration: {
    event: 'registration-interaction',
    eventCategory: 'Cuzo',
    component: {
      name: 'Registration',
    },
  },
  binding: {
    event: 'binding-interaction',
    eventCategory: 'Cuzo',
    component: {
      name: 'Binding',
    },
  },
  orangeBlock: {
    event: 'orangeBlock-interaction',
    eventCategory: 'My Energy',
    component: {
      name: 'Orange block',
    },
  },
  depositModification: {
    event: 'depositModification-interaction',
    eventCategory: 'My Energy',
    component: {
      name: 'Deposit Modification',
    },
  },
  myEnergyFlow: {
    event: 'myEnergyFlow-interaction',
    eventCategory: 'My Energy',
    component: {
      name: 'MyEnergy flow',
    },
  },
  myEnergyConsumption: {
    event: 'annualConsumption-Interaction',
    eventCategory: 'My Energy',
    component: {
      name: 'MyEnergy-annual-consumption',
    },
  },
  myEnergyMeterReading: {
    event: 'meterReading-Interaction',
    eventCategory: 'My Energy',
    component: {
      name: 'MyEnergy-meter-reading',
    },
  },
  blogBlock: {
    event: 'blogBlock-interaction',
    eventCategory: 'Blog link',
    component: {
      name: 'Blog article block',
    },
  },
  eliqInsight: {
    event: 'eliqInsight-interaction',
    eventCategory: 'Eliq Insight',
    component: {
      name: 'click_cta',
    },
  },
};

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function myEnergyEvents(path) {
  return {
    event: 'myEnergyFlow-step',
    component: {
      name: 'MyEnergy flow',
    },
    page: {
      phase: 'care',
      category: 'cuzo',
      subCategory: 'MyEnergy flow - cuzo',
      path,
    },
  };
}
