import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs';
import { Article } from '../../models/article.interface';
import { UtilsService } from '@app/shared/utils/utils.service';

@Injectable({
  providedIn: 'root',
})
export class BlogService {
  private blogArticles: any = {};

  constructor(private http: HttpClient, public utilsService: UtilsService) {}

  public getBlogArticles(limit: number, langcode: string): Observable<any> {
    if (this.blogArticles[langcode]) {
      return of(this.blogArticles[langcode]);
    }

    return this.getBlogArticlesFromApi(limit, langcode).pipe(
      tap((data) => {
        if (!this.blogArticles[langcode]) {
          this.blogArticles[langcode] = {};
        }
        this.blogArticles[langcode] = data;
      })
    );
  }

  public getBlogArticlesFromApi(limit: number, langcode: string) {
    const blogUrl = this.utilsService.getBlogUrl();
    const endpoint = `${blogUrl}/jsonapi/node/article`;
    const params = new HttpParams()
      .set('page[limit]', limit.toString())
      .set('filter[status][value]', '1')
      .set('filter[langcode][value]', langcode)
      .set('sort', '-created');

    return this.http.get(endpoint, { params }).pipe(
      map((data: any) => {
        const items = [];
        data.data.forEach((article) => {
          const item = {
            title: article.attributes.title,
            teaser: article.attributes.field_teaser.processed,
            url: `${blogUrl}${article.attributes.path.alias}`,
          } as Article;
          items.push(item);
        });
        return items;
      })
    );
  }
}
