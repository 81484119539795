<div class="card-primary-small relative h-full">
  <div class="md:flex md:flex-col justify-between h-full pt-4 px-8">
    <div>
      <h3 class="mb-4 text-2xl font-medium text-dark-grey leading-snug">
        {{ 'components.widgets.crossSell.title.' + crossSellType | translate }}
      </h3>
      <div class="flex md:block lg:flex gap-x-8">
        <img
          [src]="'./assets/img/cross-sell/' + getImageName(crossSellType) + '.png'"
          class="w-40 h-52 rounded-2xl object-cover"
        />
        <p class="text-sm">{{ 'components.widgets.crossSell.description.' + crossSellType | translate }}</p>
      </div>
    </div>
    <div class="pt-4">
      <a [href]="getCrossSellFluviusUrl()" target="_blank" class="btn btn-primary !block font-bold px-8 m-auto">
        {{ 'components.widgets.crossSell.cta.' + crossSellType | translate }}
      </a>
      <a [href]="getCrossSellUrl()" target="_blank" class="block btn-text text-primary font-bold px-8 mt-4 text-center">
        {{ 'components.widgets.crossSell.cta.moreInfo' | translate }}
      </a>
    </div>
  </div>
</div>
