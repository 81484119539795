export enum CtxPosition {
  left = 'left',
  right = 'right',
  center = 'center',
  middle = 'middle',
  top = 'top',
  bottom = 'bottom',
}

export enum CtxFont {
  roboto = 'Roboto',
  normal = 'normal',
  bold = 'bold',
}

export enum ChartUnit {
  kwh = 'kWh',
  percentage = '%',
  separator = ' ',
  m3 = 'm³',
}

export enum ChartStyle {
  bar = 'bar',
  line = 'line',
  doughnut = 'doughnut',
}

export enum ChartColor {
  darkGrayBlue = 'rgba(27, 71, 217, 1)',
  darkBlue = 'rgba(40, 90, 255, 1)',
  darkBlue10 = 'rgba(40, 90, 255, 0.1)',
  darkBlue30 = 'rgba(40, 90, 255, 0.3)',
  grayBlue = 'rgba(115, 185, 229, 1)',
  lightGray = 'rgba(247, 249, 250, 1)',
  gray = 'rgba(135, 144, 146, 1)',
  gray60 = 'rgba(112, 152, 167, 0.6)',
  darkGray = 'rgba(55, 70, 73, 1)',
  lightBlue = 'rgba(128, 205, 255, 1)',
  lightBlue10 = 'rgba(128, 205, 255, 0.1)',
  lightBlue30 = 'rgba(128, 205, 255, 0.3)',
  lightBlue40 = 'rgba(128, 205, 255, 0.4)',
  lightBlue60 = 'rgba(128, 205, 255, 0.6)',
  silver = 'rgba(183, 203, 211, 1)',
  silver50 = 'rgba(183, 203, 211, 0.5)',
  redDefault = 'rgba(237, 0, 0, 1)',
  red = 'rgba(219, 57, 0, 1)',
  red20 = 'rgba(219, 57, 0,0.3)',
  red30 = 'rgba(219, 57, 0,0.3)',
  turquoise = 'rgba(146, 234, 234, 1)',
  white = 'rgba(255, 255, 255, 1)',
  orange = 'rgba(239, 111, 0, 1)',
  orange10 = 'rgba(239, 111, 0, 0.1)',
  grayYellow = 'rgba(219, 196, 67, 1)',
  yellow = 'rgba(255, 231, 79, 1)',
  yellow40 = 'rgba(255, 231, 79, 0.4)',
}

export const TextRowOffset = 18;
