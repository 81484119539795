import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-tooltip',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit, OnDestroy, OnChanges {
  @Input() tooltip: string;
  @Input() tooltipParams: object;
  @Input() position: string;
  @Input() contentClass = '';
  @Input() isWarning? = false;

  public notifier$ = new Subject<void>();

  public tooltipContent: SafeHtml;

  constructor(protected sanitizer: DomSanitizer, protected translate: TranslateService) {}

  ngOnInit(): void {
    this.translateTooltip();
    this.translate.onLangChange.pipe(takeUntil(this.notifier$)).subscribe(() => this.translateTooltip());
    this.position = this.position ? this.position : 'left';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tooltip?.currentValue) {
      this.translateTooltip();
    }
  }

  ngOnDestroy(): void {
    this.notifier$.next();
    this.notifier$.complete();
  }

  protected setMessageFromKey(key: string, params: object) {
    const text = this.translate.instant(key, params);

    return this.sanitizer.bypassSecurityTrustHtml(text);
  }

  private translateTooltip(): void {
    this.tooltipContent = this.setMessageFromKey(this.tooltip, this.tooltipParams);
  }
}
