<!-- SINGLE SELECT -->
<div *ngIf="question?.dataType === dataType.singleSelect">
  <div *ngFor="let value of question?.possibleValues; let i=index">
    <div class="form-element input-radio-big mb-8">
      <input
        id="{{ question?.key + i }}"
        value="{{ value.value }}"
        type="radio"
        name="{{ question.key }}"
        [formControl]="form?.get(question.key)"
      />
      <label for="{{ question.key + i }}">
        {{ 'components.homeProfileForm.list.' + (category ? category + '.' : '') + question?.key + '.choices.' + value?.value | translate }}
      </label>
    </div>
  </div>
</div>

<!-- MULTI SELECT -->
<div *ngIf="question?.dataType === dataType.multiSelect">
  <div *ngFor="let control of form?.get(question.key)?.controls; let i=index">
    <div class="form-element input-checkbox-big mb-8">
      <input
        type="checkbox"
        id="{{ question?.possibleValues[i].value + i }}"
        name="{{ question.key }}"
        [formControl]="control"
      />
      <label for="{{ question?.possibleValues[i].value + i }}">
        {{ question?.possibleValues[i].value }}
      </label>
    </div>
  </div>
</div>

<!-- NUMBER -->
<div *ngIf="question?.dataType === dataType.number">
  <div class="bg-grey-light flex items-center">
    <span class="font-semibold px-8 py-4 flex-1">
      {{ 'components.homeProfileForm.list.' + (category ? category + '.' : '') + question?.key + '.label' | translate }}
    </span>
    <app-input-number
      [min]="question?.limit?.min"
      [max]="question?.limit?.max"
      [step]="question?.limit?.step"
      [formControl]="form?.get(question.key)"
    >
    </app-input-number>
  </div>
</div>

<!-- NUMERIC INPUT -->
<div *ngIf="question?.dataType === dataType.numericInput">
  <div
    class="form-element input-text"
    [class.has-suffix]="question?.suffix"
    [class.errors]="form?.get(question.key).invalid && (form?.get(question.key).dirty || form?.get(question.key).touched)"
  >
    <label for="{{ question?.key }}">
      {{ 'components.homeProfileForm.list.' + (category ? category + '.' : '') + question?.key + '.label' | translate }}
    </label>
    <div class="flex">
      <input
        onlyNumber
        id="{{ question?.key }}"
        name="{{ question?.key }}"
        type="text"
        autocomplete="off"
        [formControl]="form?.get(question.key)"
      />
      <div *ngIf="question?.suffix" class="suffix">
        {{ 'components.homeProfileForm.general.' +  question?.suffix  | translate }}
      </div>
    </div>
    <div class="message">
      <ng-container
        *ngIf="form?.get(question.key).invalid && (form?.get(question.key)?.dirty || form?.get(question.key)?.touched)">
        <span *ngIf="form?.get(question.key)?.errors?.required">
          {{ 'components.homeProfileForm.list.' + (category ? category + '.' : '') + question?.key + '.validation.required' | translate }}
        </span>
        <span *ngIf="form?.get(question.key)?.errors?.leadingZero; else minMaxError">
          {{ 'components.homeProfileForm.genericErrors.leadingZero' | translate }}
        </span>
        <ng-template #minMaxError>
          <span *ngIf="form?.get(question.key)?.errors?.min">
            {{ 'components.homeProfileForm.list.' + (category ? category + '.' : '') + question?.key + '.validation.min' | translate: { value: question?.limit?.min } }}
          </span>
          <span *ngIf="form?.get(question.key)?.errors?.max">
          {{ 'components.homeProfileForm.list.' + (category ? category + '.' : '') + question?.key + '.validation.max' | translate: { value: question?.limit?.max } }}
        </span>
        </ng-template>
      </ng-container>
    </div>
  </div>
</div>
