import { Component, inject, Input, OnInit } from '@angular/core';
import { NgForOf, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ModalComponent } from '@app/shared/components/modal/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-related-invoices',
  standalone: true,
  templateUrl: './related-invoices.component.html',
  imports: [NgForOf, TranslateModule, NgIf, ModalComponent],
  styleUrls: ['./related-invoices.component.scss'],
})
export class RelatedInvoicesComponent implements OnInit {
  readonly MAX_INVOICES = 6;
  private modalService: NgbModal = inject(NgbModal);
  @Input() relatedInvoices!: string[];

  constructor() {}

  ngOnInit(): void {}

  openModal(content) {
    this.modalService.open(content, { size: 'sm' });
  }
}
