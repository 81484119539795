<ng-template #content let-modal>
  <ng-container *ngIf="contact?.email !== userInfo?.email">
    <app-modal [activeModal]="modal" title="{{ 'components.emailSync.title' | translate }}">
      <div class="">
        <div class="my-8">
          <p class="mb-8">{{ 'components.emailSync.description' | translate }}</p>
          <p>
            {{ 'components.emailSync.loginEmail' | translate }} <span class="font-bold">{{ userInfo?.email }}</span>
          </p>
          <p>
            {{ 'components.emailSync.contactEmail' | translate }} <span class="font-bold">{{ contact?.email }}</span>
          </p>
          <p>
            {{ 'components.emailSync.newContactEmail' | translate }}
            <span class="font-bold">{{ userInfo?.email }}</span>
          </p>
        </div>

        <div class="mt-4 inline-block w-full">
          <button (click)="updateEmail()" class="btn btn-primary inline-block w-full font-bold mb-4 md:mb-0 md:w-auto">
            {{ 'general.update' | translate }}
          </button>
          <button (click)="modal.dismiss()" class="btn btn-secondary inline-block w-full font-bold md:ml-8 md:w-auto">
            {{ 'general.cancel' | translate }}
          </button>
        </div>
      </div>
    </app-modal>
  </ng-container>
</ng-template>
