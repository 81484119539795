<section [formGroup]="energyTypeFormGroup">
  <div>
    <h3 class="font-medium text-lg"> Pour quelles énergies voulez-vous souscrire à un contrat à votre nouvelle adresse ? </h3>
    <p
      *ngFor="let deliveryPoint of deliveryPoints"
      class="disabled form-element input-checkbox-big errors mt-4"
    >
      <input
        type="checkbox"
        checked
        disabled
        [name]="deliveryPoint?.energyType"
        [id]="deliveryPoint?.energyType"
      />
      <label [for]="deliveryPoint?.energyType">{{ deliveryPoint?.energyType }}</label>
    </p>
  </div>

  <div class="mt-12">
    <h3 class="font-medium text-lg">Date de début de fourniture</h3>
    <span class="mb-4 inline-block w-full">Indiquez la date souhaitée de début de fourniture <br/>(concernera toutes les énergies sélectionnées ci-dessus)</span>
    <date-picker-field
      formControlName="date"
      [fieldLabel]="'JJ/MM/AAAA'"
      [dateRange]="serviceDateRange"
      (dateChanged)="onDateSelection($event)"
    >
    </date-picker-field>
  </div>
</section>
