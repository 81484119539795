import { Component, OnDestroy, OnInit } from '@angular/core';
import { MainFacade } from '@app/core/facade/main.facade';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-e-move',
  templateUrl: './e-move.component.html',
  styleUrls: ['./e-move.component.scss'],
})
export class EMoveComponent implements OnInit, OnDestroy {
  public moveUrl: string;
  private notifier: Subject<void> = new Subject();

  constructor(private facade: MainFacade) {}

  ngOnInit(): void {
    this.facade.utils.setPageTitle('pages.move.pageTitle');
    this.setMoveUrl();

    this.facade.translate.onLangChange.pipe(takeUntil(this.notifier)).subscribe(() => {
      this.setMoveUrl();
    });
  }

  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
  }

  private setMoveUrl(): void {
    this.moveUrl = this.facade.utils.getMoveUrl();
  }
}
