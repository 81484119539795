import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { filter, Observable } from 'rxjs';
import { ReferenceDetails } from '@app/modules/customer-zone/user/models/reference.interface';
import { ReferenceService } from '@app/modules/customer-zone/user/services/reference/reference.service';
import { LangChangeEvent } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { UserInfo } from '@app/modules/customer-zone/user/models/userInfo.interface';
import { UserService } from '@app/modules/customer-zone/user/services/user/user.service';
import { ScreenSizeService } from '../../../service/screen-size.service';
import { LoaderService } from '@app/shared/modules/loader/services/loader.service';
import { LangSwitcherType } from '@app/shared/components/lang-switcher/lang-switcher';
import { Content } from '@app/shared/models/content.interface';
import { MainFacade } from '@app/core/facade/main.facade';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterContentChecked, DoCheck {
  @ViewChild('menu') menu: ElementRef;
  @ViewChild('menuToggler') menuToggler: ElementRef;
  @Output() public mobileMenuEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  public readonly langSwitcherType = LangSwitcherType;
  public referencesDetails$: Observable<ReferenceDetails[]>;
  public activeReference$: Observable<string>;
  public userInfo$: Observable<UserInfo>;
  public activeReference: string;
  public currentLanguage: string;

  public isSubmenuActive = false;
  public mobileMenu = false;
  public mobileSubmenu = false;
  public mobileActiveSubmenu: string;

  public isMobileVersion = 768;

  //public userType$: Observable<UserType>;
  public content$: Observable<Content>;

  constructor(
    private referenceService: ReferenceService,
    private userService: UserService,
    private router: Router,
    public screenSizeService: ScreenSizeService,
    private cdr: ChangeDetectorRef,
    private loaderService: LoaderService,
    private facade: MainFacade
  ) {
    this.facade.translate.onLangChange.subscribe((event: LangChangeEvent) => (this.currentLanguage = event.lang));
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (
      this.mobileMenu &&
      !this.menu.nativeElement.contains(event.target) &&
      !this.menuToggler.nativeElement.contains(event.target)
    ) {
      this.toggleMobileMenu(false);
    }
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngDoCheck() {
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.referencesDetails$ = this.referenceService.getReferencesDetails();
    this.currentLanguage = this.facade.translate.currentLang;
    this.userInfo$ = this.userService.getUserInfo();
    this.facade.reference$.pipe(filter((reference: string) => !!reference)).subscribe((reference: string) => {
      this.content$ = this.facade.loadContent(reference);
      this.activeReference = reference;
    });
  }

  public switchReference(reference: string) {
    this.loaderService.showLoadingEffect();
    this.facade.updateActiveReference(reference);
  }

  public switchLanguage(lang: string) {
    this.facade.translate.use(lang);
    this.facade.translate.setDefaultLang(lang);
    this.currentLanguage = lang;
  }

  public goHome() {
    this.toggleMobileMenu(false);
    this.router.navigate(['/']);
  }

  public showSubmenu($event: boolean) {
    this.isSubmenuActive = $event;
  }

  public toggleMobileMenu(toggleMobileMenu: boolean = null) {
    this.mobileMenu = toggleMobileMenu !== null ? toggleMobileMenu : !this.mobileMenu;
    this.mobileMenuEmitter.emit(this.mobileMenu);
  }

  public mobileCloseSubmenu(mobileSubmenu: boolean) {
    this.mobileSubmenu = mobileSubmenu;
  }

  public mobileShowSubmenu(mobileActiveSubmenu: string) {
    this.mobileSubmenu = !this.mobileSubmenu;
    this.mobileActiveSubmenu = mobileActiveSubmenu;
  }
}
