<div class="font-medium text-2xl text-grey-dark my-8">
  {{ 'components.monitoring.weeklyData.title' | translate: { name: (chargingStation$ | async)?.name } }}
</div>

<div class="border border-grey rounded-4xl">
  <!--  header with aggregated data of the week  -->
  <div class="md:flex py-6 px-6 md:py-8 md:px-12">
    <div class="md:w-1/3">
      <app-date-picker [activeDateRange$]="activeDateRange$" (rangeSelected)="selectedRangeChanged($event)">
      </app-date-picker>
    </div>
    <div class="md:flex flex-row mt-8 md:mt-4 md:w-2/3">
      <div class="flex-1 flex items-start md:justify-center gap-4 w-full md:w-1/2">
        <img
          src="./assets/img/electricity.svg"
          width="45"
          height="45"
          [alt]="'components.monitoring.weeklyData.summary.consumption' | translate"
        />
        <div class="">
          <div class="font-medium text-xl text-primary">
            {{ totalConsumption | async | number: '1.0-2' }} {{ 'general.units.kwh' | translate }}
          </div>
          <div class="text-xs text-grey-dark60">
            {{ 'components.monitoring.weeklyData.summary.consumption' | translate }}
          </div>
        </div>
      </div>

      <div class="mt-4 flex-1 flex items-start md:justify-center gap-4 w-full md:w-1/2 md:mt-0">
        <img
          src="./assets/img/euro.svg"
          width="45"
          height="45"
          [alt]="'components.monitoring.weeklyData.summary.price.label' | translate"
        />
        <div class="flex">
          <div>
            <div class="font-medium text-xl text-primary">{{ totalPrice | async | number: '1.2-2' }} €</div>
            <div class="text-xs text-grey-dark60">
              {{ 'components.monitoring.weeklyData.summary.price.label' | translate }}
            </div>
          </div>
          <div class="self-center ml-6 text-xs">
            <app-tooltip [tooltip]="'components.monitoring.weeklyData.summary.price.tooltip' | translate"></app-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--  table  -->
  <div class="bg-white rounded-b-4xl">
    <div class="hidden md:flex text-xs md:py-2 md:px-8 border-t border-solid text-grey-dark60">
      <div class="w-1/4">{{ 'components.monitoring.weeklyData.table.date' | translate }}</div>
      <div class="w-1/4 text-center">{{ 'components.monitoring.weeklyData.table.consumption' | translate }}</div>
      <div class="w-1/4 text-center">{{ 'components.monitoring.weeklyData.table.price' | translate }}</div>
      <div class="w-1/4 text-center">{{ 'components.monitoring.weeklyData.table.time' | translate }}</div>
    </div>

    <ng-container *ngIf="(chargingStationSessions$ | async)?.length === 0">
      <div class="block w-full md:flex border-t border-solid items-center px-12">
        <div class="w-full py-16 text-sm text-center">
          {{ 'components.monitoring.weeklyData.table.empty' | translate }}
        </div>
      </div>
    </ng-container>

    <ng-container *ngFor="let session of chargingStationSessions$ | async">
      <div class="block w-full md:flex border-t border-solid items-center px-8">
        <div class="mb-4 w-full pt-8 md:mb-0 md:p-0 md:w-1/4">
          <span class="font-medium text-base text-grey-dark">
            {{ session.startDate | date: 'EEEE':'':locale | titlecase }}
            {{ session.startDate | date: 'dd LLLL':'':locale }}
          </span>
          <div class="font-normal text-xs text-grey-dark pt-1">
            {{ 'components.monitoring.weeklyData.table.from' | translate }}
            {{ session.startDate | date: 'HH' }}h{{ session.startDate | date: 'mm' }}
            {{ 'components.monitoring.weeklyData.table.to' | translate }}
            {{ session.endDate | date: 'HH' }}h{{ session.endDate | date: 'mm' }}
          </div>
        </div>
        <div class="w-full md:h-24 md:flex md:h-40 items-center md:pl-16">
          <div class="mb-4 md:mb-0 w-full md:w-1/3">
            <div class="flex md:justify-center">
              <img
                src="./assets/img/tevc-monitoring/grey/electricity.svg"
                [alt]="'components.monitoring.weeklyData.table.consumption' | translate"
              />
              <span class="font-medium pl-4"
                >{{ session.totalEnergy / 1000 | number: '1.0-2' }} {{ 'general.units.kwh' | translate }}</span
              >
            </div>
          </div>
          <div class="mb-4 md:mb-0 w-full md:w-1/3">
            <div class="flex md:justify-center">
              <img
                src="./assets/img/tevc-monitoring/grey/euro.svg"
                [alt]="'components.monitoring.weeklyData.table.price' | translate"
              />
              <span class="font-medium pl-4">{{ session.totalAmount | number: '1.2-2' }} €</span>
            </div>
          </div>
          <div class="mb-4 md:mb-0 w-full md:w-1/3">
            <div class="flex md:justify-center">
              <img
                src="./assets/img/tevc-monitoring/grey/charging-time.svg"
                [alt]="'components.monitoring.weeklyData.table.time' | translate"
              />
              <span class="font-medium pl-4">{{ session.duration | secondsToTime }}</span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<!--  pagination  -->
<div class="flex justify-between py-8">
  <div (click)="previous()" class="flex cursor-pointer">
    <span class="font-bold text-sm text-primary">
      {{ 'components.monitoring.weeklyData.previous' | translate }}
    </span>
  </div>

  <div
    *ngIf="(this.activeDateRange$ | async).dateTo.getTime() < this.currentDay.getTime()"
    (click)="next()"
    class="flex cursor-pointer"
  >
    <span class="font-bold text-sm text-primary">
      {{ 'components.monitoring.weeklyData.next' | translate }}
    </span>
  </div>
</div>
