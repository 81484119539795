import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InvoiceFilters } from '@app/core/state/state';
import { InvoiceParams } from '@app/modules/customer-zone/invoices/models/invoice.interface';

export interface ApplyFilters {
  filters?: InvoiceParams;
  resetPagination?: boolean;
}

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {
  @Input() set filters(filters: InvoiceFilters) {
    this.yearFiltersSorted = filters?.invoiceYears?.sort((a, b) => b - a);
    this.invoiceTypes = filters?.invoiceTypes;
    this.invoicesFilters = {};
  }
  @Output() emitActiveFilters: EventEmitter<{
    filters?: InvoiceParams;
    resetPagination?: boolean;
  }> = new EventEmitter<ApplyFilters>();
  public invoiceTypes: string[];
  public invoicesFilters: InvoiceParams = null;
  public yearFiltersSorted: number[];

  constructor(public activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.retrieveFiltersFromQueryparams();
  }

  public filterBy(type: string, filter: string = null) {
    if (!filter && this.invoicesFilters[type]) {
      delete this.invoicesFilters[type];
    } else {
      this.invoicesFilters[type] = filter;
    }
    this.emitActiveFilters.emit({
      filters: this.invoicesFilters,
      resetPagination: true,
    });
  }

  private retrieveFiltersFromQueryparams() {
    this.activatedRoute.queryParams.forEach((queryParams) => {
      this.invoicesFilters = {};
      if (queryParams.year) {
        this.invoicesFilters.year = queryParams.year;
      }
      if (queryParams.invoiceType) {
        this.invoicesFilters.invoiceType = queryParams.invoiceType;
      }
    });
    this.emitActiveFilters.emit({
      filters: this.invoicesFilters,
      resetPagination: false,
    });
  }
}
