import { Component, Input, OnChanges } from '@angular/core';
import { DeliveryPoint, EliqAccessRights } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { Site } from '@app/modules/customer-zone/user/models/site.interface';
import {
  ConsumptionRequestData,
  EnergyType,
  MeasurementNature,
  MeterVolume,
  RegisterType,
  Volume,
} from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { MainFacade } from '@app/core/facade/main.facade';
import { catchError, map, Observable, of } from 'rxjs';
import { Direction } from '@app/modules/customer-zone/consumption/models/deliveryPoint.interface';

@Component({
  selector: 'app-comparison-consumption-widget',
  templateUrl: './comparison-consumption-widget.component.html',
  styleUrls: ['./comparison-consumption-widget.component.scss'],
})
export class ComparisonConsumptionWidgetComponent implements OnChanges {
  @Input() eliqAccessRights: EliqAccessRights;
  @Input() reference: string;
  @Input() site: Site;
  @Input() deliveryPoints: DeliveryPoint[];
  @Input() energyType: EnergyType;

  error = false;
  data$: Observable<any>;
  maxConsumption: number;
  protected readonly energyTypes = EnergyType;

  constructor(public facade: MainFacade) {}

  ngOnChanges(data): void {
    if (data?.deliveryPoints?.currentValue?.length) {
      this.error = false;
      const deliveryPoint: DeliveryPoint = this.deliveryPoints.find(({ energy }) => energy === this.energyType);
      if (!deliveryPoint) {
        return;
      }

      const k: ConsumptionRequestData = {
        reference: this.reference,
        siteId: this.site.id,
        energyType: this.energyType,
        deliveryPoint: deliveryPoint?.reference,
      };

      const order: RegisterType[] = [
        RegisterType.LOW,
        RegisterType.HIGH,
        RegisterType.TOTAL_HOUR,
        RegisterType.NIGHT_EXCLUSIVE,
      ];
      this.data$ = this.facade.loadVolumesDetails(k).pipe(
        catchError((e) => {
          this.error = true;
          return of(null);
        }),
        map((vol: Volume[]) => {
          if (!vol) {
            return vol;
          }
          const array = vol
            .map((d) => ({
              ...d,
              volumes: d?.volumes
                .filter(
                  (meterVolume: MeterVolume) =>
                    meterVolume.direction !== Direction.injection &&
                    meterVolume.direction !== Direction.production &&
                    meterVolume.measurementNature !== MeasurementNature.ACTIVE_POWER
                )
                .sort((a: MeterVolume, b: MeterVolume) => {
                  const indexA = order.indexOf(a.register);
                  const indexB = order.indexOf(b.register);
                  return indexA - indexB;
                }),
            }))
            .filter((d) => d.volumes.length > 0)
            .map((d) => ({
              ...d,
              totalVolumes: d?.volumes.reduce((acc, curr) => acc + curr?.value, 0),
            }));
          this.maxConsumption = Math.max(...array.map((d) => d?.totalVolumes));
          if (!array || array?.length < 4) {
            return array.reverse();
          }
          return array.slice(0, 3).reverse();
        })
      );
    }
  }
}
