import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, delay, Observable, of, shareReplay, tap, throwError } from 'rxjs';
import { InvoiceParams } from '@app/modules/customer-zone/invoices/models/invoice.interface';
import { InvoiceResponseV2CuzoApi } from '@app/shared/models/cuzo-be-contract';

@Injectable({
  providedIn: 'root',
})
export class InvoiceV2Service {
  readonly httpClient: HttpClient = inject(HttpClient);
  private cache: Map<string, InvoiceResponseV2CuzoApi> = new Map<string, InvoiceResponseV2CuzoApi>();

  getInvoices(reference: string, invoiceParams: InvoiceParams): Observable<InvoiceResponseV2CuzoApi> {
    const cacheKey: string = this.generateCacheKey(reference, invoiceParams);
    const params: HttpParams = this.createHttpParams(invoiceParams);
    if (this.cache.has(cacheKey)) {
      return of(this.cache.get(cacheKey)).pipe(delay(500));
    }
    return this.httpClient.get<InvoiceResponseV2CuzoApi>(`/v2/customers/${reference}/invoices`, { params }).pipe(
      catchError((err): Observable<InvoiceResponseV2CuzoApi> => throwError(err)),
      tap((invoices: InvoiceResponseV2CuzoApi) => this.cache.set(cacheKey, invoices)),
      shareReplay(1)
    );
  }

  clearCache(): void {
    this.cache.clear();
  }

  private generateCacheKey(reference: string, invoiceParams: InvoiceParams): string {
    return `${reference}-${JSON.stringify(invoiceParams)}`;
  }

  private createHttpParams(invoiceParams: Partial<InvoiceParams>): HttpParams {
    let params: HttpParams = new HttpParams();
    params = params.set('loader', 'false');

    if (invoiceParams?.offset !== undefined) {
      params = params.set('offset', invoiceParams.offset.toString());
    }

    if (invoiceParams?.limit !== undefined) {
      params = params.set('limit', invoiceParams.limit.toString());
    }

    if (invoiceParams?.year !== undefined) {
      params = params.set('year', invoiceParams.year.toString());
    }

    if (invoiceParams?.invoiceType !== undefined) {
      params = params.set('invoiceType', invoiceParams.invoiceType);
    }

    return params;
  }
}
