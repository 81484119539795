<div class="filters inline-block w-full mt-16 md:mt-0 md:ml-auto">
  <div *ngIf="yearFiltersSorted?.length" class="form-element custom-select inline-block w-full md:w-80 md:mr-8">
    <div class="select mb-8 md:mb-0" ngbDropdown>
      <button
        id="year-filter"
        class="selected-option !flex"
        [ngClass]="{ 'pointer-events-none': !yearFiltersSorted?.length }"
        ngbDropdownToggle
        [disabled]="!yearFiltersSorted?.length"
      >
        <ng-container *ngIf="invoicesFilters?.year">{{ invoicesFilters?.year }}</ng-container>
        <ng-container *ngIf="!invoicesFilters?.year">{{
          'components.invoices.filters.yearAll' | translate
        }}</ng-container>
      </button>
      <ul ngbDropdownMenu aria-labelledby="year-filter" class="options">
        <li
          ngbDropdownItem
          *ngIf="invoicesFilters?.year"
          (click)="filterBy('year')"
          [analyticsEvent]="'invoices:filter:year'"
        >
          {{ 'components.invoices.filters.yearAll' | translate }}
        </li>
        <ng-container *ngFor="let year of yearFiltersSorted">
          <li
            ngbDropdownItem
            *ngIf="year !== invoicesFilters?.year"
            (click)="filterBy('year', year)"
            [analyticsEvent]="'invoices:filter:year-' + year"
          >
            {{ year }}
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
  <div *ngIf="invoiceTypes?.length" class="form-element custom-select inline-block w-full md:w-[26rem]">
    <div class="select" ngbDropdown>
      <button
        id="invoice-type-filter"
        class="selected-option !flex"
        [ngClass]="{ 'pointer-events-none': !yearFiltersSorted?.length }"
        ngbDropdownToggle
        [disabled]="!yearFiltersSorted?.length"
      >
        <ng-container *ngIf="invoicesFilters?.invoiceType">
          {{ 'components.invoices.invoiceTypes.' + invoicesFilters.invoiceType | translate }}
        </ng-container>
        <ng-container *ngIf="!invoicesFilters?.invoiceType">
          {{ 'components.invoices.filters.invoiceTypeAll' | translate }}
        </ng-container>
      </button>
      <ul ngbDropdownMenu aria-labelledby="invoice-type-filter" class="options">
        <li
          ngbDropdownItem
          *ngIf="invoicesFilters?.invoiceType"
          (click)="filterBy('invoiceType')"
          [analyticsEvent]="'invoices:filter:invoice-type'"
        >
          {{ 'components.invoices.filters.invoiceTypeAll' | translate }}
        </li>
        <ng-container *ngFor="let invoiceType of invoiceTypes">
          <li
            ngbDropdownItem
            *ngIf="invoiceType !== invoicesFilters?.invoiceType"
            (click)="filterBy('invoiceType', invoiceType)"
            [analyticsEvent]="'invoices:filter:invoice-type-' + invoiceType"
          >
            {{ 'components.invoices.invoiceTypes.' + invoiceType | translate }}
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>
