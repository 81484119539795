import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqComponent } from './components/faq/faq.component';
import { FaqItemComponent } from './components/faq-item/faq-item.component';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared.module';
import { AnalyticsModule } from '../analytics/analytics.module';

@NgModule({
  declarations: [FaqComponent],
  exports: [FaqComponent, FaqItemComponent],
  imports: [CommonModule, NgbAccordionModule, TranslateModule, AnalyticsModule, SharedModule, FaqItemComponent],
})
export class FaqModule {}
