<div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
  <div class="mb-16">
    <div class="md:flex md:items-center">
      <h2 class="mt-12 mb-8 font-display font-normal text-5xl te-text-gradient-home inline-block md:my-12">
        {{ 'components.contracts.myContracts' | translate }}
      </h2>
      <app-sites-switcher
        class="inline-block w-full ml-auto sites-switcher mb-8 md:mb-0 md:w-auto"
        [sites]="sites$ | async"
        [activeSite]="activeSite$ | async"
        [version]="'small'"
        [activeReference]="activeReference"
        (switchSiteEmitter)="switchSite($event)"
      >
      </app-sites-switcher>
    </div>
    <div>
      <div class="mb-8 md:mb-16">
        <app-contracts
          class="mb-16"
          [contracts]="contracts$ | async"
          [site]="activeSite$ | async"
          [reference]="activeReference"
        >
        </app-contracts>
      </div>
    </div>
  </div>
</div>
