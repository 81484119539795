<div class="card-primary-small relative h-full !p-0 md:!p-8">
  <div class="md:flex md:flex-col h-full">
    <img
      [src]="'./assets/img/cross-sell/' + getImageName(crossSellType) + '.png'"
      class="w-full h-full md:h-56 rounded-t-4xl md:rounded-2xl object-cover"
    />
    <div class="flex flex-col grow pb-8 md:pb-0">
      <div class="grow pt-8 px-8">
        <h3 class="mb-4 text-2xl font-medium text-dark-grey leading-snug">
          {{ 'components.widgets.crossSell.title.' + crossSellType | translate }}
        </h3>
        <p class="text-sm">{{ 'components.widgets.crossSell.description.' + crossSellType | translate }}</p>
      </div>
      <a [href]="getCrossSellUrl()" target="_blank" class="block self-start text-primary font-bold px-8 pt-4 text-left">
        {{ 'components.widgets.crossSell.cta.' + crossSellType | translate }}
      </a>
    </div>
  </div>
</div>
