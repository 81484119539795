import { EnergyType } from '@app/modules/customer-zone/consumption/models/consumption.interface';

export interface MoveMeter {
  energy: EnergyType;
  ean: string;
  number: string;
}

export enum ViewStatus {
  NO_MOVE = 'NO_MOVE',
  PART_MOVE = 'PART_MOVE',
  ALL_MOVE = 'ALL_MOVE',
  ALL_DONE = 'ALL_DONE',
}

export const priorityOrder: { [key in ViewStatus]: number } = {
  [ViewStatus.NO_MOVE]: 0,
  [ViewStatus.PART_MOVE]: 1,
  [ViewStatus.ALL_MOVE]: 2,
  [ViewStatus.ALL_DONE]: 3,
};

export enum LoaderStatus {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
}
