import { AnalyticsEventProcessorInterface } from '../../shared/modules/analytics/analytics-event-processor.interface';
import { eventDefaults } from './models/event-defaults';

export class AnalyticsEventProcessor implements AnalyticsEventProcessorInterface {
  public process(event: string) {
    const args: Array<string> = event.split(':');
    const func = args.shift();

    // Check if it is a default event
    if (eventDefaults[func]) {
      return this.defaultEvent(args, eventDefaults[func]);
    }

    // Check if we have function for this event.
    if (typeof this[func] === 'function') {
      return this[func](args);
    }

    return {};
  }

  private defaultEvent([eventAction, eventLabel]: Array<string>, defaults) {
    return {
      ...defaults,
      eventAction,
      eventLabel,
    };
  }

  private blog(args) {
    return {
      event: 'blogBlock-interaction',
      eventCategory: `Blog link - ${args[0]}`,
      eventAction: 'Click link',
      eventLabel: `${args[0]}:${args[1]}`,
      component: {
        name: 'Blog article block',
      },
    };
  }

  private faq(args) {
    const label = args[0] === 'title' ? args[1] : args[1] + args[2];
    return {
      event: 'faq-interaction',
      eventCategory: `Faq`,
      eventAction: 'toggle',
      eventLabel: label,
      component: {
        name: 'faq item',
      },
    };
  }
}
