<ng-container *ngIf="hasMeterNumber; else noValue">
  <ng-container *ngFor="let meter of meters">
    <li *ngIf="meter?.number" class="bg-[#F3F3F3] text-grey-dark px-2 py-1 inline-block rounded-xl border border-grey/20 mr-4">
      <ng-template *ngIf="meter?.energy === EnergyType.ELECTRICITY" [ngTemplateOutlet]="electricity"></ng-template>
      <ng-template *ngIf="meter?.energy === EnergyType.GAS" [ngTemplateOutlet]="gas"></ng-template>
      <span class="pl-2">{{ meter.number }}</span>
    </li>
  </ng-container>
</ng-container>

<ng-template #noValue> — </ng-template>

<ng-template #electricity>
  <svg class="inline-block" width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.1741 0.695652C3.1741 0.311454 3.48556 8.85009e-07 3.86976 8.51422e-07C4.25395 8.17834e-07 4.56541 0.311454 4.56541 0.695652L4.56541 2.66667L9.43497 2.66667L9.43497 0.695652C9.43497 0.311454 9.74643 3.37667e-07 10.1306 3.04079e-07C10.5148 2.70492e-07 10.8263 0.311454 10.8263 0.695652L10.8263 2.66667L11.4399 2.66667C12.6378 2.66667 13.6089 3.5621 13.6089 4.66667L13.6089 6.57291C13.6089 9.62655 11.173 12.1556 7.99519 12.5981C7.99966 12.6299 8.00198 12.6625 8.00198 12.6956L8.00198 15.3043C8.00198 15.6885 7.69052 16 7.30633 16C6.92213 16 6.61067 15.6885 6.61067 15.3043L6.61068 12.6956C6.61068 12.6824 6.61104 12.6693 6.61177 12.6563C3.14268 12.4709 0.391498 9.81817 0.391497 6.57291L0.391497 4.66667C0.391497 3.5621 1.36259 2.66667 2.5605 2.66667L3.1741 2.66667L3.1741 0.695652ZM10.8263 3.96875L11.4399 3.96875C11.8579 3.96875 12.1968 4.28122 12.1968 4.66667L12.1968 6.57291C12.1968 9.21928 9.87018 11.3646 7.00019 11.3646C4.1302 11.3646 1.80361 9.21928 1.80361 6.57292L1.80361 4.66667C1.80361 4.28122 2.14248 3.96875 2.5605 3.96875L3.17411 3.96875L3.17411 4L4.56541 4L4.56541 3.96875L9.43497 3.96875L9.43497 4L10.8263 4L10.8263 3.96875Z" fill="#80CDFF"/>
  </svg>
</ng-template>

<ng-template #gas>
  <svg class="inline-block" width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.95708 15.7816C2.04459 15.7315 -0.765109 11.2536 1.62693 6.20673C3.28195 2.71487 5.22212 0.986687 6.27166 0.248223C6.73983 -0.0811819 7.33956 -0.0822401 7.81125 0.242102C8.89677 0.988524 10.9151 2.74234 12.4539 6.20672C14.6654 11.1857 12.0043 15.782 7.0404 15.782H7.03759C7.03398 15.782 7.03038 15.782 7.02677 15.782C7.00347 15.782 6.98023 15.7819 6.95708 15.7816ZM2.83178 6.77779C4.38184 3.50737 6.16508 1.95352 7.03892 1.33868C7.04156 1.33682 7.04371 1.33551 7.04535 1.3346C7.04772 1.33572 7.05122 1.33762 7.05579 1.34076C7.96797 1.968 9.81009 3.53921 11.2353 6.74796C12.199 8.9176 12.045 10.8578 11.2794 12.2014C11.2275 12.2926 11.1723 12.3818 11.1138 12.4691C11.2436 11.5815 11.1023 10.5822 10.6357 9.55808C9.80055 7.72476 8.75347 6.62841 8.00258 6.02239C7.42221 5.554 6.63177 5.55567 6.05723 6.03119C5.3306 6.6326 4.31179 7.71902 3.41779 9.55808C2.9149 10.5926 2.75688 11.5903 2.87892 12.4726C2.82284 12.3909 2.76977 12.3074 2.71975 12.2223C1.94729 10.9073 1.78408 8.9883 2.83178 6.77779ZM6.97165 14.4483C5.75277 14.4328 4.93316 13.9015 4.52196 13.2191C4.10478 12.5267 3.97711 11.4572 4.61694 10.141C5.42413 8.48053 6.3211 7.54357 6.90737 7.05835C6.95924 7.01541 7.00252 7.00626 7.03282 7.00615C7.06359 7.00603 7.10979 7.01526 7.16519 7.05997C7.77193 7.54965 8.68182 8.48524 9.42238 10.1108C10.011 11.4028 9.89294 12.4939 9.47393 13.2109C9.06479 13.9111 8.2604 14.446 7.03607 14.4487C7.01454 14.4487 6.99306 14.4485 6.97165 14.4483Z" fill="#285AFF"/>
  </svg>
</ng-template>
