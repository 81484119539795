import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { noop } from 'rxjs';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import { DateRange } from './date.model';
import { dateFormatHelper, DateParseFormat } from '@app/shared/date-picker-field/validators';
import { FadeUpDown } from '@app/shared/date-picker-field/animations/fade-up-down.animation';

@Component({
  selector: 'date-picker-field[formControl],date-picker-field[formControlName]',
  templateUrl: './date-picker-field.component.html',
  styleUrls: ['./date-picker-field.component.scss'],
  animations: [FadeUpDown],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerFieldComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: DatePickerFieldComponent,
      multi: true,
    },
  ],
})
export class DatePickerFieldComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() fieldLabel: string;
  @Input() dateRange: DateRange;
  @Input() markDisabled: () => boolean;
  @Output() dateChanged = new EventEmitter();

  onChange: (value: string) => void = noop;
  onTouch: () => void = noop;

  maxLength = 10;
  dateLastKeyHit: string;
  dateLastKeyCode: string;
  disabled = false;
  isPickerDisplayed = false;
  pickedDateValue: NgbDate;

  dateControl = new FormControl<string>('');

  constructor() {
    dayjs.extend(customParseFormat);
  }

  ngOnInit(): void {
    this.dateControl.valueChanges.subscribe((value: string) => this.dateInputEvt(value));
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: string): void {
    // convert date string to the format DD/MM/YYYY, which is used in the date picker field
    if (value) {
      const dayjsObject = dayjs(value, DateParseFormat.withDashes, true);
      // if the date string contains any dashes
      value = (dayjsObject.isValid() ? dayjsObject : dayjs(value)).format(DateParseFormat.default);

      if (value !== this.dateControl.value) {
        this.dateControl.setValue(value);
      }
    }
  }

  validate(control: AbstractControl<string>): ValidationErrors | null {
    return null;
    //return control.value && this.isDateValid(control.value) ? null : { dobInvalid: true };
  }

  togglePicker() {
    this.isPickerDisplayed = !this.isPickerDisplayed;
  }

  dateFieldKeydownEvt($event): void {
    this.dateLastKeyHit = $event.key;
    this.dateLastKeyCode = $event.code;
  }

  closeDatePicker() {
    this.isPickerDisplayed = false;
  }

  onDatePicked(date: NgbDate): void {
    this.closeDatePicker();

    const pickedDayjsDate = dayjs()
      .year(date.year)
      .month(date.month - 1)
      .date(date.day);

    this.dateControl.patchValue(pickedDayjsDate.format(DateParseFormat.default));

    this.prepareFormatAndChange(pickedDayjsDate);
  }

  private dateInputEvt(event): void {
    const value = dateFormatHelper(event, this.dateLastKeyHit, this.dateLastKeyCode);
    this.dateControl.setValue(value, { emitEvent: false, onlySelf: true });

    // update datepicker selection
    const dayjsDate = dayjs(value, [DateParseFormat.default, DateParseFormat.withDashes], true);
    if (dayjsDate.isValid()) {
      this.pickedDateValue = new NgbDate(dayjsDate.year(), dayjsDate.month() + 1, dayjsDate.date());
      this.prepareFormatAndChange(dayjsDate);
    } else {
      this.pickedDateValue = undefined;
      this.onChange(value);
    }
  }

  private prepareFormatAndChange(value: dayjs.Dayjs): void {
    // convert date to the ISO format with end of day time setting before onChange calling
    const isoDate = value.endOf('day').toISOString();

    this.onChange(isoDate);
    this.dateChanged.emit(isoDate);
  }

  private isDateValid(value: string): boolean {
    // the valid string is ISO date format, with dash or slash separators only
    return dayjs(value, [DateParseFormat.iso, DateParseFormat.default, DateParseFormat.withDashes], true).isValid();
  }
}
