<div class="mt-16">
  <div class="inline-block w-full text-center mx-auto">
    <h2
      class="font-display font-normal text-5xl te-text-gradient-home inline-block leading-snug w-full sm:max-w-4xl"
      [innerHTML]="
        sendingMode === 'EMAIL'
          ? ('pages.bindingProcess.step3.titleEmail' | translate)
          : ('pages.bindingProcess.step3.titlePhone' | translate)
      "
    ></h2>
  </div>
  <div class="w-full sm:max-w-2xl mx-auto py-10 mt-8">
    <form [formGroup]="codeForm" novalidate (ngSubmit)="submit()">
      <div class="form-element input-text" [class.errors]="codeError">
        <label for="code">
          {{ 'pages.bindingProcess.step3.verificationCode' | translate }}
        </label>
        <input id="code" formControlName="code" value="" type="text" autocomplete="off" />
        <div class="message">
          <div *ngIf="codeError">{{ 'pages.bindingProcess.step3.wrongVerificationCode' | translate }}</div>
        </div>
      </div>

      <div class="mt-8 text-center inline-block w-full md:flex md:text-left">
        <button
          [attr.disabled]="codeError || !codeForm.get('code').value ? '' : null"
          [class.not-submittable]="codeError || !codeForm.get('code').value"
          [analyticsEvent]="'binding:click:submit-code'"
          class="btn btn-primary inline-block w-full font-bold mb-4 md:mb-0 md:w-auto md:order-last"
          type="submit"
        >
          {{ 'general.send' | translate }}
        </button>
        <span class="navigate-back text-sm text-primary cursor-pointer font-bold md:flex-1">
          <a (click)="emitGoBackRequest()" [analyticsEvent]="'binding:click:back'">
            {{ 'pages.emailSent.return' | translate }}
          </a>
        </span>
      </div>
    </form>
  </div>
</div>
