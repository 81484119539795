<div
  class="bg-grey-light box-shadow-small p-8 mb-8 rounded-2xl cursor-pointer flex items-center"
  (click)="emitItem(item)"
>
  <div class="bg-white p-8 rounded-full mr-8 flex justify-center">
    <img
      [src]="'./assets/img/home-profile/' + item?.key + '.svg' | lowercase"
      alt="{{ 'components.homeProfileForm.list.' + item?.key + '.itemTitle' | translate }}"
    />
  </div>
  <div class="flex-1">
    <ng-container *ngIf="isUpdating; else showValues">
      <div class="item-loading">
        <span class="item-loading__dot"></span>
        <span class="item-loading__dot"></span>
        <span class="item-loading__dot"></span>
      </div>
    </ng-container>
    <ng-template #showValues>
      <span class="inline-block w-full text-sm text-grey-dark60 font-semibold mb-2">
        {{ 'components.homeProfileForm.list.' + item?.key + '.itemTitle' | translate }}
      </span>
      <span class="inline-block w-full font-semibold pr-4">
        {{ value }}
      </span>
    </ng-template>
  </div>
  <img ngSrc="./assets/img/icons/standalone/red/arrow-right.svg" height="17" width="9" alt="" />
</div>
