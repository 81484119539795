import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs';
import { OnboardingService } from '../../services/onboarding.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { UtilsService } from '../../../../shared/utils/utils.service';
import { ToastrService } from 'ngx-toastr';
import { MainFacade } from '@app/core/facade/main.facade';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss'],
})
export class CreateAccountComponent implements OnInit {
  public submitAttempted = false;
  public error = false;
  public valid = false;
  public showForm = true;
  public emailForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.email]),
  });
  private registrationInProcess = false;
  private killer = new Subject();

  constructor(
    private onboardingService: OnboardingService,
    private translate: TranslateService,
    private router: Router,
    private utilsService: UtilsService,
    private toastrService: ToastrService,
    private facade: MainFacade
  ) {}

  ngOnInit() {
    this.utilsService.setPageTitle('pages.onboarding.pageTitle');
    this.analytics();
    this.emailForm.controls.email.valueChanges.pipe(debounceTime(300), takeUntil(this.killer)).subscribe(() => {
      const { email } = this.emailForm.controls;
      this.error = this.submitAttempted && (email.invalid || !email.value);
      this.valid = email.valid && email.value;
    });
  }

  public checkEmail(): boolean {
    this.submitAttempted = true;
    const { email } = this.emailForm.controls;
    if (email.invalid || email.pristine) {
      this.error = true;
      return false;
    }
    return true;
  }

  public handleSubmit() {
    if (this.checkEmail()) {
      this.submitEmailAddress();
    }
  }

  public goBack() {
    if (this.showForm) {
      this.goToLogin();
    } else {
      this.goToForm();
    }
  }

  public submitEmailAddress() {
    if (this.registrationInProcess) {
      return;
    }
    this.registrationInProcess = true;
    const { value } = this.emailForm.controls.email;
    this.onboardingService
      .sendEmailAddress({
        email: value,
        language: this.translate.currentLang.toUpperCase() as 'NL' | 'FR',
        redirectUrl: this.utilsService.getBaseUrl(),
      })
      .subscribe({
        next: () => {
          this.showForm = false;
          this.registrationInProcess = false;
        },
        error: (error: any) => {
          this.registrationInProcess = false;
          const translateKey =
            error.status === 409 ? 'errorMessages.RegistrationInProgress' : 'errorMessages.serverError';
          this.toastrService.error(this.translate.instant(translateKey));
          console.error(
            `submission of email address: ${this.emailForm.controls.email.value} failed with the following:`,
            error
          );
        },
      });
  }

  private goToLogin() {
    this.router.navigate(['/']);
  }

  private goToForm() {
    this.showForm = true;
  }

  private analytics() {
    this.facade.analytics.push({
      event: 'pageview',
      page: {
        phase: 'care',
        category: 'cuzo',
        subCategory: 'Registration - cuzo',
      },
    });
  }
}
