import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import {
  DataType,
  HomeProfileMultiQuestions,
  HomeProfileQuestion,
  HomeProfileQuestionType,
  HomeProfileQuestionValue,
  HomeProfileUpdate,
} from '@app/modules/customer-zone/consumption/services/home-profile/home-profile.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { leadingZero } from '@app/shared/utils/utils.validators';

@Component({
  selector: 'app-home-profile-panel',
  templateUrl: './home-profile-panel.component.html',
  styleUrls: ['./home-profile-panel.component.scss'],
})
export class HomeProfilePanelComponent implements OnChanges {
  @ViewChildren('close') closePanel: QueryList<ElementRef>;
  @Output() closeEmitter: EventEmitter<null> = new EventEmitter<null>();
  @Output() updateEmitter: EventEmitter<HomeProfileUpdate[]> = new EventEmitter<HomeProfileUpdate[]>();
  @Input() question: HomeProfileQuestion | HomeProfileMultiQuestions = null;

  form: FormGroup = null;
  singleQuestion: HomeProfileQuestion;
  multiQuestions: HomeProfileMultiQuestions;
  hasSuggestedValue = false; // allow form submit without edition
  pristineCanBeSubmitted = false;

  constructor(private fb: FormBuilder) {}

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.closePanelAndResetState();
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    const closeElements: ElementRef[] = this.closePanel?.toArray();
    if (this.question && closeElements.some(({ nativeElement }) => nativeElement.contains(event.target))) {
      this.closePanelAndResetState();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.question) {
      this.scrollFreezer(true);
      this.multiQuestions =
        this.question.questionType === HomeProfileQuestionType.multi
          ? (this.question as HomeProfileMultiQuestions)
          : null;
      this.singleQuestion = this.multiQuestions ? null : (this.question as HomeProfileQuestion);
      this.hasSuggestedValue = this.checkForSuggestedValue(this.multiQuestions, this.singleQuestion);
      this.form = this.buildForm();
      this.pristineCanBeSubmitted = this.hasOnlyNumberFields() && this.hasOnlyZeroValues();
    }
  }

  handleSubmit(): void {
    if (this.form.valid) {
      const payload: HomeProfileUpdate[] = this.multiQuestions
        ? (this.question.value as HomeProfileQuestion[])
            .map((question: HomeProfileQuestion) => this.buildPayload(question))
            .filter(({ value }) => value !== null)
        : [this.buildPayload(this.question as HomeProfileQuestion)];
      this.updateEmitter.emit(payload);
      this.closePanelAndResetState();
    }
  }

  private hasOnlyNumberFields() {
    return this.multiQuestions
      ? (this.question.value as HomeProfileQuestion[]).every(
          (question: HomeProfileQuestion) => question.dataType === DataType.number
        )
      : (this.question as HomeProfileQuestion).dataType === DataType.number;
  }

  private hasOnlyZeroValues() {
    return this.multiQuestions
      ? (this.question.value as HomeProfileQuestion[]).every(
          (question: HomeProfileQuestion) => Number(question?.value) === 0
        )
      : Number((this.question as HomeProfileQuestion).value) === 0;
  }

  private buildPayload(question: HomeProfileQuestion): HomeProfileUpdate {
    const value =
      question.dataType === DataType.multiSelect
        ? this.form
            .get(question.key)
            .value.map((controlValue: boolean, i: number) => (controlValue ? question.possibleValues[i].value : null))
            .filter((val) => val)
        : this.form.get(question.key).value;
    return {
      key: `${this.question.category}/${question.key}`,
      value,
    };
  }

  private buildForm(): FormGroup<any> {
    let form = this.fb.group({});
    if (this.multiQuestions) {
      this.multiQuestions.value.forEach(
        (question: HomeProfileQuestion) => (form = this.formControlBuilder(form, question))
      );
    }
    if (this.singleQuestion) {
      form = this.formControlBuilder(form, this.singleQuestion);
    }
    return form;
  }

  private formControlBuilder(form, question: HomeProfileQuestion) {
    if (question.dataType === DataType.multiSelect) {
      const defaultValues: FormControl<boolean>[] = question?.value
        ? question.possibleValues.map(
            (possibleValue: HomeProfileQuestionValue) =>
              new FormControl((question?.value as string[]).some((value) => value === possibleValue.value))
          )
        : question?.possibleValues.map(() => new FormControl(false));
      form.addControl(question.key, new FormArray(defaultValues));
    } else {
      let validators = question?.required ? [Validators.required] : [];
      validators = question?.limit?.min ? [...validators, Validators.min(question?.limit?.min)] : [...validators];
      validators = question?.limit?.max ? [...validators, Validators.max(question?.limit?.max)] : [...validators];
      validators = question?.limit?.min > 0 ? [...validators, leadingZero()] : [...validators];
      form.addControl(question.key, new FormControl(this.getDefaultValue(question), validators));
    }
    return form;
  }

  private getDefaultValue(question) {
    return question.dataType === DataType.number ? question?.value || 0 : question?.value || question?.suggestedValue;
  }

  private closePanelAndResetState() {
    this.scrollFreezer(false);
    this.question = this.singleQuestion = this.multiQuestions = this.form = null;
    this.closeEmitter.emit(null);
  }

  private scrollFreezer(freeze: boolean) {
    const appWrapper: HTMLElement = document.getElementById('app-wrapper');
    if (freeze) {
      appWrapper.style.top = `-${window.scrollY}px`;
      appWrapper.style.position = 'fixed';
    } else {
      const scrollY = appWrapper.style.top;
      appWrapper.removeAttribute('style');
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    }
  }

  private checkForSuggestedValue(
    multiQuestions: HomeProfileMultiQuestions,
    singleQuestion: HomeProfileQuestion
  ): boolean {
    return multiQuestions
      ? multiQuestions?.value?.every((question: HomeProfileQuestion) => 'suggestedValue' in question)
      : 'suggestedValue' in singleQuestion;
  }
}
