import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-ghost-table',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ghost-table.component.html',
  styleUrls: ['./ghost-table.component.scss'],
})
export class GhostTableComponent {
  public numberOfRowsArray: number[] = Array(10);
  @Input() set numberOfRows(value: number) {
    this.numberOfRowsArray = Array(value);
  }
}
