<div class="md:grid md:grid-cols-12 gap-16">
  <!-- ROW 1 -->
  <div class="col-span-4">
    <div class="md:grid md:grid-cols-12 gap-8">
      <div class="col-span-12 mb-8 md:mb-0 xl:col-span-6">
        <ng-content select="[forecastElecConsumptionWidget]"></ng-content>
      </div>
      <div class="col-span-12 mb-8 md:mb-0 xl:col-span-6">
        <ng-content select="[forecastGasConsumptionWidget]"></ng-content>
      </div>
      <div class="col-span-12 mb-8 md:mb-0">
        <ng-content select="[peakConsumptionWidget]"></ng-content>
      </div>
    </div>
  </div>
  <div class="col-span-8 mb-8 md:mb-0">
    <ng-content select="[monthlyConsumptionChartWidget]"></ng-content>
  </div>
  <!-- ROW 1 END -->

  <!-- ROW 2 -->
  <ng-content select="[comparisonElecConsumptionWidget]"></ng-content>

  <ng-content select="[comparisonGasConsumptionWidget]"></ng-content>

  <ng-content select="[disaggregationChartWidget]"></ng-content>
  <!-- ROW 2 END -->

  <ng-content select="[crossSellView]"></ng-content>
</div>
