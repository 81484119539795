/* eslint-disable */
import { Address } from '../../../../shared/models/address.interface';

export interface Countries {
  [key: string]: string;
}

export interface ContactDetails {
  lastName: string;
  firstName: string;
  civility: string;
  birthDate?: string;
  emailLogin?: string;
  email: string;
  language: string;
  phone: string;
  mobile: string;
  address: Address;
  locked: boolean;
  contactName: string;
  companyNumber: string;
  type: ContactType;
  socialReason?: SocialReason; // It is company type
}

export interface ContactDetailsPerReference {
  [reference: string]: ContactDetails;
}

export interface SocialReason {
  code?: string;
  translations?: {
    fr?: string;
    nl?: string;
  };
}

export enum Civility {
  Mr = 'general.contact.civilityOptions.mr',
  Mrs = 'general.contact.civilityOptions.mrs',
  Miss = 'general.contact.civilityOptions.miss',
  MrAndMrs = 'general.contact.civilityOptions.mrAndMrs',
}

export enum ContactType {
  B2B = 'B2B',
  B2C = 'B2C',
  VSE = 'VSE',
  SME = 'SME',
}
