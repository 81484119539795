import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EncryptionService {
  constructor() {}

  /**
   * Encrypt a message.
   * Based on https://stackoverflow.com/questions/33529103/simply-xor-encrypt-in-javascript-and-decrypt-in-java
   */
  public encrypt(message: string, key: string) {
    let encrypted = '';
    for (let i = 0; i < message.length; i++) {
      const value1 = message[i].charCodeAt(0);
      const value2 = key[i].charCodeAt(0);

      // eslint-disable-next-line no-bitwise
      const xorValue = value1 ^ value2;
      let xorValueAsHexString = xorValue.toString(16);

      if (xorValueAsHexString.length < 2) {
        xorValueAsHexString = '0' + xorValueAsHexString;
      }
      encrypted += xorValueAsHexString;
    }
    return encrypted;
  }

  public decrypt(encrypted: string, key: string) {
    let message = '';
    for (let i = 0; i < encrypted.length; i += 2) {
      const hexValue: string = encrypted.substr(i, 2);
      const value1: number = parseInt(hexValue, 16);
      const value2: number = key.charCodeAt(i / 2);

      // eslint-disable-next-line no-bitwise
      const xorValue = value1 ^ value2;
      message += String.fromCharCode(xorValue);
    }

    return message;
  }
}
