<div class="inline-block w-full sm:max-w-4xl text-center mx-auto pt-16 pb-8">
  <h2
    class="font-medium text-2xl text-grey-dark inline-block leading-snug mb-10"
    [innerHTML]="'pages.bindingProcess.step2.unhappyFlow.title' | translate"
  ></h2>
  <p class="text-center mb-8 mt-4">{{ 'pages.bindingProcess.step2.unhappyFlow.contactUs' | translate }}</p>
  <ul class="contact-list unstyle">
    <li class="contact-list__item">
      <a class="contact-list__link" href="">
        <i class="chat"></i>
        <span class="font-medium inline-block">
          {{ 'pages.bindingProcess.step2.unhappyFlow.viaChat' | translate }}
        </span>
      </a>
    </li>
    <li class="contact-list__item">
      <a class="contact-list__link" href="">
        <i class="email"></i>
        <span class="font-medium">{{ 'pages.bindingProcess.step2.unhappyFlow.viaEmail' | translate }}</span>
      </a>
    </li>
  </ul>
</div>
