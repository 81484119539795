import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Countries } from '../../../modules/customer-zone/contact/models/contactDetails.interface';

@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent implements OnInit {
  @Input() control: UntypedFormGroup;

  constructor() {}

  ngOnInit(): void {}
}
