import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  BarChartData,
  CustomChartDataset,
  DashedBorderBarOptions,
  TooltipObject,
  LegendLabelledOptions,
  CustomTooltipOptions,
} from '../../models/chart.interfaces';
import { ChartUnit, ChartColor, CtxFont } from '../../models/chart.enums';
import { ChartOptions, Plugin, ChartData, ScriptableScaleContext, LegendItem, Align } from 'chart.js';
import { ChartTooltipHelperService } from '../../services/chart-tooltip-helper.service';
import Utils from '../../utils/utils';
import Chart from 'chart.js/auto';

@Component({
  selector: 'app-consumption-bar-chart',
  templateUrl: './consumption-bar-chart.component.html',
  styleUrls: ['./consumption-bar-chart.component.scss'],
})
export class ConsumptionBarChartComponent implements OnChanges {
  @Input() barCharDatasets: CustomChartDataset<BarChartData>[] = [];
  @Input() tooltipOptions: CustomTooltipOptions;
  @Input() minScale: number;
  @Input() maxScale: number;
  @Input() legendAlign: Align;

  data: ChartData<'bar', BarChartData[]>;
  plugins: Plugin<'bar', BarChartData>[] = [];
  options: ChartOptions<'bar'>;

  private negativeAxis = false;

  constructor(private readonly tooltipHelperService: ChartTooltipHelperService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.barCharDatasets?.currentValue?.length) {
      this.setChartData();
      this.setChartOptions();
      this.setChartPlugins();
    }
  }

  private setChartData(): void {
    this.data = {
      datasets: [],
    };

    this.negativeAxis = false;

    this.barCharDatasets.forEach((chartDataset: CustomChartDataset<BarChartData>) => {
      const dashedBorderBarOptions: DashedBorderBarOptions = {
        dashedBorder: chartDataset.dashedBorder,
      };
      const legendLabelledOptions: LegendLabelledOptions = {
        legendLabel: chartDataset.legendLabel,
      };

      // check if the datatsets contains negative values
      this.negativeAxis =
        this.negativeAxis || chartDataset.data.findIndex((barChartData: BarChartData) => barChartData.value < 0) > -1;

      this.data.datasets.push({
        data: chartDataset.data,
        label: chartDataset.label,
        backgroundColor: '' + chartDataset.backgroundColor,
        hoverBackgroundColor: '' + chartDataset.hoverBackgroundColor,
        borderColor: chartDataset.borderColor ? '' + chartDataset.borderColor : '' + chartDataset.backgroundColor,
        borderWidth: chartDataset.dashedBorder ? 0 : chartDataset.borderWidth || 1,
        borderRadius: 5,
        borderSkipped: 'bottom',
        ...dashedBorderBarOptions,
        ...legendLabelledOptions,
      });
    });
  }

  private setChartOptions(): void {
    const generateFontColors = () => {
      return this.barCharDatasets[0].data.map((barChartData: BarChartData) =>
        barChartData.fontColor ? barChartData.fontColor : ChartColor.gray
      );
    };

    this.options = {
      maintainAspectRatio: false,
      interaction: {
        mode: 'index',
      },
      parsing: {
        xAxisKey: 'label',
        yAxisKey: 'value',
      },
      plugins: {
        legend: {
          onClick: null,
          position: 'bottom',
          align: this.legendAlign,
          labels: {
            usePointStyle: true,
            pointStyle: 'circle',
            padding: 20,
            boxHeight: 8,
            font: {
              size: 12,
            },
            color: ChartColor.darkGray,
            generateLabels: (chart: Chart) => {
              const datasets = chart.data.datasets as unknown as CustomChartDataset<BarChartData>[];
              if (datasets) {
                return datasets.map<LegendItem>((chartDataset: CustomChartDataset<BarChartData>, index: number) => {
                  const legendLabel = chartDataset.legendLabel;
                  return {
                    datasetIndex: index,
                    text: '  ' + (legendLabel ? legendLabel : chartDataset.label),
                    fillStyle: '' + chart.data.datasets[index].backgroundColor,
                    strokeStyle: '' + chart.data.datasets[index].backgroundColor,
                    hidden: chart.getDatasetMeta(index).hidden,
                  };
                });
              }

              return [];
            },
          },
        },
        tooltip: {
          enabled: false,
          padding: 20,
          external: (tooltipObject: TooltipObject<'bar'>) =>
            this.tooltipHelperService.createTooltip(
              tooltipObject,
              this.tooltipHelperService.getMonthlyConsumptionBarChartTemplate(
                tooltipObject.tooltip,
                this.tooltipOptions
              ),
              'bar'
            ),
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          stacked: true,
          ticks: {
            color: generateFontColors(),
            font: {
              weight: function (context: ScriptableScaleContext) {
                const index = context.index;
                const value = context.chart.config.data.datasets[0].data[index] as unknown as BarChartData;
                return value.bold ? CtxFont.bold : CtxFont.normal;
              },
            },
          },
        },
        y: {
          border: {
            display: false,
          },
          grid: {
            color: (context: ScriptableScaleContext) => {
              return context.tick.value === 0 && this.negativeAxis ? ChartColor.red : ChartColor.silver50;
            },
            lineWidth: 1,
          },
          ...(this.minScale ? { min: this.minScale } : {}),
          ...(this.maxScale ? { min: this.maxScale } : {}),
          ticks: {
            color: (context: ScriptableScaleContext) => {
              return context.tick.value === 0 && this.negativeAxis ? ChartColor.red : ChartColor.gray;
            },
            callback: (val: number | string) => Math.abs(+val) + ChartUnit.separator + ChartUnit.kwh,
          },
        },
      },
      layout: {
        padding: {
          left: 10,
        },
      },
    };
  }

  private setChartPlugins(): void {
    this.plugins = [
      {
        id: 'boxBorderDash',
        afterDatasetsDraw(chart: Chart<'bar'>) {
          const dataset = chart
            .getSortedVisibleDatasetMetas()
            .find((dataset) => (dataset.controller.getDataset() as DashedBorderBarOptions).dashedBorder);

          if (!dataset) {
            return;
          }

          const boxBorderDash = [3, 3];
          const boxBorderWidth = 1;

          dataset.data.forEach((element: any) => {
            if (element.height) {
              const ctx = chart.ctx;
              const horizontal = element.horizontal;
              const skip = element.borderSkipped;
              const borderRadius = (chart.config.data.datasets[0].borderRadius as number) - 1;

              const { left, right, top, bottom } = Utils.getArea(element, boxBorderWidth, horizontal);

              ctx.beginPath();
              ctx.lineWidth = boxBorderWidth;
              ctx.strokeStyle = element.options.borderColor;
              ctx.setLineDash(boxBorderDash);

              Utils.drawLine(ctx, { x: left + borderRadius, y: top + 1 });
              Utils.drawLine(ctx, { x: right - borderRadius, y: top + 1 });
              Utils.drawLine(ctx, { x: right - 1, y: top + borderRadius }, skip.top);
              Utils.drawLine(ctx, { x: right - 1, y: bottom }, skip.right);
              Utils.drawLine(ctx, { x: right - 1, y: bottom }, skip.right);
              Utils.drawLine(ctx, { x: left + 1, y: bottom }, skip.bottom);
              Utils.drawLine(ctx, { x: left + 1, y: top + borderRadius }, skip.left);
              Utils.drawLine(ctx, { x: left + borderRadius, y: top + 1 }, skip.left);

              ctx.stroke();
              ctx.save();
            }
          });
        },
      },
    ];
  }
}
