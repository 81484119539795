import { Component, OnInit } from '@angular/core';
import { MainFacade } from '@app/core/facade/main.facade';

@Component({
  selector: 'app-error-access',
  templateUrl: './error-access.component.html',
})
export class ErrorAccessComponent implements OnInit {
  constructor(private facade: MainFacade) {}

  ngOnInit(): void {}

  backToConsumptions() {
    this.facade.utils.redirectTo('/invoices/advance-payment');
  }
}
