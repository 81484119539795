import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-basic-tpl',
  templateUrl: './basic-tpl.component.html',
  styleUrls: ['./basic-tpl.component.scss'],
})
export class BasicTplComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
