import { HttpClient } from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from './app-config';
import { DeviceService } from '@app/core/service/device.service';

const httpLoaderFactory = (http: HttpClient, conf: AppConfig) =>
  new TranslateHttpLoader(http, conf.config.path.languageAssets, `.json?version=${conf.config.app.version}`);

export const initializer =
  (
    http: HttpClient,
    keycloak: KeycloakService,
    conf: AppConfig,
    translate: TranslateService,
    deviceService: DeviceService
  ): (() => Promise<any>) =>
  (): Promise<any> =>
    new Promise(async (resolve, reject) => {
      // at first load external config file
      // THIS IS VERY IMPORTANT, because config values are used for external library initialization
      await conf.load();

      // configure translate loader
      translate.currentLoader = httpLoaderFactory(http, conf);

      const shouldInitializeKeycloak = !window.location.href.includes('/webview/');
      if (!shouldInitializeKeycloak) {
        resolve(true);
        return;
      }

      // configure Kecloak
      try {
        await keycloak.init({
          config: conf.config.keycloak,
          initOptions: {
            onLoad: 'check-sso',
            checkLoginIframe: false,
          },
          enableBearerInterceptor: true,
          bearerExcludedUrls: [
            '/assets',
            'excluded=true',
            'totalenergies.be/api/v1',
            'totalenergies.be/fr/jsonapi',
            'totalenergies.be/nl/jsonapi',
            conf.config.path.languageAssets,
          ],
        });
        resolve(true);
      } catch (error) {
        console.log(error);
        if (conf.config) {
          window.location.href = conf.config.url.base.fr;
        }
        reject(error);
      }
    });
