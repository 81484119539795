import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Site } from '../../../user/models/site.interface';
import { Contract } from '../../../contracts/models/contract.interface';
import { catchError, map, Observable, of, startWith } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContractDetailComponent } from '../../../contracts/components/modals/contract-detail/contract-detail.component';
import { ListServicesComponent } from '../list-services/list-services.component';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ServiceEligibility } from '../../../contracts/models/service-eligibility.interface';
import { Product } from '../../../user/models/product.interface';
import { MainFacade } from '../../../../../core/facade/main.facade';
import { AsyncData } from '../../../../../shared/models/async.interface';
import { ContractDtoCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Component({
  selector: 'app-contracts-icons',
  templateUrl: './contracts-icons.component.html',
  styleUrls: ['./contracts-icons.component.scss'],
})
export class ContractsIconsComponent implements OnInit, OnChanges {
  @Input() activeSite: Site;
  @Input() services: ServiceEligibility[];
  public lang: string;

  constructor(private facade: MainFacade, private translate: TranslateService, private modalService: NgbModal) {
    this.lang = this.translate.currentLang;
  }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => (this.lang = event.lang));
  }

  ngOnChanges() {
    if (this.services) {
      this.services = this.services.filter(
        (service: ServiceEligibility) =>
          (service.commercialLinks && service.commercialLinks[this.lang]) ||
          (service.description && service.description[this.lang])
      );
    }
  }

  public openModal(product: Product) {
    let contract$: Observable<AsyncData<Contract>> = this.facade
      .loadContracts(this.facade.state$.value.reference, this.activeSite, true)
      .pipe(
        map((contracts: ContractDtoCuzoApi[]) => ({
          loading: false,
          data: contracts.find(
            (contract: ContractDtoCuzoApi) =>
              contract.type === product.type && contract.deliveryPointReference === product.ean
          ),
        })),
        startWith({ loading: true }),
        catchError((error) => of({ loading: false, error }))
      );
    const modalRef = this.modalService.open(ContractDetailComponent, { size: 'lg' });
    const data = { contract$, site$: of(this.activeSite) };
    modalRef.componentInstance.data = data;
  }

  public openServices() {
    const modalRef = this.modalService.open(ListServicesComponent, { size: 'xl', windowClass: 'grid' });
    const data = { services: this.services, lang: this.lang };
    modalRef.componentInstance.data = data;
  }
}
