import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { BindingProcessService } from '../../services/binding-process.service';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { PrivateContactInfo } from '../../models/privateContactInfo.interface';
import { CheckVerificationCodePayload, GetVerificationCodePayload } from '../../models/verification-code.interface';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../customer-zone/user/services/user/user.service';
import { Alert } from '@app/shared/components/alert/alert.interface';
import { ReferenceService } from '../../../customer-zone/user/services/reference/reference.service';
import { UtilsService } from '@app/shared/utils/utils.service';
import { takeUntil } from 'rxjs';
import { Observable, Subject } from 'rxjs';
import { ReferenceDetails } from '../../../customer-zone/user/models/reference.interface';
import { ToastrService } from 'ngx-toastr';
import { AnalyticsTrackingService } from '@app/core/analytics/tracking/analytics-tracking.service';
import { SalesforceService } from '@app/shared/services/salesforce/salesforce.service';
import { MainFacade } from '@app/core/facade/main.facade';
import { AlertType } from '@app/shared/components/alert/alert.interface';

@Component({
  selector: 'app-references',
  templateUrl: './binding-process.component.html',
  styleUrls: ['./binding-process.component.scss'],
})
export class BindingProcessComponent implements OnInit, OnDestroy {
  public step = 1;
  public referenceError = false;
  public codeError = false;
  public sendingError: Alert | null = null;
  public bindingError: Alert | null = null;
  public privateContactInfo: PrivateContactInfo = null;
  public reference = '';
  public sendingMode = '';
  public currentLanguage: string;
  public notifier = new Subject<void>();
  public referencesDetails$: Observable<ReferenceDetails[]>;
  public isAcceptedReference = true;

  constructor(
    public facade: MainFacade,

    public bindingProcessService: BindingProcessService,
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public keycloakService: KeycloakService,
    public translate: TranslateService,
    public userService: UserService,
    private referenceService: ReferenceService,
    private utilsService: UtilsService,
    private toastrService: ToastrService,
    private cdr: ChangeDetectorRef,
    private analyticsService: AnalyticsTrackingService,
    private salesforceService: SalesforceService
  ) {}

  ngOnInit() {
    this.analytics();
    this.facade.utils.setPageTitle('pages.bindingProcess.pageTitle');

    this.retrieveDataFromQueryparams();
    this.referencesDetails$ = this.referenceService.getReferencesDetails();
    this.currentLanguage = this.facade.translate.currentLang;
    this.facade.translate.onLangChange.pipe(takeUntil(this.notifier)).subscribe((event: LangChangeEvent) => {
      this.currentLanguage = event.lang;
      this.analytics();
    });
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }

  public getPrivateInfoFromReference(params: { reference: string; sendingMode?: string }, step: number) {
    this.bindingProcessService.getPrivateInfoFromReference(params.reference).subscribe(
      (privateContactInfo: PrivateContactInfo) => {
        this.isAcceptedReference = true;
        this.referenceError = false;
        this.privateContactInfo = privateContactInfo;
        this.reference = params.reference;
        this.setQueryParams(params);
        this.step = step;
        this.bindingError = null;
      },
      (err) => {
        if (err.status === 400) {
          this.referenceError = true;
          this.step = 1;
        } else {
          this.toastrService.error(this.translate.instant('errorMessages.serverError'));
        }
      }
    );
  }

  public setQueryParams(params) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: params,
      skipLocationChange: false,
    });
  }

  public retrieveDataFromQueryparams() {
    this.activatedRoute.queryParams.forEach((queryParams) => {
      if (!this.reference || this.reference !== queryParams.reference) {
        if (queryParams.reference && (queryParams.sendingMode === 'PHONE' || queryParams.sendingMode === 'EMAIL')) {
          this.getPrivateInfoFromReference(
            {
              reference: queryParams.reference,
              sendingMode: queryParams.sendingMode,
            },
            3
          );
          this.reference = queryParams.reference;
        } else if (queryParams.reference) {
          this.getPrivateInfoFromReference({ reference: queryParams.reference }, 2);
          this.reference = queryParams.reference;
        }
      }
    });
  }

  public sendVerificationCode(sendingMode: string) {
    this.sendingMode = sendingMode;
    const keycloakInstance = this.keycloakService.getKeycloakInstance();
    const payload: GetVerificationCodePayload = {
      sendingMode,
      reference: this.reference,
      loginEmail: keycloakInstance.idTokenParsed['email'],
      token: keycloakInstance.tokenParsed.sub,
    };
    this.bindingProcessService.sendVerificationCode(payload).subscribe(
      () => {
        this.sendingError = null;
        this.setQueryParams({ reference: this.reference, sendingMode });
        this.step = 3;
        this.cdr.detectChanges();
      },
      (error) => {
        if (error.status === 403) {
          this.isAcceptedReference = false;
          this.sendingError = {
            type: AlertType.error,
            content: {
              message: this.translate.instant('errorMessages.invalidReference'),
            },
          };
        } else {
          this.isAcceptedReference = true;
          this.sendingError = {
            type: AlertType.error,
            content: {
              message: this.translate.instant('errorMessages.serverError'),
            },
          };
        }
      }
    );
  }

  public checkVerificationCode(code: string) {
    const token: string = this.keycloakService.getKeycloakInstance().tokenParsed.sub;
    const payload: CheckVerificationCodePayload = {
      code,
      reference: this.reference,
    };
    this.codeError = false;
    this.bindingProcessService.checkVerificationCode(payload, token).subscribe(
      () => {
        this.utilsService.deleteCookies();
        this.salesforceService.deleteCookie();
        this.userService.resetUserProfile();
        this.referenceService.updateActiveReference(this.reference);
        window.localStorage.setItem('binding', this.reference);
        this.resetValues();
        this.router.navigate(['/']);
      },
      () => (this.codeError = true)
    );
  }

  public errorHandler(errorVariable: string, errorStatus: boolean) {
    this[errorVariable] = errorStatus;
  }

  public goBack() {
    this.step--;
    this.sendingError = null;
    const params = this.step === 2 ? { reference: this.reference } : {};
    this.setQueryParams(params);
  }

  private resetValues() {
    this.step = 1;
    this.codeError = false;
    this.reference = '';
  }

  private analytics() {
    this.facade.analytics.push(
      {
        event: 'pageview',
        page: {
          phase: 'care',
          category: 'cuzo',
          subCategory: 'Binding - cuzo',
        },
      },
      {
        reference: this.facade?.state$?.value?.reference,
        site: this.facade?.state$?.value?.activeSite,
        accessRights: this.facade?.state$?.value?.accessRights,
      }
    );
  }
}
