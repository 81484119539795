import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ContactService } from '../../services/contact/contact.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactDetails } from '../../models/contactDetails.interface';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-address',
  templateUrl: './change-address.component.html',
  styleUrls: ['./change-address.component.scss'],
})
export class ChangeAddressComponent implements OnInit {
  @Input() contactDetails: ContactDetails;
  @Input() reference: string;
  public addressForm: UntypedFormGroup = new UntypedFormGroup({
    street: new UntypedFormControl('', [Validators.required]),
    streetNumber: new UntypedFormControl('', [Validators.required]),
    boxNumber: new UntypedFormControl('', []),
    zipCode: new UntypedFormControl('', [Validators.required]),
    city: new UntypedFormControl('', [Validators.required]),
    country: new UntypedFormControl('', []),
  });

  constructor(
    private contactService: ContactService,
    public activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.addressForm.controls.street.setValue(this.contactDetails.address.street);
    this.addressForm.controls.streetNumber.setValue(this.contactDetails.address.streetNumber);
    this.addressForm.controls.boxNumber.setValue(this.contactDetails.address.boxNumber);
    this.addressForm.controls.zipCode.setValue(this.contactDetails.address.zipCode);
    this.addressForm.controls.city.setValue(this.contactDetails.address.city);
    this.addressForm.controls.country.setValue(this.contactDetails.address.country);
  }

  handleSubmit() {
    const contactDetails = Object.assign({}, this.contactDetails);
    contactDetails.address = {
      street: this.addressForm.get('street').value,
      streetNumber: this.addressForm.get('streetNumber').value,
      boxNumber: this.addressForm.get('boxNumber').value,
      zipCode: this.addressForm.get('zipCode').value,
      city: this.addressForm.get('city').value,
      country: this.addressForm.get('country').value,
    };
    this.contactService.updateContactDetails(this.reference, contactDetails).subscribe(
      (result) => {
        this.contactDetails.locked = true;
        this.toastrService.success(this.translate.instant('general.success'));
      },
      (error) => {
        this.toastrService.error(this.translate.instant('errorMessages.serverError'));
      }
    );
    this.activeModal.close();
  }
}
