import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ApiError, ApiResponse } from '../../shared/models/api.inteface';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  handleError<T>(error: HttpErrorResponse): Observable<ApiResponse<T>> {
    let errorMessage: ApiError = { errorMessage: 'An unknown error occurred!' };
    if (error.error instanceof ErrorEvent) {
      errorMessage.errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage.errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      errorMessage.errorStatus = error.status;
    }
    return of({ error: errorMessage, loading: false });
  }
}
