import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, shareReplay, tap } from 'rxjs';
import { Building, BuildingPerReference } from '../../models/building.interface';

@Injectable({
  providedIn: 'root',
})
export class BuildingService {
  private building: BuildingPerReference = {};
  private building$: Observable<Building>;
  private retrieveBuildingInProgress = false;

  constructor(private http: HttpClient) {}

  public updateBuilding(reference: string, siteId: string, building: Building): void {
    if (building) {
      this.building[reference][siteId] = building;
    }
  }

  public getBuilding(reference: string, siteId: string): Observable<Building> {
    if (
      this.building &&
      this.building[reference] &&
      this.building[reference][siteId] &&
      !this.checkForUpdateInProgress(reference, siteId)
    ) {
      return of(this.building[reference][siteId]);
    }
    return this.getBuildingFromApi(reference, siteId);
  }

  public checkForUpdateInProgress(reference: string, siteId: string) {
    return this.building[reference][siteId].locked;
  }

  public postBuildingToAPI(reference: string, siteId: string, payload: any) {
    return this.http.put<Building>(`/v1/customers/${reference}/sites/${siteId}/building`, payload).pipe(
      catchError((error) => (error.status === 404 ? of({ locked: true, ...payload }) : throwError(error))),
      tap((building: Building) => {
        if (!this.building[reference]) {
          this.building[reference] = {};
        }
        this.building[reference][siteId] = { locked: true, ...payload };
      })
    );
  }

  private getBuildingFromApi(reference: string, siteId: string): Observable<Building> {
    if (this.building$ && this.retrieveBuildingInProgress) {
      return this.building$;
    }
    this.retrieveBuildingInProgress = true;
    this.building$ = this.http.get<Building>(`/v1/customers/${reference}/sites/${siteId}/building`).pipe(
      catchError((error) => {
        if (!this.building[reference]) {
          this.building[reference] = {};
        }
        return of(this.building[reference][siteId]);
      }),
      tap(
        (building: Building) => {
          if (!this.building[reference]) {
            this.building[reference] = {};
          }
          this.building[reference][siteId] = building;
          this.retrieveBuildingInProgress = false;
        },
        () => (this.retrieveBuildingInProgress = false)
      ),
      shareReplay(1)
    );
    return this.building$;
  }
}
