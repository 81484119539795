<div class="card te-border-gradient-home">
  <span class="mb-2 text-grey-dark60 font-medium text-sm">
    {{ 'components.homeProfile.sidebar.progress.boxTitle' | translate }}
  </span>
  <h3>{{ 'components.homeProfile.sidebar.progress.title' | translate }}</h3>
  <div class="my-8 text-sm">
    {{ 'components.homeProfile.sidebar.progress.description' | translate }}
  </div>
  <div class="bg-grey-light border border-grey rounded-2xl pt-6 pb-8 px-8">
    <span class="flex justify-between font-bold text-grey-dark text-[1.3rem] pb-4">
      <span>{{ 'components.homeProfile.sidebar.progress.progressBar' | translate }}</span>
      <span>{{ progressValue / 100 | percent }}</span>
    </span>
    <app-progress-bar [progressValue]="progressValue"></app-progress-bar>
  </div>
  <a class="btn btn-secondary mt-8" *ngIf="showEditButton" routerLink="/consumptions/home-profile">
    {{ 'components.homeProfile.sidebar.progress.editButton' | translate }}
  </a>
</div>
