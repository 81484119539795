import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { InvoiceModelV2CuzoApi } from '@app/shared/models/cuzo-be-contract';
import { EnergyType } from '@app/modules/customer-zone/consumption/models/consumption.interface';

@Component({
  selector: 'app-invoice-type',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './invoice-type.component.html',
  styleUrls: ['./invoice-type.component.scss'],
})
export class InvoiceTypeComponent implements OnInit {
  @Input() invoice: InvoiceModelV2CuzoApi;
  @Input() showInvoiceNumber: boolean = false;
  readonly EnergyType = EnergyType;

  ngOnInit(): void {}
}
