import { Component, Input, OnChanges } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PaymentPlanCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Component({
  selector: 'app-payment-plan-progress',
  standalone: true,
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
  imports: [CurrencyPipe, TranslateModule],
})
export class PaymentPlanProgressComponent implements OnChanges {
  @Input() paymentPlan: PaymentPlanCuzoApi;
  progress: number = 0;
  progressKey: string;

  ngOnChanges(): void {
    this.progress = Math.round((this.paymentPlan?.paidSlices / this.paymentPlan?.totalSlices) * 100);
    this.progressKey = this.getProgressKey(this.progress);
  }

  private getProgressKey(progress: number): string {
    if (progress === 0) {
      return 'start';
    }
    return progress > 50 ? 'end' : 'ongoing';
  }
}
