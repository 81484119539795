<div *ngIf="energyType" class="{{ padding ? 'pt-8 pr-8 pb-8 pl-8' : '' }}">
  <div *ngIf="padding" class="mb-8">&nbsp;</div>
  <div class="text-center card-primary-small md:text-left">
    <div class="md:flex items-center">
      <div class="md:flex items-center">
        <img src="./assets/img/{{ energyType | lowercase }}.svg" class="inline-block w-24 h-24 mb-8 md:mb-0" />
        <div class="w-full align-middle inline-block text-center md:text-left md:ml-12 md:pr-20 md:w-auto">
          <h5 class="text-2xl font-medium">
            {{ 'components.noConsumption.explanation.' + (energyType | lowercase) | translate }}
          </h5>
          <span class="inline-block mt-4">{{ 'components.noConsumption.solution' | translate }}</span>
        </div>
      </div>
      <div class="mt-12 flex-none md:border-l md:border-grey md:pl-20 md:mt-0">
        <a
          [analyticsEvent]="'myEnergyMeterReading:cta-click:become-client'"
          [href]="'general.links.simulator' | translate"
          target="_blank"
          class="btn btn-primary font-bold inline-block"
        >
          {{ 'components.noConsumption.cta' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
