import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { KeycloakService, KeycloakAuthGuard } from 'keycloak-angular';
import { UtilsService } from '../../shared/utils/utils.service';
import { UserInfo } from '../../modules/customer-zone/user/models/userInfo.interface';
import { UserService } from '../../modules/customer-zone/user/services/user/user.service';
import jwt_decode from 'jwt-decode';
import { SalesforceService } from '@app/shared/services/salesforce/salesforce.service';
import { AppConfig } from '@app/shared/utils/app-config';

// noinspection JSAnnotator
@Injectable()
export class ClientAuthguard extends KeycloakAuthGuard {
  constructor(
    protected router: Router,
    protected keycloakService: KeycloakService,
    private utilsService: UtilsService,
    private userService: UserService,
    private salesforceService: SalesforceService,
    private conf: AppConfig
  ) {
    super(router, keycloakService);
  }

  // noinspection JSAnnotator
  isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve) => {
      if (!this.authenticated) {
        this.utilsService.deleteCookies();
        this.salesforceService.deleteCookie();
        let language = window.location.pathname.split('/')[1];
        language = language && language.includes('?') ? language.substring(0, language.indexOf('?')) : language;
        this.keycloakService.login({ prompt: 'login', locale: language });
        return;
      }

      this.checkCookieValidity(this.keycloakService.getKeycloakInstance().tokenParsed.sub);
      this.updateCookieInfo(this.keycloakService.getKeycloakInstance());
      this.setUserInfo(this.keycloakService.getKeycloakInstance().token);

      const requiredRoles = route.data.roles;
      if (!requiredRoles || requiredRoles.length === 0) {
        return resolve(true);
      } else {
        if (!this.roles || this.roles.length === 0) {
          resolve(false);
        }
        let granted = false;
        for (const requiredRole of requiredRoles) {
          if (this.roles.indexOf(requiredRole) > -1) {
            granted = true;
            break;
          }
        }
        resolve(granted);
      }
    });
  }

  public checkCookieValidity(keycloakToken: string) {
    const cookieName: string = this.utilsService.activeInfoCookieName;
    const cookie: any = this.utilsService.getCookieValue(cookieName);
    if (cookie.uuid && cookie.uuid !== keycloakToken) {
      this.utilsService.deleteCookies();
      this.salesforceService.deleteCookie();
    }
  }

  public updateCookieInfo(keycloakInstance: any) {
    const cookieName: string = this.utilsService.activeInfoCookieName;
    let cookie: any = this.utilsService.getCookieValue(cookieName);

    if (!cookie || !cookie.uuid) {
      cookie = cookie || {};
      cookie.uuid = keycloakInstance.tokenParsed.sub;
    }

    const keycloakCookie = keycloakInstance.token;
    this.utilsService.setCookieValue(cookieName, cookie);

    const expiredDate = new Date();
    expiredDate.setDate(expiredDate.getDate() + this.conf.config.cookieKeycloakExpiredDays);

    this.utilsService.setCookieValue('KEYCLOAK', keycloakCookie, expiredDate, this.conf.config.cookieRootDomain);
  }

  public setUserInfo(token: string): void {
    const userInfo: UserInfo = this.getUserInfo(token);
    this.userService.updateUserInfo(userInfo);
    this.salesforceService.setSalesforceChatData(userInfo);
  }

  private getUserInfo(token: string): UserInfo {
    const decodedToken: any = jwt_decode(token);
    return {
      firstName: decodedToken.given_name,
      lastName: decodedToken.family_name,
      email: decodedToken.email,
    };
  }
}
