<div
  class="py-8 px-8 sm:px-16 sm:-mx-16 -mx-8 -mt-8 sm:-mt-12 bg-success-lighter/5 text-success-lighter leading[2.6rem]"
  [innerHTML]="'components.consumption.index.monthlyInstallment.already_ok.content' | translate"
></div>
<div class="flex flex-wrap flex-col sm:flex-row sm:justify-between sm:gap-4 mt-10 mb-7">
  <div class="rounded-2xl sm:grow sm:basis-2/5 border border-solid border-grey">
    <div class="text-grey-dark relative p-8">
      <div class="flex gap-x-8 sm:gap-x-16">
        <div>
          <span class="text-grey-dark60 font-medium text-sm">
            {{ 'components.consumption.index.monthlyInstallment.currentInstallment' | translate }}
          </span>
          <br />
          <span class="text-2xl font-medium">
            {{ lastAdvanceAssessment.currentMonthlyDeposit | currency: 'EUR':'symbol':'1.2-2' }}
          </span>
        </div>
      </div>
    </div>
  </div>
  <div
    class="flex flex-row sm:flex-col mt-4 sm:mt-0 items-center sm:items-start rounded-2xl sm:grow sm:basis-2/5 border border-solid border-grey relative p-8 bg-success-lighter/5"
  >
    <span class="text-grey-dark60 font-medium text-sm">
      {{ 'components.consumption.index.monthlyInstallment.settlementInvoice' | translate }}
    </span>
    <span class="text-2xl ml-10 sm:ml-0 font-medium text-success-lighter">
      {{ 0.0 | currency: 'EUR':'symbol':'1.2-2' }}
    </span>
  </div>
</div>

<button (click)="continue()" class="btn btn-primary w-full sm:w-auto font-bold inline-block mt-4">
  {{ 'components.consumption.index.monthlyInstallment.already_ok.btn' | translate }}
</button>
