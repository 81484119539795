import { Injectable } from '@angular/core';
import { BehaviorSubject, finalize, Observable, of, share, take, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Content, Contents } from '@app/shared/models/content.interface';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  activeContent$ = new BehaviorSubject<Content>(null);
  content: Contents | null = {};
  cacheObservable: Observable<Content>;

  constructor(private http: HttpClient) {}

  getActiveContent() {
    return this.activeContent$;
  }

  getContent(reference: string): Observable<Content> {
    let observable: Observable<Content>;
    if (this.content && this.content[reference]) {
      this.activeContent$.next(this.content[reference]);
      observable = of(this.content[reference]);
    } else if (this.cacheObservable) {
      observable = this.cacheObservable;
    } else {
      this.cacheObservable = this.getContentFromAPI(reference).pipe(
        share(),
        finalize(() => (this.cacheObservable = null))
      );
      observable = this.cacheObservable;
    }
    return observable;
  }

  private getContentFromAPI(reference: string): Observable<Content> {
    return this.http.get<any>(`/v1/customers/${reference}/content`).pipe(
      take(1),
      tap((m) => {
        this.content[reference] = m;
        this.activeContent$.next(m);
      })
    );
  }
}
