import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-estimated-indexes',
  templateUrl: './estimated-indexes.component.html',
  styleUrls: ['./estimated-indexes.component.scss'],
})
export class EstimatedIndexesComponent implements OnInit {
  @Input() indexes: any;
  public active = 1;

  constructor() {}

  ngOnInit(): void {}
}
