<div class="switch-toggle" [formGroup]="parentForm">
  <input id="{{ consent.code + '-false' }}" value="false" type="radio" formControlName="{{ consent.code }}" />
  <label for="{{ consent.code + '-false' }}">OFF</label>

  <input
    id="{{ consent.code + '-undefined' }}"
    disabled
    value="undefined"
    type="radio"
    formControlName="{{ consent.code }}"
  />
  <label for="{{ consent.code + '-undefined' }}">N/A</label>

  <input id="{{ consent.code + '-true' }}" value="true" type="radio" formControlName="{{ consent.code }}" />
  <label for="{{ consent.code + '-true' }}">ON</label>
  <span class="toggler" (click)="updateValue(parentForm.get(consent.code))"></span>
  <span class="background"></span>
</div>
