import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-consumption',
  templateUrl: './no-consumption.component.html',
  styleUrls: ['./no-consumption.component.scss'],
})
export class NoConsumptionComponent implements OnInit {
  @Input() energyType: string;
  @Input() padding?: boolean = true;

  constructor() {}

  ngOnInit(): void {}
}
