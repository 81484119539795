import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { LangSwitcherType } from './lang-switcher';

@Component({
  selector: 'app-lang-switcher',
  templateUrl: './lang-switcher.component.html',
  styleUrls: ['./lang-switcher.component.scss'],
})
export class LangSwitcherComponent {
  @Input() type?: LangSwitcherType = LangSwitcherType.dropDown;
  @Output() switchLangEvent?: EventEmitter<string> = new EventEmitter<string>();
  readonly langSwitcherType = LangSwitcherType;
  public languages: string[];
  public lang: string;

  constructor(private translate: TranslateService) {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => (this.lang = event.lang));
    this.languages = this.translate.getLangs();
  }

  switchLang(lang: string) {
    this.translate.use(lang);
    this.switchLangEvent.emit(lang);
  }
}
