<div class="relative h-full" *ngIf="usage$ | observableLoadingError | async as usage">
  <ng-container *ngIf="usage.value">
    <div class="card-primary-small relative !pt-6 min-w-[24.6rem]">
      <div class="flex flex-row justify-end md:items-center gap-4">
        <app-toggle-switch
          class="z-0"
          *ngIf="showEnergyToggle"
          [options]="{
            id: 'disaggregation-energy-switch',
            on: {
              styleClass: 'peer-checked:bg-blue-dark peer-checked:border-blue-dark',
              svgUrl: './assets/img/toggle/gas.svg'
            },
            off: {
              styleClass: 'bg-blue-light border-blue-light',
              svgUrl: './assets/img/toggle/electricity.svg'
            }
          }"
          [checked]="activeEnergyType !== EnergyType.ELECTRICITY"
          (toggleSwitched)="toggleEnergy($event)"
        ></app-toggle-switch>
        <img
          *ngIf="!showEnergyToggle"
          src="./assets/img/{{ activeEnergyType | lowercase }}.svg"
          width="35"
          height="35"
          [alt]="'general.product.electricity' | translate"
        />
        <ng-container *ngIf="(isMobile$ | async) === false">
          <app-tooltip
            *ngIf="meterConfig !== MeterConfig.basic"
            [tooltip]="'components.widgets.disaggreagtionChart.tooltip.' + meterConfig"
          ></app-tooltip>
          <app-warning-tooltip
            *ngIf="meterConfig === MeterConfig.basic"
            [tooltip]="
              'components.widgets.disaggreagtionChart.tooltip.' +
              (activeEnergyType === EnergyType.ELECTRICITY ? meterConfig : 'gas')
            "
            [warning]="'components.widgets.disaggreagtionChart.tooltipWarning'"
          ></app-warning-tooltip>
        </ng-container>
      </div>
      <div class="md:mx-7 mb-4">
        <h3 class="mb-2 text-2xl font-medium text-dark-grey leading-snug">
          {{ 'components.widgets.disaggreagtionChart.title.' + activeEnergyType.toLowerCase() | translate }}
        </h3>
        <div class="text-grey-dark60 text-sm font-medium">
          {{ 'components.widgets.disaggreagtionChart.period' | translate }}
          ({{
            currentPeriod.toDate() | date : 'MMMM y' : undefined : facade.translate.currentLang + '-BE' | titlecase
          }})
        </div>
      </div>
      <ng-container *ngIf="(isMobile$ | async) === false">
        <app-doughnut-chart
          *ngIf="doughnutChartDataset"
          [doughnutChartDataset]="doughnutChartDataset"
          styleClass="h-[20rem]"
        ></app-doughnut-chart>
      </ng-container>

      <ng-container *ngIf="(isMobile$ | async) === true">
        <div class="flex items-end justify-stretch h-[14rem] w-full text-white text-4xl mt-4">
          <app-disaggregation-podium-platform
            order="3"
            [percentage]="topCategories[2].percentage"
            [category]="topCategories[2].category"
            [styleClass]="activeEnergyType === EnergyType.ELECTRICITY ? 'bg-[#ADDDFC]' : 'bg-blue-dark30'"
          ></app-disaggregation-podium-platform>
          <app-disaggregation-podium-platform
            order="1"
            [percentage]="topCategories[0].percentage"
            [category]="topCategories[0].category"
            [styleClass]="activeEnergyType === EnergyType.ELECTRICITY ? 'bg-[#60C0FF]' : 'bg-blue-dark'"
          ></app-disaggregation-podium-platform>
          <app-disaggregation-podium-platform
            order="2"
            [percentage]="topCategories[1].percentage"
            [category]="topCategories[1].category"
            [styleClass]="activeEnergyType === EnergyType.ELECTRICITY ? 'bg-[#ADDDFC]' : 'bg-blue-dark30'"
          ></app-disaggregation-podium-platform>
        </div>
      </ng-container>

      <div class="text-center mt-4" *ngIf="usage">
        <button (click)="openPopupDetails()" target="_blank" class="inline-block text-primary font-bold">
          {{ 'components.widgets.disaggreagtionChart.moreInfo' | translate }}
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="usage.loading">
    <app-loader-widget></app-loader-widget>
  </ng-container>

  <ng-container *ngIf="usage.error">
    <ng-container *ngIf="usage?.error?.status === 428; else dataError">
      <app-no-data-widget>
        <span class="inline-block w-full">
          {{ 'components.widgets.noData.label' | translate }}
        </span>
        <a class="text-primary" routerLink="/consumptions/home-profile">
          {{ 'components.widgets.noData.updateProfile' | translate }}
        </a>
      </app-no-data-widget>
    </ng-container>
  </ng-container>

  <ng-template #dataError>
    <app-error-widget>
    </app-error-widget>
  </ng-template>
</div>

<app-usage-categories-popup
  *ngIf="showDisaggrationDetails && selectedEnergyUsage"
  [contracts]="contracts"
  [activeContract]="activeContract"
  [currentUsage]="selectedEnergyUsage"
  (closeEmitter)="showDisaggrationDetails = false"
>
</app-usage-categories-popup>
