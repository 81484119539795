import { AbstractControl, ValidatorFn } from '@angular/forms';
import moment from 'moment';

export enum DateParseFormat {
  iso = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]', // "[Z]" represents static character "Z", because "Z" without brackets represents offset from UTC in dayjs
  default = 'DD/MM/YYYY',
  withDashes = 'DD-MM-YYYY',
}

export function dateRangeValidator(minDate: moment.Moment, maxDate: moment.Moment): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const controlDate = moment(control.value, 'YYYY-MM-DD');

    if (!controlDate.isValid()) {
      return { invalidDate: 'Date is not valid' };
    }

    if (controlDate.isBefore(minDate)) {
      return { dateTooEarly: 'Date is before the minimum allowed date' };
    }

    if (controlDate.isAfter(maxDate)) {
      return { dateTooLate: 'Date is after the maximum allowed date' };
    }

    return null; // Date is within the range
  };
}

export function dateFormatHelper(value, data, keyCode): string {
  if (typeof value !== 'undefined') {
    let day;
    const length = value.length;
    const numbersRegex = /^[\d\/-]+$/;

    if (numbersRegex.test(data)) {
      switch (length) {
        case 1:
          if (data > 3) {
            value = [0, data, '/'].join('');
          } else if (data === '/' || data === '-') {
            value = value.substring(0, length - 1);
          }
          break;

        case 2:
          day = value.slice(0, 3);
          if (day[1] === '/' || day[1] === '-') {
            if (day[0] !== '0') {
              value = [0, day[0], '/'].join('');
            } else {
              value = value.substring(0, length - 1);
            }
          } else if (value.charAt(0) === '0' && data === '0') {
            value = value.substring(0, length - 1);
          } else if (day > 31) {
            value = value.substring(0, length - 2);
          } else if (data !== '/' && data !== '-') {
            value += '/';
          }
          break;

        case 4:
          if (data === '/' || data === '-') {
            value = value.substring(0, length - 1);
          } else if (data >= 2 && data <= 9) {
            value = [value.slice(0, 3), 0, data, '/'].join('');
          }
          break;

        case 5:
          if (data === '/' || data === '-') {
            if (value.charAt(3) !== '0') {
              const m = value.slice(3, 5);
              value = value.substring(0, length - 2);
              value = [value, 0, m[0], '/'].join('');
            } else {
              value = value.substring(0, length - 1);
            }
          } else if (value.charAt(3) === '0' && data === '0') {
            value = value.substring(0, length - 1);
          } else {
            value = value + '/';
          }
          break;

        case 7:
          if (data === '/' || data === '-') {
            value = value.substring(0, length - 1);
          }
          break;

        case length > 10:
          value = value.substring(0, length - 1);
          break;
      }
    } else {
      if (typeof data !== 'undefined' && data !== 'Delete' && data !== 'Backspace' && keyCode !== 'KeyV') {
        value = value.substring(0, length - 1);
      }
    }
    return value;
  }
}
