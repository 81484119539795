<if-meter-selection
  *ngIf="step === 'meterSelection'"
  [contracts]="contracts"
  [control]="control.get('meterSelection')"
  (formReady)="meterSelectionReady($event)"
>
</if-meter-selection>

<if-meter-index
  *ngIf="step === StepTypes.meterIndex"
  [editIndex]="editIndex"
  [reference]="reference"
  [activeSiteId]="activeSiteId"
  [contract]="activeContract"
  [control]="control.get('meterIndex')"
  [energyActions]="energyActions"
  (formReady)="meterIndexReady()"
>
</if-meter-index>

<div class="loader-container" *ngIf="step === StepTypes.loading">
  <app-loader></app-loader>
</div>

<if-update-monthly-installment
  *ngIf="step === StepTypes.updateMonthlyInstallment"
  [energy]="energy"
  [control]="control.get('monthlyInstallment')"
  [reference]="reference"
  [siteId]="activeSiteId"
  [contract]="activeContract"
  [minAmount]="minAmount"
  [suggestedAmount]="suggestedAmount"
  [useCaseMyEnergy]="useCaseMyEnergy"
  (formReady)="continue()"
>
</if-update-monthly-installment>

<if-all-good
  *ngIf="step === StepTypes.allGood || step === StepTypes.indexPosted"
  [type]="step"
  [origin]="origin"
  [contract]="activeContract"
  (formReady)="continue()"
>
</if-all-good>

<app-warning-message
  *ngIf="step === StepTypes.serviceNotAvailable || step === StepTypes.adjustmentNotAvailable"
  [messageType]="step"
  (formReady)="continue()"
>
</app-warning-message>
