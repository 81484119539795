<div class="">
  <div class="top">
    <div class="heading bg-white p-8 md:py-16">
      <div class="text-center">
        <h2 class="font-display font-normal text-4xl te-text-gradient-home md:text-5xl">
          {{ 'general.upsell.boiler_one_shot.title' | translate }}
        </h2>
      </div>
    </div>

    <div class="boiler-content">
      <app-step-progress-bar [totalSteps]="totalSteps" [activeStep]="activeStepCount"> </app-step-progress-bar>

      <div class="w-full max-w-4xl px-8 m-auto">
        <form novalidate [formGroup]="control" class="index-form">
          <!-- Select reference -->
          <app-select-reference
            *ngIf="activeStep === BoilerOneShotSteps.SELECT_REFERENCE"
            [referencesDetails]="referencesDetails"
            [control]="control.get(BoilerOneShotSteps.SELECT_REFERENCE)"
            (formReady)="goToStep(BoilerOneShotSteps.SELECT_SITES)"
          >
          </app-select-reference>

          <!-- Select site -->
          <app-select-sites
            *ngIf="activeStep === BoilerOneShotSteps.SELECT_SITES"
            [reference]="control.get([BoilerOneShotSteps.SELECT_REFERENCE, 'reference'])?.value"
            [control]="control.get(BoilerOneShotSteps.SELECT_SITES)"
            (formReady)="goToStep(BoilerOneShotSteps.BOILER_TYPE)"
            (goBackEmitter)="goBack()"
          >
          </app-select-sites>

          <!-- Select boiler type -->
          <app-boiler-type
            *ngIf="activeStep === BoilerOneShotSteps.BOILER_TYPE"
            [reference]="control.get([BoilerOneShotSteps.SELECT_REFERENCE, 'reference'])?.value"
            [siteId]="control.get([BoilerOneShotSteps.SELECT_SITES, 'site'])?.value"
            [control]="control.get(BoilerOneShotSteps.BOILER_TYPE)"
            (formReady)="goToStep(BoilerOneShotSteps.HOUSE_AGE)"
            (goBackEmitter)="goBack()"
          >
          </app-boiler-type>

          <!-- Select house age -->
          <app-house-age
            *ngIf="activeStep === BoilerOneShotSteps.HOUSE_AGE"
            [control]="control.get(BoilerOneShotSteps.HOUSE_AGE)"
            (formReady)="goToStep(BoilerOneShotSteps.CONTACT_INFO)"
            (goBackEmitter)="goBack()"
          >
          </app-house-age>

          <!-- Contact form -->
          <app-contact-info
            class="contact-info-section"
            *ngIf="activeStep === BoilerOneShotSteps.CONTACT_INFO"
            [contactDetail]="contactDetail"
            [control]="control.get(BoilerOneShotSteps.CONTACT_INFO)"
            (formReady)="goToStep(BoilerOneShotSteps.CONFIRMATION)"
            (goBackEmitter)="goBack()"
          ></app-contact-info>

          <!-- Confirmation -->
          <app-confirmation-boiler-maintenance-os
            *ngIf="activeStep === BoilerOneShotSteps.CONFIRMATION"
            [control]="control.get('confirmation')"
            (formReady)="submit()"
            (goBackEmitter)="goBack()"
          >
          </app-confirmation-boiler-maintenance-os>

          <!-- Done -->
          <app-done-boiler-maintenance-os *ngIf="activeStep === BoilerOneShotSteps.DONE">
          </app-done-boiler-maintenance-os>
        </form>

        <div class="bottom"></div>
      </div>
    </div>
  </div>
</div>
