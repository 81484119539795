import { Component, OnDestroy, OnInit } from '@angular/core';
import { MainFacade } from '@app/core/facade/main.facade';
import { filter, forkJoin, map, Observable, of, Subject, switchMap, take, takeUntil } from 'rxjs';
import { Site } from '@app/modules/customer-zone/user/models/site.interface';
import { VersionSize } from '@app/shared/models/units.interface';
import { Mandate, MandateStatus } from '@app/modules/customer-zone/consumption/models/mandates.interface';
import moment from 'moment';
import { Meter } from '@app/modules/customer-zone/consumption/models/deliveryPoint.interface';
import { DeliveryPoint, EliqAccessRights } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { DeviceService } from '@app/core/service/device.service';
import { ContractDtoCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Component({
  selector: 'app-fluvius-consents',
  templateUrl: './fluvius-consents.component.html',
  styleUrls: ['./fluvius-consents.component.scss'],
})
export class FluviusConsentsComponent implements OnInit, OnDestroy {
  readonly mandateStatus = MandateStatus;
  reference: string;
  activeSite: Site;
  sites$: Observable<Site[]>;
  eliqAccessRights$: Observable<EliqAccessRights>;
  versionSize = VersionSize;
  mandates$: Observable<Mandate[]>;
  hasSmartMeter: boolean;
  contentPrefix: string;
  isMobileApp: boolean = false;
  private notifier$ = new Subject<void>();

  constructor(public facade: MainFacade, private deviceService: DeviceService) {}

  ngOnInit(): void {
    this.analytics();
    this.deviceService.type$.pipe(take(1)).subscribe(({ isMobileApp }) => (this.isMobileApp = isMobileApp));
    this.facade.utils.setPageTitle('pages.fluviusConsents.pageTitle');
    this.facade.contracts$
      .pipe(
        takeUntil(this.notifier$),
        filter((contracts: ContractDtoCuzoApi[]) => !!contracts),
        switchMap((contracts: ContractDtoCuzoApi[]) => {
          this.reference = this.facade.state$.value.reference;
          this.activeSite = this.facade.state$.value.activeSite;
          this.sites$ = this.facade.sites$;
          this.eliqAccessRights$ = this.facade.eliqAccessRights$;
          this.mandates$ = this.facade.loadMandates(this.reference).pipe(
            map((mandates: Mandate[]) =>
              mandates?.map((mandate: Mandate): Mandate => {
                const endOn = 'endOn' in mandate ? mandate.endOn : moment(mandate.startOn).add('3', 'y').toISOString();
                return {
                  ...mandate,
                  endOn,
                  expiringSoon:
                    mandate.status === this.mandateStatus.approved &&
                    moment(endOn) >= moment() &&
                    moment(endOn).diff(moment(), 'months') < 3,
                };
              })
            )
          );

          return forkJoin(
            contracts.map((contract: ContractDtoCuzoApi) =>
              this.facade
                .loadDeliveryPoint(this.reference, this.activeSite, contract)
                .pipe(map((meter: Meter[]): DeliveryPoint => this.facade.remodelDeliveryPointResponse(meter, contract)))
            )
          );
        })
      )
      .subscribe((deliveryPoints: DeliveryPoint[]) => {
        this.hasSmartMeter = deliveryPoints.some((deliveryPoint: DeliveryPoint) => deliveryPoint.smartMeter);
        this.contentPrefix = this.hasSmartMeter ? 'smartMeter' : 'nonSmartMeter';
      });
  }

  ngOnDestroy(): void {
    this.notifier$.next();
    this.notifier$.complete();
  }

  switchSite(site: string) {
    this.facade.updateActiveSite(site);
  }

  private analytics(): void {
    this.facade.analytics.push(
      {
        event: 'pageview',
        page: {
          phase: 'care',
          category: 'cuzo',
          subCategory: 'home profile - cuzo',
        },
      },
      {
        reference: this.facade?.state$?.value?.reference,
        site: this.facade?.state$?.value?.activeSite,
        accessRights: this.facade?.state$?.value?.accessRights,
        eliqAccessRights: this.facade?.state$?.value?.eliqAccessRights,
        contracts: this.facade?.state$?.value?.contracts,
      }
    );
  }
}
