<div class="head mb-8">
  <h3 class="font-medium text-xl te-text-gradient-home inline-block">
    {{ moveSite?.moveOutAddress?.streetName }}, {{ moveSite?.moveOutAddress?.streetNumber }} —
    {{ moveSite?.moveOutAddress?.zipCode }} {{ moveSite?.moveOutAddress?.town }}
  </h3>
  <div class="text-sm text-grey-dark60">
    <span *ngIf="numberMeterElec > 0">
      {{ 'components.move.dashboard.card.labels.elecMeter' | translate }}
      {{ numberMeterElec }}
    </span>
    <span class="pl-4" *ngIf="numberMeterGas > 0">
      {{ 'components.move.dashboard.card.labels.gasMeter' | translate }}
      {{ numberMeterGas }}
    </span>
  </div>
</div>
