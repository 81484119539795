import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import {
  DataType,
  HomeProfile,
  HomeProfileCategory,
  HomeProfileMultiQuestions,
  HomeProfileQuestion,
  HomeProfileQuestionType,
  HomeProfileUpdate,
} from '@app/modules/customer-zone/consumption/services/home-profile/home-profile.service';

@Component({
  selector: 'app-home-profile-form',
  templateUrl: './home-profile-form.component.html',
  styleUrls: ['./home-profile-form.component.scss'],
})
export class HomeProfileFormComponent implements OnChanges {
  @Input() homeProfile: HomeProfile;
  @Output() homeProfileUpdateEmitter: EventEmitter<HomeProfileUpdate[]> = new EventEmitter<HomeProfileUpdate[]>();
  @Input() updateOngoing: number = 0; // 0=none;1=ongoing;2=error
  readonly homeProfileCategory = HomeProfileCategory;
  readonly homeProfileQuestionType = HomeProfileQuestionType;
  homeQuestions: HomeProfile;
  otherQuestions: HomeProfile;
  question: HomeProfileQuestion | HomeProfileMultiQuestions = null;
  formUpdate: { key?: string; category?: string; payload?: HomeProfileUpdate[]; status?: number } | null = null;

  ngOnChanges(data): void {
    if (data?.homeProfile?.currentValue) {
      this.homeQuestions = this.extractPropertiesFromObject(this.homeProfile, (key) => key.includes('properties'));
      this.otherQuestions = this.extractPropertiesFromObject(this.homeProfile, (key) => !key.includes('properties'));
    }
    if (data?.updateOngoing?.currentValue !== null) {
      this.formUpdate = { ...this.formUpdate, status: this.updateOngoing };
    }
  }

  openQuestionPanel(
    question: HomeProfileQuestion | HomeProfileMultiQuestions,
    category: HomeProfileCategory | string
  ): void {
    this.question = { ...question, category };
  }

  closeQuestionPanel(): void {
    this.question = null;
  }

  updateHomeProfile(payload: HomeProfileUpdate[]): void {
    this.formUpdate = this.getPayloadUpdate(payload);
    this.closeQuestionPanel();
    this.homeProfileUpdateEmitter.emit(payload);
  }

  private getPayloadUpdate(payload: HomeProfileUpdate[]) {
    if (!payload.length) {
      return null;
    }
    const [path] = payload;
    if (!path.key) {
      return null;
    }
    const [category, key] = path?.key?.split('/');
    return { category, key, payload, status: 1 };
  }

  private extractPropertiesFromObject(object: HomeProfile, filter: any): HomeProfile {
    return Object.keys(object)
      .filter((key: string) => filter(key))
      .reduce((obj: HomeProfile, key: string) => {
        obj[key] = this.updateDataType(object[key]);
        return obj;
      }, {});
  }

  private updateDataType(questions: HomeProfileQuestion[]): HomeProfileQuestion[] {
    return questions.map((question: HomeProfileQuestion): HomeProfileQuestion => {
      if (question.dataType !== DataType.number) {
        return question;
      }
      if ((question.limit.max - question.limit.min) / question.limit.step > 50) {
        question.dataType = DataType.numericInput;
        question = this.alterWithHardCodedData(question); // not good --> temporary
      }
      return question;
    });
  }

  private alterWithHardCodedData(question: HomeProfileQuestion): HomeProfileQuestion {
    if (question.key === 'living_area') {
      question.suffix = 'm2';
    }
    return question;
  }

  protected readonly HomeProfileCategory = HomeProfileCategory;
}
