<div class="container relative h-full">
  <div class="lds-ellipsis">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <span class="loader-text">
      {{ 'general.loader' | translate }}
    </span>
  </div>
</div>
