<div *ngFor="let moveSite of moveSites">
  <app-no-move
    *ngIf="moveSite?.viewStatus === ViewStatus.NO_MOVE"
    [moveSite]="moveSite"
  >
  </app-no-move>
  <app-move-summary
    *ngIf="moveSite?.viewStatus !== ViewStatus.NO_MOVE"
    [moveSite]="moveSite"
  >
  </app-move-summary>
</div>
