<div class="date-picker-wrap">
  <ngb-datepicker
    id="datepicker"
    class="custom-datepicker px-3 pt-1 pb-3"
    outsideDays="visible"
    navigation="none"
    [formControl]="pickerControl"
    [displayMonths]="1"
    [weekdays]="true"
    [minDate]="dateRange?.min"
    [maxDate]="dateRange?.max"
    [dayTemplate]="dayTemplate"
    [markDisabled]="markDisabled"
    (dateSelect)="onDateSelect($event)"
  >
    <ng-template ngbDatepickerContent>
      <div *ngFor="let month of datepicker.state.months">
        <div id="select-wrap">
          <button
            type="button"
            [class.disabled]="prevArrowDisabled"
            [attr.disabled]="prevArrowDisabled || null"
            (click)="makeOneStep('backward')"
          >
            <img src="./assets/img/chevron-left.svg" />
          </button>

          <ng-container [formGroup]="selectsForm">
            <select formControlName="month" id="monthsSelect">
              <option disabled>{{ 'common.month.label' | translate }}</option>
              <option *ngFor="let month of months" [value]="month.id">
                {{ 'common.month.' + month.value | translate }}
              </option>
            </select>

            <select formControlName="year" id="yearsSelect">
              <option disabled>{{ 'common.year.label' | translate }}</option>
              <option *ngFor="let year of years" [value]="year.value">{{ year.value }}</option>
            </select>
          </ng-container>

          <button
            type="button"
            [class.disabled]="nextArrowDisabled"
            [attr.disabled]="nextArrowDisabled || null"
            (click)="makeOneStep('forward')"
          >
            <img src="./assets/img/chevron-right.svg" />
          </button>
        </div>
        <h3 class="date-title">{{ title }}</h3>
        <ngb-datepicker-month class="border rounded" [month]="month"></ngb-datepicker-month>
      </div>
    </ng-template>
  </ngb-datepicker>

  <ng-template #dayTemplate let-date="date" let-selected="selected" let-currentMonth="currentMonth">
    <div class="day-template" [class.selected]="selected" [class.outside]="date.month !== currentMonth">
      {{ date.day }}
    </div>
  </ng-template>
</div>
