import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { Observable, Subject } from 'rxjs';
import { SponsorshipService } from '../../services/sponsorship/sponsorship.service';
import { LangChangeEvent } from '@ngx-translate/core';
import { Discount } from '../../models/discount.interface';
import { DiscountService } from '../../services/discount/discount.service';
import { MainFacade } from '@app/core/facade/main.facade';
import { SponsorshipCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Component({
  selector: 'app-mgm',
  templateUrl: './mgm.component.html',
  styleUrls: ['./mgm.component.scss'],
})
export class MgmComponent implements OnInit, OnDestroy {
  readonly yearlyMax: number = 20;
  public reference: string;

  public sponsorship$: Observable<SponsorshipCuzoApi>;
  public discount$: Observable<Discount>;
  public maxDiscount: number;
  private notifier: Subject<void> = new Subject();

  constructor(
    private facade: MainFacade,
    private sponsorshipService: SponsorshipService,
    public discountService: DiscountService
  ) {}

  ngOnInit(): void {
    this.facade.utils.setPageTitle('pages.mgm.pageTitle');
    this.analytics();
    this.facade.translate.onLangChange
      .pipe(takeUntil(this.notifier))
      .subscribe((event: LangChangeEvent) => this.analytics());

    this.discount$ = this.discountService.getDiscount();

    this.discount$.pipe(takeUntil(this.notifier)).subscribe((discount: Discount) => {
      if (discount) {
        this.maxDiscount = (discount.promoDiscount || discount.defaultDiscount) * this.yearlyMax;
      }
    });

    this.facade.reference$.pipe(takeUntil(this.notifier)).subscribe((reference: string) => {
      this.reference = reference;
      this.sponsorship$ = this.sponsorshipService.getSponsorship(this.reference);
    });
  }

  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
  }

  private analytics() {
    this.facade.analytics.push(
      {
        event: 'pageview',
        page: {
          phase: 'loyalty',
          category: 'mgm - cuzo',
          subCategory: 'MGM',
        },
        product: {
          branch: 'GasElec',
          name: 'GasElec',
        },
      },
      {
        reference: this.facade?.state$?.value?.reference,
        site: this.facade?.state$?.value?.activeSite,
        accessRights: this.facade?.state$?.value?.accessRights,
      }
    );
  }
}
