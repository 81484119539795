import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-progress-circle',
  templateUrl: './progress-circle.component.html',
  styleUrls: ['./progress-circle.component.scss'],
})
export class ProgressCircleComponent implements OnInit {
  @Input() content: string;
  @Input() size: string;
  @Input() progress?: number = 0;
  @Input() circleClass?: string;
  @Input() contentClass?: string = 'white';

  constructor() {}

  ngOnInit(): void {}
}
