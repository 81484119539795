import { Component, Input, OnInit } from '@angular/core';
import { ContactDetails } from '../../../../../contact/models/contactDetails.interface';
import { BillingDetails } from '../../../../models/billingDetails.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangeAddressComponent } from '../../../../../contact/components/change-address/change-address.component';
import { AccessRight } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { ChangeInvoiceMethodComponent } from '../change-invoice-method/change-invoice-method.component';

@Component({
  selector: 'app-paper',
  templateUrl: './paper.component.html',
  styleUrls: ['./paper.component.scss'],
})
export class PaperComponent implements OnInit {
  @Input() contactDetails: ContactDetails;
  @Input() billingDetails: BillingDetails;
  @Input() reference: string;
  readonly accessRight = AccessRight;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  public modifyAddress() {
    const modalRef = this.modalService.open(ChangeAddressComponent, {
      size: 'lg',
    });

    modalRef.componentInstance.contactDetails = this.contactDetails;
    modalRef.componentInstance.reference = this.reference;
  }

  changeInvoiceMethod() {
    const modalRef = this.modalService.open(ChangeInvoiceMethodComponent, {
      size: 'lg',
    });

    modalRef.componentInstance.billingDetails = this.billingDetails;
    modalRef.componentInstance.contactDetails = this.contactDetails;
    modalRef.componentInstance.reference = this.reference;
  }
}
