import { MoveFormFacade } from '@app/core/facade/move-form.facade';
import { Observable, switchMap, take } from 'rxjs';

export abstract class MoveFormStep<T> {
  protected constructor(protected moveFormFacade: MoveFormFacade) {
    this.onSaveDataForLater();
  }

  onSaveDataForLater(): void {
    this.moveFormFacade.saveFormListener
      .pipe(
        take(1),
        switchMap(() => this.saveFormData())
      )
      .subscribe(() => {
        this.moveFormFacade.saveStateToLocalStorage();
        this.moveFormFacade.redirectToMoveDashboard();
      });
  }

  abstract onNextClicked(): void;
  abstract onPreviousClicked(): void;
  abstract saveFormData(): Observable<T>;
}
