import { Component, inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaymentStatusComponent } from '../payment-status/payment-status.component';
import { MainFacade } from '../../../../../../../core/facade/main.facade';
import { SliceCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Component({
  selector: '[payment-plan-slice]',
  standalone: true,
  imports: [CommonModule, PaymentStatusComponent],
  templateUrl: './payment-plan-slice.component.html',
  styleUrls: ['../breakdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PaymentPlanSliceComponent implements OnInit {
  @Input() slice: SliceCuzoApi;
  facade: MainFacade = inject(MainFacade);
  ngOnInit(): void {}
}
