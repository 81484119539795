import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule, formatDate } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ContractService } from '@app/modules/customer-zone/contracts/services/contract.service';
import { catchError, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UtilsService } from '@app/shared/utils/utils.service';
import { MainFacade } from '@app/core/facade/main.facade';

@Component({
  selector: 'app-product-info',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss'],
})
export class ProductInfoComponent implements OnInit, OnDestroy {
  @Output() checkProductSwapState = new EventEmitter<void>();
  public notifier = new Subject<void>();
  public contractStartDate: Date = undefined;
  public changeContractActive = true;
  private activeReference: string = undefined;

  constructor(
    private contractService: ContractService,
    private utilsService: UtilsService,
    private facade: MainFacade
  ) {
    this.contractStartDate = this.firstcontractStartDate();
  }

  ngOnInit() {
    this.facade.reference$
      .pipe(takeUntil(this.notifier))
      .subscribe((reference: string) => (this.activeReference = reference));
  }

  firstcontractStartDate(): Date {
    const today = new Date();
    if (today.getDate() <= 15) {
      return new Date(today.getFullYear(), today.getMonth() + 1, 1);
    } else {
      return new Date(today.getFullYear(), today.getMonth() + 2, 1);
    }
  }

  createNewRequest() {
    this.changeContractActive = false;
    this.contractService
      .requestForProductSwap(
        { contractStartDate: formatDate(this.contractStartDate, 'yyyy-MM-dd', 'en') },
        this.activeReference
      )
      .pipe(
        catchError((error) => {
          this.changeContractActive = true;
          throw error.message;
        })
      )
      .subscribe(() => {
        this.changeContractActive = true;
        this.utilsService.setCookieValue('productSwapApplied_' + this.activeReference, true);
        this.checkProductSwapState.emit();
      });
  }

  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
  }
}
