<div
  *ngIf="moveData.status !== Status.NOT_INITIATED"
  class="body rounded-xl bg-grey-light border border-grey p-8 mb-8"
  [class.border-success-lighter]="moveData.status === Status.COMPLETED"
>
  <div class="flex">
    <div class="flex-1 pr-12">
      <div class="text-sm mb-4">
        <span class="inline-block w-full text-grey-dark60">
          {{ 'components.move.dashboard.card.labels.moving' | translate }}
        </span>
        <span class="inline-block w-full text-grey-dark">
          {{ (moveData?.moveOutDateElec || moveData?.moveOutDateGas) || '—' }}
        </span>
      </div>
      <div class="text-sm mb-4">
        <span class="inline-block w-full text-grey-dark60">
          {{ 'components.move.dashboard.card.labels.selectedMeters' | translate }}
        </span>
        <ul class="text-grey-dark">
          <app-move-meter-tag [meters]="moveData?.meters">
          </app-move-meter-tag>
        </ul>
      </div>
      <div class="text-sm mb-4">
        <span class="inline-block w-full text-grey-dark60">
          {{ 'components.move.dashboard.card.labels.newAddress' | translate }}
        </span>
        <span
          *ngIf="moveData?.moveInAddress; else noAddress"
          class="inline-block w-full text-grey-dark"
        >
          {{ moveData?.moveInAddress?.streetName }}, {{ moveData?.moveInAddress?.streetNumber }} —
          {{ moveData?.moveInAddress?.zipCode }} {{ moveData?.moveInAddress?.town }}
        </span>
        <ng-template #noAddress> — </ng-template>
      </div>
    </div>
    <div class="flex-1 border-l border-grey pl-12">
      <app-move-status-progress
        [status]="moveData.status"
      >
      </app-move-status-progress>
    </div>
  </div>

  <app-alert
    *ngIf="moveData?.status === Status.INITIATED"
    class="inline-block mt-8 w-full"
    [flatDesign]="true"
    [alert]="missingInfo"
  ></app-alert>

  <app-move-cta
    [moveData]="moveData"
    [siteId]="siteId"
  >
  </app-move-cta>
</div>
