<th>
  <span class="desktop-only">{{ 'components.paymentPlan.breakdown.headers.monthlyPayment.long' | translate }}</span>
  <span class="mobile-only">{{ 'components.paymentPlan.breakdown.headers.monthlyPayment.short' | translate }}</span>
</th>
<th>
  <span class="desktop-only">
    {{ 'components.paymentPlan.breakdown.headers.deadline.long' | translate }}
    <app-tooltip
      [tooltip]="'components.paymentPlan.breakdown.headers.deadline.tooltip' | translate"
      class="ml-4 inline-block align-middle"
    ></app-tooltip>
  </span>
  <span class="mobile-only">{{ 'components.paymentPlan.breakdown.headers.deadline.short' | translate }}</span>
</th>
<th>
  <span class="desktop-only">{{ 'components.paymentPlan.breakdown.headers.amount.long' | translate }}</span>
  <span class="mobile-only">{{ 'components.paymentPlan.breakdown.headers.amount.short' | translate }}</span>
</th>
<th>
  <span class="desktop-only">
    {{ 'components.paymentPlan.breakdown.headers.status.long' | translate }}
    <app-tooltip
      [tooltip]="'components.paymentPlan.breakdown.headers.status.tooltip' | translate"
      class="ml-4 inline-block align-middle"
    ></app-tooltip>
  </span>
  <span class="mobile-only">{{ 'components.paymentPlan.breakdown.headers.status.short' | translate }}</span>
</th>
