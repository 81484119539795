import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, catchError, of } from 'rxjs';
import { ContractsPerReference } from 'src/app/modules/customer-zone/contracts/models/contract.interface';
import { shareReplay, tap } from 'rxjs';
import { ServiceEligibility, ServicesEligibilityPerReference } from '../models/service-eligibility.interface';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ContractDtoCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Injectable({
  providedIn: 'root',
})
export class ContractService {
  private contractsByReferenceAndSite: ContractsPerReference = {};
  private servicesElegibilityByReferenceAndSite: ServicesEligibilityPerReference = {};
  private contractsArray$ = {};

  constructor(private http: HttpClient, private translate: TranslateService, private toastrService: ToastrService) {}

  public getContracts(reference: string, siteId: string, formulaPrice: boolean): Observable<ContractDtoCuzoApi[]> {
    if (
      this.contractsByReferenceAndSite &&
      this.contractsByReferenceAndSite[reference] &&
      this.contractsByReferenceAndSite[reference][siteId]
    ) {
      return !formulaPrice || this.hasFormulaPriceDetails(reference, siteId)
        ? of(this.contractsByReferenceAndSite[reference][siteId])
        : this.getContractsFromApi(reference, siteId, formulaPrice);
    }
    return this.getContractsFromApi(reference, siteId, formulaPrice);
  }

  public getServices(reference: string, siteId: string, loader: boolean = true): Observable<ServiceEligibility[]> {
    if (
      this.servicesElegibilityByReferenceAndSite &&
      this.servicesElegibilityByReferenceAndSite[reference] &&
      this.servicesElegibilityByReferenceAndSite[reference][siteId]
    ) {
      return of(this.servicesElegibilityByReferenceAndSite[reference][siteId]);
    }
    return this.getServicesEligibilityFromApi(reference, siteId, loader);
  }

  // CZBE-1712: Awaiting for EL1 update
  public filterDuplicateContracts(contracts: ContractDtoCuzoApi[]) {
    return contracts.filter(
      (contract, index, self) =>
        index ===
        self.findIndex(
          (c) => c.deliveryPointReference === contract.deliveryPointReference && c.meterNumber === contract.meterNumber
        )
    );
  }

  public requestForProductSwap(payload: { contractStartDate: string }, ref: string): Observable<any> {
    const params = new HttpParams().set('loader', 'false');
    return this.http.post<any>(`/v1/customers/${ref}/swap-product`, payload, { params }).pipe(
      catchError((error) => {
        this.toastrService.error(this.translate.instant('errorMessages.serverError'));
        throw error.message;
      })
    );
  }

  private hasFormulaPriceDetails(reference: string, siteId: string) {
    return this.contractsByReferenceAndSite[reference][siteId].some(
      (contract: ContractDtoCuzoApi) => contract?.tariff !== null
    );
  }

  private getContractsFromApi(
    reference: string,
    siteId: string,
    formulaPrice: boolean
  ): Observable<ContractDtoCuzoApi[]> {
    if (
      !formulaPrice &&
      this.contractsArray$ &&
      this.contractsArray$[reference] &&
      this.contractsArray$[reference][siteId]
    ) {
      return this.contractsArray$[reference][siteId];
    }
    if (!this.contractsArray$[reference]) {
      this.contractsArray$[reference] = {};
    }
    this.contractsArray$[reference][siteId] = this.http
      .get<ContractDtoCuzoApi[]>(`/v1/customers/${reference}/sites/${siteId}/contracts`, {
        params: new HttpParams().set('includeFormulaPrice', formulaPrice),
      })
      .pipe(
        catchError(() => of(null)),
        tap((contracts: ContractDtoCuzoApi[]) => {
          if (contracts) {
            if (!this.contractsByReferenceAndSite[reference]) {
              this.contractsByReferenceAndSite[reference] = {};
            }
            this.contractsByReferenceAndSite[reference][siteId] = contracts;
          }
        }),
        shareReplay(1)
      );
    return this.contractsArray$[reference][siteId];
  }

  private getServicesEligibilityFromApi(
    reference: string,
    siteId: string,
    loader: boolean
  ): Observable<ServiceEligibility[]> {
    const params = new HttpParams().set('loader', loader.toString());
    return this.http
      .get<ServiceEligibility[]>(`/v1/customers/${reference}/sites/${siteId}/services-eligibility`, { params })
      .pipe(
        tap((services: ServiceEligibility[]) => {
          if (!this.servicesElegibilityByReferenceAndSite[reference]) {
            this.servicesElegibilityByReferenceAndSite[reference] = {};
          }
          this.servicesElegibilityByReferenceAndSite[reference][siteId] = services;
        })
      );
  }

  private contracts(reference: string, siteId: string) {
    return this.contractsByReferenceAndSite[reference][siteId];
  }
}
