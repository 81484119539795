<div class="inline-block bg-white px-8 sm:px-12 py-4 rounded-lg shadow-md">
  <div class="flex items-center text-center justify-center gap-12">
    <button type="button" class="text-2xl text-primary" (click)="decrement()" [disabled]="value === min">
      <img [src]="'./assets/img/minus' + (value === min ? '-disabled' : '') + '.svg'" height="25" width="25" />
    </button>
    <p class="text-xl text-grey-dark font-semibold">{{ value }}</p>
    <button type="button" class="text-2xl text-primary" (click)="increment()" [disabled]="value === max">
      <img [src]="'./assets/img/plus' + (value === max ? '-disabled' : '') + '.svg'" width="25" height="25" />
    </button>
  </div>
</div>
