import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { KeycloakService, KeycloakAuthGuard } from 'keycloak-angular';
import { TranslateService } from '@ngx-translate/core';
import { OnboardingService } from '../../modules/registration/services/onboarding.service';

// noinspection JSAnnotator
@Injectable()
export class PublicAuthguard extends KeycloakAuthGuard {
  constructor(
    protected router: Router,
    protected keycloakAngular: KeycloakService,
    private translate: TranslateService,
    private onboardingService: OnboardingService
  ) {
    super(router, keycloakAngular);
  }

  // noinspection JSAnnotator
  isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve) => {
      if (this.authenticated) {
        this.router.navigate([`/`], { skipLocationChange: true });
        return resolve(false);
      }
      return resolve(true);
    });
  }
}
