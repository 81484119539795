import { Component, Input, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { InvoiceModelV2CuzoApi, InvoiceModelV2StatusEnumCuzoApi } from '@app/shared/models/cuzo-be-contract';
import { MainFacade } from '@app/core/facade/main.facade';
import { TranslateModule } from '@ngx-translate/core';
import { AddDaysToDatePipe } from '@app/shared/pipes/add-days-to-date/add-days-to-date.pipe';
import { take } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { BillingFacade } from '@app/core/facade/billing.facade';

@Component({
  selector: 'app-invoice-status',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, TranslateModule, AddDaysToDatePipe],
  templateUrl: './invoice-status.component.html',
  styleUrls: ['./invoice-status.component.scss'],
})
export class InvoiceStatusComponent implements OnInit {
  @Input() invoice: InvoiceModelV2CuzoApi;
  @Input() icon?: boolean = true;
  readonly Status = InvoiceModelV2StatusEnumCuzoApi;

  constructor(public facade: MainFacade, public billingFacade: BillingFacade) {}

  ngOnInit(): void {}

  downloadPDF(invoiceId: string) {
    this.billingFacade
      .getInvoiceDocument(invoiceId)
      .pipe(take(1))
      .subscribe((response: HttpResponse<Blob>) =>
        this.facade.utils.downLoadFile(response.body, 'application/pdf', `invoice.pdf`)
      );
  }
}
