<div class="container container--medium pt-8 pb-8">
  <h3 class="text-center mb-16 mt-16">
    <strong>{{ 'general.upsell.boiler_one_shot.thanks.title' | translate }} </strong>
  </h3>
  <div class="info__content" [innerHTML]="'general.upsell.boiler_one_shot.thanks.content' | translate"></div>

  <div class="text-center mt-16">
    <a [routerLink]="['/']" class="w-full btn btn-primary m-auto md:w-auto">
      <strong>{{ 'general.upsell.return' | translate }}</strong>
    </a>
  </div>
</div>
