import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-home-profile-progress',
  templateUrl: './home-profile-progress.component.html',
  styleUrls: ['./home-profile-progress.component.scss'],
})
export class HomeProfileProgressComponent {
  @Input() progressValue: number;
  @Input() showEditButton = false;

  constructor() {}
}
