import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ReferenceDetails } from '../../../../modules/customer-zone/user/models/reference.interface';
import { Observable } from 'rxjs';
import { UserInfo } from '../../../../modules/customer-zone/user/models/userInfo.interface';
import { KeycloakService } from 'keycloak-angular';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from '../../../../shared/utils/utils.service';
import { KeycloakConfig } from 'keycloak-js';
import { DomSanitizer } from '@angular/platform-browser';
import { LowerCasePipe } from '@angular/common';
import { SalesforceService } from '@app/shared/services/salesforce/salesforce.service';
import { AppConfig } from '@app/shared/utils/app-config';
import { Content } from '@app/shared/models/content.interface';

@Component({
  selector: 'app-secondary-menu',
  templateUrl: './secondary-menu.component.html',
  styleUrls: ['./secondary-menu.component.scss'],
})
export class SecondaryMenuComponent implements OnInit, OnChanges {
  @Input() content$: Observable<Content>;
  @Input() referencesDetails$: Observable<ReferenceDetails[]>;
  @Input() userInfo$: Observable<UserInfo>;
  @Input() activeReference: string;
  @Input() currentLanguage: string;
  @Input() mobileSubmenu: boolean;
  @Input() mobileActiveSubmenu: string;

  @Output()
  activeReferenceEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  currentLanguageEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  mobileMenuEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  mobileSubmenuEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  mobileActiveSubmenuEmitter: EventEmitter<string> = new EventEmitter<string>();
  public passwordPage;

  constructor(
    private keycloakService: KeycloakService,
    public translate: TranslateService,
    private cookieService: CookieService,
    public utilsService: UtilsService,
    private sanitizer: DomSanitizer,
    private lowerCasePipe: LowerCasePipe,
    private salesforceService: SalesforceService,
    private conf: AppConfig
  ) {}

  ngOnInit() {}

  ngOnChanges() {
    const keycloakConfig: KeycloakConfig = this.conf.config.keycloak;
    const baseURL: string = this.utilsService.getBaseUrl();
    // eslint-disable-next-line max-len
    const passwordPageURL = `${keycloakConfig.url}/realms/${keycloakConfig.realm}/account/password?referrer=${keycloakConfig.clientId}&referrer_uri=${baseURL}&kc_locale=${this.currentLanguage}`;
    this.passwordPage = this.sanitizer.bypassSecurityTrustUrl(passwordPageURL);
  }

  public emitMobileShowSubmenu(itemTitle: string) {
    this.mobileSubmenu = true;
    this.mobileActiveSubmenu = itemTitle;
    this.mobileActiveSubmenuEmitter.emit(itemTitle);
  }

  public emitMobileCloseSubmenu() {
    this.mobileSubmenu = false;
    this.mobileSubmenuEmitter.emit(this.mobileSubmenu);
  }

  public emitSwitchReference(reference: string) {
    this.utilsService.redirectToSameRoute();
    this.activeReferenceEmitter.emit(reference);
  }

  public emitSwitchLanguage(lang: string) {
    this.currentLanguageEmitter.emit(lang);
  }

  public emitMobileCloseMenu() {
    this.mobileMenuEmitter.emit(false);
  }

  public async doLogout() {
    this.cookieService.delete(this.salesforceService.salesforceChatData, '/');
    this.cookieService.delete(this.utilsService.activeInfoCookieName, '/');
    this.cookieService.delete('KEYCLOAK', '/');
    await this.keycloakService.logout(
      `${window.location.origin}${
        this.conf.config.path.prefix[this.lowerCasePipe.transform(this.translate.currentLang)]
      }`
    );
  }
}
