import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DeliveryPoint, EliqAccessRights } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { Site } from '@app/modules/customer-zone/user/models/site.interface';
import { MainFacade } from '@app/core/facade/main.facade';
import {
  EnergyType,
  Index,
  IndexContext,
  MeasurementNature,
  MeterIndex,
} from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { MeterConfig } from '@app/shared/models/meter.interface';
import { map, switchMap, take } from 'rxjs';
import { ChartDataset } from 'chart.js';
import { AnnotationOptions } from 'chartjs-plugin-annotation';
import moment from 'moment';
import { Direction } from '@app/modules/customer-zone/consumption/models/deliveryPoint.interface';
import { defaultDataset } from '@app/modules/customer-zone/consumption/pages/dashboard/widgets/meter-reading-chart-widget/line-dataset.default';
import { MeteringsService } from '@app/modules/customer-zone/consumption/services/meterings/meterings.service';

@Component({
  selector: 'app-meter-reading-chart-widget',
  templateUrl: './meter-reading-chart-widget.component.html',
  styleUrls: ['./meter-reading-chart-widget.component.scss'],
})
export class MeterReadingChartWidgetComponent implements OnChanges {
  @Input() eliqAccessRights: EliqAccessRights;
  @Input() reference: string;
  @Input() site: Site;
  @Input() deliveryPoints: DeliveryPoint[];
  @Input() meterConfig = MeterConfig.basic;
  lineChartData: ChartDataset[];
  lineChartLabels: string[];
  lineChartAnnotations: AnnotationOptions[];
  energyOptions: EnergyType[] = [];
  activeEnergyType: EnergyType;
  deliveryPoint: DeliveryPoint;
  officialIndex: Index;

  public EnergyType = EnergyType;
  public MeterConfig = MeterConfig;

  constructor(public facade: MainFacade, private meteringsService: MeteringsService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.deliveryPoints?.currentValue?.length) {
      this.resetChartData();
      this.deliveryPoints = changes.deliveryPoints.currentValue;

      this.activeEnergyType = this.deliveryPoints.find(
        (deliveryPoint: DeliveryPoint) => deliveryPoint.energy === EnergyType.ELECTRICITY
      )
        ? EnergyType.ELECTRICITY
        : EnergyType.GAS;

      this.loadMeteringAndBuildChart();
    }
  }

  private loadMeteringAndBuildChart() {
    this.energyOptions = [...new Set(this.deliveryPoints.map((value: DeliveryPoint) => value.energy))];
    this.deliveryPoint = this.deliveryPoints.find(({ energy }) => energy === this.activeEnergyType);

    // GetState Triggers new call on index update
    this.meteringsService
      .getState()
      .pipe(
        switchMap(() =>
          this.facade
            .loadMeterings(this.reference, this.site, this.activeEnergyType, this.deliveryPoint.reference, false)
            .pipe(
              take(1),
              map((reading: Index[]) => {
                if (this.activeEnergyType === EnergyType.GAS) {
                  reading = reading.map((index: Index) => ({
                    ...index,
                    indexes: index.indexes.filter((meterIndex: MeterIndex) =>
                      [MeasurementNature.ACTIVE_ENERGY, MeasurementNature?.VOLUME].includes(
                        meterIndex.measurementNature as MeasurementNature
                      )
                    ),
                  }));
                }
                return reading;
              }),
              map((reading: Index[]) => {
                let firstReadingIndex = 0;
                this.officialIndex = reading.find((index, position) => {
                  if (index.context !== IndexContext.PROVISION) {
                    firstReadingIndex = position;
                    return true;
                  }
                  return false;
                });
                return reading.slice(0, firstReadingIndex + 1);
              }),
              map((readings: Index[]) =>
                readings
                  .filter(
                    (reading: Index): boolean => moment(reading.indexDate) >= moment(this.officialIndex.indexDate)
                  )
                  .sort((a: Index, b: Index) => new Date(a.indexDate).getTime() - new Date(b.indexDate).getTime())
              )
            )
        )
      )
      .subscribe((meterReading: Index[]) => {
        this.lineChartData = this.prepareLineChartData(meterReading);
        this.lineChartLabels = this.prepareLineChartLabels(meterReading);
        this.lineChartAnnotations = this.prepareLineChartAnnotations(meterReading);
      });
  }

  private resetChartData() {
    this.lineChartData = this.lineChartLabels = this.lineChartAnnotations = null; // reset
  }

  private prepareLineChartData(meterReading: Index[]): ChartDataset[] {
    let dataset: any = structuredClone(defaultDataset);

    meterReading.forEach((reading: Index, pos) => {
      reading.indexes.forEach((meterIndex: MeterIndex) => {
        const direction = meterIndex?.direction;
        const register = meterIndex?.register;
        const officialMeterIndex = this.officialIndex.indexes.find(
          (officialMeterIndex: MeterIndex) =>
            officialMeterIndex.direction === direction && officialMeterIndex.register === register
        );
        const value = pos === 0 ? 0 : meterIndex?.value - officialMeterIndex?.value;
        let registerType: string = direction === Direction.production ? `${Direction.production}_` : '';
        registerType = registerType + register;
        if (dataset[registerType]) {
          dataset[registerType].data.push({ x: moment(reading.indexDate).valueOf(), y: Number(value) });
          dataset[registerType].label = this.facade.translate.instant('general.meters.' + registerType + '.title');
        }
      });
    });

    dataset = Object.values(dataset).filter((set) => set['data'].length) as ChartDataset[];
    return dataset;
  }

  private prepareLineChartLabels(meterReading: Index[]): string[] {
    const lastIndex = meterReading.length - 1;

    const mostRecentIndexDate = meterReading[lastIndex].indexDate;
    const oldestIndexDate = meterReading[0].indexDate;

    // EOM = End Of Month
    const mostRecentEOM = moment(mostRecentIndexDate).endOf('month');
    const oldestPlusOneYearEOM = moment(oldestIndexDate).add(1, 'years').subtract(1, 'month').endOf('month');

    const startDate = moment(oldestIndexDate).startOf('day');
    const endDate = mostRecentEOM.isAfter(oldestPlusOneYearEOM) ? mostRecentEOM : oldestPlusOneYearEOM;

    return [startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')];
  }

  private prepareLineChartAnnotations(meterReading: Index[]): AnnotationOptions[] {
    const [startDate, endDate] = this.lineChartLabels;
    const annotations = [];
    if (!moment(startDate).isSame(moment(endDate), 'year')) {
      annotations.push({
        type: 'line',
        scaleID: 'x',
        value: moment(endDate).startOf('year').valueOf(),
        borderColor: 'rgba(0, 0, 0, 0.4)',
        borderWidth: 1,
        borderDash: [5, 10],
        label: {
          backgroundColor: 'rgba(0, 0, 0, 0)',
          color: 'rgba(0, 0, 0, 0.4)',
          font: {
            style: 'normal',
            family: '"Roboto", Arial, Helvetica, sans-serif',
          },
          content: '2023 - 2024',
          position: 'start',
        },
      });
    }
    annotations.push({
      type: 'line',
      scaleID: 'y',
      value: 0,
      borderColor: 'rgba(55,70,73,1)',
      borderWidth: 1,
    });
    return annotations;
  }

  switchEnergy(energyType: string) {
    this.activeEnergyType = energyType as EnergyType;
    this.resetChartData();
    this.loadMeteringAndBuildChart();
  }
}
