import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { SponsorshipService } from '../../services/sponsorship/sponsorship.service';
import { take } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { UtilsService } from '../../../../../shared/utils/utils.service';
import { InvoiceService } from '../../../invoices/services/invoices/invoice.service';
import { SponsorshipCuzoApi, SponsorshipRecipientCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Component({
  selector: 'mgm-sponsor-info',
  templateUrl: './mgm-sponsor-info.component.html',
  styleUrls: ['./mgm-sponsor-info.component.scss'],
})
export class MgmSponsorInfoComponent implements OnInit, OnChanges {
  @Input() sponsorShip: SponsorshipCuzoApi;
  @Input() reference: string;
  @Input() yearlyMax: number;

  public page = 1;
  public pageSize = 10;
  public yearlyPercentage: number;

  public recipients$: Observable<SponsorshipRecipientCuzoApi[]>;

  constructor(
    private sponsorshipService: SponsorshipService,
    private invoiceService: InvoiceService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.recipients$ = this.sponsorshipService.getSponsorshipRecipients(this.reference);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.yearlyPercentage = Math.round((this.sponsorShip.yearlyRecipients / this.yearlyMax) * 100);
  }
  public downloadInvoicePDF(invoiceId: string): void {
    invoiceId = invoiceId.replace('/', '');
    this.invoiceService
      .getInvoicePDF(this.reference, invoiceId)
      .pipe(take(1))
      .subscribe((response: HttpResponse<Blob>) =>
        this.utilsService.downLoadFile(response.body, 'application/pdf', `${invoiceId}.pdf`)
      );
  }
}
