import { OpenInvoicesCuzoApi } from '@app/shared/models/cuzo-be-contract';
import { InvoicesResponse } from './invoicesResponse.interface';
import { Alert, AlertType } from '@app/shared/components/alert/alert.interface';

export interface Invoice {
  reference: string;
  customerReference: string;
  status: string;
  balance: number;
  structuredCommunication: string;
  dunningLocked: boolean;
  amount: InvoiceAmount;
  invoiceDate: string;
  dueDate: string;
  type: string;
  paymentDate: string;
  paymentMethod: string;
  startDate: string;
  endDate: string;
  payNxtInfo: PayNxtInfo;
  pdfAvailable?: boolean;
}

export interface InvoiceAmount {
  value: number;
  currency: string;
}

export interface PayNxtInfo {
  status: string;
  checkoutUrl: string;
}

export interface InvoicesPerReference {
  [reference: string]: InvoicesPerPage;
}

export interface InvoicesPerPage {
  [page: string]: InvoicesResponse;
}

export interface OpenInvoicesPerRef {
  [ref: string]: OpenInvoicesCuzoApi;
}

export interface InvoiceParams {
  offset?: number;
  limit?: number;
  year?: number;
  invoiceType?: string;
}

export enum InvoiceTypes {
  AVANCE = 'AVANCE',
  REGULE = 'REGULE',
  PAPUR = 'PAPUR',
  ANNULATION_REGULE = 'ANNULATION_REGULE',
}

export enum PaymentMethod {
  VIR = 'VIR',
  DOM = 'DOM',
  DOMV = 'DOMV',
}

export enum PaymentBalanceViewType {
  'NOTHING_TO_PAY' = 'NOTHING_TO_PAY',
  'PAY_ONE_INVOICE' = 'PAY_ONE_INVOICE',
  'PAY_MORE_INVOICES' = 'PAY_MORE_INVOICES',
  'ONLY_PAYMENT_PLAN' = 'ONLY_PAYMENT_PLAN',
  'NOTHING_TO_REFUND' = 'NOTHING_TO_REFUND',
  'REFUND_ONE_INVOICE' = 'REFUND_ONE_INVOICE',
  'REFUND_MORE_INVOICES' = 'REFUND_MORE_INVOICES',
}

export enum PaymentType {
  DUE,
  REFUND,
}

export interface InvoiceAlerts {
  alertInvoice: Alert;
  invoiceEndpointError: Alert;
  alertAllBillsPaid: Alert;
}

export const invoiceAlerts: InvoiceAlerts = {
  alertInvoice: {
    type: AlertType.danger,
    content: {
      titleKey: 'components.invoices.messages.financial.title',
      messageKey: 'components.invoices.messages.financial.content',
    },
  },
  invoiceEndpointError: {
    type: AlertType.danger,
    content: {
      titleKey: 'components.invoices.endpointError.title',
      messageKey: 'components.invoices.endpointError.content',
    },
  },
  alertAllBillsPaid: {
    type: AlertType.successThumbUp,
    content: {
      messageKey: 'pages.dashboard.paymentBalance.toPay.allBillsPaid',
    },
  },
};
