import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Direction } from '@app/modules/customer-zone/consumption/models/deliveryPoint.interface';
import { RegisterTypeExtra } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { MainFacade } from '@app/core/facade/main.facade';

@Component({
  selector: 'app-input-meter-index',
  templateUrl: './input-meter-index.component.html',
  styleUrls: ['./input-meter-index.component.scss'],
})
export class InputMeterIndexComponent implements OnInit {
  @Input() style?: InputMeterStyle = InputMeterStyle.COLUMN;
  @Input() direction?: string;
  @Input() newType = 'none';
  @Input() type = 'none';
  @Input() value: number;
  @Input() unit: string;
  @Input() date?: string | Date;
  @Input() label: string;
  @Input() tooltip: string;
  @Input() control: AbstractControl;
  @Input() modal?: boolean = false;
  @Input() errors?: { required?: string; minimumNotReached?: string; higherThanAverage?: string };
  public class = '';
  public readonly registerTypeExtra = RegisterTypeExtra;
  readonly InputMeterStyle = InputMeterStyle;

  constructor(private facade: MainFacade) {}

  ngOnInit(): void {
    if (this.date) {
      this.date = new Date(this.date);
    }
    if (
      this.direction &&
      this.direction === Direction.production &&
      !this.facade.state$?.value?.computedData?.hasSolarPanel
    ) {
      this.class = 'hidden';
      this.control.get('unit').patchValue(0);
    }
  }

  numericOnly(e: KeyboardEvent) {
    const { keyCode, key } = e;
    return keyCode === 8 ? true : !isNaN(Number(key));
  }

  handlePastedContent(clipboard: ClipboardEvent, formControl) {
    let value = clipboard.clipboardData.getData('text/plain');
    value = value.replace(/\D/g, '');
    setTimeout(() => formControl.setValue(value), 0);
  }
}

export enum InputMeterStyle {
  COLUMN = 'COLUMN',
  INLINE = 'INLINE',
}
