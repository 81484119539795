<div class="details">
  <div class="box box--radius box--padding mb-16 flex details__building">
    <app-image-icon
      class="index-icon"
      [altDescription]="estimatedIndex.energy"
      [imageSize]="{ width: 20, height: 20 }"
      imagePath="./assets/img/icons/standalone/dark-green/{{ estimatedIndex.energy | lowercase }}.svg"
    >
    </app-image-icon>
    <div class="contact-details ml-16" *ngIf="contact$ | async as contact">
      <strong>{{ contact.firstName }} {{ contact.lastName }} </strong><br />
      (#{{ estimatedIndex.reference }})
    </div>
    <div class="site-details ml-16" *ngIf="site$ | async as site">
      <strong>{{ 'general.eanCode' | translate }}</strong> {{ estimatedIndex.ean }} <br />
      <strong>{{ 'general.address.label' | translate }}</strong> {{ site.formattedAddress }} <br />
      <strong>{{ 'components.estimatedIndexDetail.corrections' | translate }}</strong>
      {{ estimatedIndex.indexDate | date: 'd/MM/yyyy' }}
    </div>
  </div>

  <div *ngIf="showForm" class="box box--radius box--padding mb-16">
    <form (ngSubmit)="submit()" novalidate [formGroup]="estimatedIndexForm" class="gc-form">
      <div class="form__component date">
        <app-custom-select
          [options]="{ style: 'text', defaultValue: false, icon: 'date' }"
          [field]="{
            id: 'date',
            label: ('components.estimatedIndexDetail.form.date' | translate),
            formControl: estimatedIndexForm.get('date'),
            options: dateOptions
          }"
        >
        </app-custom-select>
      </div>
      <div class="form__component meter-input">
        <ng-container *ngFor="let metersControl of meters.controls; let j = index">
          <div>
            <h5>{{ 'general.meterNumber' | translate }}: {{ estimatedIndex.meters[j].meterNumber }}</h5>
          </div>
          <ng-container *ngFor="let indexControl of metersControl.get('indexes')['controls']; let i = index">
            <app-input-meter-index
              [newType]="estimatedIndex.meters[j].indexes[i]['newRegister']"
              [type]="estimatedIndex.meters[j].indexes[i].register | lowercase"
              [value]="estimatedIndex.meters[j].indexes[i].value"
              [control]="indexControl"
              [date]="estimatedIndex.indexDate"
              [unit]="energyUnit"
              [label]="estimatedIndex.meters[j].indexes[i].label"
              [tooltip]="estimatedIndex.meters[j].indexes[i].tooltip"
            >
            </app-input-meter-index>
            <div class="form__error form__error--highlighted">
              <div *ngIf="indexControl.get('unit').errors?.required && indexControl.get('unit').touched">
                {{ 'errorMessages.required' | translate }}
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <button
        type="submit"
        [attr.disabled]="!estimatedIndexForm.valid || estimatedIndexForm.pristine ? '' : null"
        [class.not-submittable]="!estimatedIndexForm.valid || estimatedIndexForm.pristine"
        class="submit mt-8 btn--medium btn btn--tertiary"
        [analyticsEvent]="'estimatedIndex:click:submit-estimated-index'"
      >
        {{ 'general.confirm' | translate }}
      </button>
    </form>
  </div>
</div>
