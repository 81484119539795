<div class="card-primary mb-8">
  <h3 class="mb-6 text-2xl font-medium text-grey-dark">{{ 'components.refundMethod.title' | translate }}</h3>
  <div class="inline-block mb-8 w-full" *ngIf="billingDetails?.paymentMethodLocked">
    <app-alert [alert]="alert"></app-alert>
  </div>

  <div class="rounded-2xl box-shadow-small bg-grey-light p-8 md:p-12 flex">
    <div>
      {{ 'components.refundMethod.intro' | translate }}
      <span class="font-medium">{{ 'general.billing.refundMethod' | translate | lowercase }}</span
      >.
    </div>
  </div>

  <div class="mt-6 md:mt-16 mb-4">{{ 'components.refundMethod.paymentDetails' | translate }}:</div>

  <div class="md:flex justify-between rounded-2xl bg-grey-light p-8 md:p-12 mb-8 md:mb-16">
    <div class="flex-1">
      <p>
        <span class="font-medium mb-2 inline-block">{{ 'components.refundMethod.account' | translate }}</span>
      </p>
      <span class="font-medium">{{ 'general.billing.IBAN' | translate }} </span>
      {{
        this.billingDetails?.paymentMethod === bankTransfer
          ? this.billingDetails?.refundAccount
          : this.billingDetails?.bankAccount
      }}<br />
    </div>
    <div
      *ngIf="this.billingDetails?.paymentMethod === bankTransfer"
      class="ml-auto mt-4 md:mt-0 md:self-center md:pl-4"
    >
      <span
        *ngIf="!billingDetails.paymentMethodLocked"
        (click)="changRefundAccount()"
        class="cursor-pointer btn-text btn-text-primary font-bold"
        [analyticsEvent]="'paymentPreferences:click:change-bank-account'"
      >
        {{ 'general.modify' | translate }}
      </span>
    </div>
  </div>
</div>
