import { ChartOptions, TooltipItem } from 'chart.js';
import { RegisterType, RegisterTypeColor } from '../../../models/consumption.interface';

const chartContent = {
  title: [
    RegisterType.HIGH,
    RegisterType.LOW,
    RegisterType.TOTAL_HOUR,
    RegisterType.NIGHT_EXCLUSIVE,
    RegisterType.MONO,
    RegisterType.PRODUCTION_LOW,
    RegisterType.PRODUCTION_HIGH,
    RegisterType.PRODUCTION_TOTAL_HOUR,
  ],
  colors: [
    RegisterTypeColor.HIGH,
    RegisterTypeColor.LOW,
    RegisterTypeColor.TOTAL_HOUR,
    RegisterTypeColor.NIGHT_EXCLUSIVE,
    RegisterTypeColor.MONO,
    RegisterTypeColor.PRODUCTION_HIGH,
    RegisterTypeColor.PRODUCTION_LOW,
    RegisterTypeColor.PRODUCTION_TOTAL_HOUR,
  ],
};

const barChartData = chartContent.title.map((title, index) => ({
  data: [],
  label: title,
  backgroundColor: chartContent.colors[index],
  hoverBackgroundColor: chartContent.colors[index],
  stack: 'a',
}));

const barColors = chartContent.title.map((title, index) => ({
  backgroundColor: chartContent.colors[index],
  hoverBackgroundColor: chartContent.colors[index],
}));

const barChartOptions: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    duration: 0,
  },
  elements: {
    line: {
      tension: 0.5,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      beginAtZero: true,
      grid: {
        color: '#B7CBD3',
      },
      ticks: {
        callback: (val, index) => `${val}`,
      },
    },
  },
  plugins: {
    legend: {
      position: 'bottom',
      align: 'center',
      labels: {
        usePointStyle: true,
        pointStyle: 'circle',
        padding: 10,
        boxWidth: 7,
      },
    },
    tooltip: {
      callbacks: {
        title: () => '',
        labelTextColor: () => '#7B7B7B',
      },
      filter: (tooltipItem) => !Number.isNaN(tooltipItem.raw as number),
      backgroundColor: 'rgba(255,255,255,1)',
      borderColor: '#EDEDED',
      borderWidth: 1,
      caretPadding: 15,
      caretSize: 10,
      bodySpacing: 10,
      titleFont: {
        size: 14,
      },
      bodyFont: {
        size: 14,
      },
      displayColors: true,
      enabled: true,
      intersect: true,
      mode: 'x',
      titleColor: '#7B7B7B',
      titleMarginBottom: 10,
      padding: 15,
      footerColor: '#7B7B7B',
      footerFont: {
        size: 14,
      },
      footerSpacing: 10,
      footerMarginTop: 15,
    },
  },
};

export const chartConfig = {
  chartContent,
  barChartOptions,
  barChartData,
  barColors,
};
