<div class="card border-none box-shadow-small mb-8">
  <app-move-header [moveSite]="moveSite">
  </app-move-header>
  <div class="foot">
    <button
      class="btn btn-primary font-bold h-fit inline-block w-full sm:w-auto"
      (click)="startMove(moveSite?.siteId)"
    >
      {{ 'components.move.dashboard.cta.launchMove' | translate }}
    </button>
  </div>
</div>
