import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FaqItem } from '../../models/faqItem.interface';
import { CommonModule } from '@angular/common';
import { AnalyticsModule } from '@app/shared/modules/analytics/analytics.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { StripHtmlTagsPipe } from '@app/shared/pipes/strip-html-tags/strip-html-tags.pipe';
import { SlideToggleDirective } from '@app/shared/directives/slide-toggle.directive';
import { MainFacade } from '@app/core/facade/main.facade';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-faq-item',
  standalone: true,
  imports: [CommonModule, AnalyticsModule, TranslateModule, StripHtmlTagsPipe, SlideToggleDirective],
  templateUrl: './faq-item.component.html',
  styleUrls: ['./faq-item.component.scss'],
})
export class FaqItemComponent implements OnInit, OnDestroy {
  @Input() faqItem: FaqItem;
  @Input() backgroundColor?: string = 'bg-white';
  @Input() translated = true;
  public _faqItem: FaqItem;
  public notifier = new Subject<void>();

  constructor(public facade: MainFacade, public translate: TranslateService) {}

  ngOnInit() {
    if (!this.translated) {
      this.translateItem();
      this.facade.translate.onLangChange.pipe(takeUntil(this.notifier)).subscribe(() => {
        this.translateItem();
      });
    } else {
      this._faqItem = this.faqItem;
    }
  }

  translateItem() {
    this._faqItem = {
      title: this.translate.instant(this.faqItem.title),
      abstract: this.translate.instant(this.faqItem.abstract),
      url: this.translate.instant(this.faqItem.url),
    };
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }
}
