<div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
  <div class="mb-16">
    <h2 class="mt-12 mb-8 font-display font-normal text-5xl te-text-gradient-home inline-block md:my-12">
      {{ 'pages.contactDetails.title' | translate }}
    </h2>
    <div class="md:grid md:grid-cols-12 gap-16">
      <div class="md:col-span-8">
        <div class="card-primary mb-8">
          <div class="inline-block mb-8 w-full" *ngIf="alert && contactDetails?.locked">
            <app-alert class="mb-16" [alert]="alert"></app-alert>
          </div>
          <div class="font-medium text-sm text-grey-dark60 mb-16">
            {{ 'pages.contactDetails.intro' | translate : { reference: activeReference } }}
          </div>
          <form
            *ngIf="contactForm"
            [formGroup]="contactForm"
            novalidate
            class="md:grid md:grid-rows-7 md:gap-4"
            (ngSubmit)="handleSubmit()"
          >
            <ng-container *ngIf="showPersonalContactDetails">
              <div class="grid__line">
                <div class="form-element custom-select disabled">
                  <app-custom-select
                    [field]="{
                      id: 'civility',
                      label: ('general.contact.civility' | translate),
                      formControl: contactForm.get('civility'),
                      options: civilityOptions
                    }"
                    [isLocked]="true"
                  >
                  </app-custom-select>
                </div>
              </div>

              <div class="grid__line">
                <div class="form-element input-text mb-8">
                  <label for="input-name">
                    {{ 'general.contact.name' | translate }}
                  </label>
                  <input formControlName="name" id="input-name" name="name" type="text" autocomplete="off" />
                </div>
                <div class="form-element input-text mb-8">
                  <label for="input-first-name">
                    {{ 'general.contact.firstName' | translate }}
                  </label>
                  <input formControlName="firstName" id="input-first-name" name="name" type="text" autocomplete="off" />
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="showBusinessContactDetails">
              <div class="grid__line">
                <div class="form-element input-text mb-8">
                  <label for="input-contact-name">
                    {{ 'general.contact.contactName' | translate }}
                  </label>
                  <input
                    formControlName="contactName"
                    id="input-contact-name"
                    name="contactName"
                    type="text"
                    autocomplete="off"
                  />
                </div>
              </div>

              <div class="grid__line">
                <div class="form-element input-text mb-8">
                  <label for="input-company-number">
                    {{ 'general.contact.companyNumber' | translate }}
                  </label>
                  <input
                    formControlName="companyNumber"
                    id="input-company-number"
                    name="companyNumber"
                    type="text"
                    autocomplete="off"
                  />
                </div>
                <div class="form-element input-text mb-8">
                  <label for="input-company-type">
                    {{ 'general.contact.socialReason' | translate }}
                  </label>
                  <input
                    formControlName="socialReason"
                    id="input-company-type"
                    name="socialReason"
                    type="text"
                    autocomplete="off"
                  />
                </div>
              </div>
            </ng-container>

            <div class="grid__line">
              <div class="form-element input-email" [class.errors]="contactForm.get('email').invalid">
                <label for="input-email">
                  {{ 'general.email' | translate }}
                </label>
                <input
                  [formControl]="contactForm.controls.email"
                  id="input-email"
                  name="email"
                  type="email"
                  autocomplete="off"

                />
                <div class="message">
                  <div *ngIf="contactForm.get('email').errors?.required">
                    {{ 'errorMessages.required' | translate }}
                  </div>
                  <div *ngIf="contactForm.get('email').errors?.email">
                    {{ 'errorMessages.invalidEmail' | translate }}
                  </div>
                  <div *ngIf="!contactForm.get('email').errors && contactForm.get('email').dirty">
                    {{ 'infoMessages.modified' | translate }}
                  </div>
                </div>
              </div>
              <div class="form-element input-text">
                <label for="input-iban">
                  {{ 'general.billing.IBAN' | translate }}
                </label>
                <input
                  [formControl]="contactForm.controls.iban"
                  id="input-iban"
                  name="iban"
                  type="text"
                  autocomplete="off"
                />
                <div class="message">
                  <div *ngIf="contactForm.get('iban').errors?.required">
                    {{ 'errorMessages.required' | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div class="grid__line">
              <div class="form-element input-phone" [class.errors]="contactForm.get('phone').invalid">
                <label for="input-telephone">
                  {{ 'general.contact.phone' | translate }}
                </label>
                <input
                  [formControl]="contactForm.controls.phone"
                  id="input-telephone"
                  name="telephone"
                  type="text"
                  autocomplete="off"
                  placeholder="0032 XXX XX XX XX"
                />
                <div class="message">
                  <div *ngIf="contactForm.get('phone').errors?.required">
                    {{ 'errorMessages.required' | translate }}
                  </div>
                </div>
              </div>
              <div class="form-element input-phone" [class.errors]="contactForm.get('mobile').invalid">
                <label for="input-mobile">
                  {{ 'general.contact.mobile' | translate }}
                </label>
                <input
                  [formControl]="contactForm.controls.mobile"
                  id="input-mobile"
                  name="mobile"
                  type="tel"
                  autocomplete="off"
                  placeholder="0032 X XX XX XX XX"
                />
                <div class="message">
                  <div *ngIf="contactForm.get('mobile').errors?.required">
                    {{ 'errorMessages.required' | translate }}
                  </div>
                </div>
              </div>
            </div>
            <div class="grid__line">
              <div class="form-element input-text" [class.errors]="contactForm.get('address.street').invalid">
                <label for="input-street">
                  {{ 'general.address.street' | translate }}
                </label>
                <input
                  [formControl]="contactForm.get('address.street')"
                  id="input-street"
                  name="street"
                  type="text"
                  autocomplete="off"
                />
                <div class="message">
                  <div *ngIf="contactForm.get('address.street').errors?.required">
                    {{ 'errorMessages.required' | translate }}
                  </div>
                  <div *ngIf="!contactForm.get('address.street').errors && contactForm.get('address.street').dirty">
                    {{ 'infoMessages.modified' | translate }}
                  </div>
                </div>
              </div>
              <div class="flex flex-row space-x-5">
                <div
                  class="flex-1 form-element input-text"
                  [class.errors]="contactForm.get('address.streetNumber').invalid"
                >
                  <label for="input-street-number">
                    {{ 'general.address.streetNumber' | translate }}
                  </label>
                  <input
                    [formControl]="contactForm.get('address.streetNumber')"
                    id="input-street-number"
                    name="street-number"
                    type="text"
                    autocomplete="off"
                  />
                  <div class="message">
                    <div *ngIf="contactForm.get('address.streetNumber').errors?.required">
                      {{ 'errorMessages.required' | translate }}
                    </div>
                    <div *ngIf="!contactForm.get('address.streetNumber').errors && contactForm.get('address.streetNumber').dirty">
                      {{ 'infoMessages.modified' | translate }}
                    </div>
                  </div>
                </div>
                <div
                  class="flex-1 form-element input-text"
                  [class.errors]="contactForm.get('address.boxNumber').invalid"
                >
                  <label for="input-street-number">
                    {{ 'general.address.boxNumber' | translate }}
                  </label>
                  <input
                    [formControl]="contactForm.get('address.boxNumber')"
                    id="input-box-number"
                    name="box-number"
                    type="text"
                    autocomplete="off"
                  />
                  <div class="message">
                    <div *ngIf="contactForm.get('address.boxNumber').errors?.required">
                      {{ 'errorMessages.required' | translate }}
                    </div>
                    <div *ngIf="!contactForm.get('address.boxNumber').errors && contactForm.get('address.boxNumber').dirty">
                      {{ 'infoMessages.modified' | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="grid__line">
              <div class="form-element input-number" [class.errors]="contactForm.get('address.zipCode').invalid">
                <label class="" for="input-zip-code">
                  {{ 'general.address.zipCode' | translate }}
                </label>
                <input
                  [formControl]="contactForm.get('address.zipCode')"
                  id="input-zip-code"
                  name="zip-code"
                  type="text"
                  autocomplete="off"
                />
                <div class="message">
                  <div *ngIf="contactForm.get('address.zipCode').errors?.required">
                    {{ 'errorMessages.required' | translate }}
                  </div>
                  <div *ngIf="!contactForm.get('address.zipCode').errors && contactForm.get('address.zipCode').dirty">
                    {{ 'infoMessages.modified' | translate }}
                  </div>
                </div>
              </div>
              <div class="form-element input-text" [class.errors]="contactForm.get('address.city').invalid">
                <label for="input-city">
                  {{ 'general.address.city' | translate }}
                </label>
                <input
                  [formControl]="contactForm.get('address.city')"
                  id="input-city"
                  name="street"
                  type="text"
                  autocomplete="off"
                />
                <div class="message">
                  <div *ngIf="contactForm.get('address.city').errors?.required">
                    {{ 'errorMessages.required' | translate }}
                  </div>
                  <div *ngIf="!contactForm.get('address.city').errors && contactForm.get('address.city').dirty">
                    {{ 'infoMessages.modified' | translate }}
                  </div>
                </div>
              </div>
            </div>

            <div class="grid__line">
              <button
                type="submit"
                [attr.disabled]="!contactForm.valid || contactForm.pristine || contactDetails?.locked ? '' : null"
                [class.not-submittable]="(!contactForm.valid || contactForm.pristine) && !contactDetails?.locked"
                class="btn btn-primary inline-block w-full font-bold mb-4 md:mb-0 md:w-72"
                [analyticsEvent]="'contact:click:update-contact-details'"
              >
                {{ 'general.update' | translate }}
              </button>
            </div>
          </form>
        </div>
      </div>

      <div class="mt-16 md:mt-0 md:col-span-4">
        <app-chat-cta></app-chat-cta>
      </div>
    </div>
  </div>
</div>
