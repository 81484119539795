<div class="relative h-full">
  <div *ngIf="!error" class="h-full comparison-wrapper">
    <ng-container *ngIf="data$ | async as data; else loading">
      <div class="comparison-container relative card-primary-small w-full h-full p-8">
        <div class="flex flex-col h-full relative">
          <div class="flex justify-between gap-x-8">
            <div class="flex-1">
              <h3 class="text-grey-dark text-lg font-medium">
                {{ 'components.widgets.consumptionComparison.' + (energyType | lowercase) + '.title' | translate }}
              </h3>
            </div>
            <app-tooltip
              [tooltip]="'components.widgets.consumptionComparison.' + (energyType | lowercase) + '.tooltip.label'"
            >
            </app-tooltip>
          </div>
          <div *ngIf="data?.length >= 2; else noEnoughData" class="chart-container">
            <div
              *ngFor="let dataItem of data | slice : 0 : 3"
              class="consumption-bar-wrapper flex relative h-full items-end"
            >
              <span class="absolute popup-hover h-full w-full"></span>
              <div
                class="consumption-bar"
                [style]="
                  'height:' + (maxConsumption > 0 ? (dataItem['totalVolumes'] / maxConsumption) * 100 : 100) + '%'
                "
              >
                <div class="h-full overflow-hidden">
                  <div class="popup">
                    <h3 class="text-base font-medium mb-4">
                      {{
                        dataItem?.previousMeteringDate
                          | date : 'MMM yyyy' : undefined : facade.translate.currentLang + '-BE'
                          | titlecase
                      }}
                      -
                      {{
                        dataItem?.currentMeteringDate
                          | date : 'MMM yyyy' : undefined : facade.translate.currentLang + '-BE'
                          | titlecase
                      }}
                    </h3>
                    <div *ngIf="energyType === energyTypes.ELECTRICITY" class="flex items-center">
                      <span class="text-sm font-medium">
                        {{ 'components.widgets.consumptionComparison.consumption' | translate }}
                      </span>
                      <span class="text-base font-medium ml-auto">
                        {{ dataItem['totalVolumes'] | number : '1.0-0' : facade.translate.currentLang + '-BE' }}kWh
                      </span>
                    </div>
                    <ul class="mt-4">
                      <li
                        *ngFor="let volume of dataItem?.volumes; let i = index"
                        class="flex text-sm relative pl-4 {{ volume?.direction | lowercase }} {{
                          volume?.register | lowercase
                        }} energy-{{ energyType | lowercase }}"
                      >
                        <span>
                          {{
                            energyType === energyTypes.ELECTRICITY
                              ? ('general.meters.' + volume?.register + '.title' | translate)
                              : ('general.product.gas' | translate)
                          }}
                        </span>
                        <span class="font-bold ml-auto text-grey-dark60">
                          {{ volume?.value | number : '1.0-0' : facade.translate.currentLang + '-BE' }}
                          {{ volume?.unit }}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <span
                    *ngFor="let volume of dataItem?.volumes; let i = index"
                    class="w-full inline-block rounded-t-2xl energy-{{ energyType | lowercase }} {{
                      volume?.direction | lowercase
                    }} bar-{{ (volume?.value > 0 ? volume?.register : 'none') | lowercase }}"
                    [class.h-full]="volume?.value === 0 && dataItem?.totalVolumes === 0"
                    [class.-mb-4]="dataItem?.volumes.length > 1 && i !== dataItem?.volumes.length - 1"
                    [style]="
                      'height: ' +
                      (dataItem?.totalVolumes === 0 && i > 0
                        ? '0px'
                        : dataItem?.volumes.length > 1 && i !== 0
                        ? 'calc(' + (volume.value / dataItem.totalVolumes) * 100 + '% + 1rem)'
                        : (volume.value / dataItem.totalVolumes) * 100 + '%')
                    "
                  >
                  </span>
                </div>
                <div class="leading-tight text-center mt-2">
                  <div class="flex items-center">
                    <div class="flex-1">
                      <span class="font-medium text-2xs inline-block w-full">
                        {{
                          dataItem?.previousMeteringDate
                            | date : 'MMM' : undefined : facade.translate.currentLang + '-BE'
                            | titlecase
                        }}
                      </span>
                      <span class="font-medium text-sm inline-block w-full">
                        {{
                          dataItem?.previousMeteringDate
                            | date : 'yyyy' : undefined : facade.translate.currentLang + '-BE'
                            | titlecase
                        }}
                      </span>
                    </div>
                    -
                    <div class="flex-1">
                      <span class="font-medium text-2xs inline-block w-full">
                        {{
                          dataItem?.currentMeteringDate
                            | date : 'MMM' : undefined : facade.translate.currentLang + '-BE'
                            | titlecase
                        }}
                      </span>
                      <span class="font-medium text-sm inline-block w-full">
                        {{
                          dataItem?.currentMeteringDate
                            | date : 'yyyy' : undefined : facade.translate.currentLang + '-BE'
                            | titlecase
                        }}
                      </span>
                    </div>
                  </div>
                  <span class="font-bold text-xs text-grey-dark60">
                    {{ dataItem['totalVolumes'] | number : '1.0-0' : facade.translate.currentLang + '-BE' }}kWh
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-template #loading>
    <app-loader-widget></app-loader-widget>
  </ng-template>

  <ng-template #noEnoughData>{{ 'components.widgets.noData.description' | translate }}</ng-template>

  <app-error-widget *ngIf="error"></app-error-widget>
</div>
