import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductSwapService {
  constructor(private http: HttpClient) {}

  triggerProductSwap(reference: string, payload: ProductSwapPayload): Observable<void> {
    return this.http
      .post<void>(`/v1/customers/${reference}/swap-product`, payload)
      .pipe(catchError((error) => throwError(error)));
  }
}

export interface ProductSwapPayload {
  contractStartDate: string;
}
