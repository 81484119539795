import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-power-peak-q-and-a',
  templateUrl: './power-peak-q-and-a.component.html',
  styleUrls: ['./power-peak-q-and-a.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({
          transform: 'translate(0, -2rem)',
          opacity: '0',
        }),
        animate('0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94)', style({ transform: 'translate(0, 0)', opacity: 1 })),
      ]),
    ]),
  ],
})
export class PowerPeakQAndAComponent implements OnInit {
  public readonly textPath = 'components.consumption.powerPeak.questionsAndAnswers.0';
  public activeBox = false;
  constructor() {}

  ngOnInit(): void {}

  toogle() {
    this.activeBox = !this.activeBox;
  }
}
