import { Injectable } from '@angular/core';
import { AppConfig } from '@app/shared/utils/app-config';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private isLoaded = false;
  private gtmKey: string;

  private browserGlobals = {
    windowRef: () => window,
    documentRef: () => document,
  };

  constructor(private conf: AppConfig) {
    this.gtmKey = conf.config.googleTagManagerKey;

    this.initAnalytics();
  }

  public openConsent() {
    this.browserGlobals.windowRef()['tC'].privacyCenter.showPrivacyCenter();
  }

  public push(event) {
    const dataLayer = this.getDataLayer();
    dataLayer.push(event);
  }

  private initAnalytics() {
    const doc = this.browserGlobals.documentRef();

    const styleTag = doc.head.querySelector('style');

    const gtmScript = doc.createElement('script');
    gtmScript.id = 'GTMscript';
    gtmScript.async = true;
    gtmScript.src = '//www.googletagmanager.com/gtm.js?id=' + this.gtmKey;
    doc.head.insertBefore(gtmScript, doc.head.firstChild);

    const antiFlickeringScript = doc.createElement('script');
    antiFlickeringScript.text = `(function (a, s, y, n, c, h, i, d, e) {
      s.className += ' ' + y;
      h.start = 1 * new Date();
      h.end = i = function () {
        s.className = s.className.replace(RegExp(' ?' + y), '');
      };
      (a[n] = a[n] || []).hide = h;
      setTimeout(function () {
        i();
        h.end = null;
      }, c);
      h.timeout = c;
    })(window, document.documentElement, 'async-hide', 'dataLayer', 4000, { '${this.gtmKey}': true });
    `;

    doc.head.insertBefore(antiFlickeringScript, gtmScript);
    doc.head.insertBefore(styleTag, antiFlickeringScript);

    const ifrm = doc.createElement('iframe');
    ifrm.setAttribute('src', '//www.googletagmanager.com/ns.html?id=' + this.gtmKey);
    ifrm.style.width = '0';
    ifrm.style.height = '0';
    ifrm.style.display = 'none';
    ifrm.style.visibility = 'hidden';

    const noscript = doc.createElement('noscript');
    noscript.id = 'GTMiframe';
    noscript.appendChild(ifrm);

    doc.body.insertBefore(noscript, doc.body.firstChild);
    this.isLoaded = true;
    this.push({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
  }

  private getDataLayer() {
    const window = this.browserGlobals.windowRef();
    window['dataLayer'] = window['dataLayer'] || [];
    return window['dataLayer'];
  }
}
