import { Component } from '@angular/core';
import { MainFacade } from '@app/core/facade/main.facade';

@Component({
  selector: 'app-eliq-tab',
  templateUrl: './eliq-tab.component.html',
  styleUrls: ['./eliq-tab.component.scss'],
})
export class EliqTabComponent {
  eliqAccessRights$;
  constructor(private facade: MainFacade) {
    this.eliqAccessRights$ = this.facade.eliqAccessRights$;
  }
}
