import { Component, Input } from '@angular/core';
import { Address } from '@app/shared/models/address.interface';

@Component({
  selector: 'app-contact-address',
  templateUrl: './contact-address.component.html',
  styleUrls: ['./contact-address.component.scss'],
})
export class ContactAddressComponent {
  @Input() address: Address;
}
