import { RegisterType, RegisterTypeExtra } from '@app/modules/customer-zone/consumption/models/consumption.interface';

export interface Dgo {
  gridOperator: string;
  reference: string;
  name: string;
  gln: string;
}

export interface Meter {
  meterNumber: string;
  dgo: Dgo;
  smartMeter: boolean;
  registers: Register[];
  fluvius: boolean;
}

export interface Register {
  timeFrame: string | RegisterType | RegisterTypeExtra;
  direction: Direction;
}

export enum Direction {
  production = 'PRODUCTION', // Production and injection have same meaning - produce energy
  injection = 'INJECTION',
  consumption = 'CONSUMPTION',
  offtake = 'OFFTAKE', // For gas consumption
}

export interface DeliveryPointsPerReference {
  [reference: string]: MetersPerDeliveryPoint;
}

export interface MetersPerDeliveryPoint {
  [deliveryPoint: string]: Meter[];
}
