import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of, shareReplay, tap, throwError } from 'rxjs';
import { PaymentPlanCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Injectable({
  providedIn: 'root',
})
export class PaymentPlanService {
  private readonly httpClient: HttpClient = inject(HttpClient);
  private cache: Map<string, PaymentPlanCuzoApi> = new Map<string, PaymentPlanCuzoApi>();

  getPaymentPlan(reference: string): Observable<PaymentPlanCuzoApi> {
    const cacheKey: string = reference;
    if (this.cache.has(cacheKey)) {
      return of(this.cache.get(cacheKey));
    }
    return this.httpClient.get<PaymentPlanCuzoApi>(`/v1/customers/${reference}/payment-plan`).pipe(
      catchError((err): Observable<PaymentPlanCuzoApi> => throwError(err)),
      tap((value: PaymentPlanCuzoApi) => this.cache.set(cacheKey, value)),
      shareReplay(1)
    );
  }
}
