import { Component, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { Alert } from '../../../../../shared/components/alert/alert.interface';

@Component({
  selector: 'app-add-reference',
  templateUrl: './add-reference.component.html',
  styleUrls: ['./add-reference.component.scss'],
})
export class AddReferenceComponent implements OnInit {
  @Input() bindingError: Alert | null;
  @Input() referenceError: boolean;
  @Input() reference: string;
  @Output() stepInfoEmitter: EventEmitter<{
    params: Record<string, unknown>;
    step: number;
  }> = new EventEmitter<{ params: Record<string, unknown>; step: number }>();
  @Output() referenceErrorEmitter: EventEmitter<{
    errorVariable: string;
    errorStatus: boolean;
  }> = new EventEmitter<{ errorVariable: string; errorStatus: boolean }>();
  public referenceForm: UntypedFormGroup;

  constructor(public formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.buildForms();
  }

  public buildForms() {
    this.referenceForm = this.formBuilder.group({
      reference: [this.reference],
    });
  }

  public handleReferenceSubmit() {
    const reference = this.referenceForm.get('reference').value;
    if (reference.length > 0 && !this.referenceError) {
      this.stepInfoEmitter.emit({ params: { reference }, step: 2 });
    }
  }

  public checkReference() {
    const reference = this.referenceForm.get('reference').value;
    this.referenceErrorEmitter.emit({
      errorVariable: 'referenceError',
      errorStatus: reference[0] !== 'E' && reference.length > 0,
    });
  }
}
