import { ChartArea } from 'chart.js';
import { CtxFont, ChartUnit } from '../models/chart.enums';
import { ChartPoint } from '../models/chart.interfaces';

export default class Utils {
  public static getCtxFont(fontSize = 12, bold = false, family = CtxFont.roboto): string {
    return `${bold ? CtxFont.bold : ''} ${fontSize}px ${family}`;
  }

  public static getValueWithUnit(value: number, unit: ChartUnit): string {
    return `${value} ${unit}`;
  }

  public static getArea(element: any, borderWidth: number, horizontal: boolean): ChartArea {
    if (horizontal) {
      const half = element.height / 2;

      return {
        top: element.y - half,
        bottom: element.y + half,
        right: Math.max(element.x, element.base),
        left: Math.min(element.x, element.base),
        width: null,
        height: null,
      };
    }
    const half = element.width / 2;

    return {
      left: element.x - half,
      right: element.x + half,
      top: Math.min(element.y, element.base),
      bottom: Math.max(element.y, element.base),
      width: null,
      height: null,
    };
  }

  public static drawLine(ctx: CanvasRenderingContext2D, point: ChartPoint, skipped: boolean = false) {
    if (!skipped) {
      ctx.lineTo(point.x, point.y);
    } else {
      ctx.moveTo(point.x, point.y);
    }
  }
}
