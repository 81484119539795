import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { take, takeUntil } from 'rxjs';
import { Site } from '../../../user/models/site.interface';
import { Observable, Subject } from 'rxjs';
import { EncodeIndexCtaVersion } from '@app/shared/components/encode-index-cta/encode-index-cta.component';
import { Meter, Register } from '../../models/deliveryPoint.interface';
import { RegisterType } from '../../models/consumption.interface';
import { VersionSize } from '@app/shared/models/units.interface';
import { MainFacade } from '@app/core/facade/main.facade';
import { ContractDtoCuzoApi, ContractDtoTypeEnumCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Component({
  selector: 'app-meter-reading',
  templateUrl: './meter-reading.component.html',
  styleUrls: ['./meter-reading.component.scss'],
})
export class MeterReadingComponent implements OnInit {
  public encodeIndexCtaVersion = EncodeIndexCtaVersion;
  public sites$: Observable<Site[]>;
  public activeSite: Site;
  public activeReference: string;
  public activeSiteId: string;
  public notifier = new Subject<void>();
  public contracts$: Observable<ContractDtoCuzoApi[]>;
  public active = 1;
  public selectedContract: ContractDtoCuzoApi | ContractDtoTypeEnumCuzoApi;
  public meterType: string;
  public showEncodingCTA = true;
  public versionSize = VersionSize;

  constructor(private facade: MainFacade, private cd: ChangeDetectorRef) {}

  get contractTypes() {
    return ContractDtoTypeEnumCuzoApi;
  }
  get selectedContractCast() {
    return this.selectedContract as ContractDtoCuzoApi;
  }

  ngOnInit(): void {
    this.facade.utils.setPageTitle('pages.meterReading.pageTitle');
    this.facade.activeSite$.pipe(takeUntil(this.notifier)).subscribe((site: Site) => {
      this.activeReference = this.facade.state$.value.reference;
      this.sites$ = this.facade.sites$;
      this.contracts$ = this.facade.contracts$;
      this.activeSiteId = site?.id;
      this.activeSite = site;
    });
  }

  public switchSite(siteId: string) {
    this.facade.updateActiveSite(siteId);
  }

  public updateSelectedContract(contract: ContractDtoCuzoApi | ContractDtoTypeEnumCuzoApi) {
    this.selectedContract = contract;
    this.meterType = null;
    if ((contract as ContractDtoCuzoApi).deliveryPointReference) {
      this.getDeliveryPoint(contract as ContractDtoCuzoApi);
    }
  }

  public getDeliveryPoint(contract: ContractDtoCuzoApi) {
    if (!this.activeSite) {
      return;
    }
    this.facade
      .loadDeliveryPoint(this.activeReference, this.activeSite, contract)
      .pipe(take(1))
      .subscribe((meters: Meter[]) => (this.meterType = this.getMeterType(meters)));
  }

  public getMeterType(meters: Meter[]) {
    let fullMeterName = '';
    meters.forEach((meter, i) => {
      let meterName: string = null;
      const isSmart = meter.smartMeter;
      const isMono = meter.registers.some(
        (register: Register) =>
          register.timeFrame === RegisterType.TOTAL_HOUR || register.timeFrame === RegisterType.MONO
      );
      const isExlusiveNight = meter.registers.some(
        (register: Register) => register.timeFrame === RegisterType.NIGHT_EXCLUSIVE
      );
      meterName = isMono ? 'mono' : 'dual';
      meterName += isExlusiveNight ? 'ExclusiveNight' : '';
      meterName = isSmart ? `smart${meterName.charAt(0).toUpperCase()}${meterName.slice(1)}` : meterName;
      // Concat meter name since we can have more than one
      fullMeterName += this.facade.translate.instant(`general.meterType.${meterName}`) || '';
      fullMeterName += i < meters.length - 1 ? ' + ' : '';
    });
    return fullMeterName;
  }

  public getIndexEncodingStatus(showEncodingCTA) {
    this.showEncodingCTA = showEncodingCTA;
    this.cd.detectChanges();
  }
}
