import { Pipe, PipeTransform } from '@angular/core';
import { isObservable, map, startWith, catchError, of, Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { isArray } from 'chart.js/helpers';

export interface ObsWithStatusResult<T> {
  loading?: boolean;
  value?: T;
  error?: HttpErrorResponse | string;
}

// Note: this pipe is useful for combining Latest operator or first subscription
@Pipe({
  name: 'observableLoadingError',
})
export class ObservableLoadingErrorPipe implements PipeTransform {
  transform<T = any>(val: Observable<T>): Observable<ObsWithStatusResult<T>> {
    return isObservable(val)
      ? val.pipe(
          map((value: any) => ({ loading: false, error: !value || (isArray(value) && value?.length === 0), value })),
          startWith({ loading: true }),
          catchError((error) => of({ loading: false, error }))
        )
      : val;
  }
}
