import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable, of, tap } from 'rxjs';
import {
  Advance,
  AdvancePerReference,
  AdvanceSaved,
  AdvanceUpdate,
  AdvanceViewData,
  CanChangeInstallmentPerRef,
  RegularizationFrequencyType,
  UnallowedReasonDefinedByAPI,
  UnallowedReasonReturnedByEL1,
  UnallowedReasons,
} from '../../models/advance.interface';
import {
  AdvancesChangeOrigin,
  MyEnergyActionsPerEAN,
} from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { UtilsService } from '@app/shared/utils/utils.service';
import { Alert } from '@app/shared/components/alert/alert.interface';

@Injectable({
  providedIn: 'root',
})
export class AdvancesService {
  private advances: AdvancePerReference = {};
  private canChangeInstallment: CanChangeInstallmentPerRef = {};

  constructor(private http: HttpClient) {}

  public updateAdvances(reference: string, siteId: string, advances: Advance[] | null): void {
    this.advances[reference][siteId] = advances;
  }

  public siteHasAdvances(reference: string, siteId: string) {
    return this.advances && this.advances[reference] && this.advances[reference][siteId];
  }

  public getAdvances(reference: string, siteId: string, loader: boolean = false): Observable<Advance[]> {
    if (this.siteHasAdvances(reference, siteId)) {
      return of(this.advances[reference][siteId]);
    }
    return this.getAdvancesFromApi(reference, siteId, loader);
  }

  public getAdvancesFromApi(reference: string, siteId: string, loader = false): Observable<Advance[]> {
    const params = new HttpParams().set('loader', loader.toString());
    return this.http.get<Advance[]>(`/v1/customers/${reference}/sites/${siteId}/advances`, { params }).pipe(
      tap((advances: Advance[]) => {
        if (!this.advances[reference]) {
          this.advances[reference] = {};
        }
        this.updateAdvances(reference, siteId, advances);
      })
    );
  }

  public postAdvancesToApi(
    reference: string,
    siteId: string,
    advances: AdvanceUpdate,
    advancesChangeOrigin: AdvancesChangeOrigin,
    loader = false
  ): Observable<AdvanceSaved> {
    const params = new HttpParams().set('loader', loader.toString());
    const payload = { ...advances, origin: advancesChangeOrigin };
    return this.http.put<AdvanceSaved>(`/v1/customers/${reference}/sites/${siteId}/advances`, payload, { params });
  }

  public updateCanChangeInstallment(reference: string, siteId: string, actions: MyEnergyActionsPerEAN): void {
    this.canChangeInstallment[reference][siteId] = actions;
  }

  public getCanChangeInstallment(
    reference: string,
    siteId: string,
    deliveryPoints: string[],
    loader = true
  ): Observable<MyEnergyActionsPerEAN> {
    return this.getCanChangeInstallmentApi(reference, siteId, deliveryPoints, loader);
  }

  public getCanChangeInstallmentApi(
    reference: string,
    siteId: string,
    deliveryPoints: string[],
    loader = true
  ): Observable<MyEnergyActionsPerEAN> {
    const params = new HttpParams().set('loader', loader.toString());

    return this.http
      .post<{ deliveryPointsInformation: MyEnergyActionsPerEAN }>(
        `/v1/customers/${reference}/sites/${siteId}/myenergy-flow`,
        { deliveryPoints },
        { params }
      )
      .pipe(
        tap((value: { deliveryPointsInformation: MyEnergyActionsPerEAN }) => {
          if (!this.canChangeInstallment[reference]) {
            this.canChangeInstallment[reference] = {};
          }
          this.updateCanChangeInstallment(reference, siteId, value.deliveryPointsInformation);
        }),
        map((value: { deliveryPointsInformation: MyEnergyActionsPerEAN }) => value.deliveryPointsInformation)
      );
  }

  public getAlertIfNotAllowedToChangeDeposit(
    advance: Partial<Advance>,
    alerts: Record<string, Alert>
  ): Omit<AdvanceViewData, 'showMoreFeatures'> {
    const firstUnallowedReason = advance?.unallowedReasons?.length >= 1 && advance?.unallowedReasons[0];

    if (advance?.budgetMeter) {
      return {
        alertBox: alerts.activeBudgetMeter,
        unallowedReasonForView: UnallowedReasonDefinedByAPI.BUDGET_METER,
      };
    } else if (advance?.regularizationFrequency === RegularizationFrequencyType.MONTH && advance?.smartMeter) {
      return {
        alertBox: alerts.monthlyRegularizedInvoice,
        unallowedReasonForView: UnallowedReasons.MONTHLY_REGULARIZED_INVOICE,
      };
    }
    const oneMessageForSeveralreasons = [
      UnallowedReasons.NOT_ADVANCE,
      UnallowedReasons.NO_VOLUMES,
      UnallowedReasons.NO_PRICE,
      UnallowedReasons.NO_MASTERDATA,
    ];
    if (oneMessageForSeveralreasons.includes(firstUnallowedReason)) {
      return { alertBox: alerts.missingData, unallowedReasonForView: UnallowedReasonDefinedByAPI.MISSING_DATA };
    } else if (firstUnallowedReason === UnallowedReasonReturnedByEL1.INVALID_AMOUNT) {
      return { alertBox: alerts.invalidAmount, unallowedReasonForView: UnallowedReasons.INVALID_AMOUNT };
    }
    const reasonToCamelCase = firstUnallowedReason
      ? UtilsService.toCamel(firstUnallowedReason.toLocaleLowerCase())
      : null;

    if (firstUnallowedReason && alerts[reasonToCamelCase] !== null) {
      return {
        alertBox: alerts[reasonToCamelCase],
        unallowedReasonForView: firstUnallowedReason,
      };
    }
    return {
      alertBox: null,
      unallowedReasonForView: null,
    };
  }
}
