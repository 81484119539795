<app-modal title="{{ 'components.contact.changeAddress.title' | translate }}">
  <div class="contact-change">
    <h5 class="text-grey-dark font-medium text-2xl my-8">
      {{ 'components.contact.changeAddress.intro' | translate }}
    </h5>
    <form [formGroup]="addressForm" novalidate class="form">
      <div class="md:grid md:grid-cols-4 gap-8">
        <div
          class="form-element input-text street col-span-2"
          [class.errors]="!addressForm.get('street').pristine && addressForm.get('street').invalid"
        >
          <label for="input-street">
            {{ 'general.address.street' | translate }}
          </label>
          <input
            [formControl]="addressForm.controls.street"
            id="input-street"
            name="street"
            type="text"
            autocomplete="off"
          />
          <div class="message">
            <div *ngIf="addressForm.get('street').errors?.required">{{ 'errorMessages.required' | translate }}</div>
          </div>
        </div>
        <div
          class="form-element input-text street-number col-span-1"
          [class.errors]="!addressForm.get('streetNumber').pristine && addressForm.get('streetNumber').invalid"
        >
          <label for="input-street-number">
            {{ 'general.address.streetNumber' | translate }}
          </label>
          <input
            [formControl]="addressForm.controls.streetNumber"
            id="input-street-number"
            name="street-number"
            type="text"
            autocomplete="off"
          />
          <div class="message">
            <div *ngIf="addressForm.get('streetNumber').errors?.required">
              {{ 'errorMessages.required' | translate }}
            </div>
          </div>
        </div>
        <div class="form-element input-text box-number col-span-1">
          <label for="input-box-number">
            {{ 'general.address.boxNumber' | translate }}
          </label>
          <input
            [formControl]="addressForm.controls.boxNumber"
            id="input-box-number"
            name="box-number"
            type="text"
            autocomplete="off"
          />
          <div class="message"></div>
        </div>
      </div>

      <div class="md:grid md:grid-cols-2 gap-8">
        <div
          class="form-element input-text zip-code"
          [class.errors]="!addressForm.get('zipCode').pristine && addressForm.get('zipCode').invalid"
        >
          <label class="form__label" for="input-street-number">
            {{ 'general.address.zipCode' | translate }}
          </label>
          <input
            [formControl]="addressForm.controls.zipCode"
            id="input-zip-code"
            name="zip-code"
            type="text"
            autocomplete="off"
          />
          <div class="message">
            <div *ngIf="addressForm.get('zipCode').errors?.required">{{ 'errorMessages.required' | translate }}</div>
          </div>
        </div>
        <div
          class="form-element input-text city"
          [class.errors]="!addressForm.get('city').pristine && addressForm.get('city').invalid"
        >
          <label for="input-street-number">
            {{ 'general.address.city' | translate }}
          </label>
          <input [formControl]="addressForm.controls.city" id="input-city" name="city" type="text" autocomplete="off" />
          <div class="message">
            <div *ngIf="addressForm.get('city').errors?.required">{{ 'errorMessages.required' | translate }}</div>
          </div>
        </div>
      </div>
      <div class="md:flex md:justify-center gap-8 mt-4 md:mt-8">
        <button
          class="btn btn-primary w-full"
          [attr.disabled]="!addressForm.valid ? '' : null"
          [class.not-submittable]="!addressForm.valid"
          (click)="handleSubmit()"
          [analyticsEvent]="'addressChange:click:update-address'"
        >
          {{ 'general.update' | translate }}
        </button>
        <button
          class="btn btn-secondary w-full mt-8 md:mt-0"
          (click)="activeModal.close()"
          [analyticsEvent]="'addressChange:click:cancel-address-update'"
        >
          {{ 'general.cancel' | translate }}
        </button>
      </div>
    </form>
  </div>
</app-modal>
