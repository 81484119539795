import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-image-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './image-icon.component.html',
  styleUrls: ['./image-icon.component.scss'],
})
export class ImageIconComponent {
  @Input() altDescription: string;
  @Input() imagePath?: string;
  @Input() imageSize?: { width: number; height: number };
  @Input() text?: string;
  @Input() textStyle?: Record<string, unknown>;
  @Input() align: string;
  @Input() ghost?: any = false;

  @Input() fallbackImg?: string;
  @Input() showImageIcon = true;

  public hideImageIcon($event) {
    if (this.fallbackImg) {
      this.imagePath = this.fallbackImg;
    } else {
      this.showImageIcon = false;
    }
  }
}
