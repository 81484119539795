import { Injectable } from '@angular/core';
import { FaqItem } from '../models/faqItem.interface';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, tap } from 'rxjs';
import { AppConfig } from '@app/shared/utils/app-config';

@Injectable({
  providedIn: 'root',
})
export class FaqService {
  private faqItems: any = {};

  constructor(private http: HttpClient, private conf: AppConfig) {}

  public getFaqItems(langcode: string, tag: string): Observable<FaqItem[]> {
    if (this.faqItems && this.faqItems[langcode] && this.faqItems[langcode][tag]) {
      return of(this.faqItems[langcode][tag]);
    }

    return this.getFaqItemsFromApi(langcode, tag).pipe(
      tap((data) => {
        if (!this.faqItems[langcode]) {
          this.faqItems[langcode] = {};
        }
        this.faqItems[langcode][tag] = data;
      })
    );
  }

  public getFaqItemsFromApi(langcode: string, tag: string): Observable<FaqItem[]> {
    const endpoint = `${this.conf.config.url.website}/${langcode}/jsonapi/node/faq`;
    const params = new HttpParams()
      .set('include', 'field_faq_tags')
      .set('filter[field_faq_tags.name][condition][path]', 'field_faq_tags.name')
      .set('filter[field_faq_tags.name][condition][value]', tag)
      .set('filter[status][value]', '1')
      .set('sort', '-created');

    return this.http.get<FaqItem[]>(endpoint, { params }).pipe(
      map((data: any) => {
        const items = [];
        data.data.forEach((article) => {
          const item = {
            title: article.attributes.title,
            abstract: article.attributes.field_faq_abstract.processed,
            url: `${this.conf.config.url.website}/${langcode}${article.attributes.path.alias}`,
          } as FaqItem;

          items.push(item);
        });
        return items;
      })
    );
  }
}
