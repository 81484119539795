import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Alert } from './alert.interface';
import { takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit, OnDestroy {
  @Input() flatDesign?: boolean = false;
  @Input() set alert(value: Alert) {
    this._alert = value;
    if (this.translateKeys) {
      this.setKeys();
    }
  }
  @Input() options?: { template: string } = { template: 'row' };
  @Output() closeAlert = new EventEmitter<void>();

  private notifier = new Subject<void>();
  private translateKeys: boolean = false;
  _alert: Alert;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.translateKeys = !!(this._alert?.content?.messageKey || this._alert?.content?.titleKey);
    if (this.translateKeys) {
      this.setKeys();
      this.translate.onLangChange.pipe(takeUntil(this.notifier)).subscribe(() => this.setKeys());
    }
  }

  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
  }

  setKeys = () => {
    if (this._alert?.content?.messageKey) {
      this.setContentFromKey('message', this._alert?.content?.messageKey);
    }
    if (this._alert?.content?.titleKey) {
      this.setContentFromKey('title', this._alert?.content?.titleKey);
    }
  };

  closeAlertBox(): void {
    this.closeAlert.emit();
  }

  private setContentFromKey(content, key) {
    this._alert.content[content] = this.translate.instant(key, this._alert.content.messageInterpolateParams);
  }
}
