import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllContractsComponent } from './pages/all-contracts/all-contracts.component';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { ContractComponent } from './components/contract/contract.component';
import { ContractsComponent } from './components/contracts/contracts.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AnalyticsModule } from '../../../shared/modules/analytics/analytics.module';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { OtherContractsComponent } from './pages/other-contracts/other-contracts.component';
import { RegisterFixProductComponent } from '../../../shared/standalone/register-fix-product/register-fix-product.component';

@NgModule({
  declarations: [AllContractsComponent, ContractComponent, ContractsComponent, OtherContractsComponent],
  imports: [
    CommonModule,
    SharedModule,
    HttpClientModule,
    TranslateModule,
    AnalyticsModule,
    RegisterFixProductComponent,
  ],
})
export class ContractsModule {}

_('pages.contracts.pageTitle');
_('components.contracts.products.ELECTRICITY');
_('components.contracts.products.GAS');
_('components.contracts.products.GAS+ELECTRICITY');
_('components.contracts.products.ASSISTANCE');
_('components.contracts.products.BOILER_MAINTENANCE');
_('components.contracts.products.assistance-elec');
_('components.contracts.products.assistance-gas-elec');
_('components.contracts.products.assistance-solar');
_('components.contracts.contract.tariff_type.fixe');
_('components.contracts.contract.tariff_type.social');
_('components.contracts.contract.tariff_type.variable');
_('components.contracts.products_description.ASSISTANCE');
_('components.contracts.products_description.BOILER_MAINTENANCE');
_('components.contracts.products_description.BOILER_MAINTENANCE_ONE_SHOT');
_('components.contracts.products_description.BOILER_REPAIR');
_('components.contracts.products_description.assistance-elec');
_('components.contracts.products_description.assistance-elec');
_('components.contracts.products_description.assistance-solar');
_('components.contracts.products_description.emobility.help');
_('components.contracts.products_description.online.help');
_('components.contracts.products_description.solar.help');
_('components.contracts.products_description.tip.help');
_('components.contracts.products_description.top.help');
_('components.contracts.products_description.pixel.help');
_('components.contracts.products_description.pixel-blue.help');
_('components.contracts.products_description.pixel-pro.help');
_('components.contracts.products_description.pixel-blue-pro.help');
_('components.contracts.modal.title.ASSISTANCE');
_('components.contracts.modal.title.BOILER_MAINTENANCE');
_('components.contracts.modal.title.BOILER_MAINTENANCE_ONE_SHOT');
_('components.contracts.modal.title.BOILER_REPAIR');
_('components.contracts.modal.title.ELECTRICITY');
_('components.contracts.modal.title.GAS');
_('components.contracts.modal.tariffNames.mono');
_('components.contracts.modal.tariffNames.nightTimeOnly');
_('components.contracts.modal.tariffNames.offPeak');
_('components.contracts.modal.tariffNames.peak');
_('components.contracts.modal.tariffNames.socialTariffEndDate');
_('components.contracts.modal.tariffNames.socialTariffStartDate');
_('components.contracts.modal.socialTariffGrid.electricity');
_('components.contracts.modal.socialTariffGrid.gas');
_('components.contracts.prices.fixe');
_('components.contracts.prices.social');
_('components.contracts.prices.variable');
_('components.contracts.contract.links.showInvoice');
_('components.contracts.duration.infinite');
_('components.contracts.modal.downloadYourContract');
_('components.contracts.products_description.assistance-gas-elec');
_('components.contracts.contract.links.downloadPricingGrid.PRODUCT');
_('components.contracts.contract.links.downloadPricingGrid.INJECTION');
_('components.contracts.contract.links.downloadPricingGrid.TARIFF_SOCIAL');
_('components.contracts.contract.links.downloadPricingGrid.tooltip');
_('components.contracts.registerFix.productDescription.0');
_('components.contracts.registerFix.productDescription.1');
_('components.contracts.registerFix.productDescription.2');
_('components.contracts.registerFix.productDescription.3');
_('components.contracts.registerFix.productAdvantages.features.0');
_('components.contracts.registerFix.productAdvantages.features.1');
_('components.contracts.registerFix.productAdvantages.features.2');
_('components.contracts.registerFix.productAdvantages.features.3');
_('components.contracts.registerFix.prodcutSwapSubmitted.processSteps.0.title');
_('components.contracts.registerFix.prodcutSwapSubmitted.processSteps.0.description');
_('components.contracts.registerFix.prodcutSwapSubmitted.processSteps.1.title');
_('components.contracts.registerFix.prodcutSwapSubmitted.processSteps.1.description');
_('components.contracts.registerFix.prodcutSwapSubmitted.processSteps.2.title');
_('components.contracts.registerFix.prodcutSwapSubmitted.processSteps.2.description');
