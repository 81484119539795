<div *ngIf="show" class="relative h-full">
  <div *ngIf="!error" class="h-full box-shadow-small rounded-2xl">
    <ng-container *ngIf="data$ | async as data; else loading">
      <ng-container *ngIf="data?.consumption?.length && data?.forecast?.length; else noData">
        <div
          class="relative rounded-2xl bg-white w-full h-full p-8 wrapper-small"
          [ngClass]="energyType === EnergyType.ELECTRICITY ? 'box-shadow-blue-light' : 'box-shadow-blue-dark'"
        >
          <div class="flex items-center">
            <div class="flex-1">
              <h3
                class="text-grey-dark text-lg font-medium"
                [innerHTML]="
                  'components.widgets.consumptionForecast.' + (energyType | lowercase) + '.title' | translate
                "
              ></h3>
              <span class="text-grey-dark60 font-medium">
                {{
                  data?.consumption[0].previousMeteringDate
                    | date : 'MMMM yyyy' : undefined : facade.translate.currentLang + '-BE'
                    | titlecase
                }}
              </span>
            </div>
            <app-tooltip
              [tooltip]="
                'components.widgets.consumptionForecast.' +
                  (energyType | lowercase) +
                  '.tooltip.' +
                  (data?.consumption[0].volumes[0].value >= data?.forecast[0].volumes[0].value ? 'warning' : 'label')
                  | translate
              "
              class="mx-8 z-10"
              [isWarning]="data?.consumption[0].volumes[0].value >= data?.forecast[0].volumes[0].value"
            ></app-tooltip>
          </div>
          <div class="flex mt-8 items-center">
            <div class="mr-4">
              <span class="text-3xl sm:text-xl lg:text-3xl">
                {{ data?.consumption[0].volumes[0].value | number : '1.0-0' : facade.translate.currentLang + '-BE' }}
              </span>
              <span class="text-sm"> {{ data?.consumption[0].volumes[0].unit }}</span>
              <span
                class="text-sm inline-block w-full"
                [innerHTML]="
                  'components.widgets.consumptionForecast.' + (energyType | lowercase) + '.body.forecast'
                    | translate
                      : {
                          date:
                            data?.consumption[0].previousMeteringDate
                            | date : 'MMMM yyyy' : undefined : facade.translate.currentLang + '-BE',
                          value:
                            data?.forecast[0].volumes[0].value
                            | number : '1.0-0' : facade.translate.currentLang + '-BE',
                          unit: data?.forecast[0].volumes[0].unit
                        }
                "
              >
              </span>
            </div>
          </div>
          <div
            class="gauge absolute bottom-0 left-0 w-full opacity-20"
            [ngClass]="energyType | lowercase"
            [ngStyle]="{
              height: (data?.consumption[0].volumes[0].value / data?.forecast[0].volumes[0].value) * 100 + '%'
            }"
          ></div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <ng-template #loading>
    <app-loader-widget></app-loader-widget>
  </ng-template>

  <ng-template #noData>
    <app-no-data-widget>
      <span class="inline-block w-full">{{ 'components.widgets.noData.label' | translate }}</span>
      <a class="text-primary" routerLink="/consumptions/home-profile">
        {{ 'components.widgets.noData.updateProfile' | translate }}
      </a>
    </app-no-data-widget>
  </ng-template>

  <app-error-widget *ngIf="error"> </app-error-widget>
</div>
