<ng-container *accessControl="accessRight.accessProductSwap">
  <ng-container *ngIf="!productSwapApplied">
    <div class="absolute block top-[20%] w-[150vw] left-0 ml-[-50vw] h-4/5 md:h-3/4 bg-white"></div>
    <h2 class="flex mt-12 mb-8 font-display font-normal text-5xl te-text-gradient-home md:my-12">
      {{ 'components.contracts.registerFix.title' | translate }}
    </h2>
    <div class="flex flex-col-reverse md:flex-row items-center md:gap-16 relative">
      <div class="md:w-1/3">
        <h3
          class="font-display font-normal text-2xl md:text-4xl te-text-gradient-home mb-8 mt-16 md:mt-0 leading-tight"
        >
          {{ 'components.contracts.registerFix.productAdvantages.title' | translate }}
        </h3>

        <ul class="text-sm">
          <li class="relative flex items-center mb-5" *ngFor="let index of [0, 1, 2, 3]">
            <span
              class="font-medium"
              [innerHTML]="'components.contracts.registerFix.productAdvantages.features.' + index | translate"
            ></span>
          </li>
        </ul>

        <a
          class="btn btn-secondary my-8"
          [href]="'components.contracts.registerFix.productAdvantages.btnLink' | translate"
          target="_blank"
        >
          {{ 'components.contracts.registerFix.productAdvantages.btn' | translate }}
        </a>
      </div>
      <app-product-info class="md:w-1/3" (checkProductSwapState)="checkProductSwapState()"></app-product-info>
      <div
        class="flex md:w-1/3 self-start mt-12 md:mt-28 -order-1 md:order-none bg-success-lighter60 border-2 rounded-xl px-10 py-5 mb-12 border-solid border-blue-dark"
      >
        <div class="flex">
          <img src="./assets/img/info.svg" class="w-8 h-8" alt="question_mark" />
          <span class="success-box flex relative font-medium text-sm text-blue-dark ml-8">{{
            'components.contracts.registerFix.infoBox' | translate
          }}</span>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="productSwapApplied">
    <div class="absolute block w-[150vw] h-5/6 top-[17rem] md:top-48 z-0 left-0 ml-[-50vw] bg-white"></div>
    <app-request-processing-info class="relative"></app-request-processing-info>
  </ng-container>
</ng-container>
