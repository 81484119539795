<ul class="secondary-menu relative z-30">
  <li *ngIf="content$ | async as content" class="menu-item text-xs uppercase font-bold hidden md:inline-block">
    <a class="cursor-pointer">{{ 'header.secondaryMenu.helpAndContact.label' | translate }}</a>
    <div class="submenu-wrapper">
      <span class="submenu-back inline-block w-full md:hidden" (click)="emitMobileCloseSubmenu()">
        {{ 'general.back' | translate }}
      </span>
      <div class="submenu-content" (click)="emitMobileCloseMenu()">
        <ul class="secondary-submenu">
          <li class="submenu-item">
            <a
              class="pb-8"
              [href]="content.faqLink.label | translate"
              target="_blank"
              [analyticsEvent]="'directLink:click:help-center'"
            >
              {{ 'components.quickLinks.helpcenter' | translate }}
            </a>
          </li>
          <li class="mt-8 pt-8 border-t border-grey submenu-item">
            <a
              class="pb-8"
              [href]="content.contactLink.label | translate"
              target="_blank"
              [analyticsEvent]="'directLink:click:contact-us'"
            >
              {{ 'components.quickLinks.contactUs' | translate }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </li>

  <li
    *ngIf="userInfo$ | async as userInfo"
    class="menu-item inline-block relative w-full md:border-l md:border-grey md:pl-8 md:ml-8 md:w-auto"
  >
    <span
      class="menu-link profile inline-block w-full"
      [ngClass]="{ 'mobile-active-submenu': mobileActiveSubmenu === 'profile' }"
      (click)="emitMobileShowSubmenu('profile')"
      [analyticsEvent]="'navigation:open:name-menu'"
    >
      <span>
        {{ userInfo?.firstName }}
        {{ userInfo?.lastName }}
      </span>
      <span class="text-grey-dark60 mx-2">–</span>
      <span class="text-grey-dark60 mr-2"> {{ activeReference }}</span>
    </span>
    <div class="submenu-wrapper">
      <span class="submenu-back inline-block w-full md:hidden" (click)="emitMobileCloseSubmenu()">
        {{ 'general.back' | translate }}
      </span>
      <div class="submenu-title md:hidden">
        <span class="text-primary">
          {{ userInfo?.firstName }}
          {{ userInfo?.lastName }}
        </span>
      </div>
      <div class="submenu-content" (click)="emitMobileCloseMenu()">
        <span class="text-lg normal-case mb-6 inline-block w-full hidden md:block md:text-2xl md:font-medium">
          <span class="">{{ 'header.secondaryMenu.helloUser.label' | translate }}</span>
          {{ userInfo?.firstName }}
        </span>
        <span class="block text-sm font-normal text-grey-dark60 mb-3">
          {{ 'header.secondaryMenu.helloUser.selectClientNumber' | translate }}
        </span>
        <ul>
          <li *ngFor="let referenceDetails of referencesDetails$ | async">
            <label
              class="te-radio-btn"
              [class.active]="referenceDetails.reference === activeReference"
              [for]="referenceDetails.reference"
              [analyticsEvent]="'navigation:secondary-menu:reference'"
            >
              <span>{{
                referenceDetails.reference
              }}</span>
              <input
                type="radio"
                [id]="referenceDetails.reference"
                name="ref_select"
                (click)="emitSwitchReference(referenceDetails.reference)"
                [value]="referenceDetails.reference"
                [checked]="referenceDetails.reference === activeReference"
              />
            </label>
          </li>
          <li class="mt-8 pt-8 border-t border-grey submenu-item">
            <span
              class="submenu-link"
              routerLink="/preferences/references"
              [skipLocationChange]="true"
              [analyticsEvent]="'navigation:secondary-menu:manage-references'"
            >
              {{ 'header.secondaryMenu.helloUser.manageMyCustomerReferences' | translate }}
            </span>
          </li>
          <li class="submenu-item hidden md:block">
            <span
              class="submenu-link"
              routerLink="/preferences"
              [skipLocationChange]="true"
              [analyticsEvent]="'navigation:secondary-menu:preferences'"
            >
              {{ 'header.secondaryMenu.helloUser.myPreferences' | translate }}
            </span>
          </li>
          <li class="submenu-item">
            <a
              class="submenu-link"
              target="_blank"
              [href]="passwordPage"
              [analyticsEvent]="'navigation:secondary-menu:update-password'"
            >
              {{ 'header.secondaryMenu.helloUser.updatePassword' | translate }}
              <i class="icon-external-2 pl-4"></i>
            </a>
          </li>
          <li class="submenu-item">
            <span class="submenu-link" (click)="doLogout()" [analyticsEvent]="'navigation:secondary-menu:logout'">
              {{ 'header.secondaryMenu.helloUser.logout' | translate }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </li>
</ul>
