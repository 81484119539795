import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MainFacade } from '@app/core/facade/main.facade';
import { TranslateModule } from '@ngx-translate/core';
import { PaymentPlanCuzoApi, SliceCuzoApi, SliceStatusEnumCuzoApi } from '@app/shared/models/cuzo-be-contract';
import { BillingFacade } from '@app/core/facade/billing.facade';
import { RouterLink } from '@angular/router';
import { ActiveInvoices } from '../../../../../shared/models/cuzo-be-contract-extend';

@Component({
  selector: 'app-open-payment-plan',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, TranslateModule, RouterLink],
  templateUrl: './open-payment-plan.component.html',
  styleUrls: ['./open-payment-plan.component.scss'],
})
export class OpenPaymentPlanComponent {
  @Input()
  set data(value: PaymentPlanCuzoApi | ActiveInvoices) {
    this._paymentPlan = this.isOfTypeActiveInvoices(value) ? value?.paymentPlan?.data : value;
    const openInvoicesTotalAmount: number = this.isOfTypeActiveInvoices(value)
      ? value?.openInvoices?.data?.due?.totalAmount
      : 0;

    this.paymentSlices = this.getOverdueSlices(this._paymentPlan);
    this.paymentSlices = this.addUpcomingSlice(this._paymentPlan, this.paymentSlices).reverse();

    const totalDue: number = this.getTotalDue(this.paymentSlices, openInvoicesTotalAmount) || 0;
    this.totalDueEmitter.emit(totalDue);
    this.totalSlicesToPayEmitter.emit(this.paymentSlices.length);

    this.payWithDirectDebit = this.billingFacade.billingState$.value.payWithDirectDebit;
    this.structuredCommunication = this.billingFacade.formatStructuredCommunication(this._paymentPlan?.paymentId);
  }
  @Output() totalSlicesToPayEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output() totalDueEmitter: EventEmitter<number> = new EventEmitter<number>();
  _paymentPlan: PaymentPlanCuzoApi;
  paymentSlices: SliceCuzoApi[];
  payWithDirectDebit: Boolean;
  structuredCommunication: string;

  constructor(readonly facade: MainFacade, private readonly billingFacade: BillingFacade) {}

  private isOfTypeActiveInvoices(value: PaymentPlanCuzoApi | ActiveInvoices): value is ActiveInvoices {
    return 'openInvoices' in value && 'paymentPlan' in value;
  }

  private getOverdueSlices(paymentPlan: PaymentPlanCuzoApi): SliceCuzoApi[] {
    return paymentPlan.slices.filter((slice: SliceCuzoApi) => slice.status.includes(SliceStatusEnumCuzoApi.OVERDUE));
  }

  private addUpcomingSlice(paymentPlan: PaymentPlanCuzoApi, paymentSlices: SliceCuzoApi[]): SliceCuzoApi[] {
    if (!paymentPlan?.upcomingPayment?.sliceNumber) {
      return paymentSlices;
    }
    return [
      ...paymentSlices,
      {
        sliceNumber: paymentPlan?.upcomingPayment?.sliceNumber,
        amount: paymentPlan?.upcomingPayment?.amount,
        balance: paymentPlan?.upcomingPayment?.balance,
        dueDate: paymentPlan?.upcomingPayment?.dueDate,
        status: SliceStatusEnumCuzoApi.DUE,
      },
    ];
  }

  private getTotalDue(paymentSlices: SliceCuzoApi[], openInvoicesTotalAmount: number): number {
    return (
      openInvoicesTotalAmount +
      paymentSlices.reduce((value: number, currentSlice: SliceCuzoApi) => value + currentSlice.balance, 0)
    );
  }
}
