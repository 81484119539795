import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-code-verification',
  templateUrl: './code-verification.component.html',
  styleUrls: ['./code-verification.component.scss'],
})
export class CodeVerificationComponent implements OnInit {
  @Input() codeError: boolean;
  @Input() sendingMode: string;
  @Output() verificationCodeEmitter: EventEmitter<string> = new EventEmitter<string>();
  @Output() codeErrorEmitter: EventEmitter<{
    errorVariable: string;
    errorStatus: boolean;
  }> = new EventEmitter<{ errorVariable: string; errorStatus: boolean }>();
  @Output() goBackEmitter: EventEmitter<any> = new EventEmitter<any>();
  public codeForm: UntypedFormGroup;

  constructor(public formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.buildForms();
    this.resetError();
  }

  public buildForms() {
    this.codeForm = this.formBuilder.group({
      code: ['', Validators.required],
    });
    this.codeForm.get('code').valueChanges.subscribe(() => (this.codeError = false));
  }

  public resetError() {
    this.codeErrorEmitter.emit({
      errorVariable: 'codeError',
      errorStatus: false,
    });
  }

  public submit() {
    if (this.codeForm.valid) {
      this.verificationCodeEmitter.emit(this.codeForm.get('code').value);
    }
  }

  public emitGoBackRequest() {
    this.goBackEmitter.emit();
  }
}
