<div class="flex border-t border-grey/30 pt-8">
  <button
    (click)="onPreviousClick()"
    *ngIf="!moveFormFacade.isFirstStep()"
    class="btn btn-secondary font-bold"
  >Précédent</button>
  <button
    [disabled]="!stepValidity"
    (click)="onNextClick()"
    class="ml-auto btn btn-primary font-bold"
  >Suivant</button>
</div>
