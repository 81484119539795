import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs';
import {
  EstimatedIndex,
  EstimatedIndexRequest,
  EstimatedIndexPerReference,
} from '../../models/estimated-index.interface';

@Injectable({
  providedIn: 'root',
})
export class EstimatedIndexService {
  private indexes: EstimatedIndexPerReference = {};

  constructor(private http: HttpClient) {}

  public resetEstimatedIndexes(reference: string): void {
    this.indexes[reference] = null;
  }

  public getEstimatedIndexes(reference: string): Observable<EstimatedIndex[]> {
    if (this.indexes[reference]) {
      return of(this.indexes[reference]);
    }

    return this.getEstimatedIndexesFromApi(reference).pipe(
      tap((indexes) => {
        this.indexes[reference] = indexes;
      })
    );
  }

  public sendEstimatedIndexes(reference: string, processId: string, indexesRequest: EstimatedIndexRequest) {
    return this.http.post<EstimatedIndexRequest>(
      `/v1/customers/${reference}/estimated-indexes/${processId}`,
      indexesRequest
    );
  }

  private getEstimatedIndexesFromApi(reference: string): Observable<EstimatedIndex[]> {
    return this.http.get<EstimatedIndex[]>(`/v1/customers/${reference}/estimated-indexes`);
  }
}
