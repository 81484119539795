<div class="mgm">
  <div class="mt-8 w-full flex flex-wrap bg-white md:m-8 md:mt-20 md:max-w-7xl md:mx-auto">
    <div class="w-full m-auto md:w-1/2 md:order-2 lg:pl-16">
      <div class="inline-block w-full md:ml-16">
        <h3
          class="font-display font-normal text-2xl md:text-4xl te-text-gradient-home mb-8 leading-tight"
          [innerHTML]="'components.mgm.contentInfo.intro.title' | translate"
        ></h3>
        <p
          class="font-normal"
          [innerHTML]="
            'components.mgm.contentInfo.intro.content' | translate: { maxGodchild: yearlyMax, maxDiscount: maxDiscount }
          "
        ></p>
      </div>
    </div>
    <div class="w-full text-center md:w-1/2 md:order-1">
      <img
        src="./assets/img/mgm/content-info-image.png"
        class="mt-8 w-full max-w-3xl mx-auto md:mt-0"
        alt="Member get member illustration"
      />
    </div>
  </div>

  <div class="w-full mb-8 inline-block md:my-16 xl:px-32">
    <div class="text-left mb-8 mt-16 md:text-center md:mb-16">
      <h2
        class="font-display font-normal text-2xl md:text-4xl te-text-gradient-home"
        [innerHTML]="'components.mgm.contentInfo.howDoesItWork.title' | translate"
      ></h2>
    </div>

    <div class="overflow-hidden mt-10 inline-block flex flex-col md:flex-row md:my-4">
      <div class="flex-1 flex flex-row mb-8 pb-8 md:flex-col md:pb-0">
        <app-progress-circle
          class="progress-circle w-24 md:w-64 md:mx-auto"
          [circleClass]="'first'"
          [contentClass]="'bg-turquoise'"
        >
          <span class="font-display font-normal text-grey-dark text-4xl font-display md:text-5xl circle-content"
            >1</span
          >
        </app-progress-circle>
        <div class="circle-description pl-6 flex-1 self-center inline-block md:text-center md:p-8">
          <h6 class="text-grey-dark font-medium text-2xl md:text-3xl md:mb-6">
            {{ 'components.mgm.contentInfo.howDoesItWork.step1.title' | translate }}
          </h6>
          <div>{{ 'components.mgm.contentInfo.howDoesItWork.step1.content' | translate }}</div>
        </div>
      </div>

      <div class="flex-1 flex flex-row mb-8 pb-8 md:flex-col md:pb-0">
        <app-progress-circle class="w-24 md:w-64 md:mx-auto" [contentClass]="'bg-appleblue'">
          <span class="text-grey-dark text-4xl font-display md:text-5xl circle-content">2</span>
        </app-progress-circle>
        <div class="circle-description pl-6 flex-1 self-center inline-block md:text-center md:p-8">
          <h6 class="text-grey-dark font-medium text-2xl md:text-3xl md:mb-6">
            {{ 'components.mgm.contentInfo.howDoesItWork.step2.title' | translate }}
          </h6>
          <div>{{ 'components.mgm.contentInfo.howDoesItWork.step2.content' | translate }}</div>
        </div>
      </div>

      <div class="flex-1 flex flex-row mb-8 md:flex-col">
        <app-progress-circle
          class="progress-circle progress-circle--last w-24 md:w-64 md:mx-auto"
          [circleClass]="'last'"
          [contentClass]="'bg-green'"
        >
          <span class="text-grey-dark text-4xl font-display md:text-5xl circle-content">3</span>
        </app-progress-circle>
        <div class="circle-description pl-6 flex-1 self-center inline-block md:text-center md:p-8">
          <h6 class="text-grey-dark font-medium text-2xl md:text-3xl md:mb-6">
            {{ 'components.mgm.contentInfo.howDoesItWork.step3.title' | translate }}
          </h6>
          <div>
            {{
              'components.mgm.contentInfo.howDoesItWork.step3.content'
                | translate: { discount: discount?.promoDiscount }
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
