import { Pipe, PipeTransform } from '@angular/core';
import { min } from 'rxjs';

@Pipe({
  name: 'secondsToTime',
})
export class SecondsToTimePipe implements PipeTransform {
  transform(value: number): string {
    let minutes = Math.floor(value / 60);
    const seconds = value % 60;
    const hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
    return (
      (hours !== 0 ? hours + 'h ' : '') + (minutes !== 0 ? minutes + 'min ' : '') + (seconds !== 0 ? seconds + 's' : '')
    );
  }
}
