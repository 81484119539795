import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Location, LowerCasePipe } from '@angular/common';
import { UtilsService } from '../../../shared/utils/utils.service';
import { SalesforceService } from '@app/shared/services/salesforce/salesforce.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConfig } from '@app/shared/utils/app-config';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  readonly languages: string[] = ['fr', 'nl'];
  public language: string;

  readonly defaultLang = 'fr';
  readonly langCookieName = 'lang';
  private language$: BehaviorSubject<string> = new BehaviorSubject(this.defaultLang);

  constructor(
    public translate: TranslateService,
    private router: Router,
    private location: Location,
    private utils: UtilsService,
    private lowerCasePipe: LowerCasePipe,
    private salesforceService: SalesforceService,
    private conf: AppConfig
  ) {}

  public init() {
    this.translate.addLangs(this.languages);
    this.translate.setDefaultLang(this.defaultLang);

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (event.lang === this.language) {
        return;
      }

      // Force pre-populate field on lang switch
      this.salesforceService.prepopulateChatFields(this.salesforceService.setSalesforceChatObject());

      this.utils.setCookieValue(this.langCookieName, event.lang);
      const internalPath = window.location.pathname.replace(
        this.conf.config.path.prefix[this.lowerCasePipe.transform(this.language)],
        ''
      );
      // Rewrite the url with a prefix if needed.
      let url = this.conf.config.path.prefix[this.lowerCasePipe.transform(event.lang)];
      url += internalPath === '/' ? '' : internalPath;
      url += window.location.search;

      this.language = event.lang;
      window.history.replaceState({}, '', url);
      this.language$.next(this.language);
    });

    this.language = this.getLanguage();
    this.language$.next(this.language);
    this.translate.use(this.language);

    return this.language;
  }

  public getCurrentLanguage(): Observable<string> {
    return this.language$.asObservable();
  }

  public getLanguage(): string {
    // Check url language
    const urlLang = this.getUrlLanguage();
    if (urlLang) {
      return urlLang;
    }

    // Return default
    return this.defaultLang;
  }

  public getUrlLanguage(): string {
    const fullURL = window.location['pathname'];
    const urlLang = fullURL.split('/')[1];
    if (this.languages.find((el) => el === urlLang)) {
      return urlLang;
    }

    return;
  }

  public languageService(lang: string) {
    this.translate.use(lang);
    this.translate.setDefaultLang(lang);
  }
}
