import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MoveFormFacade } from '@app/core/facade/move-form.facade';

@Component({
  selector: 'app-navigation',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  @Input() stepValidity: boolean;
  @Output() previousClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() nextClick: EventEmitter<void> = new EventEmitter<void>();

  constructor(public moveFormFacade: MoveFormFacade) {}

  ngOnInit(): void {}

  onNextClick(): void {
    this.nextClick.emit();
  }

  onPreviousClick(): void {
    this.previousClick.emit();
  }
}
