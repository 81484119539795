import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NavigationComponent } from '@app/modules/customer-zone/move/components/move-form/navigation/navigation.component';
import { LoaderStatus } from '@app/modules/customer-zone/move/models/status.interface';
import { filter, Observable, take, timer } from 'rxjs';
import { MoveFormFacade } from '@app/core/facade/move-form.facade';
import { AlertComponent } from '@app/shared/components/alert/alert.component';
import { EanControlComponent } from '@app/modules/customer-zone/move/components/move-form/steps/my-meters/ean-control/ean-control.component';
import { DeliveryPoint, INITIAL_MOVE_STATE, MoveDTO, MoveState } from '@app/core/state/move.state';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { EnergyType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { DatePickerFieldModule } from '@app/shared/date-picker-field/date-picker-field.module';
import { EnergyTypeComponent } from '@app/modules/customer-zone/move/components/move-form/steps/new-meters/energy-type/energy-type.component';
import { ElectricityMeterComponent } from '@app/modules/customer-zone/move/components/move-form/steps/new-meters/electricity-meter/electricity-meter.component';
import { MoveInRegistration } from '@app/modules/customer-zone/move/models/movein.interface';
import moment from 'moment/moment';
import { MoveFormStep } from '@app/modules/customer-zone/move/components/move-form/steps/MoveFormStep';

@Component({
  selector: 'app-new-meters',
  standalone: true,
  imports: [
    CommonModule,
    NavigationComponent,
    AlertComponent,
    EanControlComponent,
    NgOptimizedImage,
    DatePickerFieldModule,
    FormsModule,
    ReactiveFormsModule,
    EnergyTypeComponent,
    ElectricityMeterComponent,
  ],
  templateUrl: './new-meters.component.html',
  styleUrls: ['./new-meters.component.scss'],
})
export class NewMetersComponent extends MoveFormStep<MoveInRegistration> implements OnInit {
  form: FormGroup;
  totalSteps: number = 2;
  step: number;
  deliveryPoints: DeliveryPoint[];
  meters: { hasElectricity: boolean; hasGas: boolean };
  isCurrentStepValid: boolean = false;
  registration: MoveInRegistration;

  constructor(
    protected readonly moveFormFacade: MoveFormFacade,
    private readonly formBuilder: FormBuilder,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    super(moveFormFacade);

    this.form = this.formBuilder.group({
      energyType: this.formBuilder.group({
        date: this.formBuilder.control('', [Validators.required]),
      }),
      meters: this.formBuilder.group({}),
    });
  }

  ngOnInit(): void {
    this.moveFormFacade.state$
      .pipe(
        filter((): boolean => this.moveFormFacade.state$.value !== INITIAL_MOVE_STATE),
        take(1)
      )
      .subscribe((state: MoveState): void => {
        this.registration = state?.registration;
        const newMetersForm = state?.form?.newMeters;
        this.form.get('energyType.date').setValue(newMetersForm?.energyType?.date);
        this.deliveryPoints = state.moveDTO?.sites?.[0]?.deliveryPoints;
        this.meters = {
          hasElectricity: this.hasMeter(EnergyType.ELECTRICITY),
          hasGas: this.hasMeter(EnergyType.GAS),
        };
        this.totalSteps = this.meters?.hasElectricity && this.meters.hasGas ? 3 : 2;
        this.step = this.moveFormFacade.state$?.value?.form?.newMeters?.step || 1;
      });
  }

  onNextClicked(): void {
    this.moveFormFacade.loader$.next(LoaderStatus.LOADING);
    this.saveFormData().subscribe(() => {
      this.moveFormFacade.loader$.next(LoaderStatus.LOADED);
      if (this.step === this.totalSteps) {
        alert('GO TO NEXT BULLET POINT');
        //this.moveFormFacade.next();
      } else {
        this.step++;
      }
    });
  }

  onPreviousClicked(): void {
    this.moveFormFacade.loader$.next(LoaderStatus.LOADING);
    timer(500)
      .pipe(take(1))
      .subscribe((): void => {
        this.moveFormFacade.loader$.next(LoaderStatus.LOADED);
        if (this.step === 1) {
          this.moveFormFacade.previous();
        } else {
          this.step--;
          this.updateStateAndLocalStorage();
        }
      });
  }

  setFormValidity(isValid: boolean) {
    this.isCurrentStepValid = isValid;
    this.changeDetectorRef.detectChanges();
  }

  saveFormData(): Observable<MoveInRegistration> {
    return this.moveFormFacade.updateRegistration(this.getUpdatedRegistration());
  }

  private hasMeter(type: EnergyType) {
    return this.deliveryPoints.some(({ energyType }) => energyType === type);
  }

  private updateStateAndLocalStorage(): void {
    const currentStep = this.step - 1;
    const nextStep = this.step;
    const state = {
      ...this.moveFormFacade.state$?.value,
      registration: this.getUpdatedRegistration(),
      form: {
        ...this.moveFormFacade.state$?.value?.form,
        newMeters: {
          ...this.moveFormFacade.state$?.value?.form?.newMeters,
          ...(currentStep === 1 ? { energyType: this.form.value.energyType } : { meters: this.form.value.meters }),
          step: nextStep,
        },
      },
    };
    this.moveFormFacade.updateData(state);
    if (state?.moveDTO?.id) {
      localStorage.setItem(state.moveDTO.id, JSON.stringify(state));
    }
  }

  private getUpdatedRegistration() {
    const registration = this.registration;
    if (this.step === 1) {
      registration.sites[0].deliveryPoints[0].requestedServiceDate = moment(
        this.form.get('energyType.date').value
      ).format('YYYY-MM-DD');
      registration.sites[0].deliveryPoints[0].customerWillingServiceDate = moment(
        this.form.get('energyType.date').value
      ).format('YYYY-MM-DD');
    } else if (this.step === 2 && this.meters.hasElectricity) {
      const electricityForm = this.form.get('meters.electricity');
      const site = this.registration?.sites?.[0];
      site.hasSolarPanels = electricityForm.get('hasSolarPanels').value;
      site.solarPanelKVA = electricityForm.get('solarPanelsKva').value;
      site.peakSolarPower = electricityForm.get('peakPower').value;
      site.hasInjectionTariff = electricityForm.get('injectionTariff').value;
      site.solarPanelsInstallationDateStarting2024 = electricityForm.get('solarPanelsInstallationDate').value;
      // MeterType?

      const deliveryPointIndex = site?.deliveryPoints?.findIndex(
        ({ energyType }) => energyType === EnergyType.ELECTRICITY
      );
      const deliveryPoint = site?.deliveryPoints?.[deliveryPointIndex];
      deliveryPoint.code = this.form.get('meters.electricity.ean').value;
    }
    return registration;
  }
}
