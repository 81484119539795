import { Component, Input, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MoveFormFacade } from '@app/core/facade/move-form.facade';
import { MainFacade } from '@app/core/facade/main.facade';
import { MoveDataCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Component({
  selector: 'app-move-cta',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage],
  templateUrl: './move-cta.component.html',
  styleUrls: ['./move-cta.component.scss'],
})
export class MoveCtaComponent implements OnInit {
  @Input() moveData: MoveDataCuzoApi;
  @Input() siteId: string;

  constructor(private readonly facade: MainFacade, private readonly moveFormFacade: MoveFormFacade) {}

  ngOnInit(): void {}

  resumeMove() {
    const reference: string = this.facade.state$.value.reference;
    this.moveFormFacade.resumeMove(reference, this.siteId, this.moveData.moveId);
  }
}
