<div class="select-reference">
  <div class="py-6">
    <h3 class="my-12 text-center" [innerHTML]="'general.upsell.boiler_one_shot.house_age.title' | translate"></h3>
  </div>

  <div>
    <div class="form-element input-radio-big mb-4">
      <input id="LOW" value="LOW" type="radio" name="house_age" [formControl]="control.get('taxEligibility')" />
      <label for="LOW">
        {{ 'general.upsell.boiler_one_shot.house_age.low' | translate }}
      </label>
    </div>
    <div class="form-element input-radio-big mb-4">
      <input id="HIGH" value="HIGH" type="radio" name="house_age" [formControl]="control.get('taxEligibility')" />
      <label for="HIGH">
        {{ 'general.upsell.boiler_one_shot.house_age.high' | translate }}
      </label>
    </div>
    <div *ngIf="control.get('taxEligibility').value === 'LOW'" class="form-element input-checkbox mt-12">
      <input
        [formControl]="control.get('vatConfirmation')"
        id="vatConfirmation"
        type="checkbox"
        class="form__checkbox"
      />
      <label for="vatConfirmation">
        {{ 'general.upsell.boiler_one_shot.house_age.vat_confirmation_label' | translate }}
      </label>
    </div>
    <div class="md:flex next fr my-12">
      <button *ngIf="displayNext" class="w-full btn btn-primary ml-auto md:w-auto" (click)="continue()">
        {{ 'general.next' | translate }}
      </button>
      <div class="text-center mt-8 md:flex-1 md:order-first md:mt-0 md:text-left">
        <strong class="navigate-back text-primary cursor-pointer">
          <a (click)="goBack()">{{ 'general.back' | translate }}</a>
        </strong>
      </div>
    </div>
    <hr />
    <div
      class="text-sm border-t border-grey-light pt-8"
      [innerHTML]="'general.upsell.boiler_one_shot.house_age.vat_confirmation_full' | translate"
    ></div>
  </div>
</div>
