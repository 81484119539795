import { Directive, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[routeTransformer]',
})
export class RouteTransformerDirective {
  constructor(private router: Router) {}

  @HostListener('click', ['$event'])
  public onClick(event) {
    if (event.target.tagName === 'A') {
      this.router.navigate([event.target.getAttribute('routerLink')], { fragment: 'refund' });
      event.preventDefault();
    } else {
      return;
    }
  }
}
