import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainFacade } from '@app/core/facade/main.facade';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  constructor(private facade: MainFacade, private router: Router) {}

  ngOnInit(): void {
    this.analytics();
  }

  private analytics() {
    this.facade.analytics.push(
      {
        event: 'pageload',
        page: {
          phase: 'care',
          category: '404',
          subCategory: '404 - cuzo',
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        404: {
          notFound: this.router.url,
        },
      },
      {
        reference: this.facade?.state$?.value?.reference,
        site: this.facade?.state$?.value?.activeSite,
        accessRights: this.facade?.state$?.value?.accessRights,
      }
    );
  }
}
