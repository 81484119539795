import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-step-navigation',
  templateUrl: './step-navigation.component.html',
  styleUrls: ['./step-navigation.component.scss'],
})
export class StepNavigationComponent {
  @Input() stepsNumber: number;
  @Input() currentStep: number;
}
