<span *ngIf="showImageIcon" class="image-icon relative {{ align }} {{ ghost?.color }}" [class.ghost]="ghost">
  <img
    *ngIf="imagePath"
    (error)="hideImageIcon($event)"
    [attr.height]="imageSize?.height ? imageSize.height : null"
    [attr.width]="imageSize?.width ? imageSize.width : null"
    [alt]="altDescription"
    [src]="imagePath"
  />

  <span *ngIf="text" [ngStyle]="textStyle">
    {{ text }}
  </span>
</span>
