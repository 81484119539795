import { Injectable } from '@angular/core';
import { Observable, of, shareReplay, Subject, take, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UserProfile } from '../../models/userProfile.interface';
import { UserInfo } from '../../models/userInfo.interface';
import { map } from 'rxjs';
import { AccessRight, AccessRights } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userProfile$: Subject<UserProfile> = new Subject<UserProfile>();
  private userProfile: UserProfile;

  private userInfo$: Subject<UserInfo> = new Subject<UserInfo>();
  private userInfo: UserInfo;

  private accessRightsCache: Map<string, Observable<AccessRights>> = new Map<string, Observable<AccessRights>>();

  constructor(private http: HttpClient) {}

  public resetUserProfile() {
    this.userProfile = null;
  }

  public getAccessRights(reference: string): Observable<AccessRights> {
    if (this.accessRightsCache.has(reference)) {
      return this.accessRightsCache.get(reference);
    }
    const accessRights$: Observable<AccessRights> = this.http
      .get<any>(`/v1/customers/${reference}/access-rights`)
      .pipe(shareReplay(1));
    this.accessRightsCache.set(reference, accessRights$);
    return accessRights$;
  }

  public getUserProfile(keycloakUserId: string, reset: boolean = false): Observable<UserProfile> {
    if (this.userProfile && !reset) {
      return of(this.userProfile);
    }
    return this.getUserProfileFromApi(keycloakUserId);
  }

  public getUserInfo(): Observable<UserInfo> {
    if (this.userInfo) {
      return of(this.userInfo);
    }
    return this.userInfo$;
  }

  public getUserPro(): UserProfile {
    return this.userProfile;
  }

  public getNoAccessReason(reference): Observable<NoAccessReason> {
    return this.http.get<NoAccessReason>(`/v1/customers/${reference}/no-access`);
  }

  public updateUserInfo(userInfo: UserInfo): void {
    this.userInfo = userInfo;
    this.userInfo$.next(userInfo);
  }

  private getUserProfileFromApi(keycloakUserId: string): Observable<UserProfile> {
    return this.http
      .get<UserProfile>(`/v1/userprofiles/${keycloakUserId}`, {
        params: {
          t: Date.now(),
          projection: 'reference',
        },
      })
      .pipe(
        map((userProfile: UserProfile) => {
          this.userProfile = userProfile;
          this.userProfile$.next(userProfile);
          return userProfile;
        })
      );
  }
}

export interface NoAccessReason {
  genericMessageReason: Reason;
  genericMessageDescription: GenericMessageDescription;
}

export interface GenericMessageDescription {
  customerReference?: string;
  statusDate?: string;
  badPayer?: boolean;
  reasons?: string[];
  reasonsDetails: BadPayerDescription[];
  oldReference?: string;
}

export enum BadPayerDescription {
  debtSales = 'Debt_sales',
  futureDebtSales = 'Future_Debt_sales',
  iurisJPB = 'Iuris_jpb',
  rcvActifIuris = 'Rcv_Actif_Iuris',
  rcvActifBordet = 'Rcv_Actif_Bordet',
  rcvActifBordetRCCI = 'Rcv_Actif_BordetRCCI',
  rcvActifHenryMersch = 'Rcv_Actif_HenryMersch',
  rcvActifIntrum = 'Rcv_Actif_Intrum',
  rcvActifRobert = 'Rcv_Actif_Robert',
  prisPourPerte = 'Pris_pour_perte',
}

export enum Reason {
  noAccessDueToFinancialSituation = 'NoAccessDueToFinancialSituation',
  noAccessDueToNoContractFound = 'NoAccessDueToNoContractFound',
  noAccessDueToB2BWithoutPixel = 'NoAccessDueToB2BWithoutPixel',
}
