<div *ngIf="activeInvoicesData$ | async as activeInvoicesData">
  <div *ngIf="activeInvoicesData?.openInvoices?.data; else invoicesError">
    <h2 class="text-2xl font-sans text-grey-dark60 font-medium mb-8">
      {{ 'pages.dashboard.paymentBalance.balanceOfPayments' | translate }}
    </h2>
    <section id="due-invoices">
      <app-payment-balance
        [balanceInfo]="balanceViewTypes[typeOfDueBalances]"
      >
        <app-payment-informations
          *ngIf="typeOfDueBalances === PaymentBalanceViewType.PAY_ONE_INVOICE"
          [billingDetails]="activeInvoicesData.openInvoices?.data?.billingDetails"
          [invoice]="activeInvoicesData.openInvoices?.data?.due.invoices[0]"
          [viewType]="typeOfDueBalances"
        ></app-payment-informations>
        <app-open-payment-plan
          *ngIf="typeOfDueBalances === PaymentBalanceViewType.ONLY_PAYMENT_PLAN"
          class="inline-block w-full mt-8"
          [data]="activeInvoicesData"
          (totalDueEmitter)="updateBalanceWithPaymentPlanSlices($event)"
        >
        </app-open-payment-plan>
        <app-invoice-actions
          action
          *ngIf="typeOfDueBalances === PaymentBalanceViewType.PAY_ONE_INVOICE"
          [billingDetails]="activeInvoicesData.openInvoices?.data?.billingDetails"
          [invoice]="activeInvoicesData.openInvoices?.data?.due?.invoices[0]"
          [accessRights]="accessRights"
        >
        </app-invoice-actions>
      </app-payment-balance>
      <app-alert
        *ngIf="typeOfDueBalances === PaymentBalanceViewType.NOTHING_TO_PAY"
        [alert]="invoiceAlerts?.alertAllBillsPaid"
      ></app-alert>

      <app-payments-list
        *ngIf="typeOfDueBalances === PaymentBalanceViewType.PAY_MORE_INVOICES"
        title="pages.dashboard.paymentBalance.toPay.currentInvoices"
        [billingDetails]="activeInvoicesData.openInvoices?.data?.billingDetails"
        [invoices]="activeInvoicesData.openInvoices?.data?.due?.invoices"
        [viewType]="typeOfDueBalances"
        [accessRights]="accessRights"
        [numberOfPaymentPlanSlicesToPay]="numberOfPaymentPlanSlicesToPay"
      >
        <app-open-payment-plan
          papur
          *ngIf="activeInvoicesData?.paymentPlan?.data"
          [data]="activeInvoicesData"
          (totalDueEmitter)="updateBalanceWithPaymentPlanSlices($event)"
          (totalSlicesToPayEmitter)="setNumberOfPaymentPlanSlicesToPay($event)"
          class="inline-block mb-8 w-full"
        >
        </app-open-payment-plan>
      </app-payments-list>
    </section>

    <section id="refund-invoices">
      <app-payment-balance
        class="block mt-10"
        [balanceInfo]="balanceViewTypes[typeOfRefundBalances]"
        *ngIf="typeOfRefundBalances !== PaymentBalanceViewType.NOTHING_TO_REFUND"
      >
        <app-payment-informations
          *ngIf="typeOfRefundBalances === PaymentBalanceViewType.REFUND_ONE_INVOICE"
          [billingDetails]="activeInvoicesData.openInvoices?.data?.billingDetails"
          [invoice]="activeInvoicesData.openInvoices?.data?.refund?.invoices[0]"
          [viewType]="typeOfRefundBalances"
        ></app-payment-informations>
        <app-invoice-actions
          action
          *ngIf="typeOfRefundBalances === PaymentBalanceViewType.REFUND_ONE_INVOICE"
          [billingDetails]="activeInvoicesData.openInvoices?.data?.billingDetails"
          [invoice]="activeInvoicesData.openInvoices?.data?.refund?.invoices[0]"
          [accessRights]="accessRights"
        >
        </app-invoice-actions>
      </app-payment-balance>

      <app-payments-list
        *ngIf="typeOfRefundBalances === PaymentBalanceViewType.REFUND_MORE_INVOICES"
        title="pages.dashboard.paymentBalance.toRefund.currentInvoices"
        [billingDetails]="activeInvoicesData.openInvoices?.data?.billingDetails"
        [invoices]="activeInvoicesData.openInvoices?.data?.refund?.invoices"
        [viewType]="typeOfRefundBalances"
        [accessRights]="accessRights"
      ></app-payments-list>
    </section>
  </div>
</div>

<ng-template #invoicesError>
  <app-alert
    [alert]="invoiceAlerts?.invoiceEndpointError"
    class="inline-block w-full"
  ></app-alert>
</ng-template>
