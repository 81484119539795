import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BillingDetails } from '@app/modules/customer-zone/billing/models/billingDetails.interface';
import { ContactDetails } from '@app/modules/customer-zone/contact/models/contactDetails.interface';
import {
  AccessRight,
  AccessRightOperator,
  UserType,
} from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { directDebitAndBankTransfer } from '@app/modules/customer-zone/billing/billing.constants';

import { ChangePaymentMethodComponent } from '../change-payment-method/change-payment-method.component';

@Component({
  selector: 'app-direct-debit',
  templateUrl: './direct-debit.component.html',
  styleUrls: ['./direct-debit.component.scss'],
})
export class DirectDebitComponent implements OnInit {
  @Input() billingDetails: BillingDetails;
  @Input() contactDetails: ContactDetails;
  @Input() reference: string;
  readonly accessRight = AccessRight;
  readonly accessRightOperator = AccessRightOperator;
  readonly directdebitandbanktransfer = directDebitAndBankTransfer;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  changePaymentMethod() {
    const modalRef = this.modalService.open(ChangePaymentMethodComponent, {
      size: 'lg',
    });

    modalRef.componentInstance.billingDetails = this.billingDetails;
    modalRef.componentInstance.contactDetails = this.contactDetails;
    modalRef.componentInstance.reference = this.reference;
  }
}
