import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ContactDetails } from '../../../contact/models/contactDetails.interface';

@Component({
  selector: 'app-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.scss'],
})
export class ContactInfoComponent implements OnInit {
  @Input() reference: string;
  @Input() contactDetail: ContactDetails;
  @Input() control: UntypedFormGroup;
  @Output() formReady: EventEmitter<string> = new EventEmitter<string>();
  @Output() goBackEmitter: EventEmitter<any> = new EventEmitter<any>();

  loader = true;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.initFormControl();
  }

  initFormControl() {
    if (!this.control.contains('name')) {
      this.control.addControl(
        'name',
        this.fb.control(`${this.contactDetail.firstName} ${this.contactDetail.lastName}`, [Validators.required])
      );
      this.control.addControl(
        'phone',
        this.fb.control(this.contactDetail.mobile ? this.contactDetail.mobile : this.contactDetail.phone, [
          Validators.required,
        ])
      );
      this.control.addControl(
        'email',
        this.fb.control(this.contactDetail.email ? this.contactDetail.email : '', [
          Validators.required,
          Validators.email,
        ])
      );
    }
  }

  public goBack() {
    this.goBackEmitter.emit();
  }

  submitForm() {
    this.formReady.emit();
  }
}
