import { TranslationWidth } from '@angular/common';
import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { MONTHS, WEEKDAYS } from './date.constant';

@Injectable()
export class DatePickerCustomTranslationI18n extends NgbDatepickerI18n {
  getWeekdayShortName(code: WeekdayCode): string {
    const weekday = WEEKDAYS[code];
    const translated = this.translate.instant(`common.weekday.${weekday}`) as string;
    const firstLetter = translated.substring(0, 1);
    return `${firstLetter}`;
  }

  constructor(private translate: TranslateService) {
    super();
  }

  getMonthFullName(monthCode: number, year?: number): string {
    const month = MONTHS[monthCode];

    return this.translate.instant(`common.month.${month}`) as string;
  }

  getMonthShortName(monthCode: number, year?: number): string {
    const month = MONTHS[monthCode];
    const translated = this.translate.instant(`common.month.${month}`) as string;

    return translated.substring(0, 2);
  }

  getWeekLabel(): string {
    return this.translate.instant('common.days.label') as string;
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    const day = WEEKDAYS[weekday];
    const translated = this.translate.instant(`common.days.${day}`) as string;

    return translated.substring(0, 1);
  }
}

type WeekdayCode = 1 & 2 & 3 & 4 & 5 & 6 & 7;
