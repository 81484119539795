<app-update-contact-email
  *ngIf="localStorageItem('binding')"
  [contact]="viewModel?.contact$ | async"
  [userInfo]="viewModel?.userInfo$ | async"
  [reference]="viewModel?.reference"
>
</app-update-contact-email>

<div class="sites-infos bg-white">
  <div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
    <ng-container *accessControl="accessRight.accessSponsorship">
      <app-alert
        *ngIf="showSponsorshipAlert"
        class="inline-block mt-16 w-full"
        [alert]="sponsorshipAlert"
        (closeAlert)="closeSponsorshipAlert()"
      ></app-alert>
    </ng-container>
    <ng-container *accessControl="accessRight.accessIndex56">
      <div *ngIf="(viewModel?.estimatedIndex$ | async)?.length > 0" class="inline-block mt-16 w-full">
        <app-alert [alert]="esimatedIndexAlert" [options]="{ template: 'column' }"></app-alert>
      </div>
    </ng-container>

    <div class="items-center pb-8 pt-14 sm:pb-14 md:grid md:grid-cols-12 md:gap-16">
      <div class="mb-12 md:mb-0 md:col-span-8">
        <app-sites-switcher
          [sites]="viewModel?.sites$ | async"
          [activeSite]="viewModel?.activeSite$ | async"
          [activeReference]="viewModel?.reference"
          (switchSiteEmitter)="switchSite($event)"
        >
        </app-sites-switcher>
      </div>

      <div class="md:col-span-4">
        <app-contracts-icons
          [activeSite]="viewModel?.activeSite$ | async"
          [services]="viewModel?.services$ | async"
        >
        </app-contracts-icons>
      </div>
    </div>
  </div>
</div>

<div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
  <div class="mt-8 md:grid md:grid-cols-12 md:gap-16 md:mt-16">
    <div class="pb-10 sm:pb-0 md:col-span-8">
      <app-open-invoices
        [accessRights]="viewModel?.accessRights"
      >
      </app-open-invoices>
    </div>

    <div class="aside md:col-span-4">
      <app-quick-links class="mb-8 inline-block w-full"></app-quick-links>
      <app-breaking-news></app-breaking-news>
    </div>
  </div>
</div>
