import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit: number): string {
    if (!value) return '';
    if (value.length <= limit) {
      return value;
    }
    const lastSpace = value.substr(0, limit).lastIndexOf(' ');
    return lastSpace === -1 ? value.substr(0, limit) + '…' : value.substr(0, lastSpace) + ' (...)';
  }
}
