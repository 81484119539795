import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { filter, forkJoin, of, Subject, switchMap, takeUntil } from 'rxjs';
import {
  BadPayerDescription,
  NoAccessReason,
  Reason,
  UserService,
} from '@app/modules/customer-zone/user/services/user/user.service';
import { Content } from '@app/shared/models/content.interface';
import { MainFacade } from '@app/core/facade/main.facade';
import { AccessRights } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent implements OnInit, OnDestroy {
  public content: Content;
  public message: { title: string; description: string } = { title: null, description: null };
  private notifier: Subject<void> = new Subject();

  constructor(private facade: MainFacade, private router: Router, private userService: UserService) {}

  ngOnInit(): void {
    this.facade.accessRights$
      .pipe(
        filter((accessRights: AccessRights) => !!accessRights),
        takeUntil(this.notifier),
        switchMap((accessRights: AccessRights) => {
          if (!accessRights?.seeGenericMessage) {
            this.router.navigate(['/']);
            return of([null, null]);
          }
          const reference: string = this.facade.state$.value.reference;
          return forkJoin([this.userService.getNoAccessReason(reference), this.facade.loadContent(reference)]);
        })
      )
      .subscribe(([response, content]) => this.setReason(response, content));
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }

  private setReason(response: NoAccessReason, content: Content) {
    if (!response || !response?.genericMessageReason) {
      return;
    }
    this.content = content;
    if (
      response.genericMessageReason.toLowerCase() === Reason.noAccessDueToFinancialSituation.toLowerCase() &&
      response.genericMessageDescription
    ) {
      const [reasonDetails] = response?.genericMessageDescription?.reasonsDetails;
      const reasonNumber = this.getBadPayerDescriptionNumber(reasonDetails);
      this.message.title = `general.noAccess.reasons.${reasonNumber}.title`;
      this.message.description = `general.noAccess.reasons.${reasonNumber}.description`;
      return;
    }
    if (response.genericMessageReason.toLowerCase() === Reason.noAccessDueToNoContractFound.toLowerCase()) {
      this.message.title = `general.noAccess.reasons.noAccessDueToNoContractFound.title`;
      this.message.description = `general.noAccess.reasons.noAccessDueToNoContractFound.description`;
      return;
    }
    if (response.genericMessageReason.toLowerCase() === Reason.noAccessDueToB2BWithoutPixel.toLowerCase()) {
      this.message.title = `general.noAccess.reasons.noAccessDueToB2BWithoutPixel.title`;
      this.message.description = `general.noAccess.reasons.noAccessDueToB2BWithoutPixel.description`;
      return;
    }
  }

  private getBadPayerDescriptionNumber(reasonDetails): string {
    if (
      reasonDetails.toLowerCase().includes(BadPayerDescription.debtSales.toLowerCase()) ||
      reasonDetails.toLowerCase().includes(BadPayerDescription.futureDebtSales.toLowerCase())
    ) {
      return 'one';
    } else if (
      reasonDetails.toLowerCase().includes(BadPayerDescription.iurisJPB.toLowerCase()) ||
      reasonDetails.toLowerCase().includes(BadPayerDescription.rcvActifIuris.toLowerCase())
    ) {
      return 'two';
    } else if (
      reasonDetails.toLowerCase().includes(BadPayerDescription.rcvActifBordet.toLowerCase()) ||
      reasonDetails.toLowerCase().includes(BadPayerDescription.rcvActifBordetRCCI.toLowerCase())
    ) {
      return 'three';
    } else if (reasonDetails.toLowerCase().includes(BadPayerDescription.rcvActifHenryMersch.toLowerCase())) {
      return 'four';
    } else if (reasonDetails.toLowerCase().includes(BadPayerDescription.rcvActifIntrum.toLowerCase())) {
      return 'five';
    } else if (reasonDetails.toLowerCase().includes(BadPayerDescription.rcvActifRobert.toLowerCase())) {
      return 'six';
    } else if (reasonDetails.toLowerCase().includes(BadPayerDescription.prisPourPerte.toLowerCase())) {
      return 'seven';
    }
  }
}
