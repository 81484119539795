import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MoveState } from '@app/core/state/move.state';
import { ORDERED_QUESTION_LEAVE } from '@app/modules/customer-zone/move/components/move-form/move-form-config';
import { MoveFormPaths } from '@app/core/models/paths';

@Component({
  selector: 'app-leaving-banner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './leaving-banner.component.html',
  styleUrls: ['./leaving-banner.component.scss'],
})
export class LeavingBannerComponent implements OnInit {
  @Input() moveState: MoveState;
  readonly ORDERED_QUESTION_LEAVE: MoveFormPaths[] = ORDERED_QUESTION_LEAVE;
  readonly MoveFormPaths = MoveFormPaths;

  constructor() {}

  ngOnInit(): void {}
}
