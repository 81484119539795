<div class="mb-2 p-8 {{ backgroundColor }} rounded-4xl" appSlideToggle [collapsed]="true">
  <div #toggler class="flex flex-row items-center cursor-pointer">
    <div class="toggle-icon"></div>
    <div class="font-medium text-grey-dark pl-10" [analyticsEvent]="'faq:title:' + _faqItem.title">
      {{ _faqItem.title }}
    </div>
  </div>

  <div #contentBox class="faq-item__content collapsible">
    <div #content class="pt-8 answer">
      {{ _faqItem.abstract | stripHtmlTags }}
      <div *ngIf="_faqItem.url" class="flex flex-row items-center pt-4 mr-8">
        <img src="./assets/img/icons/standalone/red/more.png" class="h-4 w-2 mr-4" />
        <a
          [analyticsEvent]="'faq:link:' + _faqItem.url"
          [href]="_faqItem.url"
          class="font-bold text-primary"
          target="_blank"
        >
          {{ 'general.readMore' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
