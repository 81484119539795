import { Component, Input } from '@angular/core';
import { Contract } from 'src/app/modules/customer-zone/contracts/models/contract.interface';
import { Site } from 'src/app/modules/customer-zone/user/models/site.interface';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
})
export class ContractsComponent {
  @Input() public reference: string;
  @Input() public site: Site;
  @Input() contracts: Contract[];
}
