import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Contract } from '../../../../../../../contracts/models/contract.interface';
import { myEnergyEvents } from '../../../../../../../../../core/analytics/models/event-defaults';
import { AnalyticsService } from '../../../../../../../../../shared/modules/analytics/services/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { UseCaseMyEnergyType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import {
  IndexModalService,
  StepTypes,
} from '@app/modules/customer-zone/consumption/services/index-modal/index-modal.service';
import { CamelCasePipe } from '@app/shared/pipes/camel-case/camel-case.pipe';

@Component({
  selector: 'app-warning-message',
  templateUrl: './warning-message.component.html',
  styleUrls: ['./warning-message.component.scss'],
  providers: [CamelCasePipe],
})
export class WarningMessageComponent implements OnInit {
  @Input() contract?: Contract;
  @Input() messageType:
    | UseCaseMyEnergyType.DECREASE_TOO_IMPORTANT
    | UseCaseMyEnergyType.MODIFICATION_ONGOING
    | UseCaseMyEnergyType.ALREADY_DECREASED
    | StepTypes.serviceNotAvailable
    | StepTypes.adjustmentNotAvailable;
  @Output() formReady = new EventEmitter();

  constructor(
    private analyticsService: AnalyticsService,
    private translate: TranslateService,
    private indexModalService: IndexModalService,
    private camelCasePipe: CamelCasePipe
  ) {}

  ngOnInit() {
    this.analytics();
    this.indexModalService.setModalTitle(
      this.translate.instant(
        `components.consumption.index.monthlyInstallment.warning.${this.camelCasePipe.transform(
          this.messageType
        )}.title`
      )
    );
  }

  continue() {
    this.formReady.emit();
  }

  private analytics() {
    if (this.messageType === StepTypes.serviceNotAvailable || this.messageType === StepTypes.adjustmentNotAvailable) {
      return;
    }
    let path = 'deposit-already-modified';
    path = this.messageType === UseCaseMyEnergyType.DECREASE_TOO_IMPORTANT ? 'modification-too-big' : path;
    this.analyticsService.push({
      ...myEnergyEvents(`${this.translate.currentLang}/myenergy-flow/${path}`),
      myEnergy: {
        energyType: this.contract.type,
        ean: this.contract.deliveryPointReference,
      },
    });
  }
}
