import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from '@app/shared/components/alert/alert.component';
import { MoveCtaComponent } from '@app/modules/customer-zone/move/components/move-status/views/parts/move-cta/move-cta.component';
import { Alert, AlertType } from '@app/shared/components/alert/alert.interface';
import { MoveStatusProgressComponent } from '@app/modules/customer-zone/move/components/move-status/views/parts/move-status-progress/move-status-progress.component';
import { MoveMeterTagComponent } from '@app/modules/customer-zone/move/components/move-status/views/parts/move-meter-tag/move-meter-tag.component';
import { TranslateModule } from '@ngx-translate/core';
import { MainFacade } from '@app/core/facade/main.facade';
import { MoveDataCuzoApi, MoveDataStatusEnumCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Component({
  selector: 'app-move-card',
  standalone: true,
  imports: [
    CommonModule,
    AlertComponent,
    MoveCtaComponent,
    MoveStatusProgressComponent,
    MoveMeterTagComponent,
    TranslateModule,
  ],
  templateUrl: './move-card.component.html',
  styleUrls: ['./move-card.component.scss'],
})
export class MoveCardComponent implements OnInit {
  @Input() moveData: MoveDataCuzoApi;
  @Input() siteId: string;
  readonly Status = MoveDataStatusEnumCuzoApi;
  readonly AlertType = AlertType;
  missingInfo: Alert;

  constructor(private facade: MainFacade) {}

  ngOnInit(): void {
    this.missingInfo = {
      type: AlertType.warning,
      content: {
        message: this.facade.translate.instant('components.move.dashboard.card.alerts.missingInfo'),
      },
    };
  }
}
