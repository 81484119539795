import { Component, OnInit, OnDestroy } from '@angular/core';
import { map, Observable, switchMap, takeUntil } from 'rxjs';
import { LangChangeEvent } from '@ngx-translate/core';
import { forkJoin, Subject } from 'rxjs';
import { BillingDetails } from '@app/modules/customer-zone/billing/models/billingDetails.interface';
import { ContactDetails, Countries } from '@app/modules/customer-zone/contact/models/contactDetails.interface';
import { UserType } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from '@app/shared/modules/loader/services/loader.service';
import { MainFacade } from '@app/core/facade/main.facade';

@Component({
  selector: 'app-payment-preferences',
  templateUrl: './payment-preferences.component.html',
  styleUrls: ['./payment-preferences.component.scss'],
})
export class PaymentPreferencesComponent implements OnInit, OnDestroy {
  public viewModel$: Observable<{ contactDetails: ContactDetails; billingDetails: BillingDetails }>;
  public userType: UserType;
  public contactDetails: ContactDetails;
  public billingDetails: BillingDetails;
  public activeReference: string;
  private notifier: Subject<void> = new Subject();
  private fragment: string;

  constructor(private facade: MainFacade, private route: ActivatedRoute, private loaderService: LoaderService) {}

  ngOnInit(): void {
    this.analytics();
    this.route.fragment.pipe(takeUntil(this.notifier)).subscribe((fragment) => (this.fragment = fragment));
    this.facade.utils.setPageTitle('pages.paymentPreferences.pageTitle');
    this.facade.translate.onLangChange
      .pipe(takeUntil(this.notifier))
      .subscribe((event: LangChangeEvent) => this.analytics());

    this.loaderService
      .getLoaderStatus()
      .pipe(takeUntil(this.notifier))
      .subscribe((loading) => {
        if (!loading && this.fragment) {
          setTimeout(() =>
            document.querySelector('#' + this.fragment).scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            })
          );
        }
      });

    this.viewModel$ = this.facade.reference$.pipe(
      takeUntil(this.notifier),
      switchMap((reference: string) => {
        this.activeReference = reference;
        return forkJoin([this.facade.loadContactDetails(reference), this.facade.loadBillingDetails(reference)]).pipe(
          map(([contactDetails, billingDetails]) => ({ contactDetails, billingDetails }))
        );
      })
    );
  }

  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
  }

  private analytics() {
    this.facade.analytics.push(
      {
        event: 'pageview',
        page: {
          phase: 'care',
          category: 'cuzo',
          subCategory: 'payment-preferences - cuzo',
        },
      },
      {
        reference: this.facade?.state$?.value?.reference,
        site: this.facade?.state$?.value?.activeSite,
        accessRights: this.facade?.state$?.value?.accessRights,
      }
    );
  }
}
