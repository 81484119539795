import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-smart-tpl',
  templateUrl: './smart-tpl.component.html',
  styleUrls: ['./smart-tpl.component.scss'],
})
export class SmartTplComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
