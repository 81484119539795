import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Advice, AdviceStatus } from '@app/modules/customer-zone/consumption/services/advice/advice.service';
import { ViewType } from '@app/modules/customer-zone/consumption/components/advice-investment/advice-investment.component';

@Component({
  selector: 'app-advice-panel',
  templateUrl: './advice-panel.component.html',
  styleUrls: ['./advice-panel.component.scss'],
})
export class AdvicePanelComponent implements OnChanges {
  @ViewChildren('close') closePanel: QueryList<ElementRef>;
  @Output() closeEmitter: EventEmitter<null> = new EventEmitter<null>();
  @Output() emitAction: EventEmitter<{ status: AdviceStatus; id: string }> = new EventEmitter<{
    status: AdviceStatus;
    id: string;
  }>();
  @Input() advice: Advice;
  protected readonly viewType = ViewType;

  constructor() {}

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.closePanelAndResetState();
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    const closeElements: ElementRef[] = this.closePanel?.toArray();
    if (this.advice && closeElements.some(({ nativeElement }) => nativeElement.contains(event.target))) {
      this.closePanelAndResetState();
    }
  }

  ngOnChanges(): void {
    if (this.advice) {
      this.scrollFreezer(true);
    }
  }

  updateAdviceStatus($event: { status: AdviceStatus; id: string }) {
    this.closePanelAndResetState();
    this.emitAction.emit($event);
  }

  private closePanelAndResetState() {
    this.scrollFreezer(false);
    this.advice = null;
    this.closeEmitter.emit(null);
  }

  private scrollFreezer(freeze: boolean) {
    const appWrapper: HTMLElement = document.getElementById('app-wrapper');
    if (freeze) {
      appWrapper.style.top = `-${window.scrollY}px`;
      appWrapper.style.position = 'fixed';
    } else {
      const scrollY = appWrapper.style.top;
      appWrapper.removeAttribute('style');
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    }
  }
}
