import { BehaviorSubject } from 'rxjs';

export interface ChargingStation {
  name: string;
  status: ParingStatus;
  activationDate: string;
  externalId: string;
}

export interface ChargingStationStatus {
  liveStatus: LiveStatus;
  liveStatusDate: string;
  chargingStatus: ChargingStatus | null | '';
  chargingStatusDate: string | null;
  errorCode?: string;
}

export interface ChargingStationSession {
  startDate: string;
  endDate: string;
  totalEnergy: number;
  duration: number;
  totalAmount: number;
}

export interface ChargingStationSessionReport {
  start: string;
  end: string;
  nbSession: number;
  totalDuration: number;
  totalEnergy: number;
  totalPrice: number;
  averageDuration: number;
  averageEnergy: number;
  averagePrice: number;
}

export enum ParingStatus {
  pending = 'PENDING',
  retired = 'RETIRED',
  inService = 'IN_SERVICE',
  rejected = 'REJECTED',
}

export enum LiveStatus {
  idle = 'IDLE',
  occupied = 'OCCUPIED',
  inoperative = 'INOPERATIVE',
  offline = 'OFFLINE',
}

export enum ChargingStatus {
  charging = 'CHARGING',
  suspendedByVehicle = 'SUSPENDED_BY_VEHICLE',
  suspendedByCharger = 'SUSPENDED_BY_CHARGER',
}

export interface SelectedChargingStation {
  chargingStation$: BehaviorSubject<ChargingStation | null>;
  status$: BehaviorSubject<ChargingStationStatus | null>;
  sessions$: BehaviorSubject<ChargingStationSession[] | null>;
  sessionReports$: BehaviorSubject<ChargingStationSessionReport[] | null>;
}
