import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[onlyNumber]',
})
export class OnlyNumberDirective {
  constructor() {}

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const allowedKeys = ['Backspace', 'Tab', 'Shift', 'ArrowLeft', 'ArrowRight', 'Escape'];
    const controlOrCommand = event.ctrlKey === true || event.metaKey === true;
    if (event.key === 'a' && controlOrCommand) {
      return;
    }

    if (isNaN(event.key) && event.key !== ' ' && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
}
