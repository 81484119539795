<div [class.active]="advice !== null">
  <div class="panel overflow-y-auto">
    <span class="text-primary text-sm font-bold mb-12 w-full flex items-center cursor-pointer" #close>
      <img ngSrc="./assets/img/icons/standalone/red/arrow-left.svg" class="w-2.5 mr-4 inline-block" height="17" width="9"/>
      {{ 'general.back' | translate }}
    </span>
    <span class="bg-white close close-btn" #close></span>
    <div class="tag py-4 px-6 inline-block te-border-gradient-home rounded-2xl border-2 relative overflow-hidden">
      <span class="font-medium te-text-gradient-home inline-block relative z-10">
        {{ 'components.advice.status.' + advice?.status | lowercase | translate }}
      </span>
      <span class="tag-bg absolute top-0 left-0 w-full h-full"></span>
    </div>
    <h3 class="text-grey-dark text-2xl font-semibold my-8">
      {{ 'components.advice.list.' + advice?.key + '.title' | translate: {default:advice?.title} }}
    </h3>
    <div class="">
      <app-advice-action
        class="w-full"
        [advice]="advice"
        [viewType]="viewType.inline"
        (emitAction)="updateAdviceStatus($event)"
      >
      </app-advice-action>
      <div class="content-wrapper">
        <div
          [innerHTML]="'components.advice.list.' + advice?.key + '.content' | translate: {default:advice?.content}"
        >
        </div>
        <div class="flex gap-8 my-6 md:mb-0 md:mt-16">
          <app-advice-investment
            class="w-full"
            [advice]="advice"
            [viewType]="viewType.inline"
          >
          </app-advice-investment>
        </div>
      </div>
    </div>
    <span class="text-primary text-sm font-bold mt-8 w-full flex items-center cursor-pointer" #close>
      <img ngSrc="./assets/img/icons/standalone/red/arrow-left.svg" class="w-2.5 mr-4 inline-block" alt="" height="17" width="9"/>
      {{ 'components.homeProfileForm.panel.back.label' | translate }}
    </span>
  </div>
  <div #close class="backdrop"></div>
</div>
