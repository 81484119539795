import { Component, Input } from '@angular/core';
import { Advice } from '@app/modules/customer-zone/consumption/services/advice/advice.service';

@Component({
  selector: 'app-advice-investment',
  templateUrl: './advice-investment.component.html',
  styleUrls: ['./advice-investment.component.scss'],
})
export class AdviceInvestmentComponent {
  @Input() advice: Advice;
  @Input() viewType: ViewType = ViewType.list;
  protected readonly viewTypes = ViewType;
}

export enum ViewType {
  list = 'list',
  inline = 'inline',
}
