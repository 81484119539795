<div class="w-full mt-16 px-8 xl:max-w-screen-xl xl:w-full xl:mx-auto md:mt-32">
  <div class="flex flex-wrap items-center">
    <div class="md:flex-1 order-2 my-16 mx-auto md:my-0">
      <div class="text-center w-full md:max-w-5xl md:mx-auto">
        <h3 class="text-center mb-6 font-medium font-sans">
          {{ message.title | translate }}
        </h3>
        <p
          class="font-sans mb-8"
          [innerHTML]="message.description | translate: { url: (content?.contactLink?.label | translate) }"
        >
        </p>
      </div>
    </div>
  </div>
</div>
