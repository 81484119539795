import { Injectable } from '@angular/core';
import { DeliveryPointsPerReference, Meter } from '../../models/deliveryPoint.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DeliveryPointService {
  private deliveryPoints: DeliveryPointsPerReference | Record<string, unknown> = {};

  constructor(private http: HttpClient) {}

  public getDeliveryPoint(
    reference: string,
    siteId: string,
    energyType: any,
    deliveryPoint: string,
    loader: boolean = true
  ): Observable<Meter[]> {
    if (this.deliveryPoints[reference] && this.deliveryPoints[reference][deliveryPoint]) {
      return of(this.deliveryPoints[reference][deliveryPoint]);
    }
    return this.getDeliveryPointFromApi(reference, siteId, energyType, deliveryPoint, loader);
  }

  private getDeliveryPointFromApi(
    reference: string,
    siteId: string,
    energyType: any,
    deliveryPoint: string,
    loader: boolean
  ): Observable<Meter[]> {
    const params = new HttpParams().set('loader', loader.toString());
    return this.http
      .get<Meter[]>(`/v1/customers/${reference}/sites/${siteId}/contracts/${energyType}/${deliveryPoint}`, { params })
      .pipe(
        tap((meters: Meter[]) => {
          if (!this.deliveryPoints[reference]) {
            this.deliveryPoints[reference] = {};
          }
          this.deliveryPoints[reference][deliveryPoint] = meters;
        })
      );
  }
}
