import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UserInfo } from '../../models/userInfo.interface';
import { ConsentService } from '../../services/consent/consent.service';
import { take } from 'rxjs';
import { Consent, ConsentMessage, ConsentReworked, PersonalConsent } from '../../models/consents.interface';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-consent-form',
  templateUrl: './consent-form.component.html',
  styleUrls: ['./consent-form.component.scss'],
})
export class ConsentFormComponent implements OnInit, OnChanges {
  @Input() consents: Consent[];
  @Input() myConsents: PersonalConsent[];
  @Input() userInfo: UserInfo;
  @Input() reference: string;
  @Input() language: string;
  public consentsReworked: ConsentReworked[];
  public consentForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private consentService: ConsentService,
    private translateService: TranslateService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.buildConsents();
    this.setConsentsLanguage();
    this.buildForm();
  }

  public buildFormGroup(array: ConsentReworked[]) {
    const formGroup = {};
    array.forEach(
      (item) => (formGroup[item.code] = [item.hasOwnProperty('state') ? item.state.toString() : 'undefined'])
    );
    return formGroup;
  }

  public submitConsent() {
    if (this.consentForm.valid) {
      const payload = [];
      Object.entries(this.consentForm.value).forEach(([key, value]) => {
        if (value !== 'undefined') {
          payload.push({
            consentTypeCode: key,
            state: value === 'true',
            updateBy: `${this.userInfo.firstName} ${this.userInfo.lastName}`,
            updateChannel: 'myLampiris',
            updateInfo: '',
          });
        }
      });
      this.consentService
        .postPersonalConsentsToAPI(this.reference, payload)
        .pipe(take(1))
        .subscribe(
          () => {
            this.consentService.resetPersonalConsentsCache(this.reference);
            this.consentForm.markAsPristine();
            this.toastrService.success(this.translateService.instant('pages.consents.alert.success'));
          },
          (error) => this.toastrService.error(this.translateService.instant('errorMessages.serverError'))
        );
    }
  }

  private buildForm() {
    this.consentForm = this.fb.group(this.buildFormGroup(this.consentsReworked));
  }

  private buildConsents() {
    this.consentsReworked = this.consents.map((consent: Consent) => {
      const value =
        this.myConsents &&
        this.myConsents.find((myConsent: PersonalConsent) => consent.code === myConsent.consentTypeCode);
      return value ? { ...consent, state: value.state } : consent;
    });
  }

  private setConsentsLanguage() {
    this.consentsReworked = this.consentsReworked.map((consent: ConsentReworked) => {
      if (typeof consent.labels === 'string' || typeof consent.statements === 'string') {
        return consent;
      }
      const label = consent.labels.find((labelArray: ConsentMessage) => labelArray.isoCode === `${this.language}_BE`);
      const statement = consent.statements.find(
        (statementArray: ConsentMessage) => statementArray.isoCode === `${this.language}_BE`
      );
      return label && statement ? { ...consent, labels: label.message, statements: statement.message } : consent;
    });
  }
}
