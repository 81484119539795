import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-request-processing-info',
  standalone: true,
  imports: [CommonModule, TranslateModule, SharedModule],
  templateUrl: './request-processing-info.component.html',
  styleUrls: ['./request-processing-info.component.scss'],
})
export class RequestProcessingInfoComponent {
  stepsContent = [
    {
      title: 'components.contracts.registerFix.prodcutSwapSubmitted.processSteps.0.title',
      description: 'components.contracts.registerFix.prodcutSwapSubmitted.processSteps.0.description',
      imageUrl: './assets/img/process-request/step1.svg',
    },
    {
      title: 'components.contracts.registerFix.prodcutSwapSubmitted.processSteps.1.title',
      description: 'components.contracts.registerFix.prodcutSwapSubmitted.processSteps.1.description',
      imageUrl: './assets/img/process-request/step2.svg',
    },
    {
      title: 'components.contracts.registerFix.prodcutSwapSubmitted.processSteps.2.title',
      description: 'components.contracts.registerFix.prodcutSwapSubmitted.processSteps.2.description',
      imageUrl: './assets/img/process-request/step3.svg',
    },
  ];
}
