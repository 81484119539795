import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePickerCoreComponent } from './date-picker-core/date-picker-core.component';
import { DatePickerFieldComponent } from './date-picker-field.component';

@NgModule({
  declarations: [DatePickerFieldComponent, DatePickerCoreComponent],
  exports: [DatePickerFieldComponent, DatePickerCoreComponent],
  imports: [CommonModule, NgbModule, ReactiveFormsModule, TranslateModule],
})
export class DatePickerFieldModule {}

_('common.days.label');
_('common.days.monday');
_('common.days.tuesday');
_('common.days.wednesday');
_('common.days.thursday');
_('common.days.friday');
_('common.days.saturday');
_('common.days.sunday');
_('common.month.label');
_('common.month.placeholder');
_('common.month.january');
_('common.month.february');
_('common.month.march');
_('common.month.april');
_('common.month.may');
_('common.month.june');
_('common.month.july');
_('common.month.august');
_('common.month.september');
_('common.month.october');
_('common.month.november');
_('common.month.december');
