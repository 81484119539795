import { MyEnergyActionsPerEAN } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { EnergyType, PaymentFrequency } from '../../consumption/models/consumption.interface';
import { Alert } from '@app/shared/components/alert/alert.interface';

export interface Advance {
  deliveryPoint: string;
  type: EnergyType;
  amount: string;
  minAmountAllowedIncVat: number;
  averagePeak: string;
  consumptionMono: string;
  consumptionPeak: string;
  consumptionOffpeak: string;
  consumptionNighttime: string;
  status: 0 | 1 | 2; // 1 === modification of advance is ongoing
  invoicingMode: string;
  paymentFrequency: PaymentFrequency;
  budgetMeter: boolean;
  flanders: boolean;
  smartMeter: boolean;
  brutoOfftakeMono: string;
  brutoOfftakePeak: string;
  brutoOfftakeOffPeak: string;
  injectionMono: string;
  injectionPeak: string;
  injectionOffpeak: string;
  solarPanelPower: number | null;
  regularizationFrequency: RegularizationFrequencyType;
  updateAllowed: boolean;
  unallowedReasons: UnallowedReasonReturnedByEL1[];
  effectiveDate: string;
}

export interface AdvanceSaved {
  unallowedReasons: UnallowedReasonReturnedByEL1[];
}

export enum RegularizationFrequencyType {
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export interface AdvanceUpdate {
  deliveryPoint: string;
  type: string;
  amount: string;
}

export interface AdvancePerReference {
  [reference: string]: AdvancePerSite[] | Record<string, Advance[]>;
}

export interface AdvancePerSite {
  [siteId: string]: Advance[] | Record<string, Advance[]>;
}

export interface CanChangeInstallmentPerRef {
  [reference: string]: MyEnergyActionsPerEAN[] | Record<string, unknown>;
}

export type AdvanceWithViewData = Advance & AdvanceViewData;

export interface AdvanceViewData {
  alertBox: Alert | null;
  unallowedReasonForView: UnallowedReasons | null;
  showMoreFeatures: boolean;
}

export interface AdvancesPerEnergy {
  electricity: EnergyView;
  gas: EnergyView;
}

export interface EnergyView {
  advances: AdvanceWithViewData[];
  meterNumbers: string[];
  activeAdvance: AdvanceWithViewData;
}

export enum UnallowedReasonDefinedByAPI {
  BUDGET_METER = 'BUDGET_METER',
  MONTHLY_REGULARIZED_INVOICE = 'MONTHLY_REGULARIZED_INVOICE',
  MISSING_ADVANCE = 'MISSING_ADVANCE',
  MISSING_DATA = 'MISSING_DATA', // One unity reasons for UnallowedReasons.NOT_ADVANCE, UnallowedReasons.NO_VOLUMES, UnallowedReasons.NO_PRICE,
}

export enum UnallowedReasonReturnedByEL1 {
  PROCESS_ONGOING = 'PROCESS_ONGOING',
  NOT_ADVANCE = 'NOT_ADVANCE', //last ConsoEAN is not an advance
  NO_VOLUMES = 'NO_VOLUMES', //last ConsoEAN contains no consumption
  NO_PRICE = 'NO_PRICE', //No deposit amount
  AMR_OR_MMR = 'AMR_OR_MMR', //AMR or MMR EAN
  NOT_RESIDENTIAL = 'NOT_RESIDENTIAL', // Contract type (B2B) doesn’t permit advances, = accessRights “modifyAdvance: false”
  NO_MASTERDATA = 'NO_MASTERDATA',
  INVALID_AMOUNT = 'INVALID_AMOUNT',
}

export const UnallowedReasons = { ...UnallowedReasonReturnedByEL1, ...UnallowedReasonDefinedByAPI };
export type UnallowedReasons = UnallowedReasonReturnedByEL1 | UnallowedReasonDefinedByAPI;
