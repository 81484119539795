import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlogComponent } from './components/blog/blog.component';
import { ArticleComponent } from './components/blog/components/article/article.component';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared.module';
import { AnalyticsModule } from '../analytics/analytics.module';

@NgModule({
  declarations: [BlogComponent, ArticleComponent],
  exports: [BlogComponent],
  imports: [CommonModule, HttpClientModule, TranslateModule, SharedModule, AnalyticsModule],
})
export class BlogModule {}
