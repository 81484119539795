import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

interface CustomSelectField {
  id: string;
  label?: string;
  formControl: UntypedFormControl;
  options: { [key: string]: string };
}

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
})
export class CustomSelectComponent implements OnInit {
  @Input() field: CustomSelectField;
  @Input() isLocked?: boolean;
  @Input() options?: {
    style?: string;
    defaultValue?: boolean;
    icon?: string;
  } = { style: 'field', defaultValue: true };

  constructor() {}

  ngOnInit(): void {}

  public updateSelection(formControl: UntypedFormControl, value: string) {
    formControl.setValue(value);
    formControl.markAsDirty();
  }

  public asIsOrder() {
    return 1;
  }
}
