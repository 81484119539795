import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ChargingStation, ChargingStationStatus } from '../../models/charging-station.interface';
import { UtilsService } from '../../../../../shared/utils/utils.service';
import { Observable } from 'rxjs/internal/Observable';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-station-info',
  templateUrl: './station-info.component.html',
  styleUrls: ['./station-info.component.scss'],
})
export class StationInfoComponent {
  @Input() chargingStations$: Observable<ChargingStation[]>;
  @Input() chargingStation$: Observable<ChargingStation>;
  @Input() chargingStationStatus$: Observable<ChargingStationStatus>;
  @Output() stationChanged: EventEmitter<ChargingStation> = new EventEmitter<ChargingStation>();
  @ViewChild(NgbDropdown) dropdown: NgbDropdown;

  getStationStatus(chargingStationStatus: ChargingStationStatus): string {
    // When the charging status is available, we take charging status. Otherwise we take live status.
    return chargingStationStatus
      ? chargingStationStatus.chargingStatus
        ? UtilsService.toCamel(chargingStationStatus.chargingStatus.toLowerCase())
        : UtilsService.toCamel(chargingStationStatus.liveStatus.toLowerCase())
      : null;
  }

  changeChargingStation(station: ChargingStation) {
    this.stationChanged.emit(station);
    this.dropdown.close();
  }

  isDropdownOpen(): boolean {
    return this.dropdown.isOpen();
  }
}
