import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ContractService } from '../../../contracts/services/contract.service';
import { SimulationRequest, SimulationResponse } from '../../../contracts/models/price.simulation';
import { DatePipe, formatDate } from '@angular/common';
import { UpSellService } from '../../upsell.service';
import { debounceTime, distinctUntilChanged, takeLast } from 'rxjs';
import { BoilerOneShotSteps } from '../../models/upsell.interface';

@Component({
  selector: 'app-price-simulation',
  templateUrl: './price-simulation.component.html',
  styleUrls: ['./price-simulation.component.scss'],
})
export class PriceSimulationComponent implements OnInit, OnChanges {
  @Input() simulation: SimulationResponse;
  @Input() bundleCode: string;
  @Input() taxEligibility: string;
  @Input() description: string;

  public promoCode;
  public promotionActive = false;
  public price;
  public originalPrice;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (!this.simulation) {
      return;
    }

    this.price = this.simulation.amount.amountInclVat;
    const promoCode = this.simulation.metadata
      .find((el) => el.key === 'promoCode')
      .values.find((el) => el.selected === true);

    this.promoCode = promoCode ? promoCode.value : undefined;

    this.promotionActive = Boolean(this.promoCode);

    if (this.promotionActive) {
      this.originalPrice = this.simulation.charges.find((el) => el.reference === 'fixedPrice').amountInclVat.value;
    }
  }

  /*translation(): string {
    let v =
      'general.upsell.boiler_one_shot.simulation.one_shot_boiler_description';
    if (this.bundleCode.includes('GAS')) {
      v = v.concat('_gas');
    } else {
      v = v.concat('_oil');
    }
    if (
      this.control.get([BoilerOneShotSteps.HOUSE_AGE, 'taxEligibility']) &&
      this.control.get([BoilerOneShotSteps.HOUSE_AGE, 'taxEligibility'])
        .value === 'LOW'
    ) {
      v = v.concat('_low');
    } else {
      v = v.concat('_high');
    }

    return v;
  } */
}
