import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticsEventDirective } from './directives/analytics-event.directive';
import { AnalyticsEventProcessorInterface } from './analytics-event-processor.interface';

@NgModule({
  declarations: [AnalyticsEventDirective],
  imports: [CommonModule],
  exports: [AnalyticsEventDirective],
})
export class AnalyticsModule {
  static forRoot(processor?: AnalyticsEventProcessorInterface): ModuleWithProviders<AnalyticsModule> {
    return {
      ngModule: AnalyticsModule,
      providers: [
        {
          provide: 'processor',
          useValue: processor,
        },
      ],
    };
  }
}
