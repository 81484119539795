import { Component, Input, OnInit } from '@angular/core';
import { Site } from '@app/modules/customer-zone/user/models/site.interface';
import { DeliveryPoint } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { MainFacade } from '@app/core/facade/main.facade';
import {
  EnergyType,
  Index,
  IndexEncodingOrigin,
  MeasurementNature,
} from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { forkJoin, map, Observable, switchMap } from 'rxjs';
import { IndexModalComponent } from '@app/modules/customer-zone/consumption/components/modal/index-modal/index-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Direction } from '@app/modules/customer-zone/consumption/models/deliveryPoint.interface';
import { MeteringsService } from '@app/modules/customer-zone/consumption/services/meterings/meterings.service';
import { EnergyUnit } from '@app/shared/models/units.interface';

@Component({
  selector: 'app-encode-index-widget',
  templateUrl: './encode-index-widget.component.html',
  styleUrls: ['./encode-index-widget.component.scss'],
})
export class EncodeIndexWidgetComponent implements OnInit {
  @Input() reference: string;
  @Input() site: Site;
  @Input() deliveryPoints: DeliveryPoint[];
  metersReading$: Observable<Array<{ energy: EnergyType; reading: Index[] }>>;
  protected direction = Direction;
  protected energyType = EnergyType;
  protected energyUnits = EnergyUnit;

  constructor(public facade: MainFacade, private modalService: NgbModal, private meteringsService: MeteringsService) {}

  ngOnInit(): void {
    this.metersReading$ = this.meteringsService.getState().pipe(
      switchMap(() =>
        forkJoin(
          this.deliveryPoints.map((deliveryPoint: DeliveryPoint) =>
            this.facade
              .loadMeterings(this.reference, this.site, deliveryPoint.energy, deliveryPoint.reference, false, false)
              .pipe(
                map((meterReading: Index[]) => ({
                  energy: deliveryPoint.energy,
                  reading: meterReading.map((reading: Index) => ({
                    ...reading,
                    indexes: reading?.indexes?.every((i) => i.hasOwnProperty('measurementNature'))
                      ? reading.indexes.filter(({ measurementNature }) => measurementNature !== MeasurementNature.GCF)
                      : reading.indexes,
                  })),
                }))
              )
          )
        )
      )
    );
  }

  openPopup() {
    const modalRef = this.modalService.open(IndexModalComponent, {
      size: 'auto',
    });
    modalRef.componentInstance.origin = IndexEncodingOrigin.energyInsight;
  }

  protected readonly EnergyType = EnergyType;
}
