<div
  *ngIf="showIssueNotifier"
  class="bg-blue-dark/10 rounded-4xl px-8 py-4 text-blue-dark"
>
  <div class="md:flex" *ngIf="showCallToAction; else simpleNotifier">
    <span
      class="block pb-4 md:pb-0 md:pr-8 md:flex-1"
      [innerHTML]="translationKey + '.label' | translate"
    > </span>
    <a
      class="font-bold text-primary"
      [routerLink]="translationKey + '.cta.link' | translate"
      [innerHTML]="translationKey + '.cta.label' | translate"
    > </a>
  </div>
  <ng-template #simpleNotifier>
    <span
      class="inline-block w-full"
      [innerHTML]="translationKey + '.label' | translate"
    ></span>
  </ng-template>
</div>
