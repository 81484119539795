<div class="bg-yellow-50 py-4">
  <div class="px-8 text-sm text-grey-dark flex items-center xl:max-w-screen-xl xl:w-full xl:m-auto">
    <img ngSrc="assets/img/icons/standalone/yellow/chart.svg" class="hidden md:inline-block mr-8" width="40" height="40">
    <div
      [innerHTML]="'components.eliqSyncInProgress.banner.title' | translate">
    </div>
  </div>
</div>

<div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
  <section class="border border-grey md:border-0 p-8 bg-white mt-12 rounded-4xl relative sm:p-14 sm:shadow-none">
    <div class="w-full lg:w-3/5">
      <div class="w-full">
        <h3 class="text-2xl leading-tight font-medium">
          {{ 'components.eliqSyncInProgress.title' | translate }}
        </h3>
        <div class="mt-8">
          <h4 class="text-lg">
            {{ 'components.eliqSyncInProgress.firstBlock.title' | translate }}
          </h4>
          <p class="text-base mb-8" >
            {{ 'components.eliqSyncInProgress.firstBlock.content' | translate }}
        </div>
        <div>
          <h4 class="text-lg">
            {{ 'components.eliqSyncInProgress.secondBlock.title' | translate }}
          </h4>
          <p class="inline-block w-full mb-10" >
            {{ 'components.eliqSyncInProgress.secondBlock.content' | translate }}
        </div>
      </div>
      <img ngSrc="assets/img/phone.png" width="448" height="533" class="my-4 mx-auto w-96 md:hidden">
    </div>
    <img class="hidden absolute top-0 right-12 lg:inline-block" ngSrc="assets/img/phone.png" style="max-width: 45%" width="448" height="533">
  </section>
</div>
