<div class="energy mt-8 mb-8">
  <div class="mb-8">{{ 'components.consumption.energySelection.intro' | translate }}</div>
  <div class="mb-4">
    <div class="form-element input-radio-big">
      <input
        id="electricity"
        type="radio"
        value="{{ energyType.ELECTRICITY }}"
        name="energy"
        [formControl]="energySelection.get('selection')"
        [analyticsEvent]="'myEnergyFlow:cta-click:ELECTRICITY'"
      />
      <label for="electricity">{{ 'general.product.electricity' | translate }}</label>
    </div>
  </div>

  <div class="mb-4">
    <div class="form-element input-radio-big">
      <input
        id="gas"
        type="radio"
        value="{{ energyType.GAS }}"
        name="energy"
        [formControl]="energySelection.get('selection')"
        [analyticsEvent]="'myEnergyFlow:cta-click:GAS'"
      />
      <label for="gas">{{ 'general.product.gas' | translate }}</label>
    </div>
  </div>

  <div class="mb-4">
    <div class="form-element input-radio-big">
      <input
        id="duo"
        type="radio"
        value="{{ energyType.DUO }}"
        name="energy"
        [formControl]="energySelection.get('selection')"
        [analyticsEvent]="'myEnergyFlow:cta-click:GAS_ELEC'"
      />
      <label for="duo">{{ 'general.product.duo' | translate }}</label>
    </div>
  </div>
</div>
