import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BindingProcessComponent } from './pages/binding-process/binding-process.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AddReferenceComponent } from './components/step1/add-reference/add-reference.component';
import { SendCodeComponent } from './components/step2/send-code/send-code.component';
import { CodeVerificationComponent } from './components/step3/code-verification/code-verification.component';
import { UnhappyFlowComponent } from './components/step2/unhappy-flow/unhappy-flow.component';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { AnalyticsModule } from '../../shared/modules/analytics/analytics.module';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@NgModule({
  declarations: [
    BindingProcessComponent,
    AddReferenceComponent,
    SendCodeComponent,
    CodeVerificationComponent,
    UnhappyFlowComponent,
  ],
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, SharedModule, RouterModule, AnalyticsModule],
})
export class BindingProcessModule {}

_('pages.bindingProcess.pageTitle');
