import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MoveMeter } from '@app/modules/customer-zone/move/models/status.interface';
import { EnergyType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { TranslateModule } from '@ngx-translate/core';
import { MoveDataCuzoApi, SiteStatusCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Component({
  selector: 'app-move-header',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './move-header.component.html',
  styleUrls: ['./move-header.component.scss'],
})
export class MoveHeaderComponent implements OnInit {
  @Input() moveSite: SiteStatusCuzoApi;
  numberMeterElec: number = 0;
  numberMeterGas: number = 0;

  constructor() {}

  ngOnInit(): void {
    const nbElecPerMove: number[] = this.moveSite.moveData.map(
      (moveData: MoveDataCuzoApi) =>
        moveData.meters.filter((meter: MoveMeter): boolean => meter.energy === EnergyType.ELECTRICITY).length
    );
    const nbGasPerMove: number[] = this.moveSite.moveData.map(
      (moveData: MoveDataCuzoApi) =>
        moveData.meters.filter((meter: MoveMeter): boolean => meter.energy === EnergyType.GAS).length
    );
    this.numberMeterElec = nbElecPerMove.reduce((curr: number, prev: number) => curr + prev, 0);
    this.numberMeterGas = nbGasPerMove.reduce((curr: number, prev: number) => curr + prev, 0);
  }
}
