<div
  class="content relative -ml-[27px]"
  [class.expand]="!loadingState?.get(meter?.ean) && (eanFormGroup && eanFormGroup?.get('selected').value && eanFormGroup?.get('meters')?.['length'])"
  [class.loading]="loadingState?.get(meter?.ean)"
>
  <ng-container *ngIf="loadingState?.get(meter?.ean)">
    <div class="load">
      <div class="flex absolute top-[2rem] w-full justify-center">
        <span class="border-gray-300 h-12 w-12 animate-spin rounded-full border-2 border-t-primary">
        </span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!loadingState?.get(meter?.ean) && eanFormGroup">
    <div *ngIf="eanFormGroup?.get('meters')?.['length']" class="relative z-10 mt-12">
      <div *ngFor="let meterControl of eanFormGroup?.get('meters')?.['controls']; let i = index">
        <app-alert
          [flatDesign]="true"
          [alert]="{
            type: AlertType.warning,
            content: {
              messageKey:
                'Attention: le relevé d\'index doit correspondre à votre date de votre déménagement. Tout relevé d\'index incorrect entrainera…'
            },
          }"
        ></app-alert>
        <h4 class="mt-8 mb-4 text-lg">Compteur Digital</h4>

        <ng-container *ngIf="meterControl?.get('indexes')?.['length']">
          <div class="flex flex-wrap indexes">
            <div class="w-1/2 overflow-hidden" *ngFor="let indexControl of meterControl?.get('indexes')?.['controls']; let j = index">
              <app-input-meter-index
                [style]="InputMeterStyle.INLINE"
                [type]="indexControl?.get('type')?.value"
                [value]="indexControl?.get('value')?.value"
                [control]="indexControl"
                [newType]="deliveryPointMap?.get(meter?.ean)?.meters?.[i]?.registers?.[j]?.timeFrame"
                [unit]="deliveryPointMap?.get(meter?.ean)?.meters?.[i]?.registers?.[j]?.extra?.unit | translate"
                [label]="deliveryPointMap?.get(meter?.ean)?.meters?.[i]?.registers?.[j]?.extra?.label | translate"
                [tooltip]="deliveryPointMap?.get(meter?.ean)?.meters?.[i]?.registers?.[j]?.extra?.tooltip | translate"
              >
              </app-input-meter-index>
            </div>
          </div>
        </ng-container>

      </div>
    </div>
  </ng-container>
</div>
