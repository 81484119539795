import { inject } from '@angular/core';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs';
import { MainFacade } from '@app/core/facade/main.facade';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { AccessRights } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { Paths } from '@app/core/models/paths';
import { DeviceService } from '@app/core/service/device.service';
import { DeviceOrigin } from '@app/core/models/device';

export const userProfile = (next: ActivatedRouteSnapshot) => {
  const deviceService = inject(DeviceService);
  deviceService.type$.next({ isMobileApp: next?.data?.isMobileApp || false });
  const facade: MainFacade = inject(MainFacade);
  return facade.loadUserProfile(next?.data?.isMobileApp ? DeviceOrigin.mobileApp : DeviceOrigin.web);
};

export const accessRights: CanActivateFn = (next: ActivatedRouteSnapshot) => {
  const facade: MainFacade = inject(MainFacade);
  const router: Router = inject(Router);
  return facade.reference$.pipe(
    filter((reference: string) => !!reference),
    distinctUntilChanged((previous: string, current: string) => previous === current),
    switchMap((reference: string) => facade.updateAccessRights(reference)),
    map((ac: AccessRights) => {
      const redirectUrl: null | string = redirectUserWithoutAccessToUrl(
        ac,
        (next?.firstChild || next)?.data?.allowedWithAccess,
        (next?.firstChild || next)?.data?.notAllowedRedirectTo,
        (next?.firstChild || next)?.data?.genericMessageRedirect
      );
      return redirectUrl ? router.parseUrl(redirectUrl) : true;
    })
  );
};

const redirectUserWithoutAccessToUrl = (
  ac: AccessRights,
  access: AccessRights,
  notAllowedRedirectTo: Paths,
  genericMessageRedirect: boolean
): null | string => {
  if (ac?.seeGenericMessage && genericMessageRedirect) {
    return `/${Paths.unauthorized}`;
  }

  if (!access) {
    return null;
  }
  const accessRightNames = Object.keys(access);
  if (
    accessRightNames?.length &&
    accessRightNames.every((accessRightName) => ac[accessRightName] === access[accessRightName])
  ) {
    return null;
  } else {
    return notAllowedRedirectTo;
  }
};
