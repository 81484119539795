import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Civility, ContactDetails } from '../../models/contactDetails.interface';
import { ContactService } from '../../services/contact/contact.service';
import { catchError, map, switchMap, take, takeUntil, throwError } from 'rxjs';
import { forkJoin, Subject } from 'rxjs';
import { BillingDetails } from '../../../billing/models/billingDetails.interface';
import { LangChangeEvent } from '@ngx-translate/core';
import { Alert } from '@app/shared/components/alert/alert.interface';
import { ToastrService } from 'ngx-toastr';
import { ControlsOf } from '@app/shared/models/controls-of';
import { MainFacade } from '@app/core/facade/main.facade';
import { AlertType } from '@app/shared/components/alert/alert.interface';

interface ContactFormGroup {
  civility?: string;
  name?: string;
  firstName?: string;
  contactName?: string;
  companyNumber?: string;
  socialReason?: string;
  email: string;
  iban: string;
  phone: string;
  mobile: string;
  address: {
    street: string;
    streetNumber: string;
    boxNumber: string;
    zipCode: string;
    city: string;
  };
}

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss'],
})
export class ContactDetailsComponent implements OnInit, OnDestroy {
  public civilityOptions = Civility;
  public contactForm: FormGroup<ControlsOf<ContactFormGroup>>;
  public contactDetails: ContactDetails;
  public billingDetails: BillingDetails;
  public activeReference: string;
  public alert: Alert | null = null;
  public showPersonalContactDetails = false;
  public showBusinessContactDetails = false;
  private notifier: Subject<void> = new Subject();

  constructor(
    private facade: MainFacade,
    private contactService: ContactService,
    private fb: UntypedFormBuilder,
    private toastrService: ToastrService
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    this.alert = {
      type: AlertType.info,
      content: {
        message: this.facade.translate.instant('components.contact.contactChangeInProgress'),
      },
    };

    this.analytics();
    this.facade.utils.setPageTitle('pages.contactDetails.title');
    this.facade.translate.onLangChange.pipe(takeUntil(this.notifier)).subscribe((event: LangChangeEvent) => {
      this.analytics();
      if (this.contactForm.get('socialReason') && this.contactDetails?.socialReason?.translations) {
        this.contactForm.get('socialReason').setValue(this.contactDetails?.socialReason?.translations[event.lang]);
      }
    });

    this.facade.reference$
      .pipe(
        takeUntil(this.notifier),
        switchMap((reference: string) => {
          this.activeReference = reference;
          return forkJoin([this.facade.loadContactDetails(reference), this.facade.loadBillingDetails(reference)]).pipe(
            map(([contactDetails, billingDetails]) => ({ contactDetails, billingDetails }))
          );
        })
      )
      .subscribe(({ contactDetails, billingDetails }) => {
        this.contactDetails = contactDetails;
        this.billingDetails = billingDetails;
        this.patchFormValues();
        this.resetFormControl();
        this.addBussinesOrPersonalForm();
      });
  }

  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
  }

  handleSubmit() {
    this.contactForm.markAllAsTouched();
    if (this.contactForm.valid) {
      const contactDetails = Object.assign(this.contactDetails, this.contactForm.value);
      this.contactService
        .updateContactDetails(this.activeReference, contactDetails)
        .pipe(
          take(1),
          catchError((error) => {
            this.toastrService.error(this.facade.translate.instant('errorMessages.serverError'));
            return throwError(() => new Error(error));
          })
        )
        .subscribe(() => {
          this.toastrService.success(this.facade.translate.instant('general.success'));
          this.contactDetails.locked = true;
          this.contactForm.disable();
        });
    }
  }

  initForm() {
    this.contactForm = new FormGroup<ControlsOf<ContactFormGroup>>({
      email: new FormControl('', [Validators.required, Validators.email]),
      iban: new FormControl({ value: '', disabled: true }),
      phone: new FormControl(''),
      mobile: new FormControl(''),
      address: new FormGroup({
        street: new FormControl('', Validators.required),
        streetNumber: new FormControl('', Validators.required),
        boxNumber: new FormControl(''),
        zipCode: new FormControl('', Validators.required),
        city: new FormControl('', Validators.required),
      }),
    });
  }

  patchFormValues() {
    this.contactForm.patchValue({
      email: this.contactDetails.email,
      iban: this.billingDetails.bankAccount,
      phone: this.contactDetails.phone,
      mobile: this.contactDetails.mobile,
      address: {
        street: this.contactDetails.address.street,
        streetNumber: this.contactDetails.address.streetNumber,
        boxNumber: this.contactDetails.address.boxNumber,
        zipCode: this.contactDetails.address.zipCode,
        city: this.contactDetails.address.city,
      },
    });

    if (this.contactDetails?.locked) {
      this.contactForm.disable();
    }
  }

  addBussinesOrPersonalForm() {
    if (this.facade.state$?.value?.accessRights?.personalContactDetails) {
      this.contactForm.addControl('civility', new FormControl({ value: this.contactDetails.civility, disabled: true }));
      this.contactForm.addControl('name', new FormControl({ value: this.contactDetails.lastName, disabled: true }));
      this.contactForm.addControl(
        'firstName',
        new FormControl({ value: this.contactDetails.firstName, disabled: true })
      );
      this.showPersonalContactDetails = true;
      this.showBusinessContactDetails = false;
    } else if (this.facade.state$?.value?.accessRights?.businessContactDetails) {
      this.contactForm.addControl(
        'contactName',
        new FormControl({ value: this.contactDetails.contactName, disabled: true })
      );
      this.contactForm.addControl(
        'companyNumber',
        new FormControl({ value: this.contactDetails.companyNumber, disabled: true })
      );
      this.contactForm.addControl(
        'socialReason',
        new FormControl({
          value: this.contactDetails?.socialReason?.translations[this.facade.translate.currentLang],
          disabled: true,
        })
      );

      this.showPersonalContactDetails = false;
      this.showBusinessContactDetails = true;
    }
  }

  private resetFormControl() {
    this.showPersonalContactDetails = false;
    this.showBusinessContactDetails = false;
    this.contactForm.removeControl('contactName');
    this.contactForm.removeControl('companyNumber');
    this.contactForm.removeControl('socialReason');
    this.contactForm.removeControl('civility');
    this.contactForm.removeControl('name');
    this.contactForm.removeControl('firstName');
  }

  private analytics() {
    this.facade.analytics.push(
      {
        event: 'pageview',
        page: {
          phase: 'care',
          category: 'cuzo',
          subCategory: 'contact-details - cuzo',
        },
      },
      {
        reference: this.facade?.state$?.value?.reference,
        site: this.facade?.state$?.value?.activeSite,
        accessRights: this.facade?.state$?.value?.accessRights,
      }
    );
  }
}
