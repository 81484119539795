import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-step-progress-bar',
  templateUrl: './step-progress-bar.component.html',
  styleUrls: ['./step-progress-bar.component.scss'],
})
export class StepProgressBarComponent implements OnChanges {
  @Input() totalSteps: number;
  @Input() activeStep: number;
  progress;

  constructor() {}

  ngOnChanges(): void {
    this.progress = (this.activeStep / this.totalSteps) * 100;
  }
}
