<div class="form-element custom-select">
  <div class="select" ngbDropdown>
    <div class="selected-option" ngbDropdownToggle>
      <span>{{ activeLabel }}</span>
      <input id="choose-me" type="text" />
    </div>
    <ul class="options" ngbDropdownMenu>
      <ng-container *ngFor="let option of options">
        <li (click)="select(option.id)" ngbDropdownItem>{{ option.label }}</li>
      </ng-container>
    </ul>
  </div>
</div>
