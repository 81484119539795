import { Component, Input, OnInit } from '@angular/core';
import { bankTransfer, directDebit, directDebitAndBankTransfer } from '../../../../billing.constants';
import { BillingDetails } from '../../../../models/billingDetails.interface';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  AccessRight,
  AccessRights,
  UserType,
} from '../../../../../../../shared/resolvers/user-type-resolver/models/user-type.interface';
import { IBANvalidator } from '../../../../../../../shared/utils/utils.validators';
import { ContactDetails } from '../../../../../contact/models/contactDetails.interface';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BillingService } from '../../../../services/billing/billing.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';
import { ConfirmationEmailSentComponent } from './components/confirmation-email-sent/confirmation-email-sent.component';
import { SepaService } from '../../../../services/sepa/sepa.service';
import { MainFacade } from '@app/core/facade/main.facade';
import { AlertType } from '@app/shared/components/alert/alert.interface';

@Component({
  selector: 'app-change-payment-method',
  templateUrl: './change-payment-method.component.html',
  styleUrls: ['./change-payment-method.component.scss'],
})
export class ChangePaymentMethodComponent implements OnInit {
  @Input() billingDetails: BillingDetails;
  @Input() contactDetails: ContactDetails;
  @Input() reference: string;
  @Input() defaultPaymentMethod: string = null;
  readonly accessRight = AccessRight;
  readonly bankTransfer = bankTransfer;
  readonly directDebit = directDebit;
  readonly directDebitAndBankTransfer = directDebitAndBankTransfer;
  control: UntypedFormGroup;
  paymentMethod: string;
  paymentMethods: string[] = [];
  accessRights: AccessRights;
  alertType = AlertType;

  constructor(
    private facade: MainFacade,
    private fb: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private billingService: BillingService,
    private sepaService: SepaService,
    private toastrService: ToastrService,
    private translate: TranslateService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.accessRights = this.facade.state$.value.accessRights;
    if (this.accessRights.activateBankTransfer || this.billingDetails.paymentMethod === bankTransfer) {
      this.paymentMethods.unshift(bankTransfer);
    }
    if (this.accessRights.activateDomv || this.billingDetails.paymentMethod === directDebitAndBankTransfer) {
      this.paymentMethods.unshift(directDebitAndBankTransfer);
    }
    if (this.accessRights.activateDom || this.billingDetails.paymentMethod === directDebit) {
      this.paymentMethods.unshift(directDebit);
    }
    this.buildForm();
  }

  handleSubmit() {
    if (this.control.get('paymentMethod').value === bankTransfer) {
      this.submitBankTransferRequest();
    } else {
      this.submitSepaRequest();
    }
  }

  submitBankTransferRequest() {
    const billingDetails = Object.assign({}, this.billingDetails);
    billingDetails.paymentMethod = bankTransfer;
    this.billingDetails.paymentMethodLocked = true;
    this.billingService.updateBillingDetails(this.reference, billingDetails).subscribe(
      (response) => {
        this.activeModal.close();
        this.toastrService.success(this.translate.instant('general.success'));
      },
      (error) => this.toastrService.error(this.translate.instant('errorMessages.serverError'))
    );
  }

  submitSepaRequest() {
    const sepaInfo = {
      ...this.contactDetails,
      bankAccount: this.control.get('bankAccount').value.replace(/ /g, ''),
      directDebit: true,
      directDebitAndBankTransfer: this.control.get('paymentMethod').value === directDebitAndBankTransfer,
      zoomit: false, // TODO: see if we can remove this from the request
      refundAccount: false, // TODO: see if we can remove this from the request
    };

    this.activeModal.close();
    this.sepaService
      .sendSepaInformation(this.reference, sepaInfo)
      .pipe(take(1))
      .subscribe(
        (response) => {
          this.billingDetails.paymentMethodLocked = true;
          const modalRef = this.modalService.open(ConfirmationEmailSentComponent, {
            size: 'lg',
          });
        },
        (error) => {
          this.toastrService.error(this.translate.instant('errorMessages.serverError'));
        }
      );
  }

  private buildForm() {
    this.control = this.fb.group({
      paymentMethod: [this.defaultPaymentMethod ?? this.billingDetails.paymentMethod, [Validators.required]],
      bankAccount: [this.billingDetails.bankAccount, [Validators.required, IBANvalidator()]],
    });

    if (this.defaultPaymentMethod) {
      this.control.markAllAsTouched();
    }
  }
}
