import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuildingComponent } from './pages/building/building.component';
import { SharedModule } from '../../../shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { BuildingFormComponent } from './components/building-form/building-form.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { AnalyticsModule } from '../../../shared/modules/analytics/analytics.module';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@NgModule({
  declarations: [BuildingComponent, BuildingFormComponent],
  imports: [CommonModule, SharedModule, ReactiveFormsModule, NgbModule, TranslateModule, AnalyticsModule],
})
export class BuildingModule {}

_('pages.building.pageTitle');
_('components.building.form.alert.error');
_('components.building.form.buildingName.label');
_('components.building.form.buildingType.label');
_('components.building.form.constructionDate.label');
_('components.building.form.buildingOccupant.label');
_('components.building.form.occupantNumber.label');
_('components.building.form.isolationPlanned.label');
_('components.building.form.boiler.label');
_('components.building.form.boilerAge.label');
_('components.building.form.boilerMaintenance.label');
_('components.building.form.cooking.label');
_('components.building.form.emptyBuilding.label');
_('components.building.form.heatingPumpCop.label');
_('components.building.form.isolation.label');
_('components.building.form.otherEnergy.label');
_('components.building.form.solarPanelPower');
_('components.building.form.buildingType.house');
_('components.building.form.buildingType.flat');
_('components.building.form.buildingType.flatOf25');
_('components.building.form.buildingType.flatOf50');
_('components.building.form.buildingType.flatOf75');
_('components.building.form.buildingType.flatOf100');
_('components.building.form.buildingType.houseOf100');
_('components.building.form.buildingType.houseOf150');
_('components.building.form.buildingType.houseOf200');
_('components.building.form.constructionDate.before1960');
_('components.building.form.constructionDate.between1960And1970');
_('components.building.form.constructionDate.between1971And1980');
_('components.building.form.constructionDate.between1981And2000');
_('components.building.form.constructionDate.between2001And2012');
_('components.building.form.constructionDate.after2012');
_('components.building.form.constructionDate.unknown');
_('components.building.form.buildingOccupant.tenant');
_('components.building.form.buildingOccupant.owner');
_('components.building.form.cooking.electricity');
_('components.building.form.cooking.gas');
_('components.building.form.cooking.gasAndElectricity');
_('components.building.form.cooking.none');
_('components.building.form.emptyBuilding.active');
_('components.building.form.emptyBuilding.empty');
_('components.building.form.isolation.good');
_('components.building.form.isolation.average');
_('components.building.form.isolation.bad');
_('components.building.form.isolation.none');
_('components.building.form.isolationPlanned.yes');
_('components.building.form.isolationPlanned.no');
_('components.building.form.isolationPlanned.unknown');
_('components.building.form.boiler.electricity');
_('components.building.form.boiler.gas');
_('components.building.form.boiler.oilFired');
_('components.building.form.boiler.pellet');
_('components.building.form.boiler.noBoiler');
_('components.building.form.boiler.other');
_('components.building.form.boiler.heatPump');
_('components.building.form.boilerAge.lessThan5Years');
_('components.building.form.boilerAge.between6And10Years');
_('components.building.form.boilerAge.between11And15Years');
_('components.building.form.boilerAge.moreThan15Years');
_('components.building.form.boilerAge.unknown');
_('components.building.form.boilerMaintenance.yes');
_('components.building.form.boilerMaintenance.no');
_('components.building.form.boilerMaintenance.unknown');
_('components.building.form.boilerMaintenance.yes');
_('components.building.form.boilerMaintenance.no');
_('components.building.form.boilerMaintenance.unknown');
_('components.building.form.otherEnergy.wood');
_('components.building.form.otherEnergy.pellet');
_('components.building.form.otherEnergy.woodAndPellet');
_('components.building.form.otherEnergy.none');
_('components.building.form.waterHeating.electricity');
_('components.building.form.waterHeating.fuel');
_('components.building.form.waterHeating.gas');
_('components.building.form.waterHeating.label');
_('components.building.form.waterHeating.none');
_('components.building.form.waterHeating.thermodynamic');
