<div class="confirmation-section">
  <div class="py-6">
    <h3 class="my-12 text-center" [innerHTML]="'general.upsell.boiler_one_shot.confirmation.title' | translate"></h3>
  </div>

  <!-- simulation -->
  <app-price-simulation [simulation]="simulation$ | async" [bundleCode]="bundleCode" [taxEligibility]="taxEligibility">
  </app-price-simulation>

  <div class="form-element input-text mb-4">
    <label for="promoCode">
      {{ 'general.upsell.boiler_one_shot.confirmation.promo-code-label' | translate }}
    </label>
    <input [formControl]="control.get('promoCode')" id="promoCode" name="promoCode" type="text" autocomplete="off" />
  </div>

  <div class="form-element input-checkbox mt-12">
    <input id="cgv" [formControl]="control.get('cgv')" type="checkbox" class="form__checkbox" />
    <label for="cgv" [innerHTML]="'general.upsell.boiler_one_shot.confirmation.cgv-confirmation' | translate"> </label>
  </div>

  <div class="md:flex next fr my-12">
    <button
      [attr.disabled]="!control.valid || !control.get('cgv').value ? '' : null"
      [class.not-submittable]="!control.valid || !control.get('cgv').value"
      class="w-full btn btn-primary ml-auto md:w-auto"
      (click)="submit()"
    >
      {{ 'general.upsell.boiler_one_shot.confirmation.submit-button' | translate }}
    </button>
    <div class="text-center mt-8 md:flex-1 md:order-first md:mt-0 md:text-left">
      <strong class="navigate-back text-primary cursor-pointer">
        <a (click)="goBack()">{{ 'general.back' | translate }}</a>
      </strong>
    </div>
  </div>

  <h2 class="mb-6 text-2xl font-medium text-grey-dark font-sans">
    {{ 'general.upsell.boiler_one_shot.confirmation.contact-info-title' | translate }}
  </h2>
  <div class="md:grid md:grid-cols-2 rounded-2xl bg-white p-8 mb-42 border border-grey">
    <div class="mb-8">
      <span class="font-medium">{{ 'general.contact.nameAndFirstName' | translate }}</span
      ><br />
      {{ name }}
    </div>
    <div class="mb-8">
      <span class="font-medium">{{ 'general.contact.phone' | translate }}</span> <br />
      {{ phone }}
    </div>
    <div class="mb-8 md:mb-0">
      <span class="font-medium">{{ 'general.email' | translate }}</span> <br />
      {{ email }} <br />
    </div>
  </div>
</div>
