import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NavigationComponent } from '@app/modules/customer-zone/move/components/move-form/navigation/navigation.component';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  ORDERED_QUESTION_LEAVE,
  ORDERED_QUESTION_STAY,
} from '@app/modules/customer-zone/move/components/move-form/move-form-config';
import { MoveFormFacade } from '@app/core/facade/move-form.facade';
import { MainFacade } from '@app/core/facade/main.facade';
import { filter, Observable, Subject, take, takeUntil } from 'rxjs';
import { LoaderStatus } from '@app/modules/customer-zone/move/models/status.interface';
import { INITIAL_MOVE_STATE, MoveDTO, MoveState } from '@app/core/state/move.state';
import { MoveFormStep } from '@app/modules/customer-zone/move/components/move-form/steps/MoveFormStep';

@Component({
  selector: 'app-supplier',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, NavigationComponent, ReactiveFormsModule],
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.scss'],
})
export class SupplierComponent extends MoveFormStep<MoveDTO> implements OnInit, OnDestroy {
  formControl: FormControl<boolean> = new FormControl(null, Validators.required);
  notifier: Subject<void> = new Subject<void>();
  private moveDTO: MoveDTO;

  constructor(private readonly facade: MainFacade, protected readonly moveFormFacade: MoveFormFacade) {
    super(moveFormFacade);
  }

  ngOnInit(): void {
    this.moveFormFacade.state$
      .pipe(
        filter((): boolean => this.moveFormFacade.state$.value !== INITIAL_MOVE_STATE),
        takeUntil(this.notifier)
      )
      .subscribe((state: MoveState) => {
        this.moveDTO = state?.moveDTO;
        this.formControl.setValue(state?.form?.supplier?.amendment);
      });
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }

  onNextClicked() {
    if (this.formControl.valid) {
      this.moveFormFacade.loader$.next(LoaderStatus.LOADING);
      const operation$: Observable<MoveDTO> = this.saveFormData();
      operation$.pipe(take(1)).subscribe((): void => {
        this.moveFormFacade.next();
        this.moveFormFacade.loader$.next(LoaderStatus.LOADED);
      });
    }
  }

  onPreviousClicked() {
    this.moveFormFacade.previous();
  }

  saveFormData(): Observable<MoveDTO> {
    const hasMoveId: string = this.moveDTO?.id;
    const customerReference: string = this.moveDTO?.customerReference || this.facade.state$.value.reference;

    this.moveFormFacade.updateData({
      moveDTO: {
        ...this.moveDTO,
        customerReference,
        amendment: this.formControl.value,
      },
      form: {
        supplier: {
          amendment: this.formControl.value,
        },
      },
    });

    this.moveFormFacade.setRoute(this.formControl.value ? ORDERED_QUESTION_STAY : ORDERED_QUESTION_LEAVE);

    return hasMoveId ? this.moveFormFacade.update() : this.moveFormFacade.create(customerReference);
  }
}
