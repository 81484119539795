import { Injectable } from '@angular/core';
import { ContactDetails } from '../../../contact/models/contactDetails.interface';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs';
import { BankInformation } from '../../models/bankInformation.interface';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SepaService {
  constructor(private http: HttpClient) {}

  sendSepaInformation(reference: string, data): Observable<void> {
    return this.http.post<void>(`/v1/customers/${reference}/sepa`, data);
  }
}
