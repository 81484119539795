import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  InvoiceModelV2CuzoApi,
  InvoiceModelV2StatusEnumCuzoApi,
} from '../../../../../../shared/models/cuzo-be-contract';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-invoice-issue-notifier',
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterLink],
  templateUrl: './invoice-issue-notifier.component.html',
  styleUrls: ['./invoice-issue-notifier.component.scss'],
})
export class InvoiceIssueNotifierComponent implements OnInit {
  @Input() invoice: InvoiceModelV2CuzoApi;
  readonly statusIssueEligible: InvoiceModelV2StatusEnumCuzoApi[] = [
    InvoiceModelV2StatusEnumCuzoApi.DIRECT_DEBIT_REJECTED,
    InvoiceModelV2StatusEnumCuzoApi.CANCELLED,
    InvoiceModelV2StatusEnumCuzoApi.REFUND_ON_HOLD,
  ];
  readonly callToActionEligible: InvoiceModelV2StatusEnumCuzoApi[] = [
    InvoiceModelV2StatusEnumCuzoApi.NO_REFUND_ACCOUNT,
  ];
  showIssueNotifier: boolean = false;
  showCallToAction: boolean = false;
  translationKey: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    this.showCallToAction = this.callToActionEligible.includes(this.invoice.status);
    this.showIssueNotifier = this.statusIssueEligible.includes(this.invoice.status) || this.showCallToAction;
    if (!this.showIssueNotifier) {
      this.renderer.setStyle(this.el.nativeElement, 'display', 'none');
    } else {
      this.translationKey = this.setTranslationKey();
    }
  }

  private setTranslationKey(): string {
    let key: string = `general.invoice.status.${this.invoice?.status.toLowerCase()}.issueNotifier`;
    if (this.invoice.status === InvoiceModelV2StatusEnumCuzoApi.CANCELLED) {
      key += this.invoice?.amount?.amountWithVat < 0 ? '.credit' : '.debit';
    }
    return key;
  }
}
