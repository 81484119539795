<ng-container *ngIf="showMeterReadings">
  <app-meter-reading></app-meter-reading>
</ng-container>
<ng-container *ngIf="showDashboard">
  <div *ngIf="!showGuide; else showGuideTpl">
    <div *ngIf="eliqAccessRights">
      <div class="sites-infos bg-white">
        <div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
          <div class="items-center pb-8 pt-14 sm:pb-14 md:grid md:grid-cols-12 md:gap-16">
            <div class="mb-12 md:mb-0 md:col-span-8">
              <app-sites-switcher
                class="inline-block w-full ml-auto sites-switcher mb-8 md:mb-0 md:w-auto"
                [sites]="sites$ | async"
                [activeSite]="activeSite"
                [version]="versionSize.big"
                [activeReference]="reference"
                (switchSiteEmitter)="switchSite($event)"
              >
              </app-sites-switcher>
            </div>
            <ng-container
              *ngIf="eliqAccessRights?.accessPerSiteId?.seeHomeProfileTabAndMyHome && homeProfileCompletion !== null"
            >
              <div class="md:col-span-4">
                <app-home-profile-progress-widget
                  [progressValue]="homeProfileCompletion"
                  [showEditLink]="homeProfileCompletion !== 100"
                ></app-home-profile-progress-widget>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div *ngIf="eliqAccessRights?.accessPerSiteId?.isEnergyInsightFeatureEligible; else mandatesView">
          <ng-container *ngIf="!isSyncEliqData; else eliqIsSyncData">
            <div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
              <div class="my-16">
                <ng-container [ngSwitch]="currentMeterConfig">
                  <app-basic-tpl *ngSwitchCase="meterConfig.basic">
                    <ng-template
                      [ngTemplateOutlet]="monthlyConsumptionChartWidget"
                      monthlyConsumptionChartWidget
                    ></ng-template>

                    <ng-template
                      [ngTemplateOutlet]="disaggregationChartWidget"
                      disaggregationChartWidget
                    ></ng-template>

                    <ng-template
                      [ngTemplateOutlet]="comparisonElecConsumptionWidget"
                      comparisonElecConsumptionWidget
                    ></ng-template>

                    <ng-template
                      [ngTemplateOutlet]="comparisonGasConsumptionWidget"
                      comparisonGasConsumptionWidget
                    ></ng-template>

                    <ng-template
                      [ngTemplateOutlet]="crossSellView"
                      crossSellView
                    ></ng-template>
                  </app-basic-tpl>

                  <app-smart-tpl *ngSwitchCase="meterConfig.smart">
                    <ng-template
                      [ngTemplateOutlet]="forecastElecConsumptionWidget"
                      forecastElecConsumptionWidget
                    ></ng-template>

                    <ng-template [ngTemplateOutlet]="forecastGasConsumptionWidget" forecastGasConsumptionWidget></ng-template>

                    <app-peak-consumption-widget
                      peakConsumptionWidget
                      [reference]="reference"
                      [site]="activeSite"
                      [deliveryPoints]="deliveryPoints"
                      [eliqAccessRights]="eliqAccessRights"
                    >
                    </app-peak-consumption-widget>

                    <ng-template
                      [ngTemplateOutlet]="monthlyConsumptionChartWidget"
                      monthlyConsumptionChartWidget
                    ></ng-template>

                    <ng-template
                      [ngTemplateOutlet]="comparisonElecConsumptionWidget"
                      comparisonElecConsumptionWidget
                    ></ng-template>

                    <ng-template
                      [ngTemplateOutlet]="comparisonGasConsumptionWidget"
                      comparisonGasConsumptionWidget
                    ></ng-template>

                    <ng-template [ngTemplateOutlet]="disaggregationChartWidget" disaggregationChartWidget></ng-template>

                    <ng-template [ngTemplateOutlet]="crossSellView" crossSellView></ng-template>
                  </app-smart-tpl>

                  <app-solar-tpl *ngSwitchCase="meterConfig.solar">
                    <app-encode-index-widget
                      encodeIndexWidget
                      [reference]="reference"
                      [site]="activeSite"
                      [deliveryPoints]="deliveryPoints"
                    >
                    </app-encode-index-widget>

                    <app-meter-reading-chart-widget
                      meterReadingChartWidget
                      [reference]="reference"
                      [site]="activeSite"
                      [deliveryPoints]="deliveryPoints"
                      [eliqAccessRights]="eliqAccessRights"
                    >
                    </app-meter-reading-chart-widget>

                    <ng-template
                      [ngTemplateOutlet]="comparisonElecConsumptionWidget"
                      comparisonElecConsumptionWidget
                    ></ng-template>

                    <ng-template
                      [ngTemplateOutlet]="comparisonGasConsumptionWidget"
                      comparisonGasConsumptionWidget
                    ></ng-template>

                    <ng-template [ngTemplateOutlet]="disaggregationChartWidget" disaggregationChartWidget></ng-template>

                    <ng-template [ngTemplateOutlet]="crossSellView" crossSellView></ng-template>
                  </app-solar-tpl>

                  <app-smart-solar-tpl *ngSwitchCase="meterConfig.smartAndSolar">
                    <ng-template
                      [ngTemplateOutlet]="forecastElecConsumptionWidget"
                      forecastElecConsumptionWidget
                    ></ng-template>

                    <ng-template [ngTemplateOutlet]="forecastGasConsumptionWidget" forecastGasConsumptionWidget></ng-template>

                    <app-forecast-injection-widget
                      forecastInjectionWidget
                      class="h-full"
                      [reference]="reference"
                      [site]="activeSite"
                      [deliveryPoints]="deliveryPoints"
                      [eliqAccessRights]="eliqAccessRights"
                      [energyType]="energyType.ELECTRICITY"
                    >
                    </app-forecast-injection-widget>

                    <app-peak-consumption-widget
                      peakConsumptionWidget
                      class="h-full"
                      [template]="versionSize.small"
                      [reference]="reference"
                      [site]="activeSite"
                      [deliveryPoints]="deliveryPoints"
                      [eliqAccessRights]="eliqAccessRights"
                    >
                    </app-peak-consumption-widget>

                    <ng-template
                      [ngTemplateOutlet]="monthlyConsumptionChartWidget"
                      monthlyConsumptionChartWidget
                    ></ng-template>

                    <ng-template
                      [ngTemplateOutlet]="comparisonElecConsumptionWidget"
                      comparisonElecConsumptionWidget
                    ></ng-template>

                    <ng-template
                      [ngTemplateOutlet]="comparisonGasConsumptionWidget"
                      comparisonGasConsumptionWidget
                    ></ng-template>

                    <ng-template [ngTemplateOutlet]="disaggregationChartWidget" disaggregationChartWidget></ng-template>

                    <ng-template [ngTemplateOutlet]="crossSellView" crossSellView></ng-template>
                  </app-smart-solar-tpl>
                </ng-container>
              </div>
            </div>
          </ng-container>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #eliqIsSyncData>
  <app-eliq-sync-in-progress>
  </app-eliq-sync-in-progress>
</ng-template>

<ng-template #mandatesView>
  <app-fluvius-mandates-intro
    [mandates]="mandates$ | async"
    [eliqAccessRights]="eliqAccessRights"
    [contracts]="contracts"
  >
  </app-fluvius-mandates-intro>
  <app-fluvius-mandates
    [mandates]="mandates$ | async"
    [reference]="reference"
    [eliqAccessRights]="eliqAccessRights"
  >
  </app-fluvius-mandates>
</ng-template>

<ng-template #forecastElecConsumptionWidget>
  <app-forecast-consumption-widget
    forecastElecConsumptionWidget
    class="h-full"
    [reference]="reference"
    [site]="activeSite"
    [deliveryPoints]="deliveryPoints"
    [eliqAccessRights]="eliqAccessRights"
    [energyType]="energyType.ELECTRICITY"
  >
  </app-forecast-consumption-widget>
</ng-template>

<ng-template #forecastGasConsumptionWidget>
  <app-forecast-consumption-widget
    forecastGasConsumptionWidget
    class="h-full"
    [reference]="reference"
    [site]="activeSite"
    [deliveryPoints]="deliveryPoints"
    [eliqAccessRights]="eliqAccessRights"
    [energyType]="energyType.GAS"
  >
  </app-forecast-consumption-widget>
</ng-template>

<ng-template #monthlyConsumptionChartWidget>
  <app-monthly-consumption-chart-widget
    monthlyConsumptionChartWidget
    [reference]="reference"
    [site]="activeSite"
    [deliveryPoints]="deliveryPoints"
    [meterConfig]="currentMeterConfig"
    [eliqAccessRights]="eliqAccessRights"
  >
  </app-monthly-consumption-chart-widget>
</ng-template>

<ng-template #comparisonElecConsumptionWidget>
  <app-comparison-consumption-widget
    comparisonElecConsumptionWidget
    *ngIf="widgetDisplaying.comparisonElectricity"
    class="block col-span-4 mb-8 md:mb-0 relative z-20"
    [reference]="reference"
    [site]="activeSite"
    [deliveryPoints]="deliveryPoints"
    [energyType]="energyType.ELECTRICITY"
    [eliqAccessRights]="eliqAccessRights"
  >
  </app-comparison-consumption-widget>
</ng-template>

<ng-template #comparisonGasConsumptionWidget>
  <app-comparison-consumption-widget
    comparisonGasConsumptionWidget
    *ngIf="widgetDisplaying.comparisonGas"
    class="block col-span-4 mb-8 md:mb-0 relative z-10"
    [reference]="reference"
    [site]="activeSite"
    [deliveryPoints]="deliveryPoints"
    [energyType]="energyType.GAS"
    [eliqAccessRights]="eliqAccessRights"
  >
  </app-comparison-consumption-widget>
</ng-template>

<ng-template #disaggregationChartWidget>
  <app-disaggregation-chart-widget
    disaggregationChartWidget
    *ngIf="widgetDisplaying.disaggregation"
    class="block col-span-4 mb-8 md:mb-0 relative"
    [contracts]="contracts"
    [deliveryPoints]="deliveryPoints"
    [meterConfig]="currentMeterConfig"
    [eliqAccessRights]="eliqAccessRights"
  >
  </app-disaggregation-chart-widget>
</ng-template>

<ng-template #crossSellView>
  <div class="{{ crossSells.length === 1 ? 'col-span-4' : 'col-span-12' }}">
    <div class="{{ crossSells.length === 1 ? '' : 'md:grid md:grid-cols-12 gap-16' }}">
      <ng-container *ngFor="let crossSell of crossSells">
        <div
          *ngIf="crossSell[0] === CrossSellType.disclaimerSmartMeter"
          [class.col-span-4]="crossSells.length > 1"
          class="mt-8 md:mt-0"
        >
          <app-cross-sell-smart-widget
            [crossSellType]="crossSell[0]"
          >
          </app-cross-sell-smart-widget>
        </div>
        <div
          *ngIf="crossSell[0] !== CrossSellType.disclaimerSmartMeter"
          [class.col-span-4]="crossSells.length > 1"
          class="mt-8 md:mt-0"
        >
          <app-cross-sell-widget [crossSellType]="crossSell[0]"></app-cross-sell-widget>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #showGuideTpl>
  <app-first-login-guide (closeGuideEmitter)="closeGuide()">
  </app-first-login-guide>
</ng-template>
