import { Component, inject, Input, OnInit } from '@angular/core';
import { CurrencyPipe, DatePipe, NgForOf, NgIf } from '@angular/common';
import { MainFacade } from '../../../../../../core/facade/main.facade';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { PaymentPlanSliceComponent } from './payment-plan-slice/payment-plan-slice.component';
import { PaymentPlanHeadersComponent } from './payment-plan-headers/payment-plan-headers.component';
import { SliceCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Component({
  selector: 'app-payment-plan-breakdown',
  standalone: true,
  templateUrl: './breakdown.component.html',
  styleUrls: ['./breakdown.component.scss'],
  imports: [
    NgIf,
    NgForOf,
    DatePipe,
    CurrencyPipe,
    PaymentStatusComponent,
    PaymentPlanSliceComponent,
    PaymentPlanHeadersComponent,
  ],
})
export class PaymentPlanBreakdownComponent implements OnInit {
  @Input() paymentPlanSlices: SliceCuzoApi[];
  facade: MainFacade = inject(MainFacade);
  ngOnInit(): void {}
}
