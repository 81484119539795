import { Component, Input, OnInit } from '@angular/core';
import { MainFacade } from '@app/core/facade/main.facade';
import { BillingFacade } from '@app/core/facade/billing.facade';
import { CurrencyPipe, DatePipe, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { BillingDetailsCuzoApi, UpcomingPaymentCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Component({
  selector: 'app-upcoming-payment',
  standalone: true,
  templateUrl: './upcoming-payment.component.html',
  imports: [NgIf, DatePipe, CurrencyPipe, TranslateModule],
  styleUrls: ['./upcoming-payment.component.scss'],
})
export class UpcomingPaymentComponent implements OnInit {
  @Input() upcomingPayment: UpcomingPaymentCuzoApi;
  @Input() billingDetails: BillingDetailsCuzoApi;
  @Input() paymentId: string;
  payWithDirectDebit: Boolean;
  structuredCommunication: string;

  constructor(public facade: MainFacade, public billingFacade: BillingFacade) {}

  ngOnInit(): void {
    this.payWithDirectDebit = this.billingFacade.billingState$.value.payWithDirectDebit;
    this.structuredCommunication = this.billingFacade.formatStructuredCommunication(this.paymentId);
  }
}
