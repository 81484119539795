<p
  class="{{ energyType | lowercase }} form-element input-checkbox-big errors mt-8"
  [formGroup]="eanFormGroup"
>
  <input
    type="checkbox"
    [id]="meter?.ean"
    [formControlName]="'selected'"
    (change)="onCheckboxChange($event)"
  >
  <label [for]="meter?.ean">
    <div class="flex items-center">
      <div class="pr-6">
        Compteur n°{{ meter?.number}}
        <span class="inline-block w-full text-sm text-grey-dark60/60"> EAN: {{ meter.ean }} </span>
      </div>
      <img ngSrc="./assets/img/icons/standalone/default/{{ energyType | lowercase }}.svg" class="ml-auto" height="30" width="24">
    </div>

    <app-meters-control
      [eanFormGroup]="eanFormGroup"
      [meter]="meter"
      [loadingState]="loadingState"
    >
    </app-meters-control>

    <span class="base-border"></span>
  </label>
</p>
