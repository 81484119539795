import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addDaysToDate',
  standalone: true,
})
export class AddDaysToDatePipe implements PipeTransform {
  transform(value: string, days: number): Date {
    const result: Date = new Date(value);
    result.setDate(result.getDate() + days);
    return result;
  }
}
