import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-widget',
  templateUrl: './error-widget.component.html',
  styleUrls: ['./error-widget.component.scss'],
})
export class ErrorWidgetComponent implements OnInit {
  @Input() showBorder? = true;
  constructor() {}

  ngOnInit(): void {}
}
