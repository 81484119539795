import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SlideToggleDirective } from '@app/shared/directives/slide-toggle.directive';
import { BillingDetailsCuzoApi, InvoiceModelV2CuzoApi } from '@app/shared/models/cuzo-be-contract';
import { EnergyType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { AnalyticsModule } from '@app/shared/modules/analytics/analytics.module';
import { InvoiceActionsComponent } from '@app/modules/customer-zone/invoices/components/micro/invoice-actions/invoice-actions.component';
import { PaymentBalanceViewType } from '@app/modules/customer-zone/invoices/models/invoice.interface';
import { InvoiceTypeComponent } from '@app/modules/customer-zone/invoices/components/micro/invoice-type/invoice-type.component';
import { InvoicePayOnlineComponent } from '@app/modules/customer-zone/invoices/components/micro/invoice-pay-online/invoice-pay-online.component';
import { RouterLink } from '@angular/router';
import { AccessRights } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { MainFacade } from '@app/core/facade/main.facade';
import { InvoiceStatusComponent } from '@app/modules/customer-zone/invoices/components/micro/invoice-status/invoice-status.component';

@Component({
  selector: 'app-payments-list',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    SlideToggleDirective,
    AnalyticsModule,
    InvoiceActionsComponent,
    InvoiceTypeComponent,
    InvoicePayOnlineComponent,
    RouterLink,
    InvoiceStatusComponent,
  ],
  templateUrl: './payments-list.component.html',
  styleUrls: ['./payments-list.component.scss'],
})
export class PaymentsListComponent {
  @Input() public title: string;
  @Input() public invoices: InvoiceModelV2CuzoApi[];
  @Input() public billingDetails: BillingDetailsCuzoApi;
  @Input() public viewType: PaymentBalanceViewType;
  @Input() public accessRights: AccessRights;
  @Input() public numberOfPaymentPlanSlicesToPay: number = 0;
  public PaymentBalanceViewType = PaymentBalanceViewType;
  public EnergyType = EnergyType;

  constructor(public facade: MainFacade) {}
}
