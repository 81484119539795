<ng-container *ngIf="infoBoxTranslations">
  <div class="sm:block sm:mt-10" *ngFor="let infoBox of infoBoxTranslations | async">
    <div class="card te-border-gradient-home">
      <div class="article">
        <h3
          class="text-lg text-grey-dark font-medium mb-10 leading-relaxed"
          [innerHTML]="infoBox?.title | translate"
        ></h3>
        <p class="text-sm text-grey-dark" [innerHTML]="infoBox?.content | translate"></p>
        <p>
          <a
            [href]="infoBox?.link | translate"
            target="_blank"
            class="btn-text inline-block w-full mt-8 text-primary font-medium"
          >
            {{ infoBox?.label | translate }}
          </a>
        </p>
      </div>
    </div>
  </div>
</ng-container>
