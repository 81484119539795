import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MgmComponent } from './pages/mgm/mgm.component';
import { MgmShareToolComponent } from './components/mgm-share-tool/mgm-share-tool.component';
import { MgmContentInfoComponent } from './components/mgm-content-info/mgm-content-info.component';
import { FaqModule } from '../../../shared/modules/faq/faq.module';
import { TranslateModule } from '@ngx-translate/core';
import { MgmSponsorInfoComponent } from './components/mgm-sponsor-info/mgm-sponsor-info.component';
import { SharedModule } from '../../../shared/shared.module';
import { ClipboardModule } from 'ngx-clipboard';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { AnalyticsModule } from '../../../shared/modules/analytics/analytics.module';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@NgModule({
  declarations: [MgmComponent, MgmShareToolComponent, MgmContentInfoComponent, MgmSponsorInfoComponent],
  imports: [
    CommonModule,
    FaqModule,
    TranslateModule,
    SharedModule,
    ClipboardModule,
    NgbDropdownModule,
    AnalyticsModule,
    NgbPaginationModule,
  ],
})
export class MgmModule {}

_('pages.mgm.pageTitle');
