import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  public requestsCounter = 0;
  public ignoreLoadingForSingleRequest = false;
  private isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isLoading = false;

  constructor() {}

  public show() {
    this.isLoading$.next(true);
    this.isLoading = true;
  }

  public hide() {
    this.isLoading$.next(false);
    this.isLoading = false;
  }

  public getLoaderStatus() {
    return this.isLoading$;
  }

  public showLoadingEffect() {
    if (!this.isLoading) {
      this.show();
      setTimeout(() => this.requestsCounter === 0 && this.hide(), 700);
    }
  }
}
