import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { EstimatedIndexService } from '../../services/estimated-index/estimated-index.service';
import { Observable, Subject } from 'rxjs';
import { EstimatedIndex } from '../../models/estimated-index.interface';
import { LangChangeEvent } from '@ngx-translate/core';
import { MainFacade } from '@app/core/facade/main.facade';

@Component({
  selector: 'app-estimated-index',
  templateUrl: './estimated-index.component.html',
  styleUrls: ['./estimated-index.component.scss'],
})
export class EstimatedIndexComponent implements OnInit, OnDestroy {
  public reference;
  public indexes$: Observable<EstimatedIndex[]>;
  private notifier: Subject<void> = new Subject();

  constructor(private facade: MainFacade, private estimatedIndexesService: EstimatedIndexService) {}

  ngOnInit(): void {
    this.analytics();
    this.facade.utils.setPageTitle('pages.estimatedIndex.pageTitle');
    this.facade.translate.onLangChange
      .pipe(takeUntil(this.notifier))
      .subscribe((event: LangChangeEvent) => this.analytics());

    this.facade.reference$.pipe(takeUntil(this.notifier)).subscribe((reference: string) => {
      this.reference = reference;
      this.indexes$ = this.estimatedIndexesService.getEstimatedIndexes(reference);
    });
  }

  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
  }

  private analytics() {
    this.facade.analytics.push(
      {
        event: 'pageview',
        page: {
          phase: 'care',
          category: 'cuzo',
          subCategory: 'green-certificate - cuzo',
        },
      },
      {
        reference: this.facade?.state$?.value?.reference,
        site: this.facade?.state$?.value?.activeSite,
        accessRights: this.facade?.state$?.value?.accessRights,
        eliqAccessRights: this.facade?.state$?.value?.eliqAccessRights,
        contracts: this.facade?.state$?.value?.contracts,
      }
    );
  }
}
