import { PercentPipe } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-home-profile-progress-widget',
  templateUrl: './home-profile-progress-widget.component.html',
  styleUrls: ['./home-profile-progress-widget.component.scss'],
})
export class HomeProfileProgressWidgetComponent {
  @Input() progressValue: number;
  @Input() showEditLink = false;

  constructor(private percentPipe: PercentPipe) {}

  public getPercentageProgressValue(): string {
    return this.percentPipe.transform(this.progressValue / 100);
  }
}
