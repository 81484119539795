import { Injectable } from '@angular/core';
import {
  InvoiceModelV2CuzoApi,
  InvoiceResponseV2CuzoApi,
  InvoiceModelV2StatusEnumCuzoApi,
  OpenInvoicesCuzoApi,
} from '../../../../../shared/models/cuzo-be-contract';
import { AddDaysToDatePipe } from '../../../../../shared/pipes/add-days-to-date/add-days-to-date.pipe';

@Injectable({
  providedIn: 'root',
})
/* @todo: TO BE REMOVED ONCE BACKEND READY */
export class InvoiceTransformService {
  constructor(private addDaysToDatePipe: AddDaysToDatePipe) {}

  transformOpenInvoices(data: OpenInvoicesCuzoApi): OpenInvoicesCuzoApi {
    return {
      ...data,
      due: {
        ...data.due,
        invoices: this.map(data.due.invoices),
      },
      refund: {
        ...data.refund,
        invoices: this.map(data.refund.invoices),
      },
    };
  }

  transformInvoices(data: InvoiceResponseV2CuzoApi): InvoiceResponseV2CuzoApi {
    return {
      ...data,
      invoices: this.map(data.invoices),
    };
  }

  private map(invoices: InvoiceModelV2CuzoApi[]): InvoiceModelV2CuzoApi[] {
    return invoices.map(
      (invoice: InvoiceModelV2CuzoApi): InvoiceModelV2CuzoApi => ({
        ...invoice,
        pdfAvailable: this.getPDFAvailability(invoice),
        status: this.shouldUpdateRefundStatus({ invoice: invoice })
          ? InvoiceModelV2StatusEnumCuzoApi.REFUND_ON_HOLD
          : invoice.status,
      })
    );
  }

  private getPDFAvailability(invoice: InvoiceModelV2CuzoApi): boolean {
    let pdfAvailable = true;
    if (invoice.invoiceDate) {
      const invoiceDate: Date = new Date(invoice.invoiceDate);
      invoiceDate.setDate(invoiceDate.getDate() + 2);
      pdfAvailable = invoiceDate.getTime() <= new Date().getTime();
    }
    return pdfAvailable;
  }

  private shouldUpdateRefundStatus({ invoice }: { invoice: InvoiceModelV2CuzoApi }): boolean {
    if (invoice?.status !== InvoiceModelV2StatusEnumCuzoApi.REFUND_PLANNED) {
      return false;
    }
    const refundDate: Date = this.addDaysToDatePipe.transform(invoice?.invoiceDate, 18);
    const today: Date = new Date();
    return refundDate < today;
  }
}
