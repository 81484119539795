import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs';
import {
  ConsumptionRequestData,
  Granularity,
  MeterVolume,
  PeakVolume,
  Provider,
  SelfConsumption,
  SelfConsumptionRequestData,
  Volume,
  VolumeRequestData,
  VolumesPerReference,
} from '../../models/consumption.interface';
import { Direction } from '../../models/deliveryPoint.interface';
import { UserData } from '../../pages/power-peak-graph/power-peak-graph.component';
import moment from 'moment';

export enum SmartMeterErrorsTypes {
  noData = 'noData',
  noDataFluvius = 'noDataFluvius',
  serverError = 'serverError',
  serverErrorFluvius = 'serverErrorFluvius',
  missingMandate = 'missingMandate',
  noErrors = 'noErrors',
}

@Injectable({
  providedIn: 'root',
})
export class VolumesService {
  private volumes: VolumesPerReference | Record<string, unknown> = {};
  private volumes$: BehaviorSubject<Volume[]> = new BehaviorSubject(null);

  private totalVolume$: BehaviorSubject<{ [key: string]: number }> = new BehaviorSubject(null);
  private averageConsumption$: BehaviorSubject<number> = new BehaviorSubject(null);
  private currentPowerPeak$: BehaviorSubject<PeakVolume> = new BehaviorSubject(null);

  private error$: BehaviorSubject<SmartMeterErrorsTypes> = new BehaviorSubject(null);

  constructor(private http: HttpClient) {}

  public getError$() {
    return this.error$.asObservable();
  }

  public getVolumes$() {
    return this.volumes$.asObservable();
  }

  public getTotalVolume$() {
    return this.totalVolume$.asObservable();
  }

  public getAverageConsumption$() {
    return this.averageConsumption$.asObservable();
  }

  public getCurrentPowerPeak$() {
    return this.currentPowerPeak$;
  }

  public getPowerPeakInMonth(data: UserData, granularity: Granularity, to: string): BehaviorSubject<PeakVolume> {
    this.getPowerPeak$(data, granularity, to)
      .pipe(
        tap((vol: PeakVolume[]) => {
          if (vol?.length) {
            const maxPeak = vol.reduce((max: PeakVolume, curr: PeakVolume) => (max.value > curr.value ? max : curr));
            this.currentPowerPeak$.next(maxPeak);
          }
        })
      )
      .subscribe();
    return this.currentPowerPeak$;
  }

  public getPowerPeak$(data: UserData, granularity: Granularity, to: string): Observable<PeakVolume[]> {
    const fromDate = new Date(data.from);
    const toDate = new Date(to);
    let params = new HttpParams();
    params = granularity ? params.append('granularity', granularity.toUpperCase()) : params;
    params = fromDate ? params.append('from', moment(fromDate).utc(true).format('YYYY-MM-DD')) : params;
    params = toDate ? params.append('to', moment(toDate).utc(true).format('YYYY-MM-DD')) : params;
    params = params.append('loader', false);

    return this.http
      .get<PeakVolume[]>(
        `/v1/customers/${data.reference}/sites/${data.siteId}/contracts/ELECTRICITY/${data.deliveryPoint}/peak-power`,
        {
          params,
        }
      )
      .pipe(
        map((response: PeakVolume[]) =>
          response.map((peakVolume: PeakVolume) => {
            if (!('volumes' in peakVolume)) {
              return peakVolume;
            }
            return {
              previousMeteringDate: peakVolume.previousMeteringDate,
              currentMeteringDate: peakVolume.currentMeteringDate,
              value: peakVolume.volumes[0].value,
              unit: peakVolume.volumes[0].unit,
            };
          })
        )
      );
  }

  public getVolumesDetails(requestData: ConsumptionRequestData): Observable<Volume[]> {
    return this.getVolumesDetailsFromApi(requestData);
  }

  public getSelfConsumption(requestData: SelfConsumptionRequestData): Observable<SelfConsumption> {
    return this.getSelfConsumptionFromApi(requestData);
  }

  public getVolumes(requestData: VolumeRequestData): Observable<Volume[]> {
    return this.getVolumesFromApi(requestData);
  }

  // TODO: this method will be removed with my consumption page
  public getAndStoreVolumes(requestData: VolumeRequestData, resetData: boolean = true): Observable<Volume[]> {
    if (!resetData) {
      this.volumes$.next(null);
      this.totalVolume$.next(null);
      this.averageConsumption$.next(null);
      this.error$.next(SmartMeterErrorsTypes.missingMandate);
    } else {
      if (
        requestData.provider !== Provider.FLUVIUS &&
        this.volumes[requestData.reference] &&
        this.volumes[requestData.reference][requestData.deliveryPoint]
      ) {
        if (this.volumes[requestData.reference][requestData.deliveryPoint].length === 0) {
          this.error$.next(SmartMeterErrorsTypes.noData);
        } else {
          this.error$.next(SmartMeterErrorsTypes.noErrors);
        }
        this.volumes$.next(this.volumes[requestData.reference][requestData.deliveryPoint]);
      } else {
        this.getAndStoreVolumesFromApi(requestData).subscribe();
      }
    }

    return this.volumes$.asObservable();
  }

  // TODO: this method will be removed with my consumption page
  private getAndStoreVolumesFromApi(requestData: VolumeRequestData): Observable<Volume[]> {
    return this.getVolumesFromApi(requestData).pipe(
      map((volumes: Volume[]) => {
        if (volumes?.length === 0) {
          if (requestData.provider === Provider.AR) {
            this.error$.next(SmartMeterErrorsTypes.noData);
          } else if (requestData.provider === Provider.FLUVIUS) {
            this.error$.next(SmartMeterErrorsTypes.noDataFluvius);
          }
        } else {
          this.error$.next(SmartMeterErrorsTypes.noErrors);
        }
        // Only accept kwh for now.
        volumes.forEach((volume: Volume) => {
          volume.volumes = volume.volumes.filter((x) => x.unit === 'kWh');
        });
        return volumes;
      }),
      tap((volumes: Volume[]) => {
        if (requestData.provider === Provider.AR) {
          // We store AR volumes.
          if (!this.volumes[requestData.reference]) {
            this.volumes[requestData.reference] = {};
          }
          // We need to reverse it to maintain order.
          volumes = volumes.reverse();
          this.volumes[requestData.reference][requestData.deliveryPoint] = volumes;
        }
        // Make sure the dates are ordered from low to high.
        volumes.sort((a, b) => (new Date(a.previousMeteringDate) < new Date(b.previousMeteringDate) ? -1 : 1));
        this.volumes$.next(volumes);
      }),
      tap((volumes: Volume[]) => {
        const totalVolume = {};
        let totalConsumption = 0;
        volumes.forEach((volume: Volume) => {
          volume.volumes.forEach((meterVolume: MeterVolume) => {
            // Calculate the total value
            totalVolume[`${meterVolume.register}_${meterVolume.direction}`] =
              totalVolume[`${meterVolume.register}_${meterVolume.direction}`] || 0;

            totalVolume[`${meterVolume.register}_${meterVolume.direction}`] += +meterVolume.value;
          });

          const consumptions = volume.volumes.filter((x) => x.direction === Direction.consumption);
          const total = consumptions.reduce((a, b) => a + b.value, 0);
          totalConsumption += total;
        });
        this.totalVolume$.next(Object.keys(totalVolume).length > 0 ? totalVolume : null);
        this.averageConsumption$.next(totalConsumption / volumes.length);
      }),
      catchError((_) => {
        if (requestData.provider === Provider.AR) {
          this.error$.next(SmartMeterErrorsTypes.serverError);
        } else if (requestData.provider === Provider.FLUVIUS) {
          this.error$.next(SmartMeterErrorsTypes.serverErrorFluvius);
        }
        this.volumes$.next(null);
        this.totalVolume$.next(null);
        this.averageConsumption$.next(null);
        return of(null);
      })
    );
  }

  private getVolumesFromApi(requestData: VolumeRequestData): Observable<Volume[]> {
    let params = new HttpParams();
    params = requestData.provider ? params.append('provider', requestData.provider) : params;
    params = requestData.granularity ? params.append('granularity', requestData.granularity.toUpperCase()) : params;
    params = requestData.fromDate
      ? params.append('from', moment(requestData.fromDate).utc(true).format('YYYY-MM-DDTHH:mm:ss.SSS'))
      : params;
    params = requestData.toDate
      ? params.append('to', moment(requestData.toDate).utc(true).format('YYYY-MM-DDTHH:mm:ss.SSS'))
      : params;
    params = requestData.type ? params.append('type', requestData.type) : params;
    params = requestData.resolution ? params.append('resolution', requestData.resolution.toUpperCase()) : params;
    params = requestData.estimate ? params.append('estimate', requestData.estimate) : params;
    params = params.append('loader', false);

    return this.http.get<Volume[]>(
      `/v1/customers/${requestData.reference}/sites/${requestData.siteId}/contracts/${requestData.energyType}/${requestData.deliveryPoint}/volumes`,
      {
        params,
      }
    );
  }

  private getSelfConsumptionFromApi(requestData: SelfConsumptionRequestData): Observable<SelfConsumption> {
    let params = new HttpParams();
    params = requestData.fromDate
      ? params.append('from', moment(requestData.fromDate).utc(true).format('YYYY-MM-DDTHH:mm:ss.SSS'))
      : params;
    params = requestData.toDate
      ? params.append('to', moment(requestData.toDate).utc(true).format('YYYY-MM-DDTHH:mm:ss.SSS'))
      : params;
    params = params.append('loader', false);

    return this.http.get<SelfConsumption>(
      `/v1/customers/${requestData.reference}/sites/${requestData.siteId}/contracts/${requestData.energyType}/delivery-points/${requestData.deliveryPoint}/self-consumption`,
      {
        params,
      }
    );
  }

  private getVolumesDetailsFromApi(requestData: ConsumptionRequestData): Observable<Volume[]> {
    return this.http
      .get<Volume[]>(
        `/v1/customers/${requestData.reference}/sites/${requestData.siteId}/contracts/${requestData.energyType}/${requestData.deliveryPoint}/volumes-details`
      )
      .pipe(catchError((e) => throwError(e)));
  }
}
