<div
 class="relative rounded-2xl w-full h-full z-10 flex items-center border-grey p-8"
 [class.border]="border"
 style="min-height: 150px"
>
  <div class="bg-white p-8 text-blue-dark rounded-2xl mx-auto flex items-center border border-grey max-w-md">
    <img src="./assets/img/icons/standalone/blue/home.svg" class="inline-block mr-4">
    <div>
      <ng-content></ng-content>
    </div>
  </div>
</div>
