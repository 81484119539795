import { Component, Input, OnInit } from '@angular/core';
import {
  DataType,
  HomeProfileQuestion,
} from '@app/modules/customer-zone/consumption/services/home-profile/home-profile.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-home-profile-question-type',
  templateUrl: './home-profile-question-type.component.html',
  styleUrls: ['./home-profile-question-type.component.scss'],
})
export class HomeProfileQuestionTypeComponent implements OnInit {
  @Input() question: HomeProfileQuestion;
  @Input() form: FormGroup;
  @Input() category?: string;
  readonly dataType = DataType;

  ngOnInit(): void {}
}
