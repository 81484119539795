import { PaymentBalanceViewType } from '../../../invoices/models/invoice.interface';

export const InvoiceViewTypes = {
  [PaymentBalanceViewType.NOTHING_TO_PAY]: {
    title: 'pages.dashboard.paymentBalance.toPay.payments',
    subtitle: '',
    cardClass: '',
    amount: {
      title: 'pages.dashboard.paymentBalance.toPay.totalToPay',
      value: 0,
    },
  },
  [PaymentBalanceViewType.PAY_ONE_INVOICE]: {
    title: 'pages.dashboard.paymentBalance.toPay.payments',
    subtitle: '',
    cardClass: '',
    amount: null,
  },
  [PaymentBalanceViewType.PAY_MORE_INVOICES]: {
    title: 'pages.dashboard.paymentBalance.toPay.payments',
    subtitle: 'pages.dashboard.paymentBalance.toPay.totalInvoicesToPay',
    cardClass: 'te-border-gradient-home',
    amount: {
      title: 'pages.dashboard.paymentBalance.toPay.totalToPay',
      value: 0,
    },
  },
  [PaymentBalanceViewType.ONLY_PAYMENT_PLAN]: {
    title: 'pages.dashboard.paymentBalance.toPay.payments',
    subtitle: 'pages.dashboard.paymentBalance.toPay.totalInvoicesToPay',
    cardClass: 'te-border-gradient-home',
    amount: {
      title: 'pages.dashboard.paymentBalance.toPay.totalToPay',
      value: 0,
    },
  },
  [PaymentBalanceViewType.NOTHING_TO_REFUND]: {
    title: '',
    subtitle: '',
    cardClass: '',
    amount: null,
  },
  [PaymentBalanceViewType.REFUND_ONE_INVOICE]: {
    title: 'pages.dashboard.paymentBalance.toRefund.refund',
    subtitle: '',
    cardClass: '',
    amount: null,
  },
  [PaymentBalanceViewType.REFUND_MORE_INVOICES]: {
    title: 'pages.dashboard.paymentBalance.toRefund.refund',
    subtitle: 'pages.dashboard.paymentBalance.toRefund.totalAmountOfInvoiceToRefund',
    cardClass: 'te-border-gradient-home',
    amount: {
      title: 'pages.dashboard.paymentBalance.toRefund.totalToRefund',
      value: 0,
    },
  },
};
