import { Component, DoCheck, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ContactDetails } from '../../../contact/models/contactDetails.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactService } from '../../../contact/services/contact/contact.service';
import { take } from 'rxjs';
import { UserInfo } from '../../../user/models/userInfo.interface';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-update-contact-email',
  templateUrl: './update-contact-email.component.html',
  styleUrls: ['./update-contact-email.component.scss'],
})
export class UpdateContactEmailComponent implements DoCheck {
  @Input() contact: ContactDetails;
  @Input() userInfo: UserInfo;
  @Input() reference: string;
  @ViewChild('content') modalContent: ElementRef;
  public isSetup = false;

  constructor(
    private modalService: NgbModal,
    private contactService: ContactService,
    private translateService: TranslateService,
    private toastrService: ToastrService
  ) {}

  ngDoCheck() {
    if (this.contact && this.userInfo && !this.isSetup) {
      if (this.contact.email === this.userInfo.email) {
        this.isSetup = true;
        this.toastrService.success(this.translateService.instant('pages.bindingProcess.step3.refAddedTitle'));
        window.localStorage.removeItem('binding');
      } else if (this.modalContent) {
        this.isSetup = true;
        this.modalService.open(this.modalContent, { size: 'medium' }).result.then(
          () => {},
          (reason) => {
            const alert =
              reason === 'UPDATE-EMAIL'
                ? 'components.emailSync.alert.success'
                : 'pages.bindingProcess.step3.refAddedTitle';
            this.toastrService.success(this.translateService.instant(alert));
            window.localStorage.removeItem('binding');
          }
        );
      }
    }
  }

  public updateEmail() {
    this.modalService.dismissAll('UPDATE-EMAIL');
    const payload: ContactDetails = { ...this.contact, email: this.userInfo.email };
    this.contactService.updateContactDetails(this.reference, payload).pipe(take(1)).subscribe();
  }
}

_('components.emailSync.alert.success');
