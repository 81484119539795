<div class="px-8 my-32 xl:max-w-screen-xl xl:w-full xl:mx-auto">
  <div class="mb-16">
    <div class="flex flex-col md:grid md:grid-cols-12 gap-16">
      <div class="md:col-span-6 md:mr-16 flex align-middle">
        <div class="flex flex-col self-center">
          <h2 class="mb-8 font-display font-normal text-5xl te-text-gradient-home inline-block">
            {{ 'components.otherContracts.title' | translate }}
          </h2>
          <p>
            {{ 'components.otherContracts.description' | translate }}
          </p>
          <div>
            <a [href]="'components.otherContracts.link' | translate" target="_blank" class="btn btn-primary inline-block font-bold mb-4 mt-10 md:mb-0">
              {{ 'components.otherContracts.label' | translate }}
            </a>
          </div>
        </div>
      </div>
      <div class="-order-1 b2b-portal">
        <img src="assets/img/b2b-portal-2.jpg" class="md:hidden" />
        <img src="assets/img/b2b-portal.jpg" class="hidden md:block"/>
      </div>
    </div>
  </div>
</div>
