import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FiltersComponent } from './components/filters/filters.component';
import { FaqModule } from '../../../shared/modules/faq/faq.module';
import { SharedModule } from '../../../shared/shared.module';
import { AnalyticsModule } from '../../../shared/modules/analytics/analytics.module';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@NgModule({
  declarations: [FiltersComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    RouterModule,
    FaqModule,
    SharedModule,
    AnalyticsModule,
    NgOptimizedImage,
  ],
  exports: [FiltersComponent],
})
export class InvoicesModule {}

_('pages.invoices.pageTitle');
_('general.billing.BANK_TRANSFER');
_('general.billing.BANK_DOMICILIATION');
_('general.billing.DIRECT_DEBIT');
_('general.billing.DIRECT_DEBIT_AND_BANK_TRANSFER');
_('components.invoices.invoiceTypes.AVANCE');
_('components.invoices.invoiceTypes.REGULE');
_('components.invoices.invoiceTypes.PAPUR');
_('components.invoices.invoiceTypes.ANNULATION_REGULE');
_('components.invoices.invoiceTypes.ANNULATION_AVANCE');
_('components.invoices.invoiceTypes.EXTRA');
_('components.invoices.due');
_('components.invoices.overdue');
_('components.invoices.payNxtError');
_('components.invoices.payNxtInvoiceNotYetAvailable');
_('components.invoices.payNxtInvoiceOpenForTooLong');
_('components.invoices.payNxtPaymentImpossible');
_('components.invoices.yearlyInvoice');
_('components.invoices.messages.financial.title');
_('components.invoices.messages.financial.content');
_('components.invoices.endpointError.title');
_('components.invoices.endpointError.content');

_('components.paymentPlan.overview.paymentPlanDetails.relatedInvoices.content.single');
_('components.paymentPlan.overview.paymentPlanDetails.relatedInvoices.content.multi');

_('components.paymentPlan.overview.upcomingPayment.payViaDirectDebit');
_('components.paymentPlan.overview.upcomingPayment.payViaBankTransfer');
_('components.paymentPlan.progress.description.start');
_('components.paymentPlan.progress.description.ongoing');
_('components.paymentPlan.progress.description.end');

_('general.invoice.status.direct_debit_rejected.issueNotifier.label');
_('general.invoice.status.cancelled.issueNotifier.credit.label');
_('general.invoice.status.cancelled.issueNotifier.debit.label');
_('general.invoice.status.refund_on_hold.issueNotifier.label');
_('general.invoice.status.no_refund_account.issueNotifier.label');
_('general.invoice.status.no_refund_account.issueNotifier.cta.label');
_('general.invoice.status.no_refund_account.issueNotifier.cta.link');
