<input type="checkbox" [attr.id]="options.id" (change)="toggleValue($event)" [checked]="checked" class="peer" />
<label [attr.for]="options.id">
  <div class="toggle-button-switch"></div>
</label>
<div
  class="toggle-button-content"
  [ngClass]="
    styleClass ? styleClass : 'bg-grey-light border-grey peer-checked:bg-primary peer-checked:border-primary-dark'
  "
>
  <div class="toggle-button-content-on">
    <div *ngIf="options.on?.text">{{ options.on.text }}</div>
    <div *ngIf="options.on?.svgUrl"><img [src]="options.on.svgUrl" class="inline-block align-middle" /></div>
  </div>
  <div class="toggle-button-content-off">
    <div *ngIf="options.off?.text">{{ options.off.text }}</div>
    <div *ngIf="options.off?.svgUrl"><img [src]="options.off.svgUrl" class="inline-block align-middle" /></div>
  </div>
</div>
