<section>
  <h3 class="text-2xl mb-4">{{ 'components.paymentPlan.overview.paymentPlanDetails.title' | translate }}</h3>
  <app-related-invoices
    *ngIf="paymentPlan?.relatedInvoiceIds?.length"
    [relatedInvoices]="paymentPlan?.relatedInvoiceIds"
  >
  </app-related-invoices>
  <app-payment-plan-info
    [paymentPlan]="paymentPlan"
  >
  </app-payment-plan-info>
</section>
