<div class="flex items-end justify-between space-x-4 border-b-2 border-slate-300 animate-pulse">
  <div class="w-10 h-60 bg-slate-200 rounded-t-xl"></div>
  <div class="w-10 h-40 bg-slate-200 rounded-t-xl"></div>
  <div class="w-10 h-32 bg-slate-200 rounded-t-xl"></div>
  <div class="w-10 h-44 bg-slate-200 rounded-t-xl"></div>
  <div class="w-10 h-24 bg-slate-200 rounded-t-xl"></div>
  <div class="w-10 h-60 bg-slate-200 rounded-t-xl"></div>
  <div class="w-10 h-40 bg-slate-200 rounded-t-xl"></div>
  <div class="w-10 h-32 bg-slate-200 rounded-t-xl"></div>
  <div class="w-10 h-44 bg-slate-200 rounded-t-xl"></div>
  <div class="w-10 h-24 bg-slate-200 rounded-t-xl"></div>
  <div class="w-10 h-60 bg-slate-200 rounded-t-xl"></div>
  <div class="w-10 h-40 bg-slate-200 rounded-t-xl"></div>
  <div class="w-10 h-32 bg-slate-200 rounded-t-xl"></div>
  <div class="w-10 h-44 bg-slate-200 rounded-t-xl"></div>
  <div class="w-10 h-60 bg-slate-200 rounded-t-xl"></div>
  <div class="w-10 h-40 bg-slate-200 rounded-t-xl"></div>
  <div class="w-10 h-32 bg-slate-200 rounded-t-xl"></div>
  <div class="w-10 h-44 bg-slate-200 rounded-t-xl"></div>
  <div class="w-10 h-24 bg-slate-200 rounded-t-xl"></div>
  <div class="w-10 h-60 bg-slate-200 rounded-t-xl"></div>
  <div class="w-10 h-40 bg-slate-200 rounded-t-xl"></div>
  <div class="w-10 h-32 bg-slate-200 rounded-t-xl"></div>
  <div class="w-10 h-44 bg-slate-200 rounded-t-xl"></div>
  <div class="w-10 h-24 bg-slate-200 rounded-t-xl"></div>
  <div class="w-10 h-60 bg-slate-200 rounded-t-xl"></div>
  <div class="w-10 h-40 bg-slate-200 rounded-t-xl"></div>
  <div class="w-10 h-32 bg-slate-200 rounded-t-xl"></div>
  <div class="w-10 h-44 bg-slate-200 rounded-t-xl"></div>
</div>
<div class="flex items-start justify-between space-x-4 animate-pulse">
  <div class="w-10 h-32 bg-slate-300 rounded-b-xl"></div>
  <div class="w-10 h-10 bg-slate-300 rounded-b-xl"></div>
  <div class="w-10 h-24 bg-slate-300 rounded-b-xl"></div>
  <div class="w-10 h-12 bg-slate-300 rounded-b-xl"></div>
  <div class="w-10 h-24 bg-slate-300 rounded-b-xl"></div>
  <div class="w-10 h-10 bg-slate-300 rounded-b-xl"></div>
  <div class="w-10 h-12 bg-slate-300 rounded-b-xl"></div>
  <div class="w-10 h-16 bg-slate-300 rounded-b-xl"></div>
  <div class="w-10 h-10 bg-slate-300 rounded-b-xl"></div>
  <div class="w-10 h-14 bg-slate-300 rounded-b-xl"></div>
  <div class="w-10 h-24 bg-slate-300 rounded-b-xl"></div>
  <div class="w-10 h-10 bg-slate-300 rounded-b-xl"></div>
  <div class="w-10 h-32 bg-slate-300 rounded-b-xl"></div>
  <div class="w-10 h-20 bg-slate-300 rounded-b-xl"></div>
  <div class="w-10 h-32 bg-slate-300 rounded-b-xl"></div>
  <div class="w-10 h-10 bg-slate-300 rounded-b-xl"></div>
  <div class="w-10 h-24 bg-slate-300 rounded-b-xl"></div>
  <div class="w-10 h-12 bg-slate-300 rounded-b-xl"></div>
  <div class="w-10 h-24 bg-slate-300 rounded-b-xl"></div>
  <div class="w-10 h-10 bg-slate-300 rounded-b-xl"></div>
  <div class="w-10 h-12 bg-slate-300 rounded-b-xl"></div>
  <div class="w-10 h-16 bg-slate-300 rounded-b-xl"></div>
  <div class="w-10 h-10 bg-slate-300 rounded-b-xl"></div>
  <div class="w-10 h-14 bg-slate-300 rounded-b-xl"></div>
  <div class="w-10 h-24 bg-slate-300 rounded-b-xl"></div>
  <div class="w-10 h-10 bg-slate-300 rounded-b-xl"></div>
  <div class="w-10 h-32 bg-slate-300 rounded-b-xl"></div>
  <div class="w-10 h-20 bg-slate-300 rounded-b-xl"></div>
</div>
<div class="flex justify-start mt-16 animate-pulse">
  <div class="w-40 h-10 bg-slate-300 rounded-xl mr-10"></div>
  <div class="w-48 h-10 bg-slate-300 rounded-xl mr-10"></div>
  <div class="w-60 h-10 bg-slate-300 rounded-xl mr-10"></div>
</div>
