import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { finalize, map, Observable, of, share, tap } from 'rxjs';
import { EliqAccessRights } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';

interface FeatureBannerAccessRight {
  displayNewFeaturesBanner: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class EliqAccessService {
  eliqAccessRights = {};
  private newFeatureBannerAccessRights = {};
  private cachedObservable: Observable<EliqAccessRights> = null;

  constructor(private http: HttpClient) {}

  public getEliqAccessRights(reference: string, siteId: string, loader: boolean = true): Observable<EliqAccessRights> {
    let observable: Observable<EliqAccessRights>;
    if (this.eliqAccessRights[reference] && this.eliqAccessRights[reference][siteId]) {
      observable = of(this.eliqAccessRights[reference][siteId]);
    } else if (this.cachedObservable) {
      observable = this.cachedObservable;
    } else {
      this.cachedObservable = this.getEliqAccessRightsFromApi(reference, siteId, loader).pipe(
        share(),
        finalize(() => (this.cachedObservable = null))
      );
      observable = this.cachedObservable;
    }
    return observable;
  }

  public getEliqFeatureBannerAccessRight(
    reference: string,
    siteId: string,
    loader: boolean = true
  ): Observable<boolean> {
    let observable: Observable<boolean>;
    if (
      this.newFeatureBannerAccessRights[reference] &&
      this.newFeatureBannerAccessRights[reference][siteId] !== undefined
    ) {
      observable = of(this.newFeatureBannerAccessRights[reference][siteId]);
    } else {
      observable = this.getEliqFeatureBannerAccessRightFromApi(reference, siteId).pipe(share());
    }
    return observable;
  }

  private getEliqAccessRightsFromApi(reference: string, siteId: string, loader: boolean): Observable<EliqAccessRights> {
    const params = new HttpParams().set('loader', loader.toString());
    return this.http.get<any>(`/v1/customers/${reference}/sites/${siteId}/widgets/access`).pipe(
      tap((eliqAccessRights) => {
        if (!this.eliqAccessRights[reference]) {
          this.eliqAccessRights[reference] = {};
        }
        this.eliqAccessRights[reference][siteId] = eliqAccessRights;
      })
    );
  }

  private getEliqFeatureBannerAccessRightFromApi(reference: string, siteId: string): Observable<boolean> {
    return this.http.get<any>(`/v1/customers/${reference}/sites/${siteId}/widgets/access/feature-banner`).pipe(
      tap((featureBannerAccess: FeatureBannerAccessRight) => {
        if (!this.newFeatureBannerAccessRights[reference]) {
          this.newFeatureBannerAccessRights[reference] = {};
        }
        this.newFeatureBannerAccessRights[reference][siteId] = featureBannerAccess.displayNewFeaturesBanner;
      }),
      map((featureBannerAccess: FeatureBannerAccessRight) => {
        return featureBannerAccess.displayNewFeaturesBanner;
      })
    );
  }
}
