<ng-container *ngIf="balanceInfo">
  <div class="balance card border-none box-shadow-small mb-4" [ngClass]="[balanceInfo?.cardClass]">
    <div class="flex justify-between flex-wrap -mt-8 -ml-4">
      <div class="flex flex-col justify-center flex-1 pt-4 pl-4">
        <div class="flex flex-row items-center">
          <h3 class="font-display font-normal text-5xl te-text-gradient-home inline-block flex-1">
            {{ balanceInfo.title | translate }}
          </h3>
          <ng-content select="[action]"></ng-content>
        </div>
        <span class="text-sm text-grey-dark60 inline-block">
          {{ balanceInfo.subtitle | translate }}
        </span>
      </div>
      <div *ngIf="balanceInfo.amount !== null" class="flex flex-col pt-8 pl-4">
        <span class="text-base font-medium text-grey-dark">{{ balanceInfo.amount.title | translate }} </span>
        <span class="font-display font-normal text-5xl te-text-gradient-home">
          <span *ngIf="balanceInfo.amount.value > 0; else nothingToPay">
            {{ balanceInfo.amount.value | currency : 'EUR' }}
          </span>
          <ng-template #nothingToPay>{{ '-' }}</ng-template>
        </span>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
</ng-container>
