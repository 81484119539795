import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { ChartType, TooltipItem, TooltipModel } from 'chart.js';
import { DecimalPipe, formatDate, TitleCasePipe } from '@angular/common';
import { BarChartData, CustomTooltipOptions, TooltipObject } from '../models/chart.interfaces';
import { ChartColor, ChartStyle, ChartUnit } from '../models/chart.enums';
import Chart from 'chart.js/auto';
import { MainFacade } from '@app/core/facade/main.facade';
import { EnergyType } from '@app/modules/customer-zone/consumption/models/consumption.interface';

enum CssSelector {
  chartTooltip = 'chartjs-tooltip',
  reverse = 'reverse',
  noTail = 'no-tail',
}

@Injectable({
  providedIn: 'root',
})
export class ChartTooltipHelperService {
  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private readonly facade: MainFacade,
    private decimalPipe: DecimalPipe,
    private titleCasePipe: TitleCasePipe
  ) {}

  public createTooltip(tooltipObject: TooltipObject<any>, tooltipTemplate: string, chartType: ChartType = null) {
    const chart = tooltipObject.chart;
    const tooltipModel = tooltipObject.tooltip;
    const tooltipElement = this.getOrCreateTooltipContainer(chart);

    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
      tooltipElement.style.opacity = '0';
      return;
    }

    tooltipElement.classList.remove(CssSelector.reverse);
    tooltipElement.classList.remove(CssSelector.noTail);

    tooltipElement.innerHTML = tooltipTemplate;

    const { offsetLeft: positionX, offsetTop: positionY } = tooltipModel.chart.canvas;

    const chartCanvasPosition = tooltipModel.chart.canvas.getBoundingClientRect();
    const chartHeight = chartCanvasPosition.height;
    const barHeight = chartHeight - tooltipModel.caretY;
    const tooltipHeight = tooltipElement.offsetHeight;
    const tooltipWidth = tooltipElement.getBoundingClientRect().width;
    const defaultOffset = 15;

    let tooltipOffsetY =
      barHeight < tooltipHeight + defaultOffset ? barHeight / 2 - defaultOffset - tooltipHeight / 2 : defaultOffset;
    if (chartType === ChartStyle.line) {
      tooltipOffsetY = -(tooltipHeight / 2);
    }

    let leftDifferencePosition = 0;
    const tooltipItem = tooltipModel.dataPoints[0];

    // check if is the index in the first half
    const isFirstHalfIndex = tooltipItem.dataIndex < tooltipItem.dataset.data.length / 2;

    // shift the tooltip to the left side when the current bar is in the second half
    if (
      (chartType === ChartStyle.line && isFirstHalfIndex && tooltipItem.dataset.data.length > 1) ||
      (chartType !== ChartStyle.line && !isFirstHalfIndex)
    ) {
      leftDifferencePosition = tooltipWidth;
      tooltipElement.classList.add(CssSelector.reverse);
    }

    const extraMargin =
      chartType === ChartStyle.line || chartType === ChartStyle.doughnut
        ? leftDifferencePosition !== 0
          ? -defaultOffset
          : defaultOffset
        : 0;

    // check if the tooltip overflow the chart on the left or right side and move it to the center
    if (chartType === ChartStyle.bar) {
      if (isFirstHalfIndex) {
        if (tooltipModel.caretX + tooltipWidth > chartCanvasPosition.right) {
          tooltipElement.classList.add(CssSelector.noTail);
          leftDifferencePosition += tooltipModel.caretX + tooltipWidth - chartCanvasPosition.right + defaultOffset;
        }
      } else {
        if (tooltipModel.caretX - tooltipWidth < 0) {
          tooltipElement.classList.add(CssSelector.noTail);
          leftDifferencePosition = tooltipModel.caretX + defaultOffset;
        }
      }
    }

    tooltipElement.style.opacity = '1';
    tooltipElement.style.left = positionX + tooltipModel.caretX - leftDifferencePosition + extraMargin + 'px';
    tooltipElement.style.top =
      positionY +
      tooltipModel.caretY +
      (chartType === ChartStyle.doughnut ? -tooltipHeight / 2 : tooltipOffsetY) +
      'px';
    tooltipElement.style.padding = tooltipModel.options.padding + 'px ' + tooltipModel.options.padding + 'px';
    tooltipElement.style.transitionDelay = '0.25s';
  }

  public getMonthlyConsumptionBarChartTemplate(
    tooltipModel: TooltipModel<'bar'>,
    tooltipOptions: CustomTooltipOptions
  ): string {
    const dataIndex = tooltipModel.dataPoints[0].dataIndex;

    const rootDiv = document.createElement('div');

    const titleDiv = document.createElement('div');
    titleDiv.style.fontSize = '1.6rem';

    const titleText = document.createTextNode(
      this.titleCasePipe.transform(
        formatDate(
          new Date(new Date().getFullYear(), dataIndex, 1),
          'MMMM yyyy',
          this.facade.translate.currentLang + '-BE'
        )
      )
    );
    titleDiv.appendChild(titleText);
    rootDiv.appendChild(titleDiv);

    const estimationDiv = document.createElement('div');
    if (tooltipOptions?.showEstimationWarning) {
      estimationDiv.style.backgroundColor = ChartColor.orange10;
      estimationDiv.style.color = ChartColor.orange;
      estimationDiv.style.borderRadius = '0.5rem';
      estimationDiv.style.paddingLeft = '3.5rem';
      estimationDiv.style.paddingTop = '0.7rem';
      estimationDiv.style.paddingBottom = '0.7rem';
      estimationDiv.style.marginTop = ' 0.9rem';
      estimationDiv.style.backgroundImage = 'url(assets/img/icons/standalone/orange/estimation-exclamation-mark.svg)';
      estimationDiv.style.backgroundRepeat = 'no-repeat';
      estimationDiv.style.backgroundPosition = '1.1rem center';

      const estimationText = document.createTextNode(
        this.facade.translate.instant('components.widgets.monthlyConsumption.chart.tooltip.estimation')
      );
      estimationDiv.appendChild(estimationText);
    } else {
      estimationDiv.style.padding = '0.5rem';
    }
    rootDiv.appendChild(estimationDiv);

    tooltipModel.dataPoints.forEach((dataPoint: TooltipItem<'bar'>) => {
      const barChartData = dataPoint.raw as BarChartData;

      if (barChartData.value) {
        const consumptionsDiv = document.createElement('div');
        consumptionsDiv.style.display = 'flex';
        consumptionsDiv.style.justifyContent = 'space-between';
        consumptionsDiv.style.gap = '1rem';
        consumptionsDiv.style.alignItems = 'center';
        consumptionsDiv.style.paddingTop = '1rem';

        const leftEstimationSpan = document.createElement('span');
        const rightEstimationSpan = document.createElement('span');
        rightEstimationSpan.style.textAlign = 'right';
        rightEstimationSpan.style.fontSize = '1.6rem';

        leftEstimationSpan.appendChild(document.createTextNode(`${dataPoint.dataset.label}:`));
        rightEstimationSpan.appendChild(document.createTextNode(`${Math.abs(barChartData.value)} ${ChartUnit.kwh}`));
        consumptionsDiv.appendChild(leftEstimationSpan);
        consumptionsDiv.appendChild(rightEstimationSpan);

        rootDiv.appendChild(consumptionsDiv);
      }
    });

    return rootDiv.outerHTML;
  }

  public getMeterReadingLineChartTemplate(tooltipModel: TooltipModel<'line'>, energyType: EnergyType): string {
    const rootDiv = document.createElement('div');

    const titleDiv = document.createElement('div');
    titleDiv.style.fontSize = '1.6rem';

    rootDiv.appendChild(titleDiv);

    if (tooltipModel?.dataPoints?.length) {
      const data: TooltipItem<'line'> = tooltipModel?.dataPoints[0];
      if (data) {
        const titleText = document.createTextNode(
          this.titleCasePipe.transform(
            formatDate(new Date(data.raw['x']), 'dd MMMM yyyy', this.facade.translate.currentLang + '-BE')
          )
        );
        titleDiv.appendChild(titleText);

        const consumptionsDiv = document.createElement('div');
        consumptionsDiv.style.display = 'flex';
        consumptionsDiv.style.justifyContent = 'space-between';
        consumptionsDiv.style.gap = '1rem';
        consumptionsDiv.style.alignItems = 'center';
        consumptionsDiv.style.paddingTop = '1rem';

        const leftEstimationSpan = document.createElement('span');
        const rightEstimationSpan = document.createElement('span');
        rightEstimationSpan.style.textAlign = 'right';
        rightEstimationSpan.style.fontSize = '1.6rem';

        const unit: ChartUnit = energyType === EnergyType.ELECTRICITY ? ChartUnit.kwh : ChartUnit.m3;
        leftEstimationSpan.appendChild(document.createTextNode(`${data.dataset.label}:`));
        rightEstimationSpan.appendChild(
          document.createTextNode(`${this.decimalPipe.transform(data.raw['y'], '1.1-2')} ${unit}`)
        );
        consumptionsDiv.appendChild(leftEstimationSpan);
        consumptionsDiv.appendChild(rightEstimationSpan);

        rootDiv.appendChild(consumptionsDiv);
      }
    }

    return rootDiv.outerHTML;
  }

  public getDoughnutChartTemplate(tooltipModel: TooltipModel<'doughnut'>): string {
    const rootDiv = document.createElement('div');

    const titleDiv = document.createElement('div');
    titleDiv.style.fontSize = '1.6rem';

    rootDiv.appendChild(titleDiv);

    if (tooltipModel?.dataPoints?.length) {
      const data: TooltipItem<'doughnut'> = tooltipModel?.dataPoints[0];
      if (data) {
        const titleText = document.createTextNode(this.titleCasePipe.transform(data.label));
        titleDiv.appendChild(titleText);

        const consumptionsDiv = document.createElement('div');
        consumptionsDiv.style.display = 'flex';
        consumptionsDiv.style.justifyContent = 'space-between';
        consumptionsDiv.style.gap = '1rem';
        consumptionsDiv.style.alignItems = 'center';
        consumptionsDiv.style.paddingTop = '1rem';

        const leftEstimationSpan = document.createElement('span');
        leftEstimationSpan.appendChild(
          document.createTextNode(`${this.decimalPipe.transform(data.formattedValue, '1.0-0')} ${ChartUnit.percentage}`)
        );
        consumptionsDiv.appendChild(leftEstimationSpan);
        rootDiv.appendChild(consumptionsDiv);
      }
    }

    return rootDiv.outerHTML;
  }

  private getOrCreateTooltipContainer(chart: Chart): HTMLElement {
    let tooltipElement = document.getElementById(CssSelector.chartTooltip);

    if (tooltipElement) {
      tooltipElement.remove();
    }

    // Create element on first render
    tooltipElement = document.createElement('div');
    tooltipElement.id = CssSelector.chartTooltip;
    tooltipElement.innerHTML = '<div></div>';

    tooltipElement.style.background = ChartColor.lightGray;
    tooltipElement.style.fontSize = '1.4rem';
    tooltipElement.style.color = ChartColor.darkGray;
    tooltipElement.style.opacity = '1';
    tooltipElement.style.fontFamily = 'Roboto';
    tooltipElement.style.pointerEvents = 'none';
    tooltipElement.style.fontWeight = '500';
    tooltipElement.style.position = 'absolute';
    tooltipElement.style.transition = 'all .1s ease';
    tooltipElement.style.minWidth = '24rem';
    tooltipElement.style.zIndex = '30';

    document.body.appendChild(tooltipElement);
    chart.canvas.parentNode?.appendChild(tooltipElement);

    return tooltipElement;
  }
}
