import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from '@app/shared/components/alert/alert.component';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { AnalyticsModule } from '@app/shared/modules/analytics/analytics.module';
import { InvoiceActionsComponent } from '@app/modules/customer-zone/invoices/components/micro/invoice-actions/invoice-actions.component';
import { PaymentBalance } from '@app/modules/customer-zone/invoices/models/balance.interface';

@Component({
  selector: 'app-payment-balance',
  standalone: true,
  imports: [CommonModule, TranslateModule, NgbModule, AlertComponent, AnalyticsModule, InvoiceActionsComponent],
  templateUrl: './payment-balance.component.html',
  styleUrls: ['./payment-balance.component.scss'],
})
export class PaymentBalanceComponent {
  @Input() public balanceInfo: PaymentBalance;
}

_('pages.dashboard.paymentBalance.toPay.allBillsPaid');
_('pages.dashboard.paymentBalance.paymentInformation.dueDateVir');
_('pages.dashboard.paymentBalance.paymentInformation.dueDateDomandDomv');
