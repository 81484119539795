import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-disaggregation-podium-platform',
  templateUrl: './disaggregation-podium-platform.component.html',
  styleUrls: ['./disaggregation-podium-platform.component.scss'],
})
export class DisaggregationPodiumPlatformComponent {
  @Input() order: number;
  @Input() percentage: number;
  @Input() category: string;
  @Input() styleClass: string;
}
