import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-email-sent',
  templateUrl: './confirmation-email-sent.component.html',
  styleUrls: ['./confirmation-email-sent.component.scss'],
})
export class ConfirmationEmailSentComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
}
