import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MainFacade } from '@app/core/facade/main.facade';
import moment from 'moment';
import { Contract } from '@app/modules/customer-zone/contracts/models/contract.interface';
import { EnergyType, Usage, UsageBreakdown } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { delay, Observable, shareReplay, take, tap } from 'rxjs';

@Component({
  selector: 'app-usage-categories-popup',
  templateUrl: './usage-categories-popup.component.html',
  styleUrls: ['./usage-categories-popup.component.scss'],
})
export class UsageCategoriesPopupComponent implements OnChanges {
  @ViewChildren('close') closePopup: QueryList<ElementRef>;
  @ViewChild('list') listView: ElementRef;
  @Input() currentUsage: Usage;
  @Input() contracts: Contract[];
  @Input() activeContract: Contract;
  @Output() closeEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  previousDataLoading: boolean;
  previousDataAvailable$: Observable<Usage>;
  nextDataAvailable: boolean;
  loadNewData = false;
  math = Math;
  protected readonly energyType = EnergyType;
  private readonly minHeight = 150;

  constructor(public facade: MainFacade, public renderer: Renderer2) {}

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.closePopupAndResetState();
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    const closeElements: ElementRef[] = this.closePopup?.toArray();
    if (this.currentUsage && closeElements?.some(({ nativeElement }) => nativeElement.contains(event.target))) {
      this.closePopupAndResetState();
    }
  }

  ngOnChanges(): void {
    this.updateState();
    this.previousDataAvailable$ = this.isPreviousDataAvailable(this.currentUsage.from, this.currentUsage.to);
  }

  isPreviousDataAvailable(currentViewFrom, currentViewTo) {
    this.previousDataLoading = true;
    const fromDate = moment(currentViewFrom).subtract('1', 'months');
    const toDate = moment(currentViewTo).subtract('1', 'months');
    return this.facade
      .loadEnergyUsage(
        {
          reference: this.facade.state$.value.reference,
          siteId: this.facade.state$.value.activeSite?.id,
          energyType: this.activeContract.type as EnergyType,
          deliveryPoint: this.activeContract.deliveryPointReference,
          fromDateString: fromDate.format('YYYY-MM-DD'),
          toDateString: toDate.format('YYYY-MM-DD'),
        },
        false
      )
      .pipe(
        take(1),
        shareReplay(1),
        tap(() => (this.previousDataLoading = false))
      );
  }

  getOtherMonthData(direction: number) {
    let fromDate = moment(this.currentUsage.from);
    let toDate = moment(this.currentUsage.from);
    fromDate = direction === -1 ? fromDate.subtract('1', 'months') : fromDate.add('1', 'months');
    toDate = direction === -1 ? toDate : toDate.add('2', 'months');

    if (direction === -1) {
      this.previousDataAvailable$ = this.isPreviousDataAvailable(fromDate, toDate);
    }

    const heightValue =
      this.listView.nativeElement.offsetHeight < this.minHeight
        ? this.minHeight
        : this.listView.nativeElement.offsetHeight;
    this.renderer.setStyle(this.listView.nativeElement, 'height', `${heightValue}px`);
    this.loadNewData = true;
    this.facade
      .loadEnergyUsage(
        {
          reference: this.facade.state$.value.reference,
          siteId: this.facade.state$.value.activeSite?.id,
          energyType: this.activeContract.type as EnergyType,
          deliveryPoint: this.activeContract.deliveryPointReference,
          fromDateString: fromDate.format('YYYY-MM-DD'),
          toDateString: toDate.format('YYYY-MM-DD'),
        },
        false
      )
      .pipe(take(1), delay(400))
      .subscribe((usage: Usage) => {
        this.renderer.removeAttribute(this.listView.nativeElement, 'style');
        this.loadNewData = false;
        this.currentUsage = usage;
        this.updateState();
      });
  }

  goToHomeProfile() {
    this.facade.utils.redirectTo('/consumptions/home-profile');
  }

  private updateState() {
    this.currentUsage.breakdown.sort((a: UsageBreakdown, b: UsageBreakdown) => b.value - a.value);
    this.nextDataAvailable =
      moment(this.currentUsage.from).diff(moment().subtract('1', 'months').startOf('months')) < 0;
  }

  private closePopupAndResetState() {
    this.contracts = this.activeContract = this.currentUsage = null;
    this.closeEmitter.emit(true);
    const appDisaggregation: Element | null = document.getElementsByTagName('app-disaggregation-chart-widget')[0];
    if (appDisaggregation) {
      (appDisaggregation as HTMLElement).removeAttribute('style');
    }
  }
}
