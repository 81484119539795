import { Component, Input } from '@angular/core';
import { Discount } from '../../models/discount.interface';

@Component({
  selector: 'mgm-content-info',
  templateUrl: './mgm-content-info.component.html',
  styleUrls: ['./mgm-content-info.component.scss'],
})
export class MgmContentInfoComponent {
  @Input() discount: Discount;
  @Input() yearlyMax: number;
  @Input() maxDiscount: number;
}
