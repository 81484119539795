import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route } from '@angular/router';
import { ClientAuthguard } from './core/guards/client.authguard';
import { BindingProcessComponent } from './modules/binding-process/pages/binding-process/binding-process.component';
import { CreateAccountComponent } from './modules/registration/pages/create-account/create-account.component';
import { PublicAuthguard } from './core/guards/public.authguard';
import { AllContractsComponent } from './modules/customer-zone/contracts/pages/all-contracts/all-contracts.component';
import { PaymentPreferencesComponent } from './modules/customer-zone/billing/pages/payment-preferences/payment-preferences.component';
import { ErrorComponent } from './shared/pages/error/error.component';
import { ReferencesComponent } from './modules/customer-zone/user/pages/references/references.component';
import { ContactDetailsComponent } from './modules/customer-zone/contact/pages/contact-details/contact-details.component';
import { ConsentComponent } from './modules/customer-zone/user/pages/consent/consent.component';
import { EstimatedIndexComponent } from './modules/customer-zone/consumption/pages/estimated-index/estimated-index.component';
// prettier-ignore
import {
  ActiveReferenceResolverService
} from './modules/customer-zone/user/services/active-reference-resolver/active-reference-resolver.service';
import { BoilerOneShotComponent } from './modules/customer-zone/upsell/pages/boiler-one-shot/boiler-one-shot.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { MgmComponent } from './modules/customer-zone/mgm/pages/mgm/mgm.component';
import { MeterReadingComponent } from './modules/customer-zone/consumption/pages/meter-reading/meter-reading.component';
import { TevcDashboardComponent } from './modules/customer-zone/tevc-monitoring/pages/dashboard/tevc-dashboard.component';
import { DashboardComponent } from './modules/customer-zone/dashboard/pages/dashboard/dashboard.component';
import { ReportsComponent } from './modules/customer-zone/tevc-monitoring/pages/reports/reports.component';
import { UnauthorizedComponent } from '@app/shared/pages/unauthorized/unauthorized.component';
// prettier-ignore
import {
  PowerPeakGraphComponent
} from './modules/customer-zone/consumption/pages/power-peak-graph/power-peak-graph.component';
import { OtherContractsComponent } from '@app/modules/customer-zone/contracts/pages/other-contracts/other-contracts.component';
import { SelfBillingComponent } from '@app/shared/pages/self-billing/self-billing.component';
import { AccessRight, EliqAccessRight } from './shared/resolvers/user-type-resolver/models/user-type.interface';
import { accessRights, userProfile } from '@app/core/guards/access.guard';
import { DataResolver } from '@app/core/resolvers/data.resolver';
import { Paths } from '@app/core/models/paths';
import { HomeProfileComponent } from '@app/modules/customer-zone/consumption/pages/home-profile/home-profile.component';
import { ConsumptionDashboardComponent } from '@app/modules/customer-zone/consumption/pages/dashboard/consumption-dashboard.component';
import { FluviusConsentsComponent } from '@app/modules/customer-zone/consumption/pages/fluvius-consents/fluvius-consents.component';
import { EliqAccessResolver } from '@app/modules/customer-zone/consumption/resolvers/eliq-access.resolver';
import { ErrorAccessComponent } from '@app/modules/customer-zone/consumption/pages/error-access/error-access.component';
import { ErrorAccessComponent as BillingErrorAccess } from '@app/modules/customer-zone/billing/pages/error-access/error-access.component';
import { AdviceComponent } from '@app/modules/customer-zone/consumption/pages/advice/advice.component';
import { PaymentPlanComponent } from '@app/modules/customer-zone/invoices/pages/payment-plan/payment-plan.component';
import { InvoicesComponent } from '@app/modules/customer-zone/invoices/pages/invoices/invoices.component';

const consumptionRoutes: Route = {
  path: Paths.consumptions,
  runGuardsAndResolvers: 'always',
  data: {
    genericMessageRedirect: true,
  },
  children: [
    {
      path: '',
      component: ConsumptionDashboardComponent,
      runGuardsAndResolvers: 'always',
      data: {
        allowedWithAccess: { [AccessRight.accessMyConsumption]: true },
        notAllowedRedirectTo: Paths.root,
        genericMessageRedirect: true,
      },
      resolve: {
        eliqAccess: EliqAccessResolver,
      },
      pathMatch: 'full',
    },
    {
      path: 'meter-readings',
      component: MeterReadingComponent,
      runGuardsAndResolvers: 'always',
      data: {
        allowedWithAccess: { [AccessRight.accessMyConsumption]: true },
        notAllowedRedirectTo: Paths.root,
        genericMessageRedirect: true,
      },
      pathMatch: 'full',
    },
    {
      path: Paths.estimatedIndex,
      component: EstimatedIndexComponent,
      runGuardsAndResolvers: 'always',
      data: {
        allowedWithAccess: { [AccessRight.accessIndex56]: true },
        notAllowedRedirectTo: Paths.root,
        genericMessageRedirect: true,
      },
      pathMatch: 'full',
    },
    {
      path: Paths.powerPeak,
      component: PowerPeakGraphComponent,
      runGuardsAndResolvers: 'always',
      data: {
        allowedWithAccess: { [AccessRight.accessMyConsumption]: true, [AccessRight.accessEnergyInsights]: true },
        notAllowedRedirectTo: Paths.root,
        genericMessageRedirect: true,
      },
      pathMatch: 'full',
    },
    {
      path: 'home-profile',
      component: HomeProfileComponent,
      runGuardsAndResolvers: 'always',
      data: {
        allowedWithAccess: { [AccessRight.accessMyConsumption]: true, [AccessRight.accessEnergyInsights]: true },
        notAllowedRedirectTo: Paths.root,
        genericMessageRedirect: true,
        allowedWithEliqAccess: { [EliqAccessRight.seeHomeProfileTabAndMyHome]: true },
      },
      resolve: {
        eliqAccess: EliqAccessResolver,
      },
      pathMatch: 'full',
    },
    {
      path: 'advice',
      component: AdviceComponent,
      runGuardsAndResolvers: 'always',
      data: {
        allowedWithAccess: { [AccessRight.accessMyConsumption]: true, [AccessRight.accessEnergyInsights]: true },
        notAllowedRedirectTo: Paths.root,
        genericMessageRedirect: true,
        allowedWithEliqAccess: { [EliqAccessRight.seeAdvicePage]: true },
      },
      resolve: {
        eliqAccess: EliqAccessResolver,
      },
      pathMatch: 'full',
    },
    {
      path: 'fluvius-consents',
      component: FluviusConsentsComponent,
      runGuardsAndResolvers: 'always',
      data: {
        allowedWithAccess: { [AccessRight.accessMyConsumption]: true, [AccessRight.accessEnergyInsights]: true },
        notAllowedRedirectTo: Paths.root,
        genericMessageRedirect: true,
        allowedWithEliqAccess: { [EliqAccessRight.seeMandatePage]: true },
      },
      resolve: {
        eliqAccess: EliqAccessResolver,
      },
      pathMatch: 'full',
    },
    {
      path: 'error-access',
      component: ErrorAccessComponent,
      runGuardsAndResolvers: 'always',
      data: {
        notAllowedRedirectTo: Paths.root,
        genericMessageRedirect: true,
      },
      pathMatch: 'full',
    },
  ],
};

const routes: Routes = [
  {
    path: '',
    resolve: {
      active: ActiveReferenceResolverService,
      customData: DataResolver,
    },
    children: [
      {
        path: '',
        component: DashboardComponent,
        canActivate: [ClientAuthguard, userProfile, accessRights],
        runGuardsAndResolvers: 'always',
        data: {
          genericMessageRedirect: true,
        },
        pathMatch: 'full',
      },
      {
        path: Paths.invoices,
        canActivate: [ClientAuthguard, userProfile, accessRights],
        runGuardsAndResolvers: 'always',
        data: {
          genericMessageRedirect: true,
        },
        children: [
          {
            path: '',
            component: InvoicesComponent,
            runGuardsAndResolvers: 'always',
            data: {
              allowedWithAccess: { [AccessRight.viewInvoices]: true },
              notAllowedRedirectTo: `/${Paths.invoices}/${Paths.advancePayment}`,
              genericMessageRedirect: true,
            },
            pathMatch: 'full',
          },
          {
            path: 'payment-plan',
            component: PaymentPlanComponent,
            runGuardsAndResolvers: 'always',
            data: {
              allowedWithAccess: { [AccessRight.viewPaymentPlan]: true },
              notAllowedRedirectTo: `/${Paths.invoices}`,
              genericMessageRedirect: true,
            },
            pathMatch: 'full',
          },
          {
            path: Paths.advancePayment,
            loadComponent: () =>
              import('./modules/customer-zone/billing/pages/advance-payment/advance-payment.component').then(
                (m) => m.AdvancePaymentComponent
              ),
            data: {
              genericMessageRedirect: true,
            },
            pathMatch: 'full',
          },
          {
            path: 'error',
            component: BillingErrorAccess,
            data: {
              genericMessageRedirect: true,
            },
            pathMatch: 'full',
          },
          {
            path: 'payment-preferences',
            component: PaymentPreferencesComponent,
            data: {
              genericMessageRedirect: true,
            },
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'webview',
        data: { isMobileApp: true },
        children: [{ ...consumptionRoutes }],
        canActivate: [userProfile, accessRights],
      },
      {
        ...consumptionRoutes,
        canActivate: [ClientAuthguard, userProfile, accessRights],
      },
      {
        path: Paths.myEv,
        canActivate: [ClientAuthguard, userProfile, accessRights],
        runGuardsAndResolvers: 'always',
        data: {
          genericMessageRedirect: true,
        },
        children: [
          {
            path: '',
            component: TevcDashboardComponent,
            runGuardsAndResolvers: 'always',
            data: {
              allowedWithAccess: { [AccessRight.accessTevc]: true },
              notAllowedRedirectTo: Paths.root,
              genericMessageRedirect: true,
            },
            pathMatch: 'full',
          },
          {
            path: Paths.reports,
            component: ReportsComponent,
            runGuardsAndResolvers: 'always',
            data: {
              allowedWithAccess: { [AccessRight.accessTevc]: true },
              notAllowedRedirectTo: Paths.root,
              genericMessageRedirect: true,
            },
            pathMatch: 'full',
          },
        ],
      },
      {
        path: 'contracts',
        canActivate: [ClientAuthguard, userProfile, accessRights],
        data: {
          genericMessageRedirect: true,
        },
        runGuardsAndResolvers: 'always',
        children: [
          {
            path: '',
            component: AllContractsComponent,
            pathMatch: 'full',
          },
          {
            path: Paths.other,
            component: OtherContractsComponent,
            runGuardsAndResolvers: 'always',
            data: {
              allowedWithAccess: { [AccessRight.accessMyOtherProducts]: true },
              notAllowedRedirectTo: Paths.root,
              genericMessageRedirect: true,
            },
            pathMatch: 'full',
          },
        ],
      },
      {
        path: Paths.preferences,
        canActivate: [ClientAuthguard, userProfile, accessRights],
        runGuardsAndResolvers: 'always',
        children: [
          {
            path: '',
            pathMatch: 'prefix',
            redirectTo: Paths.contact,
          },
          {
            path: Paths.references,
            component: ReferencesComponent,
            pathMatch: 'full',
          },
          {
            path: `${Paths.references}/add`,
            component: BindingProcessComponent,
            pathMatch: 'full',
          },
          {
            path: Paths.contact,
            component: ContactDetailsComponent,
            runGuardsAndResolvers: 'always',
            data: {
              allowedWithAccess: { [AccessRight.modifyContactDetails]: true },
              notAllowedRedirectTo: `/${Paths.preferences}/${Paths.references}`,
              genericMessageRedirect: true,
            },
            pathMatch: 'full',
          },
          {
            path: Paths.house,
            component: HomeProfileComponent,
            runGuardsAndResolvers: 'always',
            data: {
              allowedWithAccess: { [AccessRight.accessBuildingInfo]: true, [AccessRight.accessEnergyInsights]: true },
              notAllowedRedirectTo: Paths.root,
              genericMessageRedirect: true,
            },
            pathMatch: 'full',
          },
          {
            path: 'consent',
            component: ConsentComponent,
            pathMatch: 'full',
          },
          {
            path: Paths.move,
            loadComponent: () =>
              import('./modules/customer-zone/move/pages/move/move.component').then((m) => m.MoveComponent),
            runGuardsAndResolvers: 'always',
            data: {
              allowedWithAccess: { [AccessRight.accessMove]: true },
              notAllowedRedirectTo: Paths.root,
              genericMessageRedirect: true,
            },
            pathMatch: 'prefix',
          },
          {
            path: `${Paths.move}/form`,
            loadChildren: () =>
              import('./modules/customer-zone/move/move-form-routing.module').then((m) => m.MoveFormRoutingModule),
          },
        ],
      },
      {
        path: Paths.sponsorship,
        canActivate: [ClientAuthguard, userProfile, accessRights],
        runGuardsAndResolvers: 'always',
        data: {
          allowedWithAccess: { [AccessRight.accessSponsorship]: true },
          notAllowedRedirectTo: Paths.root,
          genericMessageRedirect: true,
        },
        component: MgmComponent,
        pathMatch: 'full',
      },
      {
        path: Paths.selfBilling,
        canActivate: [ClientAuthguard, userProfile, accessRights],
        runGuardsAndResolvers: 'always',
        data: {
          allowedWithAccess: { [AccessRight.accessInjection]: true },
          notAllowedRedirectTo: Paths.root,
          genericMessageRedirect: true,
        },
        component: SelfBillingComponent,
        pathMatch: 'full',
      },
      {
        path: 'upsell/boiler-maintenance-os',
        canActivate: [ClientAuthguard, userProfile, accessRights],
        runGuardsAndResolvers: 'always',
        component: BoilerOneShotComponent,
        pathMatch: 'full',
      },
      {
        path: 'error/:status',
        canActivate: [ClientAuthguard],
        component: ErrorComponent,
        pathMatch: 'full',
      },
      {
        path: Paths.unauthorized,
        canActivate: [ClientAuthguard, userProfile, accessRights],
        runGuardsAndResolvers: 'always',
        component: UnauthorizedComponent,
        pathMatch: 'full',
      },
      {
        path: 'references/add',
        component: BindingProcessComponent,
        canActivate: [ClientAuthguard],
        pathMatch: 'full',
      },
      {
        path: 'registration',
        component: CreateAccountComponent,
        canActivate: [PublicAuthguard],
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    canActivate: [ClientAuthguard, userProfile],
    resolve: {
      active: ActiveReferenceResolverService,
      customData: DataResolver,
    },
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
  providers: [ClientAuthguard, PublicAuthguard],
})
export class AppRoutingModule {}
