import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-smart-solar-tpl',
  templateUrl: './smart-solar-tpl.component.html',
  styleUrls: ['./smart-solar-tpl.component.scss'],
})
export class SmartSolarTplComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
