import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Granularity } from '../../models/tevc.constants';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'app-montly-reports-filter',
  templateUrl: './montly-reports-filter.component.html',
  styleUrls: ['./montly-reports-filter.component.scss'],
})
export class MontlyReportsFilterComponent implements OnInit {
  @Output() filter: EventEmitter<Granularity | string> = new EventEmitter<Granularity | string>();
  @Input() options: KeyValue<string, string>;
  @Input() default?: string;
  active: string;

  constructor() {}

  ngOnInit(): void {
    this.active = this.default ?? null;
  }

  apply(option: string) {
    this.active = option;
    this.filter.emit(option);
  }
}
