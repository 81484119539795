<div class="pixel_blue relative box-gradient">
  <div class="flex content-between flex-col flex-wrap h-full">
    <div class="w-full border-solid border-b border-grey pb-10">
      <div class="w-1/2 flex items-center">
        <img src="./assets/img/products/pixel-blue.svg" alt="pixel_blue" />
      </div>
    </div>
    <div class="main">
      <div class="border-b border-grey border-solid mt-5 mb-10">
        <div class="flex mb-7">
          <span class="text-sm">{{ 'components.contracts.registerFix.contractInfo.tariff.label' | translate }}</span>
          <span class="ml-6 font-medium">{{
            'components.contracts.registerFix.contractInfo.tariff.value' | translate
          }}</span>
        </div>
        <div class="flex mb-7">
          <span class="text-sm">{{ 'components.contracts.registerFix.contractInfo.startDate.label' | translate }}</span>
          <span class="ml-6 font-medium"> {{ contractStartDate | date : 'd/M/yyyy' }}</span>
        </div>

        <div class="flex mb-7">
          <span class="text-sm">{{
            'components.contracts.registerFix.contractInfo.contractDuration.label' | translate
          }}</span>
          <span class="ml-6 font-medium whitespace-nowrap">{{
            'components.contracts.registerFix.contractInfo.contractDuration.value' | translate
          }}</span>
        </div>

        <div class="flex mb-7">
          <span class="text-sm">{{
            'components.contracts.registerFix.contractInfo.contratFor.label' | translate
          }}</span>
          <span class="ml-6 font-medium whitespace-nowrap">{{
            'components.contracts.registerFix.contractInfo.contratFor.value' | translate
          }}</span>
        </div>
      </div>
      <ul class="product-description flex flex-col text-sm">
        <li
          class="relative mb-3"
          *ngFor="let index of [0, 1, 2, 3]"
          [innerHTML]="'components.contracts.registerFix.productDescription.' + index | translate"
        ></li>
      </ul>
    </div>
    <div class="flex justify-center mt-8">
      <button class="btn btn-primary button-become-customer" [disabled]="!changeContractActive" (click)="createNewRequest()">
        {{ 'components.contracts.registerFix.upgradeContractBtn' | translate }}
      </button>
    </div>
  </div>
</div>
