<div
  *ngIf="contracts?.length; else noContract"
  class="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-16"
>
    <app-contract
      *ngFor="let contract of contracts"
      class="contract card--no-padding"
      [contract]="contract"
      [site]="site"
    >
    </app-contract>
</div>

<ng-template #noContract>
  <div
    [innerHTML]="'components.contracts.error.unavailable' | translate"
    class="bg-white box-shadow-small mb-24 rounded-4xl inline-block w-full border border-white p-8">
  </div>
</ng-template>

<!--
<app-register-fix-product class="relative"></app-register-fix-product>
-->
