import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AlertComponent } from '@app/shared/components/alert/alert.component';
import {
  BillingDetailsCuzoApi,
  BillingDetailsPaymentMethodEnumCuzoApi,
  InvoiceModelV2CuzoApi,
} from '@app/shared/models/cuzo-be-contract';
import { AnalyticsModule } from '@app/shared/modules/analytics/analytics.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { InvoiceActionsComponent } from '@app/modules/customer-zone/invoices/components/micro/invoice-actions/invoice-actions.component';
import { PaymentBalanceViewType } from '@app/modules/customer-zone/invoices/models/invoice.interface';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { InvoicePayOnlineComponent } from '@app/modules/customer-zone/invoices/components/micro/invoice-pay-online/invoice-pay-online.component';
import { RouterLink } from '@angular/router';
import { BillingFacade } from '@app/core/facade/billing.facade';
import { InvoiceStatusComponent } from '@app/modules/customer-zone/invoices/components/micro/invoice-status/invoice-status.component';

@Component({
  selector: 'app-payment-informations',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    AlertComponent,
    AnalyticsModule,
    InvoiceActionsComponent,
    InvoicePayOnlineComponent,
    RouterLink,
    InvoiceStatusComponent,
  ],
  templateUrl: './payment-informations.component.html',
  styleUrls: ['./payment-informations.component.scss'],
})
export class PaymentInformationsComponent implements OnChanges {
  @Input() public billingDetails: BillingDetailsCuzoApi;
  @Input() public invoice: InvoiceModelV2CuzoApi;
  @Input() public viewType: PaymentBalanceViewType;
  public PaymentBalanceViewType = PaymentBalanceViewType;
  public PaymentMethod = BillingDetailsPaymentMethodEnumCuzoApi;
  structuredCommunication: string = null;

  constructor(private billingFacade: BillingFacade) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.billingDetails?.paymentMethod === BillingDetailsPaymentMethodEnumCuzoApi.BANK_TRANSFER &&
      this.invoice?.structuredCommunication
    ) {
      this.structuredCommunication = this.billingFacade.formatStructuredCommunication(
        this.invoice?.structuredCommunication
      );
    }
  }
}

_('pages.dashboard.paymentBalance.toRefund.refundMessage');
