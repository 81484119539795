import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  distinctUntilChanged,
  filter,
  forkJoin,
  map,
  Observable,
  of,
  startWith,
  Subscription,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { InvoiceService } from '../../modules/customer-zone/invoices/services/invoices/invoice.service';
import { HttpResponse } from '@angular/common/http';
import {
  BillingDetailsPaymentMethodEnumCuzoApi,
  InvoiceModelV2CuzoApi,
  InvoiceResponseV2CuzoApi,
  OpenInvoicesCuzoApi,
  PaymentPlanCuzoApi,
} from '@app/shared/models/cuzo-be-contract';
import { PaymentPlanService } from '@app/modules/customer-zone/invoices/services/payment-plan/payment-plan.service';
import { BillingState, INITIAL_BILLING_STATE } from '@app/core/state/state';
import { InvoiceV2Service } from '@app/modules/customer-zone/invoices/services/invoice-v2/invoice-v2.service';
import { InvoiceParams } from '@app/modules/customer-zone/invoices/models/invoice.interface';
import { PaynxtService } from '@app/modules/customer-zone/invoices/services/paynxt/paynxt.service';
import { ReferenceService } from '@app/modules/customer-zone/user/services/reference/reference.service';
import { ApiResponse } from '@app/shared/models/api.inteface';
import { ErrorHandlerService } from '@app/core/service/error-handler.service';
import { UserService } from '@app/modules/customer-zone/user/services/user/user.service';
import { AccessRights } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { ActiveInvoices } from '../../shared/models/cuzo-be-contract-extend';
import { InvoiceTransformService } from '@app/modules/customer-zone/invoices/services/invoices/invoice-transform.service';

@Injectable({
  providedIn: 'root',
})
export class BillingFacade {
  readonly invoicesPerPage: number = 10;
  readonly reference$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  readonly billingState$: BehaviorSubject<BillingState> = new BehaviorSubject(INITIAL_BILLING_STATE);
  readonly invoices$: Observable<ApiResponse<InvoiceResponseV2CuzoApi>> = this.billingState$.pipe(
    map((billingState: BillingState) => billingState.invoices),
    distinctUntilChanged()
  );
  readonly openInvoices$: Observable<ApiResponse<OpenInvoicesCuzoApi>> = this.billingState$.pipe(
    map((billingState: BillingState) => billingState.openInvoices),
    distinctUntilChanged()
  );
  readonly paymentPlan$: Observable<ApiResponse<PaymentPlanCuzoApi>> = this.billingState$.pipe(
    map((billingState: BillingState) => billingState.paymentPlan),
    distinctUntilChanged()
  );
  activeInvoicesData$: Observable<ActiveInvoices> = combineLatest({
    openInvoices: this.openInvoices$,
    paymentPlan: this.paymentPlan$,
    invoices: this.invoices$,
  });
  private invoiceParams$: BehaviorSubject<InvoiceParams> = new BehaviorSubject<InvoiceParams>({
    offset: 0,
    limit: this.invoicesPerPage,
  });

  constructor(
    private referenceService: ReferenceService,
    private userService: UserService,
    private paymentPlanService: PaymentPlanService,
    private invoiceService: InvoiceService,
    private invoiceV2Service: InvoiceV2Service,
    private paynxtService: PaynxtService,
    private errorHandlerService: ErrorHandlerService,
    private invoiceTransformService: InvoiceTransformService
  ) {
    this.referenceService
      .getActiveReference()
      .pipe(
        filter((reference: string) => !!reference),
        distinctUntilChanged()
      )
      .subscribe((reference: string) => {
        this.invoiceParams$.next({ offset: 0, limit: this.invoicesPerPage });
        this.reference$.next(reference);
      });
  }

  loadInvoices(): Subscription {
    return this.reference$
      .pipe(
        switchMap(() => this.invoiceParams$),
        switchMap((params: InvoiceParams) => this.getInvoices(this.reference$.value, params))
      )
      .subscribe();
  }

  updateParams(params: InvoiceParams): void {
    this.invoiceParams$.next(params);
  }

  loadPaymentPlan(): Subscription {
    return this.reference$
      .pipe(
        switchMap((reference: string) =>
          this.userService.getAccessRights(reference).pipe(
            map((accessRights: AccessRights): { reference: string; accessRights: AccessRights } => ({
              reference,
              accessRights,
            }))
          )
        ),
        filter(({ accessRights }) => accessRights?.viewPaymentPlan),
        switchMap(({ reference }) => this.getPaymentPlan(reference))
      )
      .subscribe();
  }

  loadActiveInvoicesData(): Subscription {
    return this.reference$
      .pipe(
        switchMap((reference: string) => forkJoin([of(reference), this.userService.getAccessRights(reference)])),
        switchMap(([reference, accessRights]: [string, AccessRights]) =>
          this.getActiveInvoicesData(reference, accessRights?.viewPaymentPlan)
        )
      )
      .subscribe();
  }

  getInvoiceDocument(idInvoice: string): Observable<HttpResponse<Blob>> {
    idInvoice = idInvoice.replace('/', '');
    return this.invoiceService.getInvoicePDF(this.reference$.value, idInvoice, false).pipe(take(1));
  }

  getInvoiceDetails(idInvoice: string): Observable<HttpResponse<string>> {
    idInvoice = idInvoice.replace('/', '');
    return this.invoiceService.getInvoiceDetails(this.reference$.value, idInvoice).pipe(take(1));
  }

  getOnlinePaymentUrl(invoice: InvoiceModelV2CuzoApi): string {
    if (invoice.paymentLink) {
      return invoice.paymentLink + this.paynxtService.getRedirectsAfterPayment(invoice);
    }
    return '';
  }

  isRedirectedFromPayNxt(): void {
    this.paynxtService.isRedirectedFromPayNxt();
  }

  formatStructuredCommunication(code: string): string {
    if (code.length !== 12 || !/^\d+$/.test(code)) {
      return null;
    }
    let formatted = `${code.slice(0, 3)}/${code.slice(3, 7)}/${code.slice(7)}`;
    return `+++${formatted}+++`;
  }

  private getInvoices(reference: string, params: InvoiceParams): Observable<ApiResponse<InvoiceResponseV2CuzoApi>> {
    return this.invoiceV2Service.getInvoices(reference, params).pipe(
      map((data: InvoiceResponseV2CuzoApi) => this.invoiceTransformService.transformInvoices(data)), // @todo: TO BE REMOVED
      map(
        (data: InvoiceResponseV2CuzoApi): ApiResponse<InvoiceResponseV2CuzoApi> => ({
          data: { ...data, reference },
          loading: false,
        })
      ),
      startWith({ ...this.billingState$.value.invoices, loading: true }),
      catchError(this.errorHandlerService.handleError),
      tap((invoices: ApiResponse<InvoiceResponseV2CuzoApi>) =>
        this.setState(
          (currentState: BillingState): BillingState => ({
            ...currentState,
            invoices,
          })
        )
      )
    );
  }

  private getOpenInvoices(reference: string): Observable<ApiResponse<OpenInvoicesCuzoApi>> {
    return this.invoiceService.getOpenInvoices(reference).pipe(
      map((data: OpenInvoicesCuzoApi) => this.invoiceTransformService.transformOpenInvoices(data)), // @todo: TO BE REMOVED
      map((data: OpenInvoicesCuzoApi): ApiResponse<OpenInvoicesCuzoApi> => ({ data })),
      catchError(this.errorHandlerService.handleError),
      tap((openInvoices: ApiResponse<OpenInvoicesCuzoApi>) => {
        this.setState(
          (currentState: BillingState): BillingState => ({
            ...currentState,
            openInvoices,
            payWithDirectDebit: this.payWithDirectDebit(openInvoices?.data?.billingDetails?.paymentMethod),
          })
        );
      })
    );
  }

  private getPaymentPlan(reference: string): Observable<ApiResponse<PaymentPlanCuzoApi>> {
    return this.paymentPlanService.getPaymentPlan(reference).pipe(
      map((data: PaymentPlanCuzoApi): ApiResponse<PaymentPlanCuzoApi> => ({ data })),
      catchError(this.errorHandlerService.handleError),
      tap((paymentPlan: ApiResponse<PaymentPlanCuzoApi>) => {
        this.setState(
          (currentState: BillingState): BillingState => ({
            ...currentState,
            paymentPlan,
            payWithDirectDebit: this.payWithDirectDebit(paymentPlan?.data?.billingDetails?.paymentMethod),
          })
        );
      })
    );
  }

  private getActiveInvoicesData(reference: string, hasPaymentPlan: boolean = false): Observable<ActiveInvoices> {
    return forkJoin({
      openInvoices: this.getOpenInvoices(reference).pipe(take(1)),
      paymentPlan: hasPaymentPlan ? this.getPaymentPlan(reference).pipe(take(1)) : of(null),
    }).pipe(
      tap(({ openInvoices, paymentPlan }: ActiveInvoices): void => {
        this.setState(
          (currentState: BillingState): BillingState => ({
            ...currentState,
            openInvoices,
            paymentPlan,
            payWithDirectDebit: this.payWithDirectDebit(openInvoices?.data?.billingDetails?.paymentMethod),
          })
        );
      })
    );
  }

  private payWithDirectDebit(paymentMethod: BillingDetailsPaymentMethodEnumCuzoApi): boolean {
    return [
      BillingDetailsPaymentMethodEnumCuzoApi.DIRECT_DEBIT,
      BillingDetailsPaymentMethodEnumCuzoApi.DIRECT_DEBIT_AND_BANK_TRANSFER,
    ].includes(paymentMethod);
  }

  private setState(updateFn: (currentState: BillingState) => BillingState): void {
    const newState: BillingState = updateFn(this.billingState$.value);
    this.billingState$.next(newState);
  }
}
