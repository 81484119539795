<div class="flex">
  <div class="relative cursor-pointer w-full" ngbDropdown>
    <!-- date picker selector  -->
    <div id="date-picker-dropdown" ngbDropdownToggle>
      <div class="font-medium text-lg text-grey-dark inline-block">
        {{ 'components.datePicker.week' | translate }}
      </div>
      <div
        class="inline-block ml-8 align-middle w-10 h-10 bg-contain mt-2 arrow transform scale-50"
        [ngClass]="!!dropdown && isDropdownOpen() ? 'rotate-180' : 'rotate-0'"
      ></div>
    </div>

    <!--  date picker  -->
    <div ngbDropdownMenu aria-labelledby="date-picker-dropdown" class="date-picker-wrapper">
      <ngb-datepicker
        class="date-picker"
        #datepicker
        (dateSelect)="onDateSelection($event)"
        [startDate]="selectedDate"
        [maxDate]="maxDate"
        navigation="none"
        [dayTemplate]="t"
        displayMonths="1"
      >
        <!--  date picker content  -->
        <ng-template ngbDatepickerContent>
          <div *ngFor="let month of datepicker.state.months">
            <div class="flex justify-center mb-2 items-center">
              <div class="navigation-arrow navigation-arrow--left" (click)="navigateBetweenMonths(-1)">
                <img
                  src="./assets/img/tevc-monitoring/arrow-orange.svg"
                  [alt]="'general.back' | translate"
                  class="w-4"
                />
              </div>
              <div
                class="border border-solid font-bold text-base rounded-lg py-2 px-4 w-3/5 text-center"
                (click)="monthSelectIsShowing = true"
              >
                {{ i18n.getMonthFullName(month.month) }} {{ month.year }}
              </div>
              <div class="navigation-arrow" (click)="navigateBetweenMonths(1)">
                <img
                  [src]="
                    month.year < currentYear || month.month < currentMonth
                      ? './assets/img/tevc-monitoring/arrow-orange.svg'
                      : './assets/img/tevc-monitoring/arrow-disabled.svg'
                  "
                  [alt]="'general.next' | translate"
                  class="w-4"
                />
              </div>
            </div>
            <!--  custom month view  -->
            <ngb-datepicker-month [month]="month"></ngb-datepicker-month>
          </div>
        </ng-template>

        <!--  custom day view  -->
        <ng-template #t let-date>
          <span
            class="date-picker__day"
            [class.highlighted]="isDateInCalendarCurrentMonth(date)"
            [class.selected]="isDateInCalendarSelected(date)"
            (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null"
            (click)="dropdown.close()"
          >
            {{ date.day }}
          </span>
        </ng-template>
      </ngb-datepicker>

      <!--  custom year select  -->
      <div
        *ngIf="yearSelectIsShowing"
        class="w-full h-full bg-white absolute top-0 left-0 z-10 rounded-lgtext-sm p-8 pb-4"
      >
        <div class="grid grid-cols-4 gap-4 text-center cursor-default h-40 mt-20 pt-2 mb-10">
          <div
            *ngFor="let year of yearsAvailable"
            class="year-month-select"
            (click)="selectedYear = year; yearSelectIsShowing = false; monthSelectIsShowing = true"
          >
            {{ year }}
          </div>
        </div>
      </div>

      <!--  custom month select  -->
      <div
        *ngIf="monthSelectIsShowing"
        class="w-full h-full bg-white absolute top-0 left-0 z-10 rounded-lg text-sm p-8 pb-4"
      >
        <!--  header with selected year and arrows  -->
        <div class="flex justify-center mb-2 items-center">
          <div
            *ngIf="selectedYear > yearsAvailable[yearsAvailable.length - 1]; else disabledLeftArrow"
            (click)="selectedYear = selectedYear - 1"
            class="navigation-arrow navigation-arrow--left"
          >
            <img src="./assets/img/tevc-monitoring/arrow-orange.svg" [alt]="'general.back' | translate" class="w-4" />
          </div>
          <ng-template #disabledLeftArrow>
            <div class="navigation-arrow navigation-arrow--left">
              <img
                src="./assets/img/tevc-monitoring/arrow-disabled.svg"
                [alt]="'general.back' | translate"
                class="w-4"
              />
            </div>
          </ng-template>
          <div
            class="border border-solid font-bold text-base rounded-lg py-2 px-4 w-3/5 text-center"
            (click)="monthSelectIsShowing = false; yearSelectIsShowing = true"
          >
            {{ selectedYear }}
          </div>
          <div
            *ngIf="selectedYear < yearsAvailable[0]; else disabledRightArrow"
            (click)="selectedYear = selectedYear + 1"
            class="navigation-arrow"
          >
            <img src="./assets/img/tevc-monitoring/arrow-orange.svg" [alt]="'general.next' | translate" class="w-4" />
          </div>
          <ng-template #disabledRightArrow>
            <div class="navigation-arrow">
              <img
                src="./assets/img/tevc-monitoring/arrow-disabled.svg"
                [alt]="'general.back' | translate"
                class="w-4"
              />
            </div>
          </ng-template>
        </div>

        <!--  grid with all 12 months user can choose from  -->
        <div class="grid grid-cols-4 gap-4 text-center cursor-default h-60 my-10">
          <ng-container *ngFor="let month of calendar.getMonths(selectedYear)">
            <!--  months in previous years, or in current year until current month are clickable and change colour on hover  -->
            <div
              *ngIf="selectedYear < currentYear || (selectedYear === currentYear && month <= currentMonth)"
              class="year-month-select"
              (click)="navigate(selectedYear, month); monthSelectIsShowing = false"
            >
              {{ i18n.getMonthShortName(month) }}
            </div>
            <!--  months in current year which are in the future are not clickable  -->
            <div
              *ngIf="selectedYear === currentYear && month > currentMonth"
              class="text-black bg-gray-100 rounded-lg flex items-center justify-center"
            >
              {{ i18n.getMonthShortName(month) }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="font-display font-normal text-primary text-sm mt-2">
  <span
    ><b>{{ selectedDateFrom | date: 'dd/MM/yyyy' }}</b></span
  >
  {{ 'components.datePicker.until' | translate }}
  <span
    ><b>{{ selectedDateTo | date: 'dd/MM/yyyy' }}</b></span
  >
</div>
