import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { EnergyType } from '../../../modules/customer-zone/consumption/models/consumption.interface';
import { UntypedFormControl } from '@angular/forms';
import { numberRange } from '@app/shared/utils/utils.validators';
import { UtilsService } from '@app/shared/utils/utils.service';
import { formatNumber } from '@angular/common';
import { ScreenDimensions, ScreenSizeService } from '@app/core/service/screen-size.service';
import { Subscription } from 'rxjs';

interface SliderPosition {
  percentage: number;
  marginLeft: number;
}

@Component({
  selector: 'fc-installment-slider',
  templateUrl: './input-installment-slider.component.html',
  styleUrls: ['./input-installment-slider.component.scss'],
})
export class InputInstallmentSliderComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() energy: EnergyType;
  @Input() minAmount: number;
  @Input() maxAmount: number;
  @Input() currentAmount?: number;
  @Input() suggestedAmount?: number;
  @Input() control: UntypedFormControl;

  @ViewChild('range') rangeInput: ElementRef;
  @ViewChild('rangePrefix') rangePrefixInput: ElementRef;

  @ViewChild('defaultSliderColor') defaultColorElement: ElementRef;
  @ViewChild('positiveSliderColor') positiveColorElement: ElementRef;
  @ViewChild('negativeSliderColor') negativeColorElement: ElementRef;
  @ViewChild('currentAmountRef') currentAmountElement: ElementRef;
  @ViewChild('suggestedAmountRef') suggestedAmountElement: ElementRef;

  public isSelectedCurrentAmount = false;
  public showSuggestedAmountButton = false;
  public suggestedAmountPosition: SliderPosition;
  public currentAmountPosition: SliderPosition;
  public maxLengthOfAmount: number;
  public selectedAmount: number;

  public minAmountText: string;
  public maxAmountText: string;
  public amountsOverlapped = false;

  public numberMask = {
    mask: Number,
    signed: false,
    scale: 2,
    padFractionalZeros: true,
    radix: ',',
    mapToRadix: ['.'],
  };
  private subscription: Subscription;
  private readonly sliderColors = {
    positive: '',
    negative: '',
    default: '',
  };

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private renderer: Renderer2,
    private utilsService: UtilsService,
    private screenSizeService: ScreenSizeService
  ) {}

  ngOnInit(): void {
    this.suggestedAmountPosition = this.suggestedAmount ? this.getAmountAsPercent(this.suggestedAmount) : null;
    this.currentAmountPosition = this.currentAmount ? this.getAmountAsPercent(this.currentAmount) : null;
    this.setSuggestedValue();
    this.maxLengthOfAmount = this.maxAmount.toString().length + ',00'.length;

    this.control.valueChanges.subscribe((amount) => {
      // convert string to number, because number mask returns string type
      amount = this.utilsService.convertMaskedStringToNumber(amount);

      // show suggested button when the selected amount isn't equal to suggested amount
      this.showSuggestedAmountButton = !this.utilsService.floatEqual(
        amount,
        this.utilsService.round(this.suggestedAmount, 2)
      );

      // check if the new selected amount isn't equal to previous selected amount
      if (!this.utilsService.floatEqual(amount, this.selectedAmount)) {
        this.styleRange(amount);
        this.selectedAmount = amount;
      }

      // check if new selected amount is equal to current amount
      this.isSelectedCurrentAmount = this.utilsService.floatEqual(amount, this.currentAmount);
    });
  }

  ngAfterViewInit(): void {
    // don't set colors directly in the component, read them rather from the HTML file
    // this initialization has to be done first - it will be used in styleRange method immediatelly
    this.sliderColors.default = window.getComputedStyle(this.defaultColorElement.nativeElement).color;
    this.sliderColors.positive = window.getComputedStyle(this.positiveColorElement.nativeElement).color;
    this.sliderColors.negative = window.getComputedStyle(this.negativeColorElement.nativeElement).color;

    this.selectedAmount = this.utilsService.convertMaskedStringToNumber(this.control.value);
    this.styleRange(this.selectedAmount);

    this.minAmountText = formatNumber(this.minAmount, this.locale, '1.2-2');
    this.maxAmountText = formatNumber(this.maxAmount, this.locale, '1.2-2');

    // set recommended value on slider
    this.control.setValidators(numberRange(this.minAmount, this.maxAmount));
    this.subscription = this.screenSizeService.screenSize$.subscribe(() => {
      this.amountsOverlapped = this.utilsService.elementsOverlap(
        this.currentAmountElement,
        this.suggestedAmountElement
      );
    });
  }

  public styleRange(amount: number): void {
    // here it's important to round the numbers for check the same values
    const isAmountHigherOrEqual = amount > this.suggestedAmount || !this.showSuggestedAmountButton;

    const selectedColor = isAmountHigherOrEqual ? this.sliderColors.positive : this.sliderColors.negative;

    const amountInPercent = this.getAmountAsPercent(amount).percentage;
    // eslint-disable-next-line max-len
    const bgLinear = `linear-gradient(to right, ${selectedColor} 0%, ${selectedColor} ${amountInPercent}%, ${this.sliderColors.default} ${amountInPercent}%, ${this.sliderColors.default} 100%)`;
    this.renderer.setStyle(this.rangeInput.nativeElement, 'background', bgLinear);

    // add positive or negative class
    if (isAmountHigherOrEqual) {
      this.renderer.addClass(this.rangeInput.nativeElement, 'positive-value');
      this.renderer.addClass(this.rangePrefixInput.nativeElement, 'positive-value');
    } else {
      this.renderer.removeClass(this.rangeInput.nativeElement, 'positive-value');
      this.renderer.removeClass(this.rangePrefixInput.nativeElement, 'positive-value');
    }
  }

  public getAmountAsPercent(amount: number): SliderPosition {
    const positionAsPercent = ((amount - this.minAmount) / (this.maxAmount - this.minAmount)) * 100;

    // calculate margin left value from linear function - at the beginning is margin left positive et the end is negative
    return { percentage: positionAsPercent, marginLeft: (-3 / 10) * positionAsPercent + 10 };
  }

  public updateAmount(amount: number): void {
    // round the value, because imask feature doesn't round the number and convert it to the string
    this.control.setValue(formatNumber(amount, this.locale, '1.2-2'));
  }

  public setSuggestedValue(): void {
    if (this.suggestedAmount) {
      this.updateAmount(this.suggestedAmount);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
