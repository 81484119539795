import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EncryptionService } from '../../../../../shared/utils/encryption.service';
import { UtilsService } from '../../../../../shared/utils/utils.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { IClipboardResponse } from 'ngx-clipboard';
import { timer } from 'rxjs';
import { Discount } from '../../models/discount.interface';
import { AppConfig } from '@app/shared/utils/app-config';

@Component({
  selector: 'mgm-share-tool',
  templateUrl: './mgm-share-tool.component.html',
  styleUrls: ['./mgm-share-tool.component.scss'],
})
export class MgmShareToolComponent implements OnInit, OnChanges {
  @Input() reference: string;
  @Input() discount: Discount;
  @Input() date?: string;

  public shareLink: string;
  public fbShareLink: SafeUrl;
  public waShareLink: SafeUrl;
  public mailShareLink: SafeUrl;
  public smsShareLink: SafeUrl;
  public linkCopied = false;

  constructor(
    private encryptionService: EncryptionService,
    private utilService: UtilsService,
    private translate: TranslateService,
    private sanitizer: DomSanitizer,
    private conf: AppConfig
  ) {}

  ngOnInit(): void {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.generateShareTexts();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.reference) {
      return;
    }

    this.generateShareTexts();
  }

  generateShareTexts() {
    const params = {
      sponsorReference: this.encryptionService.encrypt(this.reference, this.conf.config.encryptionKey),
    };

    this.shareLink = this.utilService.appendQueryParams(this.utilService.getMgmUrl(), params);

    this.fbShareLink = this.sanitizer.bypassSecurityTrustUrl(
      `fb-messenger://share/?link=${this.shareLink}&app_id=${this.conf.config.facebookAppId}`
    );
    const waShareText = encodeURIComponent(
      this.translate.instant('components.mgm.shareTool.messages.whatsapp', {
        link: this.shareLink,
        discount: this.discount.promoDiscount,
        reference: this.reference,
      })
    );
    this.waShareLink = this.sanitizer.bypassSecurityTrustUrl(`https://wa.me/?text=${waShareText}`);

    const mailShareSubject = encodeURIComponent(
      this.translate.instant('components.mgm.shareTool.messages.email.subject', {
        discount: this.discount.promoDiscount,
      })
    );

    const mailSharebody = encodeURIComponent(
      this.translate.instant('components.mgm.shareTool.messages.email.body', {
        link: this.shareLink,
        discount: this.discount.promoDiscount,
        reference: this.reference,
      })
    );
    this.mailShareLink = this.sanitizer.bypassSecurityTrustUrl(
      `mailto:?subject=${mailShareSubject}&body=${mailSharebody}`
    );

    const smsShareBody = this.translate.instant('components.mgm.shareTool.messages.sms', {
      link: this.shareLink,
      discount: this.discount.promoDiscount,
      reference: this.reference,
    });
    this.smsShareLink = this.sanitizer.bypassSecurityTrustUrl(`sms:?&body=${smsShareBody}`);
  }

  fbShare() {
    const quote = this.translate.instant('components.mgm.shareTool.messages.facebookShare', {
      link: this.shareLink,
      discount: this.discount.promoDiscount,
      reference: this.reference,
    });
    const url = `https://www.facebook.com/sharer/sharer.php?u=${
      this.shareLink
    }&amp;src=sdkpreparse&quote=${encodeURIComponent(quote)}`;
    window.open(url, '_blank');
  }

  copied($event: IClipboardResponse) {
    this.linkCopied = true;
    const source = timer(3000);
    source.subscribe((val) => (this.linkCopied = false));
  }
}
