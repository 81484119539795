import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MoveFormFacade } from '@app/core/facade/move-form.facade';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-move-progress',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './move-progress.component.html',
  styleUrls: ['./move-progress.component.scss'],
})
export class MoveProgressComponent implements OnInit {
  constructor(readonly moveFormFacade: MoveFormFacade) {}

  ngOnInit(): void {}
}

_('components.move.dashboard.step');
