import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  filter,
  forkJoin,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import { ComputedData, INITIAL_STATE, State } from '../state/state';
import { UserService } from '@app/modules/customer-zone/user/services/user/user.service';
import { KeycloakService } from 'keycloak-angular';
import { ReferenceService } from '@app/modules/customer-zone/user/services/reference/reference.service';
import { UserProfile } from '@app/modules/customer-zone/user/models/userProfile.interface';
import { ReferenceDetails } from '@app/modules/customer-zone/user/models/reference.interface';
import { UtilsService } from '@app/shared/utils/utils.service';
import { SiteService } from '@app/modules/customer-zone/user/services/site/site.service';
import { ContractService } from '@app/modules/customer-zone/contracts/services/contract.service';
import { Site } from '@app/modules/customer-zone/user/models/site.interface';
import { ContactService } from '@app/modules/customer-zone/contact/services/contact/contact.service';
import { EstimatedIndexService } from '@app/modules/customer-zone/consumption/services/estimated-index/estimated-index.service';
import { BillingService } from '@app/modules/customer-zone/billing/services/billing/billing.service';
import { InvoiceService } from '@app/modules/customer-zone/invoices/services/invoices/invoice.service';
import { Invoice } from '@app/modules/customer-zone/invoices/models/invoice.interface';
import { Contract } from '@app/modules/customer-zone/contracts/models/contract.interface';
import { ContentService } from '@app/shared/services/content/content.service';
import { Content } from '@app/shared/models/content.interface';
import { ServiceEligibility } from '@app/modules/customer-zone/contracts/models/service-eligibility.interface';
import { ContactDetails } from '@app/modules/customer-zone/contact/models/contactDetails.interface';
import { BillingDetails } from '@app/modules/customer-zone/billing/models/billingDetails.interface';
import { EstimatedIndex } from '@app/modules/customer-zone/consumption/models/estimated-index.interface';
import { HttpParams } from '@angular/common/http';
import { DashboardViewModel } from '@app/modules/customer-zone/dashboard/pages/dashboard/dashboard.component';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Advance } from '@app/modules/customer-zone/billing/models/advance.interface';
import { AdvancesService } from '@app/modules/customer-zone/billing/services/advances/advances.service';
import { AnalyticsTrackingService } from '@app/core/analytics/tracking/analytics-tracking.service';
import { ScreenSizeService } from '@app/core/service/screen-size.service';
import { DeliveryPointService } from '@app/modules/customer-zone/consumption/services/deliveryPoint/delivery-point.service';
import { Direction, Meter } from '@app/modules/customer-zone/consumption/models/deliveryPoint.interface';
import { MeteringsService } from '@app/modules/customer-zone/consumption/services/meterings/meterings.service';
import {
  ConsumptionRequestData,
  CrossSell,
  EnergyType,
  Index,
  SelfConsumptionRequestData,
  Usage,
  UsageRequestData,
  Volume,
  VolumeRequestData,
} from '@app/modules/customer-zone/consumption/models/consumption.interface';
import {
  AccessRights,
  DeliveryPoint,
  EliqAccessRights,
  MyEnergyActionsPerEAN,
  Profile,
} from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { TevcMonitoringService } from '@app/modules/customer-zone/tevc-monitoring/services/tevc-monitoring.service';
import { ChargingStation } from '@app/modules/customer-zone/tevc-monitoring/models/charging-station.interface';
import { BuildingService } from '@app/modules/customer-zone/building/services/building/building.service';
import { Building } from '@app/modules/customer-zone/building/models/building.interface';
import { ConsentService } from '@app/modules/customer-zone/user/services/consent/consent.service';
import { Consent, PersonalConsent } from '@app/modules/customer-zone/user/models/consents.interface';
import {
  HomeProfile,
  HomeProfileProvider,
  HomeProfileService,
  HomeProfileUpdate,
} from '@app/modules/customer-zone/consumption/services/home-profile/home-profile.service';
import { ProductSwapPayload, ProductSwapService } from '@app/shared/services/product-swap/product-swap.service';
import { EliqAccessService } from '@app/modules/customer-zone/consumption/services/eliq-access/eliq-access.service';
import { EnergyUsageCategoriesService } from '@app/modules/customer-zone/consumption/services/energy-usage-categories/energy-usage-categories.service';
import { CrossSellService } from '@app/modules/customer-zone/consumption/services/cross-sell/cross-sell.service';
import { MandatesService } from '@app/modules/customer-zone/consumption/services/mandates/mandates.service';
import {
  ActivateMandateBody,
  ActivateMandateResponse,
  Mandate,
  MandateDataType,
} from '@app/modules/customer-zone/consumption/models/mandates.interface';
import { Paths } from '@app/core/models/paths';
import { AdviceService, AdviceStatus } from '@app/modules/customer-zone/consumption/services/advice/advice.service';
import { VolumesService } from '@app/modules/customer-zone/consumption/services/volumes/volumes.service';
import { DeviceOrigin } from '@app/core/models/device';
import jwt_decode from 'jwt-decode';
import { AppConfig } from '@app/shared/utils/app-config';
import { LoaderService } from '@app/shared/modules/loader/services/loader.service';
import { ContractDtoCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Injectable({
  providedIn: 'root',
})
export class MainFacade {
  readonly state$: BehaviorSubject<State> = new BehaviorSubject<State>(INITIAL_STATE);
  readonly userProfile$: BehaviorSubject<UserProfile> = new BehaviorSubject(null);

  readonly reference$: Observable<string> = this.state$.pipe(
    map((state: State) => state.reference),
    distinctUntilChanged()
  );
  readonly sites$: Observable<Site[]> = this.state$.pipe(
    map((state: State) => state.sites),
    distinctUntilChanged()
  );
  readonly activeSite$: Observable<Site> = this.state$.pipe(
    map((state: State) => state.activeSite),
    distinctUntilChanged()
  );
  readonly computedData$: Observable<ComputedData> = this.state$.pipe(
    map((state: State) => state.computedData),
    filter((computedData) => !!computedData),
    distinctUntilChanged()
  );
  readonly accessRights$: Observable<AccessRights> = this.state$.pipe(
    map((state: State) => state.accessRights),
    distinctUntilChanged()
  );
  readonly eliqAccessRights$: Observable<EliqAccessRights> = this.state$.pipe(
    map((state: State) => state.eliqAccessRights),
    distinctUntilChanged()
  );
  contracts$: Observable<ContractDtoCuzoApi[]>;

  constructor(
    private keycloakService: KeycloakService,
    private userService: UserService,
    private referenceService: ReferenceService,
    private utilsService: UtilsService,
    private siteService: SiteService,
    private contractService: ContractService,
    private contactService: ContactService,
    private estimatedIndexService: EstimatedIndexService,
    private billingService: BillingService,
    private advancesService: AdvancesService,
    private invoiceService: InvoiceService,
    private contentService: ContentService,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private analyticsService: AnalyticsTrackingService,
    private screenSizeService: ScreenSizeService,
    private deliveryPointService: DeliveryPointService,
    private meteringsService: MeteringsService,
    private tevcMonitoringService: TevcMonitoringService,
    private buildingService: BuildingService,
    private consentService: ConsentService,
    private homeProfileService: HomeProfileService,
    private productSwapService: ProductSwapService,
    private mandatesService: MandatesService,
    private eliqAccessRights: EliqAccessService,
    private energyUsageService: EnergyUsageCategoriesService,
    private crossSellService: CrossSellService,
    private adviceService: AdviceService,
    private volumesService: VolumesService,
    private loaderService: LoaderService,
    private router: Router,
    private conf: AppConfig
  ) {
    this.reference$
      .pipe(
        filter((reference: string) => !!reference),
        switchMap((reference: string) => {
          if (router.url.includes('error')) {
            this.utils.redirectTo(Paths.root);
          }
          return forkJoin([this.loadSites(reference), this.loadContent(reference)]).pipe(
            map(([sites, content]: any) => ({ sites, content })),
            catchError((error) => {
              this.accessErrorHandler(error);
              return of({ sites: null, content: null });
            })
          );
        })
      )
      .subscribe(({ sites, content }) => {
        if (!sites || !content) {
          return;
        }
        const [activeSite] = sites; //@todo: Get Active Site
        this.setState(
          (currentState: State): State => ({
            ...currentState,
            sites,
            activeSite,
            content,
          })
        );
      });

    this.activeSite$
      .pipe(filter((activeSite: Site) => !!activeSite || !!this.state$?.value?.accessRights))
      .subscribe((activeSite: Site) => {
        const hasSolarPanel = activeSite?.hasSolarPanel;
        const computedData = { ...this.state$.value.computedData, hasSolarPanel };
        this.contracts$ = this.loadContracts(this.state$.value.reference, this.state$.value.activeSite);
        this.setState(
          (currentState: State): State => ({
            ...currentState,
            computedData: { ...computedData },
          })
        );
      });
  }

  get utils() {
    return this.utilsService;
  }

  get proxyRouter() {
    return this.router;
  }

  get translate() {
    return this.translateService;
  }

  get analytics() {
    return this.analyticsService;
  }

  get screenSize() {
    return this.screenSizeService;
  }

  loadUserProfile(origin: DeviceOrigin = DeviceOrigin.web): Observable<UserProfile> | Observable<boolean> {
    let token = null;
    if (origin === DeviceOrigin.mobileApp) {
      token = jwt_decode(this.utils.getCookieValue('KEYCLOAK'));
    } else {
      token = this.keycloakService.getKeycloakInstance()?.tokenParsed;
    }
    if (!token) {
      return of(false);
    }
    return this.userService.getUserProfile(token?.sub).pipe(
      tap((up: UserProfile) => {
        const activeReference = this.setUserInformations(up, true);
        this.updateActiveReference(activeReference);
        this.userProfile$.next(up);
      }),
      catchError((error) => this.accessErrorHandler(error))
    ) as Observable<UserProfile>;
  }

  updateAccessRights(reference: string): Observable<any> {
    return this.userService.getAccessRights(reference).pipe(
      tap((accessRights) => {
        this.setState(
          (currentState: State): State => ({
            ...currentState,
            accessRights,
          })
        );
      }),
      catchError((error) => this.accessErrorHandler(error))
    );
  }

  updateActiveReference(reference: string): void {
    if (reference === this.state$.value.reference) {
      return;
    }
    this.referenceService.updateActiveReference(reference);
    this.setState(() => ({ reference, computedData: {} }));
  }

  updateActiveSite(newSite: Site | string): void {
    this.setState((currentState: State): State => {
      const activeSite: Site = (
        typeof newSite === 'string' ? currentState.sites.find((site: Site) => site.id === newSite) : newSite
      ) as Site;
      return { ...currentState, activeSite };
    });
  }

  updateContent(reference) {
    return this.loadContent(reference).pipe(
      tap((content) => {
        this.setState(
          (currentState: State): State => ({
            ...currentState,
            content,
          })
        );
      })
    );
  }

  loadSites(reference: string = null): Observable<Site[]> {
    reference = reference || this.state$.value.reference;
    return this.siteService.getSites(reference).pipe(catchError((error) => throwError(error)));
  }

  loadContracts(
    reference: string = null,
    site: Site = null,
    formulaPrice: boolean = false
  ): Observable<ContractDtoCuzoApi[]> {
    reference = reference || this.state$.value.reference;
    site = site || this.state$.value.activeSite;
    return this.contractService.getContracts(reference, site?.id, formulaPrice);
  }

  loadBuildingInfo(reference: string = null, site: Site = null): Observable<Building> {
    reference = reference || this.state$.value.reference;
    site = site || this.state$.value.activeSite;
    return this.buildingService.getBuilding(reference, site?.id);
  }

  loadServices(reference: string = null, site: Site = null, loader: boolean = true): Observable<ServiceEligibility[]> {
    reference = reference || this.state$.value.reference;
    site = site || this.state$.value.activeSite;
    return this.contractService.getServices(reference, site?.id, loader);
  }

  loadContactDetails(reference: string = null): Observable<ContactDetails> {
    reference = reference || this.state$.value.reference;
    return this.contactService.getContactDetails(reference);
  }

  loadBillingDetails(reference: string = null, loader: boolean = true): Observable<BillingDetails> {
    reference = reference || this.state$.value.reference;
    return this.billingService.getBillingDetails(reference, loader);
  }

  loadAdvances(reference: string = null, site: Site = null, loader: boolean = false): Observable<Advance[]> {
    reference = reference || this.state$.value.reference;
    site = site || this.state$.value.activeSite;
    return this.advancesService.getAdvances(reference, site?.id, loader);
  }

  updateAdviceStatus(
    reference: string = null,
    site: Site = null,
    adviceId: string,
    body: any,
    loader = false
  ): Observable<any> {
    reference = reference || this.state$.value.reference;
    site = site || this.state$.value.activeSite;
    return this.adviceService
      .patchAdviceStatus(reference, site?.id, adviceId, body, loader)
      .pipe(catchError((e) => throwError(e)));
  }

  loadAdvice(reference: string = null, site: Site = null, status = null, loader: boolean = true): Observable<any> {
    reference = reference || this.state$.value.reference;
    site = site || this.state$.value.activeSite;
    return this.adviceService.getAdvice(reference, site?.id, status, loader).pipe(
      shareReplay(1),
      catchError((e) => throwError(e))
    );
  }

  isSynchronisingEliqData(reference: string = null, site: Site = null, loader: boolean = true): Observable<boolean> {
    reference = reference || this.state$.value.reference;
    site = site || this.state$.value.activeSite;

    return this.eliqAccessRights$.pipe(
      filter((eliqAccessRights: EliqAccessRights) => !!eliqAccessRights),
      switchMap((eliqAccessRights: EliqAccessRights) => {
        const isEliqEligible: boolean =
          eliqAccessRights.accessPerSiteId.eliqEligible &&
          eliqAccessRights.accessPerSiteId.isEnergyInsightFeatureEligible;

        const profileByDeliveryPointReference: Profile[] = Object.values(
          eliqAccessRights.profileByDeliveryPointReference
        );
        const isSmartAndFluvius: boolean = profileByDeliveryPointReference?.some(
          (profile) => profile.fluvius && profile.smart
        );

        return this.loadAdvice(reference, site, AdviceStatus.none, loader).pipe(
          shareReplay(1),
          catchError((error) => of(error?.status !== 428 && isEliqEligible && isSmartAndFluvius)),
          map((isSyncEliqData): boolean => isSyncEliqData === true)
        );
      })
    );
  }

  loadEstimatedIndexes(reference: string = null): Observable<EstimatedIndex[]> {
    reference = reference || this.state$.value.reference;
    return this.estimatedIndexService.getEstimatedIndexes(reference);
  }

  loadContent(reference: string = null): Observable<Content> {
    reference = reference || this.state$.value.reference;
    return this.contentService.getContent(reference).pipe(
      catchError(() => of(null)),
      shareReplay(1)
    );
  }

  loadChargingStations(reference: string = null): Observable<any> {
    reference = reference || this.state$.value.reference;
    return this.tevcMonitoringService.getChargingStations(reference).pipe(
      tap((response: ChargingStation[]) => {
        const computedData = { ...this.state$.value.computedData, hasChargingStations: response.length > 0 };
        this.setState((currentState: State): State => ({ ...currentState, computedData }));
      }),
      catchError(() => of(null))
    );
  }

  loadConsents(isBusiness: boolean): Observable<Consent[]> {
    return this.consentService.getTypeConsents(isBusiness);
  }

  loadPersonalConsents(reference: string = null): Observable<PersonalConsent[]> {
    reference = reference || this.state$.value.reference;
    return this.consentService.getPersonalConsents(reference);
  }

  loadMandates(reference: string = null): Observable<Mandate[]> {
    reference = reference || this.state$.value.reference;
    return this.mandatesService.getMandates(reference).pipe(shareReplay(1));
  }

  ignoreLoadingForSingleRequest(value: boolean): void {
    this.loaderService.ignoreLoadingForSingleRequest = value;
  }

  getShortLinkMandateActivation(
    reference: string = null,
    body: ActivateMandateBody = null
  ): Observable<ActivateMandateResponse> {
    reference = reference || this.state$.value.reference;
    body = body || {
      dataServices: [
        {
          dataServiceType: MandateDataType.quarterHour2,
        },
        {
          dataServiceType: MandateDataType.day,
        },
      ],
      flow: 'B2C',
      referenceNumber: reference,
    };
    return this.mandatesService.postActivateMandateShortlink(reference, body).pipe(
      shareReplay(1),
      map((activateMandateResponse: ActivateMandateResponse) => ({
        ...activateMandateResponse,
        fluviusUrl: `${this.conf.config.fluviusMandateFlow}?id=${activateMandateResponse?.shortUrlIdentifier}`,
      }))
    );
  }

  downloadPersonalData(reference: string = null, params: HttpParams) {
    reference = reference || this.state$.value.reference;
    return this.consentService.getPersonalData(reference, params);
  }

  loadReferencesDetails(): Observable<ReferenceDetails[]> {
    return this.referenceService.getReferencesDetails();
  }

  loadEnergyUsage(requestData: UsageRequestData, loader: boolean = true): Observable<Usage> {
    requestData.reference = requestData.reference || this.state$.value.reference;
    requestData.siteId = requestData.siteId || this.state$.value.activeSite?.id;
    return this.energyUsageService.getEnergyUsage(requestData, loader);
  }

  loadVolumes(requestData: VolumeRequestData): Observable<Volume[]> {
    requestData.reference = requestData.reference || this.state$.value.reference;
    requestData.siteId = requestData.siteId || this.state$.value.activeSite?.id;
    return this.volumesService.getVolumes(requestData);
  }

  loadVolumesDetails(requestData: ConsumptionRequestData): Observable<Volume[]> {
    requestData.reference = requestData.reference || this.state$.value.reference;
    requestData.siteId = requestData.siteId || this.state$.value.activeSite?.id;
    return this.volumesService.getVolumesDetails(requestData);
  }

  loadSelfConsumption(requestData: SelfConsumptionRequestData): Observable<any> {
    requestData.reference = requestData.reference || this.state$.value.reference;
    requestData.siteId = requestData.siteId || this.state$.value.activeSite?.id;
    return this.volumesService.getSelfConsumption(requestData);
  }

  loadMeterings(
    reference: string = null,
    site: Site = null,
    energyType: EnergyType,
    deliveryPoint: string,
    loader: boolean = true,
    flush: boolean = false
  ): Observable<Index[]> {
    reference = reference || this.state$.value.reference;
    site = site || this.state$.value.activeSite;
    return this.meteringsService
      .getMeterings(reference, site?.id, energyType, deliveryPoint, loader, flush)
      .pipe(shareReplay(1));
  }

  loadAllMeterings(): Observable<Index[][]> {
    return this.contracts$.pipe(
      filter((contracts: ContractDtoCuzoApi[]) => !!contracts),
      switchMap((contracts: ContractDtoCuzoApi[]) =>
        forkJoin(
          contracts?.map((contract: ContractDtoCuzoApi) =>
            this.loadMeterings(
              this.state$.value.reference,
              this.state$.value.activeSite,
              contract?.type as unknown as EnergyType,
              contract?.deliveryPointReference
            )
          )
        )
      )
    );
  }

  fetchHomeProfileData(
    reference: string = null,
    site: Site = null,
    reset: boolean = false,
    loader: boolean = true
  ): Observable<{ completion: number; data: HomeProfile }> {
    reference = reference || this.state$.value.reference;
    site = site || this.state$.value.activeSite;
    return forkJoin([
      this.getHomeProfile(reference, site, reset, loader),
      this.getHomeProfileCompletion(reference, site, reset, HomeProfileProvider.energyInsight, loader),
    ]).pipe(
      take(1),
      map(([data, completion]) => ({ data, completion })),
      shareReplay(1)
    );
  }

  bustCrossSellCaching(reference: string = null): void {
    reference = reference || this.state$.value.reference;
    this.crossSellService.bustCaching(reference);
  }

  bustEnergyUsageCaching(reference: string = null) {
    reference = reference || this.state$.value.reference;
    return this.energyUsageService.resetEnergyUsageCache(reference);
  }

  getCrossSell(reference: string, siteId: string, loader: boolean = true): Observable<CrossSell> {
    reference = reference || this.state$.value.reference;
    siteId = siteId || this.state$.value.activeSite?.id;
    return this.crossSellService.getCrossSell(reference, siteId, loader);
  }

  getHomeProfile(
    reference: string = null,
    site: Site = null,
    reset: boolean = false,
    loader = true
  ): Observable<HomeProfile> {
    reference = reference || this.state$.value.reference;
    site = site || this.state$.value.activeSite;
    return this.homeProfileService.getHomeProfile(reference, site, reset, loader);
  }

  getHomeProfileCompletion(
    reference: string = null,
    site: Site = null,
    refreshValue = false,
    provider: HomeProfileProvider = HomeProfileProvider.energyInsight,
    loader: boolean = true
  ): Observable<number> {
    reference = reference || this.state$.value.reference;
    site = site || this.state$.value.activeSite;

    return this.homeProfileService
      .getHomeProfileCompletion(reference, site, provider, refreshValue, loader)
      .pipe(catchError(() => of(null)));
  }

  updateHomeProfile(payload: HomeProfileUpdate[], reference: string = null, site: Site = null, loader: boolean = true) {
    reference = reference || this.state$.value.reference;
    site = site || this.state$.value.activeSite;
    return this.homeProfileService.postHomeProfileToAPI(reference, site?.id, payload, loader);
  }

  loadEliqAccessRights(reference: string = null, site: Site = null, loader = true): Observable<EliqAccessRights> {
    reference = reference || this.state$.value.reference;
    site = site || this.state$.value.activeSite;
    return this.eliqAccessRights.getEliqAccessRights(reference, site?.id, loader).pipe(
      tap((eliqAccessRights: EliqAccessRights) =>
        this.setState((currentState: State): State => ({ ...currentState, eliqAccessRights }))
      ),
      shareReplay(1)
    );
  }

  loadEliqFeatureBannerAccessRight(reference: string = null, site: Site = null): Observable<boolean> {
    reference = reference || this.state$.value.reference;
    site = site || this.state$.value.activeSite;
    return this.eliqAccessRights.getEliqFeatureBannerAccessRight(reference, site?.id).pipe(shareReplay(1));
  }

  loadDeliveryPoint(
    reference: string = null,
    site: Site = null,
    contract: ContractDtoCuzoApi,
    loader: boolean = true
  ): Observable<Meter[]> {
    reference = reference || this.state$.value.reference;
    site = site || this.state$.value.activeSite;
    return this.deliveryPointService.getDeliveryPoint(
      reference,
      site?.id,
      contract?.type,
      contract?.deliveryPointReference
    );
  }

  remodelDeliveryPointResponse(meter: Meter[], contract): DeliveryPoint {
    const [firstMeter] = meter; // line 129 user-type-resolver: Only get first meter - WHY?
    const deliveryPoint: DeliveryPoint = {
      energy: contract.type as EnergyType,
      reference: contract.deliveryPointReference,
      meterNumber: firstMeter.meterNumber,
      smartMeter: firstMeter.smartMeter,
    };
    if (contract.type === EnergyType.ELECTRICITY) {
      deliveryPoint['injection'] =
        firstMeter.registers?.find((register) =>
          [Direction.injection, Direction.production].includes(register.direction)
        ) !== undefined;
    }
    return deliveryPoint;
  }

  loadMyEnergyActions(reference: string, site: Site, deliveryPoints: string[]): Observable<MyEnergyActionsPerEAN> {
    reference = reference || this.state$.value.reference;
    site = site || this.state$.value.activeSite;
    return this.advancesService.getCanChangeInstallment(reference, site?.id, deliveryPoints, false);
  }

  swapProduct(reference: string, payload: ProductSwapPayload): Observable<void> {
    reference = reference || this.state$.value.reference;
    return this.productSwapService.triggerProductSwap(reference, payload);
  }

  deleteReference(reference: string): Observable<UserProfile> {
    const keycloakUserId: string = this.keycloakService.getKeycloakInstance().tokenParsed.sub;
    return this.referenceService.unbindReferenceFromApi(keycloakUserId, reference).pipe(
      switchMap(() => {
        this.userService.resetUserProfile();
        return this.loadUserProfile();
      })
    );
  }

  renameReference(reference: string, body: { label: string }): Observable<UserProfile | boolean> {
    const keycloakUserId: string = this.keycloakService.getKeycloakInstance().tokenParsed.sub;
    return this.referenceService.renameReferenceFromApi(keycloakUserId, reference, body).pipe(
      switchMap(() => {
        this.userService.resetUserProfile();
        return this.loadUserProfile();
      })
    );
  }

  public setUserInformations(userProfile: UserProfile, redirect: boolean): string | null {
    const references = userProfile.customers.sort((a, b) => (a.reference < b.reference ? 1 : -1));
    if (references.length === 0) {
      this.newBindingError();
    }
    this.checkCookieContentValidity(references);
    if (redirect && references.length === 0) {
      this.redirectToBinding();
      return;
    }
    return this.referenceService.updateReferencesDetails(references);
  }

  getDashboardViewModel(): DashboardViewModel {
    return {
      reference: this.state$.value.reference,
      accessRights: this.state$.value.accessRights,
      computedData$: this.computedData$,
      sites$: this.sites$,
      activeSite$: this.activeSite$,
      contracts$: this.contracts$,
      services$: this.state$.value.accessRights?.viewUpsell ? this.loadServices() : of(null),
      contact$: this.loadContactDetails(),
      estimatedIndex$: this.loadEstimatedIndexes(),
      userInfo$: this.userService.getUserInfo(),
    };
  }

  private checkCookieContentValidity(referencesDetails: ReferenceDetails[]) {
    const references = referencesDetails.map((referenceDetail: ReferenceDetails) => referenceDetail.reference);
    const cookieName: string = this.utilsService.activeInfoCookieName;
    const cookie: any = this.utilsService.getCookieValue(cookieName);
    if (cookie && cookie.activeRef) {
      if (cookie.activeSitePerRef) {
        // Reset if number of valid references !== number of activeSitePerRef set
        let cookieToBeReset: boolean = references.length !== Object.keys(cookie.activeSitePerRef).length;
        Object.keys(cookie.activeSitePerRef).forEach((referenceFromCookie) => {
          if (!references.includes(referenceFromCookie)) {
            cookieToBeReset = true;
          }
        });
        // Some references have been deleted/updated -> reset cookie
        if (cookieToBeReset) {
          this.utilsService.deleteCookie(cookieName);
        }
      }

      const activeReference = references.find((reference: string) => reference === cookie.activeRef);
      // Active Reference has been deleted or is invalid after binding -> update it
      if (activeReference === undefined) {
        this.referenceService.updateActiveReference(null);
        // In case of new binding -> updating success alert to error
        if (references.length > 0) {
          this.newBindingError();
        }
      }
    }
  }

  private redirectToBinding() {
    this.router.navigate([`/references/add`]);
  }

  private newBindingError() {
    if (this.comesFromBindingProcess()) {
      this.toastrService.error(this.translate.instant('pages.bindingProcess.step3.refAdditionError'));
      window.localStorage.removeItem('binding');
    }
  }

  private comesFromBindingProcess() {
    return window.localStorage.getItem('binding');
  }

  private accessErrorHandler(error): Observable<boolean> {
    //@todo: Implement userProfile retry (ie: slow binding)
    if (error?.status === 500 && this.utilsService.isMissingKeycloakAccountInfo()) {
      this.utilsService.redirectToKeycloakAccountPage();
    } else if ((error?.status === 404 || error?.status === 412) && error?.url?.includes('userprofiles')) {
      this.redirectToBinding();
    } else {
      this.utilsService.redirectToErrorPage(error.status);
    }
    return of(false);
  }

  private setState(updateFn: (currentState: State) => State): void {
    const newState = updateFn(this.state$.value);
    this.state$.next(newState);
  }
}
