<div [formGroup]="dreFormGroup">
  <h3 class="text-2xl font-medium text-grey-dark mt-12">Document de reprise d'énergie</h3>
  <div>
    <p class="form-element input-radio-big errors mt-8">
      <input type="radio" id="doc-completed" formControlName="dreStatus" [value]="UploadDocumentParamsDreCompletionStatusEnumCuzoApi.FULL">
      <label for="doc-completed">
        <div class="flex items-center">
          <div class="sm:pr-6 max-w-full parent">
            J'ai un document de reprise des énergies <strong>complet</strong>
            <span class="inline-block w-full text-sm text-grey-dark60/60">
                  Signé par les deux parties et entièrement complété
                </span>
            <div class="content">
              <app-input-file-upload
                [control]="fileControl"
                [accept]="validExtensions"
                [fileSize]="maxFileSizeMB"
                [fileName]="fileName"
                (fileEmitter)="onFileSelection($event)"
              >
              </app-input-file-upload>
            </div>
          </div>
        </div>
      </label>
    </p>
    <p class="form-element input-radio-big errors mt-8">
      <input type="radio" id="doc-incomplete" formControlName="dreStatus" [value]="UploadDocumentParamsDreCompletionStatusEnumCuzoApi.PARTIAL">
      <label for="doc-incomplete">
        <div class="flex items-center">
          <div class="sm:pr-6 max-w-full parent">
            J'ai un document de reprise d'énergie <strong>incomplet</strong>
            <div class="content">
              <app-input-file-upload
                [control]="fileControl"
                [accept]="validExtensions"
                [fileSize]="maxFileSizeMB"
                [fileName]="fileName"
                (fileEmitter)="onFileSelection($event)"
              >
              </app-input-file-upload>
            </div>
          </div>
        </div>
      </label>
    </p>
    <p class="form-element input-radio-big errors mt-8">
      <input type="radio" id="no-doc" formControlName="dreStatus" [value]="UploadDocumentParamsDreCompletionStatusEnumCuzoApi.NONE">
      <label for="no-doc">
        <div class="flex items-center">
          <div class="sm:pr-6 max-w-full">
            Je n'ai pas de document de reprise des énergies
          </div>
        </div>
      </label>
    </p>
  </div>
  <app-alert
    class="inline-block mt-16 w-full"
    [flatDesign]="true"
    [alert]="{
          type: AlertType.info,
          content: {
            message: 'Le document de reprise des énergies est un formulaire à compléter lorsqu\'il y a un changement de locataire au sein d\'un logement. Vous pouvez télécharger un document de reprise d\’énergies vierge pour le compléter',
          },
        }"
  ></app-alert>
</div>
