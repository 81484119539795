import { Component, OnDestroy, OnInit } from '@angular/core';
import { MainFacade } from '@app/core/facade/main.facade';
import {
  catchError,
  combineLatest,
  filter,
  forkJoin,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';
import { Site } from '@app/modules/customer-zone/user/models/site.interface';
import { VersionSize } from '@app/shared/models/units.interface';
import {
  DeliveryPoint,
  EliqAccessRights,
  Profile,
} from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { MeterConfig } from '@app/shared/models/meter.interface';
import { Mandate } from '@app/modules/customer-zone/consumption/models/mandates.interface';
import {
  CrossSell,
  CrossSellType,
  EnergyType,
} from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { UtilsService } from '@app/shared/utils/utils.service';
import * as moment from 'moment';
import { AccessRightsCuzoApi, ContractDtoCuzoApi } from '@app/shared/models/cuzo-be-contract';

enum EliqCookieName {
  firstLoginGuide = 'eliq_firstLoginGuideRefs',
}

interface ContractsAndAccess {
  contracts: ContractDtoCuzoApi[];
  eliqAccessRights: EliqAccessRights;
}

interface WidgetDisplaying {
  disaggregation?: boolean;
  comparisonElectricity?: boolean;
  comparisonGas?: boolean;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './consumption-dashboard.component.html',
  styleUrls: ['./consumption-dashboard.component.scss'],
})
export class ConsumptionDashboardComponent implements OnInit, OnDestroy {
  readonly CrossSellType = CrossSellType;
  reference: string;
  activeSite: Site;
  contracts: ContractDtoCuzoApi[];
  eliqAccessRights: EliqAccessRights;
  sites$: Observable<Site[]>;
  currentMeterConfig: MeterConfig;
  deliveryPoints: DeliveryPoint[];
  showGuide: boolean;
  homeProfileCompletion: number;
  mandates$: Observable<Mandate[]>;
  crossSells: [string, boolean][] = [];
  widgetDisplaying: WidgetDisplaying = {};
  showDashboard: boolean = false;
  isSyncEliqData: boolean = false;
  showMeterReadings: boolean = false;

  protected readonly versionSize = VersionSize;
  protected readonly meterConfig = MeterConfig;
  protected readonly energyType = EnergyType;
  private notifier: Subject<void> = new Subject();

  constructor(public facade: MainFacade, private readonly utilsService: UtilsService) {}

  ngOnInit(): void {
    this.facade.utils.setPageTitle('pages.consumption.pageTitle');
    combineLatest([this.facade.accessRights$, this.facade.activeSite$])
      .pipe(
        tap(() => {
          this.showDashboard = false;
          this.showMeterReadings = false;
        }),
        filter(([accessRights, site]: [AccessRightsCuzoApi, Site]) => {
          if (accessRights?.accessEnergyInsights && site) {
            this.showMeterReadings = false;
          } else if (!accessRights?.accessEnergyInsights && site) {
            this.showMeterReadings = true;
          } else {
            return false;
          }
          return !!accessRights?.accessEnergyInsights;
        }),
        switchMap(() => {
          return this.facade.eliqAccessRights$;
        }),
        filter((eliqAccessRights: EliqAccessRights) => !!eliqAccessRights),
        takeUntil(this.notifier),
        switchMap((eliqAccessRights: EliqAccessRights) => {
          if (
            !eliqAccessRights?.widgetAccessPerDeliveryPointReference ||
            eliqAccessRights?.widgetAccessPerDeliveryPointReference.length === 0 ||
            Object.keys(eliqAccessRights?.accessPerSiteId).length === 0
          ) {
            return throwError('NO DATA');
          }
          return this.facade.isSynchronisingEliqData().pipe(
            map((isSyncEliqData: boolean) => {
              this.isSyncEliqData = isSyncEliqData;
              return { eliqAccessRights };
            })
          );
        }),
        switchMap(({ eliqAccessRights }: any) => {
          return this.facade.contracts$.pipe(
            filter((contracts: ContractDtoCuzoApi[]) => !!contracts),
            map((contracts: ContractDtoCuzoApi[]): ContractsAndAccess => ({ eliqAccessRights, contracts }))
          );
        }),
        switchMap(({ eliqAccessRights, contracts }: ContractsAndAccess): Observable<[number | null, CrossSell]> => {
          this.analytics();
          this.eliqAccessRights = eliqAccessRights;
          this.widgetDisplaying.disaggregation = this.eliqAccessRights.widgetAccessPerDeliveryPointReference.some(
            ({ access }) => access.seeWidgetDisagreement
          );

          this.reference = this.facade.state$.value.reference;
          this.activeSite = this.facade.state$.value.activeSite;
          this.contracts = contracts;
          this.sites$ = this.facade.sites$;
          this.deliveryPoints = contracts.map(
            (contract: ContractDtoCuzoApi) =>
              <DeliveryPoint>{
                energy: contract.type as unknown as EnergyType,
                reference: contract.deliveryPointReference,
              }
          );
          this.widgetDisplaying.comparisonGas =
            this.deliveryPoints.findIndex(({ energy }) => energy === this.energyType.GAS) >= 0;
          this.widgetDisplaying.comparisonElectricity =
            this.deliveryPoints.findIndex(({ energy }) => energy === this.energyType.ELECTRICITY) >= 0;

          const profile: Profile = this.facade.utils.getSiteProfile(eliqAccessRights, contracts);
          this.currentMeterConfig = this.facade.utils.getMeterConfig(profile);
          this.mandates$ = profile?.smart && profile?.fluvius ? this.facade.loadMandates(this.reference) : of(null);

          return forkJoin([
            eliqAccessRights?.accessPerSiteId?.eliqEligible
              ? this.facade.getHomeProfileCompletion(this.reference, this.activeSite, false)
              : of(null),
            this.facade.getCrossSell(this.reference, this.activeSite?.id, true).pipe(catchError(() => of(null))),
          ]);
        })
      )
      .subscribe({
        next: ([completion, crossSell]: [number | null, CrossSell]) => {
          this.showDashboard = true;
          this.homeProfileCompletion = completion;
          this.showGuide =
            !this.isSyncEliqData &&
            !this.utilsService.getCookieValue(EliqCookieName.firstLoginGuide, this.reference) && // don't show the guide for reference which exist in cookies
            this.eliqAccessRights?.accessPerSiteId?.eliqEligible &&
            (completion === null || completion === 0); // show the guide for empty completion only

          if (crossSell) {
            this.crossSells = Object.entries(crossSell);
            this.crossSells = this.crossSells.filter(([key, value]) => value); // remove false
            this.crossSells =
              this.currentMeterConfig === MeterConfig.solar
                ? this.crossSells.filter((_, i) => i === 0)
                : this.crossSells;
          }
        },
        error: (e) => {
          this.facade.utils.redirectTo('/consumptions/error-access');
        },
      });
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }

  switchSite(site: string) {
    this.facade.updateActiveSite(site);
  }

  closeGuide(): void {
    this.showGuide = false;
    this.setEliqCookie(EliqCookieName.firstLoginGuide);
  }

  private setEliqCookie(cookieName: EliqCookieName): void {
    const references = this.utilsService.getCookieValue(EliqCookieName.firstLoginGuide) || {};
    references[this.reference] = 1;
    this.utilsService.setCookieValue(cookieName, JSON.stringify(references), moment().add(1, 'years').toDate());
  }

  private analytics(): void {
    this.facade.analytics.push(
      {
        event: 'pageview',
        page: {
          phase: 'care',
          category: 'cuzo',
          subCategory: 'home profile - cuzo',
          path: '/consumption/overview',
        },
      },
      {
        reference: this.facade?.state$?.value?.reference,
        site: this.facade?.state$?.value?.activeSite,
        accessRights: this.facade?.state$?.value?.accessRights,
        eliqAccessRights: this.facade?.state$?.value?.eliqAccessRights,
        contracts: this.facade?.state$?.value?.contracts,
      }
    );
  }
}
