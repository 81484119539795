import { Pipe, PipeTransform } from '@angular/core';
import { ContractDtoCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Pipe({
  name: 'energyFilter',
})
export class EnergyFilterPipe implements PipeTransform {
  transform(contracts: ContractDtoCuzoApi[], energy: string): ContractDtoCuzoApi[] {
    return contracts.filter((contract) => contract.type === energy);
  }
}
