import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { filter, forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { MainFacade } from '@app/core/facade/main.facade';

@Injectable({
  providedIn: 'root',
})
export class DataResolver {
  constructor(private facade: MainFacade) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (!this.facade.userProfile$.value) {
      return of(true);
    }

    this.facade.reference$
      .pipe(
        filter((reference: string) => !!reference),
        switchMap((reference: string) => this.facade.updateContent(reference))
      )
      .subscribe();

    // @todo: To remove -> This has to be implemented into /access-rights
    this.facade.loadChargingStations(this.facade.state$.value.reference).subscribe();

    return of(true);
  }
}
