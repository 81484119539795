import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { TevcMonitoringService } from '../../services/tevc-monitoring.service';
import { Observable } from 'rxjs/internal/Observable';
import {
  ChargingStation,
  ChargingStationSession,
  ChargingStationStatus,
} from '../../models/charging-station.interface';
import { takeUntil } from 'rxjs';
import { filter } from 'rxjs';
import { formatDate } from '@angular/common';
import { MainFacade } from '@app/core/facade/main.facade';

@Component({
  selector: 'app-tevc-dashboard',
  templateUrl: './tevc-dashboard.component.html',
  styleUrls: ['./tevc-dashboard.component.scss'],
})
export class TevcDashboardComponent implements OnInit, OnDestroy {
  public chargingStations$: Observable<ChargingStation[] | null> = this.monitoringService.getChargingStations$();
  public chargingStation$: BehaviorSubject<ChargingStation> = this.monitoringService.getChargingStation$();
  public chargingStationStatus$: Observable<ChargingStationStatus> = this.monitoringService.getChargingStationStatus$();
  public chargingStationSessions$: Observable<ChargingStationSession[]> =
    this.monitoringService.getChargingStationSessions$();

  chargingStationName: string;

  private reference: string;
  private locale: string;
  private notifier$: Subject<void> = new Subject();

  constructor(private facade: MainFacade, private monitoringService: TevcMonitoringService) {}

  ngOnDestroy(): void {
    this.notifier$.next();
    this.notifier$.complete();
  }

  ngOnInit(): void {
    this.analytics();
    this.facade.utils.setPageTitle('pages.tevcMonitoring.dashboard.pageTitle');

    this.facade.reference$.pipe(takeUntil(this.notifier$)).subscribe((reference: string) => {
      this.reference = reference;
      this.monitoringService.getChargingStations(reference);
    });

    this.locale = this.facade.translate.currentLang + '-BE';
    this.facade.translate.onLangChange.pipe(takeUntil(this.notifier$)).subscribe((value) => {
      this.locale = value.lang + '-BE';
    });

    this.chargingStation$
      .pipe(
        filter((value) => value !== null),
        takeUntil(this.notifier$)
      )
      .subscribe((value) => {
        if (!this.reference) {
          return;
        }
        this.chargingStationName = value.name;
        this.monitoringService.getChargingStationStatus(this.reference, value.name);
      });
  }

  changeChargingStation(station: ChargingStation) {
    this.monitoringService.getChargingStation(station.name);
  }

  selectedRangeChanged({ selectedDateFrom, selectedDateTo }): void {
    if (!this.reference || !this.chargingStationName) {
      return;
    }
    this.monitoringService.getChargingStationSessions(
      this.reference,
      this.chargingStationName,
      formatDate(selectedDateFrom, 'YYYY-MM-dd', this.locale),
      formatDate(selectedDateTo, 'YYYY-MM-dd', this.locale)
    );
  }

  private analytics() {
    this.facade.analytics.push(
      {
        event: 'pageview',
        page: {
          phase: 'care',
          category: 'cuzo',
          subCategory: 'tevc monitoring dashboard - cuzo',
        },
      },
      {
        reference: this.facade?.state$?.value?.reference,
        site: this.facade?.state$?.value?.activeSite,
        accessRights: this.facade?.state$?.value?.accessRights,
      }
    );
  }
}
