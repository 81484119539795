import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, take, takeUntil } from 'rxjs';
import { StepModel, StepsService } from '@app/modules/customer-zone/consumption/services/steps/steps.service';
import { MainFacade } from '@app/core/facade/main.facade';
import { DeviceService } from '@app/core/service/device.service';

@Component({
  selector: 'app-first-login-guide',
  templateUrl: './first-login-guide.component.html',
  styleUrls: ['./first-login-guide.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({
          transform: 'translate(0, -2rem)',
          opacity: '0',
        }),
        animate('0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94)', style({ transform: 'translate(0, 0)', opacity: 1 })),
      ]),
    ]),
  ],
})
export class FirstLoginGuideComponent implements OnInit, OnDestroy {
  @Output() closeGuideEmitter = new EventEmitter<boolean>();
  public currentStep: StepModel;
  public stepsNumber: number;
  public isLastStep = false;
  public notifier$ = new Subject<void>();
  isMobile = false;
  private readonly ANALYTICS_URL: string[] = [
    '/onboarding/consumption-control',
    '/onboarding/monthly-consumption',
    '/onboarding/save-energy',
    '/onboarding/home-profile',
  ];

  constructor(
    private facade: MainFacade,
    private stepsService: StepsService,
    private router: Router,
    private deviceService: DeviceService
  ) {}

  ngOnInit(): void {
    this.deviceService.type$.pipe(take(1)).subscribe((data) => (this.isMobile = data?.isMobileApp));
    this.stepsService.resetStep();
    this.stepsService
      .getCurrentStep()
      .pipe(takeUntil(this.notifier$))
      .subscribe((step: StepModel) => {
        this.analytics(step);
        this.currentStep = step;
        this.scrollFreezer(true);
      });
  }

  ngOnDestroy() {
    this.notifier$.next();
    this.notifier$.complete();
    this.scrollFreezer(false);
  }

  private scrollFreezer(freeze: boolean) {
    const appWrapper: HTMLElement = document.getElementById('app-wrapper');
    if (freeze) {
      appWrapper.style.top = `-${window.scrollY}px`;
      appWrapper.style.position = 'fixed';
    } else {
      const scrollY = appWrapper.style.top;
      appWrapper.removeAttribute('style');
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    }
  }

  public onPreviousStep() {
    this.stepsService.moveToPreviousStep();
    this.isLastStep = this.stepsService.isLastStep();
  }

  public onNextStep() {
    if (!this.stepsService.isLastStep()) {
      this.stepsService.moveToNextStep();
    } else {
      this.onSubmit();
    }
    this.isLastStep = this.stepsService.isLastStep();
  }

  public closeGuide(): void {
    this.stepsService.setLastStep();
    this.closeGuideEmitter.emit(true);
    this.scrollFreezer(false);
  }

  public onSubmit(): void {
    this.closeGuide();

    this.router.navigate([`/consumptions/home-profile`]);
  }

  private analytics(step: StepModel): void {
    this.facade.analytics.push(
      {
        event: 'pageview',
        page: {
          phase: 'care',
          category: 'cuzo',
          subCategory: 'consumption - cuzo',
          path: this.ANALYTICS_URL[step.stepIndex - 1],
        },
      },
      {
        reference: this.facade?.state$?.value?.reference,
        site: this.facade?.state$?.value?.activeSite,
        accessRights: this.facade?.state$?.value?.accessRights,
        eliqAccessRights: this.facade?.state$?.value?.eliqAccessRights,
        contracts: this.facade?.state$?.value?.contracts,
      }
    );
  }
}
