import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { RegistrationModule } from 'src/app/modules/registration/registration.module';

@Component({
  selector: 'app-meter-switcher',
  templateUrl: './meter-switcher.component.html',
  styleUrls: ['./meter-switcher.component.scss'],
})
export class MeterSwitcherComponent implements OnInit, OnChanges {
  @Input() options: { id: string; label: string }[] = [];
  @Input() active: string;

  @Output() selectAction: EventEmitter<string> = new EventEmitter<string>();
  activeLabel: string;

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.active?.currentValue) {
      return;
    }

    this.activeLabel = this.options.find((el) => el.id === changes.active.currentValue).label;
  }

  ngOnInit(): void {}

  select(id) {
    this.activeLabel = this.options.find((el) => el.id === id).label;
    this.selectAction.emit(id);
  }
}
