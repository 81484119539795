export enum EnergyUnit {
  kwh = 'kwh',
  kw = 'kW',
  m3 = 'm3',
}

export enum VersionSize {
  small = 'small',
  big = 'big',
}
