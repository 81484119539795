import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  NgbDatepicker,
  NgbDateStruct,
  NgbDate,
  NgbCalendar,
  NgbDateNativeAdapter,
  NgbDatepickerI18n,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DatePickerCustomTranslationI18n } from '../date-picker-field-translation.class';
import { MONTHS } from '../date.constant';
import { DateRange } from '../date.model';

@Component({
  selector: 'date-picker-core',
  templateUrl: 'date-picker-core.component.html',
  styleUrls: ['date-picker-core.component.scss'],
  providers: [NgbDateNativeAdapter, { provide: NgbDatepickerI18n, useClass: DatePickerCustomTranslationI18n }],
})
export class DatePickerCoreComponent implements OnInit {
  @ViewChild(NgbDatepicker, { static: true }) datepicker: NgbDatepicker;

  @Input() dateRange: DateRange = {};
  @Input() markDisabled: () => boolean;
  @Input() set pickedDateValue(value: NgbDate) {
    this.initPickedValue(value);
  }
  @Output() datePicked = new EventEmitter<NgbDate>();

  pickerControl = new FormControl<NgbDate>(null);
  selectsForm: FormGroup<SelectsDetailFormGroup>;

  years: OptionDetails<number>[] = [];
  months: OptionDetails<string>[] = [];
  title = '';

  prevArrowDisabled: boolean = false;
  nextArrowDisabled: boolean = false;

  get minDate(): NgbDateStruct {
    return new NgbDate(this.dateRange.min.year, this.dateRange.min.month, this.dateRange.min.day);
  }

  get maxDate(): NgbDateStruct {
    return new NgbDate(this.dateRange.max.year, this.dateRange.max.month, this.dateRange.max.day);
  }

  constructor(private translate: TranslateService, private formBuilder: FormBuilder, private calendar: NgbCalendar) {
    this.selectsForm = this.formBuilder.group({
      month: new FormControl(''),
      year: new FormControl(''),
    });
  }

  ngOnInit(): void {
    // set default date range first (+-10 yers is default Ngb picker range)
    if (!this.dateRange.min) {
      this.dateRange.min = this.calendar.getPrev(this.calendar.getToday(), 'y', 10);
    }
    if (!this.dateRange.max) {
      this.dateRange.max = this.calendar.getNext(this.calendar.getToday(), 'y', 10);
    }

    // set today as default init date value if hasn't been set yet - it have to be set before using the datepicker
    if (!this.pickerControl.value) {
      this.initPickedValue(this.getValidDate());
    }

    this.years = this.getYearOptions();
    this.months = this.getMonthOptions();

    this.updateTitle();

    this.pickerControl.valueChanges.subscribe((date: NgbDate) => {
      this.datepicker.focusDate(date);
    });

    this.selectsForm.valueChanges.subscribe((selectedAge: SelectsDetails) => {
      this.onSelectsFormChanged(selectedAge);
    });
  }

  onDateSelect(date: NgbDate) {
    this.datePicked.next(date);
  }

  makeOneStep(direction: 'forward' | 'backward'): void {
    const currentDate = this.pickerControl.value as NgbDate;

    if (direction === 'forward') {
      const nextDate = this.calendar.getNext(currentDate, 'm');
      this.nextArrowDisabled = nextDate.month === this.dateRange.max.month && nextDate.year === this.dateRange.max.year;
      this.prevArrowDisabled = false;

      if (nextDate.after(this.dateRange.max)) {
        this.pickedDateValue = new NgbDate(this.dateRange.max.year, this.dateRange.max.month, this.dateRange.max.day);

        return;
      }

      this.pickedDateValue = nextDate;
    } else {
      const previousDate = this.calendar.getPrev(currentDate, 'm');
      this.prevArrowDisabled =
        previousDate.month === this.dateRange.min.month && previousDate.year === this.dateRange.min.year;
      this.nextArrowDisabled = false;

      if (previousDate.before(this.dateRange.min)) {
        this.pickedDateValue = new NgbDate(this.dateRange.min.year, this.dateRange.min.month, this.dateRange.min.day);

        return;
      }

      this.pickedDateValue = previousDate;
    }

    this.updateTitle();
  }

  private getMonthOptions(): OptionDetails<string>[] {
    const chosenDate = this.pickerControl.value as NgbDateStruct;

    const months = Object.values(MONTHS).map((month, index) => ({ id: index + 1, value: month }));

    if (chosenDate?.year === this.dateRange.max.year) {
      return months.filter((month) => month.id <= this.dateRange.max.month);
    } else if (chosenDate?.year === this.dateRange.min.year) {
      return months.filter((month) => month.id >= this.dateRange.min.month);
    } else {
      return months;
    }
  }

  private getYearOptions(): OptionDetails<number>[] {
    const years: number[] = [];
    for (let i = this.dateRange.max.year; i > this.dateRange.min.year - 1; i--) {
      years.push(i);
    }
    return years.map((year, index) => ({ id: index + 1, value: year }));
  }

  private updateTitle(): void {
    const date = this.datepicker?.model?.focusDate;

    if (date) {
      this.title = `${this.translate.instant(`common.month.${MONTHS[date.month]}`)} ${date.year}`;
    }
  }

  private initPickedValue(value: NgbDate): void {
    //don't init picker with undefined value or value out of range!
    if (value && this.isDateInRange(value)) {
      this.datepicker.focusDate(value);
      this.pickerControl.setValue(value);
      this.setSelectControls(value);
    }
  }

  private onSelectsFormChanged(selectedAge: SelectsDetails) {
    const selected = this.pickerControl.value as NgbDate;

    const newDate = new NgbDate(Number(selectedAge.year), Number(selectedAge.month), selected.day);
    const newValidDate = this.isDateInRange(newDate) ? newDate : this.getValidDate(newDate);

    // set value when the date is in the valid range
    this.pickerControl.setValue(newValidDate);

    // set month options according to year
    this.months = this.getMonthOptions();

    // reset year options too (after reseting the months the year list lost some options)
    this.years = this.getYearOptions();

    // update title text
    this.updateTitle();

    // change value when is not same (to prevent the error maximum call stack size exceeded after updating selects form)
    if (!selected.equals(newValidDate)) {
      this.setSelectControls(newValidDate);
    }
  }

  private isDateInRange(date: NgbDate): boolean {
    return (
      (date.after(this.minDate) && date.before(this.maxDate)) || date.equals(this.minDate) || date.equals(this.maxDate)
    );
  }

  private getValidDate(defaultDate = this.calendar.getToday()): NgbDate {
    if (this.isDateInRange(defaultDate)) {
      return defaultDate;
    } else {
      const maxDate = new NgbDate(this.dateRange.max.year, this.dateRange.max.month, this.dateRange.max.day);

      // return maximum or minimum date if today isn't in the date range
      return maxDate.before(defaultDate)
        ? maxDate
        : new NgbDate(this.dateRange.min.year, this.dateRange.min.month, this.dateRange.min.day);
    }
  }

  private setSelectControls(date: NgbDateStruct): void {
    const selectValue: SelectsDetails = {
      year: date.year.toString(),
      month: date.month.toString(),
    };
    this.selectsForm.setValue(selectValue);
  }
}

interface OptionDetails<T extends string | number> {
  id: number;
  value: T;
}

interface SelectsDetails {
  year: string;
  month: string;
}

interface SelectsDetailFormGroup {
  year: FormControl<string>;
  month: FormControl<string>;
}
