import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, take } from 'rxjs';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private previousRequestError: HttpRequest<any> = null;
  constructor(private toastrService: ToastrService, private translateService: TranslateService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event) => event),
      catchError((error) => {
        if (!this.previousRequestError || this.previousRequestError.url !== request.url) {
          const { status } = error;
          if (status >= 500 && status <= 599) {
            this.translateService
              .get('errorMessages.serverError')
              .pipe(take(1))
              .subscribe((translated: string) => this.toastrService.error(translated));
          }
        }
        this.previousRequestError = request;
        return throwError(error);
      })
    );
  }
}
