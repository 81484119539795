<h2 class="flex mt-12 mb-8 font-display font-normal text-5xl te-text-gradient-home md:my-12">
  {{ 'components.contracts.registerFix.title' | translate }}
</h2>
<div class="mt-24">
  <div
    class="flex w-full bg-success-lighter60 border-2 rounded-xl px-10 py-5 mt-4 mb-12 border-solid border-success-lighter"
  >
    <span class="success-box flex relative font-medium text-sm text-success-lighter ml-20">{{
      'components.contracts.registerFix.prodcutSwapSubmitted.thankYouMessage' | translate
    }}</span>
  </div>

  <h3
    class="flex justify-center w-full font-display font-normal text-2xl md:text-4xl te-text-gradient-home mb-12 mt-12 md:mt-0 leading-tight"
  >
    {{ 'components.contracts.registerFix.prodcutSwapSubmitted.subtitle' | translate }}
  </h3>

  <div class="overflow-hidden mt-10 flex flex-col md:flex-row md:my-4">
    <ng-container *ngFor="let step of stepsContent; let i = index; let last = last">
      <div class="flex-1 flex flex-row mb-8 z-0pb-8 md:flex-col md:pb-0">
        <app-progress-circle
          class="progress-circle w-24 md:w-64 md:mx-auto"
          [ngClass]="{ 'progress-circle--last': last }"
        >
          <span class="relative font-display font-normal text-grey-dark text-4xl md:text-5xl circle-content">
            <img [src]="step.imageUrl" [alt]="'image' + i" />
            <div
              class="absolute flex items-center justify-center -top-6 md:-top-7 -right-6 md:-right-7 w-5 h-5 rounded-full bg-white p-6 md:p-8 box-shadow-small"
            >
              <span class="font-sans font-medium text-sm md:text-2xl text-primary">{{ i + 1 }}</span>
            </div>
          </span>
        </app-progress-circle>
        <div class="circle-description pl-6 flex-1 self-center inline-block md:text-center md:p-8">
          <h6 class="text-grey-dark font-medium text-2xl md:text-3xl md:mb-6">
            {{ step.title | translate }}
          </h6>
          <div>{{ step.description | translate }}</div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
