<app-modal #modal title="{{ 'components.handleReferences.delete.title' | translate }} ">
  <div>
    <div class="mt-8 mb-8">
      <p>{{ 'components.handleReferences.delete.description' | translate }}</p>
    </div>
    <div class="has-gutter-xl delete-reference__action mb-4 mt-8">
      <button
        (click)="deleteReference()"
        class="btn btn-primary inline-block w-full font-bold mb-4 md:mb-0 md:w-72"
        [analyticsEvent]="'references:click:delete-reference-confirm'"
      >
        {{ 'general.delete' | translate }}
      </button>
      <button
        (click)="close()"
        class="btn btn-secondary inline-block w-full font-bold md:ml-8 md:w-72"
        [analyticsEvent]="'references:click:delete-reference-cancel'"
      >
        {{ 'general.cancel' | translate }}
      </button>
    </div>
  </div>
</app-modal>
