<div class="modal-wrapper rounded-4xl">
  <span
    *ngIf="showCloseButton"
    class="bg-white close close-btn"
    aria-label="Close"
    (click)="activeModal.close('Cross click')"
  >
  </span>
  <div class="rounded-t-4xl" [ngClass]="[customHeaderClass]">
    <div class="w-full flex flex-row items-center p-8 md:p-12">
      <app-image-icon
        *ngIf="icon?.svgName"
        class="app-image-icon float-left mr-8"
        [altDescription]="title"
        [imagePath]="
          './assets/img/icons/standalone/' +
          (icon?.color ? icon?.color : 'red') +
          '/' +
          (icon?.svgName | lowercase) +
          '.svg'
        "
      >
      </app-image-icon>

      <h4 class="font-medium text-2xl mt-4 sm:mb-2 text-grey-dark">
        {{ title }}
      </h4>
    </div>
  </div>
  <div class="modal-body bg-white">
    <div class="sm:m-12 sm:mt-8">
      <ng-content></ng-content>
    </div>
  </div>
</div>
