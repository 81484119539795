import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../../../../shared/utils/utils.service';
import { Observable } from 'rxjs';
import { ContentService } from '@app/shared/services/content/content.service';
import { Content } from '@app/shared/models/content.interface';

@Component({
  selector: 'app-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss'],
})
export class QuickLinksComponent implements OnInit {
  public content$: Observable<Content>;

  constructor(public utilsService: UtilsService, private contentService: ContentService) {}

  ngOnInit() {
    this.content$ = this.contentService.getActiveContent();
  }
}
