import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map, of, tap } from 'rxjs';
import { SponsorshipCuzoApi, SponsorshipRecipientCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Injectable({
  providedIn: 'root',
})
export class SponsorshipService {
  private sponsorship: Array<SponsorshipCuzoApi> = [];

  constructor(private http: HttpClient) {}

  public getSponsorship(reference: string): Observable<SponsorshipCuzoApi> {
    if (this.sponsorship[reference]) {
      return of(this.sponsorship[reference]);
    }

    return this.getSponsorshipSummaryFromApi(reference).pipe(
      tap((sponsorship: SponsorshipCuzoApi) => {
        sponsorship.totalDiscount.discount = this.addRoundedVAT(sponsorship.totalDiscount.discount);
        let sponsorRecipients = this.sortByDate(sponsorship.recipients);
        sponsorRecipients = this.updateDiscountWithVAT(sponsorRecipients);

        this.sponsorship[reference] = sponsorship;
        this.sponsorship[reference].recipients = sponsorRecipients;
      })
    );
  }

  public getSponsorshipRecipients(reference: string): Observable<SponsorshipRecipientCuzoApi[]> {
    if (this.sponsorship[reference]) {
      return of(this.sponsorship[reference].recipients);
    }

    return this.getSponsorship(reference).pipe(
      map((sponsorship: SponsorshipCuzoApi) => {
        return sponsorship.recipients;
      })
    );
  }

  private getSponsorshipSummaryFromApi(reference: string): Observable<SponsorshipCuzoApi> {
    return this.http.get<SponsorshipCuzoApi>(`/v1/customers/${reference}/sponsorship-summary`);
  }

  private sortByDate(sponsorRecipients: SponsorshipRecipientCuzoApi[]): SponsorshipRecipientCuzoApi[] {
    return sponsorRecipients.sort((a, b) => new Date(b.signatureDate).getTime() - new Date(a.signatureDate).getTime());
  }

  private updateDiscountWithVAT(sponsorRecipients: SponsorshipRecipientCuzoApi[]): SponsorshipRecipientCuzoApi[] {
    return sponsorRecipients.map((sponsorRecipient: SponsorshipRecipientCuzoApi) => ({
      ...sponsorRecipient,
      discount: {
        discount: this.addRoundedVAT(sponsorRecipient.discount.discount),
        currency: sponsorRecipient.discount.currency,
      },
    }));
  }

  private addRoundedVAT(amount: number) {
    return Math.abs(Math.round(amount * 1.21));
  }
}
