<div class="contact-info">
  <div class="py-6">
    <h3 class="my-12 text-center" [innerHTML]="'general.upsell.boiler_one_shot.contact_info.title' | translate"></h3>
  </div>

  <div>
    <div class="form-element input-text mb-4" [class.errors]="control.get('name').errors?.required">
      <label for="name"> {{ 'general.upsell.boiler_one_shot.contact_info.name' | translate }}* </label>
      <input [formControl]="control.get('name')" id="name" name="name" type="text" autocomplete="off" />
      <span class="message">
        <ng-container *ngIf="control.get('name').errors?.required">
          {{ 'errorMessages.required' | translate }}
        </ng-container>
      </span>
    </div>

    <div class="form-element input-text mb-4" [class.errors]="control.get('phone').errors?.required">
      <div class="flex">
        <label for="phone"> {{ 'general.upsell.boiler_one_shot.contact_info.phone.text' | translate }}* </label>
        <app-tooltip
          [tooltip]="'general.upsell.boiler_one_shot.contact_info.phone.tooltip' | translate"
          class="ml-auto fr"
        ></app-tooltip>
      </div>
      <input [formControl]="control.get('phone')" id="phone" name="phone" type="text" autocomplete="off" />
      <span class="message">
        <ng-container *ngIf="control.get('phone').errors?.required">
          {{ 'errorMessages.required' | translate }}
        </ng-container>
      </span>
    </div>

    <div
      class="form-element input-text mb-4"
      [class.errors]="control.get('email').errors?.required || control.get('email').errors?.email"
    >
      <div class="flex">
        <label for="email">{{ 'general.email' | translate }}*</label>
        <app-tooltip
          [tooltip]="'general.upsell.boiler_one_shot.contact_info.email.tooltip' | translate"
          class="fr"
        ></app-tooltip>
      </div>
      <input [formControl]="control.get('email')" id="email" name="email" type="text" autocomplete="off" />
      <div class="message">
        <ng-container *ngIf="control.get('email').errors?.required">
          {{ 'errorMessages.required' | translate }}
        </ng-container>
        <ng-container *ngIf="control.get('email').errors?.email">
          {{ 'errorMessages.invalidEmail' | translate }}
        </ng-container>
      </div>
    </div>

    <div class="md:flex next fr my-12">
      <button
        class="w-full btn btn-primary ml-auto md:w-auto"
        [attr.disabled]="!control.valid ? '' : null"
        [class.not-submittable]="!control.valid"
        (click)="submitForm()"
      >
        {{ 'general.upsell.boiler_one_shot.contact_info.submit_button' | translate }}
      </button>
      <div class="text-center mt-8 md:flex-1 md:order-first md:mt-0 md:text-left">
        <strong class="navigate-back text-primary cursor-pointer">
          <a (click)="goBack()">{{ 'general.back' | translate }}</a>
        </strong>
      </div>
    </div>
  </div>
</div>
