import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DeviceService } from '@app/core/service/device.service';
import { UtilsService } from '@app/shared/utils/utils.service';

@Injectable()
export class BearerInterceptor implements HttpInterceptor {
  private isMobileApp = false;
  constructor(private deviceService: DeviceService, private utilsService: UtilsService) {
    this.deviceService.type$.subscribe(({ isMobileApp }) => (this.isMobileApp = isMobileApp));
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isMobileApp) {
      return next.handle(request);
    }
    const authToken = this.utilsService.getCookieValue('KEYCLOAK');
    if (authToken) {
      const authRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      return next.handle(authRequest);
    } else {
      return next.handle(request);
    }
  }
}
