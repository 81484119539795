import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PrivateContactInfo } from '../models/privateContactInfo.interface';
import { CheckVerificationCodePayload, GetVerificationCodePayload } from '../models/verification-code.interface';

@Injectable({
  providedIn: 'root',
})
export class BindingProcessService {
  constructor(private http: HttpClient) {}

  public getPrivateInfoFromReference(reference: string): Observable<PrivateContactInfo> {
    return this.http.get<PrivateContactInfo>(`/v1/useraccount/${reference}/private`);
  }

  public sendVerificationCode(payload: GetVerificationCodePayload): Observable<any> {
    return this.http.post<any>(`/v1/userprofiles`, payload);
  }

  public checkVerificationCode(payload: CheckVerificationCodePayload, token: string): Observable<any> {
    return this.http.post<any>(`/v1/userprofiles/${token}/validate`, payload);
  }
}
