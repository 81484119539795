import { LOCALE_ID, Component, EventEmitter, Input, OnInit, Output, Inject } from '@angular/core';
import { formatDate, formatCurrency, formatNumber } from '@angular/common';
import {
  AdvancesAssessment,
  EnergyType,
  TimeType,
  UseCaseMyEnergyType,
} from '../../../../../../../../models/consumption.interface';
import { UntypedFormControl } from '@angular/forms';
import { myEnergyEvents } from '../../../../../../../../../../../core/analytics/models/event-defaults';
import { AnalyticsService } from '../../../../../../../../../../../shared/modules/analytics/services/analytics.service';
import { Contract } from '../../../../../../../../../contracts/models/contract.interface';
import { TranslateService } from '@ngx-translate/core';
import { IndexModalService } from '@app/modules/customer-zone/consumption/services/index-modal/index-modal.service';
import { AdvancesAssessmentService } from '@app/modules/customer-zone/consumption/services/advancesAssessment/advances-assessment.service';
import { UtilsService } from '@app/shared/utils/utils.service';

interface TooltipParams {
  extrapolatedConsumption: string;
  estimatedMonth: string;
  newCost: string;
  instalmentsNumber: number;
  invoiceAmount: string;
  recommendedAmount: string;
}

@Component({
  selector: 'app-monthly-installment-form',
  templateUrl: './monthly-installment-form.component.html',
  styleUrls: ['./monthly-installment-form.component.scss'],
})
export class MonthlyInstallmentFormComponent implements OnInit {
  @Input() contract: Contract;
  @Input() energy: EnergyType;
  @Input() control: UntypedFormControl;
  @Input() minAmount: number;
  @Input() suggestedAmount: number;
  @Input() useCaseMyEnergy: UseCaseMyEnergyType;
  @Output() amountEmitter = new EventEmitter();
  @Output() formReady = new EventEmitter();

  public lastAdvanceAssessment: AdvancesAssessment = undefined;
  public newAmount = 0;
  public invoiceAdjustment = 0;
  public currentAmount: number;
  public maxAmount: number;
  public messageType: string;
  public tooltipParams: TooltipParams;
  public updateButtonDisabled = false;

  private readonly amountRatio = {
    min: 0.82,
    max: 1.3,
  };

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private analyticsService: AnalyticsService,
    private translateService: TranslateService,
    private indexModalService: IndexModalService,
    private utilsService: UtilsService,
    private advancesAssessmentService: AdvancesAssessmentService
  ) {}

  ngOnInit(): void {
    this.analytics();

    this.newAmount = this.suggestedAmount;

    this.lastAdvanceAssessment = this.advancesAssessmentService.getLastAdvanceAssessment();
    this.currentAmount = this.control.value;

    this.tooltipParams = this.generateTooltipParams(this.lastAdvanceAssessment);

    this.maxAmount = this.utilsService.round(
      (this.useCaseMyEnergy === UseCaseMyEnergyType.DECREASE_POSSIBLE ? this.currentAmount : this.suggestedAmount) *
        this.amountRatio.max,
      2
    );

    this.messageType = this.currentAmount < this.suggestedAmount ? 'goUp' : 'goDown';

    this.indexModalService.setModalTitle(
      this.translateService.instant('components.consumption.index.monthlyInstallment.modalTitle') +
        ' ' +
        this.translateService.instant(
          `components.consumption.index.monthlyInstallment.modalTitleEnergy.${this.energy.toLowerCase()}`
        )
    );

    this.control.valueChanges.subscribe((amount: number) => {
      // convert string to number, because number mask returns string type
      const normalizedAmount = amount ? Number(`${amount}`.replace(',', '.')) : 0;

      this.calculateNewAmount(normalizedAmount);

      // set update button disable if is the new amout equal to the current amount
      // or is out of range or the field is empty
      this.updateButtonDisabled =
        this.utilsService.floatEqual(normalizedAmount, this.currentAmount) ||
        (this.control.errors && this.control.errors.numberRange) ||
        `${amount}` === '';
    });
  }

  submit() {
    this.amountEmitter.emit(this.newAmount);
  }

  continue() {
    this.formReady.emit();
  }

  private calculateNewAmount(amount: number): void {
    // calculate new installment value
    if (this.utilsService.floatEqual(this.utilsService.round(this.suggestedAmount, 2), amount)) {
      amount = this.suggestedAmount;
    }

    this.newAmount = amount;

    this.invoiceAdjustment = this.utilsService.round(
      this.lastAdvanceAssessment.yearlyEstimatedAmountBasedOnIndex -
        this.lastAdvanceAssessment.alreadyInvoicedInstallments.amount -
        this.lastAdvanceAssessment.remainingInvoicesBeforeSettlementInvoice * amount,
      2
    );
  }

  private generateTooltipParams(advanceAssessment: AdvancesAssessment): TooltipParams {
    return {
      extrapolatedConsumption: formatNumber(
        advanceAssessment.yearlyEstimateBasedOnIndex.timeType
          .map((typeItem: TimeType) => typeItem.value)
          .reduce((prev: number, next: number) => prev + next, 0),
        this.locale,
        '1.2-2'
      ),
      estimatedMonth: formatDate(
        new Date().setMonth(advanceAssessment.meterReadingMonth - 1),
        'LLLL',
        this.translateService.currentLang + '_BE' // this is important for getting the localized month name
      ),
      newCost: formatCurrency(
        advanceAssessment.yearlyEstimatedAmountBasedOnIndex,
        this.locale,
        '€',
        undefined,
        '1.2-2'
      ),
      instalmentsNumber: advanceAssessment.remainingInvoicesBeforeSettlementInvoice,
      invoiceAmount: formatCurrency(
        advanceAssessment.alreadyInvoicedInstallments.amount,
        this.locale,
        '€',
        undefined,
        '1.2-2'
      ),
      recommendedAmount: formatCurrency(this.suggestedAmount, this.locale, '€', undefined, '1.2-2'),
    };
  }

  private analytics() {
    this.analyticsService.push({
      ...myEnergyEvents(`${this.translateService.currentLang}/myenergy-flow/deposit-modify`),
      component: { name: 'Deposit Modification' },
      deposit: {
        suggestedAmount: this.suggestedAmount,
      },
      myEnergy: {
        energyType: this.contract.type,
        ean: this.contract.deliveryPointReference,
      },
    });
  }
}
