<div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
  <div class="mb-16">
    <h2 class="mt-12 mb-8 font-display font-normal text-5xl te-text-gradient-home inline-block md:my-12">
      {{ 'pages.consents.title' | translate }}
    </h2>
    <div class="md:grid md:grid-cols-12 gap-16">
      <div class="md:col-span-8">
        <div class="card-primary mb-8 md:mb-16">
          <div class="">
            <strong class="font-medium text-base mb-4 inline-block w-full">{{
              'pages.consents.general.title' | translate
            }}</strong>
            <div class="font-normal text-base" [innerHTML]="'pages.consents.general.content' | translate"></div>
          </div>
          <div class="mt-16">
            <strong class="font-medium text-base mb-4 inline-block w-full">{{
              'pages.consents.getMyData.title' | translate
            }}</strong>
            <div class="font-normal text-base" [innerHTML]="'pages.consents.getMyData.content' | translate"></div>
          </div>
          <div class="mt-16">
            <strong class="font-medium text-base mb-4 inline-block w-full">{{
              'pages.consents.howToEditMyData.title' | translate
            }}</strong>
            <div class="font-normal text-base" [innerHTML]="'pages.consents.howToEditMyData.content' | translate"></div>
          </div>
          <div class="mt-16">
            <strong class="font-medium text-base mb-4 inline-block w-full">{{
              'pages.consents.howToExportMyData.title' | translate
            }}</strong>
            <div
              class="font-normal text-base"
              [innerHTML]="'pages.consents.howToExportMyData.content' | translate"
            ></div>
            <div class="mt-8 portability-document">
              <button
                class="w-full btn btn-primary inline-block md:w-auto"
                (click)="downloadData('PDF')"
                [analyticsEvent]="'consent:download:pdf'"
              >
                {{ 'pages.consents.howToExportMyData.downloadPDF' | translate }}
              </button>
              <span
                class="mt-8 py-4 cursor-pointer text-center w-full btn btn-text inline-block text-primary md:ml-16 md:w-auto md:mt-0 md:text-left"
                (click)="downloadData('CSV')"
                [analyticsEvent]="'consent:download:csv'"
              >
                {{ 'pages.consents.howToExportMyData.downloadCSV' | translate }}
              </span>
            </div>
          </div>
        </div>

        <ng-container *ngIf="consents$ | async as consents">
          <div class="card-primary">
            <ng-container *ngIf="userInfo$ | async as userInfo">
              <app-consent-form
                [consents]="consents"
                [myConsents]="myConsents$ | async"
                [userInfo]="userInfo"
                [reference]="activeReference"
                [language]="language"
              >
              </app-consent-form>
            </ng-container>
          </div>
        </ng-container>
      </div>

      <div class="mt-16 md:mt-0 md:col-span-4">
        <app-chat-cta></app-chat-cta>
      </div>
    </div>
  </div>
</div>
