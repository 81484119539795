import { Component, OnDestroy, OnInit } from '@angular/core';
import { Site } from '@app/modules/customer-zone/user/models/site.interface';
import { catchError, filter, Observable, of, shareReplay, Subject, take, takeUntil, tap } from 'rxjs';
import { MainFacade } from '@app/core/facade/main.facade';
import { EliqAccessRights } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { VersionSize } from '@app/shared/models/units.interface';
import { Advice, AdviceStatus } from '@app/modules/customer-zone/consumption/services/advice/advice.service';
import { ToastrService } from 'ngx-toastr';
import { Mandate } from '@app/modules/customer-zone/consumption/models/mandates.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { ContractDtoCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Component({
  selector: 'app-advice',
  templateUrl: './advice.component.html',
  styleUrls: ['./advice.component.scss'],
})
export class AdviceComponent implements OnInit, OnDestroy {
  readonly adviceStatus = AdviceStatus;
  readonly versionSize = VersionSize;
  reference: string;
  activeSite: Site;
  contracts$: Observable<ContractDtoCuzoApi[]>;
  sites$: Observable<Site[]>;
  eliqAccessRights$: Observable<EliqAccessRights>;
  homeProfileCompletion$: Observable<number>;
  mandates$: Observable<Mandate[]>;
  advice$: Observable<Advice[]>;
  currentAdvice: Advice = null;
  activeStatus: AdviceStatus = AdviceStatus.none;
  isLoading: boolean = false;
  isInError: boolean = false;
  isSyncEliqData$: Observable<Boolean>;
  notEnoughData: boolean = false;
  successPopup: boolean = false;
  private notifier$: Subject<void> = new Subject<void>();

  constructor(public facade: MainFacade, private toastrService: ToastrService) {}

  ngOnInit(): void {
    this.facade.utils.setPageTitle('pages.advice.pageTitle');
    this.facade.activeSite$
      .pipe(
        filter((site) => !!site),
        takeUntil(this.notifier$)
      )
      .subscribe((site: Site) => {
        this.reference = this.facade.state$.value.reference;
        this.activeSite = site;
        this.contracts$ = this.facade.contracts$;
        this.mandates$ = this.facade.loadMandates(this.reference);
        this.sites$ = this.facade.sites$;
        this.eliqAccessRights$ = this.facade.eliqAccessRights$;
        this.homeProfileCompletion$ = this.facade.getHomeProfileCompletion(this.reference, this.activeSite, false);
        this.isSyncEliqData$ = this.facade.isSynchronisingEliqData(this.reference, this.activeSite, false);
        this.loadAdvices();
      });
  }

  ngOnDestroy(): void {
    this.notifier$.next();
    this.notifier$.complete();
  }

  switchSite(site: string) {
    this.facade.updateActiveSite(site);
  }

  updateAdviceView(status: AdviceStatus) {
    this.activeStatus = status;
    this.loadAdvices();
  }

  updateAdviceStatus(data: { status: AdviceStatus; id: string }) {
    this.isLoading = true;
    this.facade
      .updateAdviceStatus(this.reference, this.activeSite, data.id, { status: data.status })
      .pipe(
        take(1),
        catchError(() => {
          this.isLoading = false;
          this.isInError = true;
          // eslint-disable-next-line @typescript-eslint/quotes
          return of(null);
        })
      )
      .subscribe(() => {
        if (data.status === AdviceStatus.implemented) {
          this.successPopup = true;
        }
        this.toastrService.success(
          this.facade.translate.instant(`components.advice.actionCallback.${data.status.toLowerCase()}`)
        );
        this.isLoading = false;
        this.loadAdvices();
      });
  }

  close() {
    this.currentAdvice = null;
  }

  closeSuccessPopup() {
    this.successPopup = false;
  }

  asIsOrder() {
    return 1;
  }

  openSingleAdvice(advice: Advice) {
    const title = this.facade.translate
      .instant('components.advice.list.' + advice?.key + '.title')
      .replace(/\s+/g, '-')
      .toLowerCase();
    this.analytics(null, `/consumption/tips/${title}`);
    this.currentAdvice = advice;
  }

  private loadAdvices() {
    this.analytics(this.activeStatus);
    this.isLoading = true;
    this.isInError = false;
    this.notEnoughData = false;
    this.advice$ = this.facade.loadAdvice(this.reference, this.activeSite, this.activeStatus, false).pipe(
      shareReplay(1),
      tap(() => {
        this.isLoading = this.isInError = false;
      }),
      catchError((e: HttpErrorResponse) => {
        this.isLoading = false;
        if (e.status === 428) {
          this.notEnoughData = true;
        } else {
          this.isInError = true;
          //this.toastrService.error(this.facade.translate.instant(`components.advice.errorCallback.standardError`));
        }
        return of([]);
      })
    );
  }

  private analytics(status: AdviceStatus = null, path: string = null): void {
    this.facade.analytics.push(
      {
        event: 'pageview',
        page: {
          phase: 'care',
          category: 'cuzo',
          subCategory: 'tips - cuzo',
          path: path || this.mapAnalyticsData()[status].path,
        },
      },
      {
        reference: this.facade?.state$?.value?.reference,
        site: this.facade?.state$?.value?.activeSite,
        accessRights: this.facade?.state$?.value?.accessRights,
        eliqAccessRights: this.facade?.state$?.value?.eliqAccessRights,
        contracts: this.facade?.state$?.value?.contracts,
      }
    );
  }

  private mapAnalyticsData() {
    const mapper = {};
    mapper[AdviceStatus.none] = { path: '/consumption/tips/to-do' };
    mapper[AdviceStatus.all] = { path: '/consumption/tips/all' };
    mapper[AdviceStatus.implemented] = { path: '/consumption/tips/done' };
    mapper[AdviceStatus.skipped] = { path: '/consumption/tips/archive' };
    return mapper;
  }
}
