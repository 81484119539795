import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalComponent } from '../../../../../../shared/components/modal/modal.component';
import { TranslateService } from '@ngx-translate/core';
import { EnergyType, Index, IndexContext } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { IndexModalComponent } from '@app/modules/customer-zone/consumption/components/modal/index-modal/index-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Contract } from '@app/modules/customer-zone/contracts/models/contract.interface';
import { MeteringsService } from '@app/modules/customer-zone/consumption/services/meterings/meterings.service';
import { switchMap, take } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { MainFacade } from '@app/core/facade/main.facade';

@Component({
  selector: 'app-provisional-indexes',
  templateUrl: './provisional-indexes.component.html',
  styleUrls: ['./provisional-indexes.component.scss'],
})
export class ProvisionalIndexesComponent implements OnInit {
  @Input() reference: string;
  @Input() siteId: string;
  @Input() provisionalIndexes: [];
  @Input() boundaryIndexes;
  @Input() provisionalsByDirection;
  @Input() energyUnit;
  @Input() contract: Contract;
  @ViewChild(ModalComponent) modal: ModalComponent;
  itemSelect: string[] = [];
  loading = false;
  readonly indexContext = IndexContext;

  constructor(
    private facade: MainFacade,
    public translateService: TranslateService,
    private modalService: NgbModal,
    private meteringsService: MeteringsService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {}

  editIndex(index: number) {
    const [consumptionProvision, productionProvision]: any[] = this.provisionalIndexes;
    const selectedConsumptionProvision = consumptionProvision && consumptionProvision[index];
    const selectedProductionProvision = (productionProvision && productionProvision[index]) || null;
    const metering = {
      ...selectedConsumptionProvision,
      indexes: [].concat(selectedConsumptionProvision?.indexes, selectedProductionProvision?.indexes),
    };
    this.modal.activeModal.dismiss();
    const modalRef = this.modalService.open(IndexModalComponent, {
      size: 'auto',
    });
    modalRef.componentInstance.editIndex = { metering, contract: this.contract };
    modalRef.result.then(() => document.querySelector('body').removeAttribute('style'));
  }

  toggleActions(index: number) {
    this.itemSelect[index] = this.itemSelect[index] === 'actions' ? 'indexes' : 'actions';
  }

  deleteIndex(metering: Index, index: number) {
    this.loading = true;
    const payload = {
      gsrn: this.contract.deliveryPointReference,
      date: metering.indexDate,
    };
    this.meteringsService
      .deleteIndexFromApi(
        this.reference,
        this.siteId,
        this.contract.type as EnergyType,
        this.contract.deliveryPointReference,
        payload,
        false
      )
      .pipe(
        take(1),
        switchMap(() =>
          this.meteringsService.getMeterings(
            this.reference,
            this.siteId,
            this.contract.type as EnergyType,
            this.contract.deliveryPointReference,
            false,
            true
          )
        )
      )
      .subscribe(
        (meterings: Index[]) => {
          let provisional = [];
          let newMeterings = [];
          if (meterings) {
            [newMeterings, provisional] = this.meteringsService.prepareIndexes(
              [...meterings].reverse(),
              this.contract.type,
              this.facade.state$.value.computedData.hasSolarPanel
            );
          }
          const { deliveryPointReference } = this.contract;
          this.meteringsService.setMeterings$(deliveryPointReference, newMeterings);
          this.meteringsService.setProvisionalIndexes$(deliveryPointReference, provisional);
          this.provisionalsByDirection.splice(index, 1); // remove item from array to directly show changes to user
          this.loading = false;
          this.toastrService.success(this.translateService.instant('components.consumption.index.delete.success'));
        },
        () => {
          this.loading = false;
          this.toastrService.error(this.translateService.instant('components.consumption.index.delete.error'));
        }
      );
  }
}
