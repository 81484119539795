import { Component, Input, OnInit } from '@angular/core';
import { BillingDetails } from '../../../../models/billingDetails.interface';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IBANvalidator } from '../../../../../../../shared/utils/utils.validators';
import { BillingService } from '../../../../services/billing/billing.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-refund-account',
  templateUrl: './change-refund-account.component.html',
  styleUrls: ['./change-refund-account.component.scss'],
})
export class ChangeRefundAccountComponent implements OnInit {
  @Input() billingDetails: BillingDetails;
  @Input() reference: string;

  public control: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    public billingService: BillingService,
    private translate: TranslateService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.control = this.fb.group({
      refundAccount: ['', [Validators.required, IBANvalidator()]],
    });
  }

  handleSubmit() {
    this.billingService
      .updateBillingDetails(this.reference, {
        bankAccount: this.control.get('refundAccount').value.replace(/ /g, ''),
      })
      .subscribe(
        (response) => {
          this.toastrService.success(this.translate.instant('general.success'));
          this.billingDetails.paymentMethodLocked = true;
        },
        (error) => {
          this.toastrService.error(this.translate.instant('errorMessages.serverError'));
        }
      );
    this.activeModal.close();
  }
}
