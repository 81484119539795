<div class="menu-wrapper" role="navigation">
  <ul>
    <ng-container *ngIf="facade.computedData$ | async as computedData">
      <ng-container *accessControl="'!seeGenericMessage'">
        <li
          *accessControl="accessRight.accessInvoices"
          class="menu-item"
          #invoices
          [routerLinkActive]="!isMobile ? 'submenu-open' : ''"
        >
          <a
            class="menu-link"
            [ngClass]="{ 'mobile-active-submenu': mobileActiveSubmenu === 'invoices' }"
            (click)="emitMobileShowSubmenu('invoices')"
            [routerLink]="!isMobile ? '/invoices' : null"
            [routerLinkActive]="!isMobile ? 'active' : ''"
            [skipLocationChange]="true"
            [analyticsEvent]="'navigation:click-primary-menu:invoices'"
          >
            {{ 'header.primaryMenu.invoices.label' | translate }}
          </a>
          <div class="submenu-wrapper">
            <span class="submenu-back inline-block w-full md:hidden" (click)="emitMobileCloseSubmenu()">
              {{ 'general.back' | translate }}
            </span>
            <span class="submenu-title inline-block w-full md:hidden">
              {{ 'header.primaryMenu.invoices.label' | translate }}
            </span>
            <ul class="submenu" (click)="emitMobileCloseMenu()">
              <li *accessControl="accessRight.viewInvoices" class="submenu-item">
                <a
                  class="submenu-link"
                  [class.active]="isLinkActive('/invoices')"
                  routerLink="/invoices"
                  [skipLocationChange]="true"
                  [analyticsEvent]="'navigation:click-primary-submenu:my-invoices'"
                >
                  {{ 'header.primaryMenu.invoices.myInvoices' | translate }}
                </a>
              </li>

              <li *accessControl="accessRight.viewPaymentPlan" class="submenu-item">
                <a
                  class="submenu-link"
                  routerLink="/invoices/payment-plan"
                  [skipLocationChange]="true"
                  [routerLinkActive]="!isMobile ? 'active' : ''"
                  [analyticsEvent]="'navigation:click-primary-submenu:payment-plan'"
                >
                    {{ 'header.primaryMenu.invoices.myPaymentPlan' | translate }}
                </a>
            </li>
              <li class="submenu-item">
                <a
                  class="submenu-link"
                  routerLink="/invoices/advance-payment"
                  [skipLocationChange]="true"
                  [routerLinkActive]="!isMobile ? 'active' : ''"
                  [analyticsEvent]="'navigation:click-primary-submenu:advance-payment'"
                >
                  {{ 'header.primaryMenu.invoices.myAdvancePayment' | translate }}</a
                >
              </li>
              <li class="submenu-item">
                <a
                  class="submenu-link"
                  routerLink="/invoices/payment-preferences"
                  [skipLocationChange]="true"
                  [routerLinkActive]="!isMobile ? 'active' : ''"
                  [analyticsEvent]="'navigation:click-primary-submenu:payment-preferences'"
                >
                  {{ 'header.primaryMenu.invoices.myPaymentPreferences' | translate }}
                </a>
              </li>
            </ul>
          </div>
        </li>

        <ng-container *accessControl="accessRight.accessMyConsumption">
          <!-- Consumption menu without submenu -->
          <ng-container
            *multipleAccessControl="{
              rights: [accessRightOperator.negation + accessRight.accessEnergyInsights]
            }"
          >
            <li class="menu-item" (click)="emitMobileCloseMenu()">
              <a
                class="menu-link no-submenu"
                routerLink="/consumptions"
                routerLinkActive="active"
                [skipLocationChange]="true"
                [analyticsEvent]="'navigation:click-primary-menu:consumptions'"
                >{{ 'header.primaryMenu.consumptions.label' | translate }}</a
              >
            </li>
          </ng-container>

          <!-- Consumption menu with submenu for user with access to Energy insight  -->
          <ng-container *accessControl="accessRight.accessEnergyInsights">
            <li
              *ngIf="!isMobile || (isMobile && eliqAccessSuccess)"
              class="menu-item"
              #consumptions
              [routerLinkActive]="!isMobile ? 'submenu-open' : ''"
            >
              <a
                class="menu-link"
                [ngClass]="{ 'mobile-active-submenu': mobileActiveSubmenu === 'consumptions' }"
                (click)="emitMobileShowSubmenu('consumptions')"
                [routerLink]="!isMobile ? '/consumptions' : null"
                [routerLinkActive]="!isMobile ? 'active' : ''"
                [skipLocationChange]="true"
                [analyticsEvent]="'navigation:click-primary-menu:consumptions'"
              >
                {{ 'header.primaryMenu.consumptions.label' | translate }}
              </a>
              <div class="submenu-wrapper" *ngIf="eliqAccessRights$ | async as eliqAccessRights">
                <span class="submenu-back inline-block w-full md:hidden" (click)="emitMobileCloseSubmenu()">
                  {{ 'general.back' | translate }}
                </span>
                <span class="submenu-title inline-block w-full md:hidden">
                  {{ 'header.primaryMenu.consumptions.label' | translate }}
                </span>
                <ul
                  *ngIf="eliqAccessRights?.widgetAccessPerDeliveryPointReference?.length"
                  class="submenu"
                  (click)="emitMobileCloseMenu()"
                >
                  <li class="submenu-item">
                    <a
                      class="submenu-link"
                      [class.active]="isLinkActive('/consumptions')"
                      [skipLocationChange]="true"
                      routerLink="/consumptions"
                      [analyticsEvent]="'navigation:click-primary-submenu:meter-overview'"
                    >
                      {{ 'header.primaryMenu.consumptions.dashboard' | translate }}
                    </a>
                  </li>
                  <li *ngIf="eliqAccessRights?.accessPerSiteId?.seeAdvicePage" class="submenu-item">
                    <a
                      class="submenu-link"
                      routerLink="/consumptions/advice"
                      [skipLocationChange]="true"
                      [routerLinkActive]="!isMobile ? 'active' : ''"
                      [analyticsEvent]="'navigation:click-primary-submenu:my-advice'"
                    >
                      {{ 'header.primaryMenu.consumptions.myAdvice' | translate }}
                    </a>
                  </li>
                  <li *ngIf="eliqAccessRights?.accessPerSiteId?.seeHomeProfileTabAndMyHome" class="submenu-item">
                    <a
                      class="submenu-link"
                      routerLink="/consumptions/home-profile"
                      [skipLocationChange]="true"
                      [routerLinkActive]="!isMobile ? 'active' : ''"
                      [analyticsEvent]="'navigation:click-primary-submenu:home-profile'"
                    >
                      {{ 'header.primaryMenu.consumptions.myHomeProfile' | translate }}
                    </a>
                  </li>
                  <li *ngIf="eliqAccessRights?.accessPerSiteId?.seeMandatePage" class="submenu-item">
                    <a
                      class="submenu-link"
                      routerLink="/consumptions/fluvius-consents"
                      [skipLocationChange]="true"
                      [routerLinkActive]="!isMobile ? 'active' : ''"
                      [analyticsEvent]="'navigation:click-primary-submenu:fluvius-consents'"
                    >
                      {{ 'header.primaryMenu.consumptions.myFluviusConsents' | translate }}
                    </a>
                  </li>
                  <li class="submenu-item">
                    <a
                      class="submenu-link"
                      routerLink="/consumptions/meter-readings"
                      [skipLocationChange]="true"
                      [routerLinkActive]="!isMobile ? 'active' : ''"
                      [analyticsEvent]="'navigation:click-primary-submenu:meter-readings'"
                    >
                      {{ 'header.primaryMenu.consumptions.myMeterReadings' | translate }}
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ng-container>
        </ng-container>

        <ng-container *accessControl="accessRight.accessTevc">
          <li
            *ngIf="computedData?.hasChargingStations"
            class="menu-item"
            #myEv
            [routerLinkActive]="!isMobile ? 'submenu-open' : ''"
          >
            <a
              class="menu-link"
              [ngClass]="{ 'mobile-active-submenu': mobileActiveSubmenu === 'my-ev' }"
              (click)="emitMobileShowSubmenu('my-ev')"
              [skipLocationChange]="true"
              [routerLink]="!isMobile ? '/my-ev' : null"
              [routerLinkActive]="!isMobile ? 'active' : ''"
              [analyticsEvent]="'navigation:click-primary-menu:my-ev'"
              [attr.data-cy]="'my-ev--link'"
            >
              {{ 'header.primaryMenu.myEv.label' | translate }}
            </a>
            <div class="submenu-wrapper">
              <span class="submenu-back inline-block w-full md:hidden" (click)="emitMobileCloseSubmenu()">
                {{ 'general.back' | translate }}
              </span>
              <span class="submenu-title inline-block w-full md:hidden">
                {{ 'header.primaryMenu.myEv.label' | translate }}
              </span>
              <ul class="submenu" (click)="emitMobileCloseMenu()">
                <li class="submenu-item">
                  <a
                    class="submenu-link"
                    [class.active]="isLinkActive('/my-ev')"
                    [skipLocationChange]="true"
                    routerLink="/my-ev"
                    [analyticsEvent]="'navigation:click-primary-submenu:my-ev'"
                    [attr.data-cy]="'my-ev__dashboard--link'"
                  >
                    {{ 'header.primaryMenu.myEv.dashboard' | translate }}
                  </a>
                </li>
                <li class="submenu-item">
                  <a
                    class="submenu-link"
                    routerLink="/my-ev/reports"
                    [skipLocationChange]="true"
                    [routerLinkActive]="!isMobile ? 'active' : ''"
                    [analyticsEvent]="'navigation:click-primary-submenu:my-ev-reports'"
                    [attr.data-cy]="'my-ev__reports--link'"
                  >
                    {{ 'header.primaryMenu.myEv.reports' | translate }}
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ng-container>

        <!-- Contracts with submenu -->
        <ng-container
          *multipleAccessControl="{ rights: [accessRight.accessMyOtherProducts, accessRight.accessContracts] }"
        >
          <!-- <li *ngIf="userType.hasContracts" class="menu-item" #contracts [routerLinkActive]="!isMobile ? 'submenu-open' : ''" > -->
          <li class="menu-item" #contracts [routerLinkActive]="!isMobile ? 'submenu-open' : ''">
            <a
              class="menu-link"
              [ngClass]="{ 'mobile-active-submenu': mobileActiveSubmenu === 'contracts' }"
              (click)="emitMobileShowSubmenu('contracts')"
              [routerLink]="!isMobile ? '/contracts' : null"
              [routerLinkActive]="!isMobile ? 'active' : ''"
              [skipLocationChange]="true"
              [analyticsEvent]="'navigation:click-primary-menu:contracts'"
            >
              {{ 'header.primaryMenu.contracts.label' | translate }}
            </a>
            <div class="submenu-wrapper">
              <span class="submenu-back inline-block w-full md:hidden" (click)="emitMobileCloseSubmenu()">
                {{ 'general.back' | translate }}
              </span>
              <span class="submenu-title inline-block w-full md:hidden">
                {{ 'header.primaryMenu.contracts.label' | translate }}
              </span>
              <ul class="submenu" (click)="emitMobileCloseMenu()">
                <li class="submenu-item">
                  <a
                    class="submenu-link"
                    [class.active]="isLinkActive('/contracts')"
                    [skipLocationChange]="true"
                    routerLink="/contracts"
                    [analyticsEvent]="'navigation:click-primary-submenu:contracts'"
                  >
                    {{ 'header.primaryMenu.contracts.myContracts' | translate }}
                  </a>
                </li>
                <li class="submenu-item">
                  <a
                    class="submenu-link"
                    [class.active]="isLinkActive('/contracts/other')"
                    [skipLocationChange]="true"
                    routerLink="/contracts/other"
                    [analyticsEvent]="'navigation:click-primary-submenu:other-contracts'"
                  >
                    {{ 'header.primaryMenu.contracts.myOtherContracts' | translate }}
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ng-container>

        <!-- Contracts without submenu -->
        <ng-container
          *multipleAccessControl="{
            rights: [accessRightOperator.negation + accessRight.accessMyOtherProducts, accessRight.accessContracts]
          }"
        >
          <!--<li *ngIf="userType.hasContracts" class="menu-item" (click)="emitMobileCloseMenu()" >-->
          <li class="menu-item" (click)="emitMobileCloseMenu()">
            <a
              class="menu-link no-submenu"
              routerLink="/contracts"
              routerLinkActive="active"
              [skipLocationChange]="true"
              [analyticsEvent]="'navigation:click-primary-menu:contracts'"
              >{{ 'header.primaryMenu.contracts.label' | translate }}</a
            >
          </li>
        </ng-container>

        <li *accessControl="accessRight.accessSponsorship" class="menu-item" (click)="emitMobileCloseMenu()">
          <a
            class="menu-link no-submenu"
            routerLink="/sponsorship"
            [skipLocationChange]="true"
            routerLinkActive="active"
            [analyticsEvent]="'navigation:click-primary-menu:sponsorship'"
            >{{ 'header.primaryMenu.mgm.label' | translate }}</a
          >
        </li>

        <li *accessControl="accessRight.accessInjection" class="menu-item" (click)="emitMobileCloseMenu()">
          <a
            class="menu-link no-submenu"
            routerLink="/self-billing"
            routerLinkActive="active"
            [skipLocationChange]="true"
            [analyticsEvent]="'navigation:click-primary-menu:self-billing'"
            >{{ 'header.primaryMenu.injection.label' | translate }}</a
          >
        </li>
      </ng-container>
    </ng-container>

    <li class="menu-item" [routerLinkActive]="!isMobile ? 'submenu-open' : ''">
      <a
        class="menu-link preferences text-primary md:text-grey-dark"
        [ngClass]="{ 'mobile-active-submenu': mobileActiveSubmenu === 'help' }"
        (click)="emitMobileShowSubmenu('help')"
        [routerLink]="!isMobile ? '/help' : null"
        [routerLinkActive]="!isMobile ? 'active' : ''"
        [skipLocationChange]="true"
        [analyticsEvent]="'navigation:click:Helpcenter'"
      >
        {{ 'header.secondaryMenu.helpAndContact.label' | translate }}
      </a>

      <div class="submenu-wrapper">
        <span class="submenu-back inline-block w-full md:hidden" (click)="emitMobileCloseSubmenu()">
          {{ 'general.back' | translate }}
        </span>
        <span class="submenu-title inline-block w-full md:hidden">
          {{ 'header.secondaryMenu.helpAndContact.label' | translate }}
        </span>
        <ul class="submenu" (click)="emitMobileCloseMenu()">
          <li class="submenu-item">
            <a
              class="submenu-link"
              [href]="facade.utils.getContactUsUrl()"
              target="_blank"
              [routerLinkActive]="!isMobile ? 'active' : ''"
              [analyticsEvent]="'directLink:click:help-center'"
            >
              {{ 'components.quickLinks.helpcenter' | translate }}
            </a>
          </li>
          <li class="submenu-item">
            <a
              class="submenu-link"
              [href]="facade.utils.getContactUsUrl()"
              target="_blank"
              [routerLinkActive]="!isMobile ? 'active' : ''"
              [analyticsEvent]="'directLink:click:help-center'"
            >
              {{ 'components.quickLinks.contactUs' | translate }}
            </a>
          </li>
        </ul>
      </div>
    </li>

    <li class="menu-item" #preferences [routerLinkActive]="!isMobile ? 'submenu-open' : ''">
      <a
        class="menu-link preferences text-primary md:text-grey-dark"
        [ngClass]="{ 'mobile-active-submenu': mobileActiveSubmenu === 'preferences' }"
        (click)="emitMobileShowSubmenu('preferences')"
        [routerLink]="!isMobile ? '/preferences' : null"
        [routerLinkActive]="!isMobile ? 'active' : ''"
        [skipLocationChange]="true"
      >
        {{ 'header.primaryMenu.preferences.label' | translate }}
      </a>
      <div class="submenu-wrapper">
        <span class="submenu-back inline-block w-full md:hidden" (click)="emitMobileCloseSubmenu()">
          {{ 'general.back' | translate }}
        </span>
        <span class="submenu-title inline-block w-full md:hidden">
          {{ 'header.primaryMenu.preferences.label' | translate }}
        </span>
        <ul class="submenu" (click)="emitMobileCloseMenu()">
          <li class="submenu-item">
            <a
              class="submenu-link"
              routerLink="/preferences/references"
              [skipLocationChange]="true"
              [routerLinkActive]="!isMobile ? 'active' : ''"
              [analyticsEvent]="'navigation:secondary-submenu:my-references'"
            >
              {{ 'header.primaryMenu.preferences.myClientReferences' | translate }}
            </a>
          </li>
          <li *accessControl="accessRight.modifyContactDetails" class="submenu-item">
            <a
              class="submenu-link"
              [routerLinkActiveOptions]="{ exact: true }"
              [skipLocationChange]="true"
              routerLink="/preferences/contact"
              [routerLinkActive]="!isMobile ? 'active' : ''"
              [analyticsEvent]="'navigation:secondary-submenu:contact'"
            >
              {{ 'header.primaryMenu.preferences.myContactDetails' | translate }}
            </a>
          </li>

          <ng-container
            *multipleAccessControl="{
              rights: [accessRight.accessBuildingInfo, accessRight.accessEnergyInsights]
            }"
          >
            <li class="submenu-item">
              <a
                class="submenu-link"
                [routerLinkActiveOptions]="{ exact: true }"
                [skipLocationChange]="true"
                routerLink="/preferences/house"
                [routerLinkActive]="!isMobile ? 'active' : ''"
                [analyticsEvent]="'navigation:secondary-submenu:my-house'"
              >
                {{ 'header.primaryMenu.preferences.myHouse' | translate }}
              </a>
            </li>
          </ng-container>

          <li class="submenu-item">
            <a
              class="submenu-link"
              [routerLinkActiveOptions]="{ exact: true }"
              [skipLocationChange]="true"
              routerLink="/preferences/consent"
              [routerLinkActive]="!isMobile ? 'active' : ''"
              [analyticsEvent]="'navigation:secondary-submenu:my-privacy'"
            >
              {{ 'header.primaryMenu.preferences.myPrivacy' | translate }}
            </a>
          </li>
          <li class="submenu-item" *accessControl="accessRight.accessMove">
            <a
              class="submenu-link"
              [routerLinkActiveOptions]="{ exact: true }"
              [skipLocationChange]="true"
              routerLink="/preferences/move"
              [routerLinkActive]="!isMobile ? 'active' : ''"
              [analyticsEvent]="'navigation:secondary-submenu:move'"
            >
              {{ 'header.primaryMenu.preferences.move' | translate }}
            </a>
          </li>
          <li class="submenu-item">
            <a
              class="submenu-link"
              [href]="accountPage"
              target="_blank"
              [analyticsEvent]="'navigation:secondary-submenu:my-account'"
            >
              {{ 'header.primaryMenu.preferences.myAccount' | translate }}
            </a>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</div>
