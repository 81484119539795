import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  DataType,
  HomeProfileMultiQuestions,
  HomeProfileQuestion,
  HomeProfileQuestionType,
} from '@app/modules/customer-zone/consumption/services/home-profile/home-profile.service';
import { MainFacade } from '@app/core/facade/main.facade';
import { isArray } from 'chart.js/helpers';

@Component({
  selector: 'app-home-profile-item',
  templateUrl: './home-profile-item.component.html',
  styleUrls: ['./home-profile-item.component.scss'],
})
export class HomeProfileItemComponent implements OnInit {
  @Input() item: HomeProfileQuestion | HomeProfileMultiQuestions;
  @Input() questionType: HomeProfileQuestionType;
  @Output() itemEmitter: EventEmitter<HomeProfileQuestion | HomeProfileMultiQuestions> = new EventEmitter<
    HomeProfileQuestion | HomeProfileMultiQuestions
  >();
  @Input() formUpdate: any = null;
  value: string;
  isUpdating: boolean = false;
  initialValue: string;

  constructor(private facade: MainFacade) {}

  ngOnInit(): void {
    this.value = this.getValue();
    this.initialValue = this.getValue();
    this.facade.translate.onLangChange.subscribe(() => (this.value = this.getValue()));
  }

  ngOnChanges(data): void {
    this.isUpdating = false;
    /* Update view (value) on form update --> avoid making call */
    if (data?.formUpdate?.currentValue && [this.formUpdate.key, this.formUpdate.category].includes(this.item?.key)) {
      this.item.value = this.updateValuesFromPayload();
      this.value = this.formUpdate?.status === 2 ? this.initialValue : this.getValue();
      this.isUpdating = this.formUpdate?.status === 1;
    }
  }

  emitItem(value: HomeProfileQuestion | HomeProfileMultiQuestions): void {
    this.itemEmitter.emit({ ...value, questionType: this.questionType });
  }

  private updateValuesFromPayload() {
    return isArray(this.item.value)
      ? this.item.value.map((val) => ({
          ...val,
          value: this.formUpdate.payload.find(({ key }) => key.includes(val.key)).value,
        }))
      : this.formUpdate.payload[0].value;
  }

  private getValue(): string {
    if (this.questionType === HomeProfileQuestionType.multi) {
      const items: HomeProfileQuestion[] = this.item.value as [];
      const values = [];
      const result: any = items
        .map((item: HomeProfileQuestion) => {
          if (item.value !== null) {
            values.push(item.value);
          }
          return item.dataType === DataType.number && Number(item?.value) > 0
            ? `${item.value} ${this.translateValue(item, false)}${this.translateSuffix(item)}`
            : '';
        })
        .filter((item: string) => item !== '');
      return this.getMultiValuesTranslated(result, values);
    } else {
      this.item = this.item as HomeProfileQuestion;
      if (this.item?.dataType === DataType.multiSelect) {
        return this.item?.value ? (this.item?.value as string[]).join(', ') : '?';
      }
      return this.item?.value ? this.translateValue(this.item) + this.translateSuffix(this.item) : '?';
    }
  }

  private getMultiValuesTranslated(result, values) {
    if (result.length === 0 && values.length === 0) {
      return '?'; // If never answered
    } else if (result.length === 0 && values.length !== 0) {
      return this.facade.translate.instant('components.homeProfileForm.list.noEquipment.label'); // If answered only 0 values
    } else {
      return result.join(', '); // If has values
    }
  }

  private translateValue(item, returnValue = true) {
    if (item['dataType'] === DataType.singleSelect || item['dataType'] === DataType.multiSelect) {
      return this.facade.translate.instant(`components.homeProfileForm.list.${item?.key}.choices.${item.value}`);
    } else if (this.questionType === HomeProfileQuestionType.multi && item['dataType'] === DataType.number) {
      return this.facade.translate.instant(`components.homeProfileForm.list.${this.item?.key}.${item.key}.label`);
    }
    return returnValue ? item.value : '';
  }

  private translateSuffix(item) {
    if (!item?.suffix) {
      return '';
    }
    return ' ' + this.facade.translate.instant(`components.homeProfileForm.general.${item.suffix}`).toLowerCase();
  }
}
