<div class="card te-border-gradient-home">
  <span class="font-medium text-grey-dark60 mb-4">{{ 'components.chatCTA.subtitle' | translate }}</span>
  <h3 class="leading-normal font-medium text-2xl text-grey-dark mt-2 inline-block w-full">
    {{ 'components.chatCTA.title' | translate }}
  </h3>
  <button
    [analyticsEvent]="'contact:click:chat'"
    [attr.disabled]="agentAvailable === true && chatInstantiated === false ? null : ''"
    class="btn btn-secondary my-8"
    (click)="openChat()"
  >
    <i class="icon-chat"></i>
    {{ 'components.chatCTA.button' | translate }}
  </button>
  <ng-container *ngIf="content$ | async as content">
    <p class="btn-" *ngIf="content.chatSchedule.label !== (content.chatSchedule.label | translate)">
      {{ content.chatSchedule.label | translate }}
    </p>
  </ng-container>
</div>
