import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ChargingStation, ChargingStationSession } from '../../models/charging-station.interface';
import { Observable } from 'rxjs/internal/Observable';
import { filter, map, skip } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-station-weekly-data',
  templateUrl: './station-weekly-data.component.html',
  styleUrls: ['./station-weekly-data.component.scss'],
})
export class StationWeeklyDataComponent implements OnInit, OnDestroy {
  @Input() chargingStation$: BehaviorSubject<ChargingStation>;
  @Input() chargingStationSessions$: Observable<ChargingStationSession[]>;
  @Output() rangeSelected: EventEmitter<{ selectedDateFrom: Date; selectedDateTo: Date }> = new EventEmitter<{
    selectedDateFrom: Date;
    selectedDateTo: Date;
  }>();

  locale: string;
  activeDateRange$: BehaviorSubject<{ dateFrom: Date; dateTo: Date }> = new BehaviorSubject<{
    dateFrom: Date;
    dateTo: Date;
  }>({
    dateFrom: moment().startOf('isoWeek').toDate(),
    dateTo: moment().endOf('isoWeek').toDate(),
  });
  currentDay: Date;

  private notifier$ = new Subject<void>();

  constructor(private translate: TranslateService) {}

  get totalConsumption(): Observable<number> {
    return this.chargingStationSessions$.pipe(
      map((sessions) => sessions?.reduce((total, session) => total + session.totalEnergy / 1000, 0))
    );
  }

  get totalPrice(): Observable<number> {
    return this.chargingStationSessions$.pipe(
      map((sessions) => sessions?.reduce((total, session) => total + session.totalAmount, 0))
    );
  }

  ngOnInit(): void {
    // we use currentDay for the correct display of the 'next' pagination arrow
    this.currentDay = moment().toDate();

    this.locale = this.translate.currentLang + '-BE';
    this.translate.onLangChange.pipe(takeUntil(this.notifier$)).subscribe((value) => {
      this.locale = value.lang + '-BE';
    });

    // Reset the active range when we switch charging station.
    this.chargingStation$
      .pipe(
        filter((value) => value !== null),
        takeUntil(this.notifier$)
      )
      .subscribe(() => {
        const dateFrom = moment().startOf('isoWeek').toDate();
        const dateTo = moment().endOf('isoWeek').toDate();
        this.activeDateRange$.next({ dateFrom, dateTo });
      });

    this.activeDateRange$.pipe(takeUntil(this.notifier$)).subscribe(({ dateFrom, dateTo }) => {
      this.rangeSelected.emit({
        selectedDateFrom: dateFrom,
        selectedDateTo: dateTo,
      });
    });
  }

  ngOnDestroy() {
    this.notifier$.next();
    this.notifier$.complete();
  }

  selectedRangeChanged({ selectedDateFrom, selectedDateTo }): void {
    this.activeDateRange$.next({ dateFrom: selectedDateFrom, dateTo: selectedDateTo });
  }

  next() {
    this.activeDateRange$.next({
      dateFrom: moment(this.activeDateRange$.value.dateFrom).add(1, 'week').toDate(),
      dateTo: moment(this.activeDateRange$.value.dateTo).add(1, 'week').toDate(),
    });
  }

  previous() {
    this.activeDateRange$.next({
      dateFrom: moment(this.activeDateRange$.value.dateFrom).subtract(1, 'week').toDate(),
      dateTo: moment(this.activeDateRange$.value.dateTo).subtract(1, 'week').toDate(),
    });
  }
}
