import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ReferenceService } from '../../../modules/customer-zone/user/services/reference/reference.service';
import { distinctUntilChanged, filter, take } from 'rxjs';
import { forkJoin } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { SiteService } from '../../../modules/customer-zone/user/services/site/site.service';
import { ActiveSitesIdPerReference, Site } from '../../../modules/customer-zone/user/models/site.interface';
import { AnalyticsService } from '../../../shared/modules/analytics/services/analytics.service';
import { GAPageData } from '../models/gadata';
import { TranslateService } from '@ngx-translate/core';
import {
  AccessRights,
  EliqAccessRights,
  Profile,
} from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { UtilsService } from '@app/shared/utils/utils.service';
import { ContractDtoCuzoApi } from '@app/shared/models/cuzo-be-contract';

interface ClientInfo {
  reference?: string;
  site?: Site;
  accessRights?: AccessRights;
  eliqAccessRights?: EliqAccessRights;
  contracts?: ContractDtoCuzoApi[];
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsTrackingService {
  private reference: string;

  constructor(
    private router: Router,
    private analyticsService: AnalyticsService,
    private referenceService: ReferenceService,
    private siteService: SiteService,
    private translate: TranslateService,
    private utils: UtilsService,
    protected keycloakService: KeycloakService
  ) {}

  public init(isMobileApp: boolean) {
    this.initTracking(isMobileApp);
    this.pushOnReferenceSwitch();
  }

  push(data: GAPageData, clientInfo: ClientInfo = null) {
    const newData = {
      ...data,
      site: {
        target: clientInfo?.accessRights?.businessContactDetails ? 'pro-sme' : 'residential',
      },
      page: {
        language: this.translate.currentLang,
        path: this.router.url,
        ...data.page,
      },
      user: {
        CustomerReference: clientInfo?.reference || this.reference,
        referenceAddress: clientInfo?.site?.id,
        ...data.user,
      },
    };
    if (clientInfo?.eliqAccessRights && clientInfo?.contracts) {
      newData['user']['category'] = this.getMeterCategory(clientInfo?.eliqAccessRights, clientInfo?.contracts);
    }
    this.analyticsService.push(newData);
  }

  private getMeterCategory(eliqAccessRights: EliqAccessRights, contracts: ContractDtoCuzoApi[]) {
    const profile: Profile = this.utils?.getSiteProfile(eliqAccessRights, contracts);
    return this.utils?.getMeterConfig(profile);
  }

  private initTracking(isMobileApp: boolean) {
    let uuid = null;
    let token = null;
    if (!isMobileApp) {
      const keycloakInstance = this.keycloakService.getKeycloakInstance();
      uuid = keycloakInstance.hasOwnProperty('tokenParsed') ? keycloakInstance.tokenParsed.sub : null;
      token = keycloakInstance.hasOwnProperty('token') ? keycloakInstance.token : null;
    }
    if (isMobileApp || (uuid && token)) {
      forkJoin([
        this.referenceService.getActiveReference().pipe(
          filter((ref) => ref !== null),
          take(1)
        ),
        this.siteService.getActiveSiteIdPerReference().pipe(
          filter((siteRef) => siteRef !== null),
          take(1)
        ),
      ]).subscribe(([reference, activeSiteIdPerReference]: Array<string | ActiveSitesIdPerReference>) => {
        if (reference && activeSiteIdPerReference) {
          this.reference = reference as string;
          this.analyticsService.push({
            user: {
              CustomerReference: reference as string, // eslint-disable-line @typescript-eslint/naming-convention
              // eslint-disable-next-line @typescript-eslint/naming-convention
              SiteIdPerReference: activeSiteIdPerReference as ActiveSitesIdPerReference,
              Uuid: uuid, // eslint-disable-line @typescript-eslint/naming-convention
              Token: token, // eslint-disable-line @typescript-eslint/naming-convention
            },
          });
        }
      });
    }
  }

  private pushOnReferenceSwitch() {
    this.referenceService
      .getActiveReference()
      .pipe(distinctUntilChanged())
      .subscribe((reference: string) => {
        if (reference) {
          this.reference = reference;
          this.analyticsService.push({
            event: 'pageview',
            page: this.router.url,
            user: { CustomerReference: reference }, // eslint-disable-line @typescript-eslint/naming-convention
          });
        }
      });
  }
}
