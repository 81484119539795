import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Discount } from '../../models/discount.interface';
import { AppConfig } from '@app/shared/utils/app-config';

@Injectable({
  providedIn: 'root',
})
export class DiscountService {
  private discount$: BehaviorSubject<Discount> = new BehaviorSubject<Discount>(null);

  constructor(private http: HttpClient, private conf: AppConfig) {}

  public getDiscount(): Observable<Discount> {
    if (!this.discount$.value) {
      this.getDiscountFromApi().subscribe((value: Discount) => {
        this.discount$.next(value);
      });
    }

    return this.discount$;
  }

  public getDiscountFromApi() {
    return this.http.get<Discount>(
      `${this.conf.config.url.website}/api/v1/member_get_member/configuration?_format=json&_anonymous=`
    );
  }
}
