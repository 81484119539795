import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { LanguageService } from '../../../../core/language/services/language.service';
import { TranslationWidth } from '@angular/common';

const I18N_VALUES = {
  fr: {
    weekdays: ['L', 'M', 'M', 'J', 'V', 'S', 'D'],
    months: [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Août',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre',
    ],
    monthsShort: ['Jan', 'Févr', 'Mars', 'Avril', 'Mai', 'Juin', 'Juill', 'Août', 'Sept', 'Oct', 'Nov', 'Déc'],
  },
  nl: {
    weekdays: ['M', 'D', 'W', 'D', 'V', 'Z', 'Z'],
    months: [
      'Januari',
      'Februari',
      'Maart',
      'April',
      'Mei',
      'Juni',
      'Juli',
      'Augustus',
      'September',
      'Oktober',
      'November',
      'December',
    ],
    monthsShort: ['Jan', 'Feb', 'Maart', 'Apr', 'Mei', 'Juni', 'Juli', 'Aug', 'Sept', 'Okt', 'Nov', 'Dec'],
  },
};

@Injectable({
  providedIn: 'root',
})
export class I18n {
  public language;

  constructor(private languageService: LanguageService) {
    this.language = this.languageService.init();
    this.languageService.getCurrentLanguage().subscribe((lang) => (this.language = lang));
  }
}

@Injectable({
  providedIn: 'root',
})
export class DatepickerI18n extends NgbDatepickerI18n {
  constructor(private i18n: I18n) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return I18N_VALUES[this.i18n.language].weekdays[weekday - 1];
  }

  getMonthShortName(month: number): string {
    return I18N_VALUES[this.i18n.language].monthsShort[month - 1];
  }

  getMonthFullName(month: number): string {
    return I18N_VALUES[this.i18n.language].months[month - 1];
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  getAllMonths(): string[] {
    return I18N_VALUES[this.i18n.language].months;
  }

  getAllShortMonths(): string[] {
    return I18N_VALUES[this.i18n.language].monthsShort;
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    return I18N_VALUES[this.i18n.language].weekdays[weekday - 1];
  }
}
