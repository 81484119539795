<div class="form-element custom-select inline-block w-full md:w-80">
  <div class="select" ngbDropdown>
    <div class="selected-option" ngbDropdownToggle>
      <span *ngIf="active">{{ options[active] }}</span>
      <span *ngIf="!active">{{ 'components.invoices.filters.yearAll' | translate }}</span>
    </div>
    <ul ngbDropdownMenu aria-labelledby="filter" class="options">
      <ng-container *ngFor="let option of options | keyvalue">
        <li ngbDropdownItem (click)="apply(option.key)" [attr.data-cy]="option.key">{{ option.value }}</li>
      </ng-container>
    </ul>
  </div>
</div>
