import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import {
  ActivateMandateResponse,
  Mandate,
  MandateStatus,
} from '@app/modules/customer-zone/consumption/models/mandates.interface';
import { Contract } from '@app/modules/customer-zone/contracts/models/contract.interface';
import { EliqAccessRights } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { FaqItem } from '@app/shared/modules/faq/models/faqItem.interface';
import { MainFacade } from '@app/core/facade/main.facade';
import { Observable } from 'rxjs';

enum GlobalMandateStatus {
  requiredMandateRequested = 'REQUIRED_MANDATE_REQUESTED',
  missingRequiredMandate = 'MISSING_REQUIRED_MANDATE',
  missingMandate = 'MISSING_MANDATE',
}
@Component({
  selector: 'app-fluvius-mandates-intro',
  templateUrl: './fluvius-mandates-intro.component.html',
  styleUrls: ['./fluvius-mandates-intro.component.scss'],
})
export class FluviusMandatesIntroComponent implements OnChanges {
  readonly GlobalMandateStatus = GlobalMandateStatus;
  @Input() mandates?: Mandate[];
  @Input() contracts?: Contract[];
  @Input() eliqAccessRights: EliqAccessRights;
  @Output() showListOfMandates: EventEmitter<boolean> = new EventEmitter<boolean>();
  globalMandateStatus: GlobalMandateStatus;
  faqItems: FaqItem[];
  fluviusLink$: Observable<ActivateMandateResponse>;

  constructor(private facade: MainFacade) {}

  ngOnChanges() {
    if (this.eliqAccessRights?.profileByDeliveryPointReference) {
      const eans: string[] = Object.keys(this.eliqAccessRights?.profileByDeliveryPointReference);
      this.globalMandateStatus = this.getGlobalMandateStatus(eans);
      this.showListOfMandates.emit(this.globalMandateStatus === GlobalMandateStatus.requiredMandateRequested);
      this.faqItems = this.getFaqItems(this.globalMandateStatus);
      this.facade.translate.onLangChange.subscribe(() => (this.faqItems = this.getFaqItems(this.globalMandateStatus)));
      this.fluviusLink$ = this.facade.getShortLinkMandateActivation();
    }
  }

  private getGlobalMandateStatus(eans: string[]): GlobalMandateStatus {
    if (!this.mandates?.length || !eans.length) {
      return GlobalMandateStatus.missingMandate;
    }
    return this.hasActiveMandateForEveryEAN(eans)
      ? GlobalMandateStatus.missingRequiredMandate
      : GlobalMandateStatus.missingMandate;
  }

  private hasActiveMandateForEveryEAN(eans: string[]): boolean {
    return eans.every((ean: string) =>
      this.mandates.some(
        (mandate: Mandate) => mandate.ean === ean && mandate.active && mandate.status === MandateStatus.approved
      )
    );
  }

  private getFaqItems(globalMandateStatus: GlobalMandateStatus): FaqItem[] {
    if (globalMandateStatus === GlobalMandateStatus.missingMandate) {
      return [
        {
          title: this.facade.translate.instant('components.fluviusConsents.intro.faq.noMandate.item_one.title'),
          abstract: this.facade.translate.instant('components.fluviusConsents.intro.faq.noMandate.item_one.content'),
          url: null,
        },
        {
          title: this.facade.translate.instant('components.fluviusConsents.intro.faq.noMandate.item_two.title'),
          abstract: this.facade.translate.instant('components.fluviusConsents.intro.faq.noMandate.item_two.content'),
          url: null,
        },
        {
          title: this.facade.translate.instant('components.fluviusConsents.intro.faq.noMandate.item_three.title'),
          abstract: this.facade.translate.instant('components.fluviusConsents.intro.faq.noMandate.item_three.content'),
          url: null,
        },
      ];
    } else if (globalMandateStatus === GlobalMandateStatus.missingRequiredMandate) {
      return [
        {
          title: this.facade.translate.instant(
            'components.fluviusConsents.intro.faq.missingRequiredMandate.item_one.title'
          ),
          abstract: this.facade.translate.instant(
            'components.fluviusConsents.intro.faq.missingRequiredMandate.item_one.content'
          ),
          url: null,
        },
        {
          title: this.facade.translate.instant(
            'components.fluviusConsents.intro.faq.missingRequiredMandate.item_two.title'
          ),
          abstract: this.facade.translate.instant(
            'components.fluviusConsents.intro.faq.missingRequiredMandate.item_two.content'
          ),
          url: null,
        },
        {
          title: this.facade.translate.instant(
            'components.fluviusConsents.intro.faq.missingRequiredMandate.item_three.title'
          ),
          abstract: this.facade.translate.instant(
            'components.fluviusConsents.intro.faq.missingRequiredMandate.item_three.content'
          ),
          url: null,
        },
      ];
    } else {
      return [];
    }
  }
}
