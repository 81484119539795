import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DecimalPipe, TitleCasePipe } from '@angular/common';
import { ConsumptionBarChartComponent } from './components/consumption-bar-chart/consumption-bar-chart.component';
import { DoughnutChartComponent } from './components/doughnut-chart/doughnut-chart.component';
import { SimpleBarChartComponent } from './components/simple-bar-chart/simple-bar-chart.component';
import { NgChartsModule } from 'ng2-charts';
import { MeterReadingLineChartComponent } from './components/meter-reading-line-chart/meter-reading-line-chart.component';

@NgModule({
  declarations: [
    ConsumptionBarChartComponent,
    DoughnutChartComponent,
    SimpleBarChartComponent,
    MeterReadingLineChartComponent,
  ],
  imports: [BrowserModule, NgChartsModule],
  exports: [
    ConsumptionBarChartComponent,
    DoughnutChartComponent,
    SimpleBarChartComponent,
    MeterReadingLineChartComponent,
  ],
  providers: [DecimalPipe, TitleCasePipe],
})
export class ChartModule {}
