<div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
  <div class="mb-16">
    <h2 class="mt-12 mb-8 font-display font-normal text-5xl te-text-gradient-home inline-block md:my-12">
      {{ 'pages.myClientReferences.title' | translate }}
    </h2>

    <div class="md:grid md:grid-cols-3 gap-14 mb-12">
      <ng-container *ngIf="referencesDetails$ | async as referencesDetails">
        <div *ngFor="let referenceDetails of referencesDetails" class="reference">
          <div class="box reference__wrapper" [class.active]="activeReference === referenceDetails.reference">
            <div class="flex flex-col flex-wrap h-full">
              <div class="self-start flex-1 w-full">
                <div class="mb-6 inline-block w-full text-right relative" ngbDropdown>
                  <span class="reference__action" id="toggle-action" ngbDropdownToggle>...</span>
                  <ul class="reference__actions-list" ngbDropdownMenu aria-labelledby="toggle-action">
                    <li
                      class="text-primary text-sm font-medium"
                      (click)="renameReference(referenceDetails)"
                      [analyticsEvent]="'references:click:rename-reference'"
                    >
                      {{ 'pages.myClientReferences.renameReference' | translate }}
                    </li>
                    <li
                      class="text-primary text-sm font-medium"
                      (click)="deleteReference(referenceDetails)"
                      [analyticsEvent]="'references:click:rename-reference'"
                    >
                      {{ 'pages.myClientReferences.deleteReference' | translate }}
                    </li>
                  </ul>
                </div>
                <div class="text-center pb-8 border-b border-grey">
                  <h5 class="te-text-gradient-home font-medium text-2xl mb-4">{{ referenceDetails.label }}</h5>
                  <p>
                    {{ 'pages.myClientReferences.ReferenceClientNumber' | translate }}{{ referenceDetails.reference }}
                  </p>
                </div>
                <div class="text-center pt-8 reference__address" *ngFor="let site of referenceDetails.sites">
                  <div class="inline-block w-full">
                    <app-site-address [site]="site"></app-site-address>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <button
                  *ngIf="activeReference !== referenceDetails.reference"
                  class="mt-12 btn btn-primary"
                  (click)="switchReference(referenceDetails.reference)"
                  [analyticsEvent]="'references:click:activate-reference'"
                >
                  {{ 'general.select' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="reference relative">
        <div class="flex flex-col flex-wrap reference__wrapper--basic">
          <div class="item-center">
            <span class="reference__add-icon">+</span>
            <span class="font-bold mt-4 w-full inline-block text-primary text-base">
              {{ 'pages.myClientReferences.addReference' | translate }}
            </span>
          </div>
        </div>
        <a
          routerLink="/preferences/references/add"
          class="wrap-all"
          [analyticsEvent]="'references:click:add-reference'"
        ></a>
      </div>
    </div>

    <div class="md:grid md:grid-cols-12 gap-14 pt-16" style="border-top: 1px solid #d9d9d9">
      <div class="col-span-8">
        <div class="card-primary">
          <div class="md:flex">
            <div class="">
              <h2
                class="text-3xl text-grey-dark font-medium"
                [innerHTML]="'components.addReferenceCTA.title' | translate"
              ></h2>
              <p class="mt-8">{{ 'components.addReferenceCTA.description' | translate }}</p>
              <a
                routerLink="/preferences/references/add"
                class="btn-text font-bold text-primary mt-8 inline-block w-full"
              >
                {{ 'components.addReferenceCTA.cta' | translate }}
              </a>
            </div>
            <div class="mt-8 md:ml-16 md:mt-0">
              <div class="flex-container h-full">
                <img
                  src="./assets/img/totalenergies_binding.png?v=1.0.0"
                  width="182"
                  height="182"
                  alt="Where to find reference"
                  class="m-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-span-4">
        <app-chat-cta></app-chat-cta>
      </div>
    </div>
  </div>
</div>
