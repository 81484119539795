import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

export interface ToggleStateOptions {
  styleClass?: string;
  svgUrl?: string;
  text?: string;
}

export interface ToggleOptions {
  id?: string;
  on?: ToggleStateOptions;
  off?: ToggleStateOptions;
}

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss'],
})
export class ToggleSwitchComponent implements OnChanges {
  // following options is example of default value
  // NOTICE 1: don't define default color via component style file, it won't work (because of encapsulation)
  // NOTICE 2: for using the ON color is important to have 'peer' class and 'peer-checked' sibling selector variant,
  // because tailwind doesn't generate it from dynamically attribute
  @Input() options: ToggleOptions = {
    id: 'toggle-switch',
    on: { styleClass: 'peer-checked:bg-primary peer-checked:border-primary-dark' },
    off: { styleClass: 'bg-grey-light border-grey' },
  };
  @Input() checked = false;
  @Output() toggleSwitched = new EventEmitter<boolean>();

  public styleClass = '';

  public toggleValue(event: Event) {
    this.toggleSwitched.emit((<HTMLInputElement>event.target).checked);
    this.checked = !this.checked;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options?.currentValue) {
      this.styleClass = this.getToggleStyleClasses(changes.options.currentValue);
    }
  }

  private getToggleStyleClasses(toggleOptions: ToggleOptions): string {
    let styleClass = '';
    if (toggleOptions.on?.styleClass) {
      styleClass += toggleOptions.on.styleClass + ' ';
    }
    if (toggleOptions.off?.styleClass) {
      styleClass += toggleOptions.off.styleClass;
    }

    return styleClass;
  }
}
