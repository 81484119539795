<div class="mb-4">
    <div class="flex justify-between">
      <div>
        <img src="./assets/img/{{ energy | lowercase }}.svg" class="inline-block w-16 h-16 sm:w-24 sm:h-24" />
        <span class="align-middle ml-4 text-2xl text-grey-dark font-medium invisible sm:visible">
          {{ 'general.product.' + (energy | lowercase) | translate }}
        </span>
      </div>
      <div class="form-element input-number relative text-right" [class.errors]="control.errors?.numberRange">
        <div
          class="relative after:absolute after:right-4 after:content-['\20ac'] after:top-1/2 after:transform-gpu after:-translate-y-1/2"
        >
          <img
            src="./assets/img/icons/standalone/grey/pencil.svg"
            class="w-6 h-full inline-block absolute ml-4"
            alt=""
          />
          <input
            type="text"
            class="!w-[14.5rem] !pr-12 !pl-14"
            [imask]="numberMask"
            [maxlength]="maxLengthOfAmount"
            [formControl]="control"
            [analyticsEvent]="'depositModification:click:change-deposit-amount'"
          />
        </div>
        <span *ngIf="control.errors?.numberRange" class="message !flex justify-end before:!relative">
          {{ 'errorMessages.valueRange' | translate: { min: minAmountText, max: maxAmountText } }}
        </span>
      </div>
    </div>

    <div class="relative mt-12 mb-16" [ngClass]="{ 'mt-20': amountsOverlapped }">
      <div class="flex items-baseline">
        <div
          class="w-8 relative bg-primary rounded-l-3xl h-[0.4rem] after:absolute after:rotate-90 after:w-[1.4rem] after:border-2 after:border-grey after:rounded-3xl after:-right-[2.2rem]"
          #rangePrefix
        ></div>

        <div class="relative grow">
          <div
            *ngIf="currentAmount > minAmount"
            class="absolute -top-[2.2rem] text-sm font-medium text-grey-dark"
            [ngStyle]="{
              left: currentAmountPosition.percentage + '%',
              'margin-left': currentAmountPosition.marginLeft + 'px'
            }"
          >
            <div
              #currentAmountRef
              class="absolute -translate-x-1/2 left-2/4"
              [ngClass]="{ 'text-grey': !isSelectedCurrentAmount, 'text-primary': isSelectedCurrentAmount }"
            >
              {{ currentAmount | currency: 'EUR':'symbol':'1.2-2' }}
            </div>
          </div>

          <div
            *ngIf="currentAmount > minAmount"
            class="absolute top-4 w-4 h-4 bg-grey rounded-lg"
            [ngStyle]="{
              left: currentAmountPosition.percentage + '%',
              'margin-left': currentAmountPosition.marginLeft + 'px'
            }"
          ></div>

          <div
            *ngIf="suggestedAmount && suggestedAmount > minAmount"
            class="absolute -top-[2.2rem] text-sm font-medium text-grey-dark"
            [ngStyle]="{
              left: suggestedAmountPosition.percentage + '%',
              'margin-left': suggestedAmountPosition.marginLeft + 'px'
            }"
          >
            <div
              #suggestedAmountRef
              class="absolute -translate-x-1/2 left-2/4"
              [ngClass]="{ 'text-success-lighter': !showSuggestedAmountButton, '-top-7': amountsOverlapped }"
            >
              {{ suggestedAmount | currency: 'EUR':'symbol':'1.2-2' }}
            </div>
          </div>

          <div
            class="absolute top-4 w-4 h-4 bg-grey rounded-lg"
            [ngStyle]="{
              left: suggestedAmountPosition.percentage + '%',
              'margin-left': suggestedAmountPosition.marginLeft + 'px'
            }"
          ></div>

          <input
            type="range"
            class="input-slider"
            min="{{ minAmount }}"
            max="{{ maxAmount }}"
            step="0.01"
            value="{{ selectedAmount }}"
            [analyticsEvent]="'depositModification:click-slider:change-deposit-amount'"
            (input)="updateAmount($event.target.value)"
            #range
          />
        </div>

        <div
          class="w-8 relative bg-grey rounded-r-3xl h-[0.4rem] before:absolute before:rotate-90 before:w-[1.4rem] before:border-2 before:border-grey before:rounded-3xl before:-left-[2.2rem]"
        ></div>
      </div>

      <div class="flex justify-between mt-4 text-sm font-medium text-grey-dark60">
        <span>{{ 'general.min' | translate }} {{ minAmount | currency: 'EUR':'symbol':'1.2-2' }}</span>
        <span>{{ 'general.max' | translate }} {{ maxAmount | currency: 'EUR':'symbol':'1.2-2' }}</span>
      </div>

      <button type="button" *ngIf="showSuggestedAmountButton" (click)="setSuggestedValue()" class="btn btn-secondary">
        <img src="./assets/img/triangle-top.svg" class="inline p-1.5" />
        <span>
          {{ 'general.suggestedAmount' | translate }}
          {{ suggestedAmount | currency: 'EUR':'symbol':'1.2-2' }}
        </span>
        <img src="./assets/img/triangle-top.svg" class="inline p-1.5" />
      </button>
    </div>
</div>

<div class="invisible">
  <div #defaultSliderColor class="text-grey"></div>
  <div #positiveSliderColor class="text-success-lighter"></div>
  <div #negativeSliderColor class="text-primary"></div>
</div>
