import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartDataset, ChartType } from 'chart.js';
import { ChartColor } from '@app/modules/chart/models/chart.enums';
import { TooltipObject } from '@app/modules/chart/models/chart.interfaces';
import { ChartTooltipHelperService } from '@app/modules/chart/services/chart-tooltip-helper.service';
import 'chartjs-adapter-moment';
import { AnnotationOptions } from 'chartjs-plugin-annotation';
import Chart from 'chart.js/auto';
import moment from 'moment/moment';
import { MainFacade } from '@app/core/facade/main.facade';
import { LangChangeEvent } from '@ngx-translate/core';
import { EnergyType } from '@app/modules/customer-zone/consumption/models/consumption.interface';

@Component({
  selector: 'app-meter-reading-line-chart',
  templateUrl: './meter-reading-line-chart.component.html',
  styleUrls: ['./meter-reading-line-chart.component.scss'],
})
export class MeterReadingLineChartComponent implements OnInit, OnChanges {
  @Input() lineChartData: ChartDataset[];
  @Input() lineChartLabels: string[];
  @Input() lineChartAnnotations: AnnotationOptions[];
  @Input() energyType: EnergyType;
  @ViewChild('myChart', { static: true }) private chartRef: ElementRef<HTMLCanvasElement>;
  public lineChartType: ChartType = 'line';
  public lineChartOptions: ChartConfiguration['options'];
  private chart: Chart;

  constructor(private facade: MainFacade, private tooltipHelperService: ChartTooltipHelperService) {}

  ngOnInit(): void {
    moment.locale(`${this.facade.translate.currentLang}-BE`);
    this.facade.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      moment.locale(`${event.lang}-BE`);
      this.chart.update();
    });
  }

  ngOnChanges() {
    this.lineChartOptions = this.setChartOptions();
    this.createChart();
  }

  private createChart() {
    if (this.chart) {
      this.chart.destroy();
    }
    this.chart = new Chart(this.chartRef.nativeElement, {
      type: this.lineChartType,
      data: {
        datasets: this.lineChartData,
        labels: this.lineChartLabels,
      },
      options: this.lineChartOptions,
    });
  }

  private setChartOptions(): ChartConfiguration['options'] {
    return {
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 0,
      },
      elements: {
        line: {
          tension: 0,
        },
      },
      scales: {
        y: {
          position: 'left',
          beginAtZero: true,
        },
        x: {
          type: 'time',
          time: {
            unit: 'month',
            tooltipFormat: 'YYYY-MM-DD',
            displayFormats: {
              month: 'MMM',
            },
          },
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          onClick: null,
          position: 'bottom',
          align: 'start',
          labels: {
            usePointStyle: true,
            pointStyle: 'circle',
            padding: 20,
            boxHeight: 8,
            font: {
              size: 12,
            },
            color: ChartColor.darkGray,
          },
        },
        tooltip: {
          enabled: false,
          padding: 20,
          external: (tooltipObject: TooltipObject<'line'>) =>
            this.tooltipHelperService.createTooltip(
              tooltipObject,
              this.tooltipHelperService.getMeterReadingLineChartTemplate(tooltipObject.tooltip, this.energyType),
              this.lineChartType
            ),
        },
        annotation: {
          annotations: this.lineChartAnnotations,
        },
      },
    };
  }
}
