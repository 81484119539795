import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

export enum StepTypes {
  preloading = 'preloading',
  intro = 'intro',
  energySelection = 'energySelection',
  energy = 'energy',
  serviceNotAvailable = 'serviceNotAvailable',
  meterIndex = 'meterIndex',
  meterSelection = 'meterSelection',
  loading = 'loading',
  updateMonthlyInstallment = 'updateMonthlyInstallment',
  allGood = 'allGood',
  indexPosted = 'indexPosted',
  adjustmentNotAvailable = 'adjustmentNotAvailable',
}

@Injectable({
  providedIn: 'root',
})
export class IndexModalService {
  private step$: Subject<StepTypes> = new Subject<StepTypes>();
  private modalTitle$: Subject<string> = new Subject<string>();
  private modalIcon$: Subject<string> = new Subject<string>();

  setModalTitle(title: string): void {
    this.modalTitle$.next(title);
  }

  getModalTitle(): Observable<string> {
    return this.modalTitle$;
  }

  setModalIcon(icon: string): void {
    this.modalIcon$.next(icon);
  }

  getModalIcon(): Observable<string> {
    return this.modalIcon$;
  }

  setStep(step: StepTypes): void {
    this.step$.next(step);
  }

  getStep(): Observable<StepTypes> {
    return this.step$;
  }
}
