import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { BillingFacade } from '../../../../../core/facade/billing.facade';
import { Observable, Subscription, tap } from 'rxjs';
import { InvoiceActionsComponent } from '@app/modules/customer-zone/invoices/components/micro/invoice-actions/invoice-actions.component';
import { TranslateModule } from '@ngx-translate/core';
import { SlideToggleDirective } from '@app/shared/directives/slide-toggle.directive';
import {
  invoiceAlerts,
  InvoiceAlerts,
  InvoiceParams,
} from '@app/modules/customer-zone/invoices/models/invoice.interface';
import { AnalyticsModule } from '@app/shared/modules/analytics/analytics.module';
import { InvoicePayOnlineComponent } from '@app/modules/customer-zone/invoices/components/micro/invoice-pay-online/invoice-pay-online.component';
import { InvoiceTypeComponent } from '@app/modules/customer-zone/invoices/components/micro/invoice-type/invoice-type.component';
import { InvoiceStatusComponent } from '@app/modules/customer-zone/invoices/components/micro/invoice-status/invoice-status.component';
import { InvoiceComponent } from '@app/modules/customer-zone/invoices/components/invoice/invoice.component';
import { InvoicesModule } from '@app/modules/customer-zone/invoices/invoices.module';
import { NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import { FaqModule } from '@app/shared/modules/faq/faq.module';
import { AlertComponent } from '@app/shared/components/alert/alert.component';
import { ApiResponse } from '@app/shared/models/api.inteface';
import { InvoiceResponseV2CuzoApi } from '@app/shared/models/cuzo-be-contract';
import { ApplyFilters } from '../../components/filters/filters.component';
import { InvoiceFilters } from '@app/core/state/state';
import { GhostTableComponent } from '../../../../../shared/components/ghost-table/ghost-table.component';

@Component({
  selector: 'app-invoices',
  standalone: true,
  imports: [
    CommonModule,
    InvoiceActionsComponent,
    TranslateModule,
    SlideToggleDirective,
    AnalyticsModule,
    InvoicePayOnlineComponent,
    InvoiceTypeComponent,
    NgOptimizedImage,
    InvoiceStatusComponent,
    InvoiceComponent,
    InvoicesModule,
    NgbPagination,
    FaqModule,
    AlertComponent,
    GhostTableComponent,
  ],
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
})
export class InvoicesComponent implements OnInit, OnDestroy {
  readonly invoiceAlerts: InvoiceAlerts = invoiceAlerts;
  readonly invoicesPerPage: number = this.billingFacade.invoicesPerPage;
  invoiceFilters: InvoiceFilters = null;
  invoices$: Observable<ApiResponse<InvoiceResponseV2CuzoApi>> = this.billingFacade.invoices$.pipe(
    tap((invoices: ApiResponse<InvoiceResponseV2CuzoApi>): void => this.resetInvoiceFiltersIfReferenceChanged(invoices))
  );
  toggleFilters: boolean = false;
  activeFilters: InvoiceParams;
  activePage: number = 1;
  subscription: Subscription;
  reference: string;
  constructor(private billingFacade: BillingFacade) {}

  ngOnInit(): void {
    this.subscription = this.billingFacade.loadInvoices();
    this.billingFacade.isRedirectedFromPayNxt();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  updateFilters($event: ApplyFilters): void {
    if ($event?.filters) {
      const { filters } = $event;
      const invoiceParams: InvoiceParams = this.getInvoicesParams(0, filters);
      this.billingFacade.updateParams(invoiceParams);
      this.activeFilters = filters;
      this.activePage = 1;
    }
  }

  getInvoices(page: number): void {
    const offset: number = (page - 1) * this.invoicesPerPage;
    const invoiceParams: InvoiceParams = this.getInvoicesParams(offset);
    this.billingFacade.updateParams(invoiceParams);
    this.scrollToTop();
  }

  private scrollToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  private getInvoicesParams(offset: number, filters: Partial<InvoiceParams> = this.activeFilters): InvoiceParams {
    return {
      offset,
      ...filters,
      limit: this.invoicesPerPage,
    };
  }

  private resetFilters(invoiceFilters: InvoiceFilters, reference: string): void {
    this.reference = reference;
    this.invoiceFilters = invoiceFilters;
    this.activeFilters = null;
    this.activePage = 1;
  }

  private resetInvoiceFiltersIfReferenceChanged(invoices: ApiResponse<InvoiceResponseV2CuzoApi>): void {
    const invoiceFilters: InvoiceFilters = {
      invoiceYears: invoices?.data?.invoiceYears,
      invoiceTypes: invoices?.data?.invoiceTypes,
    };
    if (this.reference !== invoices?.data?.reference) {
      this.resetFilters(invoiceFilters, invoices?.data?.reference);
    }
  }
}
