<div class="card border-none box-shadow-small mb-8">
  <app-move-header
    [moveSite]="moveSite"
  >
  </app-move-header>

  <ng-container *ngFor="let moveData of moveSite?.moveData">
    <app-move-card
      *ngIf="moveData.status !== Status.NOT_INITIATED"
      [moveData]="moveData"
      [siteId]="moveSite?.siteId"
    >
    </app-move-card>
  </ng-container>

  <div class="foot" *ngIf="moveSite.viewStatus === ViewStatus.PART_MOVE">
    <span (click)="startMove(moveSite?.siteId)" class="btn-text inline-block w-full text-sm cursor-pointer">
      {{ 'components.move.dashboard.card.labels.moveOtherMeters' | translate }}
    </span>
  </div>
</div>
