import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AdvancesAssessment, EnergyType, Index } from '../../models/consumption.interface';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdvancesAssessmentService {
  private lastAdvanceAssessment: AdvancesAssessment = undefined;

  constructor(private http: HttpClient) {}

  public getAdvancesAssessment(
    indexRequest: Index,
    reference: string,
    siteId: string,
    energyType: EnergyType,
    deliveryPoint: string,
    loader: boolean = false
  ): Observable<AdvancesAssessment> {
    const params = new HttpParams().set('loader', loader.toString());
    return this.http
      .post<AdvancesAssessment>(
        `/v2/customers/${reference}/sites/${siteId}/contracts/${energyType}/${deliveryPoint}/advances`,
        indexRequest,
        { params }
      )
      .pipe(tap((value: AdvancesAssessment) => (this.lastAdvanceAssessment = value)));
  }

  public getLastAdvanceAssessment(): AdvancesAssessment {
    return this.lastAdvanceAssessment;
  }
}
