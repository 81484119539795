<div class="select-reference">
  <div class="py-6">
    <h3
      class="my-12 text-center"
      [innerHTML]="'general.upsell.boiler_one_shot.select_reference.title' | translate"
    ></h3>
  </div>

  <div>
    <div class="form-element input-radio-big mb-4" *ngFor="let referenceDetails of referencesDetails">
      <input
        id="{{ referenceDetails.reference }}"
        value="{{ referenceDetails.reference }}"
        type="radio"
        name="reference"
        [formControl]="control.get('reference')"
      />
      <label for="{{ referenceDetails.reference }}"> {{ referenceDetails.reference }} </label>
    </div>
    <div class="md:flex next fr my-12">
      <button
        *ngIf="control.get('reference').value !== ''"
        class="w-full btn btn-primary ml-auto md:w-auto"
        (click)="continue()"
      >
        {{ 'general.next' | translate }}
      </button>
    </div>
  </div>
</div>
