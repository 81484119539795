import { Component, Input } from '@angular/core';
import { Site } from '@app/modules/customer-zone/user/models/site.interface';

@Component({
  selector: 'app-site-address',
  templateUrl: './site-address.component.html',
  styleUrls: ['./site-address.component.scss'],
})
export class SiteAddressComponent {
  @Input() site: Site;
}
