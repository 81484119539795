import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  EnergyType,
  Index,
  IndexEncodingOrigin,
  MeterIndex,
  UseCaseMyEnergyType,
} from '../../../../models/consumption.interface';
import { AbstractControl } from '@angular/forms';
import { AdvancesAssessmentService } from '../../../../services/advancesAssessment/advances-assessment.service';
import { forkJoin, switchMap, take } from 'rxjs';
import { IndexModalService, StepTypes } from '../../../../services/index-modal/index-modal.service';
import { Direction } from '../../../../models/deliveryPoint.interface';
import { ContractService } from '../../../../../contracts/services/contract.service';
import { AdvancesService } from '@app/modules/customer-zone/billing/services/advances/advances.service';
import { MyEnergyActionsPerEAN } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { Advance, UnallowedReasonReturnedByEL1 } from '@app/modules/customer-zone/billing/models/advance.interface';
import { ContractDtoCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Component({
  selector: 'if-energy',
  templateUrl: './energy.component.html',
  styleUrls: ['./energy.component.scss'],
})
export class EnergyComponent implements OnInit {
  @Input() origin?: IndexEncodingOrigin = IndexEncodingOrigin.default;
  @Input() editIndex?: { metering: Index; contract: ContractDtoCuzoApi };
  @Input() energy: EnergyType;
  @Input() reference: string;
  @Input() activeSiteId: string;
  @Input() contracts: ContractDtoCuzoApi[];
  @Input() control: AbstractControl;
  @Input() energyActions: MyEnergyActionsPerEAN;

  @Output() formReady = new EventEmitter();

  public step: string;
  public activeContract: ContractDtoCuzoApi;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public StepTypes = StepTypes;
  public useCaseMyEnergy: UseCaseMyEnergyType = undefined;
  public suggestedAmount: number = undefined;
  public minAmount: number;

  constructor(
    private advancesAssessmentService: AdvancesAssessmentService,
    private advancesService: AdvancesService,
    private indexModalService: IndexModalService,
    private contractService: ContractService
  ) {}

  ngOnInit(): void {
    this.indexModalService.getStep().subscribe((step) => (this.step = step));
    // If reference has more than one contract, then user must select meter (meterSelection screen)
    this.indexModalService.setStep(
      this.contractService.filterDuplicateContracts(this.contracts).length > 1 && !this.editIndex
        ? StepTypes.meterSelection
        : StepTypes.meterIndex
    );
    this.activeContract = this.contracts[0];
  }

  meterSelectionReady(active: number) {
    this.activeContract = this.contracts[active];
    this.indexModalService.setStep(StepTypes.meterIndex);
  }

  meterIndexReady() {
    const date = this.control.get('meterIndex').get('date').value;
    let indexes = this.control.get('meterIndex').get('indexes').value;
    // Remove PRODUCTION direction
    indexes = indexes.filter((index) => index.direction !== Direction.production);

    const indexRequest: Index = {
      indexDate: `${date.year}-${date.month}-${date.day}`,
      indexes: [],
    };

    this.indexModalService.setStep(StepTypes.loading);

    this.advancesService
      .getAdvances(this.reference, this.activeSiteId)
      .pipe(
        switchMap((advances: Advance[]) => {
          indexes.forEach((index) => {
            indexRequest.indexes.push({
              register: index.register,
              value: Number(`${index.unit}.${index.decimal}`),
            } as MeterIndex);
          });
          const advance: Advance = advances.find(
            (advance: Advance) => advance.deliveryPoint === this.activeContract.deliveryPointReference
          );
          this.minAmount = advance.minAmountAllowedIncVat;
          indexRequest.advanceStatus =
            advance.minAmountAllowedIncVat === +advance.amount
              ? 2
              : advance.unallowedReasons.includes(UnallowedReasonReturnedByEL1.PROCESS_ONGOING)
              ? 1
              : 0;

          return forkJoin({
            assessment: this.advancesAssessmentService.getAdvancesAssessment(
              indexRequest,
              this.reference,
              this.activeSiteId,
              this.energy,
              this.activeContract.deliveryPointReference,
              true
            ),
          });
        }),
        take(1)
      )
      .subscribe(
        ({ assessment }) => {
          this.useCaseMyEnergy = assessment.useCaseMyEnergy;

          if (assessment.useCaseMyEnergy === UseCaseMyEnergyType.NO_RESPONSE) {
            this.indexModalService.setStep(StepTypes.serviceNotAvailable);
            return;
          }
          this.suggestedAmount = assessment.suggestedDeposit;
          this.control.get('monthlyInstallment').setValue(assessment.currentMonthlyDeposit); // Format to 2 decimals.
          this.indexModalService.setStep(StepTypes.updateMonthlyInstallment);
        },
        () => this.indexModalService.setStep(StepTypes.serviceNotAvailable)
      );
  }

  public continue() {
    this.formReady.emit();
  }
}
