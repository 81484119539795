<div>
  <h4>{{ 'components.consumption.index.monthlyInstallment.success.title' | translate }}</h4>
  <div class="mt-2 mb-0 sm:mt-12 sm:mb-8">
    <p>
      {{
        'components.consumption.index.monthlyInstallment.success.' + energy.toLowerCase() + 'Content'
          | translate: { previousAmount: previousAmount | number: '1.0-2', newAmount: newAmount }
      }}
    </p>
    <p>{{ 'components.consumption.index.monthlyInstallment.success.thankYou' | translate }}</p>
  </div>
  <button (click)="continue()" class="btn btn-primary font-bold inline-block w-full sm:w-auto  mt-8">
    {{ 'general.ok' | translate }}
  </button>
</div>
