import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../utils/utils.service';
import { KeycloakService } from 'keycloak-angular';
import { UserService } from '@app/modules/customer-zone/user/services/user/user.service';
import { Subject, switchMap, take, takeUntil } from 'rxjs';
import { TitleCasePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { MainFacade } from '@app/core/facade/main.facade';
import { Paths } from '@app/core/models/paths';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit, OnDestroy {
  public status: any;
  public knownError: boolean;
  private notifier: Subject<void> = new Subject();

  constructor(
    private facade: MainFacade,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private keycloakService: KeycloakService,
    private userService: UserService,
    private titlecasePipe: TitleCasePipe,
    private translateService: TranslateService,
    private router: Router
  ) {}

  ngOnInit() {
    this.utilsService.deleteCookie(this.utilsService.activeInfoCookieName);
    this.route.params.subscribe(({ status }) => {
      this.status = Number(status);
      this.knownError = this.status && [403, 404, 500, 501, 505].includes(this.status);
      if (this.status === 404 || this.status === 500) {
        this.checkForError();
      }
    });
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }

  async checkForError() {
    let subIssue;
    if (this.facade.state$.value.reference) {
      subIssue = this.facade.reference$.pipe(
        takeUntil(this.notifier),
        switchMap((reference) => this.facade.updateAccessRights(reference))
      );
    } else {
      const keycloakInstance = await this.keycloakService.getKeycloakInstance();
      const keycloakID = keycloakInstance?.tokenParsed?.sub;
      subIssue = this.userService.getUserProfile(keycloakID).pipe(take(1));
    }
    subIssue.subscribe({
      next: () => this.router.navigate(['/']),
      error: ({ status }) => {
        if (status === 500 && this.utilsService.isMissingKeycloakAccountInfo()) {
          this.facade.utils.redirectToKeycloakAccountPage();
        } else if (status !== this.status) {
          this.facade.utils.redirectToErrorPage(status);
        }
      },
    });
  }

  reloadPage() {
    this.facade.utils.redirectTo(Paths.root);
  }
}
