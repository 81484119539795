<div *ngIf="viewType === viewTypes.list; else inline" >
  <div class="text-sm">
    {{ 'components.advice.general.amountBeingSaved' | translate }}
    <div class="mt-2">
      <ng-template
        [ngTemplateOutletContext]="{ advice }"
        [ngTemplateOutlet]="amountBeingSaved"
      >
      </ng-template>
    </div>
  </div>
  <div class="text-sm mt-8">
    {{ 'components.advice.general.investmentNeeded' | translate }}
    <div class="mt-2">
      <ng-template
        [ngTemplateOutletContext]="{ advice }"
        [ngTemplateOutlet]="investmentNeeded"
      ></ng-template>
    </div>
  </div>
</div>

<ng-template #inline>
  <div class="bg-grey-light rounded-2xl p-8 mb-8 lg:flex">
    <div class="flex flex-1 lg:border-r lg:border-grey-500 lg:inline-block">
      <span class="font-medium mr-8">
        {{ 'components.advice.general.amountBeingSaved' | translate }}
      </span>
      <ng-template
        [ngTemplateOutletContext]="{ advice }"
        [ngTemplateOutlet]="amountBeingSaved"
      >
      </ng-template>
    </div>
    <div class="flex flex-1 mt-4 lg:mt-0 lg:text-right lg:inline-block">
      <span class="font-medium mr-8">
        {{ 'components.advice.general.investmentNeeded' | translate }}
      </span>
      <ng-template
        [ngTemplateOutletContext]="{ advice }"
        [ngTemplateOutlet]="investmentNeeded"
      >
      </ng-template>
    </div>
  </div>
</ng-template>

<ng-template let-advice="advice" #amountBeingSaved>
  <img
    src="./assets/img/icons/standalone/black/euro_{{ 'components.advice.list.' + advice?.key + '.amountBeingSaved' | translate: {default:3} }}.svg"
    class="inline-block ml-auto"
    height="24"
    width="100"
  />
</ng-template>

<ng-template let-advice="advice" #investmentNeeded>
  <img
    src="./assets/img/icons/standalone/default/euro_{{ 'components.advice.list.' + advice?.key + '.investmentNeeded' | translate:{default:3} }}.svg"
    class="inline-block ml-auto"
    height="24"
    width="100"
  />
</ng-template>
