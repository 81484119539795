import { Component, inject, Input, OnInit } from '@angular/core';
import { CurrencyPipe, DatePipe, NgOptimizedImage, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { MainFacade } from '../../../../../../../core/facade/main.facade';
import { TranslateModule } from '@ngx-translate/core';
import { SliceCuzoApi, SliceStatusEnumCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Component({
  selector: 'app-payment-status',
  standalone: true,
  templateUrl: './payment-status.component.html',
  imports: [NgSwitchCase, NgSwitchDefault, NgSwitch, DatePipe, CurrencyPipe, NgOptimizedImage, TranslateModule],
  styleUrls: ['../breakdown.component.scss'],
})
export class PaymentStatusComponent implements OnInit {
  @Input() slice: SliceCuzoApi;
  readonly Status = SliceStatusEnumCuzoApi;
  readonly facade: MainFacade = inject(MainFacade);

  ngOnInit(): void {}
}
