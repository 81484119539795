import { Component, OnDestroy, OnInit } from '@angular/core';
import { Content, InfoContentDescription } from '@app/shared/models/content.interface';
import { ContentService } from '@app/shared/services/content/content.service';
import { Observable, Subject, filter, map, switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'app-breaking-news',
  templateUrl: './breaking-news.component.html',
  styleUrls: ['./breaking-news.component.scss'],
})
export class BreakingNewsComponent implements OnInit, OnDestroy {
  public infoBoxTranslations: Observable<InfoContentDescription[]> = null;
  private notifier = new Subject<void>();

  constructor(public contentService: ContentService) {}

  ngOnInit(): void {
    this.infoBoxTranslations = this.contentService.getActiveContent().pipe(
      takeUntil(this.notifier),
      filter((value: Content) => !!value),
      map((value: Content) => value.infoBox)
    );
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }
}
