<div class="flex flex-1 flex-col gap-y-4 h-full">
  <div class="flex flex-1 items-end justify-center">
    <img
      class="invert h-[2.5rem]"
      [src]="'./assets/img/icons/standalone/white/' + category + '.svg'"
      [attr.alt]="'components.usageCategoryPopup.categories.' + category | translate"
    />
  </div>
  <div
    class="text-center pt-2 rounded-t-2xl transition-[width]"
    [ngClass]="styleClass"
    [ngStyle]="{ height: percentage + 30 + '%' }"
  >
    <span>{{ order }}</span>
  </div>
</div>
