import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Contract } from 'src/app/modules/customer-zone/contracts/models/contract.interface';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import {
  EnergyType,
  Index,
  IndexContext,
  RegisterTypeExtra,
} from 'src/app/modules/customer-zone/consumption/models/consumption.interface';
import { MeteringsService } from '../../../../../../services/meterings/meterings.service';
import { validMeterInput } from '../../../../../../../../../shared/utils/utils.validators';
import { UtilsService } from '../../../../../../../../../shared/utils/utils.service';
import { catchError, switchMap, take } from 'rxjs';
import { IndexModalService, StepTypes } from '../../../../../../services/index-modal/index-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { DeliveryPointService } from '../../../../../../services/deliveryPoint/delivery-point.service';
import { forkJoin, of } from 'rxjs';
import { Meter } from '../../../../../../models/deliveryPoint.interface';
import { myEnergyEvents } from '../../../../../../../../../core/analytics/models/event-defaults';
import { AnalyticsService } from '../../../../../../../../../shared/modules/analytics/services/analytics.service';
import { MyEnergyActionsPerEAN, UserType } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { MainFacade } from '@app/core/facade/main.facade';

@Component({
  selector: 'if-meter-index',
  templateUrl: './meter-index.component.html',
  styleUrls: ['./meter-index.component.scss'],
})
export class MeterIndexComponent implements OnInit {
  @Input() editIndex?: { metering: Index; contract: Contract };
  @Input() reference: string;
  @Input() activeSiteId: string;
  @Input() contract: Contract;
  @Input() control: AbstractControl;
  @Input() energyActions: MyEnergyActionsPerEAN;
  @Output() formReady = new EventEmitter();
  public formSubmitting = false;
  public today: Date = new Date();
  public lastMeterIndex: Index;
  public getError = false;
  public indexes: UntypedFormArray;
  public meterNumber: string[] = [];
  readonly energyType = EnergyType;

  constructor(
    private facade: MainFacade,
    private meteringsService: MeteringsService,
    private deliveryPointService: DeliveryPointService,
    private fb: UntypedFormBuilder,
    public utilsService: UtilsService,
    private indexModalService: IndexModalService,
    private translate: TranslateService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.analytics();
    this.updateModalTitleAndIcon();
    this.indexes = this.control.get('indexes') as UntypedFormArray;
    this.control.setValidators(this.isValidIndex.bind(this));

    forkJoin([
      this.deliveryPointService
        .getDeliveryPoint(
          this.reference,
          this.activeSiteId,
          this.contract.type as EnergyType,
          this.contract.deliveryPointReference,
          false
        )
        .pipe(take(1)),
      this.meteringsService
        .getMeterings(
          this.reference,
          this.activeSiteId,
          this.contract.type as EnergyType,
          this.contract.deliveryPointReference,
          false
        )
        .pipe(
          take(1),
          catchError(() => of([]))
        ),
    ])
      .pipe(take(1))
      .subscribe(
        ([meters, indexes]: Array<Meter[] | Index[]>) => {
          if (!meters.length) {
            this.indexModalService.setStep(StepTypes.serviceNotAvailable);
            return;
          }

          const registers = (meters as Meter[]).reduce((array, meter: Meter) => {
            this.meterNumber.push(meter.meterNumber);
            const reg = meter.registers
              .map((register) => ({ ...register, meterNumber: meter.meterNumber, smartMeter: meter.smartMeter }))
              .filter(({ timeFrame }) => timeFrame !== RegisterTypeExtra.NOT_USED);
            return [...array, ...reg];
          }, []);

          this.lastMeterIndex = { indexDate: null, indexes: registers };

          if (indexes.length > 0) {
            const [lastIndex] = (indexes as Index[])
              .sort((a, b) => new Date(b.indexDate).getTime() - new Date(a.indexDate).getTime())
              .filter((index) => ![IndexContext.PROVISION].includes(index.context));
            if (lastIndex) {
              const { indexDate, indexes: indexRegisters } = lastIndex;
              this.lastMeterIndex.indexDate = indexDate;
              this.lastMeterIndex.indexes = this.lastMeterIndex.indexes.map((meterRegister) => {
                const indexRegister = indexRegisters.find(
                  (ir) =>
                    ir.register === meterRegister.timeFrame &&
                    ir.direction === meterRegister.direction &&
                    ir.measurementNature !== 'GCF'
                );
                return { ...meterRegister, value: (indexRegister && indexRegister.value) || 0 };
              });
            }
          }

          this.buildIndexFields();
        },
        () => this.indexModalService.setStep(StepTypes.serviceNotAvailable)
      );
  }

  getIndexValues(meterIndex) {
    const defaultValues = ['', ''];
    if (!this.editIndex) {
      return defaultValues;
    }
    const existingIndex = this.editIndex.metering.indexes.find(
      (index) => meterIndex.direction === index.direction && meterIndex.timeFrame === index.register
    );
    return existingIndex ? existingIndex.value.split('.') : defaultValues;
  }

  buildIndexFields() {
    this.lastMeterIndex.indexes.forEach((meterIndex) => {
      const [unit, decimal] = this.getIndexValues(meterIndex);
      this.indexes.push(
        this.fb.group({
          register: [meterIndex.timeFrame],
          meterNumber: meterIndex.meterNumber,
          direction: meterIndex.direction,
          unit: [
            unit,
            {
              validators: [
                Validators.required,
                validMeterInput(
                  new Date(this.lastMeterIndex.indexDate),
                  meterIndex,
                  this.contract.type as EnergyType,
                  this.contract.type === EnergyType.ELECTRICITY &&
                    this.facade.state$?.value?.computedData?.hasSolarPanel &&
                    !meterIndex.smartMeter
                ),
              ],
              updateOn: 'change',
            },
          ],
          decimal: [decimal],
        })
      );
    });
  }

  isValidIndex(control: AbstractControl): { [s: string]: boolean } {
    if (control.pristine) {
      return { notValid: true };
    }
    const indexes = control.get('indexes') as UntypedFormArray;
    let errors = false;
    indexes.controls.forEach((indexControl) => {
      if (
        indexControl.get('unit').errors &&
        (indexControl.get('unit').errors.minimumNotReached || indexControl.get('unit').errors.required)
      ) {
        errors = true;
      }
      return null;
    });

    if (errors) {
      return { notValid: true };
    }
  }

  public formatDate(day, month, year) {
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;
    return `${year}-${month}-${day}`;
  }

  submit() {
    this.formSubmitting = true;
    // Post indexes & then get updated index array
    this.meteringsService
      .sendMetering(
        this.reference,
        this.activeSiteId,
        this.contract.type as EnergyType,
        this.contract.deliveryPointReference,
        this.buildPostIndexPayload(),
        false
      )
      .pipe(
        take(1),
        switchMap(() =>
          this.meteringsService.getMeterings(
            this.reference,
            this.activeSiteId,
            this.contract.type as EnergyType,
            this.contract.deliveryPointReference,
            false,
            true
          )
        )
      )
      .subscribe(
        (meterings: Index[]) => {
          let provisional = [];
          let newMeterings = [];
          if (meterings) {
            [newMeterings, provisional] = this.meteringsService.prepareIndexes(
              [...meterings].reverse(),
              this.contract.type,
              this.facade.state$?.value?.computedData?.hasSolarPanel
            );
          }
          const { deliveryPointReference } = this.contract;
          this.meteringsService.setMeterings$(deliveryPointReference, newMeterings);
          this.meteringsService.setProvisionalIndexes$(deliveryPointReference, provisional);
          this.meteringsService.updateState(meterings);
          // Check empty/failed response for mynergy-flow endpoint
          if (
            this.energyActions &&
            this.energyActions[this.contract.deliveryPointReference]?.accessToMyEnergyRecommendation
          ) {
            this.formReady.emit();
          } else {
            this.indexModalService.setStep(StepTypes.indexPosted);
          }
        },
        () => {
          if (
            this.energyActions &&
            this.energyActions[this.contract.deliveryPointReference].accessToMyEnergyRecommendation
          ) {
            this.formReady.emit();
          } else {
            this.indexModalService.setStep(StepTypes.serviceNotAvailable);
          }
        }
      );
  }

  private buildPostIndexPayload() {
    const { day, month, year } = this.control.get('date').value;
    return {
      indexDate: this.formatDate(day, month, year),
      meters: this.buildMetersArray(),
    };
  }

  private buildMetersArray() {
    const meters = [];
    const inputs = this.control.get('indexes').value;
    this.meterNumber.forEach((meter) => {
      meters.push({
        meterNumber: meter,
        indexes: inputs
          .filter((input) => input.meterNumber === meter)
          .map(({ register, unit, decimal, direction }) => ({
            register,
            value: Number(`${unit}.${decimal}`),
            direction,
          })),
      });
    });
    return meters;
  }

  private updateModalTitleAndIcon() {
    const energy = this.contract.type.toLowerCase();
    const key = this.editIndex ? 'components.indexModal.editIndexTitle' : `components.indexModal.${energy}Title`;
    const modalTitle = this.translate.instant(key);
    this.indexModalService.setModalTitle(modalTitle);

    this.indexModalService.setModalIcon(energy);
  }

  private analytics() {
    this.analyticsService.push({
      ...myEnergyEvents(`${this.translate.currentLang}/myenergy-flow/add-reading`),
      myEnergy: {
        energyType: this.contract.type,
        ean: this.contract.deliveryPointReference,
      },
    });
  }
}
