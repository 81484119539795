import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FaqItem } from '../../models/faqItem.interface';
import { Observable, Subscription } from 'rxjs';
import { FaqService } from '../../services/faq.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { UtilsService } from '../../../../utils/utils.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() tag: string;
  @Input() limit: number;
  @Input() faqURL?: string;

  public faqItems$: Observable<FaqItem[]>;
  private languageSubscription: Subscription;

  constructor(private faqService: FaqService, private translate: TranslateService, public utilsService: UtilsService) {
    this.languageSubscription = this.translate.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
      this.faqItems$ = this.faqService.getFaqItems(langChangeEvent.lang, this.tag);
    });
  }

  ngOnInit(): void {
    const lang = this.translate.currentLang;
    this.faqItems$ = this.faqService.getFaqItems(lang, this.tag);
  }

  ngOnDestroy(): void {
    this.languageSubscription.unsubscribe();
  }
}
