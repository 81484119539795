import { Component, OnDestroy, OnInit } from '@angular/core';
import { MainFacade } from '@app/core/facade/main.facade';
import { EliqAccessRights } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { catchError, distinctUntilChanged, filter, of, Subject, switchMap, takeUntil } from 'rxjs';
import { Site } from '@app/modules/customer-zone/user/models/site.interface';

@Component({
  selector: 'app-error-access',
  templateUrl: './error-access.component.html',
  styleUrls: ['./error-access.component.scss'],
})
export class ErrorAccessComponent implements OnInit, OnDestroy {
  private notifier: Subject<void> = new Subject();
  constructor(private facade: MainFacade) {}

  ngOnInit(): void {
    this.facade.activeSite$
      .pipe(
        takeUntil(this.notifier),
        filter((site: Site) => !!site),
        distinctUntilChanged(),
        switchMap((site: Site) => this.facade.loadEliqAccessRights(this.facade.state$.value.reference, site)),
        catchError(() => of(null))
      )
      .subscribe((access: EliqAccessRights) => {
        const currentURL = window.location.href;
        if (currentURL.includes('consumptions')) {
          if (
            access &&
            access?.widgetAccessPerDeliveryPointReference?.length &&
            Object.keys(access?.accessPerSiteId).length
          ) {
            this.backToConsumptions();
          }
        }
      });
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }

  backToConsumptions() {
    this.facade.utils.redirectTo('/consumptions/');
  }
}
