import { Component, Input, OnInit } from '@angular/core';
import { ReferenceDetails } from '../../models/reference.interface';
import { take } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MainFacade } from '@app/core/facade/main.facade';

@Component({
  selector: 'app-rename-reference',
  templateUrl: './rename-reference.component.html',
  styleUrls: ['./rename-reference.component.scss'],
})
export class RenameReferenceComponent implements OnInit {
  @Input() data: { referenceDetails: ReferenceDetails };
  public labelError = false;
  public label: string;

  constructor(private facade: MainFacade, public activeModal: NgbActiveModal, private toastrService: ToastrService) {}

  ngOnInit(): void {
    this.label = this.data.referenceDetails.label;
  }

  public close() {
    this.activeModal.close();
  }

  public submitData() {
    if (!this.labelError && this.label !== this.data.referenceDetails.label) {
      const body = { label: this.label };
      const reference = this.data.referenceDetails.reference;
      this.activeModal.close();

      this.facade
        .renameReference(reference, body)
        .pipe(take(1))
        .subscribe({
          next: () =>
            this.toastrService.success(
              this.facade.translate.instant('components.handleReferences.rename.successAlert', {
                reference: this.data.referenceDetails.reference,
                name: this.label,
              })
            ),
          error: () => this.toastrService.error(this.facade.translate.instant('errorMessages.serverError')),
        });
    }
  }

  public updateLabel(label: string) {
    if (this.isLabelValid(label)) {
      this.label = label;
    }
  }

  public isLabelValid(label: string) {
    this.labelError = label.length === 0;
    return !this.labelError;
  }
}
