<div class="contracts">
  <ng-container *ngIf="activeSite?.products?.length; else noActiveContract">
    <p class="font-medium text-base mb-4 text-grey-dark md:mb-8">
      {{ 'components.contracts.contractsOnYourAddress' | translate }}
    </p>

    <ng-container *ngIf="activeSite?.products?.length">
      <button
        *ngFor="let product of activeSite?.products"
        (click)="openModal(product)"
        class="align-top mb-4"
        [analyticsEvent]="'personalBanner:click-contract:' + (product.type | lowercase)"
      >
        <app-image-icon
          class="inline-block mr-8"
          altDescription="{{ 'components.contracts.products.' + product.type | translate | titlecase }}"
          imagePath="./assets/img/icons/standalone/red/{{ product.type | lowercase }}.svg"
        >
        </app-image-icon>
      </button>

      <button
        *ngIf="services?.length"
        class="align-top"
        (click)="openServices()"
        [analyticsEvent]="'personalBanner:click:add-product'"
      >
        <app-image-icon
          class="inline-block mr-4"
          [ghost]="true"
          [textStyle]="{ fontSize: '3rem', width: '100%' }"
          [text]="'+'"
        >
        </app-image-icon>
      </button>
    </ng-container>
  </ng-container>
  <ng-template #noActiveContract>
    <p class="font-medium text-base text-grey-dark">
      {{ 'components.contracts.noActiveContract' | translate }}
    </p>
  </ng-template>
</div>
