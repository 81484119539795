<div class="rounded-2xl box-shadow-small bg-grey-light p-8 md:p-12 md:flex">
  <div class="flex-1 font-normal">
    {{ 'components.paymentMethod.intro' | translate }}
    <span class="font-medium">{{ 'general.billing.BANK_TRANSFER' | translate | lowercase }}</span
    >.
  </div>
  <ng-container
    *multipleAccessControl="{
      operator: accessRightOperator.disjunction,
      rights: [accessRight.activateDom, accessRight.activateDomv]
    }"
  >
    <div class="ml-auto mt-4 md:mt-0 md:pl-4 md:self-center" *ngIf="!billingDetails.paymentMethodLocked">
      <span
        (click)="changePaymentMethod()"
        class="cursor-pointer btn-text btn-text-primary font-bold"
        [analyticsEvent]="'paymentPreferences:click:change-payment-method'"
      >
        {{ 'general.modify' | translate }}
      </span>
    </div>
  </ng-container>
</div>

<div class="mt-8 md:mt-16 mb-4">{{ 'components.paymentMethod.bankTransfer.paymentDetails' | translate }}:</div>
<div class="md:grid md:grid-cols-2 rounded-2xl bg-grey-light p-8 mb-42">
  <div class="">
    <span class="font-medium mb-2 inline-block">{{ 'general.address.label' | translate }}</span> <br />
    {{ 'general.enterprise.name' | translate }} <br />
    {{ 'general.enterprise.address.street' | translate }} {{ 'general.enterprise.address.streetNumber' | translate
    }}<br />
    {{ 'general.enterprise.address.zipCode' | translate }} {{ 'general.enterprise.address.city' | translate }} <br />
    {{ 'general.enterprise.address.country' | translate }}
  </div>
  <div class="mt-8 md:mt-0">
    <span class="font-medium mb-2 inline-block">{{ 'general.billing.label' | translate }}</span> <br />
    {{ 'general.billing.IBAN' | translate }}<span class="font-medium"> BE38 0015 0942 4272</span> <br />
    {{ 'general.billing.BIC' | translate }} <span class="font-medium"> GEBABEBB</span> <br />
    {{ 'general.billing.communication' | translate }}<span class="font-medium"> {{ reference }} </span>
  </div>
</div>

<ng-container
  *multipleAccessControl="{
    operator: accessRightOperator.disjunction,
    rights: [accessRight.activateDom, accessRight.activateDomv]
  }"
>
  <div
    *ngIf="!billingDetails.paymentMethodLocked"
    class="rounded-2xl mt-8 md:mt-16 te-border-gradient-home overflow-hidden"
  >
    <div class="direct-debit inline-block w-full p-8 md:flex md:justify-between md:p-12">
      <div class="flex-1">
        {{ 'components.paymentMethod.bankTransfer.directDebit.intro' | translate }}
      </div>
      <div class="mt-4 md:mt-0 md:pl-4 md:self-center">
        <span
          (click)="changePaymentMethod(directDebit)"
          class="cursor-pointer btn-text btn-text-primary font-bold"
          [analyticsEvent]="'paymentPreferences:click:activate-direct-debit-cta'"
        >
          {{ 'components.paymentMethod.bankTransfer.directDebit.cta' | translate }}
        </span>
      </div>
    </div>
  </div>
</ng-container>
