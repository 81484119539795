import { Component, Input, OnInit } from '@angular/core';
import { ReferenceDetails } from '../../models/reference.interface';
import { ReferenceService } from '../../services/reference/reference.service';
import { Alert } from '@app/shared/components/alert/alert.interface';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MainFacade } from '@app/core/facade/main.facade';
import { take } from 'rxjs';

@Component({
  selector: 'app-delete-reference',
  templateUrl: './delete-reference.component.html',
  styleUrls: ['./delete-reference.component.scss'],
})
export class DeleteReferenceComponent implements OnInit {
  @Input() data: { referenceDetails: ReferenceDetails };
  public alert: Alert;

  constructor(
    private facade: MainFacade,

    private referenceService: ReferenceService,
    private toastrService: ToastrService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {}

  public close() {
    this.activeModal.close();
  }

  public deleteReference() {
    const reference = this.data.referenceDetails.reference;
    this.activeModal.close();
    this.facade
      .deleteReference(reference)
      .pipe(take(1))
      .subscribe({
        next: () =>
          this.toastrService.success(
            this.facade.translate.instant('components.handleReferences.delete.successAlert', {
              name: this.data.referenceDetails.label,
            })
          ),
        error: () => this.toastrService.error(this.facade.translate.instant('errorMessages.serverError')),
      });
  }
}
