<div class="piece-of-advice" (click)="openSingleAdvice(advice)">
  <div class="lg:flex">
    <div class="tag py-4 px-6 inline-block te-border-gradient-home rounded-2xl border-2 relative overflow-hidden">
    <span class="font-medium te-text-gradient-home inline-block relative z-10">
      {{ 'components.advice.status.' + advice?.status | lowercase | translate }}
    </span>
      <span class="tag-bg absolute top-0 left-0 w-full h-full"></span>
    </div>
    <div class="p-12 flex-1">
      <h3 class="text-2xl font-medium text-grey-dark mt-4">
        {{ 'components.advice.list.' + advice?.key + '.title' | translate:{default:advice?.title} }}
      </h3>
      <div
        class="content mt-4"
        [innerHTML]="'components.advice.list.' + advice?.key + '.content' | translate:{default:advice?.content} | truncate:150"
      >
      </div>
      <button
        class="btn btn-text text-primary mt-8"
        (click)="openSingleAdvice(advice)"
      >
        {{ 'components.advice.general.readMore' | translate }}
      </button>
    </div>
    <div class="sidebar">
      <app-advice-action
        [advice]="advice"
        (emitAction)="updateAdviceStatus($event)"
      >
      </app-advice-action>
    </div>
  </div>
  <div class="px-12">
    <app-advice-investment
      [advice]="advice"
      [viewType]="viewTypes.inline"
    >
    </app-advice-investment>
  </div>
</div>
