<h3 class="text-2xl leading-tight mb-8 md:mb-16">
  {{ 'components.fluviusConsents.intro.infoTitle' | translate }}
</h3>
<div class="md:flex gap-8">
  <div class="relative bg-grey-light rounded-xl p-8 flex items-start mt-8 sm:mt-0">
    <img ngSrc="assets/img/icons/standalone/red/electricity.svg" width="24" height="24" class="mr-8"/>
    <div class="flex-1">
      <h4
        class="text-base font-normal"
        [innerHTML]="'components.fluviusConsents.intro.energy.electricity' | translate"
      >
      </h4>
      <ng-container [ngTemplateOutlet]="fluviusLink"> </ng-container>
    </div>
  </div>
  <div class="relative bg-grey-light rounded-xl p-8 flex-1 flex items-start mt-4 md:mt-0">
    <img ngSrc="assets/img/icons/standalone/red/gas.svg" width="24" height="24" class="mr-8"/>
    <div class="flex-1">
      <h4
        class="text-base font-normal"
        [innerHTML]="'components.fluviusConsents.intro.energy.gas' | translate"
      >
      </h4>
      <ng-container [ngTemplateOutlet]="fluviusLink"> </ng-container>
    </div>
  </div>
</div>

<ng-template #fluviusLink>
  <a
    class="absolute top-0 left-0 w-full h-full cursor-pointer"
    [href]="fluviusUrl"
    target="_blank">
  </a>
</ng-template>
