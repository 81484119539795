import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { catchError, combineLatest, distinctUntilChanged, filter, Observable, of, switchMap } from 'rxjs';
import { MainFacade } from '@app/core/facade/main.facade';
import { Site } from '@app/modules/customer-zone/user/models/site.interface';
import { EliqAccessRights } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { AccessRightsCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Injectable({
  providedIn: 'root',
})
export class EliqAccessResolver {
  constructor(private facade: MainFacade) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    combineLatest([this.facade.accessRights$, this.facade.activeSite$])
      .pipe(
        filter(([accessRights, site]: [AccessRightsCuzoApi, Site]) => !!accessRights?.accessEnergyInsights && !!site),
        distinctUntilChanged(),
        switchMap(([, site]: [AccessRightsCuzoApi, Site]) =>
          this.facade.loadEliqAccessRights(this.facade.state$.value.reference, site)
        ),
        catchError(() => of(null))
      )
      .subscribe((val: EliqAccessRights) => {
        const currentURL = window.location.href;
        if (currentURL.includes('consumptions')) {
          if (!val) {
            this.facade.utils.redirectTo('/consumptions/error-access');
          }
          if (route?.data?.allowedWithEliqAccess) {
            const eliqAccessRightNames: string[] = Object.keys(route.data.allowedWithEliqAccess);
            if (eliqAccessRightNames?.length) {
              const hasAccess: boolean = eliqAccessRightNames.every(
                (earn: string): boolean =>
                  val?.accessPerSiteId && val?.accessPerSiteId[earn] === route.data.allowedWithEliqAccess[earn]
              );
              if (!hasAccess) {
                this.facade.utils.redirectTo('/consumptions/');
              }
            }
          }
        }
      });
    return of(true);
  }
}
