<div class="card-primary mb-8">
  <h3 class="mb-6 text-2xl font-medium text-grey-dark">
    {{ 'components.invoiceDeliveryChannel.title' | translate }}
  </h3>
  <div class="inline-block mb-8 w-full" *ngIf="contactDetails?.locked || billingDetails?.invoiceSendingMethodLocked">
    <app-alert [alert]="alert"></app-alert>
  </div>

  <app-email
    *ngIf="billingDetails?.invoiceDeliveryChannel === email"
    [contactDetails]="contactDetails"
    [billingDetails]="billingDetails"
    [reference]="reference"
  >
  </app-email>
  <app-paper
    *ngIf="billingDetails?.invoiceDeliveryChannel === paper"
    [contactDetails]="contactDetails"
    [billingDetails]="billingDetails"
    [reference]="reference"
  >
  </app-paper>
  <app-zoomit
    *ngIf="billingDetails?.invoiceDeliveryChannel === zoomit"
    [contactDetails]="contactDetails"
    [billingDetails]="billingDetails"
    [reference]="reference"
  >
  </app-zoomit>
</div>
