import { Component, Input, OnInit } from '@angular/core';
import { MainFacade } from '@app/core/facade/main.facade';
import { TranslateModule } from '@ngx-translate/core';
import { CurrencyPipe, DatePipe, NgIf } from '@angular/common';
import { RelatedInvoicesComponent } from './related-invoices/related-invoices.component';
import { PaymentPlanInfoComponent } from '@app/modules/customer-zone/invoices/components/payment-plan/overview/payment-plan-details/payment-plan-info/payment-plan-info.component';
import { PaymentPlanCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Component({
  selector: 'app-payment-plan-details',
  standalone: true,
  templateUrl: './payment-plan-details.component.html',
  imports: [TranslateModule, CurrencyPipe, DatePipe, RelatedInvoicesComponent, NgIf, PaymentPlanInfoComponent],
  styleUrls: ['./payment-plan-details.component.scss'],
})
export class PaymentPlanDetailsComponent implements OnInit {
  @Input() paymentPlan: PaymentPlanCuzoApi;

  constructor(public facade: MainFacade) {}

  ngOnInit(): void {}
}
