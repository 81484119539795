import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs';
import { LoaderService } from '../../shared/modules/loader/services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private startTimer;
  private endTimer;

  constructor(public loaderService: LoaderService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const loader = request.params.get('loader');

    if (this.showLoader(loader)) {
      if (this.loaderService.requestsCounter === 0) {
        this.startTimer = new Date().getTime();
        this.loaderService.show();
      }
      this.loaderService.requestsCounter++;
    }

    return next.handle(request).pipe(
      finalize(() => {
        if (this.showLoader(loader)) {
          this.loaderService.requestsCounter--;
          if (this.loaderService.requestsCounter === 0) {
            this.endTimer = this.startTimer;
            while (this.endTimer < this.startTimer + 700) {
              this.endTimer = new Date().getTime();
            }
            this.loaderService.hide();
          }
        }
        this.loaderService.ignoreLoadingForSingleRequest = false;
      })
    );
  }

  private showLoader(loadParam: string) {
    return loadParam !== 'false' && !this.loaderService.ignoreLoadingForSingleRequest;
  }
}
