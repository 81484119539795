<ul class="tab">
  <li
    class="tab-item"
    [class.active]="activeOption === switchOptions.energy"
    (click)="changeActiveOption(switchOptions.energy)"
  >
    {{ 'general.product.electricity' | translate }}
  </li>
  <li
    class="tab-item"
    [class.active]="activeOption === switchOptions.price"
    (click)="changeActiveOption(switchOptions.price)"
  >
    {{ 'general.units.euro' | translate }}
  </li>
</ul>
