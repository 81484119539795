import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { ReferenceDetails } from '../../models/reference.interface';
import { Site, SitesPerReference } from '../../models/site.interface';
import { SiteService } from '../site/site.service';
import { UtilsService } from '../../../../../shared/utils/utils.service';
import { HttpClient } from '@angular/common/http';
import { SalesforceService } from '@app/shared/services/salesforce/salesforce.service';

@Injectable({
  providedIn: 'root',
})
export class ReferenceService {
  private referencesDetails$: BehaviorSubject<ReferenceDetails[]> = new BehaviorSubject<ReferenceDetails[]>(null);
  private referencesDetails: ReferenceDetails[];

  private activeReference$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private activeReference: string;

  constructor(
    private http: HttpClient,
    private siteService: SiteService,
    private utilsService: UtilsService,
    private salesforceService: SalesforceService
  ) {}

  public updateReferencesDetails(referencesDetails: ReferenceDetails[]): string | null {
    let activeReference = this.activeReference;
    this.referencesDetails = referencesDetails;
    this.referencesDetails$.next(referencesDetails);
    if (!activeReference) {
      activeReference = this.setFirstReferenceAsTheActiveOne(referencesDetails);
    }
    //this.setSitesPerReference();
    return activeReference;
  }

  public getReferencesDetails(): Observable<ReferenceDetails[]> {
    return this.referencesDetails$;
  }

  public updateActiveReference(reference: string): void {
    this.updateCookieWithActiveReference(reference);
    this.activeReference$.next(reference);
    this.activeReference = reference;
    this.salesforceService.updateSalesforceChatReference(reference);
  }

  public getActiveReference(): Observable<string> {
    return this.activeReference$;
  }

  public unbindReferenceFromApi(keycloakUserId: string, reference: string): any {
    return this.http.delete<any>(`/v1/userprofiles/${keycloakUserId}/references/${reference}`);
  }

  public renameReferenceFromApi(keycloakUserId: string, reference: string, body) {
    return this.http.put<any>(`/v1/userprofiles/${keycloakUserId}/references/${reference}`, body);
  }

  private updateCookieWithActiveReference(reference: string): void {
    this.setReferenceCookie(reference, this.utilsService.activeInfoCookieName, 'activeRef');
  }

  private setReferenceCookie(reference: string, cookieName: string, cookieProperty: string) {
    let cookie: any = this.utilsService.getCookieValue(cookieName);
    cookie = cookie || {};
    cookie[cookieProperty] = reference;
    this.utilsService.setCookieValue(cookieName, cookie);
  }

  private getActiveReferenceFromCookie() {
    const cookieName: string = this.utilsService.activeInfoCookieName;
    const activeRefFromCookie = this.utilsService.getCookieValue(cookieName, 'activeRef');
    return activeRefFromCookie;
  }

  private setFirstReferenceAsTheActiveOne(referencesDetails: ReferenceDetails[]): string | null {
    let reference = null;
    if (referencesDetails && referencesDetails.length) {
      reference = this.getActiveReferenceFromCookie();
      reference = reference ? reference : referencesDetails[0].reference;
      this.updateActiveReference(reference);
    }
    return reference;
  }

  private setSitesPerReference() {
    const sitesPerReference: SitesPerReference = {};
    this.referencesDetails.forEach(
      (referenceDetails: ReferenceDetails) => (sitesPerReference[referenceDetails.reference] = referenceDetails.sites)
    );
    this.siteService.updateSitesPerReference(sitesPerReference, this.activeReference);
  }
}
