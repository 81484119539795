import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipComponent } from '@app/shared/components/tooltip/tooltip.component';

@Component({
  selector: '[payment-plan-headers]',
  standalone: true,
  imports: [CommonModule, TranslateModule, TooltipComponent],
  templateUrl: './payment-plan-headers.component.html',
  styleUrls: ['../breakdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PaymentPlanHeadersComponent implements OnInit {
  ngOnInit(): void {}
}
