import {
  AfterViewChecked,
  AfterViewInit,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[appSlideToggle]',
  standalone: true,
})
export class SlideToggleDirective implements AfterViewChecked, AfterViewInit {
  @Input() collapsed?: boolean = true;
  @Output() togglerEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ContentChild('toggler', { read: ElementRef }) toggler: ElementRef;
  @ContentChild('contentBox', { read: ElementRef }) contentBox: ElementRef;
  @ContentChild('content', { read: ElementRef }) content: ElementRef;

  constructor(private renderer: Renderer2, private element: ElementRef) {}

  @HostListener('click', ['$event']) onClick($event) {
    if ($event.target === this.toggler.nativeElement || this.toggler.nativeElement.contains($event.target)) {
      this.setTransitions();
      this.toggleContent();
      this.emitTogglerState();
    }
  }

  ngAfterViewInit() {
    this.setToogleClasses();
  }

  ngAfterViewChecked() {
    this.setBlockHeight();
  }

  private setTransitions() {
    this.renderer.setStyle(this.contentBox.nativeElement, 'transition', 'height .4s ease');
  }

  private toggleContent() {
    this.collapsed = !this.collapsed;
    this.setToogleClasses();
  }

  private setBlockHeight() {
    if (this.collapsed) {
      this.renderer.setStyle(this.contentBox.nativeElement, 'height', '0');
    } else {
      const height = this.content.nativeElement.offsetHeight;
      this.renderer.setStyle(this.contentBox.nativeElement, 'height', `${height}px`);
    }
  }

  private setToogleClasses() {
    this.renderer.setStyle(this.contentBox.nativeElement, 'overflow-y', 'clip');
    this.renderer.setStyle(this.contentBox.nativeElement, 'clip', 'auto');
    if (this.collapsed) {
      this.renderer.addClass(this.element.nativeElement, 'collapsed');
      this.renderer.removeClass(this.element.nativeElement, 'expanded');
    } else {
      this.renderer.addClass(this.element.nativeElement, 'expanded');
      this.renderer.removeClass(this.element.nativeElement, 'collapsed');
    }
  }

  private emitTogglerState() {
    this.togglerEmitter.emit(this.collapsed);
  }
}
