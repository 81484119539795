<div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
  <div class="mt-40 md:grid md:grid-cols-12 md:gap-16 md:mt-32">
    <div data-cy="my-ev__dashboard" class="w-full md:col-span-8 my-16 md:my-4 sm:max-w-screen-xl mx-auto">
      <div class="block mb-4">
        <div class="">
          <app-station-info
            [chargingStations$]="chargingStations$"
            [chargingStation$]="chargingStation$"
            [chargingStationStatus$]="chargingStationStatus$"
            (stationChanged)="changeChargingStation($event)"
          ></app-station-info>

          <div class="mb-4">
            <app-station-weekly-data
              [chargingStation$]="chargingStation$"
              [chargingStationSessions$]="chargingStationSessions$"
              (rangeSelected)="selectedRangeChanged($event)"
            ></app-station-weekly-data>
          </div>
        </div>
      </div>
    </div>

    <div class="md:col-span-4">
      <app-quick-links class="mb-8 inline-block w-full"></app-quick-links>
      <app-blog [limit]="1"></app-blog>
    </div>
  </div>
</div>
