<app-modal #modal title="{{ 'components.provisionalIndexes.title' | translate }}">
  <div class="loader-container" *ngIf="loading">
    <app-loader></app-loader>
  </div>
  <div class="text-base mt-8 md:border md:border-b-0" *ngIf="!loading">
    <!-- HEAD -->
    <div class="hidden md:grid md:grid-cols-{{ provisionalsByDirection[0]?.indexes.length + 1 }} head md:border-b">
      <div class="bg-gray md:py-6 md:px-8">
        <strong>{{ 'general.date' | translate }}</strong>
      </div>
      <div *ngFor="let index of provisionalsByDirection[0]?.indexes" class="bg-gray bg-opacity-95 py-6 px-8 font-bold md:border-l">
        <span class="meter-label {{ index.register | lowercase }}">
          {{ 'general.meters.' + index.register + '.title' | translate }}
        </span>
      </div>
    </div>

    <!-- FIRST INDEX -->
    <ng-template [ngTemplateOutlet]="row" [ngTemplateOutletContext]="{ metering: boundaryIndexes?.from }">
    </ng-template>

    <!-- PROVISONALS -->
    <ng-template
      *ngFor="let provisional of provisionalsByDirection; let j=index"
      [ngTemplateOutlet]="row"
      [ngTemplateOutletContext]="{ metering: provisional, index: j }"
    >
    </ng-template>

    <!-- LAST INDEX -->
    <ng-template [ngTemplateOutlet]="row" [ngTemplateOutletContext]="{ metering: boundaryIndexes?.to }"> </ng-template>

    <ng-template #row let-metering="metering" let-index="index">
      <div
        *ngIf="metering"
        class="row relative mb-4 md:mb-0 md:border-b md:grid md:grid-cols-{{ metering.indexes.length + 1 }} {{ itemSelect[index] }}"
        [class.cursor-pointer]="index > -1"
        (click)="toggleActions(index)"
      >
        <div
          *ngIf="metering.context === indexContext.PROVISION"
          class="index-actions md:w-{{ metering.indexes.length }}/{{ metering.indexes.length + 1 }}"
        >
          <span (click)="editIndex(index)">
            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="inline-block">
              <path d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13" stroke="#ED0000" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z" stroke="#ED0000" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            {{ 'components.consumption.index.actions.edit' | translate }}
          </span>
          <span (click)="deleteIndex(metering, index)">
            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="inline-block">
              <path d="M0 0C0 0 0 0 0 0C0 0 0 0 0 0" stroke="#ED0000" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M19 6V20C19 20.5304 18.7893 21.0391 18.4142 21.4142C18.0391 21.7893 17.5304 22 17 22H7C6.46957 22 5.96086 21.7893 5.58579 21.4142C5.21071 21.0391 5 20.5304 5 20V6H19Z" stroke="#ED0000" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M0 0C0 0 0 0 0 0" stroke="#ED0000" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M0 0C0 0 0 0 0 0" stroke="#ED0000" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            {{ 'components.consumption.index.actions.delete' | translate }}
          </span>
        </div>

        <div class="date text-center py-4 bg-gray bg-opacity-95 md:text-left md:bg-transparent md:py-6 md:px-8">
          {{ metering.indexDate | date: 'longDate':undefined:translateService.currentLang + '-BE' }}
        </div>
        <div
          *ngFor="let index of metering.indexes"
          class="register text-right p-4 md:text-left md:py-6 md:px-8 md:border-l"
          [attr.data-label]="'general.meters.' + index.register + '.title' | translate"
        >
          {{ index.value | number: '1.1-2' }} {{ 'general.units.' + energyUnit | translate }}
        </div>
      </div>
    </ng-template>
  </div>
</app-modal>
