import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
  @Input() progressValue = 0;
  @Input() progressHeightClass: string;
  @Input() progressBarGradientBackground: boolean;
}
