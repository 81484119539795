import { Component, EventEmitter, Output } from '@angular/core';
import { SwitchOption } from '../../models/tevc.constants';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent {
  @Output() optionChanged: EventEmitter<SwitchOption> = new EventEmitter<SwitchOption>();

  activeOption = SwitchOption.energy;
  readonly switchOptions = SwitchOption;

  changeActiveOption(option: SwitchOption): void {
    this.activeOption = option;
    this.optionChanged.emit(this.activeOption);
  }
}
