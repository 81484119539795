import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, Observable, of, shareReplay, tap, throwError } from 'rxjs';
import { Site } from '../../../user/models/site.interface';

@Injectable({
  providedIn: 'root',
})
export class HomeProfileService {
  private homeProfile: [] = [];
  private homeProfileCompletion: [] = [];

  constructor(private http: HttpClient) {}

  getHomeProfile(
    reference: string,
    site: Site,
    reset: boolean = false,
    loader: boolean = true
  ): Observable<HomeProfile> {
    if (!reset && this.homeProfile && this.homeProfile[reference] && this.homeProfile[reference][site?.id]) {
      return of(this.homeProfile[reference][site?.id]);
    }
    return this.getHomeProfileFromAPI(reference, site, loader);
  }

  public getHomeProfileCompletion(
    reference: string,
    site: Site,
    provider: HomeProfileProvider,
    reset: boolean,
    loader: boolean = true
  ): Observable<number> {
    if (this.homeProfileCompletion[site?.id] && !reset) {
      return of(this.homeProfileCompletion[site?.id]);
    }

    return this.getHomeProfileCompletionFromAPI(reference, site, provider, loader);
  }

  public postHomeProfileToAPI(
    reference: string,
    siteId: string,
    payload: HomeProfileUpdate[],
    loader: boolean
  ): Observable<any> {
    const params = new HttpParams().set('loader', loader.toString());
    return this.http
      .patch<HomeProfileUpdate>(`/v1/customers/${reference}/sites/${siteId}/building`, payload, { params })
      .pipe(catchError((error) => throwError(error)));
  }

  private getHomeProfileCompletionFromAPI(
    reference: string,
    site: Site,
    provider: HomeProfileProvider,
    loader: boolean
  ): Observable<number> {
    const params = new HttpParams().set('loader', loader.toString());
    return this.http.get<any>(`/v1/customers/${reference}/sites/${site?.id}/building/completion`, { params }).pipe(
      map(
        (homeProfileCompletionObject: { homeProfileCompletion: number }) =>
          homeProfileCompletionObject.homeProfileCompletion
      ),
      tap((homeProfileCompletionValue: number) => {
        this.homeProfileCompletion[site.id] = homeProfileCompletionValue;
      }),
      shareReplay(1)
    );
  }

  private getHomeProfileFromAPI(reference: string, site: Site, loader: boolean): Observable<HomeProfile> {
    const params = new HttpParams().set('loader', loader.toString());
    return this.http.get<HomeProfile>(`/v1/customers/${reference}/sites/${site?.id}/building`, { params }).pipe(
      tap((response) => {
        if (!this.homeProfile[reference]) {
          this.homeProfile[reference] = {};
        }
        this.homeProfile[reference][site?.id] = response;
      })
    );
  }
}

export interface HomeProfile {
  [key: string]: HomeProfileQuestion[];
}

export interface HomeProfileQuestion {
  name?: string;
  key?: string;
  dataType?: DataType;
  required?: boolean;
  limit?: { min: number; max: number; step: number };
  possibleValues?: HomeProfileQuestionValue[];
  value?: string | string[] | number;
  suggestedValue?: string | string[] | number;
  category?: HomeProfileCategory | string;
  questionType?: HomeProfileQuestionType;
  suffix?: string;
  description?: boolean;
}

export interface HomeProfileMultiQuestions {
  key: string;
  value: HomeProfileQuestion[];
  category?: HomeProfileCategory | string;
  questionType?: HomeProfileQuestionType;
}

export interface HomeProfileQuestionValue {
  name?: string;
  type?: string;
  value?: string;
}

export interface HomeProfileUpdate {
  key: string;
  value: string;
}

export enum DataType {
  singleSelect = 'single_select',
  multiSelect = 'multiple_select',
  number = 'number',
  numericInput = 'numeric_input',
}

export enum HomeProfileCategory {
  properties = 'properties',
  cooking = 'cooking',
  appliances = 'appliances',
  fridgesAndFreezers = 'fridgesAndFreezers',
  anyValue = 'any_value',
}

export enum HomeProfileQuestionType {
  single = 'single',
  multi = 'multi',
}

export enum HomeProfileProvider {
  energyInsight = 'EnergyInsight',
}
