import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MoveHeaderComponent } from '@app/modules/customer-zone/move/components/move-status/views/parts/move-header/move-header.component';
import { MoveCtaComponent } from '@app/modules/customer-zone/move/components/move-status/views/parts/move-cta/move-cta.component';
import { MoveStatusProgressComponent } from '@app/modules/customer-zone/move/components/move-status/views/parts/move-status-progress/move-status-progress.component';
import { AlertComponent } from '@app/shared/components/alert/alert.component';
import { MoveCardComponent } from '@app/modules/customer-zone/move/components/move-status/views/parts/move-card/move-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { MoveFormFacade } from '@app/core/facade/move-form.facade';
import { MoveDataStatusEnumCuzoApi, SiteStatusCuzoApi } from '@app/shared/models/cuzo-be-contract';
import { ViewStatus } from '@app/modules/customer-zone/move/models/status.interface';

@Component({
  selector: 'app-move-summary',
  standalone: true,
  imports: [
    CommonModule,
    MoveHeaderComponent,
    MoveCtaComponent,
    MoveStatusProgressComponent,
    AlertComponent,
    MoveCardComponent,
    TranslateModule,
  ],
  templateUrl: './move-summary.component.html',
  styleUrls: ['./move-summary.component.scss'],
})
export class MoveSummaryComponent implements OnInit {
  @Input() moveSite: SiteStatusCuzoApi;
  readonly ViewStatus = ViewStatus;
  readonly Status = MoveDataStatusEnumCuzoApi;

  constructor(private readonly moveFormFacade: MoveFormFacade) {}

  ngOnInit(): void {}

  startMove(siteId: string) {
    this.moveFormFacade.startMove(siteId);
  }
}
