<div *ngIf="eliqAccessRights$ | async as eliqAccessRights">
  <div class="sites-infos bg-white">
    <div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
      <div class="items-center pb-8 pt-14 sm:pb-14 md:grid md:grid-cols-12 md:gap-16">
        <div class="mb-12 md:mb-0 md:col-span-8">
          <app-sites-switcher
            class="inline-block w-full ml-auto sites-switcher mb-8 md:mb-0 md:w-auto"
            [sites]="sites$ | async"
            [activeSite]="activeSite"
            [version]="versionSize.big"
            [activeReference]="reference"
            (switchSiteEmitter)="switchSite($event)"
          >
          </app-sites-switcher>
        </div>
        <ng-container
          *ngIf="eliqAccessRights?.accessPerSiteId?.isEnergyInsightFeatureEligible &&
          eliqAccessRights?.accessPerSiteId?.seeHomeProfileTabAndMyHome"
        >
          <div class="md:col-span-4">
            <app-home-profile-progress-widget
              [progressValue]="homeProfileCompletion$ | async"
              [showEditLink]="(homeProfileCompletion$ | async) !== 100"
            ></app-home-profile-progress-widget>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div *ngIf="eliqAccessRights?.accessPerSiteId?.isEnergyInsightFeatureEligible; else mandatesView">
    <div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
      <div class="my-16">
        <ng-container *ngIf="!(isSyncEliqData$ | async); else eliqIsSyncData">
            <div class="grid grid-cols-1 md:grid-cols-12 gap-16 mb-16" >
              <div class="md:col-span-8">
                <h2 class="leading-tight mb-8 font-display font-normal text-5xl te-text-gradient-home inline-block">
                  {{ 'components.advice.title' | translate }}
                </h2>
                <ul class="mb-12 mt-6 pb-4 overflow-x-auto whitespace-nowrap sm:overflow-x-clip sm:pb-0">
                  <ng-container *ngFor="let status of adviceStatus | keyvalue : asIsOrder">
                    <li
                      (click)="updateAdviceView(status.value)"
                      class="status"
                      [class.active]="activeStatus === status.value"
                    >
                      {{ 'components.advice.status.' + status?.key | lowercase | translate }}
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-12 gap-16">
              <div class="md:col-span-8">
                <div *ngIf="isLoading">
                  <app-loader-widget [showBorder]="false"> </app-loader-widget>
                </div>

                <div *ngIf="isInError && !isLoading && !notEnoughData">
                  <app-error-widget [showBorder]="false"> </app-error-widget>
                </div>

                <div *ngIf="notEnoughData && !isInError && !isLoading">
                  <app-no-data-widget [border]="false">
                    <span class="inline-block w-full">{{ 'components.widgets.noData.label' | translate }}</span>
                    <a class="text-primary" routerLink="/consumptions/home-profile">
                      {{ 'components.widgets.noData.updateProfile' | translate }}
                    </a>
                  </app-no-data-widget>
                </div>

                <div *ngIf="advice$ | async as allAdvice">
                  <ng-container *ngIf="!isLoading && !isInError && !notEnoughData">
                    <div *ngIf="allAdvice.length > 0; else noAdvice">
                      <app-advice-item
                        *ngFor="let advice of allAdvice"
                        [advice]="advice"
                        (emitOpenAdvice)="openSingleAdvice($event)"
                        (emitStatusAction)="updateAdviceStatus($event)"
                      >
                      </app-advice-item>
                    </div>
                    <ng-template #noAdvice>
                      <div class="bg-white box-shadow-small mb-24 rounded-4xl inline-block w-full border border-white p-8">
                        {{ 'components.advice.general.noAdvice' | translate }}
                      </div>
                    </ng-template>
                  </ng-container>
                </div>
              </div>

              <div class="md:col-span-4">
                <ng-container *ngIf="eliqAccessRights?.accessPerSiteId?.seeHomeProfileTabAndMyHome">
                  <ng-container *ngIf="homeProfileCompletion$ | async as completion">
                    <div class="mb-8">
                      <app-home-profile-progress [progressValue]="completion"></app-home-profile-progress>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
        </ng-container>
        <ng-template #eliqIsSyncData>
          <app-eliq-sync-in-progress>
          </app-eliq-sync-in-progress>
        </ng-template>
      </div>
    </div>
  </div>
  <ng-template #mandatesView>
    <app-fluvius-mandates-intro
      [mandates]="mandates$ | async"
      [eliqAccessRights]="eliqAccessRights"
      [contracts]="contracts$ | async"

    >
    </app-fluvius-mandates-intro>
    <app-fluvius-mandates
      [mandates]="mandates$ | async"
      [reference]="reference"
      [eliqAccessRights]="eliqAccessRights"
    >
    </app-fluvius-mandates>
  </ng-template>
</div>

<app-advice-panel [advice]="currentAdvice" (emitAction)="updateAdviceStatus($event)" (closeEmitter)="close()">
</app-advice-panel>

<app-success-popup [showPopup]="successPopup" (closeEmitter)="closeSuccessPopup()">
  <ng-container title> {{ 'components.advice.successCallback.title' | translate }} </ng-container>
  <ng-container content> {{ 'components.advice.successCallback.content' | translate }} </ng-container>
</app-success-popup>
