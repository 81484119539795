<div class="card border-none md:box-shadow-small md:mb-8">
  <img ngSrc="./assets/img/box-move.svg" width="50" height="50" class="mb-8" alt="moving box" />
  <div class="content mb-8" *ngIf="site$ | async as site">
    <span class="text-sm text-grey-dark60/50 inline-block w-full">Votre adresse actuelle :</span>
    <span class="text-grey-dark/90 inline-block w-full">
      {{ site?.streetName }}, {{ site?.streetNumber }} {{ site?.boxNumber }} <br/>
      {{ site?.zipCode }} {{ site?.town }}
    </span>
  </div>
  <div *ngIf="meters?.length" class="content mb-8">
    <span class="text-sm text-grey-dark60/50 inline-block w-full">Compteurs selectionnés :</span>
    <app-move-meter-tag [meters]="meters"></app-move-meter-tag>
  </div>
  <div *ngIf="moveState?.moveDTO?.ilcDate?.length" class="content mb-8">
    <span class="text-sm text-grey-dark60/50 inline-block w-full">Date de déménagement :</span>
    <span class="text-grey-dark/90 inline-block w-full">
      {{ moment(moveState?.moveDTO?.ilcDate).format('DD/MM/YYYY') }}
    </span>
  </div>
  <div *ngIf="moveState?.registration?.sites?.[0]?.address?.street?.length" class="content mb-8">
    <span class="text-sm text-grey-dark60/50 inline-block w-full">Votre nouvelle adresse :</span>
    <span class="text-grey-dark/90 inline-block w-full">
      {{ moveState?.registration?.sites?.[0]?.address?.street }}, {{ moveState?.registration?.sites?.[0]?.address?.streetNumber }} {{ moveState?.registration?.sites?.[0]?.address?.box }} <br/>
      {{ moveState?.registration?.sites?.[0]?.address?.zip }} {{ moveState?.registration?.sites?.[0]?.address?.city }}
    </span>
  </div>
  <div *ngIf="moveState?.moveDTO?.id" class="text-center">
    <button (click)="saveData()" class="btn btn-secondary font-bold h-fit inline-block w-full mb-6">
      Sauver pour plus tard
    </button>
  </div>
</div>
