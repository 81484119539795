<div class="form-element input-text">
  <label class="form__label" for="date">{{ label }}</label>
  <div class="input-group">
    <input
      id="date"
      type="text"
      [formControl]="control"
      placeholder="dd/mm/yyyy"
      name="date"
      [minDate]="minDateStruct"
      [maxDate]="maxDateStruct"
      [displayMonths]="1"
      [navigation]="'arrows'"
      [placement]="'right'"
      ngbDatepicker
      #d="ngbDatepicker"
    />
    <div class="input-group-append">
      <button
        class="btn btn-outline-secondary calendar"
        (click)="d.toggle()"
        type="button"
        [attr.disabled]="editIndex ? '' : null"
      ></button>
    </div>
  </div>
</div>
