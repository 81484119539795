import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnalyticsModule } from '@app/shared/modules/analytics/analytics.module';
import {
  BillingDetailsCuzoApi,
  BillingDetailsPaymentMethodEnumCuzoApi,
  InvoiceModelV2CuzoApi,
} from '@app/shared/models/cuzo-be-contract';
import { BillingFacade } from '@app/core/facade/billing.facade';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-invoice-pay-online',
  standalone: true,
  imports: [CommonModule, AnalyticsModule, TranslateModule],
  templateUrl: './invoice-pay-online.component.html',
  styleUrls: ['./invoice-pay-online.component.scss'],
})
export class InvoicePayOnlineComponent implements OnInit {
  @Input() invoice: InvoiceModelV2CuzoApi;
  @Input() linkStyle?: boolean = false;
  @Input() btnStyle: boolean = false;
  @Input() alignment?: string = 'center';
  @Input() billingDetails: BillingDetailsCuzoApi;
  readonly PaymentMethod = BillingDetailsPaymentMethodEnumCuzoApi;

  constructor(private billingFacade: BillingFacade) {}

  ngOnInit(): void {}

  goToOnlinePayment(invoice: InvoiceModelV2CuzoApi): void {
    document.location.href = this.billingFacade.getOnlinePaymentUrl(invoice);
  }
}
