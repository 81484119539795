<div *ngIf="show" class="relative h-full">
  <div *ngIf="!error" class="h-full box-shadow-small rounded-2xl">
    <ng-container *ngIf="peaksData$ | async as peaks; else loading">
      <ng-container *ngIf="peaks?.peakCurrentMonth; else noData">
        <div
          class="relative box-shadow-primary rounded-2xl bg-white w-full h-full p-8 cursor-pointer"
          [ngClass]="'wrapper-' + template"
          (click)="goToDetails($event)"
        >
          <div class="flex items-center gap-x-8">
            <div class="flex-1">
              <h3 class="text-grey-dark text-lg font-medium">
                {{ 'components.widgets.peakConsumption.title' | translate }}
              </h3>
            </div>
            <span #tooltip class="{{ template === versionSize.big? 'relative' : 'absolute right-0' }} top-0.5">
              <app-tooltip
                [tooltip]="'components.widgets.peakConsumption.tooltip.label' | translate" class="mt-2">
              </app-tooltip>
            </span>
            <ng-template *ngIf="template === versionSize.big" [ngTemplateOutlet]="arrow"></ng-template>
          </div>
          <span class="text-grey-dark60 font-medium">
            {{
              peaks?.peakCurrentMonth?.currentMeteringDate
                | date : 'MMMM yyyy' : undefined : facade.translate.currentLang + '-BE'
                | titlecase
            }}
          </span>
          <div class="flex mt-8 {{ template === versionSize.big ? 'items-end' : 'items-center' }}">
            <div *ngIf="peaks?.peakCurrentMonth" class="mr-4">
              <span class="text-3xl sm:text-xl lg:text-3xl">
                {{ peaks?.peakCurrentMonth.value | number : '1.2-2' : facade.translate.currentLang + '-BE' }}
              </span>
              <span class="text-sm"> {{ peaks?.peakCurrentMonth.unit }} </span>
              <span
                class="text-sm inline-block w-full"
                [innerHTML]="
                  'components.widgets.peakConsumption.body.measuredDate'
                    | translate
                      : {
                          value:
                            peaks?.peakCurrentMonth.currentMeteringDate
                            | date : 'dd MMMM' : undefined : facade.translate.currentLang + '-BE'
                            | lowercase
                        }
                "
              >
              </span>
            </div>
            <ng-template *ngIf="template === versionSize.small; else barChart" [ngTemplateOutlet]="arrow"></ng-template>
            <ng-template #barChart>
              <ng-container *ngIf="peaks?.peakLastMonths">
                <div class="flex w-full">
                  <ul class="chart">
                    <li
                      *ngFor="let peakMonth of peaks?.peakLastMonths; let i = index"
                      class="rounded-t-xl text-center"
                      [ngStyle]="{ height: (peakMonth.value / maxPeakLastMonths.value) * 100 + '%' }"
                    >
                      <span>
                        {{
                          (peakMonth.currentMeteringDate
                            | date : 'MMM' : '-120' : facade.translate.currentLang + '-BE'
                            | titlecase)[0]
                        }}
                      </span>
                    </li>
                  </ul>
                </div>
              </ng-container>
            </ng-template>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <ng-template #loading>
    <app-loader-widget></app-loader-widget>
  </ng-template>

  <ng-template #noData>
    <app-no-data-widget>
      <span class="inline-block w-full">{{ 'components.widgets.noData.label' | translate }}</span>
      <a class="text-primary" routerLink="/consumptions/home-profile">{{
        'components.widgets.noData.updateProfile' | translate
      }}</a>
    </app-no-data-widget>
  </ng-template>

  <app-error-widget *ngIf="error"> </app-error-widget>
</div>

<ng-template #arrow>
  <img ngSrc="./assets/img/icons/standalone/red/arrow-right.svg" height="17" width="9" alt="" />
</ng-template>
