<div
  class="{{ isMobile ? 'top-0 z-10' : 'top-[9rem] md:top-[12rem] z-0'}} fixed backdrop-blur-sm left-0 w-screen h-screen"
>
  <div
    class="{{ isMobile ? 'top-[2rem]' : 'top-0 md:top-1/3 md:-translate-y-1/2' }} absolute w-[calc(100%-4rem)] md:w-[76rem] z-10 left-1/2 -translate-x-1/2"
  >
    <span *ngIf="isLastStep" class="btn-close !-right-6 md:!-right-10" #close (click)="closeGuide()"></span>
    <div
      class="{{ isMobile ? 'flg-app-wrapper' : 'flg-web-wrapper' }} bg-white px-8 md:px-24 py-16 box-shadow-small rounded-4xl overflow-y-auto"
    >
      <div class="absolute -top-6 te-border-gradient-home py-2 px-6 rounded-2xl !border">
        <span class="te-text-gradient-home">{{ 'components.firstLoginGuide.new' | translate }}</span>
      </div>
      <div class="flex">
        <ng-container [ngSwitch]="currentStep?.stepIndex">
          <ng-container *ngSwitchCase="'1'">
            <app-step-template [step]="currentStep" [@fadeInOut]>
              <ng-template [ngTemplateOutlet]="buttonTemplate"></ng-template>
            </app-step-template>
          </ng-container>
          <ng-container *ngSwitchCase="'2'">
            <app-step-template [step]="currentStep" [@fadeInOut]>
              <ng-template [ngTemplateOutlet]="buttonTemplate"></ng-template>
            </app-step-template>
          </ng-container>
          <ng-container *ngSwitchCase="'3'">
            <app-step-template [step]="currentStep" [@fadeInOut]>
              <ng-template [ngTemplateOutlet]="buttonTemplate"></ng-template>
            </app-step-template>
          </ng-container>
          <ng-container *ngSwitchCase="'4'">
            <app-step-template [step]="currentStep" [@fadeInOut]>
              <ng-template [ngTemplateOutlet]="buttonTemplate"></ng-template>
            </app-step-template>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #buttonTemplate>
  <div
    class="flex justify-between flex-wrap gap-8 md:pt-0 pt-8"
    [ngClass]="{ '!justify-end': currentStep?.stepIndex === 1 }"
  >
    <button (click)="onPreviousStep()" *ngIf="currentStep?.stepIndex > 1" class="text-primary text-sm font-bold">
      <img src="./assets/img/icons/standalone/red/arrow-left.svg" class="mr-2 inline-block w-5 h-5" />
      {{ 'components.firstLoginGuide.previous' | translate }}
    </button>
    <button (click)="onNextStep()" class="btn btn-primary !px-10">
      <span *ngIf="!isLastStep">{{ 'components.firstLoginGuide.next' | translate }}</span>
      <span *ngIf="isLastStep">{{ 'components.firstLoginGuide.homeProfile' | translate }}</span>
      <img src="./assets/img/icons/standalone/white/arrow-right.svg" class="inline-block ml-4" />
    </button>
  </div>
</ng-template>
