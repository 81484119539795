<div class="meter mt-8 mb-8">
  <div class="mb-8">{{ 'components.consumption.meterSelection.intro' | translate }}</div>
  <div class="mb-4" *ngFor="let contract of contracts; let i = index">
    <div class="form-element input-radio-big">
      <input
        id="{{ contract.deliveryPointReference }}"
        type="radio"
        value="{{ i }}"
        name="meterSelection"
        [formControl]="control"
      />
      <label for="{{ contract.deliveryPointReference }}">{{ contract.meterNumber }} / {{ contract.deliveryPointReference }}</label>
    </div>
  </div>
</div>
