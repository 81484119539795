import { Component, Input } from '@angular/core';
import { CrossSellType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { UtilsService } from '@app/shared/utils/utils.service';

@Component({
  selector: 'app-cross-sell-widget',
  templateUrl: './cross-sell-widget.component.html',
  styleUrls: ['./cross-sell-widget.component.scss'],
})
export class CrossSellWidgetComponent {
  @Input() crossSellType: CrossSellType;

  constructor(private readonly utilsService: UtilsService) {}

  public getImageName(crossSellType: CrossSellType) {
    return crossSellType.replace(/[A-Z]/g, (name: string) => '-' + name.toLowerCase());
  }

  public getCrossSellUrl() {
    return this.utilsService.getCrossSellUrl(this.crossSellType);
  }
}
