<ng-container *ngIf="viewType === PaymentBalanceViewType.PAY_ONE_INVOICE">
  <div class="grid grid-cols-2 gap-x-4 gap-y-8 border-t border-grey w-full pt-8 mt-4 items-center">
    <ng-container [ngTemplateOutlet]="amount" [ngTemplateOutletContext]="{ invoice: invoice }"></ng-container>
    <div class="flex flex-col sm:items-end justify-end col-span-2 sm:col-span-1 order-last sm:order-none">
      <app-invoice-pay-online
        [billingDetails]="billingDetails"
        [invoice]="invoice"
        [btnStyle]="true">
      </app-invoice-pay-online>
    </div>

    <div class="grid grid-cols-1 col-span-2 sm:grid-cols-2 gap-2 font-base font-medium text-grey-dark">
      <div>
        {{ 'pages.dashboard.paymentBalance.paymentInformation.reference' | translate }}:
        <span class="font-normal">{{ invoice.invoiceNumber }}</span>
      </div>
      <div>
        {{ 'pages.dashboard.paymentBalance.paymentInformation.iban' | translate }}:
        <span class="text-primary">
          {{ 'general.companyIBAN' | translate }}
        </span>
      </div>
      <div>
        {{ 'pages.dashboard.paymentBalance.paymentInformation.date' | translate }}:
        <span
          class="font-normal"
          [innerHTML]="
            'pages.dashboard.paymentBalance.paymentInformation.' +
              (billingDetails.paymentMethod === PaymentMethod.BANK_TRANSFER ? 'dueDateVir' : 'dueDateDomandDomv') | translate
          "
        >
        </span>
        <span class="text-primary"> {{ invoice.dueDate | date : 'dd.MM.yy' }}</span>
      </div>
      <div *ngIf="billingDetails.paymentMethod === PaymentMethod.BANK_TRANSFER && structuredCommunication">
        {{ 'pages.dashboard.paymentBalance.paymentInformation.communicationStructure' | translate }}
        <span class="text-primary">{{ structuredCommunication }}</span>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="viewType === PaymentBalanceViewType.REFUND_ONE_INVOICE">
  <div class="sm:grid grid-cols-12 gap-x-2 gap-y-8 border-t border-grey w-full pt-8 mt-4">
    <div class="sm:col-span-4">
      <ng-container [ngTemplateOutlet]="amount" [ngTemplateOutletContext]="{ invoice: invoice }"></ng-container>
    </div>
    <div class="sm:col-span-3 sm:flex sm:flex-col justify-center items-center">
      <span class="font-bold">{{ 'pages.dashboard.paymentBalance.paymentInformation.reference' | translate }}: </span>
      <span class="font-normal">{{ invoice?.invoiceNumber }}</span>
    </div>
    <div class="mt-4 sm:mt-0 sm:col-span-5 sm:flex sm:flex-col justify-center items-center">
      <app-invoice-status
        [invoice]="invoice"
        [icon]="false"
      >
      </app-invoice-status>
    </div>
  </div>
</ng-container>

<ng-template #amount let-invoiceData="invoice">
  <div class="flex justify-between items-center">
    <div class="flex flex-col">
      <div class="font-base font-medium text-grey-dark">
        {{ 'pages.dashboard.paymentBalance.invoiceTypes.' + invoiceData.type | translate }}
      </div>
      <div class="w-full font-display font-normal text-5xl te-text-gradient-home inline-block">
        {{ invoiceData.amount.amountWithVat | currency : 'EUR' }}
      </div>
    </div>
  </div>
</ng-template>
