<div class="quick-links">
  <h2 class="text-base text-grey-dark60 font-medium mb-8">
    {{ 'components.quickLinks.title' | translate }}
  </h2>
  <ul>
    <li>
      <a
        class="pb-8 font-medium text-sm inline-block"
        routerLink="preferences/move"
        [analyticsEvent]="'directLink:click:move'"
      >
        <ng-template [ngTemplateOutlet]="arrowSVG"></ng-template>
        {{ 'components.quickLinks.move' | translate }}
      </a>
    </li>
    <li>
      <a
        class="pb-8 font-medium text-sm inline-block"
        routerLink="invoices/advance-payment"
        [analyticsEvent]="'directLink:click:advance-payment'"
      >
        <ng-template [ngTemplateOutlet]="arrowSVG"></ng-template>
        {{ 'components.quickLinks.updateAdvancePayments' | translate }}
      </a>
    </li>
    <li>
      <a
        class="pb-8 font-medium text-sm inline-block"
        [href]="utilsService.getHelpCenterUrl()"
        target="_blank"
        [analyticsEvent]="'directLink:click:help-center'"
      >
        <ng-template [ngTemplateOutlet]="arrowSVG"></ng-template>
        {{ 'components.quickLinks.helpcenter' | translate }}
      </a>
    </li>
  </ul>
</div>

<ng-template #arrowSVG>
  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.29288 7L5.93933 7.35355L0.646428 12.6465C0.451191 12.8417 0.451191 13.1583 0.646428 13.3535C0.841666 13.5488 1.15828 13.5488 1.35352 13.3535L7.35352 7.35355C7.54876 7.15831 7.54876 6.84169 7.35352 6.64645L1.35352 0.646443L1.35351 0.646434C1.15828 0.45119 0.841678 0.451189 0.646442 0.64643L6.29288 7ZM6.29288 7L5.93933 6.64645M6.29288 7L5.93933 6.64645M5.93933 6.64645L0.646442 1.35357M5.93933 6.64645L0.646442 1.35357M0.646442 1.35357C0.646437 1.35357 0.646433 1.35356 0.646428 1.35356M0.646442 1.35357L0.646428 1.35356M0.646428 1.35356C0.451188 1.15829 0.451191 0.841701 0.646437 0.646434L0.646428 1.35356Z"
      fill="#ED0000"
      stroke="#ED0000"
    />
  </svg>
</ng-template>
