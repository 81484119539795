import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReferenceDetails } from '../../../user/models/reference.interface';
import { UpsellRequest } from '../../models/upsell.interface';
import { AbstractControl, UntypedFormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-select-reference',
  templateUrl: './select-reference.component.html',
  styleUrls: ['./select-reference.component.scss'],
})
export class SelectReferenceComponent implements OnInit {
  @Output() formReady: EventEmitter<number> = new EventEmitter<number>();
  @Input() referencesDetails: ReferenceDetails[];
  @Input() control: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.control.addControl('reference', this.fb.control(''));
    this.control.get('reference').valueChanges.subscribe((value) => {
      this.formReady.emit();
    });
  }

  public continue() {
    this.formReady.emit();
  }
}
