<div class="inline-block md:flex w-full mt-12 gap-12 text-center">

  <div
    *ngIf="paymentPlan?.amount"
    class="flex flex-col flex-1 bg-grey-light rounded-2xl p-8 md:w-0 md:p-4 lg:p-8 mb-8 md:mb-0"
  >
    <h5 class="text-lg md:text-base lg:text-lg font-medium">
      {{ 'components.paymentPlan.overview.paymentPlanDetails.paymentPlanInfo.totalAmount' | translate }}
    </h5>
    <div class="flex-1">
      <div>
        <span class="total-amount text-5xl font-display font-normal te-text-gradient-home inline-block">
          {{ paymentPlan?.amount | currency: 'EUR' }}
        </span>
      </div>
      <div
        *ngIf="paymentPlan?.paymentPlanFees?.amount > 0"
        class="text-sm text-grey-dark60"
        [innerHTML]="'components.paymentPlan.overview.paymentPlanDetails.paymentPlanInfo.includingFees' | translate: {
        fees:paymentPlan?.paymentPlanFees?.amount | currency: 'EUR' }"
      >
      </div>
    </div>
  </div>

  <div
    *ngIf="paymentPlan?.totalSlices"
    class="flex flex-col flex-1 bg-grey-light rounded-2xl p-8 md:p-4 lg:p-8 mb-8 md:mb-0"
  >
    <h5 class="text-lg md:text-base lg:text-lg font-medium">
      {{ 'components.paymentPlan.overview.paymentPlanDetails.paymentPlanInfo.numberMonths' | translate }}
    </h5>
    <span class="total-slices text-5xl font-display flex-1 mt-4">{{ paymentPlan?.totalSlices }}</span>
  </div>

  <div
    *ngIf="paymentPlan?.startDate && paymentPlan?.endDate"
    class="flex flex-col flex-1 bg-grey-light rounded-2xl p-8 md:p-4 lg:p-8"
  >
    <h5 class="text-lg md:text-base lg:text-lg font-medium">
      {{ 'components.paymentPlan.overview.paymentPlanDetails.paymentPlanInfo.timeRange' | translate }}
    </h5>
    <div class="period text-2xl font-medium flex-1 mt-10">
      <span class="inline-block w-full">
        {{
          'components.paymentPlan.overview.paymentPlanDetails.paymentPlanInfo.from' | translate: {
              date: paymentPlan?.startDate | date: 'dd.MM.yy':undefined: facade.translate.currentLang + '-BE'
          }
        }}
      </span>
      <span class="inline-block w-full">
        {{
          'components.paymentPlan.overview.paymentPlanDetails.paymentPlanInfo.to' | translate: {
              date: paymentPlan?.endDate | date: 'dd.MM.yy':undefined: facade.translate.currentLang + '-BE'
          }
        }}
      </span>
    </div>
  </div>

</div>
