<div
  class="relative rounded-2xl w-full h-full z-10 flex items-center border-grey animate-pulse"
  [class.border]="showBorder"
  style="min-height: 150px"
>
  <div class="p-8 text-grey mx-auto">
    <svg class="animate-spin -ml-1 mr-2 h-5 w-5 inline-block" xmlns="http://www.w3.org/2000/svg" fill="none"
         viewBox="0 0 24 24">
      <circle class="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="" fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
    {{ 'general.loader' | translate }}…
  </div>
</div>
