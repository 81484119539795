<div class="rounded-2xl box-shadow-small bg-grey-light p-8 md:p-12 md:flex">
  <div class="flex-1">
    {{ 'components.invoiceDeliveryChannel.intro' | translate }}
    <span class="font-medium">{{ 'general.billing.paper' | translate | lowercase }}</span>
  </div>
  <ng-container *accessControl="accessRight.modifyInvoicingMethod">
    <div *ngIf="!contactDetails?.locked" class="ml-auto mt-4 md:mt-0 md:self-center md:pl-4">
      <span
        (click)="changeInvoiceMethod()"
        class="cursor-pointer btn-text btn-text-primary font-bold"
        [analyticsEvent]="'paymentPreferences:click:change-invoicing-method'"
      >
        {{ 'general.modify' | translate }}
      </span>
    </div>
  </ng-container>
</div>

<div class="mt-6 md:mt-16 mb-4">{{ 'components.invoiceDeliveryChannel.paper.intro' | translate }}:</div>

<div class="md:flex justify-between rounded-2xl bg-grey-light p-8 md:p-12">
  <div class="flex-1">
    <p>
      <span class="font-medium">{{ 'components.invoiceDeliveryChannel.paper.address' | translate }}</span>
    </p>
    {{ contactDetails?.firstName | titlecase }} {{ contactDetails?.lastName | titlecase }}
    <br />
    <app-contact-address [address]="contactDetails?.address"></app-contact-address>
  </div>

  <ng-container *accessControl="accessRight.modifyContactDetails">
    <div *ngIf="!contactDetails?.locked" class="ml-auto mt-4 md:mt-0 md:self-center md:pl-4">
      <span
        (click)="modifyAddress()"
        [analyticsEvent]="'paymentPreferences:click:change-address'"
        class="cursor-pointer btn-text btn-text-primary font-bold"
      >
        {{ 'general.modify' | translate }}
      </span>
    </div>
  </ng-container>
</div>
