<ng-container [ngSwitch]="slice?.status">
  <div *ngSwitchCase="Status.PAID">
    <img ngSrc="./assets/img/icon_valid.svg" class="mx-auto" height="24" width="24">
  </div>

  <div *ngSwitchCase="Status.OVERDUE">
    <img ngSrc="./assets/img/icon_error.svg" class="inline-block md:mr-2" height="24" width="24">
    <span class="desktop-only">
      {{ 'components.paymentPlan.paymentStatus.overdue' | translate }}
      <time class="text-error">
        {{ slice?.dueDate | date: 'dd.MM.yy':undefined: facade.translate.currentLang + '-BE' }}
      </time>
    </span>
  </div>

  <div *ngSwitchCase="Status.PART_OVERDUE">
    <img ngSrc="./assets/img/icon_warning.svg" class="inline-block md:mr-2" height="24" width="24">
    <span class="desktop-only">
      <strong class="font-bold">{{ slice?.balance | currency: 'EUR' }}</strong>
      {{ 'components.paymentPlan.paymentStatus.partOverdue' | translate }}
    </span>
  </div>

  <div *ngSwitchDefault>
    <span class="inline-block w-full text-center text-5xl leading-10 align-middle text-grey"> - </span>
  </div>
</ng-container>
