import { Component, OnDestroy, OnInit } from '@angular/core';
import { PaymentPlanOverviewComponent } from '@app/modules/customer-zone/invoices/components/payment-plan/overview/overview.component';
import { PaymentPlanProgressComponent } from '@app/modules/customer-zone/invoices/components/payment-plan/progress/progress.component';
import { PaymentPlanBreakdownComponent } from '@app/modules/customer-zone/invoices/components/payment-plan/breakdown/breakdown.component';
import { AsyncPipe, CommonModule, JsonPipe, NgIf } from '@angular/common';
import { Observable, Subscription, tap } from 'rxjs';
import { BillingFacade } from '@app/core/facade/billing.facade';
import { TranslateModule } from '@ngx-translate/core';
import { FaqModule } from '@app/shared/modules/faq/faq.module';
import { ApiResponse } from '@app/shared/models/api.inteface';
import { AlertComponent } from '@app/shared/components/alert/alert.component';
import { invoiceAlerts, InvoiceAlerts } from '@app/modules/customer-zone/invoices/models/invoice.interface';
import { PaymentPlanCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Component({
  selector: 'app-payment-plan',
  standalone: true,
  imports: [
    PaymentPlanOverviewComponent,
    PaymentPlanProgressComponent,
    PaymentPlanBreakdownComponent,
    AsyncPipe,
    JsonPipe,
    NgIf,
    TranslateModule,
    FaqModule,
    AlertComponent,
    CommonModule,
  ],
  templateUrl: './payment-plan.component.html',
  styleUrls: ['./payment-plan.component.scss'],
})
export class PaymentPlanComponent implements OnInit, OnDestroy {
  readonly invoiceAlerts: InvoiceAlerts = invoiceAlerts;
  paymentPlan$: Observable<ApiResponse<PaymentPlanCuzoApi>> = this.billingFacade.paymentPlan$.pipe(
    tap(
      (paymentPlan: ApiResponse<PaymentPlanCuzoApi>) =>
        (this.isFinished = paymentPlan?.data?.paidSlices >= paymentPlan?.data?.totalSlices)
    )
  );
  isFinished: boolean = false;
  subscription: Subscription;

  constructor(private billingFacade: BillingFacade) {}

  ngOnInit(): void {
    this.subscription = this.billingFacade.loadPaymentPlan();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
