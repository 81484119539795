import { Directive, Input, OnInit, ViewContainerRef, TemplateRef } from '@angular/core';
import { AccessRight, AccessRightOperator } from '../resolvers/user-type-resolver/models/user-type.interface';
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { MainFacade } from '@app/core/facade/main.facade';

@Directive({
  standalone: true,
  selector: '[accessControl]',
})
export class AccessControlDirective implements OnInit, OnDestroy {
  protected userTypeSubscription: Subscription;
  protected userType: any; //@todo: rename
  private accessRight: AccessRight;

  constructor(
    protected templateRef: TemplateRef<any>,
    protected viewContainer: ViewContainerRef,
    //protected userTypeService: UserTypeResolverService
    protected facade: MainFacade
  ) {}

  @Input() set accessControl(access: AccessRight) {
    this.accessRight = access;
    if (access) {
      this.updateView(this.checkAccess(this.accessRight));
    }
  }

  ngOnInit() {
    this.userTypeSubscription = this.facade.state$.subscribe((state) => {
      if (!state?.accessRights) {
        return;
      }
      // current access backup
      const previousAccess = this.checkAccess(this.accessRight);
      this.userType = state.accessRights;
      const currentAccess = this.checkAccess(this.accessRight);
      // call updateView method when the access was changed only
      // it's important to prevent creating element duplicates by the method createEmbeddedView
      if (previousAccess !== currentAccess) {
        this.updateView(currentAccess);
      }
    });
  }

  ngOnDestroy(): void {
    this.userTypeSubscription?.unsubscribe();
  }

  protected updateView(hasAccess: boolean): void {
    if (hasAccess) {
      // create element
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      // hide the element
      this.viewContainer.clear();
    }
  }

  protected checkAccess(accessRight: AccessRight): boolean {
    if (!this.userType) {
      return false;
    }
    // remove exclamation mark '!' from the accessRight before checking the rights
    const hasAccess = this.userType[('' + accessRight).replace(AccessRightOperator.negation, '')];
    // if the accessRight contains exclamation mark '!', the condition should be negated
    return ('' + accessRight).startsWith(AccessRightOperator.negation) ? !hasAccess : hasAccess;
  }
}
