import { Component, Input, OnInit } from '@angular/core';
import { Form, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ContactService } from '../../services/contact/contact.service';
import { ContactDetails } from '../../models/contactDetails.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss'],
})
export class ChangeEmailComponent implements OnInit {
  @Input() contactDetails: ContactDetails;
  @Input() reference: string;

  public emailForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.email, Validators.required, this.checkEmailConfirmation.bind(this)]),
    emailConfirmation: new UntypedFormControl('', [
      Validators.email,
      Validators.required,
      this.checkEmailConfirmation.bind(this),
    ]),
  });

  public error = false;

  constructor(
    private contactService: ContactService,
    public activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.emailForm.controls.email.setValue(this.contactDetails.email);
    this.emailForm.controls.emailConfirmation.setValue(this.contactDetails.email);
  }

  public checkEmailConfirmation(control: UntypedFormControl): ValidationErrors | null {
    if (!control.parent || control.parent.pristine) {
      return null;
    }
    const email = control.parent.get('email').value;
    const emailConfirmation = control.parent.get('emailConfirmation').value;

    if (email !== emailConfirmation) {
      const error = { emailNotIdentical: true };
      control.parent.get('emailConfirmation').setErrors(error);
      return error;
    }
    control.parent.get('emailConfirmation').setErrors(null);
    control.parent.get('email').setErrors(null);

    return null;
  }

  public handleSubmit() {
    const contactDetails = Object.assign({}, this.contactDetails);
    contactDetails.email = this.emailForm.get('email').value;
    this.contactService.updateContactDetails(this.reference, contactDetails).subscribe(
      (response) => {
        this.toastrService.success(this.translate.instant('general.success'));
        this.contactDetails.locked = true;
      },
      (error) => {
        this.toastrService.error(this.translate.instant('errorMessages.serverError'));
      }
    );
    this.activeModal.close();
  }
}
