<div class="mb-16">
  <h2 class="mb-8 font-sans font-medium text-3xl te-text-gradient-home inline-block w-full">Ma nouvelle adresse</h2>
  <span>Renseignez toutes les informations relatives à votre nouvelle adresse</span>
  <div [formGroup]="form">
    <section>
      <h3 class="text-2xl font-medium text-grey-dark mt-12">Nouvelle adresse de fourniture</h3>
      <app-move-address formGroupName="newAddress"> </app-move-address>
    </section>

    <section class="mt-8">
      <h3 class="text-2xl font-medium text-grey-dark mt-12">Votre contrat</h3>
      <h4 class="text-base font-medium mt-4 mb-2">
        Un avenant à votre contrat en cours sera créé pour votre nouvelle adresse.
      </h4>
      <p class="text-sm">
        Lorem ipsum dolor sit amet consectetur. A proin ipsum pulvinar aliquet est mi scelerisque. Ultricies consectetur
        turpis bibendum id eget scelerisque laoreet neque. Eget eros commodo id ac arcu sit tincidunt ante integer.
        Fusce porta feugiat quam sit arcu malesuada sit.
      </p>
      <a href="#" class="text-primary font-bold mt-4 text-sm inline-block w-full">Plus d'info</a>
    </section>

    <section
      *ngIf="defaultInvoiceDeliveryChannel !== InvoicingMethod.EMAIL; else emailInvoicingInfo"
      [class.disabled]="form.get('invoicingData.invoicingMethod').disabled"
      formGroupName="invoicingData"
    >
      <h3 class="text-2xl font-medium text-grey-dark mt-12">Méthode de facturation</h3>
      <p class="form-element input-radio-big errors mt-8">
        <input type="radio" id="email" formControlName="invoicingMethod" [value]="InvoicingMethod.EMAIL" />
        <label for="email">
          <div class="flex items-center">
            <div class="sm:pr-6 max-w-full">
              Je souhaite recevoir mes factures par e-mail
              <div class="content" *ngIf="billingEmail">
                Vos factures seront envoyées à l’adresse liée à votre compte TotalEnergies :
                <span class="text-grey-dark60">{{ billingEmail }}</span>
              </div>
            </div>
          </div>
        </label>
      </p>
      <p class="form-element input-radio-big errors mt-8">
        <input type="radio" id="post" formControlName="invoicingMethod" [value]="InvoicingMethod.POST" />
        <label for="post">
          <div class="flex items-center">
            <div class="sm:pr-6 max-w-full parent">
              Je souhaite recevoir mes factures par courrier
              <div class="post-address content">
                <div class="p-6 border border-grey/35 rounded-2xl">
                  <p class="form-element input-checkbox errors">
                    <input type="checkbox" id="invoicingAddress" formControlName="hasDifferentInvoicingAddress" />
                    <label for="invoicingAddress">
                      Mon adresse de facturation est différente de ma nouvelle adresse de fourniture
                      <div class="post-address content">
                        <section>
                          <h3 class="text-2xl font-medium text-grey-dark mt-12">
                            Renseignez l'adresse de facturation souhaitée
                          </h3>
                          <app-move-address formGroupName="invoicingAddress"> </app-move-address>
                        </section>
                      </div>
                    </label>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </label>
      </p>
    </section>

    <ng-template #emailInvoicingInfo>
      <h3 class="text-2xl font-medium text-grey-dark mt-12">{{ 'pages.move.emailInvoicingInfo.title' | translate }}</h3>
      <h4 class="text-base font-medium mt-4 mb-2">
        {{ 'pages.move.emailInvoicingInfo.subtitle' | translate }}
      </h4>
      <p class="text-sm">
        {{ 'pages.move.emailInvoicingInfo.content' | translate }}
      </p>
    </ng-template>

    <app-dre-document
      formGroupName="dre"
      [fileName]="fileName"
      (fileEmitter)="onFileSelection($event)"
    >
    </app-dre-document>
  </div>
</div>

<app-navigation
  [stepValidity]="form?.valid && !fileUploadInProgress"
  (nextClick)="onNextClicked()"
  (previousClick)="onPreviousClicked()"
>
</app-navigation>
