import { CommonModule } from '@angular/common';
import { Component, Injector, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageIconComponent } from '../image-icon/image-icon.component';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [CommonModule, ImageIconComponent],
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input() title: string;
  @Input() icon?: { svgName: string; color: string };
  @Input() showCloseButton: boolean = true;
  @Input() customHeaderClass?: string = 'bg-turquoise';
  @Input() activeModal?: NgbActiveModal = null;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    if (!this.activeModal) {
      this.activeModal = this.injector.get(NgbActiveModal) as NgbActiveModal;
    }
  }
}
