import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Alert } from '@app/shared/components/alert/alert.interface';
import { ContactDetails } from '@app/modules/customer-zone/contact/models/contactDetails.interface';
import { UserType } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';

import { BillingDetails } from './../../models/billingDetails.interface';
import { bankTransfer, directDebit, directDebitAndBankTransfer } from '../../billing.constants';
import { AlertType } from '@app/shared/components/alert/alert.interface';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
})
export class PaymentMethodComponent implements OnInit {
  @Input() billingDetails: BillingDetails;
  @Input() contactDetails: ContactDetails;
  @Input() reference: string;
  public alert: Alert | null = null;

  readonly bankTransfer = bankTransfer;
  readonly directDebit = directDebit;
  readonly directDebitAndBankTransfer = directDebitAndBankTransfer;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.alert = {
      type: AlertType.info,
      content: {
        message: this.translate.instant('components.paymentMethod.paymentMethodChangeInProgress'),
      },
    };
  }
}
