<ul
  *ngIf="eliqAccessRights$ | async as eliqAccessRights"
  class="menu flex flex-col justify-center p-4"
>
  <li class="menu-item">
    <a
      class="menu-link"
      [skipLocationChange]="true"
      [routerLink]="'/consumptions'"
      [analyticsEvent]="'navigation:click-primary-menu:meter-overview'"
    >
      {{ 'header.primaryMenu.consumptions.dashboard' | translate }}
    </a>
  </li>
  <li *ngIf="eliqAccessRights?.accessPerSiteId?.seeAdvicePage" class="menu-item">
    <a
      class="menu-link"
      [routerLink]="'/consumptions/advice'"
      [skipLocationChange]="true"
      [analyticsEvent]="'navigation:click-primary-menu:my-advice'"
    >
      {{ 'header.primaryMenu.consumptions.myAdvice' | translate }}
    </a>
  </li>
  <li *ngIf="eliqAccessRights?.accessPerSiteId?.seeHomeProfileTabAndMyHome" class="menu-item">
    <a
      class="menu-link"
      [routerLink]="'/consumptions/home-profile'"
      [skipLocationChange]="true"
      [analyticsEvent]="'navigation:click-primary-menu:home-profile'"
    >
      {{ 'header.primaryMenu.consumptions.myHomeProfile' | translate }}
    </a>
  </li>
  <li *ngIf="eliqAccessRights?.accessPerSiteId?.seeMandatePage" class="menu-item">
    <a
      class="menu-link"
      [routerLink]="'/consumptions/fluvius-consents'"
      [skipLocationChange]="true"
      [analyticsEvent]="'navigation:click-primary-menu:fluvius-consents'"
    >
      {{ 'header.primaryMenu.consumptions.myFluviusConsents' | translate }}
    </a>
  </li>
  <li class="menu-item">
    <a
      class="menu-link"
      [routerLink]="'/consumptions/meter-readings'"
      [skipLocationChange]="true"
      [analyticsEvent]="'navigation:click-primary-menu:meter-readings'"
    >
      {{ 'header.primaryMenu.consumptions.myMeterReadings' | translate }}
    </a>
  </li>
</ul>
