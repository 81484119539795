import { Component, Input, OnInit } from '@angular/core';
import { BillingDetails } from '../../models/billingDetails.interface';
import { Alert } from '../../../../../shared/components/alert/alert.interface';
import { TranslateService } from '@ngx-translate/core';
import { bankTransfer } from '../../billing.constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangeRefundAccountComponent } from './components/change-refund-account/change-refund-account.component';
import { AlertType } from '@app/shared/components/alert/alert.interface';

@Component({
  selector: 'app-refund-method',
  templateUrl: './refund-method.component.html',
  styleUrls: ['./refund-method.component.scss'],
})
export class RefundMethodComponent implements OnInit {
  @Input() billingDetails: BillingDetails;
  @Input() reference: string;

  readonly bankTransfer = bankTransfer;

  public alert: Alert = {
    type: AlertType.info,
    content: {
      message: this.translate.instant('components.paymentMethod.paymentMethodChangeInProgress'),
    },
  };

  constructor(private translate: TranslateService, private modalService: NgbModal) {}

  ngOnInit(): void {}

  changRefundAccount() {
    const modalRef = this.modalService.open(ChangeRefundAccountComponent, {
      size: 'sm',
    });

    modalRef.componentInstance.billingDetails = this.billingDetails;
    modalRef.componentInstance.reference = this.reference;
  }
}
