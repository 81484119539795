<div class="relative">
  <!--
  <canvas baseChart
          [datasets]="lineChartData"
          [labels]="lineChartLabels"
          [options]="lineChartOptions"
          [type]="lineChartType">
  </canvas>
  -->
  <canvas #myChart></canvas>
</div>
