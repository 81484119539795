<app-modal title="{{ 'components.paymentMethod.changePaymentMethod.title' | translate }}">
  <div>
    <p class="my-6">{{ 'components.paymentMethod.changePaymentMethod.intro' | translate }}</p>
    <form [formGroup]="control" novalidate class="form" (ngSubmit)="handleSubmit()">
      <div *ngFor="let paymentMethod of paymentMethods">
        <div class="form-element input-radio-big mb-8">
          <input
            id="{{ paymentMethod }}"
            value="{{ paymentMethod }}"
            type="radio"
            name="invoiceMethod"
            [formControl]="control.get('paymentMethod')"
          />
          <label for="{{ paymentMethod }}">
            {{ 'general.billing.' + paymentMethod | translate }}
          </label>
        </div>
      </div>

      <div *ngIf="control.get('paymentMethod').value !== bankTransfer" class="mt-12">
        <div class="mt-12 mb-6">
          <div class="font-medium text-grey-dark text-2xl">
            {{
              'components.paymentMethod.changePaymentMethod.permission'
                | translate : { firstName: contactDetails.firstName, lastName: contactDetails.lastName }
            }}
          </div>
          <div class="font-medium text-grey-dark60 text-base my-8">
            {{ 'components.paymentMethod.changePaymentMethod.authorization' | translate }}:
          </div>
        </div>

        <div class="border-l-2 border-grey-dark60 text-grey-dark mb-6 pl-6">
          {{ 'general.enterprise.name' | translate }} <br />
          {{ 'general.enterprise.address.street' | translate }}
          {{ 'general.enterprise.address.streetNumber' | translate }} -
          {{ 'general.enterprise.address.zipCode' | translate }} {{ 'general.enterprise.address.city' | translate }}
          <br />
          {{ 'general.billing.enterpriseNumber' | translate }} {{ 'general.enterprise.enterpriseNumber' | translate }}
          <br />
          {{ 'general.billing.creditorIdentificationNumber' | translate }}
          {{ 'general.enterprise.creditorIdentificationNumber' | translate }} <br />
        </div>
        <div class="mt-12">
          <div class="font-medium text-grey-dark60 text-base my-8">
            {{
              'components.paymentMethod.changePaymentMethod.bankAccountUsage' | translate : { reference: reference }
            }}:
          </div>
        </div>

        <div
          class="form-element input-text mb-4"
          [class.errors]="
            !control.get('bankAccount').valid &&
            !control.get('bankAccount').pristine &&
            control.get('bankAccount').touched
          "
        >
          <label for="input-iban"> {{ 'general.billing.IBAN' | translate }}* </label>
          <input
            [formControl]="control.get('bankAccount')"
            [attr.disabled]="accessRights?.modifyRefundAccount ? null : ''"
            id="input-iban"
            class="form__input"
            name="iban"
            type="text"
            placeholder="{{ 'components.billingDetails.form.iban.placeholder' | translate }}"
            autocomplete="off"
          />
          <div class="font-normal text-grey-dark text-xs my-4">
            {{ 'components.billingDetails.form.iban.description' | translate }}
          </div>
          <div class="message">
            <div
              *ngIf="
                !control.get('bankAccount').valid &&
                !control.get('bankAccount').pristine &&
                control.get('bankAccount').touched
              "
            >
              {{ 'components.billingDetails.form.iban.error' | translate }}
            </div>
          </div>
        </div>

        <div *multipleAccessControl="{ rights: [accessRight.modifyAdvance, accessRight.accessAdvance] }" class="mt-4">
          <app-alert
            [alert]="{
              type: alertType.info,
              content: { message: 'components.paymentMethod.changePaymentMethod.invoiceDisclaimer' | translate }
            }"
          >
          </app-alert>
        </div>
      </div>

      <div class="mt-8">
        <button
          type="submit"
          class="btn btn-primary w-full md:w-auto"
          [attr.disabled]="!control.valid || control.pristine ? '' : null"
          [class.not-submittable]="!control.valid || control.pristine"
          [analyticsEvent]="'paymentPreferences:click:update-invoice-method'"
        >
          {{ 'general.update' | translate }}
        </button>
        <button
          type="button"
          class="btn btn-secondary w-full mt-8 md:w-auto md:ml-8"
          (click)="activeModal.close()"
          [analyticsEvent]="'paymentPreferences:click:cancel-invoice-method-update'"
        >
          {{ 'general.cancel' | translate }}
        </button>
      </div>
    </form>
  </div>
</app-modal>
