<div class="action flex mt-8 text-sm text-primary font-bold">
  <div class="mr-12 cursor-pointer" (click)="resumeMove()">
    <img ngSrc="assets/img/icons/standalone/red/eye.svg" width="16" height="12" class="inline-block mr-1 eye"/>
    Visualiser ma demande
  </div>
  <!--
  <div class="mr-8 cursor-pointer">
    <img ngSrc="assets/img/icons/standalone/red/trash.svg" width="16" height="17" class="inline-block mr-1"/>
    Annuler ma demande
  </div>
  -->
</div>
