<ul class="nav-tabs tabs tabs--{{ version }}">
  <!-- Always show item if big version; else only if has contract -->
  <li
    *ngIf="(version === 'big' && electricityContracts?.length < 2) || electricityContracts?.length === 1"
    class="tab__item"
    [class.active]="
      selectedContract === contractTypes.ELECTRICITY || selectedContractCast?.type === contractTypes.ELECTRICITY
    "
  >
    <svg
      *ngIf="version === 'big'"
      viewBox="0 0 19 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="inline-block w-6 h-6 mr-2 align-top mt-1"
    >
      <path
        d="M8.1321 24V20.4115C3.4899 19.7846 0 15.8763 0 11.29V4.78465H3.3851V1.52239C3.3851 0.684435 4.0857 0 4.95 0C5.8143 0 6.5083 0.684435 6.5083 1.52239V4.77825H12.349V1.52239C12.349 0.678038 13.049 0 13.907 0C14.765 0 15.465 0.684435 15.465 1.52239V4.77825H18.857V11.2836C18.857 15.8763 15.367 19.7782 10.725 20.4051V23.9936L8.1321 24ZM2.5929 11.29C2.5929 14.9744 5.6571 17.968 9.429 17.968C13.2 17.968 16.264 14.9744 16.264 11.29V7.3177H2.5929V11.29Z"
        fill="#879092"
      />
    </svg>
    <span
      (click)="changeContract(electricityContracts.length === 1 ? electricityContracts[0] : contractTypes.ELECTRICITY)"
      [analyticsEvent]="analytics + ':' + contractTypes.ELECTRICITY"
    >
      {{ 'general.product.electricity' | translate }}
    </span>
  </li>

  <li
    *ngIf="electricityContracts?.length > 1"
    class="tab__item"
    [class.active]="selectedContractCast?.type === contractTypes.ELECTRICITY"
  >
    <svg
      *ngIf="version === 'big'"
      viewBox="0 0 19 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="inline-block w-6 h-6 mr-2 align-top mt-1"
    >
      <path
        d="M8.1321 24V20.4115C3.4899 19.7846 0 15.8763 0 11.29V4.78465H3.3851V1.52239C3.3851 0.684435 4.0857 0 4.95 0C5.8143 0 6.5083 0.684435 6.5083 1.52239V4.77825H12.349V1.52239C12.349 0.678038 13.049 0 13.907 0C14.765 0 15.465 0.684435 15.465 1.52239V4.77825H18.857V11.2836C18.857 15.8763 15.367 19.7782 10.725 20.4051V23.9936L8.1321 24ZM2.5929 11.29C2.5929 14.9744 5.6571 17.968 9.429 17.968C13.2 17.968 16.264 14.9744 16.264 11.29V7.3177H2.5929V11.29Z"
        fill="#879092"
      />
    </svg>
    <span> {{ 'general.product.electricity' | translate }} </span>
    <i class="icon-arrow--down"></i>
    <ul class="subtab">
      <li *ngFor="let contract of electricityContracts" [class.active]="contract === selectedContract">
        <span (click)="changeContract(contract)" [analyticsEvent]="analytics + ':switch-ean'">
          {{ 'general.meter' | translate }} {{ contract.meterNumber }}
        </span>
      </li>
    </ul>
  </li>

  <!-- Always show item if big version; else only if has contract -->
  <li
    *ngIf="(version === 'big' && gasContracts?.length < 2) || gasContracts?.length === 1"
    class="tab__item"
    [class.active]="selectedContract === contractTypes.GAS || selectedContractCast?.type === contractTypes.GAS"
  >
    <svg
      *ngIf="version === 'big'"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="inline-block w-6 h-6 mr-2 align-top mt-1"
    >
      <path
        d="M9.7134 0.593509L8.972 0L8.1934 0.593509C8.1193 0.667697 6.1172 2.18856 4.1152 4.71097C1.4459 8.08655 0 11.6476 0 15.0232C0 19.9567 4.0411 24 8.972 24C13.9028 24 17.9439 19.9567 17.9439 15.0232C17.9069 6.93663 10.0471 0.853168 9.7134 0.593509ZM5.3758 17.9165C5.3758 14.949 7.7114 12.3153 8.9349 11.1654C10.1954 12.3524 12.494 14.949 12.494 17.9165C12.494 19.8825 10.8998 21.4776 8.9349 21.4776C6.97 21.4776 5.3758 19.8825 5.3758 17.9165ZM15.0151 17.2117C14.6072 12.2411 9.9359 8.60587 9.7134 8.4575L8.9349 7.86399L8.1563 8.4575C8.008 8.60587 3.2996 12.2782 2.8918 17.2117C2.6323 16.5441 2.521 15.8022 2.521 15.0232C2.521 11.4992 4.4489 8.34621 6.0802 6.30603C7.1553 4.93354 8.2676 3.85781 8.972 3.2272C9.6764 3.85781 10.7886 4.93354 11.8638 6.30603C13.495 8.34621 15.4229 11.4992 15.4229 15.0232C15.3858 15.8022 15.2746 16.5441 15.0151 17.2117Z"
        fill="#879092"
      />
    </svg>
    <span
      (click)="changeContract(gasContracts.length === 1 ? gasContracts[0] : contractTypes.GAS)"
      [analyticsEvent]="analytics + ':' + contractTypes.GAS"
    >
      {{ 'general.product.gas' | translate }}
    </span>
  </li>

  <li
    *ngIf="gasContracts?.length > 1"
    class="tab__item"
    [class.active]="selectedContractCast?.type === contractTypes.GAS"
  >
    <svg
      *ngIf="version === 'big'"
      viewBox="0 0 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="inline-block w-6 h-6 mr-2 align-top mt-1"
    >
      <path
        d="M9.7134 0.593509L8.972 0L8.1934 0.593509C8.1193 0.667697 6.1172 2.18856 4.1152 4.71097C1.4459 8.08655 0 11.6476 0 15.0232C0 19.9567 4.0411 24 8.972 24C13.9028 24 17.9439 19.9567 17.9439 15.0232C17.9069 6.93663 10.0471 0.853168 9.7134 0.593509ZM5.3758 17.9165C5.3758 14.949 7.7114 12.3153 8.9349 11.1654C10.1954 12.3524 12.494 14.949 12.494 17.9165C12.494 19.8825 10.8998 21.4776 8.9349 21.4776C6.97 21.4776 5.3758 19.8825 5.3758 17.9165ZM15.0151 17.2117C14.6072 12.2411 9.9359 8.60587 9.7134 8.4575L8.9349 7.86399L8.1563 8.4575C8.008 8.60587 3.2996 12.2782 2.8918 17.2117C2.6323 16.5441 2.521 15.8022 2.521 15.0232C2.521 11.4992 4.4489 8.34621 6.0802 6.30603C7.1553 4.93354 8.2676 3.85781 8.972 3.2272C9.6764 3.85781 10.7886 4.93354 11.8638 6.30603C13.495 8.34621 15.4229 11.4992 15.4229 15.0232C15.3858 15.8022 15.2746 16.5441 15.0151 17.2117Z"
        fill="#879092"
      />
    </svg>
    <span> {{ 'general.product.gas' | translate }} </span>
    <i class="icon-arrow--down"></i>
    <ul class="subtab">
      <li *ngFor="let contract of gasContracts" [class.active]="contract === selectedContract">
        <span (click)="changeContract(contract)" [analyticsEvent]="analytics + ':switch-ean'">
          {{ 'general.meter' | translate }} {{ contract.meterNumber }}
        </span>
      </li>
    </ul>
  </li>
</ul>
