import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BlogService } from '../../services/blog/blog.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { Article } from '../../models/article.interface';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
})
export class BlogComponent implements OnInit, OnDestroy {
  @Input() limit: number;

  public articles$: Observable<Article[]>;

  private languageSubscription: Subscription;

  constructor(private blogService: BlogService, private translate: TranslateService) {
    this.languageSubscription = this.translate.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
      this.articles$ = this.blogService.getBlogArticles(this.limit, langChangeEvent.lang);
    });
  }

  ngOnInit(): void {
    const lang = this.translate.currentLang;
    this.articles$ = this.blogService.getBlogArticles(this.limit, lang);
  }

  ngOnDestroy(): void {
    this.languageSubscription.unsubscribe();
  }
}
