import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-done-boiler-maintenance-os',
  templateUrl: './done-boiler-maintenance-os.component.html',
  styleUrls: ['./done-boiler-maintenance-os.component.scss'],
})
export class DoneBoilerMaintenanceOsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
