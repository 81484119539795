<div [formGroup]="addressFormGroup">
  <div class="flex flex-wrap md:flex-nowrap gap-4 sm:gap-8 mb-4">
    <div class="w-full md:w-3/5 flex-auto form-element input-text mt-4">
      <label>Rue</label>
      <input type="text" formControlName="address" placeholder="Nom de la rue" />
    </div>
    <p class="flex-1 basis-1/5 form-element input-text mt-4">
      <label>N°</label>
      <input onlyNumber type="text" formControlName="number" placeholder="123" />
    </p>
    <p class="flex-1 basis-1/5 form-element input-text mt-4">
      <label>Box</label>
      <input type="text" formControlName="box" placeholder="A1" />
    </p>
  </div>
  <div class="flex flex-col sm:flex-row gap-4 sm:gap-8 mb-4">
    <p class="w-full sm:w-1/3 form-element input-text mt-4">
      <label>Code Postal</label>
      <input onlyNumber type="text" formControlName="zipCode" placeholder="4000" />
    </p>
    <p class="w-full sm:w-2/3 form-element input-text mt-4">
      <label>Localité</label>
      <input type="text" formControlName="locality" placeholder="Liège" />
    </p>
  </div>
  <div class="flex gap-8">
    <p class="w-full form-element input-text mt-4">
      <label>Pays</label>
      <input type="text" formControlName="country" />
    </p>
  </div>
</div>
