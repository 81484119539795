import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeEmailComponent } from './components/change-email/change-email.component';
import { SharedModule } from '../../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ChangeAddressComponent } from './components/change-address/change-address.component';
import { ContactDetailsComponent } from './pages/contact-details/contact-details.component';
import { ContactDetailsDisplayComponent } from './components/contact-details-display/contact-details-display.component';
import { AnalyticsModule } from '../../../shared/modules/analytics/analytics.module';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@NgModule({
  declarations: [ChangeEmailComponent, ChangeAddressComponent, ContactDetailsComponent, ContactDetailsDisplayComponent],
  exports: [ChangeEmailComponent, ChangeAddressComponent, ContactDetailsDisplayComponent],
  imports: [CommonModule, SharedModule, TranslateModule, ReactiveFormsModule, AnalyticsModule],
})
export class ContactModule {}

_('pages.contactDetails.pageTitle');
_('general.contact.civilityOptions.mr');
_('general.contact.civilityOptions.mrs');
_('general.contact.civilityOptions.miss');
_('general.contact.civilityOptions.mrAndMrs');
_('components.contact.changeEmail.form.email');
_('components.contact.changeEmail.form.emailConfirmation');
_('components.contact.changeEmail.form.error.emailNotIdentical');
