<div class="flex flex-col md:flex-row gap-x-16">
  <img [src]="'./assets/img/home-profile/guide/step' + step.stepIndex + '.png'" class="hidden md:block" />
  <img [src]="'./assets/img/home-profile/guide/step' + step.stepIndex + '-mobile.png'" class="block md:hidden" />
  <div class="flex flex-col justify-between">
    <div class="pt-8 md:pt-0">
      <app-step-navigation [currentStep]="step.stepIndex" [stepsNumber]="stepsNumber"></app-step-navigation>
      <h3 class="te-text-gradient-home text-4xl font-normal pt-4 leading-snug">
        {{ 'components.firstLoginGuide.step' + step.stepIndex + '.title' | translate }}
      </h3>
      <div
        class="pt-8"
        [innerHTML]="'components.firstLoginGuide.step' + step.stepIndex + '.description' | translate"
      ></div>
    </div>
    <ng-content></ng-content>
  </div>
</div>
