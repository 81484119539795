import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UpsellRequest } from './models/upsell.interface';
import { SimulationRequest, SimulationResponse } from '../contracts/models/price.simulation';

@Injectable({
  providedIn: 'root',
})
export class UpSellService {
  constructor(private http: HttpClient) {}

  public getPriceSimulation(
    customerReference: string,
    siteId: string,
    request: SimulationRequest,
    loader: boolean = false
  ): Observable<SimulationResponse> {
    const params = new HttpParams().set('loader', loader.toString());
    return this.http.post<SimulationResponse>(`/v1/up-sell/${customerReference}/sites/${siteId}/simulate`, request, {
      params,
    });
  }

  public addServiceUpSell(
    customerReference: string,
    siteId: string,
    request: UpsellRequest
  ): Observable<SimulationResponse> {
    return this.http.post<SimulationResponse>(`/v1/up-sell/${customerReference}/sites/${siteId}/services`, request);
  }
}
