<div class="grid grid-cols-12 gap-4 items-center">
  <div class="col-span-5 md:col-span-3">
    <app-invoice-type [invoice]="invoice" [showInvoiceNumber]="true"> </app-invoice-type>
  </div>
  <div class="col-span-5 md:col-span-3">
    <div class="flex flex-col w-full text-2xl font-medium text-center">
      {{ invoice?.amount?.amountWithVat | currency : 'EUR' : 'symbol' : '1.2-2' }}
      <span *ngIf="(facade.accessRights$ | async)?.excludeVAT" class="block text-xs text-center text-grey-dark60">{{
        'general.inclVAT' | translate
      }}</span>
    </div>
  </div>
  <div class="hidden md:block col-span-5">
    <app-invoice-status [invoice]="invoice"></app-invoice-status>
    <div *ngIf="invoice?.paymentLink" class="mt-4 pl-[3.5rem]">
      <app-invoice-pay-online
        [billingDetails]="billingDetails"
        [invoice]="invoice"
        [alignment]="'left'"
      ></app-invoice-pay-online>
    </div>
  </div>
  <div class="col-span-2 md:col-span-1 text-right">
    <app-invoice-actions
      [billingDetails]="billingDetails"
      [invoice]="invoice"
      [accessRights]="facade.accessRights$ | async"
    ></app-invoice-actions>
  </div>
  <div class="col-span-12 md:hidden">
    <app-invoice-status [invoice]="invoice"></app-invoice-status>
  </div>
  <app-invoice-issue-notifier [invoice]="invoice" class="col-span-12"> </app-invoice-issue-notifier>
</div>
