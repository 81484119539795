import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SiteService } from '../../../user/services/site/site.service';

@Component({
  selector: 'app-select-sites',
  templateUrl: './select-sites.component.html',
  styleUrls: ['./select-sites.component.scss'],
})
export class SelectSitesComponent implements OnInit, OnChanges {
  @Output() goBackEmitter = new EventEmitter<any>();
  @Output() formReady = new EventEmitter<string>();
  @Input() reference: string;
  @Input() control: UntypedFormGroup;

  public sites$: any;

  constructor(private siteService: SiteService, private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.control.addControl('site', this.fb.control(''));
    this.control.get('site').valueChanges.subscribe((value) => this.formReady.emit());
  }

  ngOnChanges(): void {
    this.sites$ = this.siteService.getSites(this.reference);
    // We need to disable the sites that do not have an eligibility. Pipe and add the eligibility info.
  }

  public goBack() {
    this.goBackEmitter.emit();
  }

  public continue() {
    this.formReady.emit();
  }
}
