import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillingDetailsCuzoApi, InvoiceModelV2CuzoApi } from '../../../../../shared/models/cuzo-be-contract';
import { InvoiceTypeComponent } from '@app/modules/customer-zone/invoices/components/micro/invoice-type/invoice-type.component';
import { InvoiceStatusComponent } from '@app/modules/customer-zone/invoices/components/micro/invoice-status/invoice-status.component';
import { InvoicePayOnlineComponent } from '@app/modules/customer-zone/invoices/components/micro/invoice-pay-online/invoice-pay-online.component';
import { InvoiceActionsComponent } from '@app/modules/customer-zone/invoices/components/micro/invoice-actions/invoice-actions.component';
import { InvoiceIssueNotifierComponent } from '@app/modules/customer-zone/invoices/components/micro/invoice-issue-notifier/invoice-issue-notifier.component';
import { MainFacade } from '@app/core/facade/main.facade';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-invoice',
  standalone: true,
  imports: [
    CommonModule,
    InvoiceTypeComponent,
    InvoiceStatusComponent,
    InvoicePayOnlineComponent,
    InvoiceActionsComponent,
    InvoiceIssueNotifierComponent,
    TranslateModule,
  ],
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
})
export class InvoiceComponent implements OnInit {
  @Input() invoice: InvoiceModelV2CuzoApi;
  @Input() billingDetails: BillingDetailsCuzoApi;

  constructor(public facade: MainFacade) {}

  ngOnInit(): void {}
}
