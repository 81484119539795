import { Component, Input, OnChanges } from '@angular/core';
import { CurrencyPipe, DatePipe, LowerCasePipe, NgForOf, NgIf, TitleCasePipe } from '@angular/common';
import { MainFacade } from '@app/core/facade/main.facade';
import { BillingFacade } from '@app/core/facade/billing.facade';
import { take } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { InvoicesModule } from '@app/modules/customer-zone/invoices/invoices.module';
import { PaymentPlanDetailsComponent } from '@app/modules/customer-zone/invoices/components/payment-plan/overview/payment-plan-details/payment-plan-details.component';
import { UpcomingPaymentComponent } from '@app/modules/customer-zone/invoices/components/payment-plan/overview/upcoming-payment/upcoming-payment.component';
import { TranslateModule } from '@ngx-translate/core';
import { PaymentPlanCuzoApi } from '@app/shared/models/cuzo-be-contract';
import { RelatedInvoicesComponent } from '@app/modules/customer-zone/invoices/components/payment-plan/overview/payment-plan-details/related-invoices/related-invoices.component';

@Component({
  selector: 'app-payment-plan-overview',
  standalone: true,
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  imports: [
    NgIf,
    CurrencyPipe,
    DatePipe,
    LowerCasePipe,
    TitleCasePipe,
    NgForOf,
    InvoicesModule,
    PaymentPlanDetailsComponent,
    UpcomingPaymentComponent,
    TranslateModule,
    RelatedInvoicesComponent,
  ],
})
export class PaymentPlanOverviewComponent {
  @Input() paymentPlan: PaymentPlanCuzoApi;
  @Input() isFinished: boolean = false;

  constructor(public facade: MainFacade, public billingFacade: BillingFacade) {}

  downloadPDF(invoiceId: string) {
    this.billingFacade
      .getInvoiceDocument(invoiceId)
      .pipe(take(1))
      .subscribe((response: HttpResponse<Blob>) =>
        this.facade.utils.downLoadFile(response.body, 'application/pdf', `invoice.pdf`)
      );
  }
}
