/* eslint-disable */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ReferenceDetails } from '../../../user/models/reference.interface';
import { ReferenceService } from '../../../user/services/reference/reference.service';
import { take, takeUntil } from 'rxjs';
import { BoilerOneShotSteps, UpsellRequest } from '../../models/upsell.interface';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SiteService } from '../../../user/services/site/site.service';
import { ContactDetails } from '../../../contact/models/contactDetails.interface';
import { ContactService } from '../../../contact/services/contact/contact.service';
import { UpSellService } from '../../upsell.service';
import { ToastrService } from 'ngx-toastr';
import { LangChangeEvent } from '@ngx-translate/core';
import { ConsentService } from '../../../user/services/consent/consent.service';
import { UserService } from '../../../user/services/user/user.service';
import { MainFacade } from '@app/core/facade/main.facade';
import { Site } from '@app/modules/customer-zone/user/models/site.interface';

@Component({
  selector: 'app-boiler-one-shot',
  templateUrl: './boiler-one-shot.component.html',
  styleUrls: ['./boiler-one-shot.component.scss'],
})
export class BoilerOneShotComponent implements OnInit, OnDestroy {
  readonly BoilerOneShotSteps = BoilerOneShotSteps;

  public totalSteps: number;
  public activeStepCount: number;
  public activeStep: any = BoilerOneShotSteps.SELECT_REFERENCE;

  public referencesDetails: ReferenceDetails[] = [];
  public notifier = new Subject<void>();
  public components = [];
  public control: UntypedFormGroup = new UntypedFormGroup({});

  public promoCode: string;
  public bundleCode: string;

  model = {} as UpsellRequest;
  askReferences = true;

  contactDetail: ContactDetails;

  public backButton = false;

  constructor(
    public formBuilder: UntypedFormBuilder,
    private facade: MainFacade,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private referenceService: ReferenceService,
    private siteService: SiteService,
    private contactService: ContactService,
    private service: UpSellService,
    private userService: UserService,
    private consentService: ConsentService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    this.analytics();
    this.facade.translate.onLangChange
      .pipe(takeUntil(this.notifier))
      .subscribe((event: LangChangeEvent) => this.analytics());

    const queryParams = this.activatedRoute.snapshot.queryParamMap['params'];
    this.askReferences = !queryParams.hasOwnProperty('bundleCode');

    this.promoCode = queryParams.hasOwnProperty('promoCode') ? queryParams.promoCode : '';
    this.bundleCode = queryParams.hasOwnProperty('bundleCode') ? queryParams.bundleCode : '';

    this.buildForm();
    this.activeStep = BoilerOneShotSteps.SELECT_REFERENCE;

    this.referenceService.getReferencesDetails().subscribe((value) => {
      this.referencesDetails = value;
      if (this.referencesDetails.length === 1) {
        this.control
          .get([BoilerOneShotSteps.SELECT_REFERENCE, 'reference'])
          .setValue(this.referencesDetails[0].reference);
        this.activeStep = BoilerOneShotSteps.SELECT_SITES;
        this.activeStepCount = Object.values(BoilerOneShotSteps).indexOf(this.activeStep) + 1;
      }
    });

    this.facade.activeSite$.pipe(take(1)).subscribe((site: Site) => {
      const reference = this.facade.state$.value.reference;
      this.refreshContactDetailByReference(reference);
      if (!this.askReferences) {
        this.control.get([BoilerOneShotSteps.SELECT_REFERENCE, 'reference']).setValue(reference);
        this.control.get([BoilerOneShotSteps.SELECT_SITES, 'site']).setValue(site?.id);
        this.activeStep = BoilerOneShotSteps.HOUSE_AGE;
      }
    });

    // Set step information.
    this.totalSteps = Object.keys(BoilerOneShotSteps).length;
    this.activeStepCount = Object.values(BoilerOneShotSteps).indexOf(this.activeStep) + 1;
    this.checkDisplayBackButton();
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }

  private buildForm() {
    // tslint:disable-next-line:forin
    for (const step in BoilerOneShotSteps) {
      this.control.addControl(BoilerOneShotSteps[step], new UntypedFormGroup({}));
    }

    // Prepare Elements which can be prefilled.
    const selectReferenceController = this.control.get(BoilerOneShotSteps.SELECT_REFERENCE) as UntypedFormGroup;
    selectReferenceController.addControl('reference', this.formBuilder.control(''));

    const selectSitesController = this.control.get(BoilerOneShotSteps.SELECT_SITES) as UntypedFormGroup;
    selectSitesController.addControl('site', this.formBuilder.control(''));

    const boilerTypeController = this.control.get(BoilerOneShotSteps.BOILER_TYPE) as UntypedFormGroup;
    boilerTypeController.addControl('bundleCode', this.formBuilder.control(this.bundleCode));

    const confirmationController = this.control.get(BoilerOneShotSteps.CONFIRMATION) as UntypedFormGroup;
    confirmationController.addControl('promoCode', this.formBuilder.control(this.promoCode));
  }

  // @todo rewrite this to an async object
  private refreshContactDetailByReference(reference: string) {
    this.contactService.getContactDetails(reference).subscribe((detail: ContactDetails) => {
      this.contactDetail = detail;
    });
  }

  private checkDisplayBackButton(): void {
    this.backButton =
      this.activeStep !== BoilerOneShotSteps.SELECT_REFERENCE && this.activeStep !== BoilerOneShotSteps.DONE;
  }

  public goToStep(step: BoilerOneShotSteps) {
    this.activeStep = step;
    this.activeStepCount = Object.values(BoilerOneShotSteps).indexOf(this.activeStep) + 1;
    this.checkDisplayBackButton();
  }

  public goBack() {
    const index = Object.values(BoilerOneShotSteps).indexOf(this.activeStep);
    this.activeStep = Object.values(BoilerOneShotSteps)[index - 1];
    this.activeStepCount = Object.values(BoilerOneShotSteps).indexOf(this.activeStep) + 1;
    this.checkDisplayBackButton();
  }

  public submit() {
    const values = this.control.value;
    const upsellRequest: UpsellRequest = {
      bundleCode: values[BoilerOneShotSteps.BOILER_TYPE].bundleCode,
      promoCode: values[BoilerOneShotSteps.CONFIRMATION].promoCode,
      contact: {
        name: values[BoilerOneShotSteps.CONTACT_INFO].name,
        phone: values[BoilerOneShotSteps.CONTACT_INFO].phone,
        email: values[BoilerOneShotSteps.CONTACT_INFO].email,
      },
      metadata: {
        taxEligibility: values[BoilerOneShotSteps.HOUSE_AGE].taxEligibility,
        vatConfirmation: values[BoilerOneShotSteps.HOUSE_AGE].vatConfirmation,
      },
      registration: {
        context: 'cuzo',
        salesContext: 'Web',
        salesContextDetail: 'Web',
      },
    };

    const reference = values[BoilerOneShotSteps.SELECT_REFERENCE].reference;
    const siteId = values[BoilerOneShotSteps.SELECT_SITES].site;

    this.service.addServiceUpSell(reference, siteId, upsellRequest).subscribe(
      (value) => {
        this.goToStep(BoilerOneShotSteps.DONE);
      },
      (error) => {
        this.toastrService.error(this.facade.translate.instant('errorMessages.serverError'));
      }
    );
  }

  private analytics() {
    this.facade.analytics.push(
      {
        event: 'pageview',
        page: {
          phase: 'care',
          category: 'cuzo',
          subCategory: 'boiler-one-shot - cuzo',
        },
      },
      {
        reference: this.facade?.state$?.value?.reference,
        site: this.facade?.state$?.value?.activeSite,
        accessRights: this.facade?.state$?.value?.accessRights,
      }
    );
  }
}
