<div class="sites-infos bg-white">
  <div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
    <div class="items-center pb-8 pt-14 sm:pb-14 md:grid md:grid-cols-12 md:gap-16">
      <div class="mb-12 md:mb-0 md:col-span-8">
        <app-sites-switcher
          class="inline-block w-full ml-auto sites-switcher mb-8 md:mb-0 md:w-auto"
          [sites]="sites$ | async"
          [activeSite]="activeSite$ | async"
          [version]="versionSize.big"
          [activeReference]="activeReference"
          (switchSiteEmitter)="switchSite($event)"
        >
        </app-sites-switcher>
      </div>

      <div class="md:col-span-4" *ngIf="meterOptions.length > 1">
        <app-meter-switcher
          [options]="meterOptions"
          [active]="userData.deliveryPoint"
          (selectAction)="switchDeliveryPoint($event)"
        ></app-meter-switcher>
      </div>
    </div>
  </div>
</div>

<div class="px-4 xl:max-w-screen-xl xl:w-full xl:m-auto">
  <div class="flex mt-8 mb-16">
    <div class="text-xs" [ngClass]="isLast ? 'text-primary' : 'nav-item'" *ngFor="let i of [0, 1, 2]; last as isLast">
      {{ 'components.consumption.powerPeak.navigation.' + i | translate }}
    </div>
  </div>

  <div class="md:flex md:items-center go-back">
    <div (click)="goBack()">{{ 'components.consumption.powerPeak.goBack' | translate }}</div>
  </div>

  <div class="md:flex md:items-center">
    <h2 class="mt-4 mb-8 font-display font-normal text-5xl te-text-gradient-home inline-block md:mb-12 md:mt-4">
      {{ 'components.consumption.powerPeak.title' | translate }}
    </h2>
  </div>
  <div class="md:grid md:grid-cols-12 gap-16">
    <div class="card-primary-small mb-8 md:col-span-12">
      <div class="ghost-container fadeIn pt-28 mx-16" *ngIf="loading">
        <app-ghost-consumption-chart></app-ghost-consumption-chart>
      </div>
      <ng-container *ngIf="barChartData[0].data && !loading">
        <div class="fadeIn">
          <div class="flex justify-between border-b-2 pb-4 mb-4">
            <div class="tab_info">{{ 'components.consumption.powerPeak.perMonth' | translate }}</div>
            <div class="tab_info">{{ 'components.contracts.products.ELECTRICITY' | translate }}</div>
          </div>
          <div class="chart-container">
            <canvas
              baseChart
              [datasets]="barChartData"
              [options]="barChartOptions"
              [legend]="barChartLegend"
              [type]="barChartType"
              [labels]="barChartLabels"
            >
            </canvas>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="no-indexes pt-6" *ngIf="!barChartData[0].data && !barChartDataError && !loading">
  {{ 'general.noIndex' | translate }}
</div>
<div *ngIf="barChartDataError">
  <div class="mb-16"></div>
  {{ 'errorMessages.serverError' | translate }}
</div>

<app-power-peak-q-and-a></app-power-peak-q-and-a>
