import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { LoaderService } from '../../../../../shared/modules/loader/services/loader.service';
import { ContractService } from '../../../contracts/services/contract.service';
import { VersionSize } from '@app/shared/models/units.interface';
import { ContractDtoCuzoApi, ContractDtoTypeEnumCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Component({
  selector: 'app-energy-switcher',
  templateUrl: './energy-switcher.component.html',
  styleUrls: ['./energy-switcher.component.scss'],
})
export class EnergySwitcherComponent implements OnInit, OnChanges {
  @Input() version? = VersionSize.small;
  @Input() reference: string;
  @Input() siteId: string;
  @Input() contracts: ContractDtoCuzoApi[];
  @Output() selectedContractEmitter: EventEmitter<ContractDtoCuzoApi | ContractDtoTypeEnumCuzoApi> = new EventEmitter<
    ContractDtoCuzoApi | ContractDtoTypeEnumCuzoApi
  >();
  electricityContracts: ContractDtoCuzoApi[];
  gasContracts: ContractDtoCuzoApi[];
  selectedContract: ContractDtoCuzoApi | ContractDtoTypeEnumCuzoApi;
  analytics: string;

  constructor(private loaderService: LoaderService, private contractService: ContractService) {}

  get contractTypes() {
    return ContractDtoTypeEnumCuzoApi;
  }
  get selectedContractCast() {
    return this.selectedContract as ContractDtoCuzoApi;
  }

  ngOnInit(): void {
    this.analytics =
      this.version === 'small' ? 'myEnergyConsumption:click-graph-filter' : 'myEnergyMeterReading:click-tab';
  }

  ngOnChanges(): void {
    if (this.contracts) {
      this.electricityContracts = this.contractService.filterDuplicateContracts(
        this.contracts.filter(
          (contract: ContractDtoCuzoApi) => contract.type === ContractDtoTypeEnumCuzoApi.ELECTRICITY
        )
      );
      this.gasContracts = this.contractService.filterDuplicateContracts(
        this.contracts.filter((contract: ContractDtoCuzoApi) => contract.type === ContractDtoTypeEnumCuzoApi.GAS)
      );
      this.selectedContract =
        this.electricityContracts.length >= 1 ? this.electricityContracts[0] : this.gasContracts[0];
      this.selectedContract = !this.selectedContract ? ContractDtoTypeEnumCuzoApi.ELECTRICITY : this.selectedContract;
      this.selectedContractEmitter.emit(this.selectedContract);
    }
  }

  changeContract(contract: ContractDtoCuzoApi | ContractDtoTypeEnumCuzoApi) {
    this.loaderService.showLoadingEffect();
    this.selectedContract = contract;
    this.selectedContractEmitter.emit(contract);
  }
}
