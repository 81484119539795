import { Component, Input, OnInit } from '@angular/core';
import { Mandate } from '@app/modules/customer-zone/consumption/models/mandates.interface';
import { Contract } from '@app/modules/customer-zone/contracts/models/contract.interface';
import { EliqAccessRights } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';

@Component({
  selector: 'app-fluvius-mandates-section',
  templateUrl: './fluvius-mandates-section.component.html',
  styleUrls: ['./fluvius-mandates-section.component.scss'],
})
export class FluviusMandatesSectionComponent implements OnInit {
  @Input() mandates?: Mandate[];
  @Input() reference?: string;
  @Input() contracts?: Contract[];
  @Input() eliqAccessRights: EliqAccessRights;
  constructor() {}

  ngOnInit(): void {}
}
