import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@app/shared/shared.module';
import { ProductInfoComponent } from './product-info/product-info.component';
import { RequestProcessingInfoComponent } from './request-processing-info/request-processing-info.component';
import { AccessRight } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { UtilsService } from '@app/shared/utils/utils.service';
import { MainFacade } from '@app/core/facade/main.facade';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-register-fix-product',
  standalone: true,
  imports: [CommonModule, TranslateModule, SharedModule, ProductInfoComponent, RequestProcessingInfoComponent],
  templateUrl: './register-fix-product.component.html',
  styleUrls: ['./register-fix-product.component.scss'],
})
export class RegisterFixProductComponent implements OnInit, OnDestroy {
  public notifier = new Subject<void>();
  public accessRight = AccessRight;
  public productSwapApplied = false;
  private activeReference: string = undefined;

  constructor(private utilsService: UtilsService, private facade: MainFacade) {}

  ngOnInit() {
    this.facade.reference$.pipe(takeUntil(this.notifier)).subscribe((reference: string) => {
      this.activeReference = reference;
      this.checkProductSwapState();
    });
  }

  checkProductSwapState() {
    this.productSwapApplied = this.utilsService.getCookieValue('productSwapApplied_' + this.activeReference);
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }
}
