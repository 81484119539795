import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentService } from '@app/shared/services/content/content.service';
import { Content } from '@app/shared/models/content.interface';

@Component({
  selector: 'app-chat-cta',
  templateUrl: './chat-cta.component.html',
  styleUrls: ['./chat-cta.component.scss'],
})
export class ChatCtaComponent implements OnInit {
  public salesforceChat = (window as any).embedded_svc;
  public agentAvailable = this.salesforceChat ? this.salesforceChat.settings.agentAvailableOnButtonClick : null;
  public chatInstantiated = (window as any)?.hasOwnProperty('$A') || (window as any)?.hasOwnProperty('AuraLocker');
  public content$: Observable<Content>;

  constructor(private contentService: ContentService) {}

  ngOnInit(): void {
    this.content$ = this.contentService.getActiveContent();
  }

  public openChat() {
    if (this.agentAvailable && !this.chatInstantiated) {
      this.salesforceChat.inviteAPI.inviteButton.acceptInvite();
    }
  }
}
