import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MoveFormComponent } from '@app/modules/customer-zone/move/components/move-form/move-form.component';
import { SupplierComponent } from '@app/modules/customer-zone/move/components/move-form/steps/supplier/supplier.component';
import { MovingInfoComponent } from '@app/modules/customer-zone/move/components/move-form/steps/moving-info/moving-info.component';
import { MoveFormPaths } from '@app/core/models/paths';
import { MyMetersComponent } from '@app/modules/customer-zone/move/components/move-form/steps/my-meters/my-meters.component';
import { NewAddressComponent } from '@app/modules/customer-zone/move/components/move-form/steps/new-address/new-address.component';
import { NewMetersComponent } from '@app/modules/customer-zone/move/components/move-form/steps/new-meters/new-meters.component';

const routes: Routes = [
  {
    path: '',
    component: MoveFormComponent,
    children: [
      { path: '', redirectTo: MoveFormPaths.supplier, pathMatch: 'full' },
      { path: MoveFormPaths.supplier, component: SupplierComponent },
      { path: MoveFormPaths.movingInfo, component: MovingInfoComponent },
      { path: MoveFormPaths.myMeters, component: MyMetersComponent },
      { path: MoveFormPaths.newAddress, component: NewAddressComponent },
      { path: MoveFormPaths.newMeters, component: NewMetersComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MoveFormRoutingModule {}
