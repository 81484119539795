import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LangChangeEvent } from '@ngx-translate/core';
import { UserService } from '../../services/user/user.service';
import { UserInfo } from '../../models/userInfo.interface';
import { take, takeUntil } from 'rxjs';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { Consent, PersonalConsent } from '../../models/consents.interface';
import { AccessRights } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { MainFacade } from '@app/core/facade/main.facade';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss'],
})
export class ConsentComponent implements OnInit, OnDestroy {
  public consents$: Observable<Consent[]>;
  public myConsents$: Observable<PersonalConsent[]>;
  public userInfo$: Observable<UserInfo>;
  public activeReference: string;
  public language: string = this.facade.translate.currentLang;

  private notifier: Subject<void> = new Subject();

  constructor(private facade: MainFacade, private userService: UserService) {}

  ngOnInit(): void {
    this.analytics();
    this.facade.utils.setPageTitle('pages.consents.pageTitle');
    this.userInfo$ = this.userService.getUserInfo();
    this.facade.translate.onLangChange.pipe(takeUntil(this.notifier)).subscribe((event: LangChangeEvent) => {
      this.language = event.lang;
      this.analytics();
    });

    this.facade.accessRights$.pipe(takeUntil(this.notifier)).subscribe((accessRights: AccessRights) => {
      this.activeReference = this.facade.state$.value.reference;
      this.myConsents$ = this.facade.loadPersonalConsents(this.activeReference);
      this.consents$ = this.facade.loadConsents(accessRights.businessConsents);
    });
  }

  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
  }

  public downloadData(type: string) {
    const params = new HttpParams().set('type', type).set('sendingMode', 'download');
    const today = new Date().toISOString().slice(0, 10);
    const filename = `TotalEnergies_portability_${this.activeReference}_${today}.${type}`;
    this.facade
      .downloadPersonalData(this.activeReference, params)
      .pipe(take(1))
      .subscribe((response: HttpResponse<Blob>) =>
        this.facade.utils.downLoadFile(response.body, `application/${type}`, filename)
      );
  }

  private analytics() {
    this.facade.analytics.push(
      {
        event: 'pageview',
        page: {
          phase: 'care',
          category: 'cuzo',
          subCategory: 'consent - cuzo',
        },
      },
      {
        reference: this.facade?.state$?.value?.reference,
        site: this.facade?.state$?.value?.activeSite,
        accessRights: this.facade?.state$?.value?.accessRights,
      }
    );
  }
}
