import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs';
import { Consent, ConsentUpdate, PersonalConsent, PersonalConsentPerReference } from '../../models/consents.interface';

export interface TypeConsents {
  personal: Consent[];
  business: Consent[];
}

@Injectable({
  providedIn: 'root',
})
export class ConsentService {
  private personalConsents: PersonalConsentPerReference[] | Record<string, unknown> = {};
  private typeConsents: TypeConsents = { personal: null, business: null };

  constructor(private http: HttpClient) {}

  public resetPersonalConsentsCache(reference: string) {
    this.personalConsents[reference] = null;
  }

  public updatePersonalConsents(reference: string, consents: PersonalConsent[]): void {
    this.personalConsents[reference] = consents;
  }

  public getPersonalConsents(reference: string): Observable<PersonalConsent[]> {
    if (this.personalConsents && this.personalConsents[reference]) {
      return of(this.personalConsents[reference]);
    }
    return this.getPersonalConsentsFromApi(reference);
  }

  public getTypeConsents(business = false): Observable<Consent[]> {
    // check business cache consents
    if (business && this.typeConsents.business) {
      return of(this.typeConsents.business);
    }

    // check personal cache consents
    if (!business && this.typeConsents.personal) {
      return of(this.typeConsents.personal);
    }

    return this.getTypeConsentsFromApi(business);
  }

  public getPersonalConsentsFromApi(reference: string): Observable<PersonalConsent[]> {
    return this.http.get<PersonalConsent[]>(`/v1/customers/${reference}/consents`).pipe(
      tap((personalConsents: PersonalConsent[]) => {
        if (!this.personalConsents[reference]) {
          this.personalConsents[reference] = {};
        }
        this.updatePersonalConsents(reference, personalConsents);
      })
    );
  }

  public postPersonalConsentsToAPI(reference: string, payload: ConsentUpdate[]) {
    return this.http.put<any>(`/v1/customers/${reference}/consents`, payload);
  }

  public getPersonalData(reference: string, params) {
    return this.http.get<any>(`/v1/customers/${reference}/portability/document`, {
      params,
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  private getTypeConsentsFromApi(business: boolean): Observable<Consent[]> {
    return this.http.get<Consent[]>(`/v1/consent-types${business ? '?segment=B2B' : ''}`).pipe(
      tap((typeConsents: Consent[]) => {
        if (business) {
          this.typeConsents.business = typeConsents;
        } else {
          this.typeConsents.personal = typeConsents;
        }
      })
    );
  }
}
