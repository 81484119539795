import { Directive, HostListener, Inject, Input } from '@angular/core';
import { AnalyticsService } from '../services/analytics.service';
import { AnalyticsEventProcessorInterface } from '../analytics-event-processor.interface';

@Directive({
  selector: '[analyticsEvent]',
})
export class AnalyticsEventDirective {
  @Input('analyticsEvent') event: any;
  private processer: AnalyticsEventProcessorInterface;

  constructor(
    private analyticsService: AnalyticsService,
    @Inject('processor') processor?: AnalyticsEventProcessorInterface
  ) {
    this.processer = processor;
  }

  @HostListener('click') onClick() {
    this.registerEvent();
  }

  private registerEvent() {
    const event = this.processer.process(this.event);
    this.analyticsService.push(event);
  }
}
