import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ghost-consumption-chart',
  templateUrl: './ghost-consumption-chart.component.html',
  styleUrls: ['./ghost-consumption-chart.component.scss'],
})
export class GhostConsumptionChartComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
