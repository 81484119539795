<div class="mt-16 text-center">
  <ng-container *ngIf="privateContactInfo?.formattedPhoneNumber || privateContactInfo?.formattedEmail">
    <div class="inline-block w-full text-center mx-auto">
      <h2
        class="font-display font-normal text-5xl te-text-gradient-home inline-block leading-snug w-full sm:max-w-4xl"
        [innerHTML]="'pages.bindingProcess.step2.title' | translate"
      ></h2>
    </div>
    <div class="w-full sm:max-w-2xl mx-auto py-10">
      <div class="inline-block mb-8 w-full" *ngIf="sendingError">
        <app-alert [alert]="sendingError"></app-alert>
      </div>

      <p class="text-center my-4 content" *ngIf="isAcceptedReference">
        <span *ngIf="privateContactInfo?.formattedPhoneNumber && !privateContactInfo?.formattedEmail">
          {{ 'pages.bindingProcess.step2.getCodeByPhone' | translate }}
        </span>
        <span *ngIf="!privateContactInfo?.formattedPhoneNumber && privateContactInfo?.formattedEmail">
          {{ 'pages.bindingProcess.step2.getCodeByEmail' | translate }}
        </span>
        <span *ngIf="privateContactInfo?.formattedPhoneNumber && privateContactInfo?.formattedEmail">
          {{ 'pages.bindingProcess.step2.getCodeByPhoneOrEmail' | translate }}
        </span>
      </p>
    </div>

    <div class="text-center" *ngIf="isAcceptedReference">
      <button
        class="btn btn-primary inline-block w-full font-bold sm:mb-0 sm:w-auto"
        *ngIf="privateContactInfo?.formattedPhoneNumber"
        (click)="emitSendingMode('PHONE')"
        [analyticsEvent]="'binding:send-code:phone'"
      >
        {{ privateContactInfo?.formattedPhoneNumber }}
      </button>
      <span
        class="inline-block my-6 w-full text-center sm:my-0 sm:w-auto sm:px-4"
        *ngIf="privateContactInfo?.formattedPhoneNumber && privateContactInfo?.formattedEmail"
      >
        {{ 'pages.bindingProcess.step2.or' | translate }}
      </span>
      <button
        class="btn btn-primary inline-block w-full font-bold mb-4 sm:mb-0 sm:w-auto"
        *ngIf="privateContactInfo?.formattedEmail"
        (click)="emitSendingMode('EMAIL')"
        [analyticsEvent]="'binding:send-code:email'"
      >
        {{ privateContactInfo?.formattedEmail }}
      </button>
    </div>
  </ng-container>

  <app-unhappy-flow *ngIf="!privateContactInfo?.formattedPhoneNumber && !privateContactInfo?.formattedEmail">
  </app-unhappy-flow>

  <div class="my-8">
    <span class="navigate-back text-sm text-primary cursor-pointer font-bold">
      <a (click)="emitGoBackRequest()" [analyticsEvent]="'binding:click:back'">
        {{ 'pages.emailSent.return' | translate }}
      </a>
    </span>
  </div>
</div>
