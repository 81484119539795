import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TaxEligibility } from '../../models/upsell.interface';

@Component({
  selector: 'app-house-age',
  templateUrl: './house-age.component.html',
  styleUrls: ['./house-age.component.scss'],
})
export class HouseAgeComponent implements OnInit {
  @Input() control: UntypedFormGroup;
  @Output() formReady: EventEmitter<number> = new EventEmitter<number>();
  @Output() goBackEmitter: EventEmitter<any> = new EventEmitter<any>();

  public displayNext = false;

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.control.addControl('taxEligibility', this.fb.control(''));
    this.control.addControl('vatConfirmation', this.fb.control(''));
    this.displayNextButton();

    this.control.get('taxEligibility').valueChanges.subscribe((value) => {
      this.displayNextButton();
      if (value === 'HIGH') {
        this.formReady.emit();
      }
    });
    this.control.get('vatConfirmation').valueChanges.subscribe((value) => {
      this.displayNextButton();

      if (value) {
        this.formReady.emit();
      }
    });
  }

  displayNextButton() {
    this.displayNext =
      this.control.get('taxEligibility').value === TaxEligibility.HIGH ||
      (this.control.get('taxEligibility').value === TaxEligibility.LOW &&
        this.control.get('vatConfirmation').value === true);
  }

  public goBack() {
    this.goBackEmitter.emit();
  }

  public continue() {
    this.formReady.emit();
  }
}
