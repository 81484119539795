import { ORDERED_QUESTION_STAY } from '@app/modules/customer-zone/move/components/move-form/move-form-config';
import { MoveFormPaths } from '@app/core/models/paths';
import { CustomerType } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { EnergyType, RegisterType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { Address } from '@app/shared/models/address.interface';
import { PreSwitchLight } from '@app/modules/customer-zone/move/models/api.interface';
import {
  DocumentCuzoApi,
  UploadDocumentParamsDreCompletionStatusEnumCuzoApi,
} from '@app/shared/models/cuzo-be-contract';
import { Product } from '@app/modules/customer-zone/user/models/product.interface';
import { MoveInRegistration } from '@app/modules/customer-zone/move/models/movein.interface';

export enum MoveOutContext {
  PAPER_MAIL = 'PAPER_MAIL',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  E_MOVE = 'E_MOVE',
  VANISHING = 'VANISHING',
}
export enum MoveOutReason {
  MOVE = 'MOVE',
  DEATH = 'DEATH',
  NAME_CHANGE = 'NAME_CHANGE',
  OWNER = 'OWNER',
  B2C_TO_B2B = 'B2C_TO_B2B',
  B2B_TO_B2C = 'B2B_TO_B2C',
}
export enum MoveStatus {
  INITIATED = 'INITIATED',
}

export const INITIAL_MOVE_STATE: MoveState = {
  route: ORDERED_QUESTION_STAY,
  currentStepUrl: ORDERED_QUESTION_STAY[0],
  currentStep: 0,
  lastStepUrlReached: ORDERED_QUESTION_STAY[0],
  numberOfStep: ORDERED_QUESTION_STAY.length,
  isLastStepReached: true,
  numberOfStepExecuted: 0,
  form: null,
  createdDate: new Date(),
  moveDTO: {
    customerReference: null,
    context: MoveOutContext.E_MOVE,
    reason: MoveOutReason.MOVE,
    sites: [
      {
        siteId: null,
      },
    ],
    newContract: false,
    amendment: null,
    indexCosigned: false,
    failReason: false,
    knowBuyer: false,
    failReasonCode: null,
    status: MoveStatus.INITIATED,
    source: 'MOVE',
    segment: CustomerType.b2c,
    contact: {},
  },
};

export interface MoveState {
  route: MoveFormPaths[];
  currentStepUrl?: MoveFormPaths;
  currentStep?: number;
  lastStepUrlReached?: MoveFormPaths;
  isLastStepReached?: boolean;
  numberOfStep: number;
  numberOfStepExecuted: number;
  form: MoveFormFrontend | any;
  createdDate: Date;
  moveDTO: MoveDTO;
  preSwitchLight?: PreSwitchLight[];
  ilcDate?: string;
  documents?: DocumentCuzoApi[];
  registration?: MoveInRegistration;
}

export interface MoveFormFrontend {
  supplier?: {
    amendment: boolean;
  };
  myMeter?: any; // Not being populated from localStorage
  movingInfo?: {
    dreStatus?: UploadDocumentParamsDreCompletionStatusEnumCuzoApi;
    file?: any;
    movingDate?: string;
  };
  newAddress?: {
    newAddress?: {
      address?: string;
      number?: string;
      box?: string;
      zipCode?: string;
      locality?: string;
    };
    invoicingData?: {
      invoicingMethod: InvoicingMethod;
      hasDifferentInvoicingAddress?: boolean;
      invoicingAddress?: {
        address?: string;
        number?: string;
        box?: string;
        zipCode?: string;
        locality?: string;
      };
    };
    dre?: {
      dreStatus?: string;
      file?: string;
    };
  };
  newMeters: any;
}

export interface MoveDTO {
  id?: string;
  customerReference: string;
  context: MoveOutContext;
  reason: MoveOutReason;
  sites: MoveSite[];
  documents?: any;
  newContract: boolean;
  amendment: boolean;
  indexCosigned: boolean;
  failReason: false;
  knowBuyer: false;
  failReasonCode: null;
  status: MoveStatus;
  source: 'MOVE';
  segment: CustomerType;
  ilcDate?: string;
  contact: Contact;
  moveChainId?: string;
}

export interface Contact {
  id?: string;
  lastName?: string;
  firstName?: string;
  email?: string;
  civility?: string;
  language?: string;
  homePhone?: string;
  homePhonePrefix?: string;
  mobilePhone?: string;
  mobilePhonePrefix?: string;
  birthDate?: any;
}

export interface MoveSite {
  siteId: string;
  address?: Address;
  deliveryPoints?: DeliveryPoint[];
}

export interface DeliveryPoint {
  id?: string;
  energyType: EnergyType;
  code: string;
  requestedServiceDate?: Date;
  moving?: boolean;
  currentSupplier?: string;
  meters?: MoveMeter[];
  dreDocumentStatus?: UploadDocumentParamsDreCompletionStatusEnumCuzoApi;
}

export interface MoveMeter {
  meterNumber?: string; // Preswitch
  number?: string;
  access?: boolean;
  type?: string;
  registerOpen?: boolean;
  registers: MoveRegister[];
  smartMeter: boolean;
}

export interface MoveRegister {
  timeFrame?: RegisterType;
  type?: string;
  value?: string;
  extra?: MoveRegisterExtra;
  // Preswitch
  direction?: string;
  name?: string;
  index?: string;
  measurementNature?: string;
}

export interface MoveRegisterExtra {
  label?: string;
  tooltip?: string;
  unit?: string;
}

export interface MoveProduct extends Product {
  type: EnergyType;
}

export interface MetersByEnergy {
  electricity?: Meter[];
  gas?: Meter[];
}

export interface Meter {
  type?: EnergyType;
  energy?: EnergyType;
  name?: string;
  ean?: string;
  meterNumber?: string;
  number?: string;
}

export enum InvoicingMethod {
  EMAIL = 'EMAIL',
  POST = 'POST',
}

export enum LinkType {
  MOVE_IN = 'MOVE_IN',
}
