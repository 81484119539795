<div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
  <div class="mb-16">
    <div class="md:flex md:items-center">
      <h2 class="mt-12 mb-8 font-display font-normal text-5xl te-text-gradient-home inline-block md:my-12">
        {{ 'pages.meterReading.title' | translate }}
      </h2>
      <app-sites-switcher
        class="inline-block w-full ml-auto sites-switcher mb-8 md:mb-0 md:w-auto"
        [sites]="sites$ | async"
        [activeSite]="activeSite"
        [version]="versionSize.small"
        [activeReference]="activeReference"
        (switchSiteEmitter)="switchSite($event)"
      >
      </app-sites-switcher>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-12 gap-16">
      <div class="md:col-span-12">
        <app-energy-switcher
          [reference]="activeReference"
          [siteId]="activeSiteId"
          [contracts]="contracts$ | async"
          [version]="versionSize.big"
          (selectedContractEmitter)="updateSelectedContract($event)"
        >
        </app-energy-switcher>

        <div *ngIf="selectedContract" class="content">
          <!-- If no contract but only returns ContractTypes (no object) -->
          <div
            *ngIf="
              selectedContract === contractTypes.GAS || selectedContract === contractTypes.ELECTRICITY;
              else meterReading
            "
          >
            <div class="box box--radius box--padding">
              <app-no-consumption [energyType]="selectedContract" [padding]="false"> </app-no-consumption>
            </div>
          </div>
          <ng-template #meterReading>
            <app-encode-index-cta
              *ngIf="showEncodingCTA"
              class="inline-block w-full mb-12"
              [version]="encodeIndexCtaVersion.inline"
              (showBoxEmitter)="getIndexEncodingStatus($event)"
            >
            </app-encode-index-cta>
            <div class="text-center card-primary md:text-left" *ngIf="selectedContractCast">
              <img
                src="./assets/img/{{ selectedContractCast.type | lowercase }}.svg"
                class="inline-block w-24 h-24 mb-8 md:mb-0"
              />
              <div
                class="w-full align-middle inline-block text-center md:text-left md:ml-10 md:w-auto"
                *ngIf="activeSite"
              >
                <strong>{{ 'general.eanCode' | translate }}</strong> {{ selectedContractCast.deliveryPointReference }}
                <br />
                <strong>{{ 'general.meterNumber' | translate }}{{ 'general.dotSeparator' | translate }}</strong>
                {{ selectedContractCast.meterNumber }} <br />
                <ng-container *ngIf="meterType">
                  <strong>{{ 'general.meterType.title' | translate }}{{ 'general.dotSeparator' | translate }}</strong>
                  {{ meterType }}<br />
                </ng-container>
                <strong>{{ 'general.address.label' | translate }}</strong> {{ activeSite.formattedAddress }} <br />
              </div>
            </div>
            <app-meter-table [contract]="selectedContract" [reference]="activeReference" [siteId]="activeSiteId">
            </app-meter-table>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
