import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AdvancesAssessment } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { AdvancesAssessmentService } from '@app/modules/customer-zone/consumption/services/advancesAssessment/advances-assessment.service';
import { IndexModalService } from '@app/modules/customer-zone/consumption/services/index-modal/index-modal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-already-ok-message',
  templateUrl: './already-ok-message.component.html',
  styleUrls: ['./already-ok-message.component.scss'],
})
export class AlreadyOkMessageComponent implements OnInit {
  @Output() formReady = new EventEmitter();
  public lastAdvanceAssessment: AdvancesAssessment = undefined;

  constructor(
    private advancesAssessmentService: AdvancesAssessmentService,
    private indexModalService: IndexModalService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.lastAdvanceAssessment = this.advancesAssessmentService.getLastAdvanceAssessment();

    this.indexModalService.setModalTitle(
      this.translate.instant('components.consumption.index.monthlyInstallment.already_ok.title')
    );
  }

  continue() {
    this.formReady.emit();
  }
}
