<div data-cy="my-ev__reports" class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
  <div class="grid grid-cols-5 md:grid-cols-12 items-center my-12">
    <h2 class="col-span-4 font-display font-normal text-5xl te-text-gradient-home inline-block">
      {{ 'pages.tevcMonitoring.monthlyReport.pageTitle' | translate }}
    </h2>
    <div class="col-span-5 md:col-span-8 md:ml-auto">
      <app-montly-reports-filter
        *ngIf="objectKeys(chargingStationOptions).length > 1"
        [options]="chargingStationOptions"
        [default]="(chargingStation$ | async)?.name"
        (filter)="applyChargingStationFilter($event)"
        [attr.data-cy]="'monthlyReport__chargingStation--filter'"
      ></app-montly-reports-filter>

      <!--  year filter only visible on screens bigger than lg  -->
      <app-montly-reports-filter
        class="hidden lg:inline-block"
        [options]="granularityOptions"
        [default]="'year'"
        (filter)="applyGranularityFilter($event)"
        [attr.data-cy]="'monthlyReport__year--filter'"
      ></app-montly-reports-filter>
    </div>
  </div>

  <div class="card-primary">
    <div class="flex justify-between mb-16">
      <!-- switch between kWh and euro  -->
      <app-switch (optionChanged)="changeSwitchOption($event)"></app-switch>

      <!--  year filter only visible on screens smaller than lg  -->
      <app-montly-reports-filter
        class="block lg:hidden"
        [options]="granularityOptions"
        [default]="'year'"
        (filter)="applyGranularityFilter($event)"
      ></app-montly-reports-filter>
    </div>

    <!--  monthly report chart  -->
    <app-monthly-report
      [chargingStationSessionReports$]="chargingStationSessionReports$"
      [activeGranularityFilter]="activeGranularityFilter"
      [activeSwitchOption]="activeSwitchOption"
    ></app-monthly-report>

    <!--  navigation arrows  -->
    <div *ngIf="activeGranularityFilter === granularity.year" class="inline-block w-full text-center my-12 md:mx-8">
      <div class="inline-block align-middle">
        <div class="navigation-arrow navigation-arrow--left cursor-pointer" (click)="navigateBetweenYears(-1)">
          <img src="./assets/img/tevc-monitoring/arrow-orange.svg" [alt]="'general.back' | translate" class="w-1/5" />
        </div>
      </div>

      <div class="inline-block font-bold text-base align-middle border border-grey rounded-2xl py-4 px-12 mx-4">
        {{ activeYear }}
      </div>

      <div class="inline-block align-middle">
        <div
          class="inline-block navigation-arrow"
          [class.pointer-events-none]="activeYear >= currentYear"
          [class.pointer-events-auto]="activeYear < currentYear"
          [class.cursor-pointer]="activeYear < currentYear"
          (click)="navigateBetweenYears(1)"
        >
          <img
            [src]="
              activeYear < currentYear
                ? './assets/img/tevc-monitoring/arrow-orange.svg'
                : './assets/img/tevc-monitoring/arrow-disabled.svg'
            "
            [alt]="'general.next' | translate"
            class="w-1/5"
          />
        </div>
      </div>
    </div>

    <!--  monthly report table  -->
    <app-reports-table [chargingStationSessionReports$]="chargingStationSessionReports$"> </app-reports-table>
  </div>
</div>
