import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Advice, AdviceStatus } from '@app/modules/customer-zone/consumption/services/advice/advice.service';
import { ViewType } from '@app/modules/customer-zone/consumption/components/advice-investment/advice-investment.component';

@Component({
  selector: 'app-advice-action',
  templateUrl: './advice-action.component.html',
  styleUrls: ['./advice-action.component.scss'],
})
export class AdviceActionComponent {
  @Output() emitAction: EventEmitter<{ status: AdviceStatus; id: string }> = new EventEmitter<{
    status: AdviceStatus;
    id: string;
  }>();
  @Input() advice: Advice;
  @Input() viewType: ViewType = ViewType.list;
  protected readonly adviceStatus = AdviceStatus;
  protected readonly viewTypes = ViewType;

  updateStatus(status: AdviceStatus) {
    this.emitAction.emit({ status, id: this.advice.id });
  }
}
