import { Injectable } from '@angular/core';
import { AppConfig } from '@app/shared/utils/app-config';
import { UserInfo } from '@app/modules/customer-zone/user/models/userInfo.interface';
import { UtilsService } from '@app/shared/utils/utils.service';

@Injectable({
  providedIn: 'root',
})
export class SalesforceService {
  public chatData = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    FirstName: '',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    LastName: '',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Email: '',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    Account_Reference_Linked__c: '',
  };
  public salesforceChatData = `${this.conf?.config?.name}SalesforceChatData`;

  constructor(private utilsService: UtilsService, private conf: AppConfig) {}

  public setSalesforceChatData(userInfo: UserInfo, activeRef: string = '') {
    const chatData = this.setSalesforceChatObject(userInfo, activeRef);
    this.setCookie(chatData);
    this.prepopulateChatFields(chatData);
  }

  // Wait for chat to be instantiate before prepopulate fields
  // Check every 100ms with max waiting time of 1000ms
  public prepopulateChatFields(chatData) {
    let timePassed = 0;
    const timeStep = 100;
    const maxTimePassed = 1000;
    const instantiateSaleforceChat = () => {
      if (window['embedded_svc']) {
        window['embedded_svc'].settings.prepopulatedPrechatFields = chatData;
        clearInterval(chatInstantiation);
      }
      if (timePassed > maxTimePassed) {
        clearInterval(chatInstantiation);
      }
      timePassed += timeStep;
    };
    const chatInstantiation = setInterval(instantiateSaleforceChat, timeStep);
  }

  public updateSalesforceChatReference(activeRef: string) {
    this.chatData['Account_Reference_Linked__c'] = activeRef;
    this.setCookie(this.chatData);
  }

  public deleteCookie() {
    this.utilsService.deleteCookie(this.salesforceChatData);
  }

  public setSalesforceChatObject(userInfo: UserInfo = null, activeRef: string = '') {
    const data = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      FirstName: userInfo?.firstName || this.chatData?.FirstName,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      LastName: userInfo?.lastName || this.chatData?.LastName,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Email: userInfo?.email || this.chatData?.Email,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Account_Reference_Linked__c: activeRef || this.chatData?.Account_Reference_Linked__c,
    };
    this.chatData = data;
    return data;
  }

  private setCookie(chatData) {
    const cookieName: string = this.salesforceChatData;
    let cookie: any = this.utilsService.getCookieValue(cookieName);
    cookie = cookie || {};
    cookie.data = chatData;
    this.utilsService.setCookieValue(cookieName, cookie, null, this.conf?.config?.cookieRootDomain);
  }
}
