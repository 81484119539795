import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { Contract } from '../../../contracts/models/contract.interface';
import { of, Subject, switchMap, takeUntil } from 'rxjs';
import { EnergyType, Index, IndexEncodingOrigin } from '../../models/consumption.interface';
import { Site } from '../../../user/models/site.interface';
import {
  MyEnergyActions,
  MyEnergyActionsPerEAN,
} from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IndexModalService, StepTypes } from '../../services/index-modal/index-modal.service';
import { MainFacade } from '@app/core/facade/main.facade';
import { ContractDtoCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Component({
  selector: 'app-index-form',
  templateUrl: './index-form.component.html',
  styleUrls: ['./index-form.component.scss'],
})
export class IndexFormComponent implements OnInit {
  @Input() origin?: IndexEncodingOrigin = IndexEncodingOrigin.default;
  @Input() editIndex?: { metering: Index; contract: Contract };
  public reference: string;
  public activeSiteId: string;
  public indexForm: UntypedFormGroup;
  public step: StepTypes;
  public activeEnergy: string;
  public energies: EnergyType[];
  public contracts: ContractDtoCuzoApi[];
  public sites$: Observable<Site[]>;
  public stepTypes = StepTypes;
  public canChangeInstallmentIntro = false;
  public energyActions: MyEnergyActionsPerEAN;
  private notifier: Subject<void> = new Subject();

  constructor(
    private facade: MainFacade,
    private fb: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private indexModalService: IndexModalService
  ) {}

  ngOnInit(): void {
    this.stepListener();
    this.initialiseData();
    this.buildForm();
  }

  showIntro() {
    this.indexModalService.setStep(StepTypes.intro);
  }

  loadNextStep() {
    if (this.editIndex) {
      this.initIndexEditing();
    } else {
      this.showIntro();
    }
  }

  energySelectionComplete() {
    const energy = this.indexForm.get('energySelection').get('selection').value;
    switch (energy) {
      case EnergyType.ELECTRICITY:
        this.energies = [EnergyType.ELECTRICITY];
        break;

      case EnergyType.GAS:
        this.energies = [EnergyType.GAS];
        break;

      case EnergyType.DUO:
        this.energies = [EnergyType.ELECTRICITY, EnergyType.GAS];
        break;
    }

    this.activeEnergy = this.energies[0];
    this.indexModalService.setStep(StepTypes.energy);
  }

  energyMeterIndexComplete(energy: EnergyType) {
    const index = this.energies.indexOf(energy);
    if (this.energies[index + 1]) {
      this.activeEnergy = this.energies[index + 1];
      return;
    }

    this.activeModal.close();
  }

  public initIndexEditing() {
    this.energies = [this.editIndex.contract.type] as EnergyType[];
    this.activeEnergy = this.energies[0];
    this.indexModalService.setStep(StepTypes.energy);
  }

  public initSteps() {
    // We have more then 1 energyType. Perform Energy selection
    if (this.getAvailableEnergies().length > 1) {
      this.indexModalService.setStep(StepTypes.energySelection);
      return;
    }

    this.energies = this.getAvailableEnergies() as unknown as EnergyType[];
    this.activeEnergy = this.energies[0];
    this.indexModalService.setStep(StepTypes.energy);
  }

  private initialiseData() {
    this.indexModalService.setStep(StepTypes.preloading);
    this.facade.contracts$
      .pipe(
        takeUntil(this.notifier),
        switchMap((contracts: ContractDtoCuzoApi[]) => {
          this.contracts = contracts;
          this.reference = this.facade.state$.value.reference;
          this.activeSiteId = this.facade.state$.value.activeSite.id;
          this.sites$ = this.facade.sites$;
          const deliveryPoints = contracts.map((contract: ContractDtoCuzoApi) => contract.deliveryPointReference);
          return this.origin === IndexEncodingOrigin.energyInsight
            ? of({ accessToMyEnergyRecommendation: false })
            : this.facade.loadMyEnergyActions(this.reference, this.facade.state$.value.activeSite, deliveryPoints);
        })
      )
      .subscribe({
        next: (energyAction: MyEnergyActionsPerEAN) => {
          this.loadNextStep();
          this.energyActions = energyAction;
          this.canChangeInstallmentIntro = !Object.values(energyAction).some(
            (action: MyEnergyActions) => !action.accessToMyEnergyRecommendation
          );
        },
        error: () => {
          this.loadNextStep();
          this.energyActions = null;
          this.canChangeInstallmentIntro = false;
        },
      });
  }

  private buildForm() {
    this.indexForm = this.fb.group({
      energySelection: this.fb.group({
        selection: ['', Validators.required],
      }),
      energies: this.fb.group({
        electricity: this.fb.group({
          meterSelection: ['', Validators.required],
          meterIndex: this.fb.group({
            date: [new Date(), Validators.required],
            indexes: new UntypedFormArray([]),
          }),
          monthlyInstallment: [],
        }),
        gas: this.fb.group({
          meterSelection: ['', Validators.required],
          meterIndex: this.fb.group({
            date: [new Date(), Validators.required],
            indexes: new UntypedFormArray([]),
          }),
          monthlyInstallment: [],
        }),
      }),
    });
  }

  private getAvailableEnergies() {
    return [
      ...new Set(this.contracts.filter((x) => (Object as any).values(EnergyType).includes(x.type)).map((x) => x.type)),
    ];
  }

  private stepListener() {
    this.indexModalService.getStep().subscribe((step: StepTypes) => {
      this.step = step;
      if (step === StepTypes.energySelection) {
        this.resetData();
      }
    });
  }

  private resetData() {
    this.energies = [];
    this.activeEnergy = null;
    this.indexForm = null;
    this.buildForm();
  }
}
