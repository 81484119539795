import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AnalyticsModule } from '../../../../../../shared/modules/analytics/analytics.module';
import { TooltipComponent } from '../../../../../../shared/components/tooltip/tooltip.component';
import { HttpResponse } from '@angular/common/http';
import {
  AccessRight,
  AccessRights,
} from '../../../../../../shared/resolvers/user-type-resolver/models/user-type.interface';
import { AccessControlDirective } from '../../../../../../shared/directives/access-control.directive';
import {
  BillingDetailsCuzoApi,
  BillingDetailsPaymentMethodEnumCuzoApi,
  InvoiceModelV2CuzoApi,
} from '../../../../../../shared/models/cuzo-be-contract';
import { BillingFacade } from '../../../../../../core/facade/billing.facade';
import { take } from 'rxjs';
import { MainFacade } from '../../../../../../core/facade/main.facade';
import { InvoicePayOnlineComponent } from '@app/modules/customer-zone/invoices/components/micro/invoice-pay-online/invoice-pay-online.component';
import { InvoiceActions } from '@app/shared/models/cuzo-be-contract-extend';

@Component({
  selector: 'app-invoice-actions',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    AnalyticsModule,
    TooltipComponent,
    AccessControlDirective,
    NgOptimizedImage,
    InvoicePayOnlineComponent,
  ],
  templateUrl: './invoice-actions.component.html',
  styleUrls: ['./invoice-actions.component.scss'],
})
export class InvoiceActionsComponent implements OnInit {
  @Input() billingDetails: BillingDetailsCuzoApi;
  @Input() accessRights: AccessRights;
  @Input() invoice: InvoiceModelV2CuzoApi;
  readonly accessRight = AccessRight;
  actions: InvoiceActions;
  numberOfActions: number = 0;

  constructor(private facade: MainFacade, private billingFacade: BillingFacade) {}

  ngOnInit() {
    this.actions = this.setActions(this.invoice, this.billingDetails, this.accessRights);
    this.numberOfActions = Object.values(this.actions).filter((value) => value).length;
  }

  downloadPDF(invoiceId: string): void {
    this.billingFacade
      .getInvoiceDocument(invoiceId)
      .pipe(take(1))
      .subscribe((response: HttpResponse<Blob>) =>
        this.facade.utils.downLoadFile(response.body, 'application/pdf', `invoice-${invoiceId}.pdf`)
      );
  }

  getInvoiceDetails(idInvoice: string): void {
    idInvoice = idInvoice.replace('/', '');
    this.billingFacade
      .getInvoiceDetails(idInvoice)
      .pipe(take(1))
      .subscribe((response: HttpResponse<string>) => this.facade.utils.openFile(response.body, 'text/html'));
  }

  private setActions(
    invoice: InvoiceModelV2CuzoApi,
    billingDetails: BillingDetailsCuzoApi,
    accessRights: AccessRights
  ): InvoiceActions {
    let actions: InvoiceActions = { downloadInvoice: false, downloadRegule: false, payOnline: false };
    if (invoice?.pdfAvailable) {
      actions.downloadInvoice = true;
      if (invoice?.hasDetailedDocument && accessRights?.viewRegulDetails) {
        actions.downloadRegule = true;
      }
    }
    if (invoice?.paymentLink && billingDetails.paymentMethod === BillingDetailsPaymentMethodEnumCuzoApi.BANK_TRANSFER) {
      actions.payOnline = true;
    }
    return actions;
  }
}
