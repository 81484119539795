<div class="select-site">
  <div class="py-6">
    <h3 class="my-12 text-center" [innerHTML]="'general.upsell.boiler_one_shot.select_site.title' | translate"></h3>
  </div>

  <div>
    <div class="form-element input-radio-big mb-4" *ngFor="let site of sites$ | async">
      <input
        id="{{ site.id }}"
        value="{{ site.id }}"
        type="radio"
        name="site"
        [formControl]="this.control.get('site')"
      />
      <label for="{{ site.id }}"> {{ site.formattedAddress }} </label>
    </div>
    <div class="md:flex next fr my-12">
      <button
        *ngIf="control.get('site').value !== ''"
        class="w-full btn btn-primary ml-auto md:w-auto"
        (click)="continue()"
      >
        {{ 'general.next' | translate }}
      </button>
      <div class="text-center mt-8 md:flex-1 md:order-first md:mt-0 md:text-left">
        <strong class="navigate-back text-primary cursor-pointer">
          <a (click)="goBack()">{{ 'general.back' | translate }}</a>
        </strong>
      </div>
    </div>
  </div>
</div>
