import { trigger, transition, style, animate } from '@angular/animations';

export const FadeUpDown = trigger('FadeUpDown', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateY(-100px)' }), //apply default styles before animation starts
    animate('300ms ease-in-out', style({ opacity: 1, transform: 'translateY(0)' })),
  ]),
  transition(':leave', [
    style({ opacity: 1, transform: 'translateY(0)' }), //apply default styles before animation starts
    animate('300ms ease-in-out', style({ opacity: 0, transform: 'translateY(-100px)' })),
  ]),
]);
