import { Component, Input, OnChanges } from '@angular/core';
import {
  ActivateMandateResponse,
  Mandate,
  MandateDataType,
  MandateStatus,
} from '@app/modules/customer-zone/consumption/models/mandates.interface';
import { MainFacade } from '@app/core/facade/main.facade';
import { EnergyType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { EliqAccessRights } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-fluvius-mandates',
  templateUrl: './fluvius-mandates.component.html',
  styleUrls: ['./fluvius-mandates.component.scss'],
})
export class FluviusMandatesComponent implements OnChanges {
  @Input() eliqAccessRights: EliqAccessRights;
  @Input() mandates: Mandate[];
  @Input() reference: string;
  mandatesByEnergy: Array<Mandate[]>;
  readonly mandateStatus = MandateStatus;
  readonly energyType = EnergyType;
  readonly granularity = MandateDataType;
  fluviusLink$: Observable<ActivateMandateResponse>;

  constructor(public facade: MainFacade) {}

  ngOnChanges() {
    if (this.eliqAccessRights?.profileByDeliveryPointReference) {
      const eans: string[] = Object.keys(this.eliqAccessRights?.profileByDeliveryPointReference);
      if (!eans.length || !this.mandates) {
        return;
      }
      if (this?.mandates?.length) {
        this.mandates = this.mandates.filter((mandate: Mandate) => eans.includes(mandate.ean));
        const missingEAN: string[] = eans.filter(
          (ean: string) => !this.mandates.some((m: Mandate): boolean => ean === m.ean)
        );
        const missingMandate: Mandate[] = this.mapMandateArray(missingEAN);
        this.mandates = [...this.mandates, ...missingMandate];
      } else {
        this.mandates = this.mapMandateArray(eans);
      }

      this.mandatesByEnergy = this.splitMandateByEnergy(this.mandates);
      this.fluviusLink$ = this.facade.getShortLinkMandateActivation();
    }
  }

  private mapMandateArray(eans: string[]): Mandate[] {
    return eans.map((ean: string) => ({
      ean,
      startOn: null,
      endOn: null,
      energyType: this.eliqAccessRights.profileByDeliveryPointReference[ean].energyType,
      reference: this.facade.state$.value.reference,
      status: MandateStatus.finished,
      dataServiceType: null,
      active: false,
    }));
  }

  private splitMandateByEnergy(mandates: Mandate[]): Array<Mandate[]> {
    if (!this.mandates.length) {
      return [];
    }
    const elec = mandates.filter((mandate) => mandate.energyType === EnergyType.ELECTRICITY);
    const gas = mandates.filter((mandate) => mandate.energyType === EnergyType.GAS);
    return [elec, gas];
  }
}
