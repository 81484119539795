/* eslint-disable */
import { Direction } from './deliveryPoint.interface';
import { MandateDataType } from './mandates.interface';

export enum RegisterType {
  TOTAL_HOUR = 'TOTAL_HOUR',
  MONO = 'MONO',
  LOW = 'LOW',
  HIGH = 'HIGH',
  NIGHT_EXCLUSIVE = 'NIGHT_EXCLUSIVE',
  PRODUCTION_TOTAL_HOUR = 'PRODUCTION_TOTAL_HOUR',
  PRODUCTION_HIGH = 'PRODUCTION_HIGH',
  PRODUCTION_LOW = 'PRODUCTION_LOW',
}

export enum RegisterTypeExtra {
  NOT_USED = 'NOT_USED',
}

export enum RegisterTypeColor {
  TOTAL_HOUR = '#92EAEA',
  MONO = '#80CDFF',
  LOW = '#80CDFF',
  HIGH = '#92EAEA',
  NIGHT_EXCLUSIVE = '#80CDFF',
  PRODUCTION_TOTAL_HOUR = '#FFE74F',
  PRODUCTION_HIGH = '#FFE74F',
  PRODUCTION_LOW = '#FFE74F',
  GAS = '#285AFF',
  POWER_PEAK = '#DB3900',
}

export enum EnergyType {
  ELECTRICITY = 'ELECTRICITY',
  GAS = 'GAS',
  DUO = 'DUO',
  DUAL = 'DUAL',
}

export enum PaymentFrequency {
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  BIMONTHLY = 'BIMONTHLY',
}

export interface MeterIndex {
  name?: string;
  direction?: Direction;
  measurementNature?: string | MeasurementNature;
  register: RegisterType | RegisterTypeExtra;
  value: number;
  meterNumber?: string;
  label?: string;
  tooltip?: string;
  quality?: IndexQuality | string;
}

export enum MeasurementNature {
  GCF = 'GCF',
  VOLUME = 'VOLUME',
  ACTIVE_ENERGY = 'ACTIVE_ENERGY',
  ACTIVE_POWER = 'ACTIVE_POWER',
}

export enum IndexQuality {
  VALIDATED = 'VALIDATED',
  VBTP = 'VALID_PROVIDED_BY_THIRD_PARTY',
}

export enum TimeTypeRegister {
  HC = 'HC',
  HP = 'HP',
  MONO = 'MONO',
}

export enum IndexEncodingOrigin {
  energyInsight = 'ENERGY_INSIGHT',
  default = 'DEFAULT',
}

export interface Index {
  status?: string;
  type?: EnergyType;
  indexDate?: Date | string;
  context?: IndexContext;
  indexes: MeterIndex[] | any;
  advanceStatus?: 0 | 1 | 2;
}

export interface IndexRange {
  from: Index;
  to: Index;
  period: number | null;
  consumption: MeterIndex[];
}

export interface MeterVolume {
  register: RegisterType;
  value: number;
  unit: string;
  quality: VolumeQuality;
  direction: Direction;
  measurementNature: MeasurementNature;
}

export interface Volume {
  previousMeteringDate: string;
  currentMeteringDate: string;
  volumes: MeterVolume[];
}

export interface PeakVolume {
  previousMeteringDate: string;
  currentMeteringDate: string;
  value: number;
  unit: string;
  volumes?: any[];
}

export interface SelfConsumption {
  totalProduction: number;
  selfConsumption: number;
  selfConsumptionPercent: number;
}

export interface UsageBreakdown {
  category: string;
  value: number;
  percentage: number;
}

export interface Usage {
  accuracy: string;
  totalValue: number;
  from: string;
  to: string;
  unit: string;
  breakdown: UsageBreakdown[];
}

export interface VolumesPerReference {
  [reference: string]: VolumesPerDeliveryPoint;
}

export interface VolumesPerDeliveryPoint {
  [deliveryPoint: string]: Volume[];
}

export type Register = {
  [key in RegisterType]: number[];
};

export type CrossSell = {
  [key in CrossSellType]: boolean;
};

export interface ConsumptionRequestData {
  reference: string;
  siteId: string;
  energyType: EnergyType;
  deliveryPoint: string;
}

export interface VolumeRequestData extends ConsumptionRequestData {
  fromDate: Date;
  toDate: Date;
  provider?: Provider;
  granularity?: Granularity | string;
  type?: Direction | string;
  resolution?: VolumeResolution;
  estimate?: boolean;
}

export interface UsageRequestData extends ConsumptionRequestData {
  fromDateString: string;
  toDateString: string;
}
export interface SelfConsumptionRequestData extends ConsumptionRequestData {
  fromDate: Date;
  toDate: Date;
}

export enum UseCaseMyEnergyType {
  NO_RESPONSE = 'NO_RESPONSE',
  MODIFICATION_ONGOING = 'MODIFICATION_ONGOING',
  ALREADY_OK = 'ALREADY_OK',
  ALREADY_DECREASED = 'ALREADY_DECREASED',
  DECREASE_TOO_IMPORTANT = 'DECREASE_TOO_IMPORTANT',
  DECREASE_POSSIBLE = 'DECREASE_POSSIBLE',
  INCREASE_RECOMMENDED_AND_DECREASE_NOT_POSSIBLE = 'INCREASE_RECOMMENDED_AND_DECREASE_NOT_POSSIBLE',
  INCREASE_WITH_DECREASE_POSSIBLE = 'INCREASE_WITH_DECREASE_POSSIBLE',
}

export interface AdvancesAssessment {
  useCaseMyEnergy: UseCaseMyEnergyType;
  suggestedDeposit: number;
  yearlyEstimatedAmountBasedOnIndex: number;
  remainingInvoicesBeforeSettlementInvoice: number;
  currentMonthlyDeposit: number;
  meterReadingMonth: number;
  alreadyInvoicedInstallments: AlreadyInvoicedInstallments;
  yearlyEstimateBasedOnIndex: YearlyEstimateBasedOnIndex;
}

export interface AlreadyInvoicedInstallments {
  amount: number;
  startPeriod: string;
  endPeriod: string;
}

export interface YearlyEstimateBasedOnIndex {
  timeType: TimeType[];
}

export interface TimeType {
  type: TimeTypeRegister;
  value: number;
}

export enum IndexContext {
  PROVISION = 'PROVISION',
  AD_HOC = 'AD_HOC',
  MDCHANGE = 'MDCHANGE',
}

export enum Granularity {
  QUARTERHOUR = 'quarter-hour',
  HOUR = 'hour',
  DAY = 'day',
  MONTH = 'month',
  YEAR = 'year',
  YEARS = 'years',
  WEEK = 'week',
}

export enum VolumeResolution {
  Day = 'day',
  Month = 'month',
}

export enum Provider {
  AR = 'ar',
  FLUVIUS = 'fluvius',
}

export enum UNIT {
  KWH = 'kWh',
  M3 = 'm3',
}

export enum MandateEnergy {
  ELECTRICITY = 'E',
  GAS = 'G',
}

export enum ConsumptionViewType {
  smartMeter = 'smartMeter',
  noSmartMeter = 'noSmartMeter',
  gas = 'gas',
}

export enum VolumeQuality {
  Validated = 'VAL',
  Estimated = 'EST',
}

export enum ConsumptionApiError {
  FILL_YOUR_HOME_PROFILE = 'FILL_YOUR_HOME_PROFILE',
}

export enum CrossSellType {
  boilerMaintenance = 'boilerMaintenance',
  pvInstallation = 'pvInstallation',
  evChargingPoint = 'evChargingPoint',
  battery = 'battery',
  disclaimerSmartMeter = 'disclaimerSmartMeter',
}

export const DAY = 60 * 60 * 24 * 1000;
export const WEEK = 60 * 60 * 24 * 7 * 1000;
export const MONTH = 60 * 60 * 24 * 31 * 1000;
export const YEAR = 60 * 60 * 24 * 365 * 1000;

export const MINIMUM_LOADTIME = 1500;

export const DisclaimerMandateTypes = { ...MandateDataType, bothElec: 'bothElec', bothGas: 'bothGas' } as const;
