<div>
  <ng-container *ngIf="homeQuestions?.properties">
    <app-home-profile-item
      *ngFor="let question of homeQuestions?.properties"
      [item]="question"
      [questionType]="homeProfileQuestionType.single"
      [formUpdate]="formUpdate"
      (itemEmitter)="openQuestionPanel($event, homeProfileCategory.properties)"
    >
    </app-home-profile-item>
  </ng-container>

  <ng-container *ngIf="otherQuestions">
    <app-home-profile-item
      *ngFor="let question of otherQuestions | keyvalue"
      [item]="question"
      [questionType]="homeProfileQuestionType.multi"
      [formUpdate]="formUpdate"
      (itemEmitter)="openQuestionPanel($event, question.key)"
    >
    </app-home-profile-item>
  </ng-container>
</div>

<app-home-profile-panel
  [question]="question"
  (updateEmitter)="updateHomeProfile($event)"
  (closeEmitter)="closeQuestionPanel()"
>
</app-home-profile-panel>
