import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { EnergyType } from '../../../../models/consumption.interface';
import { AnalyticsService } from '../../../../../../../shared/modules/analytics/services/analytics.service';
import { myEnergyEvents } from '../../../../../../../core/analytics/models/event-defaults';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'if-energy-selection',
  templateUrl: './energy-selection.component.html',
  styleUrls: ['./energy-selection.component.scss'],
})
export class EnergySelectionComponent implements OnInit {
  @Input() energySelection: UntypedFormGroup;
  @Output() formReady = new EventEmitter();

  readonly energyType = EnergyType;

  constructor(private analyticsService: AnalyticsService, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.analytics();
    this.energySelection.get('selection').valueChanges.subscribe((energy: string) => {
      this.formReady.emit();
    });
  }

  private analytics() {
    this.analyticsService.push({
      ...myEnergyEvents(`${this.translateService.currentLang}/myenergy-flow/energy-type`),
    });
  }
}
