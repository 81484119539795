<div class="card-primary mb-8">
  <h3 class="mb-6 text-2xl font-medium text-grey-dark">{{ 'components.paymentMethod.title' | translate }}</h3>
  <div class="inline-block mb-8 w-full" *ngIf="billingDetails?.paymentMethodLocked">
    <app-alert [alert]="alert"></app-alert>
  </div>
  <app-direct-debit
    *ngIf="
      billingDetails?.paymentMethod === directDebit || billingDetails?.paymentMethod === directDebitAndBankTransfer
    "
    [billingDetails]="billingDetails"
    [contactDetails]="contactDetails"
    [reference]="reference"
  >
    >
  </app-direct-debit>
  <app-bank-transfer
    *ngIf="billingDetails?.paymentMethod === bankTransfer"
    [billingDetails]="billingDetails"
    [contactDetails]="contactDetails"
    [reference]="reference"
  >
  </app-bank-transfer>
</div>
