import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AccessControlDirective } from './access-control.directive';
import { AccessRight, AccessRightOperator } from '../resolvers/user-type-resolver/models/user-type.interface';
import { MainFacade } from '@app/core/facade/main.facade';

interface MultipleAccessRight {
  rights: AccessRight[];
  operator?: AccessRightOperator; // operator is optional, default operator will be AND
}
@Directive({
  selector: '[multipleAccessControl]',
})
export class MultipleAccessControlDirective extends AccessControlDirective implements OnInit {
  private accessRightCondition: MultipleAccessRight;

  constructor(
    protected templateRef: TemplateRef<any>,
    protected viewContainer: ViewContainerRef,
    protected facade: MainFacade
  ) {
    super(templateRef, viewContainer, facade);
  }

  @Input() set multipleAccessControl(accessRightCondition: MultipleAccessRight) {
    this.accessRightCondition = accessRightCondition;
    if (accessRightCondition) {
      // set default conjunction operator (AND) if is it missing
      accessRightCondition.operator = accessRightCondition.operator || AccessRightOperator.conjunction;

      // update view in parent class
      this.updateView(this.checkAccessCondition(accessRightCondition));
    }
  }

  ngOnInit() {
    // there is no needed to unsubscribe the subscription, it will be done in parent class
    this.userTypeSubscription = this.facade.state$.subscribe(({ accessRights }) => {
      // current access backup
      const previousAccess = this.checkAccessCondition(this.accessRightCondition);
      this.userType = accessRights;
      const currentAccess = this.checkAccessCondition(this.accessRightCondition);
      // call updateView method when the access was changed only
      if (previousAccess !== currentAccess) {
        // update view in parent class
        this.updateView(currentAccess);
      }
    });
  }

  private checkAccessCondition(accessRightCondition: MultipleAccessRight): boolean {
    if (!this.accessRightCondition) {
      return false;
    }
    // operator AND
    if (accessRightCondition.operator === AccessRightOperator.conjunction) {
      return accessRightCondition.rights.every((accessRight: AccessRight) => this.checkAccess(accessRight));
    }
    // operator OR
    if (accessRightCondition.operator === AccessRightOperator.disjunction) {
      return accessRightCondition.rights.some((accessRight: AccessRight) => this.checkAccess(accessRight));
    }
  }
}
