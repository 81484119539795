<div class="bg-grey-light box-shadow-small p-8 rounded-2xl">
  <span class="flex justify-between items-center font-bold pb-4">
    <span class="text-base text-grey-dark">
      {{ 'components.homeProfile.widget.homeProfile.title' | translate }}
    </span>
    <span class="text-xs text-grey-dark60">
      {{
        'components.homeProfile.widget.homeProfile.completed' | translate : { percentage: getPercentageProgressValue() }
      }}
    </span>
  </span>
  <app-progress-bar
    [progressValue]="progressValue"
    [progressBarGradientBackground]="true"
    progressHeightClass="h-2.5"
  ></app-progress-bar>
  <div class="text-center pt-4">
    <a class="text-red font-bold" *ngIf="showEditLink" routerLink="/consumptions/home-profile">
      {{ 'components.homeProfile.widget.homeProfile.editLink' | translate }}
    </a>
  </div>
</div>
