<app-modal title="{{ 'components.refundMethod.changeRefundAccount.title' | translate }}">
  <div class="my-12 leading-relaxed">
    {{ 'components.refundMethod.changeRefundAccount.intro' | translate }}
  </div>

  <form [formGroup]="control" novalidate class="form" (ngSubmit)="handleSubmit()">
    <div
      class="form-element input-text"
      [class.errors]="!control.get('refundAccount').pristine && control.get('refundAccount').invalid"
    >
      <div class="flex">
        <label for="input-iban"> {{ 'general.billing.IBAN' | translate }}* </label>
        <app-tooltip
          class="ml-auto"
          [tooltip]="'components.refundMethod.changeRefundAccount.tooltip' | translate"
          [position]="'left'"
        ></app-tooltip>
      </div>
      <input
        [formControl]="control.get('refundAccount')"
        id="input-iban"
        class="form__input"
        name="iban"
        autocomplete="off"
      />
      <div class="message">
        <div *ngIf="control.get('refundAccount').errors?.invalidIBAN">
          {{ 'errorMessages.invalidIBAN' | translate }}
        </div>
        <div *ngIf="control.get('refundAccount').errors?.required">{{ 'errorMessages.required' | translate }}</div>
      </div>
    </div>
    <div class="md:flex md:justify-center gap-8 mt-4 md:mt-8">
      <button
        type="submit"
        class="btn btn-primary w-full"
        [attr.disabled]="!control.get('refundAccount').valid ? '' : null"
        [class.not-submittable]="!control.get('refundAccount').valid || control.get('refundAccount').pristine"
        [analyticsEvent]="'paymentPreferences:click:update-refund-account'"
      >
        {{ 'general.update' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-secondary w-full mt-8 md:mt-0"
        (click)="activeModal.close()"
        [analyticsEvent]="'paymentPreferences:click:cancel-refund-account-update'"
      >
        {{ 'general.cancel' | translate }}
      </button>
    </div>
  </form>
</app-modal>
