<div class="calendar-input">
  <input
    [formControl]="dateControl"
    type="tel"
    [maxlength]="maxLength"
    placeholder="{{ fieldLabel }}"
    (keydown)="dateFieldKeydownEvt($event)"
  />
  <button (click)="togglePicker()"><img src="./assets/img/calendar.svg" /></button>
</div>

<date-picker-core
  *ngIf="isPickerDisplayed"
  @FadeUpDown
  [dateRange]="dateRange"
  [markDisabled]="markDisabled"
  [pickedDateValue]="pickedDateValue"
  (datePicked)="onDatePicked($event)"
></date-picker-core>

<div id="date-picker-overlay" (click)="closeDatePicker()" *ngIf="isPickerDisplayed"></div>
