import { Component, OnInit } from '@angular/core';

import { UtilsService } from '../../../../../shared/utils/utils.service';

@Component({
  selector: 'app-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.scss'],
})
export class QuickLinksComponent implements OnInit {
  constructor(public utilsService: UtilsService) {}

  ngOnInit() {}
}
