import { Component, DoCheck, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ReferenceDetails } from '@app/modules/customer-zone/user/models/reference.interface';
import { KeycloakConfig } from 'keycloak-js';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AppConfig } from '@app/shared/utils/app-config';
import {
  AccessRight,
  AccessRightOperator,
  EliqAccessRights,
} from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { MainFacade } from '@app/core/facade/main.facade';
import { catchError, filter, Observable, of, retry, switchMap, take, tap } from 'rxjs';
import { Site } from '@app/modules/customer-zone/user/models/site.interface';

@Component({
  selector: 'app-primary-menu',
  templateUrl: './primary-menu.component.html',
  styleUrls: ['./primary-menu.component.scss'],
})
export class PrimaryMenuComponent implements DoCheck {
  @Input() currentLanguage: string;
  @Input() mobileActiveSubmenu: string;
  @Input() isMobile: boolean;
  @Input() referencesDetails: ReferenceDetails[];

  @Output()
  submenuEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  mobileMenuEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  mobileSubmenuEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  mobileActiveSubmenuEmitter: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('invoices') invoicesItem: ElementRef;
  @ViewChild('consumptions') consumptionsItem: ElementRef;
  @ViewChild('myEv') myEvItem: ElementRef;
  @ViewChild('contracts') contractsItem: ElementRef;
  @ViewChild('preferences') preferencesItem: ElementRef;

  public accountPage: SafeUrl;
  public accessRight = AccessRight;
  public accessRightOperator = AccessRightOperator;
  public eliqAccessRights$: Observable<EliqAccessRights>;
  public eliqAccessSuccess: boolean = true;

  constructor(
    public facade: MainFacade,
    private router: Router,
    private sanitizer: DomSanitizer,
    private conf: AppConfig
  ) {
    this.eliqAccessRights$ = this.facade.eliqAccessRights$;
    this.facade.activeSite$.pipe(filter((site: Site) => !!site)).subscribe(() => {
      if (this.isMobile) {
        this.eliqAccessRights$ = this.getEliqAccessRights$();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.isMobile?.currentValue && this.facade.state$.value.reference && this.facade.state$.value.activeSite) {
      this.eliqAccessRights$ = this.getEliqAccessRights$();
    }
  }

  ngDoCheck() {
    this.emitShowSubmenu();
    const keycloakConfig: KeycloakConfig = this.conf.config.keycloak;
    const baseURL: string = this.facade.utils.getBaseUrl();
    // eslint-disable-next-line max-len
    const accountPageURL = `${keycloakConfig.url}/realms/${keycloakConfig.realm}/account?referrer=${keycloakConfig.clientId}&referrer_uri=${baseURL}&kc_locale=${this.currentLanguage}`;
    this.accountPage = this.sanitizer.bypassSecurityTrustUrl(accountPageURL);
  }

  getEliqAccessRights$(): Observable<EliqAccessRights> {
    this.eliqAccessSuccess = true;
    return this.facade
      .loadEliqAccessRights(this.facade.state$.value.reference, this.facade.state$.value.activeSite)
      .pipe(
        retry(2),
        tap(() => (this.eliqAccessSuccess = true)),
        catchError(() => {
          this.eliqAccessSuccess = false;
          return of(null);
        })
      );
  }

  public emitShowSubmenu() {
    const invoicesSubmenu = this.invoicesItem && this.invoicesItem.nativeElement.classList.contains('submenu-open');
    const consumptionsSubmenu =
      this.consumptionsItem && this.consumptionsItem.nativeElement.classList.contains('submenu-open');
    const contractsSubmenu = this.contractsItem && this.contractsItem.nativeElement.classList.contains('submenu-open');
    const preferencesSubmenu =
      this.preferencesItem && this.preferencesItem.nativeElement.classList.contains('submenu-open');
    const myEvSubmenu = this.myEvItem && this.myEvItem.nativeElement.classList.contains('submenu-open');
    const hasSubmenu = invoicesSubmenu || consumptionsSubmenu || contractsSubmenu || preferencesSubmenu || myEvSubmenu;
    this.submenuEmitter.emit(hasSubmenu);
  }

  public emitMobileShowSubmenu(itemTitle: string) {
    this.mobileActiveSubmenu = itemTitle;
    this.mobileActiveSubmenuEmitter.emit(itemTitle);
  }

  public emitMobileCloseSubmenu() {
    this.mobileSubmenuEmitter.emit(false);
  }

  public emitMobileCloseMenu() {
    this.mobileMenuEmitter.emit(false);
  }

  public isLinkActive(url) {
    if (this.isMobile) {
      return false;
    }
    const charPos = this.router.url.indexOf('?');
    const cleanUrl = charPos !== -1 ? this.router.url.slice(0, charPos) : this.router.url;
    return cleanUrl === url;
  }
}
