import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Contract } from 'src/app/modules/customer-zone/contracts/models/contract.interface';
import { AbstractControl } from '@angular/forms';
import { myEnergyEvents } from '../../../../../../../../../core/analytics/models/event-defaults';
import { AnalyticsService } from '../../../../../../../../../shared/modules/analytics/services/analytics.service';
import { UtilsService } from '../../../../../../../../../shared/utils/utils.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'if-meter-selection',
  templateUrl: './meter-selection.component.html',
  styleUrls: ['./meter-selection.component.scss'],
})
export class MeterSelectionComponent implements OnInit {
  @Input() contracts: Contract[];
  @Input() control: AbstractControl;

  @Output() formReady = new EventEmitter();

  constructor(private analyticsService: AnalyticsService, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.analytics();
    this.control.valueChanges.subscribe((value) => {
      this.formReady.emit(value);
    });
  }

  private analytics() {
    this.analyticsService.push({
      ...myEnergyEvents(`${this.translateService.currentLang}/myenergy-flow/select-ean`),
      myEnergy: {
        energyType: this.contracts[0].type,
      },
    });
  }
}
