import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MainFacade } from '@app/core/facade/main.facade';
import {
  EnergyType,
  Granularity,
  PeakVolume,
} from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { VolumesService } from '@app/modules/customer-zone/consumption/services/volumes/volumes.service';
import { Site } from '@app/modules/customer-zone/user/models/site.interface';
import * as moment from 'moment';
import { combineLatest, catchError, Observable, of, tap, map } from 'rxjs';
import { Router } from '@angular/router';
import { DeliveryPoint, EliqAccessRights } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { VersionSize } from '@app/shared/models/units.interface';
import { Paths } from '@app/core/models/paths';

@Component({
  selector: 'app-peak-consumption-widget',
  templateUrl: './peak-consumption-widget.component.html',
  styleUrls: ['./peak-consumption-widget.component.scss'],
})
export class PeakConsumptionWidgetComponent implements OnInit, OnChanges {
  @ViewChild('tooltip') tooltip: ElementRef;
  @Input() eliqAccessRights: EliqAccessRights;
  @Input() reference: string;
  @Input() site: Site;
  @Input() deliveryPoints: DeliveryPoint[];
  @Input() template?: VersionSize = VersionSize.big;
  show = true;
  maxPeakLastMonths: PeakVolume;
  peaksData$: Observable<{ peakLastMonths: PeakVolume[]; peakCurrentMonth: PeakVolume }>;
  error = false;
  isFirstDayOfTheMonth = moment().startOf('day').isSame(moment().startOf('month'));
  protected readonly versionSize = VersionSize;

  constructor(public facade: MainFacade, private volumesService: VolumesService, private router: Router) {}

  ngOnInit(): void {}

  ngOnChanges(data): void {
    if (data?.deliveryPoints?.currentValue?.length) {
      this.error = false;
      const deliveryPoint: DeliveryPoint = this.deliveryPoints.find(({ energy }) => energy === EnergyType.ELECTRICITY);
      const deliveryPointAccess = this.eliqAccessRights?.widgetAccessPerDeliveryPointReference?.find(
        ({ deliveryPointReference }) => deliveryPointReference === deliveryPoint?.reference
      );
      this.show = deliveryPointAccess?.access?.seeWidgetPeakPower;
      if (this.show) {
        this.peaksData$ = combineLatest([
          this.template === VersionSize.big ? this.getPeaksOfLastMonths(deliveryPoint) : of(null),
          this.getCurrentMonthPeaks(deliveryPoint),
        ]).pipe(
          map(([peakLastMonths, peakCurrentMonth]): { peakLastMonths: PeakVolume[]; peakCurrentMonth: PeakVolume } => {
            if ((this.template === VersionSize.big && peakLastMonths.length === 0) || !peakCurrentMonth) {
              this.error = true;
            }
            return { peakLastMonths, peakCurrentMonth };
          }),
          catchError((error) => {
            if (error.status === 428) {
              return of({ peakLastMonths: null, peakCurrentMonth: null });
            }
            this.error = true;
            return of(error);
          })
        );
      }
    }
  }

  goToDetails(e) {
    if (!this.tooltip?.nativeElement?.contains(e?.target)) {
      this.router.navigate([Paths.consumptions, Paths.powerPeak]);
    }
  }

  private getPeaksOfLastMonths(deliveryPoint): Observable<PeakVolume[]> {
    return this.volumesService
      .getPowerPeak$(
        {
          reference: this.reference,
          siteId: this.site?.id,
          deliveryPoint: deliveryPoint?.reference,
          from: this.isFirstDayOfTheMonth
            ? moment().startOf('month').subtract(6, 'months').toDate().toISOString()
            : moment().startOf('month').subtract(5, 'months').toDate().toISOString(),
        },
        Granularity.MONTH,
        this.isFirstDayOfTheMonth
          ? moment().startOf('month').toDate().toISOString()
          : moment().startOf('month').add(1, 'months').toDate().toISOString()
      )
      .pipe(
        tap((vol) => {
          if (vol?.length === 0) {
            this.error = true;
          }
          this.maxPeakLastMonths = vol.reduce((max: PeakVolume, curr: PeakVolume) =>
            max.value > curr.value ? max : curr
          );
          this.template = vol.length > 1 ? this.template : VersionSize.small;
        })
      );
  }

  private getCurrentMonthPeaks(deliveryPoint): Observable<PeakVolume> {
    return this.volumesService
      .getPowerPeak$(
        {
          reference: this.reference,
          siteId: this.site?.id,
          deliveryPoint: deliveryPoint.reference,
          from: this.isFirstDayOfTheMonth
            ? moment().startOf('month').subtract(1, 'months').toDate().toISOString()
            : moment().startOf('month').toDate().toISOString(),
        },
        Granularity.DAY,
        this.isFirstDayOfTheMonth
          ? moment().startOf('month').toDate().toISOString()
          : moment().startOf('month').add(1, 'months').toDate().toISOString()
      )
      .pipe(
        map((vol: PeakVolume[]): PeakVolume => {
          if (vol?.length === 0) {
            this.error = false;
          }
          return vol?.length
            ? vol.reduce((max: PeakVolume, curr: PeakVolume): PeakVolume => (max.value > curr.value ? max : curr))
            : null;
        })
      );
  }

  protected readonly event = event;
}
