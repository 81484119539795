import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipComponent } from '@app/shared/components/tooltip/tooltip.component';
import { TranslateModule } from '@ngx-translate/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-input-file-upload',
  standalone: true,
  imports: [CommonModule, TooltipComponent, TranslateModule],
  templateUrl: './input-file-upload.component.html',
  styleUrls: ['./input-file-upload.component.scss'],
})
export class InputFileUploadComponent implements OnInit {
  @Input() control: AbstractControl;
  @Input() accept?: string[];
  @Input() fileSize?: number;
  @Input() fileName?: string;
  @Output() fileEmitter: EventEmitter<File> = new EventEmitter<File>();
  acceptString: string;

  constructor() {}

  ngOnInit(): void {
    if (this.accept) {
      this.acceptString = this.accept.map((ext: string): string => `.${ext}`).join(',');
    }
  }

  onFileSelected(event: Event): void {
    const input: HTMLInputElement = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const selectedFile: File = input.files[0];
      this.fileName = selectedFile.name;
      this.fileEmitter.emit(selectedFile);
      this.control.setValue(selectedFile);
    }
  }
}
