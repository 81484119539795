<div class="form-element custom-select select--{{ options.style }}">
  <label *ngIf="field.label" for="{{ field.id }}" [class]="options.icon && 'icon icon--' + options.icon">
    {{ field.label }}
  </label>
  <div class="select" placement="bottom-right" ngbDropdown>
    <div id="ngb-{{ field.id }}" class="selected-option {{ isLocked && 'locked' }}" ngbDropdownToggle>
      <span *ngIf="field?.formControl?.value && field?.options">
        {{ field?.options[field?.formControl?.value] | translate }}
      </span>
      <span *ngIf="!field?.formControl?.value && field?.options" class="no-value">{{
        'components.customSelect.defaultLabel' | translate
      }}</span>
      <input
        id="{{ field.id }}"
        class="hidden"
        readonly
        name="boiler"
        type="text"
        autocomplete="off"
        [attr.formControlName]="field.id"
      />
    </div>
    <ul *ngIf="!isLocked" class="options" ngbDropdownMenu [attr.aria-labelledby]="'ngb-' + field.id">
      <li
        ngbDropdownItem
        *ngIf="field.formControl?.value && options.defaultValue"
        (click)="updateSelection(field.formControl, null)"
      >
        {{ 'components.customSelect.defaultLabel' | translate }}
      </li>
      <ng-container *ngFor="let option of field?.options | keyvalue: asIsOrder">
        <li
          *ngIf="!field.formControl?.value || (field.formControl?.value && field.formControl?.value !== option.value)"
          ngbDropdownItem
          (click)="updateSelection(field.formControl, option.key)"
        >
          {{ option.value | translate }}
        </li>
      </ng-container>
    </ul>
  </div>
</div>
