import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BoilerOneShotSteps } from '../../models/upsell.interface';
import { PriceSimulationComponent } from '../price-simulation/price-simulation.component';
import { SiteService } from '../../../user/services/site/site.service';
import { SimulationRequest } from '../../../contracts/models/price.simulation';
import { formatDate } from '@angular/common';
import { debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs';
import { UpSellService } from '../../upsell.service';

@Component({
  selector: 'app-confirmation-boiler-maintenance-os',
  templateUrl: './confirmation-boiler-maintenance-os.component.html',
  styleUrls: ['./confirmation-boiler-maintenance-os.component.scss'],
})
export class ConfirmationBoilerMaintenanceOsComponent implements OnInit {
  @Input() control: UntypedFormGroup;
  @Output() formReady: EventEmitter<string> = new EventEmitter<string>();
  @Output() goBackEmitter: EventEmitter<any> = new EventEmitter<any>();

  public reference: string;
  public siteId: string;
  public promoCode: string;
  public bundleCode: string;
  public taxEligibility: string;

  public name: string;
  public phone: string;
  public email: string;
  public site$: any;

  public simulation$;

  constructor(private fb: UntypedFormBuilder, private siteService: SiteService, private upsellService: UpSellService) {}

  ngOnInit(): void {
    this.reference = this.control.parent.value[BoilerOneShotSteps.SELECT_REFERENCE].reference;

    this.siteId = this.control.parent.value[BoilerOneShotSteps.SELECT_SITES].site;

    this.bundleCode = this.control.parent.value[BoilerOneShotSteps.BOILER_TYPE].bundleCode;

    this.promoCode = this.control.get('promoCode').value !== '' ? this.control.get('promoCode').value : '';

    this.taxEligibility = this.control.parent.value[BoilerOneShotSteps.HOUSE_AGE].taxEligibility;

    this.name = this.control.parent.value[BoilerOneShotSteps.CONTACT_INFO].name;

    this.phone = this.control.parent.value[BoilerOneShotSteps.CONTACT_INFO].phone;

    this.email = this.control.parent.value[BoilerOneShotSteps.CONTACT_INFO].email;

    this.control.addControl('cgv', this.fb.control('', [Validators.required]));
    this.control.addControl('newsletter', this.fb.control(''));

    this.simulation$ = this.control.get('promoCode').valueChanges.pipe(
      startWith(this.simulate()),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(() => {
        this.promoCode = this.control.get('promoCode').value;
        return this.simulate();
      })
    );

    this.control.get('promoCode').setValue(this.promoCode);
  }

  public simulate() {
    const request: SimulationRequest = {
      bundleCode: this.bundleCode,
      promoCode: this.promoCode,
      metadata: {
        taxEligibility: this.taxEligibility,
        vatConfirmation: true,
      },
      signatureDate: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
    };

    return this.upsellService.getPriceSimulation(this.reference, this.siteId, request, false);
  }

  public goBack() {
    this.goBackEmitter.emit();
  }

  submit() {
    this.formReady.emit();
  }
}
