<div class="select-reference">
  <div class="py-6">
    <h3 class="my-12 text-center" [innerHTML]="'general.upsell.boiler_one_shot.select_bundle.title' | translate"></h3>
  </div>

  <div *ngIf="loader">
    <app-loader></app-loader>
  </div>

  <ng-container *ngIf="!loader">
    <div>
      <app-alert *ngIf="(!choices || choices.length === 0) && !loader" [alert]="notEligibleAlert"> </app-alert>

      <div class="form-element input-radio-big mb-4" *ngFor="let service of choices">
        <input
          id="{{ service.code }}"
          value="{{ service.code }}"
          type="radio"
          name="bundleCode"
          [formControl]="control.get('bundleCode')"
        />
        <label for="{{ service.code }}"> {{ service.i18n[translate.currentLang] }} </label>
      </div>

      <div class="md:flex next fr my-12">
        <button
          *ngIf="control.get('bundleCode').value !== ''"
          class="w-full btn btn-primary ml-auto md:w-auto"
          (click)="continue()"
        >
          {{ 'general.next' | translate }}
        </button>
        <div class="text-center mt-8 md:flex-1 md:order-first md:mt-0 md:text-left">
          <strong class="navigate-back text-primary cursor-pointer">
            <a (click)="goBack()">{{ 'general.back' | translate }}</a>
          </strong>
        </div>
      </div>
    </div>
  </ng-container>
</div>
