<form (ngSubmit)="submitConsent()" novalidate [formGroup]="consentForm">
  <div *ngFor="let consent of consentsReworked">
    <!-- TITLE -->
    <div *ngIf="!consent.childOnly && !consent.parentCode" class="mb-8">
      <strong class="font-medium text-base text-grey-dark60" *ngIf="consent.labels">{{ consent.labels }}</strong>
      <div *ngIf="consent.statements">{{ consent.statements }}</div>
    </div>

    <!-- CHECKBOX -->
    <div *ngIf="consent.childOnly && consent.parentCode" class="consent flex mb-8">
      <ng-template [ngTemplateOutlet]="radioToggle" [ngTemplateOutletContext]="{ consent: consent, title: true }">
      </ng-template>
    </div>

    <!-- TITLE AND CHECKBOX -->
    <div *ngIf="consent.childOnly && !consent.parentCode" class="mb-8 pt-8 border-t border-grey">
      <div class="flex justify-between flex-wrap">
        <!--<strong *ngIf="consent.labels" class="inline-block w-full mb-4">{{ consent.labels }}</strong>-->
        <ng-template [ngTemplateOutlet]="radioToggle" [ngTemplateOutletContext]="{ consent: consent, title: true }">
        </ng-template>
      </div>
    </div>
  </div>

  <button
    type="submit"
    [attr.disabled]="!consentForm.valid || consentForm.pristine ? '' : null"
    [class.not-submittable]="!consentForm.valid || consentForm.pristine"
    class="btn btn-primary"
    [analyticsEvent]="'consent:click:update-consents'"
  >
    {{ 'general.update' | translate }}
  </button>

  <ng-template #radioToggle let-consent="consent" let-title="title">
    <div class="">
      <app-consent-checkbox [consent]="consent" [parentForm]="consentForm"> </app-consent-checkbox>
    </div>
    <div class="flex-1 ml-8">
      <strong *ngIf="consent.labels && title" class="font-medium text-base text-grey-dark">
        {{ consent.labels }}
      </strong>
      <div *ngIf="consent.statements">{{ consent.statements }}</div>
    </div>
  </ng-template>
</form>
