<app-modal #modal title="{{ 'components.handleReferences.rename.title' | translate }} ">
  <div>
    <div class="mt-8 mb-8">
      <p class="mb-8">{{ 'components.handleReferences.rename.description' | translate }}</p>
      <div class="form-element input-text" [class.errors]="labelError">
        <input value="{{ data.referenceDetails.label }}" type="text" (input)="updateLabel($event.target.value)" />
        <div class="message">
          <div *ngIf="labelError">{{ 'components.handleReferences.rename.form.required' | translate }}</div>
        </div>
      </div>
    </div>
    <div class="rename-reference__action mb-4 mt-8">
      <button
        (click)="submitData()"
        [attr.disabled]="labelError || label === data.referenceDetails.label ? '' : null"
        [class.not-submittable]="labelError || label === data.referenceDetails.label"
        class="btn btn-primary inline-block w-full font-bold mb-4 md:mb-0 md:w-72"
        [analyticsEvent]="'references:click:rename-reference-confirm'"
      >
        {{ 'general.update' | translate }}
      </button>
      <button
        (click)="close()"
        class="btn btn-secondary inline-block w-full font-bold md:ml-8 md:w-72"
        [analyticsEvent]="'references:click:rename-reference-cancel'"
      >
        {{ 'general.cancel' | translate }}
      </button>
    </div>
  </div>
</app-modal>
