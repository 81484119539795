import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { CrossSell } from '../../models/consumption.interface';

@Injectable({
  providedIn: 'root',
})
export class CrossSellService {
  private crossSell: any = {};

  constructor(private http: HttpClient) {}

  public getCrossSell(reference: string, siteId: string, loader: boolean = true): Observable<CrossSell> {
    if (this.crossSell[reference]) {
      return of(this.crossSell[reference]);
    }
    return this.getCrossSellFromApi(reference, siteId, loader);
  }

  public bustCaching(reference: string) {
    if (this.crossSell && this.crossSell[reference]) {
      this.crossSell[reference] = null;
    }
  }

  private getCrossSellFromApi(reference: string, siteId: string, loader: boolean): Observable<CrossSell> {
    const params = new HttpParams().set('loader', loader.toString());

    return this.http.get<any>(`/v1/customers/${reference}/sites/${siteId}/cross-sells`, { params }).pipe(
      tap((response) => {
        if (!this.crossSell[reference]) {
          this.crossSell[reference] = {};
        }
        this.crossSell[reference] = response;
      })
    );
  }
}
