<div *ngIf="paymentPlan$ | async as paymentPlan" class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
  <ng-container *ngIf="paymentPlan?.data; else invoicesError">
    <div class="mb-16">
      <h2 class="text-4xl md:text-5xl mt-12 mb-8 font-display font-normal te-text-gradient-home inline-block md:my-12">
        {{ 'pages.paymentPlan.title' | translate }}
      </h2>
      <div class="grid grid-cols-1 md:grid-cols-12 gap-16">
        <div
          class="md:col-span-{{ isFinished ? '12' : '8' }} card box-shadow-small"
          [ngClass]="{
            'te-border-gradient-home-secondary': isFinished,
            'te-border-gradient-home': !isFinished
          }"
        >
          <app-payment-plan-overview
            [paymentPlan]="paymentPlan?.data"
            [isFinished]="isFinished"
          >
          </app-payment-plan-overview>
        </div>
        <div
          *ngIf="!isFinished"
          class="md:col-span-4 card te-border-gradient-home box-shadow-small"
        >
          <app-payment-plan-progress [paymentPlan]="paymentPlan?.data"> </app-payment-plan-progress>
        </div>
      </div>
    </div>

    <div class="mb-16">
      <h2 class="text-4xl mb-8 font-display font-normal te-text-gradient-home inline-block md:my-12 md:text-5xl">
        {{ 'components.paymentPlan.breakdownTitle' | translate }}
      </h2>
      <app-payment-plan-breakdown
        *ngIf="paymentPlan?.data?.slices?.length"
        [paymentPlanSlices]="paymentPlan?.data?.slices"
      >
      </app-payment-plan-breakdown>
    </div>
  </ng-container>

  <app-faq
    class="pt-4 w-full max-w-5xl block mx-auto"
    [title]="'components.faq.title' | translate"
    [faqURL]="'components.faq.links.papur' | translate"
    [tag]="'my-totalenergies-papur'"
  >
  </app-faq>
</div>

<ng-template #invoicesError>
  <app-alert [alert]="invoiceAlerts?.invoiceEndpointError" class="inline-block w-full"></app-alert>
</ng-template>
