import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ControlContainer, FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MoveAddressComponent } from '@app/modules/customer-zone/move/components/move-form/parts/move-address/move-address.component';
import { DatePickerFieldModule } from '@app/shared/date-picker-field/date-picker-field.module';
import { UtilsService } from '@app/shared/utils/utils.service';
import { Region } from '@app/shared/models/region.interface';
import { RegisterType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { Direction } from '@app/modules/customer-zone/consumption/models/deliveryPoint.interface';
import { InputMeterStyle } from '@app/shared/components/input-meter-index/input-meter-index.component';
import moment from 'moment';
import { MoveFormFacade } from '@app/core/facade/move-form.facade';
import { MoveInRegistration } from '@app/modules/customer-zone/move/models/movein.interface';

@Component({
  selector: 'app-electricity-meter',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    SharedModule,
    TranslateModule,
    MoveAddressComponent,
    DatePickerFieldModule,
  ],
  templateUrl: './electricity-meter.component.html',
  styleUrls: ['./electricity-meter.component.scss'],
})
export class ElectricityMeterComponent implements OnInit {
  @Output() formValidityEmitter: EventEmitter<boolean> = new EventEmitter(false);
  @Input() registration: MoveInRegistration;
  @Input() moveInDate: Date;
  readonly Region = Region;
  readonly InputMeterStyle = InputMeterStyle;
  readonly MeterType = MeterType;
  readonly meterTypeOptions = MeterTypeOptions;
  region: Region;

  constructor(
    private moveFormFacade: MoveFormFacade,
    private controlContainer: ControlContainer,
    private formBuilder: FormBuilder
  ) {}

  get metersFormGroup(): FormGroup {
    return this.controlContainer.control as FormGroup;
  }

  ngOnInit(): void {
    this.region = UtilsService.getRegion(+this.registration?.sites?.[0]?.address?.zip);
    const electricityGroup = this.createElectricityFormGroup();
    this.metersFormGroup.setControl('electricity', electricityGroup);
    this.setFormValidity();
    this.subscribeToFormChanges();
    this.emitFormValidity();
  }

  private createElectricityFormGroup(): FormGroup {
    const state = this.moveFormFacade.state$.value;
    const electricityForm = state?.form?.newMeters?.meters?.electricity;

    return this.formBuilder.group({
      hasMeter: [electricityForm?.hasMeter || false],
      ean: [electricityForm?.ean || null, [Validators.required]],
      meterNumber: [electricityForm?.meterNumber || null],
      meterType: [electricityForm?.meterType || null, [Validators.required]],
      exclusiveNight: [electricityForm?.exclusiveNight || false],
      peakPower: [electricityForm?.peakPower || null, [Validators.min(2.5), Validators.max(30)]],
      hasSolarPanels: [electricityForm?.hasSolarPanels || false],
      solarPanelsKva: [electricityForm?.solarPanelsKva || null, [Validators.min(2.5), Validators.max(30)]],
      solarPanelsInstallationDate: [electricityForm?.solarPanelsInstallationDate || null],
      injectionTariff: [electricityForm?.injectionTariff || false],
      indexes: this.formBuilder.array(
        electricityForm?.indexes
          ? this.buildIndexesArray(
              electricityForm?.meterType,
              electricityForm?.exclusiveNight,
              electricityForm?.indexes
            )
          : []
      ),
    });
  }

  private subscribeToFormChanges(): void {
    const electricityFormGroup = this.metersFormGroup.get('electricity') as FormGroup;
    electricityFormGroup.valueChanges.subscribe((): void => this.setFormValidity());
    this.rebuildIndexesFieldsOnValuesChanges(electricityFormGroup);
  }

  private rebuildIndexesFieldsOnValuesChanges(electricityFormGroup: FormGroup): void {
    const indexesArray: FormArray<FormGroup> = electricityFormGroup.get('indexes') as FormArray;

    electricityFormGroup.get('meterType')?.valueChanges.subscribe((meterType) => {
      indexesArray.clear();
      this.buildIndexesArray(meterType, electricityFormGroup.get('exclusiveNight').value).forEach((formGroup) =>
        indexesArray.push(formGroup)
      );
    });

    electricityFormGroup.get('exclusiveNight')?.valueChanges.subscribe((exclusiveNight) => {
      if (!exclusiveNight) {
        indexesArray.removeAt(indexesArray.length - 1);
        return;
      }
      this.addExclusiveNightIndexes(indexesArray, electricityFormGroup.get('meterType').value, exclusiveNight);
    });
  }

  private setFormValidity(): void {
    const electricityFormGroup = this.metersFormGroup.get('electricity') as FormGroup;
    this.updateValidatorsForFlanders(electricityFormGroup);
    this.updateValidatorsBasedOnSolarPanels(electricityFormGroup);
    this.updateValidatorsForWallonia(electricityFormGroup);
  }

  private updateValidatorsForFlanders(electricityFormGroup: FormGroup): void {
    const peakPowerControl = electricityFormGroup.get('peakPower');
    if (this.region === Region.flanders && electricityFormGroup.get('meterType')?.value === MeterType.smart) {
      this.addValidator(peakPowerControl, Validators.required);
    } else {
      this.removeValidator(peakPowerControl, Validators.required);
    }
  }

  private updateValidatorsBasedOnSolarPanels(electricityFormGroup: FormGroup): void {
    const solarPanelsKvaControl = electricityFormGroup.get('solarPanelsKva');
    if (electricityFormGroup.get('hasSolarPanels').value) {
      this.addValidator(solarPanelsKvaControl, Validators.required);
    } else {
      this.removeValidator(solarPanelsKvaControl, Validators.required);
    }
  }

  private updateValidatorsForWallonia(electricityFormGroup: FormGroup): void {
    const solarPanelsInstallationDateControl = electricityFormGroup.get('solarPanelsInstallationDate');
    if (
      this.region === Region.wallonia &&
      electricityFormGroup.get('meterType')?.value === MeterType.smart &&
      electricityFormGroup.get('hasSolarPanels').value
    ) {
      this.addValidator(solarPanelsInstallationDateControl, Validators.required);
    } else {
      this.removeValidator(solarPanelsInstallationDateControl, Validators.required);
    }
  }

  private addValidator(control, validator): void {
    control.addValidators([validator]);
    control.updateValueAndValidity({ emitEvent: false });
  }

  private removeValidator(control, validator): void {
    control.removeValidators([validator]);
    control.updateValueAndValidity({ emitEvent: false });
  }

  private buildIndexesArray(meterType: MeterType, hasExclusiveNight: boolean, values = null): FormGroup[] {
    let indexes = [];

    if (meterType === MeterType.mono) {
      indexes.push(this.createIndexFormGroup(RegisterType.MONO, values));
    }

    if (meterType === MeterType.bi) {
      indexes.push(this.createIndexFormGroup(RegisterType.HIGH, values));
      indexes.push(this.createIndexFormGroup(RegisterType.LOW, values));
    }

    return this.addExclusiveNightIndexes(indexes, meterType, hasExclusiveNight, values) as FormGroup[];
  }

  private createIndexFormGroup(registerType: RegisterType, values): FormGroup {
    const value = values?.find((val) => val.timeFrame === registerType);
    return this.formBuilder.group({
      timeFrame: registerType,
      type: Direction.consumption,
      unit: [value?.unit || null, this.getIndexesValidators()],
      decimal: value?.decimal || null,
      value: value?.value || null,
    });
  }

  private getIndexesValidators(): Validators[] {
    return moment(this.moveInDate).isSameOrBefore(moment()) ? [Validators.required] : [];
  }

  private addExclusiveNightIndexes(
    indexes: FormGroup[] | FormArray,
    meterType: MeterType,
    hasExclusiveNight: boolean,
    values = null
  ): FormGroup[] | FormArray {
    if (hasExclusiveNight && [MeterType.bi, MeterType.mono].includes(meterType)) {
      indexes.push(this.createIndexFormGroup(RegisterType.NIGHT_EXCLUSIVE, values));
    }
    return indexes;
  }

  private emitFormValidity(): void {
    const electricityGroup = this.metersFormGroup.get('electricity');
    this.formValidityEmitter.emit(electricityGroup.valid);
    electricityGroup.valueChanges.subscribe(() => this.formValidityEmitter.emit(electricityGroup.valid));
  }
}

enum MeterTypeOptions {
  smart = 'SMART',
  mono = 'MONO',
  bi = 'BI',
}

enum MeterType {
  smart = 'smart',
  mono = 'mono',
  bi = 'bi',
}
