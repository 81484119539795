import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoaderService } from '../../modules/loader/services/loader.service';
import { Site } from '@app/modules/customer-zone/user/models/site.interface';
import { VersionSize } from '@app/shared/models/units.interface';
import { AnalyticsModule } from '@app/shared/modules/analytics/analytics.module';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sites-switcher',
  templateUrl: './sites-switcher.component.html',
  styleUrls: ['./sites-switcher.component.scss'],
  imports: [AnalyticsModule, CommonModule, TranslateModule, NgbModule],
  standalone: true,
})
export class SitesSwitcherComponent implements OnInit {
  @Input() sites: Site[];
  @Input() activeSite: Site;
  @Input() activeReference: string;
  @Input() version?: VersionSize = VersionSize.big;
  @Output() switchSiteEmitter: EventEmitter<string> = new EventEmitter<string>();

  constructor(private loaderService: LoaderService) {}

  ngOnInit() {}

  public switchSite(siteId: string): void {
    this.loaderService.showLoadingEffect();
    this.switchSiteEmitter.emit(siteId);
  }
}
