import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs';

export interface ScreenDimensions {
  height: number;
  width: number;
}

@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService {
  public screenSize$ = new BehaviorSubject<ScreenDimensions>(this.getWindowSize());
  public readonly screensize: Observable<ScreenDimensions> = this.screenSize$.asObservable();
  private resize$: Observable<Event>;

  constructor() {
    this.resize$ = fromEvent(window, 'resize');
    this.resize$.pipe(debounceTime(200), distinctUntilChanged()).subscribe(() => {
      this.screenSize$.next(this.getWindowSize());
    });
  }

  public getWindowSize(): ScreenDimensions {
    return {
      height: window.innerHeight,
      width: window.innerWidth,
    };
  }
}
