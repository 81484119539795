import { Component, Input, OnInit } from '@angular/core';
import { BillingDetails } from '../../models/billingDetails.interface';
import { ContactDetails, Countries } from '../../../contact/models/contactDetails.interface';
import { Alert } from '../../../../../shared/components/alert/alert.interface';
import { TranslateService } from '@ngx-translate/core';
import { email, zoomit, paper } from '../../billing.constants';
import { UserType } from '../../../../../shared/resolvers/user-type-resolver/models/user-type.interface';
import { AlertType } from '@app/shared/components/alert/alert.interface';

@Component({
  selector: 'app-invoice-delivery-channel',
  templateUrl: './invoice-delivery-channel.component.html',
  styleUrls: ['./invoice-delivery-channel.component.scss'],
})
export class InvoiceDeliveryChannelComponent implements OnInit {
  @Input() billingDetails: BillingDetails;
  @Input() contactDetails: ContactDetails;
  @Input() reference: string;
  public locked: boolean;
  public alert: Alert | null = null;
  readonly email = email;
  readonly paper = paper;
  readonly zoomit = zoomit;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.alert = {
      type: AlertType.info,
      content: {
        message: this.translate.instant('components.contact.contactChangeInProgress'),
      },
    };
  }
}
