<div class="mt-16">
  <div class="inline-block w-full text-center">
    <h2 class="font-display font-normal text-5xl te-text-gradient-home inline-block">
      {{ 'pages.bindingProcess.step1.addClientRef' | translate }}
    </h2>
  </div>
  <div class="w-full sm:max-w-2xl mx-auto py-12">
    <div class="inline-block mb-16 w-full" *ngIf="bindingError">
      <app-alert [alert]="bindingError"></app-alert>
    </div>

    <form [formGroup]="referenceForm" novalidate class="form mb-16" (ngSubmit)="handleReferenceSubmit()">
      <div class="form-element input-text" [class.errors]="referenceError">
        <label for="reference">
          {{ 'pages.bindingProcess.step1.yourClientRef' | translate }}
        </label>
        <input
          formControlName="reference"
          id="reference"
          name="email"
          placeholder="Ref: Exxxxxxx"
          value=""
          type="text"
          autocomplete="off"
          (input)="checkReference()"
        />
        <div class="message">
          <div *ngIf="referenceError">
            {{ 'pages.bindingProcess.step1.wrongClientRef' | translate }}
          </div>
        </div>
      </div>
      <div class="mt-8 text-right">
        <button
          [attr.disabled]="referenceError || !referenceForm.get('reference').value ? '' : null"
          [class.not-submittable]="referenceError || !referenceForm.get('reference').value"
          class="btn btn-primary inline-block w-full font-bold mb-4 md:mb-0 md:w-auto"
          type="submit"
          [analyticsEvent]="'binding:click:add-reference'"
        >
          {{ 'pages.onboarding.createAccount.nextStep' | translate }}
        </button>
      </div>
    </form>

    <hr class="border-grey w-80 mx-auto" />

    <h5 class="text-2xl font-medium text-grey-dark mt-16 mb-8">
      {{ 'pages.bindingProcess.step1.whereToFindClientRef' | translate }}
    </h5>
    <div class="sm:flex mb-16">
      <p class="mb-8" style="line-height: 3.2rem">
        {{ 'pages.bindingProcess.step1.clientRefExplanation' | translate }}
      </p>
      <div class="w-72 mx-auto sm:w-full sm:max-w-2xl sm:ml-4">
        <img
          src="./assets/img/totalenergies_binding.png?v=1.0.0"
          width="182"
          height="182"
          alt="Where to find reference"
          class="w-full"
        />
      </div>
    </div>
  </div>
</div>
