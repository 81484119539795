<div *ngIf="eliqAccessRights$ | async as eliqAccessRights">
  <div class="sites-infos bg-white">
    <div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
      <div class="items-center pb-8 pt-14 sm:pb-14 md:grid md:grid-cols-12 md:gap-16">
        <div class="md:col-span-12">
          <app-sites-switcher
            class="inline-block w-full ml-auto sites-switcher md:w-auto"
            [sites]="sites$ | async"
            [activeSite]="activeSite"
            [version]="versionSize.big"
            [activeReference]="reference"
            (switchSiteEmitter)="switchSite($event)"
          >
          </app-sites-switcher>
        </div>
      </div>
    </div>
  </div>

  <app-fluvius-mandates-section
    [mandates]="mandates$ | async"
    [eliqAccessRights]="eliqAccessRights"
  >
  </app-fluvius-mandates-section>
</div>
