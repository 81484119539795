import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { TooltipComponent } from '../tooltip/tooltip.component';

@Component({
  selector: 'app-warning-tooltip',
  templateUrl: './warning-tooltip.component.html',
  styleUrls: ['./warning-tooltip.component.scss'],
})
export class WarningTooltipComponent extends TooltipComponent implements OnInit, OnDestroy, OnChanges {
  @Input() warning: string;
  @Input() warningParams: object;

  public warningContent: SafeHtml;

  constructor(protected sanitizer: DomSanitizer, protected translate: TranslateService) {
    super(sanitizer, translate);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.translateWarning();
    this.translate.onLangChange.pipe(takeUntil(this.notifier$)).subscribe(() => this.translateWarning());
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.warning?.currentValue) {
      this.translateWarning();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private translateWarning(): void {
    this.warningContent = this.setMessageFromKey(this.warning, this.warningParams);
  }
}
