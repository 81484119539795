<div class="relative mb-4 md:mb-20 p-4 bg-white border border-grey rounded-4xl md:flex md:h-72 w-full">
  <!--  charging station name and list of stations (if available)  -->
  <div class="md:absolute mt-6 left-8 md:left-72 md:top-4">
    <div
      class="font-medium text-grey-dark text-2xl font-bold relative w-max"
      [class.cursor-pointer]="(chargingStations$ | async)?.length > 1"
      ngbDropdown
    >
      <div id="charging-station-dropdown" data-cy="charging-station-dropdown" ngbDropdownToggle>
        {{ 'components.monitoring.stationInfo.station' | translate }}: {{ (chargingStation$ | async)?.name }}

        <div
          *ngIf="(chargingStations$ | async)?.length > 1"
          class="arrow multi-stations"
          [ngClass]="!!dropdown && isDropdownOpen() ? 'rotate-180' : 'rotate-0'"
        ></div>
      </div>

      <div
        *ngIf="(chargingStations$ | async)?.length > 1"
        class="absolute top-4 -right-8 bg-white border border-solid border-default rounded-lg p-4"
        data-cy="charging-station-list"
        ngbDropdownMenu
        aria-labelledby="charging-station-dropdown"
      >
        <div
          *ngFor="let station of chargingStations$ | async"
          class="py-2 text-center text-base"
          (click)="changeChargingStation(station)"
        >
          {{ station.name }}
        </div>
      </div>
    </div>

    <div class="font-normal text-sm py-2 text-grey-dark">
      {{ 'components.monitoring.stationInfo.description' | translate }}
    </div>
  </div>

  <!--  station image -->
  <div class="md:absolute w-20 top-32 left-8 md:w-60 md:-top-20 z-10">
    <img
      src="./assets/img/tevc-monitoring/charger.png"
      [alt]="'components.monitoring.stationInfo.station' | translate"
    />
  </div>

  <!--  station info  -->
  <div
    class="absolute left-28 bottom-8 md:left-72 md:bottom-8"
    data-cy="charging-station-status"
    *ngIf="chargingStationStatus$ | async as chargingStationStatus"
  >
    <div class="flex items-center mt-12 -ml-4">
      <div
        class="self-center w-24 h-24 rounded-full flex-shrink-0"
        [ngClass]="getStationStatus(chargingStationStatus) + '-bg'"
      >
        <div [ngClass]="getStationStatus(chargingStationStatus) + '-img'"></div>
      </div>
      <div class="px-0 md:px-8">
        <span class="font-bold text-sm block" [ngClass]="getStationStatus(chargingStationStatus)">
          {{
            'components.monitoring.stationInfo.status.' + getStationStatus(chargingStationStatus) + '.title' | translate
          }}
        </span>
        <span class="text-sm py-4 opacity-80">
          {{
            'components.monitoring.stationInfo.status.' + getStationStatus(chargingStationStatus) + '.description'
              | translate
          }}
        </span>
      </div>
    </div>
  </div>
</div>
