import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ServiceEligibility } from '../../../contracts/models/service-eligibility.interface';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-list-services',
  templateUrl: './list-services.component.html',
  styleUrls: ['./list-services.component.scss'],
})
export class ListServicesComponent implements OnInit {
  @Input() data: { services: ServiceEligibility[]; lang: string };

  constructor(public activeModal: NgbActiveModal, public router: Router, public activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {}

  redirectToUpSellAndClose(service: ServiceEligibility) {
    const params = {} as Params;
    params.bundleCode = service.code;
    if (service.promotionCode && service.promotionCode !== '') {
      params.promoCode = service.promotionCode;
    }

    this.router
      .navigate(['/upsell/boiler-maintenance-os'], {
        relativeTo: this.activatedRoute,
        queryParams: params,
      })
      .then((value) => {
        this.activeModal.close();
      });
  }
}
