<div class="loader-container" *ngIf="step === stepTypes.preloading">
  <app-loader></app-loader>
</div>

<form novalidate [formGroup]="indexForm" class="index-form lg:max-w-7xl" autocomplete="off">
  <if-intro
    *ngIf="step === stepTypes.intro"
    [origin]="origin"
    [canChangeInstallmentIntro]="canChangeInstallmentIntro"
    (initSteps)="initSteps()"
  >
  </if-intro>

  <if-energy-selection
    *ngIf="step === stepTypes.energySelection"
    [energySelection]="indexForm.get('energySelection')"
    (formReady)="energySelectionComplete()"
  >
  </if-energy-selection>

  <ng-container *ngIf="step !== stepTypes.energySelection">
    <ng-container *ngFor="let energy of energies">
      <if-energy
        *ngIf="activeEnergy === energy"
        [editIndex]="editIndex"
        [origin]="origin"
        [energy]="energy"
        [reference]="reference"
        [activeSiteId]="activeSiteId"
        [contracts]="contracts | energyFilter: energy"
        [control]="indexForm.get('energies').get(energy | lowercase)"
        [energyActions]="energyActions"
        (formReady)="energyMeterIndexComplete(energy)"
      ></if-energy>
    </ng-container>
  </ng-container>
</form>
