import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MobileScreenService {
  renderer: Renderer2;
  public mobileSize = 767;
  private isMobile$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private rendererFactory2: RendererFactory2) {
    this.isMobile$.next(window.innerWidth < this.mobileSize);

    this.renderer = this.rendererFactory2.createRenderer(null, null);
    this.renderer.listen('window', 'resize', () => {
      const previusState = this.isMobile$.getValue();
      const isMobileNow = window.innerWidth < this.mobileSize;

      if (previusState !== isMobileNow) {
        this.isMobile$.next(window.innerWidth < this.mobileSize);
      }
    });
  }

  getIsMobile() {
    return this.isMobile$.asObservable();
  }
}
