import { Injectable } from '@angular/core';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map, take, tap } from 'rxjs';
import {
  ChargingStation,
  ChargingStationSession,
  ChargingStationSessionReport,
  ChargingStationStatus,
  ParingStatus,
  SelectedChargingStation,
} from '../models/charging-station.interface';
import { Observable } from 'rxjs/internal/Observable';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class TevcMonitoringService {
  private chargingStations$: BehaviorSubject<ChargingStation[] | null> = new BehaviorSubject(null);
  private selectedChargingStationData: SelectedChargingStation = {
    chargingStation$: new BehaviorSubject(null),
    status$: new BehaviorSubject(null),
    sessions$: new BehaviorSubject(null),
    sessionReports$: new BehaviorSubject<ChargingStationSessionReport[] | null>(null),
  };
  private chargingStationsByRef: Record<string, ChargingStation[]> = {};

  constructor(private http: HttpClient, private translate: TranslateService, private toastr: ToastrService) {}

  public getChargingStations$(): Observable<ChargingStation[]> {
    return this.chargingStations$;
  }

  public getChargingStation$(): BehaviorSubject<ChargingStation> {
    return this.selectedChargingStationData.chargingStation$;
  }

  public getChargingStationStatus$(): Observable<ChargingStationStatus> {
    return this.selectedChargingStationData.status$;
  }

  public getChargingStationSessions$() {
    return this.selectedChargingStationData.sessions$;
  }

  public getChargingStationSessionReports$() {
    return this.selectedChargingStationData.sessionReports$;
  }

  public getChargingStations(reference: string): Observable<ChargingStation[] | null> {
    if (this.chargingStationsByRef[reference]) {
      // For tevc dashboard pre-selected charging station
      if (this.chargingStationsByRef[reference][0]) {
        this.selectDefaultChardingStation(this.chargingStationsByRef[reference]);
      }
      return of(this.chargingStationsByRef[reference]);
    }
    const params = new HttpParams().set('loader', 'true').set('use-no-content-response', 'true');
    return this.http.get<ChargingStation[]>(`/v1/customers/${reference}/charging-stations`, { params }).pipe(
      catchError(() => {
        this.toastr.error(this.translate.instant('errorMessages.serverError'));
        return of([]);
      }),
      take(1),
      map((value) => {
        if (value === null) {
          value = [];
        }
        // Only In service chargingstations are relevant for us.
        value = value.filter((el) => el.status === ParingStatus.inService);
        return value;
      }),
      tap((chargingStations: ChargingStation[] | null) => {
        this.chargingStationsByRef[reference] = chargingStations;
        this.chargingStations$.next(chargingStations);
        this.selectDefaultChardingStation(chargingStations);
      })
    );
  }

  public getChargingStation(name: string) {
    const chargingStation = this.chargingStations$.value.find((el) => el.name === name);
    if (chargingStation) {
      this.selectedChargingStationData.chargingStation$.next(chargingStation);
    } else {
      this.selectedChargingStationData.chargingStation$.error('not found');
    }
  }

  public getChargingStationStatus(reference: string, name: string): Observable<ChargingStationStatus | null> {
    const params = new HttpParams().set('loader', 'false');
    this.http
      .get<ChargingStationStatus>(`/v1/customers/${reference}/charging-stations/${name}/status`, { params })
      .pipe(
        catchError((error) => {
          this.toastr.error(this.translate.instant('errorMessages.serverError'));
          return throwError(() => new Error(error.message));
        }),
        take(1)
      )
      .subscribe((value) => this.selectedChargingStationData.status$.next(value));
    return this.selectedChargingStationData.status$;
  }

  public getChargingStationSessions(
    reference: string,
    name: string,
    fromDate: string,
    toDate: string
  ): Observable<ChargingStationSession[] | null> {
    const params = new HttpParams().set('fromDate', fromDate).set('toDate', toDate).set('loader', 'false');

    this.http
      .get<ChargingStationSession[]>(`/v1/customers/${reference}/charging-stations/${name}/sessions`, { params })
      .pipe(
        catchError((error) => {
          this.toastr.error(this.translate.instant('errorMessages.serverError'));
          return throwError(() => new Error(error.message));
        }),
        take(1)
      )
      .subscribe((value) => this.selectedChargingStationData.sessions$.next(value));
    return this.selectedChargingStationData.sessions$;
  }

  public getChargingStationSessionReports(
    reference: string,
    name: string,
    type: string,
    fromDate: string,
    toDate: string
  ) {
    const params = new HttpParams()
      .set('type', type)
      .set('fromDate', fromDate)
      .set('toDate', toDate)
      .set('loader', 'false');
    this.http
      .get<ChargingStationSessionReport[]>(`/v1/customers/${reference}/charging-stations/${name}/sessions-reporting`, {
        params,
      })
      .pipe(
        catchError((error) => {
          this.toastr.error(this.translate.instant('errorMessages.serverError'));
          return throwError(() => new Error(error.message));
        }),
        take(1)
      )
      .subscribe((value) => this.selectedChargingStationData.sessionReports$.next(value));

    return this.selectedChargingStationData.sessionReports$;
  }

  private selectDefaultChardingStation(chargingStations: ChargingStation[]) {
    if (chargingStations[0]) {
      // Populate the charging station data based on the first value.
      this.selectedChargingStationData.chargingStation$.next(chargingStations[0]);
    } else {
      // No charging stations found. Reset Values.
      this.selectedChargingStationData.chargingStation$.next(null);
    }
    this.selectedChargingStationData.status$.next(null);
    this.selectedChargingStationData.sessions$.next(null);
  }
}
