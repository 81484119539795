import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Contract, ContractTypes } from '../../../contracts/models/contract.interface';
import { MeteringsService } from '../../services/meterings/meterings.service';
import { EnergyType, Index, IndexRange } from '../../models/consumption.interface';
import { delay, take, takeUntil } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProvisionalIndexesComponent } from './provisional-indexes/provisional-indexes.component';
import { Observable } from 'rxjs/internal/Observable';
import { AnalyticsTrackingService } from '../../../../../core/analytics/tracking/analytics-tracking.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { EnergyUnit } from '../../../../../shared/models/units.interface';
import { MainFacade } from '@app/core/facade/main.facade';

@Component({
  selector: 'app-meter-table',
  templateUrl: './meter-table.component.html',
  styleUrls: ['./meter-table.component.scss'],
})
export class MeterTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() contract: Contract;
  @Input() reference: string;
  @Input() siteId: string;
  meteringsArray: Array<Observable<IndexRange[]>>;
  provisionalIndexesArray: Array<Observable<any[]>>;
  meteringsError = false;
  energyUnit: string;
  notifier = new Subject<void>();

  constructor(
    private facade: MainFacade,
    private meteringsService: MeteringsService,
    private modalService: NgbModal,
    public translateService: TranslateService,
    private analyticsService: AnalyticsTrackingService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.meteringsArray = this.meteringsService.getMeteringsArray();
    this.provisionalIndexesArray = this.meteringsService.getProvisionalIndexesArray();
    this.translateService.onLangChange.pipe(takeUntil(this.notifier), delay(1)).subscribe((event: LangChangeEvent) => {
      this.analytics(this.router.url);
    });
  }

  ngOnChanges() {
    this.analytics();
    this.meteringsError = false;
    this.energyUnit = this.contract.type === ContractTypes.ELECTRICITY ? EnergyUnit.kwh : EnergyUnit.m3;
    this.meteringsService
      .getMeterings(this.reference, this.siteId, this.contract.type as EnergyType, this.contract.deliveryPointReference)
      .pipe(take(1))
      .subscribe(
        (meterings: Index[]) => {
          let newMeterings = [];
          let provisional = [];
          if (meterings) {
            [newMeterings, provisional] = this.meteringsService.prepareIndexes(
              [...meterings].reverse(),
              this.contract.type,
              this.facade.state$?.value?.computedData?.hasSolarPanel
            );
          }
          const { deliveryPointReference } = this.contract;
          this.meteringsService.setMeterings$(deliveryPointReference, newMeterings);
          this.meteringsService.setProvisionalIndexes$(deliveryPointReference, provisional);
        },
        (err) => (this.meteringsError = true)
      );
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }

  showProvisionalIndexes(provisionalIndexesByDirection, boundaryIndexes, provisionalIndexes) {
    const modalRef = this.modalService.open(ProvisionalIndexesComponent, {
      size: 'xxl',
    });
    modalRef.componentInstance.provisionalIndexes = provisionalIndexes;
    modalRef.componentInstance.provisionalsByDirection = provisionalIndexesByDirection;
    modalRef.componentInstance.boundaryIndexes = boundaryIndexes;
    modalRef.componentInstance.energyUnit = this.energyUnit;
    modalRef.componentInstance.contract = this.contract;
    modalRef.componentInstance.reference = this.reference;
    modalRef.componentInstance.siteId = this.siteId;
  }

  private analytics(path: string = null) {
    this.facade.analytics.push(
      {
        event: 'pageview',
        component: { name: 'MyEnergy-meter-reading' },
        page: {
          path: `${path || this.router.url}/meter-reading-${this.contract.type.toLowerCase()}`,
          phase: 'care',
          category: 'cuzo',
          subCategory: `meter reading ${this.contract.type.toLocaleLowerCase()} - cuzo`,
        },
      },
      {
        reference: this.facade?.state$?.value?.reference,
        site: this.facade?.state$?.value?.activeSite,
        accessRights: this.facade?.state$?.value?.accessRights,
        eliqAccessRights: this.facade?.state$?.value?.eliqAccessRights,
        contracts: this.facade?.state$?.value?.contracts,
      }
    );
  }
}
