<div class="binding-process px-8 xl:max-w-screen-xl xl:w-full xl:m-auto flex flex-1 flex-col">
  <ul class="mt-8 unstyled breadcrumb" *ngIf="(referencesDetails$ | async)?.length">
    <li class="breadcrum__item">
      <span class="breadcrumb__link" routerLink="/">myTotalEnergies</span>
    </li>
    <li class="breadcrum__item">
      <span class="breadcrumb__link" routerLink="/preferences/references">
        {{ 'header.secondaryMenu.helloUser.manageMyCustomerReferences' | translate }}
      </span>
    </li>
    <li class="breadcrum__item">
      <span> {{ 'pages.bindingProcess.step1.addClientRef' | translate }} </span>
    </li>
  </ul>

  <app-add-reference
    *ngIf="step === 1"
    class="step1 flex-1"
    [reference]="reference"
    [referenceError]="referenceError"
    [bindingError]="bindingError"
    (referenceErrorEmitter)="errorHandler($event.errorVariable, $event.errorStatus)"
    (stepInfoEmitter)="getPrivateInfoFromReference($event.params, $event.step)"
  >
  </app-add-reference>

  <app-send-code
    *ngIf="step === 2"
    class="step2 flex-1"
    [privateContactInfo]="privateContactInfo"
    [sendingError]="sendingError"
    [isAcceptedReference]="isAcceptedReference"
    (sendingModeEmitter)="sendVerificationCode($event)"
    (goBackEmitter)="goBack()"
  >
  </app-send-code>

  <app-code-verification
    *ngIf="step === 3"
    class="step3 flex-1"
    [sendingMode]="sendingMode"
    [codeError]="codeError"
    (codeErrorEmitter)="errorHandler($event.errorVariable, $event.errorStatus)"
    (verificationCodeEmitter)="checkVerificationCode($event)"
    (goBackEmitter)="goBack()"
  >
  </app-code-verification>

  <div class="monitoring">monitoring</div>
</div>
