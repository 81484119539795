import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateAccountComponent } from './pages/create-account/create-account.component';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { AnalyticsModule } from '../../shared/modules/analytics/analytics.module';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
@NgModule({
  declarations: [CreateAccountComponent],
  imports: [CommonModule, TranslateModule, ReactiveFormsModule, SharedModule, AnalyticsModule],
})
export class RegistrationModule {}

_('pages.onboarding.createAccount.emailPlaceholder');
_('pages.onboarding.pageTitle');
