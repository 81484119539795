import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs';
import { Site } from 'src/app/modules/customer-zone/user/models/site.interface';
import { LangChangeEvent } from '@ngx-translate/core';
import { MainFacade } from '@app/core/facade/main.facade';
import { ContractDtoCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Component({
  selector: 'app-all-contracts',
  templateUrl: './all-contracts.component.html',
  styleUrls: ['./all-contracts.component.scss'],
})
export class AllContractsComponent implements OnInit, OnDestroy {
  public sites$: Observable<Site[]>;
  public activeSite$: Observable<Site>;
  public contracts$: Observable<ContractDtoCuzoApi[]>;
  public activeReference: string;
  public activeSiteId: string;
  private killer = new Subject<void>();

  constructor(private facade: MainFacade) {}

  ngOnInit() {
    this.analytics();
    this.facade.utils.setPageTitle('pages.contracts.pageTitle');
    this.facade.translate.onLangChange
      .pipe(takeUntil(this.killer))
      .subscribe((event: LangChangeEvent) => this.analytics());
    this.facade.activeSite$
      .pipe(
        filter((site) => !!site),
        takeUntil(this.killer)
      )
      .subscribe((site: Site) => {
        this.activeSite$ = of(site);
        this.activeReference = this.facade.state$.value.reference;
        this.activeSiteId = site?.id;
        this.sites$ = this.facade.sites$;
        this.contracts$ = this.facade.loadContracts(this.activeReference, site, true);
      });
  }

  ngOnDestroy() {
    this.killer.next();
    this.killer.complete();
  }

  public switchSite(siteId: string) {
    this.facade.updateActiveSite(siteId);
  }

  private analytics() {
    this.facade.analytics.push(
      {
        event: 'pageview',
        page: {
          phase: 'care',
          category: 'cuzo',
          subCategory: 'contracts - cuzo',
        },
      },
      {
        reference: this.facade?.state$?.value?.reference,
        site: this.facade?.state$?.value?.activeSite,
        accessRights: this.facade?.state$?.value?.accessRights,
      }
    );
  }
}
