import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MainFacade } from '@app/core/facade/main.facade';
import { VersionSize } from '@app/shared/models/units.interface';
import { filter, map, Observable, Subject, takeUntil, tap } from 'rxjs';
import { Site } from '@app/modules/customer-zone/user/models/site.interface';
import {
  HomeProfile,
  HomeProfileUpdate,
} from '@app/modules/customer-zone/consumption/services/home-profile/home-profile.service';
import { ToastrService } from 'ngx-toastr';
import { EliqAccessRights } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { Mandate } from '@app/modules/customer-zone/consumption/models/mandates.interface';
import { ContractDtoCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Component({
  selector: 'app-home-profile',
  templateUrl: './home-profile.component.html',
  styleUrls: ['./home-profile.component.scss'],
})
export class HomeProfileComponent implements OnInit, OnDestroy {
  reference: string;
  activeSite: Site;
  contracts$: Observable<ContractDtoCuzoApi[]>;
  sites$: Observable<Site[]>;
  versionSize = VersionSize;
  homeProfile$: Observable<{ completion: number; data: HomeProfile }>;
  homeProfileCompletion$: Observable<{ completion: number }>;
  eliqAccessRights$: Observable<EliqAccessRights>;
  mandates$: Observable<Mandate[]>;
  updateOngoing: number = 0; // 0=none; 1=ongoing; 2=error
  successPopup: boolean = false;
  previousCompletion: number;
  isSyncEliqData$: Observable<boolean>;
  private notifier$ = new Subject<void>();

  constructor(
    public facade: MainFacade,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.analytics();
    this.facade.utils.setPageTitle('pages.homeProfile.pageTitle');
    this.facade.activeSite$
      .pipe(
        filter((site) => !!site),
        takeUntil(this.notifier$)
      )
      .subscribe((site: Site) => {
        this.reference = this.facade.state$.value.reference;
        this.activeSite = site;
        this.contracts$ = this.facade.contracts$;
        this.mandates$ = this.facade.loadMandates(this.reference);
        this.sites$ = this.facade.sites$;
        this.eliqAccessRights$ = this.facade.eliqAccessRights$;
        this.successPopup = false;
        this.isSyncEliqData$ = this.facade.isSynchronisingEliqData();
        this.homeProfile$ = this.facade
          .fetchHomeProfileData(this.reference, site)
          .pipe(tap((data) => (this.previousCompletion = data?.completion))); // Fetch Data
      });
  }

  ngOnDestroy(): void {
    this.notifier$.next();
    this.notifier$.complete();
  }

  switchSite(site: string) {
    this.facade.updateActiveSite(site);
  }

  updateHomeProfile(payload: HomeProfileUpdate[]) {
    this.updateOngoing = 1;
    this.facade.updateHomeProfile(payload, null, null, false).subscribe(
      () => {
        this.homeProfileCompletion$ = this.facade
          .getHomeProfileCompletion(this.reference, this.activeSite, true, null, false)
          .pipe(
            map((data) => {
              this.successPopup = this.previousCompletion < 100 && data === 100;
              return { completion: data };
            })
          );
        this.updateOngoing = 0;
        this.facade.bustCrossSellCaching(this.reference);
        this.facade.bustEnergyUsageCaching(this.reference);
        this.toastrService.success(this.facade.translate.instant('components.homeProfileForm.update.success.label'));
      },
      () => {
        this.updateOngoing = 2;
        this.toastrService.error(this.facade.translate.instant('components.homeProfileForm.update.error.label'));
      }
    );
  }

  closeSuccessPopup() {
    this.successPopup = false;
  }

  private analytics(): void {
    this.facade.analytics.push(
      {
        event: 'pageview',
        page: {
          phase: 'care',
          category: 'cuzo',
          subCategory: 'home profile - cuzo',
        },
      },
      {
        reference: this.facade?.state$?.value?.reference,
        site: this.facade?.state$?.value?.activeSite,
        accessRights: this.facade?.state$?.value?.accessRights,
        eliqAccessRights: this.facade?.state$?.value?.eliqAccessRights,
        contracts: this.facade?.state$?.value?.contracts,
      }
    );
  }
}
