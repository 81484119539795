/* eslint-disable */
export enum BoilerOneShotSteps {
  SELECT_REFERENCE = 'reference',
  SELECT_SITES = 'sites',
  BOILER_TYPE = 'boilerType',
  HOUSE_AGE = 'house-age',
  CONTACT_INFO = 'contact-info',
  CONFIRMATION = 'confirmation',
  DONE = 'done',
}

export enum TaxEligibility {
  HIGH = 'HIGH',
  LOW = 'LOW',
}

export interface UpsellRequest {
  reference?: string;
  siteId?: string;
  bundleCode?: string;
  promoCode?: string;
  metadata?: JsonNode;
  contact?: Contact;
  registration?: Registration;
}

export interface JsonNode {
  taxEligibility: string;
  vatConfirmation: boolean;
}

export interface Contact {
  name: string;
  phone: string;
  email: string;
}

export interface Registration {
  context: string;
  salesContext: string;
  salesContextDetail: string;
}
