import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { StepModel, StepsService } from '@app/modules/customer-zone/consumption/services/steps/steps.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-step-template',
  templateUrl: './step-template.component.html',
  styleUrls: ['./step-template.component.scss'],
})
export class StepTemplateComponent implements OnInit, OnDestroy {
  @Input() step: StepModel;
  stepsNumber: number;

  public notifier$ = new Subject<void>();

  constructor(private stepsService: StepsService) {}

  ngOnInit(): void {
    this.step.isComplete = true;
    this.stepsService
      .getSteps()
      .pipe(takeUntil(this.notifier$))
      .subscribe((steps: StepModel[]) => {
        this.stepsNumber = steps?.length;
      });
  }

  ngOnDestroy() {
    this.notifier$.next();
    this.notifier$.complete();
  }
}
