import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Advice, AdviceStatus } from '@app/modules/customer-zone/consumption/services/advice/advice.service';
import { ViewType } from '@app/modules/customer-zone/consumption/components/advice-investment/advice-investment.component';

@Component({
  selector: 'app-advice-item',
  templateUrl: './advice-item.component.html',
  styleUrls: ['./advice-item.component.scss'],
})
export class AdviceItemComponent implements OnInit {
  @Output() emitOpenAdvice: EventEmitter<Advice> = new EventEmitter<Advice>();
  @Output() emitStatusAction: EventEmitter<{ status: AdviceStatus; id: string }> = new EventEmitter<{
    status: AdviceStatus;
    id: string;
  }>();
  @Input() advice: Advice;
  protected readonly viewTypes = ViewType;
  constructor() {}

  ngOnInit(): void {}

  openSingleAdvice(advice: Advice) {
    this.emitOpenAdvice.emit(advice);
  }

  updateAdviceStatus($event: { status: AdviceStatus; id: string }) {
    this.emitStatusAction.emit($event);
  }
}
