<div class="bg-yellow-50 py-4">
  <div class="px-8 text-sm text-grey-dark flex items-center xl:max-w-screen-xl xl:w-full xl:m-auto">
    <img ngSrc="assets/img/icons/standalone/yellow/chart.svg" class="hidden md:inline-block mr-8" width="40" height="40">
    <div
      [innerHTML]="'components.fluviusConsents.intro.' + (globalMandateStatus === GlobalMandateStatus.missingRequiredMandate ? 'missingRequiredMandate' : 'mandates') + '.banner.content' | translate">
    </div>
  </div>
</div>

<div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
  <section class="border border-grey md:border-0 p-8 bg-white mt-12 rounded-4xl relative sm:p-14 sm:shadow-none">
    <div class="w-full lg:w-3/5">
      <div class="w-full">
        <ng-template [ngTemplateOutlet]="content"></ng-template>
        <div
          *ngIf="fluviusLink$ | async as fluviusLink"
          class="hidden md:inline-block w-full md:mt-24"
        >
          <app-fluvius-mandates-cta
            [fluviusUrl]="fluviusLink?.fluviusUrl"
          >
          </app-fluvius-mandates-cta>
        </div>
      </div>
      <img ngSrc="assets/img/phone.png" width="448" height="533" class="my-4 mx-auto w-96 md:hidden">
    </div>
    <img class="hidden absolute top-0 right-12 lg:inline-block" ngSrc="assets/img/phone.png" style="max-width: 45%" width="448" height="533">
  </section>
  <section
    *ngIf="fluviusLink$ | async as fluviusLink"
    class="border border-grey p-8 bg-white mt-12 rounded-4xl relative md:hidden"
  >
    <app-fluvius-mandates-cta
      [fluviusUrl]="fluviusLink?.fluviusUrl"
    >
    </app-fluvius-mandates-cta>
  </section>
</div>

<section *ngIf="faqItems?.length" class="bg-white py-12 mt-16 md:bg-transparent md:py-0">
  <div class="px-8 xl:max-w-screen-xl xl:w-full xl:mx-auto ">
    <div class="text-center md:text-left">
      <h3 class="faq-title">
        {{ 'components.fluviusConsents.intro.faq.title' | translate }}
      </h3>
    </div>
    <div *ngFor="let faqItem of faqItems">
      <app-faq-item
        [faqItem]="faqItem"
        [backgroundColor]="'bg-grey-light md:bg-white'"
      >
      </app-faq-item>
    </div>
  </div>
</section>


<ng-template #content>
  <!-- REQUIRED MANDATE MISSING -->
  <ng-container *ngIf="globalMandateStatus === GlobalMandateStatus.missingRequiredMandate; else missingActiveMandate">
    <h3 class="text-2xl leading-tight font-medium">
      {{ 'components.fluviusConsents.intro.missingRequiredMandate.title' | translate }}
    </h3>
    <div class="mt-8">
      <h4 class="text-lg">
        {{ 'components.fluviusConsents.intro.missingRequiredMandate.subtitle_one' | translate }}
      </h4>
      <p
        class="text-base mb-8"
        [innerHTML]="'components.fluviusConsents.intro.missingRequiredMandate.content_one' | translate"
      >
      </p>
    </div>
    <div>
      <h4 class="text-lg">
        {{ 'components.fluviusConsents.intro.missingRequiredMandate.subtitle_two' | translate }}
      </h4>
      <p
        class="inline-block w-full mb-10"
        [innerHTML]="'components.fluviusConsents.intro.missingRequiredMandate.content_two' | translate"
      >
      </p>
    </div>
    <ng-container
      [ngTemplateOutlet]="fluviusCTA"
      [ngTemplateOutletContext]="{text: 'components.fluviusConsents.intro.mandates.cta.label' | translate }">
    </ng-container>
  </ng-container>

  <!-- MISSING ACTIVE MANDATE || MANDATES REQUESTED -->
  <ng-template #missingActiveMandate>
    <h3 class="text-2xl leading-tight font-medium">
      {{ 'components.fluviusConsents.intro.mandates.title' | translate }}
    </h3>
    <div class="mt-8">
      <h4 class="text-lg">
        {{ 'components.fluviusConsents.intro.mandates.subtitle_one' | translate }}
      </h4>
      <p
        class="text-base mb-8"
        [innerHTML]="'components.fluviusConsents.intro.mandates.content_one' | translate"
      >
      </p>
    </div>
    <div>
      <h4 class="text-lg">
        {{ 'components.fluviusConsents.intro.mandates.subtitle_two' | translate }}
      </h4>
      <p
        class="inline-block w-full mb-10"
        [innerHTML]="'components.fluviusConsents.intro.mandates.content_two' | translate"
      >
      </p>
    </div>
    <ng-container
      [ngTemplateOutlet]="fluviusCTA"
      [ngTemplateOutletContext]="{text: 'components.fluviusConsents.intro.mandates.cta.label' | translate }">
    </ng-container>
  </ng-template>
</ng-template>


<ng-template #fluviusCTA let-text="text">
  <ng-container *ngIf="fluviusLink$ | async as fluviusLink">
    <a
      [href]="fluviusLink?.fluviusUrl"
      target="_blank"
      class="btn btn-primary"
    >
      {{ text }}
    </a>
  </ng-container>
</ng-template>
