import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IndexModalService } from '../../../services/index-modal/index-modal.service';
import { Subject, takeUntil } from 'rxjs';
import { Index, IndexEncodingOrigin } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { Contract } from '@app/modules/customer-zone/contracts/models/contract.interface';

@Component({
  selector: 'app-index-modal',
  templateUrl: './index-modal.component.html',
  styleUrls: ['./index-modal.component.scss'],
})
export class IndexModalComponent implements OnDestroy, OnInit {
  @Input() origin?: IndexEncodingOrigin = IndexEncodingOrigin.default;
  @Input() editIndex?: { metering: Index; contract: Contract };
  public modalTitle: string;
  public modalIcon: string;
  public notifier = new Subject<void>();

  constructor(
    private translate: TranslateService,
    private indexModalService: IndexModalService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.indexModalService
      .getModalTitle()
      .pipe(takeUntil(this.notifier))
      .subscribe((title) => {
        this.modalTitle = title;
        this.changeDetectorRef.detectChanges();
      });
    this.indexModalService
      .getModalIcon()
      .pipe(takeUntil(this.notifier))
      .subscribe((icon) => {
        this.modalIcon = icon;
        this.changeDetectorRef.detectChanges();
      });
    this.indexModalService.setModalTitle(this.translate.instant('components.indexModal.title'));
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }
}
