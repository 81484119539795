import { MoveMeter } from '@app/core/state/move.state';

export interface PreSwitchLight {
  serviceComponent?: string;
  switchPossibilities?: SwitchPossibility[];
  ean: string;
  meters?: MoveMeter[];
  statusMove?: string;
}

export interface SwitchPossibility {
  switchType?: SwitchType;
  minDate?: string;
  maxDate?: string;
  moveDate?: string;
  moveContext?: string;
  transactionReference?: string;
  firstDateOfMonth?: boolean;
}

export enum SwitchType {
  ILCWithoutHandoverDocument = 'ILCWithoutHandoverDocument',
  ILCWithHandoverDocument = 'ILCWithHandoverDocument',
}
