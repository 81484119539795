import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ContactDetails } from '../../../contact/models/contactDetails.interface';
import { ContactService } from '../../../contact/services/contact/contact.service';
import { EstimatedIndex, EstimatedIndexRequest } from '../../models/estimated-index.interface';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EstimatedIndexService } from '../../services/estimated-index/estimated-index.service';
import { SiteService } from '../../../user/services/site/site.service';
import { Site } from '../../../user/models/site.interface';
import { take } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { formatDate } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { MeterIndex, RegisterType, RegisterTypeExtra } from '../../models/consumption.interface';
import { ContractTypes } from '../../../contracts/models/contract.interface';
import { EnergyUnit } from '../../../../../shared/models/units.interface';

@Component({
  selector: 'app-estimated-index-detail',
  templateUrl: './estimated-index-detail.component.html',
  styleUrls: ['./estimated-index-detail.component.scss'],
})
export class EstimatedIndexDetailComponent implements OnInit {
  @Input() estimatedIndex: EstimatedIndex;

  public estimatedIndexForm: UntypedFormGroup;
  public contact$: Observable<ContactDetails>;
  public site$: Observable<Site>;
  public showForm = true;
  public dateOptions;
  public energyUnit: EnergyUnit;

  constructor(
    private contactService: ContactService,
    private estimatedIndexService: EstimatedIndexService,
    private siteService: SiteService,
    private fb: UntypedFormBuilder,
    private toastrService: ToastrService,
    private translate: TranslateService
  ) {
    this.dateOptions = this.getDateOptions(new Date(), -4);
  }

  get meters() {
    return this.estimatedIndexForm.get('meters') as UntypedFormArray;
  }

  ngOnInit(): void {
    this.injectLabelToEstimatedIndex();
    this.translate.onLangChange.subscribe(() => this.injectLabelToEstimatedIndex());

    this.contact$ = this.contactService.getContactDetails(this.estimatedIndex.reference);
    this.site$ = this.siteService.getSite(this.estimatedIndex.reference, this.estimatedIndex.site);
    this.buildForm();
    this.energyUnit =
      this.estimatedIndex.energy === (ContractTypes.ELECTRICITY as string) ? EnergyUnit.kwh : EnergyUnit.m3;
  }

  public submit() {
    const estimatedIndexRequest: EstimatedIndexRequest = {
      indexDate: this.estimatedIndexForm.get('date').value,
      meters: [],
      createCuzoAccount: false,
    };

    this.estimatedIndex.meters.forEach((meter, j) => {
      const indexes = this.meters.at(j).get('indexes') as UntypedFormArray;
      estimatedIndexRequest.meters.push({
        meterNumber: this.meters.at(j).get('meterNumber').value,
        indexes: [],
      });
      meter.indexes.forEach((index, i) => {
        const { unit, decimal } = indexes.at(i).value;
        const value = Number(`${unit}.${decimal}`);
        estimatedIndexRequest.meters[j].indexes.push({
          value,
          name: index.name,
          register: index.register,
          direction: index.direction,
        } as MeterIndex);
      });
    });

    this.estimatedIndexService
      .sendEstimatedIndexes(this.estimatedIndex.reference, this.estimatedIndex.id, estimatedIndexRequest)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.estimatedIndexService.resetEstimatedIndexes(this.estimatedIndex.reference);
          this.toastrService.success(this.translate.instant('components.estimatedIndexDetail.alert.success'));
          this.showForm = false;
        },
        error: () => {
          this.toastrService.error(this.translate.instant('components.estimatedIndexDetail.alert.error'));
        },
      });
  }

  /*
   * RULE FROM JULIEN SESTER:
   * HIGH when '3/2.8.1', '2/H, 1/H', '%003', '%001', '3/C', '%_1', '%_3', 'E11BCY%'
   * LOW when '4/2.8.2', '2/1.8.2', '2/L', '1/L', '%004', '%002', '4/D', '_2', '%_4', 'E10BCY%'
   * TOTAL_HOUR when '2/DIGITAL'
   * */
  public deduceRegisterOfNotUsed(name: string): string {
    if (name.match(/^(3\/2\.8\.1|(?:1|2)\/H|3\/C|.+?(?:00|_)(?:1|3)|E11BCY.+?)$/)) {
      return RegisterType.HIGH;
    } else if (name.match(/^((?:4\/2|2\/1)\.8\.2|(?:1|2)\/L|.+?(?:00|_)(?:2|4)|4\/D|E10BCY.+?)$/)) {
      return RegisterType.LOW;
    } else {
      return RegisterType.TOTAL_HOUR;
    }
  }

  private getDateOptions(startDate, shift: number) {
    const dates = {};
    const currentDate = startDate;
    const stopDate = new Date();
    stopDate.setDate(startDate.getDate() + shift);

    const setOptions = () => {
      const formattedDate = formatDate(new Date(currentDate), 'yyyy-MM-dd', 'en');
      dates[formattedDate] = formattedDate;
      currentDate.setDate(shift > 0 ? currentDate.getDate() + 1 : currentDate.getDate() - 1);
    };

    if (shift > 0) {
      while (currentDate <= stopDate) {
        setOptions();
      }
    } else {
      while (currentDate >= stopDate) {
        setOptions();
      }
    }

    return dates;
  }

  private buildForm() {
    const formattedDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
    this.estimatedIndexForm = this.fb.group({
      date: [`${formattedDate}`, Validators.required],
      meters: new UntypedFormArray([]),
    });

    this.estimatedIndex.meters.forEach((meter, i) => {
      this.meters.push(
        this.fb.group({
          meterNumber: [meter.meterNumber],
          indexes: new UntypedFormArray([]),
        })
      );

      meter.indexes.forEach(() => {
        const indexes = this.meters.at(i).get('indexes') as UntypedFormArray;
        indexes?.push(
          this.fb.group({
            unit: ['', Validators.required],
            decimal: [''],
          })
        );
      });
    });
  }

  private injectLabelToEstimatedIndex() {
    this.estimatedIndex.meters = this.estimatedIndex.meters.map((meter) => {
      const indexes = meter.indexes.map((index) => {
        const register =
          index.register !== RegisterTypeExtra.NOT_USED ? index.register : this.deduceRegisterOfNotUsed(index.name);
        const translation = `components.estimatedIndexDetail.form.meterInput.${register}.${index.direction}`;
        const label = this.translate.instant(`${translation}.label`);
        const tooltip = this.translate.instant(`${translation}.tooltip`);
        return { ...index, label, tooltip, newRegister: register };
      });
      return { ...meter, indexes };
    });
  }
}
