import { Component, Input, OnInit } from '@angular/core';
import { CurrencyPipe, DatePipe, NgIf } from '@angular/common';
import { MainFacade } from '@app/core/facade/main.facade';
import { TranslateModule } from '@ngx-translate/core';
import { PaymentPlanCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Component({
  selector: 'app-payment-plan-info',
  standalone: true,
  templateUrl: './payment-plan-info.component.html',
  imports: [CurrencyPipe, NgIf, DatePipe, TranslateModule],
  styleUrls: ['./payment-plan-info.component.scss'],
})
export class PaymentPlanInfoComponent implements OnInit {
  @Input() paymentPlan: PaymentPlanCuzoApi;

  constructor(public facade: MainFacade) {}

  ngOnInit(): void {}
}
