import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface StepModel {
  stepIndex: number;
  isComplete: boolean;
}

const STEPS: StepModel[] = [
  { stepIndex: 1, isComplete: false },
  { stepIndex: 2, isComplete: false },
  { stepIndex: 3, isComplete: false },
  { stepIndex: 4, isComplete: false },
];

@Injectable({
  providedIn: 'root',
})
export class StepsService {
  private steps$: BehaviorSubject<StepModel[]> = new BehaviorSubject<StepModel[]>(STEPS);
  private currentStep$: BehaviorSubject<StepModel> = new BehaviorSubject<StepModel>(null);

  constructor() {
    this.resetStep();
  }

  public resetStep(): void {
    this.currentStep$.next(this.steps$.value[0]);
  }

  public setCurrentStep(step: StepModel): void {
    this.currentStep$.next(step);
  }

  public getCurrentStep(): Observable<StepModel> {
    return this.currentStep$.asObservable();
  }

  public getSteps(): Observable<StepModel[]> {
    return this.steps$.asObservable();
  }

  public moveToPreviousStep(): void {
    const index = this.currentStep$.value.stepIndex;

    if (index > 1) {
      this.currentStep$.next(this.steps$.value[index - 2]);
    }
  }

  public moveToNextStep(): void {
    const index = this.currentStep$.value.stepIndex;

    if (index < this.steps$.value.length) {
      this.currentStep$.next(this.steps$.value[index]);
    }
  }

  public isLastStep(): boolean {
    return this.currentStep$.value.stepIndex === this.steps$.value.length;
  }

  public setLastStep(): void {
    this.currentStep$.next(this.steps$.value[this.steps$.value.length - 1]);
  }

  public getStepsNumber(): number {
    return this.steps$.value?.length;
  }
}
