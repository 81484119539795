<div class="w-full px-8 xl:max-w-screen-xl xl:w-full xl:m-auto md:mt-16">
  <div class="flex flex-wrap items-center">
    <div class="md:flex-1 order-2 my-16 ml-4 md:my-0 md:ml-0">
      <div class="text-center md:max-w-xl md:mx-auto">
        <h3 class="text-center mb-4 font-medium font-sans">
          {{ 'components.pageError' + (status === 403 ? '.unauthorized' : '') + '.title' | translate }}
        </h3>
        <p
          class="font-sans mb-8"
          [innerHTML]="'components.pageError' + (status === 403 ? '.unauthorized' : '') + '.description' | translate"
        ></p>
        <button
          *ngIf="status === 503"
          class="btn btn-primary mt-6"
          (click)="reloadPage()"
          [innerHTML]="'components.pageError.reload' | translate"
        >
        </button>
      </div>
    </div>
    <div *ngIf="knownError" class="flex-1 mr-4 md:mr-0">
      <img src='./assets/img/errors/{{status}}.svg' alt="TotalEnergies - error {{ status }}" class="max-w-full">
    </div>
  </div>
</div>
