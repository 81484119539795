import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReferencesComponent } from './pages/references/references.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RenameReferenceComponent } from './components/rename-reference/rename-reference.component';
import { SharedModule } from '../../../shared/shared.module';
import { DeleteReferenceComponent } from './components/delete-reference/delete-reference.component';
import { ConsentComponent } from './pages/consent/consent.component';
import { ConsentFormComponent } from './components/consent-form/consent-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AnalyticsModule } from '../../../shared/modules/analytics/analytics.module';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@NgModule({
  declarations: [
    ReferencesComponent,
    RenameReferenceComponent,
    DeleteReferenceComponent,
    ConsentComponent,
    ConsentFormComponent,
  ],
  imports: [CommonModule, RouterModule, TranslateModule, NgbModule, SharedModule, ReactiveFormsModule, AnalyticsModule],
})
export class UserModule {}

_('pages.consents.pageTitle');
_('pages.myClientReferences.pageTitle');
