import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ChargingStationSessionReport } from '../../models/charging-station.interface';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs';

@Component({
  selector: 'app-reports-table',
  templateUrl: './reports-table.component.html',
  styleUrls: ['./reports-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportsTableComponent implements OnInit {
  @Input() chargingStationSessionReports$: Observable<ChargingStationSessionReport[]>;

  locale: string;

  constructor(private translate: TranslateService) {}

  get maxKwh(): Observable<number> {
    return this.chargingStationSessionReports$.pipe(
      map((reports) =>
        Math.max.apply(
          Math,
          reports?.map((a) => a.totalEnergy)
        )
      )
    );
  }

  ngOnInit(): void {
    this.locale = this.translate.currentLang + '-BE';
    this.translate.onLangChange.subscribe((value) => {
      this.locale = value.lang + '-BE';
    });
  }
}
