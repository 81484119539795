import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, Observable, shareReplay, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdviceService {
  private currentStatus: string;
  private adviceObservable: Observable<Advice>;

  constructor(private http: HttpClient) {}

  public getAdvice(reference: string, siteId: string, status: string = null, loader = true): Observable<any> {
    let params = new HttpParams().set('loader', loader.toString());
    if (status) {
      params = params.set('status', status);
    }
    if (!this.adviceObservable || this.currentStatus !== status) {
      this.currentStatus = status;
      this.adviceObservable = this.http.get<any>(`/v1/customers/${reference}/sites/${siteId}/advice`, { params }).pipe(
        shareReplay(1),
        catchError((e) => throwError(e))
      );
    }
    return this.adviceObservable;
  }

  public patchAdviceStatus(reference: string, siteId: string, adviceId: string, body: { status: string }, loader) {
    const params = new HttpParams().set('loader', loader.toString());
    return this.http
      .patch(`/v1/customers/${reference}/sites/${siteId}/advice/${adviceId}`, body, { params })
      .pipe(catchError((e) => throwError(e)));
  }
}

export interface Advice {
  id: string;
  key: string;
  relevance: number;
  estimatedYearlySavingsEnergy: number;
  estimatedYearlySavingsCost: number;
  title: string;
  content: string;
  status: AdviceStatus;
  link: { url: string; text: string };
  activatedAt: string;
  updatedStatusAt: string;
}

export enum AdviceStatus {
  none = 'NONE',
  implemented = 'IMPLEMENTED',
  skipped = 'SKIPPED',
  all = '',
}
