<div
  [class]="'alert--' + _alert?.type + ' ' + (options?.template && 'alert--' + options.template)"
  [class.flat]="flatDesign"
  [ngClass]="{ alert: true, 'before:!top-1/2': _alert?.content?.centerAlertIcon }"
>
  <div class="flex justify-between items-center gap-x-8 gap-y-8 md:gap-y-0 flex-col md:flex-row">
    <div>
      <div class="content">
        <strong *ngIf="_alert?.content?.title" class="alert__title inline-block">
          {{ _alert?.content?.title }}
        </strong>
        <div *ngIf="_alert?.content?.message" class="content" [class.mt-4]="_alert?.content?.title">
          <p [innerHTML]="_alert?.content?.message"></p>
        </div>
      </div>
    </div>
    <div class="flex items-center shrink-0 gap-x-2 sm:pr-24 md:pr-0" *ngIf="_alert?.content?.link">
      <a class="link text-primary font-bold" [routerLink]="_alert?.content?.link">
        {{ _alert?.content?.linkLabel }}
        <i class="icon-right pl-4"></i>
      </a>
      <a *ngIf="_alert?.content?.closeButton" (click)="closeAlertBox()" class="cursor-pointer">
        <img class="ml-3" [src]="'./assets/img/' + _alert?.type + '-close.svg'" alt="dropdowns_arrow" />
      </a>
    </div>
  </div>
</div>
