import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IBANvalidator } from '@app/shared/utils/utils.validators';
import { BillingDetails } from '../../../../models/billingDetails.interface';
import { ContactDetails } from '../../../../../contact/models/contactDetails.interface';
import { BillingService } from '../../../../services/billing/billing.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  bankTransfer,
  directDebit,
  directDebitAndBankTransfer,
  email,
  paper,
  zoomit,
} from '../../../../billing.constants';
import { ContactService } from '../../../../../contact/services/contact/contact.service';
import { Address } from '@app/shared/models/address.interface';
import { Subject, take, takeUntil } from 'rxjs';
// eslint-disable-next-line max-len
import { ConfirmationEmailSentComponent } from '../../../payment-method/components/change-payment-method/components/confirmation-email-sent/confirmation-email-sent.component';
import { SepaService } from '../../../../services/sepa/sepa.service';
import { MainFacade } from '@app/core/facade/main.facade';
import { AlertType } from '@app/shared/components/alert/alert.interface';

@Component({
  selector: 'app-change-invoice-method',
  templateUrl: './change-invoice-method.component.html',
  styleUrls: ['./change-invoice-method.component.scss'],
})
export class ChangeInvoiceMethodComponent implements OnInit, OnDestroy {
  @Input() billingDetails: BillingDetails;
  @Input() contactDetails: ContactDetails;
  @Input() reference: string;

  control: UntypedFormGroup;

  readonly email = email;
  readonly paper = paper;
  readonly zoomit = zoomit;
  readonly bankTransfer = bankTransfer;
  alertType = AlertType;

  public invoiceMethod: string;
  public invoiceMethods: string[] = [email, paper, zoomit];
  private notifier: Subject<void> = new Subject();

  constructor(
    private facade: MainFacade,
    private fb: UntypedFormBuilder,
    private billingService: BillingService,
    private contactService: ContactService,
    private toastrService: ToastrService,
    private translate: TranslateService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private sepaService: SepaService
  ) {}

  ngOnInit(): void {
    this.invoiceMethod = this.billingDetails.invoiceDeliveryChannel;

    this.control = this.fb.group({
      invoiceMethod: [this.billingDetails.invoiceDeliveryChannel, []],
      email: [this.contactDetails.email, []],
      address: this.fb.group({
        street: [this.contactDetails.address.street, []],
        streetNumber: [this.contactDetails.address.streetNumber, []],
        boxNumber: [this.contactDetails.address.boxNumber, []],
        zipCode: [this.contactDetails.address.zipCode, []],
        city: [this.contactDetails.address.city, []],
        country: [this.contactDetails.address.country, []],
      }),
      bankAccount: [
        {
          value: this.billingDetails.bankAccount,
          disabled: this.billingDetails.paymentMethod === directDebit,
        },
        [],
      ],
    });

    this.invoiceMethods = [email, paper, ...(this.facade.state$?.value?.accessRights?.showZoomit ? [zoomit] : [])];
    this.setValidators(this.invoiceMethod);
    this.control.get('invoiceMethod').valueChanges.subscribe((invoiceMethod) => this.setValidators(invoiceMethod));
  }

  handleSubmit() {
    const billingDetails = { invoiceDeliveryChannel: null };
    billingDetails.invoiceDeliveryChannel = this.control.get('invoiceMethod').value;

    if (billingDetails.invoiceDeliveryChannel === email) {
      billingDetails['email'] = this.control.get('email').value;
      this.updateBillingDetails(billingDetails);
      const emailChanged = this.control.get('email').value !== this.contactDetails.email;
      if (emailChanged) {
        this.updateEmail(this.control.get('email').value);
      }
    } else if (billingDetails.invoiceDeliveryChannel === paper) {
      const address = this.control.get('address').value;
      const currentAddress = this.contactDetails.address;
      billingDetails['address'] = address;
      this.updateBillingDetails(billingDetails);
      const addressChanged = Object.keys(address).some(
        (key) => !currentAddress.hasOwnProperty(key) || address[key] !== currentAddress[key]
      );
      if (addressChanged) {
        this.updateAddress(address);
      }
    } else if (billingDetails.invoiceDeliveryChannel === zoomit) {
      this.sepaService
        .sendSepaInformation(this.reference, {
          ...this.contactDetails,
          bankAccount: this.control.get('bankAccount').value.replace(/ /g, ''),
          directDebit: true,
          directDebitAndBankTransfer: this.billingDetails.paymentMethod === directDebitAndBankTransfer,
          zoomit: true,
        })
        .pipe(take(1))
        .subscribe(
          (response) => {
            this.billingDetails.paymentMethodLocked = true;
            const modalRef = this.modalService.open(ConfirmationEmailSentComponent, {
              size: 'lg',
            });
          },
          (error) => {
            this.toastrService.error(this.translate.instant('errorMessages.serverError'));
          }
        );
    }

    this.activeModal.close();
  }

  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
  }

  private updateBillingDetails(billingDetails) {
    this.billingService.updateBillingDetails(this.reference, billingDetails).subscribe(
      (response) => {
        this.toastrService.success(this.translate.instant('general.success'));
        this.billingDetails.invoiceSendingMethodLocked = true;
      },
      (error) => {
        this.toastrService.error(this.translate.instant('errorMessages.serverError'));
      }
    );
  }

  private updateEmail(newEmail) {
    const contactDetails = Object.assign({}, this.contactDetails);
    contactDetails.email = newEmail;
    this.contactService.updateContactDetails(this.reference, contactDetails).subscribe(
      (response) => {
        this.contactDetails.locked = true;
      },
      (error) => {
        this.toastrService.error(this.translate.instant('errorMessages.serverError'));
      }
    );
  }

  private updateAddress(address: Address) {
    const contactDetails = Object.assign({}, this.contactDetails);
    contactDetails.address = address;
    this.contactService
      .updateContactDetails(this.reference, contactDetails)
      .pipe(take(1))
      .subscribe(
        (response) => {
          this.contactDetails.locked = true;
        },
        (error) => {
          this.toastrService.error(this.translate.instant('errorMessages.serverError'));
        }
      );
  }

  private setEmailValidators() {
    this.control.get('email').setValidators([Validators.required, Validators.email]);
    Object.keys((this.control.get('address') as UntypedFormArray).controls).forEach((control) => {
      this.control.get(`address.${control}`).setValidators(null);
    });
    this.control.get('bankAccount').setValidators(null);
  }

  private setZoomitValidators() {
    this.control.get('bankAccount').setValidators([Validators.required, IBANvalidator()]);
    this.control.get('email').setValidators(null);
    Object.keys((this.control.get('address') as UntypedFormArray).controls).forEach((control) => {
      this.control.get(`address.${control}`).setValidators(null);
    });
  }

  private setPaperValidators() {
    this.control.get('email').setValidators(null);
    this.control.get('bankAccount').setValidators(null);
    this.control.get('address').get('street').setValidators([Validators.required]);
    this.control.get('address').get('streetNumber').setValidators([Validators.required]);
    this.control.get('address').get('zipCode').setValidators([Validators.required]);
    this.control.get('address').get('city').setValidators([Validators.required]);
  }

  private setValidators(invoiceMethod) {
    if (invoiceMethod === email) {
      this.setEmailValidators();
    } else if (invoiceMethod === paper) {
      this.setPaperValidators();
    } else if (invoiceMethod === zoomit) {
      this.setZoomitValidators();
    }

    this.control.get('email').updateValueAndValidity();
    this.control.get('address').updateValueAndValidity();
    Object.keys((this.control.get('address') as UntypedFormArray).controls).forEach((control) => {
      this.control.get(`address.${control}`).updateValueAndValidity();
    });
    this.control.get('bankAccount').updateValueAndValidity();
  }
}
