<app-modal title="{{ 'components.contact.changeEmail.title' | translate }}">
  <div class="change-email">
    <h5 class="text-grey-dark font-medium text-2xl my-8">
      {{ 'components.contact.changeEmail.intro' | translate }}
    </h5>

    <form [formGroup]="emailForm" novalidate (ngSubmit)="handleSubmit()">
      <div
        class="form-element input-text mb-4"
        [class.errors]="
          (!emailForm.get('email').pristine && emailForm.get('email').invalid) ||
          emailForm.get('emailConfirmation').errors?.emailNotIdentical
        "
      >
        <label for="input-email">
          {{ 'general.email' | translate }}
        </label>
        <input [formControl]="emailForm.controls.email" id="input-email" name="email" type="email" autocomplete="off" />
        <div class="message">
          <div *ngIf="emailForm.get('email').errors?.email">{{ 'errorMessages.invalidEmail' | translate }}</div>
          <div *ngIf="emailForm.get('email').errors?.required">{{ 'errorMessages.required' | translate }}</div>
        </div>
      </div>
      <div
        class="form-element input-text mb-4"
        [class.errors]="emailForm.get('emailConfirmation').errors?.emailNotIdentical"
      >
        <label for="input-email-confirmation">
          {{ 'general.emailConfirmation' | translate }}
        </label>
        <input
          [formControl]="emailForm.controls.emailConfirmation"
          id="input-email-confirmation"
          name="emailConfirmation"
          type="email"
          autocomplete="off"
        />
        <div class="message">
          <div *ngIf="emailForm.get('emailConfirmation').errors?.emailNotIdentical">
            {{ 'errorMessages.emailNotIdentical' | translate }}
          </div>
        </div>
      </div>
      <div class="md:flex md:justify-center gap-8 mt-4 md:mt-8">
        <button
          class="btn btn-primary w-full"
          [attr.disabled]="!emailForm.valid || emailForm.pristine ? '' : null"
          [class.not-submittable]="!emailForm.valid || emailForm.pristine"
          [analyticsEvent]="'emailChange:click:update-email'"
          (click)="handleSubmit()"
        >
          {{ 'general.update' | translate }}
        </button>
        <button
          type="button"
          class="btn btn-secondary w-full mt-8 md:mt-0"
          (click)="activeModal.close()"
          [analyticsEvent]="'emailChange:click:cancel-email-update'"
        >
          {{ 'general.cancel' | translate }}
        </button>
      </div>
    </form>
  </div>
</app-modal>
