import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Consent } from '../../../modules/customer-zone/user/models/consents.interface';

@Component({
  selector: 'app-consent-checkbox',
  templateUrl: './consent-checkbox.component.html',
  styleUrls: ['./consent-checkbox.component.scss'],
})
export class ConsentCheckboxComponent implements OnInit {
  @Input() consent: Consent;
  @Input() parentForm: UntypedFormGroup;

  constructor() {}

  ngOnInit(): void {}

  public updateValue(formControl: AbstractControl) {
    if (formControl.value !== 'undefined') {
      const oppositeValue = (!(formControl.value === 'true')).toString();
      formControl.setValue(oppositeValue);
      formControl.markAsDirty();
    }
  }
}
