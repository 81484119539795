import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'te-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.scss'],
})
export class SwitcherComponent implements OnInit {
  @Input() options: string[];
  @Input() active: string;
  @Input() translationPrefix = '';
  @Input() isMobile? = false;

  @Output() switchAction: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  switch(option: string) {
    this.switchAction.emit(option);
  }
}
