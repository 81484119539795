<section class="mb-12 pb-8 border-b border-grey">
  <h3 class="text-2xl mb-4 md:text-3xl mb-8">{{ 'components.paymentPlan.overview.upcomingPayment.title' | translate }}</h3>
  <div class="block md:flex flex-row items-center">
    <div class="min-w-[40%]">
        <span class="next-slice-amount text-5xl font-display font-normal te-text-gradient-home inline-block">
          {{ upcomingPayment?.amount | currency: 'EUR' }}
        </span>
      <div class="text-sm">
        <span class="text-grey-dark60 inline-block w-full">
          {{ 'components.paymentPlan.overview.upcomingPayment.' + (payWithDirectDebit ? 'payViaDirectDebit' : 'payViaBankTransfer') | translate }}
        </span>
        <span class="font-medium inline-block w-full">
          {{ upcomingPayment?.dueDate | date: 'dd.MM.yyyy':undefined: facade.translate.currentLang + '-BE' }}
        </span>
      </div>
    </div>
    <ul *ngIf="!payWithDirectDebit" class="bullet-list mt-8 md:mt-0">
      <li class="mb-2">
        <strong class="font-medium">{{ 'components.paymentPlan.overview.upcomingPayment.iban' | translate }}</strong>
        {{ 'general.companyIBAN' | translate }}
      </li>
      <li class="">
        <strong class="font-medium">{{ 'components.paymentPlan.overview.upcomingPayment.structuredCommunication' | translate }}</strong>
        <span class="font-medium inline-block w-full text-primary">{{ structuredCommunication }}</span>
      </li>
    </ul>
    <div *ngIf="payWithDirectDebit" class="mt-8 bg-grey-light rounded-4xl p-8 md:mt-0">
      <span class="inline-block w-full font-medium">{{ 'components.paymentPlan.overview.upcomingPayment.paymentAccount' | translate }}</span>
      <span class="font-medium">{{ 'components.paymentPlan.overview.upcomingPayment.iban' | translate }}</span> {{ 'general.companyIBAN' | translate }}
    </div>
  </div>
</section>
