<ng-container *ngIf="data?.contract$ | async as contract">
  <app-modal *ngIf="contract?.loading; else dataOrError" title="{{ 'general.loader' | translate | titlecase }}">
    {{ 'general.loader' | translate }}…
  </app-modal>
  <ng-template #dataOrError>
    <app-modal
      *ngIf="contract?.data; else error"
      title="{{ 'components.contracts.modal.title.' + contract?.data?.type | translate }}"
      [icon]="{ svgName: contract?.data?.type | lowercase }"
    >
      <div class="mt-8 mb-8">
        <span class="font-bold mb-8 inline-block w-full">
          {{ 'components.contracts.modal.productDetails' | translate }}
        </span>

        <div class="border border-gray-400 rounded-4xl">
          <div *ngIf="data.site$ | async as site" class="flex flex-wrap flex-col md:flex-row">
            <div class="row__title">
              {{ 'components.contracts.modal.deliveryAddress' | translate }}
            </div>
            <div class="row__content">
              <app-site-address [site]="site"></app-site-address>
            </div>
          </div>

          <div class="row">
            <div class="row__title">
              {{ 'components.contracts.modal.product' | translate }}
            </div>
            <div class="row__content">
              <ng-container *ngIf="!isSocial(contract?.data)">
                <ng-container *ngIf="isProductGasOrElec(contract?.data)">
                  <img
                    *ngIf="contract?.data?.bundleDetails?.picture; else noPicture"
                    height="35"
                    class="max-h-14"
                    [src]="contract?.data?.bundleDetails?.picture"
                    alt="{{ contract?.data?.name | lowercase }}"
                  />
                  <ng-template #noPicture>
                    <div class="font-display font-semibold text-3xl te-text-gradient-home inline-block max-h-14">
                      {{ contract?.data?.name }}
                    </div>
                  </ng-template>
                  <app-tooltip
                    *ngIf="
                      ('components.contracts.products_description.' + (contract?.data?.name | lowercase) + '.help'
                        | translate) !== ''
                    "
                    [tooltip]="
                      'components.contracts.products_description.' + (contract?.data?.name | lowercase) + '.help'
                        | translate
                    "
                    class="absolute right-4 -top-16 md:top-4"
                  >
                  </app-tooltip>
                  <div
                    class="w-full mt-4 list-disc--wrapper"
                    [innerHTML]="contract?.data?.bundleDetails?.description[facade?.translate?.currentLang]"
                  ></div>
                </ng-container>

                <ng-container *ngIf="!isProductGasOrElec(contract?.data)">
                  <ng-container *ngIf="contract?.data?.i18n">
                    <span> {{ contract?.data?.i18n[facade.translate.currentLang] }} </span>

                    <app-tooltip
                      *ngIf="contract?.data?.type !== 'ASSISTANCE'"
                      [tooltip]="'components.contracts.products_description.' + contract?.data?.type | translate"
                      class="absolute right-4 -top-16 md:top-4"
                    >
                    </app-tooltip>
                    <app-tooltip
                      *ngIf="contract?.data?.type === 'ASSISTANCE'"
                      [tooltip]="'components.contracts.products_description.' + contract?.data?.name | translate"
                      class="absolute right-4 -top-16 md:top-4"
                    >
                    </app-tooltip>
                  </ng-container>
                </ng-container>
              </ng-container>
              <div *ngIf="isSocial(contract?.data)">{{ 'components.contracts.modal.socialTariff' | translate }}</div>
            </div>
          </div>

          <div *ngIf="contract?.data?.tariff" class="row">
            <div class="row__title">
              {{ 'components.contracts.modal.price' | translate }}
              <span *ngIf="contract?.data?.deliveryPointReference">{{
                'components.contracts.modal.noVat' | translate
              }}</span>
            </div>
            <div class="row__content">
              <div *ngIf="!isSocial(contract?.data)">
                <div *ngIf="contract?.data?.tariff.type === 'VARIABLE'; else fixe">
                  {{ 'components.contracts.contract.tariff_type.variable' | translate }}
                </div>
                <ng-template #fixe>
                  <div *ngFor="let name of tarriffNames">
                    <div *ngIf="contract?.data?.tariff[name]">
                      <span *ngIf="name !== 'mono'">
                        {{ 'components.contracts.modal.tariffNames.' + name | translate }}
                      </span>
                      {{ contract?.data?.tariff[name] }} c&euro;/kWh
                    </div>
                  </div>
                </ng-template>
              </div>
              <div *ngIf="isSocial(contract?.data)">
                <a
                  target="_blank"
                  href="{{
                    'components.contracts.modal.socialTariffGrid.' + (contract?.data?.type | lowercase) | translate
                  }}"
                >
                  {{ 'components.contracts.modal.socialTariff' | translate }}
                </a>
              </div>
              <app-tooltip
                [tooltip]="'components.contracts.prices.' + (contract?.data?.tariff.type | lowercase) | translate"
                class="absolute right-4 -top-16 md:top-4"
              ></app-tooltip>
            </div>
          </div>

          <div
            *ngIf="contract?.data?.injectionTariff && (contract?.data?.injectionTariff | keyvalue)?.length"
            class="row"
          >
            <div class="row__title">
              {{ 'components.contracts.modal.injectionPrice' | translate }}
              <span *ngIf="contract?.data?.deliveryPointReference">{{
                'components.contracts.modal.exemptVat' | translate
              }}</span>
            </div>
            <div class="row__content">
              <div>
                <div *ngFor="let name of tarriffNames">
                  <div *ngIf="contract?.data?.injectionTariff[name] && name !== 'nightTimeOnly'">
                    <span *ngIf="name !== 'mono'">
                      {{ 'components.contracts.modal.tariffNames.' + name | translate }}
                    </span>
                    {{ contract?.data?.injectionTariff[name] }} c&euro;/kWh
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="contract?.data?.subscription" class="row">
            <div class="row__title">
              {{ 'components.contracts.modal.subscription' | translate }}
              <span *ngIf="contract?.data?.deliveryPointReference">
                {{ 'components.contracts.modal.noVat' | translate }}
              </span>
            </div>
            <div class="row__content">
              <div *ngIf="!isSocial(contract.data)">{{ contract?.data?.subscription | currency : 'EUR' }}</div>
              <div *ngIf="isSocial(contract.data)">{{ 'components.contracts.modal.socialTariff' | translate }}</div>
            </div>
          </div>

          <div class="row">
            <div class="row__title">
              {{ 'components.contracts.modal.duration' | translate }}
            </div>
            <div class="row__content">
              <div *ngIf="contract?.data?.indeterminateDuration">
                {{ 'components.contracts.duration.indeterminate' | translate }}
              </div>
              <ng-container *ngIf="!contract?.data?.indeterminateDuration">
                <div *ngIf="!isSocial(contract.data)">
                  <span *ngIf="contract?.data?.startDate">
                    {{ 'general.from' | translate | titlecase }} {{ contract?.data?.startDate | date : 'dd/MM/yyyy' }}
                  </span>
                  <span *ngIf="contract?.data?.forseenEndDate">
                    {{ 'general.until' | translate }} {{ contract?.data?.forseenEndDate | date : 'dd/MM/yyyy' }}
                  </span>
                </div>
                <div *ngIf="isSocial(contract?.data)">{{ 'components.contracts.duration.social' | translate }}</div>
                <app-tooltip
                  [tooltip]="'components.contracts.duration.help' | translate"
                  class="absolute right-4 -top-16 md:top-4"
                ></app-tooltip>
              </ng-container>
            </div>
          </div>

          <div *ngIf="contract?.data?.promotion?.name" class="row">
            <div class="row__title">
              {{ 'components.contracts.modal.promotion' | translate }}
            </div>
            <div class="row__content">
              <div class="text-primary mb-4">{{ contract?.data?.promotion.name }}</div>
              <div *ngIf="(contract?.data?.promotion.endDate | date : 'yyyy') === '9999'; else date">
                {{ 'components.contracts.promotion.unlimited' | translate }}
              </div>
              <ng-template #date> {{ contract?.data?.promotion.endDate | date : 'dd/MM/yyyy' }} </ng-template>
            </div>
          </div>

          <div *ngIf="contract?.data?.deliveryPointReference" class="row">
            <div class="row__title">
              {{ 'components.contracts.modal.eanCode' | translate }}
            </div>
            <div class="row__content">
              {{ contract?.data?.deliveryPointReference }}
            </div>
          </div>

          <div *ngIf="contract?.data?.meterNumber && contract?.data?.meterNumber !== 'null'" class="row">
            <div class="row__title">
              {{ 'components.contracts.modal.counterNumber' | translate }}
            </div>
            <div class="row__content">
              {{ contract?.data?.meterNumber }}
            </div>
          </div>

          <ng-container *accessControl="accessRight.viewTariffCard">
            <div *ngIf="contract?.data?.tariffCards && contract?.data?.tariffCards?.length" class="row">
              <div class="row__title">
                {{ 'components.contracts.contract.title.PricingGrid' | translate }}
              </div>
              <div class="row__content">
                <a
                  *ngFor="let tariffCard of contract?.data?.tariffCards"
                  class="font-bold text-sm text-primary cursor-pointer"
                  href="{{ tariffCard?.url }}"
                  target="_blank"
                  [analyticsEvent]="'contract:click:open-pricing-grid'"
                >
                  {{ 'components.contracts.contract.links.downloadPricingGrid.' + tariffCard?.type | translate }}
                  <img src="./assets/img/icons/standalone/red/download_pdf.svg" class="download_pdf_icon" />
                </a>
              </div>
            </div>
          </ng-container>
        </div>

        <span
          (click)="goToContractsPage()"
          class="btn-text font-bold inline-block w-full text-center mt-8 text-primary cursor-pointer"
          [analyticsEvent]="'contracts:click:go-to-contracts-page'"
        >
          {{ 'components.contracts.viewAll' | translate }}
        </span>
      </div>
    </app-modal>
    <ng-template #error>
      <app-modal title="{{ 'errorMessages.error' | translate | titlecase }}">
        {{ 'errorMessages.serverError' | translate }}
      </app-modal>
    </ng-template>
  </ng-template>
</ng-container>
