import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs';
import { Subject } from 'rxjs';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { Index } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { MainFacade } from '@app/core/facade/main.facade';
import { IndexModalComponent } from '@app/modules/customer-zone/consumption/components/modal/index-modal/index-modal.component';

@Component({
  selector: 'app-encode-index-cta',
  templateUrl: './encode-index-cta.component.html',
  styleUrls: ['./encode-index-cta.component.scss'],
})
export class EncodeIndexCtaComponent implements OnInit, OnDestroy {
  @Input() version?: string = EncodeIndexCtaVersion.box;
  @Output() showBoxEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  public encodeIndexCtaVersion = EncodeIndexCtaVersion;
  public copy: { title: string; content: string; button: string };
  public notifier = new Subject<void>();
  public showEncodingBox = false;

  constructor(private facade: MainFacade, private modalService: NgbModal) {}

  ngOnInit(): void {
    this.setCopy();
    this.facade.translate.onLangChange.pipe(takeUntil(this.notifier)).subscribe(() => this.setCopy());
    this.facade
      .loadAllMeterings()
      .pipe(takeUntil(this.notifier))
      .subscribe((meterings: Index[][]) => {
        this.showEncodingBox = meterings.some((metering: Index[]) => metering.length !== 0);
        this.showBoxEmitter.emit(this.showEncodingBox);
      });
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }

  openPopup() {
    const modalRef = this.modalService.open(IndexModalComponent, {
      size: 'auto',
    });
  }

  private setCopy() {
    this.copy = {
      title: this.facade.translate.instant(`components.encodeIndexCTA.${this.version.toLowerCase()}.title`),
      content: this.facade.translate.instant(`components.encodeIndexCTA.${this.version.toLowerCase()}.content`),
      button: this.facade.translate.instant(`components.encodeIndexCTA.${this.version.toLowerCase()}.button`),
    };
  }
}

export enum EncodeIndexCtaVersion {
  inline = 'INLINE',
  box = 'BOX',
}

_(`components.encodeIndexCTA.inline.title`);
_(`components.encodeIndexCTA.inline.content`);
_(`components.encodeIndexCTA.inline.button`);
_(`components.encodeIndexCTA.box.title`);
_(`components.encodeIndexCTA.box.content`);
_(`components.encodeIndexCTA.box.button`);
