import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import {
  ChartPoint,
  CustomChartDataset,
  DoughnutChartData,
  FontColorDoughnutOptions,
  TooltipObject,
} from '../../models/chart.interfaces';
import { ChartColor, ChartUnit, CtxPosition, TextRowOffset } from '../../models/chart.enums';
import Utils from '../../utils/utils';
import { ArcElement, ChartData, ChartOptions, LegendItem, Plugin } from 'chart.js';
import Chart from 'chart.js/auto';
import { ChartTooltipHelperService } from '@app/modules/chart/services/chart-tooltip-helper.service';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss'],
})
export class DoughnutChartComponent implements OnChanges {
  @Input() doughnutChartDataset: CustomChartDataset<DoughnutChartData>;
  @Input() styleClass: string;

  data: ChartData<'doughnut', DoughnutChartData[]>;
  plugins: Plugin<'doughnut', DoughnutChartData>[] = [];
  options: ChartOptions<'doughnut'>;
  images: HTMLImageElement[] = [];

  constructor(private tooltipHelperService: ChartTooltipHelperService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.doughnutChartDataset?.currentValue) {
      this.setChartData();
      this.setChartOptions();
      this.setChartPlugins();
    }
  }

  private setChartData(): void {
    const generateColors = () => {
      return this.doughnutChartDataset?.data.map((data: DoughnutChartData) => data.color);
    };
    const generateFontColors = () => {
      return this.doughnutChartDataset?.data.map((data: DoughnutChartData) => data.fontColor);
    };
    const generateLabels = () => {
      return this.doughnutChartDataset?.data.map((data: DoughnutChartData) => data.label);
    };

    const fontColorDoughnutOptions: FontColorDoughnutOptions = {
      fontColors: generateFontColors(),
    };

    this.data = {
      labels: generateLabels(),
      datasets: [
        {
          data: this.doughnutChartDataset.data,
          backgroundColor: generateColors(),
          borderColor: [ChartColor.white],
          borderWidth: 1,
          ...fontColorDoughnutOptions,
        },
      ],
    };
  }

  private setChartOptions(): void {
    this.options = {
      maintainAspectRatio: false,
      parsing: {
        key: 'value',
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          //padding: 20,
          external: (tooltipObject: TooltipObject<'doughnut'>) =>
            this.tooltipHelperService.createTooltip(
              tooltipObject,
              this.tooltipHelperService.getDoughnutChartTemplate(tooltipObject.tooltip),
              'doughnut'
            ),
        },
      },
      layout: {
        padding: {
          top: 30,
          bottom: 30,
        },
      },
    };
  }

  private setChartPlugins(): void {
    this.plugins = [
      {
        id: 'doughnutSlicesWithTextAndImage',
        beforeDraw: (chart: Chart) => {
          const thicknessRatio = 1.05;

          // get normal outer radius slice index
          const normalSliceRadiusIndex = chart?.config?.data?.datasets[0]?.data?.findIndex((data: any) => !data?.image);

          // calculate larger slice outer radius from multiplication normal slice outer radius and thickness ratio
          const largerSliceRadius =
            (chart.getDatasetMeta(0).data[normalSliceRadiusIndex === -1 ? 0 : normalSliceRadiusIndex] as any)
              .outerRadius * (normalSliceRadiusIndex === -1 ? 1 : thicknessRatio);

          chart?.config?.data?.datasets[0]?.data?.forEach((data: any, index: number) => {
            if (data.image) {
              (chart.getDatasetMeta(0).data[index] as any).outerRadius = largerSliceRadius;
            }
          });
          return true;
        },
        afterDraw: (chart: any) => {
          const ctx = chart.ctx;
          ctx.save();

          const leftLabelCoordinates: number[] = [];
          const rightLabelCoordinates: number[] = [];
          const chartCenterPoint = {
            x: (chart.chartArea.right - chart.chartArea.left) / 2 + chart.chartArea.left,
            y: (chart.chartArea.bottom - chart.chartArea.top) / 2 + chart.chartArea.top,
          };
          chart.config.data.labels.forEach((label: string, index: number) => {
            const meta = chart.getDatasetMeta(0);
            const arc = meta.data[index] as ArcElement;
            const dataset = chart.config.data.datasets[0];
            const data = dataset.data[index];

            // don't show low percentage value, because of overlapping issue possibility
            if (data.value < 1 || !data.image) {
              return;
            }

            const backgroundColor = arc.options.backgroundColor;
            const fontColor = dataset.fontColors ? dataset.fontColors[index] : backgroundColor;
            let labelColor = arc.options.borderColor;
            let color = backgroundColor;
            if (dataset.polyline && dataset.polyline.color) {
              color = dataset.polyline.color;
            }

            if (dataset.polyline && dataset.polyline.labelColor) {
              labelColor = dataset.polyline.labelColor;
            }

            const centerPoint = arc.getCenterPoint(false);
            const angle = Math.atan2(centerPoint.y - chartCenterPoint.y, centerPoint.x - chartCenterPoint.x);

            // intersection point on the outer radius of doughnut graph
            const intersectionPoint: ChartPoint = {
              x: chartCenterPoint.x + Math.cos(angle) * arc.outerRadius,
              y: chartCenterPoint.y + Math.sin(angle) * arc.outerRadius,
            };

            // start point of the line
            const startPoint: ChartPoint = {
              x: chartCenterPoint.x + Math.cos(angle) * (arc.outerRadius + 4),
              y: chartCenterPoint.y + Math.sin(angle) * (arc.outerRadius + 4),
            };

            // calculate line scalable constant by radius distance
            let lineScalableRatio = (arc.outerRadius - arc.innerRadius) / 3;
            // end point of the line
            const endPoint: ChartPoint = {
              x: chartCenterPoint.x + Math.cos(angle) * (arc.outerRadius + lineScalableRatio),
              y: chartCenterPoint.y + Math.sin(angle) * (arc.outerRadius + lineScalableRatio),
            };

            if (endPoint.x < chartCenterPoint.x) {
              leftLabelCoordinates.push(endPoint.y);
            } else {
              rightLabelCoordinates.push(endPoint.y);
            }

            if (data.image && data.value >= 15) {
              const { image, freshlyCached } = this.getCachedImage(data.image);
              const drawImage = () => {
                let imageScalableRatio = (arc.outerRadius - arc.innerRadius) / image.width / 2;
                const { x, y } = arc.tooltipPosition(false);
                const imageWidth = image.width * imageScalableRatio;
                const imageHeight = image.height * imageScalableRatio;
                ctx.drawImage(image, x - imageWidth / 2, y - imageHeight / 2, imageWidth, imageHeight);
              };
              if (freshlyCached) {
                image.onload = () => drawImage();
              } else {
                drawImage();
              }
            }

            // draw line
            ctx.strokeStyle = backgroundColor;
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(startPoint.x, startPoint.y);
            ctx.lineTo(endPoint.x, endPoint.y);
            ctx.stroke();

            const textPoint: ChartPoint = {
              x: endPoint.x,
              y: endPoint.y,
            };

            // draw text
            let labelRowCount = 1;
            let labelAlignStyle = CtxPosition.left;

            if (intersectionPoint.x < endPoint.x) {
              textPoint.x += 5;
            } else {
              textPoint.x -= 5;
              labelAlignStyle = CtxPosition.right;
            }

            ctx.fillStyle = fontColor;

            const fontSize = 12;
            let rowOffset = 0;
            ctx.font = Utils.getCtxFont(fontSize, true);

            if (data.type) {
              ctx.textBaseline = CtxPosition.middle;
              ctx.textAlign = labelAlignStyle;

              const label: string = data.value + ChartUnit.percentage + (data.unitSeparator || '') + data.label;
              const textWidth = ctx.measureText(label).width;
              if (
                (textPoint.x < centerPoint.x && textPoint.x - textWidth >= chart.chartArea.left) ||
                (textPoint.x > centerPoint.x && textPoint.x + textWidth <= chart.chartArea.right)
              ) {
                // One line
                ctx.fillText(label, textPoint.x, textPoint.y);
              } else {
                if (intersectionPoint.y < endPoint.y) {
                  textPoint.y += 10;
                } else {
                  textPoint.y -= labelRowCount * 10 + 10;
                }

                // percentage value on the top
                if (intersectionPoint.y < endPoint.y) {
                  ctx.fillText(
                    data.value + (data.unitSeparator || '') + ChartUnit.percentage,
                    textPoint.x,
                    textPoint.y + rowOffset
                  );
                  rowOffset += TextRowOffset;
                }

                ctx.font = Utils.getCtxFont(fontSize, true);

                // consumption type
                const textWidth = ctx.measureText(data.label).width;
                const offsetLabel = textPoint.x < centerPoint.x ? textWidth / 3 : -textWidth / 3;
                ctx.fillText(data.label, textPoint.x + offsetLabel, textPoint.y + rowOffset);
                rowOffset += TextRowOffset;

                // percentage value on the bottom
                if (intersectionPoint.y > endPoint.y) {
                  ctx.font = Utils.getCtxFont(fontSize, true);
                  ctx.fillText(data.value + ChartUnit.percentage, textPoint.x, textPoint.y + rowOffset);
                }
              }
            }
          });
          ctx.restore();
        },
      },
    ];
  }

  public getCachedImage(path: string) {
    const index = this.images.findIndex((image: HTMLImageElement) => image.src.includes(path.slice(1)));

    if (index === -1) {
      const image = new Image();
      image.src = path;
      this.images.push(image);

      return { image, freshlyCached: true };
    }

    return { image: this.images[index], freshlyCached: false };
  }
}
