import { Component, Input, OnInit } from '@angular/core';
import { ContactDetails, Countries } from '../../../../../contact/models/contactDetails.interface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangeEmailComponent } from '../../../../../contact/components/change-email/change-email.component';
import { BillingDetails } from '../../../../models/billingDetails.interface';
import { AccessRight } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { ChangeInvoiceMethodComponent } from '../change-invoice-method/change-invoice-method.component';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
})
export class EmailComponent implements OnInit {
  @Input() contactDetails: ContactDetails;
  @Input() billingDetails: BillingDetails;
  @Input() reference: string;
  public accessRight = AccessRight;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  public changeEmail() {
    const modalRef = this.modalService.open(ChangeEmailComponent, {
      size: 'sm',
    });

    modalRef.componentInstance.contactDetails = this.contactDetails;
    modalRef.componentInstance.reference = this.reference;
  }

  changeInvoiceMethod() {
    const modalRef = this.modalService.open(ChangeInvoiceMethodComponent, {
      size: 'lg',
    });

    modalRef.componentInstance.billingDetails = this.billingDetails;
    modalRef.componentInstance.contactDetails = this.contactDetails;
    modalRef.componentInstance.reference = this.reference;
  }
}
