<ng-container
  *ngIf="invoice?.paymentLink && billingDetails?.paymentMethod === PaymentMethod.BANK_TRANSFER"
>
  <button
    *ngIf="!linkStyle"
    class="{{ btnStyle ? 'btn btn-primary font-bold h-fit sm:w-80' : 'btn-text font-medium text-primary' }} inline-block w-full {{ 'text-' + alignment }}"
    (click)="goToOnlinePayment(invoice)"
    [analyticsEvent]="'invoices:click:pay-online'"
  >
    {{ 'pages.dashboard.paymentBalance.paymentInformation.payNow' | translate }}
  </button>
  <span
    *ngIf="linkStyle"
    class="inline-block text-primary"
    (click)="goToOnlinePayment(invoice)"
    [analyticsEvent]="'invoices:click:pay-online'"
  >
    {{ 'components.invoices.payNow' | translate }}
  </span>
</ng-container>
