import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Site } from '../../../../user/models/site.interface';
import { Observable } from 'rxjs';
import { ModalComponent } from 'src/app/shared/components/modal/modal.component';
import { AccessRight } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { MainFacade } from '../../../../../../core/facade/main.facade';
import { AsyncData } from '../../../../../../shared/models/async.interface';
import { ContractDtoCuzoApi, ContractDtoTypeEnumCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Component({
  selector: 'app-contract-detail',
  templateUrl: './contract-detail.component.html',
  styleUrls: ['./contract-detail.component.scss'],
})
export class ContractDetailComponent implements OnInit {
  @Input() data: { contract$: Observable<AsyncData<ContractDtoCuzoApi>>; site$: Observable<Site> };
  @ViewChild(ModalComponent) modal: ModalComponent;
  public tarriffNames: string[] = ['peak', 'offPeak', 'nightTimeOnly', 'mono'];
  readonly accessRight = AccessRight;

  constructor(public facade: MainFacade) {}

  ngOnInit() {
    this.facade.ignoreLoadingForSingleRequest(true);
  }

  isSocial(contract) {
    return this.isProductGasOrElec(contract) && contract.tariff.type === 'SOCIAL';
  }

  isProductGasOrElec(contract: ContractDtoCuzoApi) {
    return (
      ContractDtoTypeEnumCuzoApi[ContractDtoTypeEnumCuzoApi.GAS] === contract.type ||
      ContractDtoTypeEnumCuzoApi[ContractDtoTypeEnumCuzoApi.ELECTRICITY] === contract.type
    );
  }
  goToContractsPage() {
    this.modal.activeModal.close({ alert: false, update: false, error: false });
    this.facade.proxyRouter.navigate([`/contracts`]);
  }
}
