<ul class="nav-tabs tabs mb-16" ngbNav #nav="ngbNav" [(activeId)]="active">
  <li *ngFor="let index of indexes; let i = index" [ngbNavItem]="i + 1" class="tab__item">
    <a class="tab__link" ngbNavLink>{{ 'general.product.' + index.energy | lowercase | translate }}</a>
    <ng-template ngbNavContent>
      <div>
        <app-estimated-index-detail [estimatedIndex]="index"></app-estimated-index-detail>
      </div>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
