<div class="bg-white">
  <ng-container *ngIf="discount$ | async as discount">
    <div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
      <div class="flex flex-col-reverse md:flex-row md:items-center">
        <div class="mt-8 md:m-8 md:flex md:flex-col md:items-left md:px-20 xl:m-0 xl:pl-0">
          <h1
            class="font-display font-normal text-4xl md:text-5xl leading-snug te-text-gradient-home mb-4"
            [innerHTML]="'pages.mgm.intro.title' | translate: { discount: discount.promoDiscount } | nl2br"
          ></h1>
          <p class="font-normal text-base">
            {{ 'pages.mgm.intro.content' | translate: { discount: discount.promoDiscount, maxGodchild: yearlyMax, maxDiscount: maxDiscount } }}
          </p>
        </div>
        <div class="">
          <img class="min-w-[25vw]" src="./assets/img/mgm/friends-dinner.jpg" alt="Mgm banner illustration" />
        </div>
      </div>
    </div>

    <div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
      <div class="bg-white w-full mt-8 md:m-8 md:max-w-7xl md:mx-auto">
        <mgm-share-tool [reference]="reference" [discount]="discount"> </mgm-share-tool>

        <div
          class="font-normal text-xs mt-8"
          [innerHTML]="
            '*' +
            ('pages.mgm.disclaimer'
              | translate: { discount: discount.promoDiscount, date: discount.promoEndDate | date: 'dd.MM.yyyy' })
          "
        ></div>
      </div>
    </div>
  </ng-container>

  <div class="">
    <div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto" *ngIf="sponsorship$ | async as sponsorship">
      <mgm-content-info
        *ngIf="sponsorship.totalRecipients === 0"
        [discount]="discount$ | async"
        [yearlyMax]="yearlyMax"
        [maxDiscount]="maxDiscount"
      >
      </mgm-content-info>
      <mgm-sponsor-info
        *ngIf="sponsorship.totalRecipients > 0"
        [reference]="reference"
        [sponsorShip]="sponsorship"
        [yearlyMax]="yearlyMax"
      >
      </mgm-sponsor-info>
    </div>
  </div>

  <div class="faq bg-grey-light">
    <div class="container pt-6 md:max-w-6xl">
      <app-faq [tag]="'my-lampiris-mgm'" [title]="'pages.mgm.faqTitle' | translate"></app-faq>
    </div>
  </div>
</div>
