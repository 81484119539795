<nav>
  <ol>
    <li
      *ngFor="let step of steps; let i = index"
      class="relative pb-8"
    >
      <ng-container
        *ngTemplateOutlet="getTemplate(stepsStatus[i]); context: {
          step: step,
          lastStep: i === stepsStatus.length - 1
        }">
      </ng-container>
    </li>
  </ol>
</nav>

<ng-template #none let-step="step" let-lastStep="lastStep">
  <div *ngIf="!lastStep" aria-hidden="true" class="w-[2px] bg-grey h-full mt-1 absolute top-5 left-[11px]"></div>
  <div class="relative flex items-center">
    <span class="flex h-12 border-y-[3px] border-grey-light items-center">
      <span class="border-grey border-2 bg-grey-light w-10 h-10 flex relative z-10 justify-center rounded-full"></span>
    </span>
    <span class="ml-4 text-grey-dark text-sm"> {{ 'components.move.dashboard.statusProgress.' + step | translate }} </span>
  </div>
</ng-template>

<ng-template #missing let-step="step" let-lastStep="lastStep">
  <div *ngIf="!lastStep" aria-hidden="true" class="w-[2px] bg-grey h-full mt-1 absolute top-5 left-[11px]"></div>
  <div class="relative flex items-center">
    <span class="flex h-12 border-y-[3px] border-grey-light items-center">
      <span class="border-orange bg-orange items-center border-2 w-10 h-10 flex relative z-10 justify-center rounded-full">
        <svg class="-mt-0.5" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1761 12.1231L7.00002 1.87712L1.82395 12.1231L12.1761 12.1231ZM8.04136 1.35106C7.60986 0.496915 6.39019 0.496913 5.95869 1.35105L0.782619 11.597C0.390604 12.373 0.954562 13.2898 1.82395 13.2898H12.1761C13.0455 13.2898 13.6094 12.373 13.2174 11.597L8.04136 1.35106Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6.50457 10.3731C6.50457 10.0992 6.72656 9.87725 7.0004 9.87725H7.00623C7.28008 9.87725 7.50207 10.0992 7.50207 10.3731C7.50207 10.6469 7.28008 10.8689 7.00623 10.8689H7.0004C6.72656 10.8689 6.50457 10.6469 6.50457 10.3731Z" fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.0004 5.21059C7.27424 5.21059 7.49623 5.43258 7.49623 5.70642V8.03975C7.49623 8.31359 7.27424 8.53559 7.0004 8.53559C6.72656 8.53559 6.50457 8.31359 6.50457 8.03975V5.70642C6.50457 5.43258 6.72656 5.21059 7.0004 5.21059Z" fill="white"/>
        </svg>
      </span>
    </span>
    <span class="ml-4 text-orange text-sm"> {{ 'components.move.dashboard.statusProgress.missingInfo' | translate }} </span>
  </div>
</ng-template>

<ng-template #ongoing let-step="step" let-lastStep="lastStep">
  <div *ngIf="!lastStep" aria-hidden="true" class="w-[2px] bg-grey h-full mt-1 absolute top-5 left-[11px]"></div>
  <div class="relative flex items-center">
    <span class="flex h-12 border-y-[3px] border-grey-light items-center">
      <span class="border-blue-dark bg-blue-dark items-center border-2 w-10 h-10 flex relative z-10 justify-center rounded-full">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M6.99984 1.75004C9.89933 1.75004 12.2498 4.10055 12.2498 7.00004C12.2498 9.89954 9.89933 12.25 6.99984 12.25C4.10034 12.25 1.74984 9.89954 1.74984 7.00004C1.74984 4.10054 4.10034 1.75004 6.99984 1.75004ZM13.4165 7.00004C13.4165 3.45621 10.5437 0.583374 6.99984 0.583373C3.45601 0.583373 0.58317 3.45621 0.583169 7.00004C0.583169 10.5439 3.45601 13.4167 6.99984 13.4167C10.5437 13.4167 13.4165 10.5439 13.4165 7.00004Z"
                fill="white"/>
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M6.99984 2.91671C6.67767 2.91671 6.4165 3.17787 6.4165 3.50004L6.4165 6.63952L4.40563 7.64496C4.11747 7.78903 4.00068 8.13943 4.14475 8.42758C4.28883 8.71574 4.63922 8.83253 4.92738 8.68846L7.26071 7.52179C7.45833 7.42298 7.58317 7.22099 7.58317 7.00004L7.58317 3.50004C7.58317 3.17787 7.322 2.91671 6.99984 2.91671Z"
                fill="white"/>
        </svg>
      </span>
    </span>
    <span class="ml-4 text-grey-dark text-sm"> {{ 'components.move.dashboard.statusProgress.' + step | translate }} </span>
  </div>
</ng-template>

<ng-template #completed let-step="step" let-lastStep="lastStep">
  <div *ngIf="!lastStep" aria-hidden="true" class="w-[2px] bg-success-lighter h-full mt-1 absolute top-5 left-[11px]"></div>
  <div class="relative flex items-center">
    <span class="flex h-12 border-y-[3px] border-grey-light items-center">
      <span class="border-success-lighter items-center bg-success-lighter border-2 w-10 h-10 flex relative z-10 justify-center rounded-full">
        <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0791 1.08748C11.307 1.31529 11.307 1.68463 11.0791 1.91244L4.66248 8.3291C4.43467 8.55691 4.06533 8.55691 3.83752 8.3291L0.920854 5.41244C0.693049 5.18463 0.693049 4.81529 0.920854 4.58748C1.14866 4.35967 1.51801 4.35967 1.74581 4.58748L4.25 7.09167L10.2542 1.08748C10.482 0.859675 10.8513 0.859675 11.0791 1.08748Z" fill="white"/>
        </svg>
      </span>
    </span>
    <span class="ml-4 text-grey-dark text-sm"> {{ 'components.move.dashboard.statusProgress.' + step | translate }} </span>
  </div>
</ng-template>
