import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe, TitleCasePipe } from '@angular/common';
import { TevcDashboardComponent } from './pages/dashboard/tevc-dashboard.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { StationInfoComponent } from './components/station-info/station-info.component';
import { TranslateModule } from '@ngx-translate/core';
import { StationWeeklyDataComponent } from './components/station-weekly-data/station-weekly-data.component';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { MinutesToHoursPipe } from './pipes/minutes-to-hours.pipe';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BlogModule } from '../../../shared/modules/blog/blog.module';
import { QuickLinksComponent } from './components/quick-links/quick-links.component';
import { AnalyticsModule } from '../../../shared/modules/analytics/analytics.module';
import { RouterModule } from '@angular/router';
import { MonthlyReportComponent } from './components/monthly-report/monthly-report.component';
import { NgChartsModule } from 'ng2-charts';
import { ReportsTableComponent } from './components/reports-table/reports-table.component';
import { SharedModule } from '../../../shared/shared.module';
import { MontlyReportsFilterComponent } from './components/montly-reports-filter/montly-reports-filter.component';
import { SecondsToTimePipe } from './pipes/seconds-to-time.pipe';
import { SwitchComponent } from './components/switch/switch.component';

@NgModule({
  declarations: [
    TevcDashboardComponent,
    SecondsToTimePipe,
    MinutesToHoursPipe,
    ReportsComponent,
    StationInfoComponent,
    StationWeeklyDataComponent,
    QuickLinksComponent,
    MonthlyReportComponent,
    ReportsTableComponent,
    MontlyReportsFilterComponent,
    SwitchComponent,
  ],
  imports: [
    CommonModule,
    NgbModule,
    TranslateModule,
    BlogModule,
    AnalyticsModule,
    RouterModule,
    NgChartsModule,
    SharedModule,
  ],
  providers: [DecimalPipe, TitleCasePipe],
})
export class TecvMonitoringModule {}

// Add translation markers.
_('components.monitoring.stationInfo.status.suspendedByCharger.title');
_('components.monitoring.stationInfo.status.suspendedByCharger.description');
_('components.monitoring.stationInfo.status.suspendedByVehicle.title');
_('components.monitoring.stationInfo.status.suspendedByVehicle.description');
_('components.monitoring.stationInfo.status.charging.title');
_('components.monitoring.stationInfo.status.charging.description');
_('components.monitoring.stationInfo.status.idle.title');
_('components.monitoring.stationInfo.status.idle.description');
_('components.monitoring.stationInfo.status.occupied.title');
_('components.monitoring.stationInfo.status.occupied.description');
_('components.monitoring.stationInfo.status.inoperative.title');
_('components.monitoring.stationInfo.status.inoperative.description');
_('components.monitoring.stationInfo.status.offline.title');
_('components.monitoring.stationInfo.status.offline.description');
_('components.monitoring.monthlyReport.average');
_('pages.tevcMonitoring.dashboard.pageTitle');
