import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { take } from 'rxjs';
import { EncryptionService } from 'src/app/shared/utils/encryption.service';
import { AppConfig } from '@app/shared/utils/app-config';
import { MainFacade } from '@app/core/facade/main.facade';

@Injectable({
  providedIn: 'root',
})
export class ActiveReferenceResolverService {
  constructor(private facade: MainFacade, private encryptionService: EncryptionService, private conf: AppConfig) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    let activeReference = route.queryParamMap.get('ar');
    if (activeReference) {
      activeReference = this.encryptionService.decrypt(activeReference, this.conf.config.encryptionKey);
      this.facade.userProfile$.pipe(take(1)).subscribe((up) => {
        if (up.customers.find(({ reference }) => reference === activeReference)) {
          this.facade.updateActiveReference(activeReference);
        }
      });
    }
  }
}
