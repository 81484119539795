import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  EnergyType,
  Index,
  IndexContext,
  IndexRange,
  MeasurementNature,
  MeterIndex,
  RegisterTypeExtra,
} from '../../models/consumption.interface';
import { map, tap } from 'rxjs';
import { ContractTypes } from '../../../contracts/models/contract.interface';
import { Direction, Register } from '../../models/deliveryPoint.interface';
import { shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MeteringsService {
  private meterings: any = {};
  private meteringsArray: Array<BehaviorSubject<any>> = [];
  private provisionalIndexesArray: Array<BehaviorSubject<any>> = [];
  private caching = {};
  private state: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {}

  public getState() {
    return this.state;
  }

  public updateState(data: any) {
    this.state.next(data);
  }

  public setMeterings$(ean, data) {
    if (!this.meteringsArray[ean]) {
      this.meteringsArray[ean] = new BehaviorSubject<any>(data);
    } else {
      this.meteringsArray[ean].next(data);
    }
  }

  public getMeteringsArray(): Array<Observable<any>> {
    return this.meteringsArray;
  }

  public setProvisionalIndexes$(ean, data) {
    if (!this.provisionalIndexesArray[ean]) {
      this.provisionalIndexesArray[ean] = new BehaviorSubject<any>(data);
    } else {
      this.provisionalIndexesArray[ean].next(data);
    }
  }

  public getProvisionalIndexesArray(): Array<Observable<any>> {
    return this.provisionalIndexesArray;
  }

  public getMeterings(
    reference: string,
    siteId: string,
    energyType: EnergyType,
    deliveryPoint: string,
    loader: boolean = true,
    flush: boolean = false
  ): Observable<Index[]> {
    if (this.meterings[reference] && this.meterings[reference][deliveryPoint] && !flush) {
      return of(this.meterings[reference][deliveryPoint]);
    }

    return this.getMeteringsFromApi(reference, siteId, energyType, deliveryPoint, loader, flush);
  }

  public sendMetering(
    reference: string,
    siteId: string,
    energyType: EnergyType,
    deliveryPoint: string,
    meteringRequest,
    loader
  ) {
    const params = new HttpParams().set('loader', loader.toString());
    return this.http.post<any>(
      `/v1/customers/${reference}/sites/${siteId}/contracts/${energyType}/${deliveryPoint}/indexes`,
      meteringRequest,
      { params }
    );
  }

  public hasProduction(metering) {
    return metering?.indexes.find((m) => m.direction === Direction.production);
  }

  public splitDirections(metering) {
    if (!this.hasProduction(metering)) {
      return [metering, null];
    }

    // Consumption
    const consumptionIndexes = metering.indexes.filter((m) => m.direction === Direction.consumption);
    const consumption = { ...metering, indexes: consumptionIndexes };

    // Production
    const productionIndexes = metering.indexes.filter((m) => m.direction === Direction.production);
    const production = { ...metering, indexes: productionIndexes };

    return [consumption, production];
  }

  /* Create FROM/TO index array & separate PROVISION index array */
  /* Loop over indexes and check if next is a PROVISION and current a FROM/TO */
  /* If so, save it into a temporary variable to reuse it later */
  /* Update: Split Consumption & Production into 2 separated array */
  public prepareIndexes(meterings: Index[], contractType, hasSolarPanels: boolean = false): any {
    let provisional = [];
    let provisionalIndex = 0;
    let tempMeter = null;

    // Order meterings by date ASC
    meterings = meterings.sort((a, b) => new Date(a.indexDate).getTime() - new Date(b.indexDate).getTime());

    meterings =
      contractType === ContractTypes.ELECTRICITY
        ? // Remove NOT_USED from Electricity metering
          this.filterIndexes(meterings, 'register', RegisterTypeExtra.NOT_USED)
        : // Remove CGF from Gas metering
          this.filterIndexes(meterings, 'measurementNature', MeasurementNature.GCF);

    // Remove PRODUCTION for client without PV
    meterings = hasSolarPanels ? meterings : this.filterIndexes(meterings, 'direction', Direction.production);

    let canStartBuilding = false;
    let newMeterings = meterings.reduce((newMeters: any, meter: any, index) => {
      // Make sure to start building metering array with an official one from DGO; else skip it.
      if (!canStartBuilding) {
        if (this.isProvisionalIndex(meter)) {
          return newMeters;
        } else {
          canStartBuilding = true;
        }
      }

      const nextMetering = meterings[index + 1];

      // Ignore if is a meter type switch (MDCHANGE)
      if (this.isMeterTypeSwitch(meter, nextMetering)) {
        return newMeters;
      }

      provisional[provisionalIndex] = !provisional[provisionalIndex] ? [[], []] : provisional[provisionalIndex];
      // Ignore if next is a provisional index
      if (this.isProvisionalIndex(nextMetering)) {
        tempMeter = !this.isProvisionalIndex(meter) ? meter : tempMeter;
        const [consumptionProvision, productionProvision] = this.splitDirections(nextMetering);
        provisional[provisionalIndex][0].push(consumptionProvision);
        provisional[provisionalIndex][1].push(productionProvision);
      } else {
        // Else generate metering list
        meter = tempMeter || meter;
        tempMeter = null;
        const [fromConsumption, fromProduction] = this.splitDirections(meter);
        const [toConsumption, toProduction] = nextMetering ? this.splitDirections(nextMetering) : [null, null];
        const period =
          toConsumption && toConsumption.indexDate
            ? this.calculatePeriod(new Date(fromConsumption.indexDate), new Date(toConsumption.indexDate))
            : null;

        if (period === null || period > 0) {
          const consumption = {
            from: fromConsumption,
            to: toConsumption,
            total: this.calculateConsumption(fromConsumption?.indexes, toConsumption?.indexes) || null,
            period,
          };
          const production = fromProduction
            ? {
                from: fromProduction,
                to: toProduction,
                total: this.calculateConsumption(fromProduction?.indexes, toProduction?.indexes) || null,
                period,
              }
            : null;
          newMeters.push([consumption, production]);
        }
        provisionalIndex++;
      }
      return newMeters;
    }, []);

    provisional = provisional.reverse();
    newMeterings = newMeterings.reverse();

    //@todo: Filter duplicates with new conso/prod array
    //newMeterings = this.removeDuplicates(newMeterings);

    return [newMeterings, provisional];
  }

  public deleteIndexFromApi(
    reference: string,
    siteId: string,
    energyType: EnergyType,
    deliveryPoint: string,
    payload,
    loader
  ): any {
    const params = new HttpParams().set('loader', loader.toString());
    return this.http.request(
      'delete',
      `/v1/customers/${reference}/sites/${siteId}/contracts/${energyType}/${deliveryPoint}/indexes`,
      {
        body: payload,
        params,
      }
    );
  }

  private isMeterTypeSwitch(fromMetering, toMetering) {
    return (
      fromMetering &&
      toMetering &&
      (fromMetering.indexes.length !== toMetering.indexes.length ||
        (fromMetering.context === IndexContext.MDCHANGE && toMetering === IndexContext.MDCHANGE))
    );
  }

  private isProvisionalIndex(metering) {
    return metering && 'context' in metering && metering.context === IndexContext.PROVISION;
  }

  private removeDuplicates(meterings: IndexRange[]): IndexRange[] {
    return meterings.filter(
      (metering: IndexRange, index: number, allMeterings: IndexRange[]) =>
        index ===
        allMeterings.findIndex(
          (oneMetering: IndexRange) =>
            oneMetering.from.indexDate === metering.from.indexDate &&
            (!oneMetering.to || oneMetering.to.indexDate === metering.to.indexDate)
        )
    );
  }

  private calculatePeriod(fromDate: Date, toDate: Date): number {
    const differenceInTime = toDate.getTime() - fromDate.getTime();
    return differenceInTime / (1000 * 3600 * 24);
  }

  private calculateConsumption(fromIndexes: MeterIndex[], toIndexes: MeterIndex[]): MeterIndex[] {
    return toIndexes
      ? toIndexes.map((index, i) => ({
          ...index,
          value: index.value - fromIndexes[i].value,
        }))
      : [];
  }

  private filterIndexes(meterings: Index[], property, value): Index[] {
    return meterings
      .map((metering: Index) => ({
        ...metering,
        indexes: metering.indexes.filter((index: MeterIndex) => index[property] !== value),
      }))
      .filter((metering: Index) => metering.indexes.length > 0);
  }

  private getMeteringsFromApi(
    reference: string,
    siteId: string,
    energyType: EnergyType,
    deliveryPoint: string,
    loader: boolean,
    flush: boolean
  ): Observable<Index[]> {
    if (this.caching && this.caching[deliveryPoint] && !flush) {
      return this.caching[deliveryPoint];
    }
    const params = new HttpParams().set('loader', loader.toString());
    this.caching[deliveryPoint] = this.http
      .get<Index[]>(`/v1/customers/${reference}/sites/${siteId}/contracts/${energyType}/${deliveryPoint}/indexes`, {
        params,
      })
      .pipe(
        map((indexes) => {
          indexes.forEach((index) => {
            index.indexDate = new Date(index.indexDate);
            index.indexes = index.indexes.sort((a, b) => (a.register > b.register ? 1 : -1));
          });
          return indexes;
        }),
        tap((meterings) => {
          if (!this.meterings[reference]) {
            this.meterings[reference] = {};
          }
          this.meterings[reference][deliveryPoint] = meterings;
        }),
        shareReplay(1)
      );
    return this.caching[deliveryPoint];
  }
}
