<ng-container *ngIf="faqItems$ | async as faqItems">
  <div class="faq mt-16 mb-16" *ngIf="faqItems.length > 0">
    <h3 class="font-display font-normal text-4xl te-text-gradient-home text-center mb-16" [innerHTML]="title"></h3>
    <app-faq-item *ngFor="let faqItem of faqItems" [faqItem]="faqItem"> </app-faq-item>
    <div class="flex flex-row items-center justify-center mt-8">
      <img src="./assets/img/icons/standalone/red/more.png" class="h-4 w-2 mr-4" />
      <a [href]="faqURL ? faqURL : utilsService.getHelpCenterUrl()" class="font-bold text-primary" target="_blank">{{
        'components.faq.viewMore' | translate
      }}</a>
    </div>
  </div>
</ng-container>
