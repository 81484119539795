<div class="sponsor-info py-16 xl:px-32">
  <h2
    class="font-display font-normal te-text-gradient-home text-4xl mx-auto text-center mb-8 leading-tight w-3/4"
    [innerHTML]="'components.mgm.sponsorInfo.title' | translate"
  ></h2>

  <div class="inline-block md:flex my-4">
    <div id="total" class="text-center inline-block mx-auto w-full mb-8 md:mb-0 md:flex-1">
      <app-progress-circle class="inline-block w-56 mx-auto md:w-64" [circleClass]="'first my-10'">
        <span class="font-display font-normal text-primary text-5xl circle-content">{{
          sponsorShip.totalRecipients
        }}</span>
      </app-progress-circle>

      <div class="text-center md:px-8">
        <h6 class="font-medium text-2xl text-grey-dark mb-3 md:mb-6">
          {{ 'components.mgm.sponsorInfo.total.title' | translate }}
        </h6>
        <div class="px-12 text-gray-600 text-base md:px-0">
          {{ 'components.mgm.sponsorInfo.total.content' | translate }}
        </div>
      </div>
    </div>

    <div id="yearly" class="text-center inline-block mx-auto w-full mb-8 md:mb-0 md:flex-1">
      <app-progress-circle
        class="inline-block w-56 mx-auto md:w-64"
        progress="{{ yearlyPercentage }}"
        [circleClass]="'my-10'"
      >
        <div class="align-baseline">
          <span class="font-display font-normal text-primary text-5xl">{{ sponsorShip.yearlyRecipients }}</span>
          <span class="font-display font-normal text-primary text-2xl">/{{ yearlyMax }}</span>
        </div>
      </app-progress-circle>

      <div class="text-center md:px-8">
        <h6 class="font-medium text-2xl text-grey-dark mb-3 md:mb-6">
          {{ 'components.mgm.sponsorInfo.yearly.title' | translate }}
        </h6>
        <div
          class="px-12 text-gray-600 text-base md:px-0"
          [innerHTML]="
            'components.mgm.sponsorInfo.yearly.content' | translate : { yearly: sponsorShip.yearlyRecipients }
          "
        ></div>
      </div>
    </div>

    <div id="discount" class="text-center inline-block mx-auto w-full mb-8 md:mb-0 md:flex-1">
      <app-progress-circle class="inline-block w-56 mx-auto md:w-64" [circleClass]="'my-10'">
        <span class="font-display font-normal text-primary text-4xl">
          {{
            'components.mgm.sponsorInfo.discount.totalDiscount'
              | translate : { discount: sponsorShip.totalDiscount.discount }
          }}
        </span>
      </app-progress-circle>

      <div class="text-center md:px-8">
        <h6 class="font-medium text-2xl text-grey-dark mb-3 md:mb-6">
          {{ 'components.mgm.sponsorInfo.discount.title' | translate }}
        </h6>
        <div class="px-12 text-gray-600 text-base md:px-0">
          {{ 'components.mgm.sponsorInfo.discount.content' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="recipients-wrapper mx-auto w-full mt-6 md:mt-24 md:mb-12" *ngIf="recipients$ | async as recipients">
    <div class="recipients">
      <div
        class="recipient flex flex-wrap py-8 border-b"
        *ngFor="let recipient of recipients | slice : (page - 1) * pageSize : (page - 1) * pageSize + pageSize"
      >
        <div class="flex-1 self-center pr-2 font-medium text-grey-dark text-base md:text-lg">
          {{ recipient.firstName }} {{ recipient.lastName | firstLetter }}.
        </div>
        <div class="flex-1 self-center pr-2 text-base md:text-lg">{{ recipient.signatureDate }}</div>
        <div class="self-center md:w-40">
          <img src="./assets/img/icons/standalone/red/party.svg" />
        </div>
        <div
          class="flex-initial w-full mt-4 md:w-2/5 md:mt-0 md:text-right md:self-center md:text-base font-normal text-xs"
        >
          <ng-container *ngIf="recipient.invoiceNumber; else noInvoiceNumber">
            {{ 'components.mgm.sponsorInfo.recipient.invoice' | translate : { amount: recipient.discount.discount } }}
            <span class="text-primary cursor-pointer" (click)="downloadInvoicePDF(recipient.invoiceNumber)">
              {{ recipient.invoiceNumber }}
            </span>
          </ng-container>
          <ng-template #noInvoiceNumber>
            {{ 'components.mgm.sponsorInfo.recipient.noInvoice' | translate : { amount: recipient.discount.discount } }}
          </ng-template>
        </div>
      </div>
    </div>

    <div *ngIf="recipients.length > pageSize" class="mt-16">
      <ngb-pagination
        [(page)]="page"
        [pageSize]="pageSize"
        [collectionSize]="recipients.length"
        [maxSize]="3"
        [rotate]="true"
      >
      </ngb-pagination>
    </div>
  </div>
</div>
