import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ReferenceDetails } from '../../models/reference.interface';
import { LoaderService } from '@app/shared/modules/loader/services/loader.service';
import { LangChangeEvent } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RenameReferenceComponent } from '../../components/rename-reference/rename-reference.component';
import { DeleteReferenceComponent } from '../../components/delete-reference/delete-reference.component';
import { takeUntil } from 'rxjs';
import { Alert } from '@app/shared/components/alert/alert.interface';
import { MainFacade } from '@app/core/facade/main.facade';

@Component({
  selector: 'app-references',
  templateUrl: './references.component.html',
  styleUrls: ['./references.component.scss'],
})
export class ReferencesComponent implements OnInit, OnDestroy {
  public showAlert: { alert: Alert };
  public referencesDetails$: Observable<ReferenceDetails[]>;
  public activeReference: string;
  private notifier: Subject<void> = new Subject();

  constructor(private facade: MainFacade, private loaderService: LoaderService, private modalService: NgbModal) {}

  ngOnInit(): void {
    this.analytics();
    this.facade.utils.setPageTitle('pages.myClientReferences.pageTitle');
    this.facade.translate.onLangChange
      .pipe(takeUntil(this.notifier))
      .subscribe((event: LangChangeEvent) => this.analytics());
    this.facade.reference$
      .pipe(takeUntil(this.notifier))
      .subscribe((reference: string) => (this.activeReference = reference));
    this.referencesDetails$ = this.facade.loadReferencesDetails();
  }

  ngOnDestroy(): void {
    this.notifier.next();
    this.notifier.complete();
  }

  public switchReference(reference: string) {
    this.loaderService.showLoadingEffect();
    this.facade.utils.redirectToSameRoute();
    this.facade.updateActiveReference(reference);
  }

  public renameReference(referenceDetails: ReferenceDetails) {
    const modalRef = this.modalService.open(RenameReferenceComponent, {
      size: 'medium',
    });
    modalRef.componentInstance.data = { referenceDetails };
    modalRef.result.then((result: { alert: Alert }) => (this.showAlert = result));
  }

  public deleteReference(referenceDetails: ReferenceDetails) {
    const modalRef = this.modalService.open(DeleteReferenceComponent, {
      size: 'medium',
    });
    modalRef.componentInstance.data = { referenceDetails };
  }

  private analytics() {
    this.facade.analytics.push(
      {
        event: 'pageview',
        page: {
          phase: 'care',
          category: 'cuzo',
          subCategory: 'references - cuzo',
        },
      },
      {
        reference: this.facade?.state$?.value?.reference,
        site: this.facade?.state$?.value?.activeSite,
        accessRights: this.facade?.state$?.value?.accessRights,
      }
    );
  }
}
