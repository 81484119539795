<div>
  <ng-container *ngIf="origin === indexEncodingOrigin.energyInsight; else defaultIntro">
    <div  ngbAutofocus
          [innerHTML]="
      'components.consumption.introductionEnergyInsight.content'| translate
    "
          class="mt-4"
    ></div>
  </ng-container>
  <ng-template #defaultIntro>
    <div  ngbAutofocus
          [innerHTML]="
      (canChangeInstallmentIntro
        ? 'components.consumption.canChangeInstallmentIntro.content'
        : 'components.consumption.introduction.content'
      ) | translate
    "
          class="mt-4"
    ></div>
  </ng-template>
  <button type="button" (click)="next()" class="btn btn-primary font-bold inline-block w-full sm:w-auto mt-8">
    {{ 'general.letsGo' | translate }}
  </button>
</div>
