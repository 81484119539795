<div class="upload-wrapper">
  <div class="flex">
    <span class="font-medium mb-2 text-grey-dark">Send us your document</span>
    <app-tooltip
      class="ml-auto"
      [tooltip]="'prout' | translate"
      [position]="'left'"
    ></app-tooltip>
  </div>
  <input
    id="file-upload"
    (change)="onFileSelected($event)"
    type="file"
    class="hidden"
    [attr.accept]="acceptString"
  />
  <label for="file-upload" class="cursor-pointer">
    <span class="filename">{{ fileName ? fileName : 'Upload your document here' }}</span>
    <svg class="ml-auto" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.49909 1.05585C8.567 0.987942 8.64528 0.936703 8.72882 0.902136C8.81234 0.867503 8.90392 0.848389 8.99996 0.848389C9.18124 0.848389 9.36251 0.917544 9.50082 1.05585L13.6675 5.22252C13.9441 5.49914 13.9441 5.94763 13.6675 6.22426C13.3909 6.50088 12.9424 6.50088 12.6658 6.22426L9.70829 3.26679V11.5567C9.70829 11.9479 9.39116 12.2651 8.99996 12.2651C8.60876 12.2651 8.29163 11.9479 8.29163 11.5567V3.26679L5.33416 6.22426C5.05754 6.50088 4.60905 6.50088 4.33242 6.22426C4.0558 5.94763 4.0558 5.49914 4.33242 5.22252L8.49909 1.05585Z" fill="#374649"/>
      <path d="M2.20829 11.5567C2.20829 11.1655 1.89116 10.8484 1.49996 10.8484C1.10876 10.8484 0.791626 11.1655 0.791626 11.5567V14.8901C0.791626 15.5199 1.04185 16.124 1.48725 16.5694C1.93265 17.0148 2.53674 17.2651 3.16663 17.2651H14.8333C15.4632 17.2651 16.0673 17.0148 16.5127 16.5694C16.9581 16.124 17.2083 15.5199 17.2083 14.8901V11.5567C17.2083 11.1655 16.8912 10.8484 16.5 10.8484C16.1088 10.8484 15.7916 11.1655 15.7916 11.5567V14.8901C15.7916 15.1442 15.6907 15.388 15.5109 15.5677C15.3312 15.7474 15.0875 15.8484 14.8333 15.8484H3.16663C2.91246 15.8484 2.6687 15.7474 2.48898 15.5677C2.30926 15.388 2.20829 15.1442 2.20829 14.8901V11.5567Z" fill="#374649"/>
    </svg>
  </label>
</div>
<span *ngIf="control.errors?.fileSizeExceeded" class="error inline-block w-full text-primary text-sm">
  Fichier trop volumineux. {{ fileSize }}MB Max.
</span>
