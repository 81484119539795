import { Injectable } from '@angular/core';
import { MoveDTO, MoveFormFrontend } from '@app/core/state/move.state';
import { UploadDocumentParamsDreCompletionStatusEnumCuzoApi } from '@app/shared/models/cuzo-be-contract';
import { MoveInRegistration } from '@app/modules/customer-zone/move/models/movein.interface';
import { Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class BackendToFrontendMapperService {
  static map(moveDTO: MoveDTO, registration: MoveInRegistration = null) {
    if (!moveDTO || !moveDTO?.id) {
      return {};
    }
    const localForm = JSON.parse(localStorage.getItem(moveDTO?.id))?.form;
    return {
      supplier: this.mapSupplierStep(moveDTO, localForm),
      movingInfo: this.mapMovingInfoStep(moveDTO, localForm),
      newAddress: this.mapNewAddressStep(registration, localForm),
      newMeters: this.mapNewMetersStep(registration, localForm),
    };
  }

  private static mapSupplierStep(moveDTO: MoveDTO, localForm: MoveFormFrontend) {
    return {
      amendment: moveDTO?.amendment ?? localForm?.supplier?.amendment,
    };
  }

  private static mapMovingInfoStep(moveDTO: MoveDTO, localForm: MoveFormFrontend) {
    const filename: string = moveDTO?.documents?.[moveDTO?.documents.length - 1]?.name || null;
    const dreStatus: UploadDocumentParamsDreCompletionStatusEnumCuzoApi =
      moveDTO?.sites?.[0]?.deliveryPoints?.[0]?.dreDocumentStatus;
    return {
      file: filename ?? localForm?.movingInfo?.file,
      dreStatus: dreStatus ?? localForm?.movingInfo?.dreStatus,
      movingDate: moveDTO?.ilcDate ?? localForm?.movingInfo?.movingDate,
    };
  }

  private static mapNewAddressStep(registration: MoveInRegistration, localForm: MoveFormFrontend) {
    const filename: string = registration?.documents?.[registration?.documents.length - 1]?.name || null;
    return {
      newAddress: {
        address: registration?.sites?.[0]?.address?.street ?? localForm?.newAddress?.newAddress?.address,
        number: registration?.sites?.[0]?.address?.streetNumber ?? localForm?.newAddress?.newAddress?.number,
        box: registration?.sites?.[0]?.address?.box ?? localForm?.newAddress?.newAddress?.box,
        zipCode: registration?.sites?.[0]?.address?.zip ?? localForm?.newAddress?.newAddress?.zipCode,
        locality: registration?.sites?.[0]?.address?.city ?? localForm?.newAddress?.newAddress?.locality,
      },
      invoicingData: {
        invoicingMethod:
          registration?.billingInfo?.invoiceSendingType ?? localForm?.newAddress?.invoicingData?.invoicingMethod,
        hasDifferentInvoicingAddress:
          registration?.billingInfo?.useDeliveryAddress === false ??
          localForm?.newAddress?.invoicingData?.hasDifferentInvoicingAddress,
        invoicingAddress: {
          address:
            registration?.billingInfo?.address?.street ??
            localForm?.newAddress?.invoicingData?.invoicingAddress?.address,
          number:
            registration?.billingInfo?.address?.streetNumber ??
            localForm?.newAddress?.invoicingData?.invoicingAddress?.number,
          box: registration?.billingInfo?.address?.box ?? localForm?.newAddress?.invoicingData?.invoicingAddress?.box,
          zipCode:
            registration?.billingInfo?.address?.zip ?? localForm?.newAddress?.invoicingData?.invoicingAddress?.zipCode,
          locality:
            registration?.billingInfo?.address?.city ??
            localForm?.newAddress?.invoicingData?.invoicingAddress?.locality,
        },
      },
      dre: {
        dreStatus:
          registration?.sites?.[0]?.deliveryPoints?.[0]?.dreDocumentStatus ?? localForm?.newAddress?.dre?.dreStatus,
        file: filename ?? localForm?.newAddress?.dre?.file,
      },
    };
  }

  private static mapNewMetersStep(registration: MoveInRegistration, localForm: MoveFormFrontend) {
    return {
      energyType: {
        date:
          registration?.sites?.[0]?.deliveryPoints?.[0]?.requestedServiceDate ?? localForm?.newMeters?.energyType?.date,
      },
      meters: {
        electricity: {},
        gas: {},
      },
    };
  }
}
