import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-other-contracts',
  templateUrl: './other-contracts.component.html',
  styleUrls: ['./other-contracts.component.scss'],
})
export class OtherContractsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
