<div class="modal-wrapper">
  <div class="right service-close__wrapper">
    <span class="services__close" (click)="activeModal.dismiss()"> + </span>
  </div>
  <div class="md:grid md:grid-cols-2 md:gap-16 md:p-2 xl:grid-cols-3">
    <ng-container *ngFor="let service of data.services">
      <div class="mb-8 bg-white rounded-4xl box-shadow-small flex flex-col md:mb-0">
        <header class="flex items-center py-6 px-8 rounded-t-4xl font-medium bg-turquoise">
          <span class="rounded-full">
            <app-image-icon
              altDescription=""
              imagePath="./assets/img/icons/standalone/red/{{ service.name | lowercase }}.svg"
            >
            </app-image-icon>
          </span>
          <h5 class="text-2xl pl-8 font-medium text-grey-dark">
            {{ service.i18n[data.lang] }}
          </h5>
        </header>

        <div class="p-12 flex flex-1 flex-col justify-end">
          <span *ngIf="service.description && service.description[data.lang]" class="flex-1 w-full inline-block mb-8">
            {{ service.description[data.lang] }}
          </span>
          <div>
            <div class="inline-block w-full mb-8" *ngIf="service.price">
              <p class="font-medium">{{ 'components.services.price' | translate }}</p>
              <div class="">
                <div class="inline-block te-text-gradient-home mt-4">
                  <span class="inline mr-4 text-5xl font-display leading-none mt-4">{{ service.price }}</span>
                  <small class="inline align-bottom" *ngIf="!service.code.includes('ONE_SHOT'); else oneShot">
                    {{ 'components.services.euroPerMonth' | translate }}
                  </small>
                  <ng-template #oneShot>€</ng-template>
                </div>
              </div>
            </div>
            <div class="inline-block w-full mb-8" *ngIf="!service.price">
              <p class="">Price to define</p>
            </div>
            <a
              *ngIf="
                !service.code.includes('ONE_SHOT') && service.commercialLinks && service.commercialLinks[data.lang]
              "
              href="{{ service.commercialLinks[data.lang] }}"
              target="_blank"
              [class.mt-8]="service?.price"
              class="btn btn-secondary inline-block w-full font-bold text-center cursor-pointer sm:mb-0"
              [analyticsEvent]="'services:click:' + (service.code | lowercase)"
            >
              {{ 'components.services.discover' | translate }}
            </a>

            <a
              *ngIf="service.code.includes('ONE_SHOT')"
              (click)="redirectToUpSellAndClose(service)"
              [analyticsEvent]="'services:click:' + (service.code | lowercase)"
              [class.mt-8]="service?.price"
              class="btn btn-secondary inline-block w-full font-bold text-center cursor-pointer sm:mb-0"
            >
              {{ 'general.upsell.subscribe-button' | translate }}
            </a>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
