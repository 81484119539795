import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EnergyType, UseCaseMyEnergyType } from '../../../../../../models/consumption.interface';
import { UntypedFormControl } from '@angular/forms';
import { AdvancesService } from '../../../../../../../billing/services/advances/advances.service';
import { Advance, AdvanceUpdate } from '../../../../../../../billing/models/advance.interface';
import { Contract } from '../../../../../../../contracts/models/contract.interface';
import { IndexModalService, StepTypes } from '../../../../../../services/index-modal/index-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { catchError, of, Observable, throwError, switchMap } from 'rxjs';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { AdvancesChangeOrigin } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';

@Component({
  selector: 'if-update-monthly-installment',
  templateUrl: './update-monthly-installment.component.html',
  styleUrls: ['./update-monthly-installment.component.scss'],
})
export class UpdateMonthlyInstallmentComponent implements OnInit {
  @Input() energy: EnergyType;
  @Input() control: UntypedFormControl;
  @Input() reference: string;
  @Input() siteId: string;
  @Input() contract: Contract;
  @Input() minAmount: number;
  @Input() suggestedAmount: number;
  @Input() useCaseMyEnergy: UseCaseMyEnergyType;
  @Output() formReady = new EventEmitter();
  public currentAmount: number;
  public advances$: Observable<Advance[]>;
  public formSubmitted = false;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public UseCaseMyEnergyType = UseCaseMyEnergyType;

  constructor(
    private advancesService: AdvancesService,
    private indexModalService: IndexModalService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.updateModalTitle();
    this.currentAmount = this.control.value;
    this.advances$ = this.advancesService.getAdvances(this.reference, this.siteId).pipe(
      switchMap((value: Advance[]) => (!value && !value.length ? throwError(new Error('No data')) : of(value))),
      catchError(() => {
        this.showWarningPage(StepTypes.serviceNotAvailable);
        return of(null);
      })
    );
  }

  submit(newAmount) {
    const advanceRequest: AdvanceUpdate = {
      deliveryPoint: this.contract.deliveryPointReference,
      type: this.contract.type,
      amount: newAmount,
    };
    this.advancesService
      .postAdvancesToApi(this.reference, this.siteId, advanceRequest, AdvancesChangeOrigin.BASED_ON_INDEX, false)
      .subscribe(
        () => {
          this.indexModalService.setModalTitle(
            this.translate.instant('components.consumption.index.monthlyInstallment.thankYouForAdapting')
          );
          this.formSubmitted = true;
        },
        () => this.showWarningPage(StepTypes.adjustmentNotAvailable)
      );
  }

  showWarningPage(step: StepTypes.adjustmentNotAvailable | StepTypes.serviceNotAvailable) {
    this.indexModalService.setStep(step);
  }

  continue() {
    this.formReady.emit();
  }

  private updateModalTitle() {
    this.indexModalService.setModalTitle(
      this.translate.instant('components.consumption.index.monthlyInstallment.modalTitle')
    );
  }
}
_('components.consumption.index.monthlyInstallment.warning.adjustmentNotAvailable.content');
_('components.consumption.index.monthlyInstallment.warning.adjustmentNotAvailable.title');
_('components.consumption.index.monthlyInstallment.warning.decreaseTooImportant.content');
_('components.consumption.index.monthlyInstallment.warning.decreaseTooImportant.title');
_('components.consumption.index.monthlyInstallment.warning.modificationOngoing.content');
_('components.consumption.index.monthlyInstallment.warning.modificationOngoing.title');
_('components.consumption.index.monthlyInstallment.warning.alreadyDecreased.content');
_('components.consumption.index.monthlyInstallment.warning.alreadyDecreased.title');
_('components.consumption.index.monthlyInstallment.success.electricityContent');
_('components.consumption.index.monthlyInstallment.success.gasContent');
_('components.consumption.index.monthlyInstallment.success.duoContent');
_('components.consumption.index.monthlyInstallment.goUp.title');
_('components.consumption.index.monthlyInstallment.goDown.title');
_('components.consumption.index.monthlyInstallment.goUp.intro');
_('components.consumption.index.monthlyInstallment.goDown.intro');
_('components.consumption.index.monthlyInstallment.goUp.estimation');
_('components.consumption.index.monthlyInstallment.goDown.estimation');
_('components.consumption.index.monthlyInstallment.modalTitleEnergy.electricity');
_('components.consumption.index.monthlyInstallment.modalTitleEnergy.gas');
_('components.consumption.index.monthlyInstallment.tooltip.installmentCalculation');
_('components.consumption.index.monthlyInstallment.tooltip.installmentInvoice');
_('components.consumption.index.monthlyInstallment.thankYouForAdapting');
