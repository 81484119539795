/* eslint-disable */
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { EnergyType, MeterIndex } from '../../modules/customer-zone/consumption/models/consumption.interface';

const mod97 = (digits): boolean => {
  let checksum = digits.slice(0, 2);
  let fragment;
  for (let offset = 2; offset < digits.length; offset += 7) {
    fragment = String(checksum) + digits.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }

  return checksum === 1;
};

const isValidIBANNumber = (input: string): boolean => {
  const ibanCountryCodeLength = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26,
  };
  const iban: string = String(input)
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, ''); // keep only alphanumeric characters
  // Match and capture (1) the country code, (2) the check digits, and (3) the rest
  const matches = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
  if (matches === null) {
    return false;
  }

  const [value, countryCode, checkDigits, accountNumber] = matches;

  // Check syntax and length
  if (!countryCode || iban.length !== ibanCountryCodeLength[countryCode]) {
    return false;
  }

  // Rearrange country code and check digits, and convert chars to numbers.
  const digits = (accountNumber + countryCode + checkDigits).replace(/[A-Z]/g, (char) =>
    (char.charCodeAt(0) - 55).toString()
  );
  return mod97(digits);
};

/*
 * Implemented IBAN validator from olreg.
 */
export const IBANvalidator =
  (): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } => {
    if (control.value === '') {
      return null;
    }

    return isValidIBANNumber(control.value) || control.value.includes('XXXXXXXX')
      ? null
      : { invalidIBAN: { value: control.value } };
  };

export const leadingZero =
  (): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } => {
    if (control?.value !== '' && control?.value?.length > 1) {
      const [firstNumber] = control?.value;
      return Number(firstNumber) === 0 ? { leadingZero: control.value } : null;
    }
    return null;
  };

export const checkEmailConfirmation =
  (): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } => {
    if (!control.parent || control.parent.pristine) {
      return null;
    }
    const email = control.parent.get('email').value;
    const emailConfirmation = control.parent.get('emailConfirmation').value;

    if (email !== emailConfirmation) {
      const error = { emailNotIdentical: true };
      control.parent.get('emailConfirmation').setErrors(error);
      return error;
    }
    control.parent.get('emailConfirmation').setErrors(null);
    control.parent.get('email').setErrors(null);

    return null;
  };

export const validMeterInput =
  (
    lastMeterIndexDate: Date,
    lastMeterIndex: MeterIndex,
    energyType: EnergyType,
    canHaveSmallerIndex: boolean
  ): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } => {
    if (control.pristine || !control.value) {
      return null;
    }

    const lastMeterIndexValue = lastMeterIndex.value ? Number(lastMeterIndex.value) : 0;
    const currentMeterIndexValue = Number(control.value);

    if (!canHaveSmallerIndex && currentMeterIndexValue < lastMeterIndexValue) {
      return { minimumNotReached: true };
    }

    // Get the provided index date.
    const ngbDate = control.parent.parent.parent.get('date').value;
    const inputDate = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
    let days = null;
    if (lastMeterIndexDate) {
      const diff = inputDate.getTime() - lastMeterIndexDate.getTime();
      days = Math.floor(diff / (1000 * 60 * 60 * 24));
    }

    switch (energyType) {
      case EnergyType.ELECTRICITY:
        let electricityMaxIncrement = lastMeterIndexValue;
        electricityMaxIncrement += days ? days * 25 : 0;
        if (currentMeterIndexValue > electricityMaxIncrement) {
          return { higherThanAverage: true };
        }
        break;

      case EnergyType.GAS:
        let gasMaxIncrement = lastMeterIndexValue;
        gasMaxIncrement += days ? days * 15.45 : 0;
        if (control.value > +gasMaxIncrement) {
          return { higherThanAverage: true };
        }
        break;
    }

    return null;
  };

export const numberRange = (min: number, max: number): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control.pristine || !control.value) {
      return null;
    }

    const numberValue = Number(`${control.value}`.replace(',', '.'));

    return numberValue < min || numberValue > max ? { numberRange: { value: control.value } } : null;
  };
};

export const depositAmountValidator = (minAmount: number, maxAmount: number): ValidatorFn => {
  return ({ value }: AbstractControl): { [key: string]: any } => {
    if (isNaN(value)) {
      return { isNotNumber: true };
    }
    if (value === '') {
      return { required: true };
    }
    if (+value < minAmount) {
      return { min: true };
    }
    if (+value > maxAmount) {
      return { max: true };
    }
    return null;
  };
};

export function fileExtensionValidator(allowedExtensions: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const file = control.value;
    if (file) {
      const fileExtension = file?.name?.split('.')?.pop()?.toLowerCase();
      if (!allowedExtensions.includes(fileExtension!)) {
        return { invalidExtension: true };
      }
    }
    return null;
  };
}

export function fileSizeValidator(maxSizeInMB: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const file = control.value;
    if (file) {
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        return { fileSizeExceeded: true };
      }
    }
    return null;
  };
}
