<footer id="footer" class="bg-white py-16 text-sm text-grey-dark md:py-8 md:mt-12">
  <div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto">
    <div class="md:flex">
      <div class="text-center border-b border-grey pb-8 mb-8 md:border-b-0 md:pb-0 md:mb-0 md:text-left">
        © TotalEnergies {{ year }}
      </div>
      <div class="ml-auto text-center md:text-right" *ngIf="content$ | async as content">
        <ul>
          <ng-container *ngFor="let footer of content.footer">
            <li
              *ngIf="footer.label !== (footer.label | translate) && footer.link !== (footer.link | translate)"
              class="md:inline-block md:ml-8"
            >
              <a target="__blank" [href]="footer.link | translate" [analyticsEvent]="'footer:click'">
                {{ footer.label | translate }}
              </a>
            </li>
          </ng-container>
          <li class="md:inline-block md:ml-8 cursor-pointer">
            <a (click)="openCookies()" [analyticsEvent]="'footer:click'">
              {{ 'dynamicContent.footer.updateCookies.label' | translate }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
