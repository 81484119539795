<div #app id="app-wrapper" class="relative block w-full h-full min-h-0 mt-24 md:mt-0 md:min-h-screen flex flex-col">
  <ng-container *ngIf="!isMobileApp; else mobileApp">
    <app-header (mobileMenuEmitter)="freezeScrolling($event)"></app-header>
    <app-loader *ngIf="loading"></app-loader>
    <main class="flex-1 overflow-x-clip" [hidden]="loading">
      <router-outlet></router-outlet>
    </main>
    <app-footer [hidden]="loading"></app-footer>
    <ng-container>
      <ng-container *ngIf="accessRights$ | async as accessRights">
        <lib-salesforce-chat
          *ngIf="accessRights?.businessChat || accessRights?.privateChat"
          [lang]="language"
          [customerType]="accessRights?.businessChat ? customerType.b2b : customerType.b2c"
        >
        </lib-salesforce-chat>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #mobileApp>
  <app-loader *ngIf="loading"></app-loader>
  <main class="flex-1 overflow-x-clip -mt-24 md:mt-0" [hidden]="loading">
    <router-outlet></router-outlet>
    <app-eliq-tab></app-eliq-tab>
  </main>
</ng-template>
