import { Component, inject, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainFacade } from '../../../../../core/facade/main.facade';
import { BillingFacade } from '../../../../../core/facade/billing.facade';
import { distinctUntilChanged, Observable, Subscription, tap } from 'rxjs';
import {
  InvoicesDetailsCuzoApi,
  OpenInvoicesCuzoApi,
  PaymentPlanCuzoApi,
  SliceCuzoApi,
  SliceStatusEnumCuzoApi,
} from '../../../../../shared/models/cuzo-be-contract';
import {
  invoiceAlerts,
  InvoiceAlerts,
  PaymentBalanceViewType,
  PaymentType,
} from '@app/modules/customer-zone/invoices/models/invoice.interface';
import { PaymentBalance } from '../../../invoices/models/balance.interface';
import { InvoiceViewTypes } from '../../pages/dashboard/dashboard.constant';
import { PaymentBalanceComponent } from '@app/modules/customer-zone/dashboard/components/payment-balance/payment-balance.component';
import { PaymentInformationsComponent } from '@app/modules/customer-zone/dashboard/components/payment-informations/payment-informations.component';
import { AlertComponent } from '@app/shared/components/alert/alert.component';
import { PaymentsListComponent } from '@app/modules/customer-zone/dashboard/components/payments-list/payments-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { OpenPaymentPlanComponent } from '@app/modules/customer-zone/dashboard/components/open-payment-plan/open-payment-plan.component';
import { AccessRights } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { InvoiceActionsComponent } from '@app/modules/customer-zone/invoices/components/micro/invoice-actions/invoice-actions.component';
import { ApiResponse } from '@app/shared/models/api.inteface';
import { ActiveInvoices } from '../../../../../shared/models/cuzo-be-contract-extend';

@Component({
  selector: 'app-open-invoices',
  standalone: true,
  imports: [
    CommonModule,
    PaymentBalanceComponent,
    PaymentInformationsComponent,
    AlertComponent,
    PaymentsListComponent,
    TranslateModule,
    OpenPaymentPlanComponent,
    InvoiceActionsComponent,
  ],
  templateUrl: './open-invoices.component.html',
  styleUrls: ['./open-invoices.component.scss'],
})
export class OpenInvoicesComponent implements OnInit, OnDestroy {
  @Input() accessRights: AccessRights;
  readonly facade: MainFacade = inject(MainFacade);
  readonly billingFacade: BillingFacade = inject(BillingFacade);
  readonly invoiceAlerts: InvoiceAlerts = invoiceAlerts;
  readonly PaymentBalanceViewType = PaymentBalanceViewType;

  typeOfDueBalances: PaymentBalanceViewType = null;
  typeOfRefundBalances: PaymentBalanceViewType = null;

  balanceViewTypes: Record<PaymentBalanceViewType, PaymentBalance> = structuredClone(InvoiceViewTypes);
  activeInvoicesData$: Observable<ActiveInvoices>;
  numberOfPaymentPlanSlicesToPay: number = 0;

  subscription: Subscription;

  constructor() {
    this.activeInvoicesData$ = this.billingFacade.activeInvoicesData$.pipe(
      distinctUntilChanged(),
      tap((activeInvoicesData: ActiveInvoices) => {
        this.numberOfPaymentPlanSlicesToPay = 0; // reset on data change
        if (activeInvoicesData?.openInvoices?.data) {
          [this.typeOfDueBalances, this.typeOfRefundBalances] = this.setBalanceViewType(
            activeInvoicesData.openInvoices,
            activeInvoicesData.paymentPlan
          );
        }
      })
    );
  }

  ngOnInit(): void {
    this.subscription = this.billingFacade.loadActiveInvoicesData();
    this.billingFacade.isRedirectedFromPayNxt();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  updateBalanceWithPaymentPlanSlices(value: number): void {
    this.balanceViewTypes[this.typeOfDueBalances].amount.value = value;
  }

  setNumberOfPaymentPlanSlicesToPay(value: number): void {
    this.numberOfPaymentPlanSlicesToPay = value;
  }

  private setBalanceViewType(
    invoices: ApiResponse<OpenInvoicesCuzoApi>,
    paymentPlan: ApiResponse<PaymentPlanCuzoApi>
  ): PaymentBalanceViewType[] {
    return [
      this.chooseTypeOfBalanceView(invoices?.data?.due, PaymentType.DUE, this.hasActivePaymentPlan(paymentPlan)),
      this.chooseTypeOfBalanceView(invoices?.data?.refund, PaymentType.REFUND),
    ];
  }

  private hasActivePaymentPlan(paymentPlan: ApiResponse<PaymentPlanCuzoApi>): boolean {
    return paymentPlan?.data?.slices?.some(
      (slice: SliceCuzoApi): boolean => slice.status !== SliceStatusEnumCuzoApi.PAID
    );
  }

  private chooseTypeOfBalanceView(
    paymentInfo: InvoicesDetailsCuzoApi,
    paymentType: PaymentType,
    hasActivePaymentPlan: boolean = false
  ): PaymentBalanceViewType {
    if (paymentInfo?.totalAmount === 0) {
      const balanceType: PaymentBalanceViewType =
        paymentType === PaymentType.DUE
          ? hasActivePaymentPlan
            ? PaymentBalanceViewType.ONLY_PAYMENT_PLAN
            : PaymentBalanceViewType.NOTHING_TO_PAY
          : PaymentBalanceViewType.NOTHING_TO_REFUND;
      return balanceType;
    }

    const isMultipleInvoices: boolean = paymentInfo?.invoices?.length > 1;
    const balanceType: PaymentBalanceViewType =
      paymentType === PaymentType.DUE
        ? isMultipleInvoices || hasActivePaymentPlan
          ? PaymentBalanceViewType.PAY_MORE_INVOICES
          : PaymentBalanceViewType.PAY_ONE_INVOICE
        : isMultipleInvoices
        ? PaymentBalanceViewType.REFUND_MORE_INVOICES
        : PaymentBalanceViewType.REFUND_ONE_INVOICE;

    if (this.balanceViewTypes[balanceType].amount) {
      this.balanceViewTypes[balanceType].amount.value = paymentInfo?.totalAmount;
    }

    return balanceType;
  }
}
