<app-modal title="{{ 'components.invoiceDeliveryChannel.changeInvoiceMethod.title' | translate }}">
  <div class="m-6">
    <div class="my-8 leading-relaxed">
      {{ 'components.invoiceDeliveryChannel.changeInvoiceMethod.intro' | translate }}
    </div>

    <form [formGroup]="control" novalidate class="form" (ngSubmit)="handleSubmit()">
      <div *ngFor="let invoiceMethod of invoiceMethods">
        <div class="form-element input-radio-big mt-4">
          <input
            id="{{ invoiceMethod }}"
            value="{{ invoiceMethod }}"
            type="radio"
            name="invoiceMethod"
            [formControl]="control.get('invoiceMethod')"
          />
          <label for="{{ invoiceMethod }}">
            {{ 'general.billing.' + (invoiceMethod | lowercase) | translate }}
          </label>
        </div>
      </div>

      <div *ngIf="control.get('invoiceMethod').value === email" class="mt-8 md:mt-16">
        <h5 class="text-grey-dark font-medium text-2xl mb-8">
          {{ 'components.invoiceDeliveryChannel.changeInvoiceMethod.email.title' | translate }}
        </h5>
        <div
          class="form-element input-text mb-4"
          [class.errors]="!control.get('email').pristine && control.get('email').invalid"
        >
          <label class="form__label" for="input-email">
            {{ 'general.email' | translate }}
          </label>
          <input [formControl]="control.get('email')" id="input-email" name="email" type="email" autocomplete="off" />
          <div class="message">
            <div *ngIf="control.get('email').errors?.email">{{ 'errorMessages.invalidEmail' | translate }}</div>
            <div *ngIf="control.get('email').errors?.required">{{ 'errorMessages.required' | translate }}</div>
          </div>
        </div>
      </div>

      <div class="mt-8 md:mt-16" *ngIf="control.get('invoiceMethod').value === paper">
        <h5 class="text-grey-dark font-medium text-2xl mb-8">
          {{ 'components.invoiceDeliveryChannel.changeInvoiceMethod.paper.title' | translate }}
        </h5>
        <div class="mb-4">
          <app-address-form [control]="control.get('address')"></app-address-form>
        </div>
      </div>

      <div class="mt-8 md:mt-16" *ngIf="control.get('invoiceMethod').value === zoomit">
        <h5 class="text-grey-dark font-medium text-2xl mb-8">
          {{ 'components.invoiceDeliveryChannel.changeInvoiceMethod.zoomit.title' | translate }}
        </h5>
        <div
          class="form-element input-text mb-4"
          [class.errors]="!control.get('bankAccount').pristine && control.get('bankAccount').invalid"
        >
          <label for="input-email"> {{ 'general.billing.IBAN' | translate }}* </label>
          <input
            [formControl]="control.get('bankAccount')"
            id="input-iban"
            class="form__input"
            name="iban"
            type="text"
            autocomplete="off"
          />
          <div class="message">
            <div *ngIf="control.get('bankAccount').errors?.invalidIBAN">
              {{ 'errorMessages.invalidIBAN' | translate }}
            </div>
            <div *ngIf="control.get('bankAccount').errors?.required">{{ 'errorMessages.required' | translate }}</div>
          </div>
          <app-alert
            *ngIf="billingDetails.paymentMethod !== bankTransfer"
            [alert]="{
              type: alertType.info,
              content: {
                message:
                  'components.invoiceDeliveryChannel.changeInvoiceMethod.zoomit.directDebitDisclaimer' | translate
              }
            }"
          ></app-alert>
        </div>
      </div>

      <div class="md:flex md:justify-center gap-8 mt-4 md:mt-8">
        <button
          class="btn btn-primary w-full"
          [attr.disabled]="!control.valid || control.pristine ? '' : null"
          [class.not-submittable]="!control.valid || control.pristine"
          [analyticsEvent]="'paymentPreferences:click:update-invoice-method'"
        >
          {{ 'general.update' | translate }}
        </button>
        <button
          type="button"
          class="btn btn-secondary w-full mt-8 md:mt-0"
          (click)="activeModal.close()"
          [analyticsEvent]="'paymentPreferences:click:cancel-invoice-method-update'"
        >
          {{ 'general.cancel' | translate }}
        </button>
      </div>
    </form>
  </div>
</app-modal>
