export enum Granularity {
  year = 'year',
  months12 = 'months12',
  months24 = 'months24',
}

export enum SwitchOption {
  price = 'price',
  energy = 'energy',
}
