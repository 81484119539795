<div class="md:grid md:grid-cols-12 gap-16">
  <!-- ROW 1 -->
  <div class="col-span-4 mb-8 md:mb-0">
    <ng-content select="[encodeIndexWidget]"></ng-content>
  </div>
  <div class="col-span-8 mb-8 md:mb-0">
    <ng-content select="[meterReadingChartWidget]"></ng-content>
  </div>
  <!-- ROW 1 END -->

  <!-- ROW 2 -->
  <ng-content select="[comparisonElecConsumptionWidget]"></ng-content>

  <ng-content select="[comparisonGasConsumptionWidget]"></ng-content>

  <ng-content select="[disaggregationChartWidget]"></ng-content>
  <!-- ROW 2 END -->

  <ng-content select="[crossSellView]"></ng-content>
</div>
