import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of, tap } from 'rxjs';
import { Usage, UsageRequestData } from '../../models/consumption.interface';

@Injectable({
  providedIn: 'root',
})
export class EnergyUsageCategoriesService {
  private energyUsage: any = {};

  constructor(private http: HttpClient) {}

  public resetEnergyUsageCache(reference: string) {
    if (this.energyUsage && this.energyUsage[reference]) {
      this.energyUsage[reference] = null;
    }
  }

  public getEnergyUsage(requestData: UsageRequestData, loader: boolean = true): Observable<Usage> {
    if (this.energyUsage[requestData.reference] && this.energyUsage[requestData.reference][requestData.deliveryPoint]) {
      // check if there is cached data by request data date
      if (this.energyUsage[requestData.reference][requestData.deliveryPoint][requestData.fromDateString]) {
        return of(this.energyUsage[requestData.reference][requestData.deliveryPoint][requestData.fromDateString]);
      }
    }
    return this.getEnergyUsageFromApi(requestData, loader);
  }

  private getEnergyUsageFromApi(requestData: UsageRequestData, loader: boolean): Observable<Usage> {
    const params = new HttpParams()
      .set('loader', loader.toString())
      .set('from', requestData.fromDateString)
      .set('to', requestData.toDateString);

    return this.http
      .get<any>(
        `/v1/customers/${requestData.reference}/sites/${requestData.siteId}/contracts/${requestData.energyType}/usage-categories`,
        { params }
      )
      .pipe(
        tap((response) => {
          if (!this.energyUsage[requestData.reference]) {
            this.energyUsage[requestData.reference] = {};
          }
          if (!this.energyUsage[requestData.reference][requestData.deliveryPoint]) {
            this.energyUsage[requestData.reference][requestData.deliveryPoint] = {};
          }
          this.energyUsage[requestData.reference][requestData.deliveryPoint][requestData.fromDateString] = response;
        })
      );
  }
}
