import { Injectable } from '@angular/core';
import { UtilsService } from '../../../../../shared/utils/utils.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { InvoiceModelV2CuzoApi } from '@app/shared/models/cuzo-be-contract';

@Injectable({
  providedIn: 'root',
})
export class PaynxtService {
  constructor(
    private utilsService: UtilsService,
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private toastrService: ToastrService
  ) {}

  getRedirectsAfterPayment(invoice: InvoiceModelV2CuzoApi): string {
    const currentPage = window.location.href;
    const successURL = encodeURIComponent(
      this.utilsService.appendQueryParams(currentPage, { payment: 'ok', amount: invoice.balance })
    );
    const failureURL = encodeURIComponent(
      this.utilsService.appendQueryParams(currentPage, { payment: 'nok', amount: invoice.balance })
    );
    return `?successURL=${successURL}&failureURL=${failureURL}`;
  }

  public isRedirectedFromPayNxt() {
    const queryParams = this.activatedRoute.snapshot.queryParamMap['params'];
    if (queryParams.hasOwnProperty('payment') && queryParams.hasOwnProperty('amount')) {
      const amount = queryParams['amount'];
      if (queryParams['payment'] === 'ok') {
        this.toastrService.success(
          this.translateService.instant('components.invoices.alert.paymentSuccess', { amount })
        );
      } else if (queryParams['payment'] === 'nok') {
        this.toastrService.error(this.translateService.instant('components.invoices.alert.paymentFailure', { amount }));
      }
    }
  }
}
