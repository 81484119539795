import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ServiceEligibility } from '../../../contracts/models/service-eligibility.interface';
import { ContractService } from '../../../contracts/services/contract.service';
import { take } from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Alert } from '../../../../../shared/components/alert/alert.interface';
import { AlertType } from '@app/shared/components/alert/alert.interface';

@Component({
  selector: 'app-boiler-type',
  templateUrl: './boiler-type.component.html',
  styleUrls: ['./boiler-type.component.scss'],
})
export class BoilerTypeComponent implements OnChanges, OnInit {
  @Input() reference: string;
  @Input() siteId: string;
  @Input() control: UntypedFormGroup;
  @Output() formReady: EventEmitter<string> = new EventEmitter<string>();
  @Output() goBackEmitter: EventEmitter<any> = new EventEmitter<any>();
  public choices: ServiceEligibility[] = null;
  public loader = true;
  public notEligibleAlert: Alert = {
    type: AlertType.info,
    content: {
      message: this.translate.instant('general.upsell.boiler_one_shot.select_bundle.no_eligibility_warning'),
    },
  };

  constructor(
    private fb: UntypedFormBuilder,
    private contractService: ContractService,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.control.addControl('bundleCode', this.fb.control(''));
    this.control.get('bundleCode').valueChanges.subscribe((value) => {
      this.formReady.emit();
    });
  }

  ngOnChanges(): void {
    this.contractService
      .getServices(this.reference, this.siteId, false)
      .pipe(take(1))
      .subscribe((services: ServiceEligibility[]) => {
        this.choices = services.filter((service) => service.name === 'BOILER_MAINTENANCE_ONE_SHOT');
        this.loader = false;
      });
  }

  public goBack(): void {
    this.goBackEmitter.emit();
  }

  public continue(): void {
    this.formReady.emit();
  }
}
