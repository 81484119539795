import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SaveFormForLaterService {
  readonly save$: Subject<number> = new Subject<number>();

  save(currentTime: number) {
    this.save$.next(currentTime);
  }
}
