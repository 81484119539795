import { Injectable } from '@angular/core';
import { Observable, of, shareReplay, throwError } from 'rxjs';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs';
import { InvoicesDetailsCuzoApi, OpenInvoicesCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  private cache: Map<string, OpenInvoicesCuzoApi> = new Map<string, OpenInvoicesCuzoApi>();

  constructor(private http: HttpClient) {}

  getInvoicePDF(reference: string, idInvoice: string, loader = true): Observable<HttpResponse<Blob>> {
    const params = new HttpParams().set('loader', loader.toString());

    return this.http.get<Blob>(`/v1/customers/${reference}/invoices/${idInvoice}/document`, {
      observe: 'response',
      responseType: 'blob' as 'json',
      params,
    });
  }

  getInvoiceDetails(reference: string, idInvoice: string): Observable<HttpResponse<string>> {
    return this.http.get(`/v1/customers/${reference}/invoices/${idInvoice}/documents/detail`, {
      observe: 'response',
      responseType: 'text',
    });
  }

  getOpenInvoices(reference: string): Observable<OpenInvoicesCuzoApi> {
    const cacheKey: string = reference;
    if (this.cache.has(cacheKey)) {
      return of(this.cache.get(cacheKey));
    }
    return this.http.get<OpenInvoicesCuzoApi>(`/v1/customers/${reference}/open-invoices`).pipe(
      catchError((error) => throwError(error)),
      map((value: OpenInvoicesCuzoApi) => {
        const newValue: OpenInvoicesCuzoApi = {
          ...value,
          refund: this.transformRefund(value?.refund),
        };
        this.cache.set(cacheKey, newValue);
        return newValue;
      }),
      shareReplay(1)
    );
  }

  private transformRefund(refund: InvoicesDetailsCuzoApi): InvoicesDetailsCuzoApi {
    return {
      ...refund,
      totalAmount: Math.abs(refund?.totalAmount),
      totalBalance: Math.abs(refund?.totalBalance),
      invoices: refund?.invoices?.map((invoice) => ({
        ...invoice,
        balance: Math.abs(invoice?.balance),
        amount: {
          ...invoice?.amount,
          amountWithVat: Math.abs(invoice?.amount?.amountWithVat),
          amountWithoutVat: Math.abs(invoice?.amount?.amountWithoutVat),
          amountVat: Math.abs(invoice?.amount?.amountVat),
        },
      })),
    };
  }
}
