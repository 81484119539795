import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  constructor(private http: HttpClient) {}

  public sendEmailAddress(body: { email: string; language: 'FR' | 'NL'; redirectUrl: string }): Observable<void> {
    return this.http.post<void>('/v1/useraccount?excluded=true', body);
  }
}
