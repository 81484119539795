import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { BoilerOneShotComponent } from './pages/boiler-one-shot/boiler-one-shot.component';
import { SharedModule } from '../../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { SelectReferenceComponent } from './components/select-reference/select-reference.component';
import { SelectSitesComponent } from './components/select-sites/select-sites.component';
import { BoilerTypeComponent } from './components/boiler-type/boiler-type.component';
import { LoaderModule } from '../../../shared/modules/loader/loader.module';
import { HouseAgeComponent } from './components/house-age/house-age.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactInfoComponent } from './components/contact/contact-info.component';
import { PriceSimulationComponent } from './components/price-simulation/price-simulation.component';
import { ContactModule } from '../contact/contact.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
// prettier-ignore
import {
  ConfirmationBoilerMaintenanceOsComponent
} from './components/confirmation-boiler-maintenance-os/confirmation-boiler-maintenance-os.component';
import { DoneBoilerMaintenanceOsComponent } from './components/done-boiler-maintenance-os/done-boiler-maintenance-os.component';

@NgModule({
  declarations: [
    BoilerOneShotComponent,
    ContactInfoComponent,
    SelectReferenceComponent,
    SelectSitesComponent,
    BoilerTypeComponent,
    HouseAgeComponent,
    PriceSimulationComponent,
    ConfirmationBoilerMaintenanceOsComponent,
    DoneBoilerMaintenanceOsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgbModule,
    TranslateModule,
    RouterModule,
    TranslateModule,
    LoaderModule,
    ContactModule,
    ReactiveFormsModule,
  ],
  providers: [DatePipe],
})
export class UpsellModule {}
