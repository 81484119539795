<div
  class="relative rounded-2xl w-full h-full z-10 flex items-center border-grey"
  [class.border]="showBorder"
  style="min-height: 150px"
>
  <div class="absolute top-0 text-grey p-14">
    <ng-content></ng-content>
  </div>
  <div class="bg-white p-8 text-red rounded-2xl mx-auto">
    <img src="./assets/img/icons/standalone/red/bolt.svg" class="inline-block mr-4" />
    <span>{{ 'components.widgets.errors.somethingWentWrong.title' | translate }}</span>
  </div>
</div>
