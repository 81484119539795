import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AppConfig } from '@app/shared/utils/app-config';
import { Observable } from 'rxjs';

@Injectable()
export class BaseHrefApiInterceptor implements HttpInterceptor {
  constructor(private conf: AppConfig, @Inject(PLATFORM_ID) private platformId: object) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.conf.config) {
      return next.handle(req);
    }

    const isIgnoredRoute =
      this.conf.config.api.ignoredRoutes.filter((ignoreRoute) => new RegExp(ignoreRoute, 'g').test(req.url)).length > 0;

    if (isIgnoredRoute) {
      return next.handle(req);
    } else {
      const baseHref = this.conf.config.api.serverHost + this.conf.config.api.baseHref;
      const duplicate = req.clone({
        url: baseHref + req.url,
        setHeaders: { context: 'web' },
      });
      return next.handle(duplicate);
    }
  }
}
