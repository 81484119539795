import { Component, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MoveProgressComponent } from '@app/modules/customer-zone/move/components/move-form/move-progress/move-progress.component';
import { NavigationComponent } from '@app/modules/customer-zone/move/components/move-form/navigation/navigation.component';
import { MoveFormFacade } from '@app/core/facade/move-form.facade';
import { LeavingBannerComponent } from '@app/modules/customer-zone/move/components/move-form/leaving-banner/leaving-banner.component';
import { LoaderStatus } from '@app/modules/customer-zone/move/models/status.interface';
import { MainFacade } from '@app/core/facade/main.facade';
import { distinctUntilChanged, filter, skip, Subject, takeUntil } from 'rxjs';
import { SummaryAsideComponent } from '@app/modules/customer-zone/move/components/move-form/summary-aside/summary-aside.component';

@Component({
  selector: 'app-move-form',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    TranslateModule,
    MoveProgressComponent,
    NgOptimizedImage,
    NavigationComponent,
    LeavingBannerComponent,
    SummaryAsideComponent,
  ],
  templateUrl: './move-form.component.html',
  styleUrls: ['./move-form.component.scss'],
})
export class MoveFormComponent implements OnInit {
  protected readonly LoaderStatus = LoaderStatus;
  private readonly notifier: Subject<void> = new Subject<void>();
  public showSumUpBox = false;
  constructor(
    readonly moveFormFacade: MoveFormFacade,
    private readonly facade: MainFacade,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.facade.reference$
      .pipe(
        filter((ref: string) => !!ref),
        skip(1),
        distinctUntilChanged(),
        takeUntil(this.notifier)
      )
      .subscribe(() => this.router.navigate([MoveFormFacade.MOVE_URL]));
  }
}
