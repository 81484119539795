import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MoveFormFacade } from '@app/core/facade/move-form.facade';
import { MoveHeaderComponent } from '@app/modules/customer-zone/move/components/move-status/views/parts/move-header/move-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { SiteStatusCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Component({
  selector: 'app-no-move',
  standalone: true,
  imports: [CommonModule, MoveHeaderComponent, TranslateModule],
  templateUrl: './no-move.component.html',
  styleUrls: ['./no-move.component.scss'],
})
export class NoMoveComponent implements OnInit {
  @Input() moveSite: SiteStatusCuzoApi;

  constructor(private readonly moveFormFacade: MoveFormFacade) {}

  ngOnInit(): void {}

  startMove(siteId: string) {
    this.moveFormFacade.startMove(siteId);
  }
}
