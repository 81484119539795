<div class="rounded-2xl box-shadow-small bg-grey-light p-8 md:p-12 md:flex">
  <div class="flex-1 font-normal">
    {{ 'components.paymentMethod.intro' | translate }}
    <span class="font-medium"> {{ 'general.billing.' + billingDetails.paymentMethod | translate | lowercase }} </span>.
  </div>
  <ng-container
    *multipleAccessControl="{
      operator: accessRightOperator.disjunction,
      rights: [accessRight.activateBankTransfer, accessRight.activateDom, accessRight.activateDomv]
    }"
  >
    <div *ngIf="!billingDetails.paymentMethodLocked" class="ml-auto mt-4 md:mt-0 md:pl-4 md:self-center">
      <span
        (click)="changePaymentMethod()"
        class="cursor-pointer btn-text btn-text-primary font-bold"
        [analyticsEvent]="'paymentPreferences:click:change-bank-account'"
      >
        {{ 'general.modify' | translate }}
      </span>
    </div>
  </ng-container>
</div>

<div class="mt-8 md:mt-16 mb-4">{{ 'components.paymentMethod.directDebit.paymentDetails' | translate }}:</div>

<div class="justify-between rounded-2xl bg-grey-light p-8 md:p-12 mb-4">
  <p>
    <span class="font-medium">{{ 'components.paymentMethod.directDebit.account' | translate }}</span>
  </p>
  <span class="font-medium">{{ 'general.billing.IBAN' | translate }} </span> {{ billingDetails?.bankAccount }} <br />
  <span class="font-medium">{{ 'general.billing.BIC' | translate }} </span> {{ billingDetails?.bankAccountBIC }}
</div>

<div *ngIf="billingDetails?.paymentMethod === directdebitandbanktransfer">
  <p>{{ 'components.paymentMethod.directDebitAndBankTransfer.info' | translate }}</p>
</div>
