import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'camelCase',
})
export class CamelCasePipe implements PipeTransform {
  transform(val: string): string {
    return val
      .split('_')
      .map((word, i, array) => (array.length > 1 ? word.toLowerCase() : word)) // Preserve camelCase strings
      .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
      .join('');
  }
}
