<div class="md:grid md:grid-cols-4 gap-8">
  <div
    class="form-element input-text street col-span-2"
    [class.errors]="!control.get('street').pristine && control.get('street').invalid"
  >
    <label for="input-street">
      {{ 'general.address.street' | translate }}
    </label>
    <input [formControl]="control.get('street')" id="input-street" name="street" type="text" autocomplete="off" />
    <div class="message">
      <div *ngIf="control.get('street').errors?.required">{{ 'errorMessages.required' | translate }}</div>
    </div>
  </div>
  <div
    class="form-element input-text street-number col-span-1"
    [class.errors]="!control.get('streetNumber').pristine && control.get('streetNumber').invalid"
  >
    <label for="input-street-number">
      {{ 'general.address.streetNumber' | translate }}
    </label>
    <input
      [formControl]="control.controls.streetNumber"
      id="input-street-number"
      name="street-number"
      type="text"
      autocomplete="off"
    />
    <div class="message">
      <div *ngIf="control.get('streetNumber').errors?.required">{{ 'errorMessages.required' | translate }}</div>
    </div>
  </div>
  <div
    class="form-element input-text box-number col-span-1"
    [class.errors]="!control.get('boxNumber').pristine && control.get('boxNumber').invalid"
  >
    <label for="input-box-number">
      {{ 'general.address.boxNumber' | translate }}
    </label>
    <input
      [formControl]="control.controls.boxNumber"
      id="input-box-number"
      name="box-number"
      type="text"
      autocomplete="off"
    />
    <div class="message">
      <div *ngIf="control.get('boxNumber').errors?.required">{{ 'errorMessages.required' | translate }}</div>
    </div>
  </div>
</div>

<div class="md:grid md:grid-cols-2 gap-8">
  <div
    class="form-element input-text zip-code"
    [class.errors]="!control.get('zipCode').pristine && control.get('zipCode').invalid"
  >
    <label for="input-street-number">
      {{ 'general.address.zipCode' | translate }}
    </label>
    <input
      [formControl]="control.controls.zipCode"
      id="input-zip-code"
      name="zip-code"
      type="text"
      autocomplete="off"
    />
    <div class="message">
      <div *ngIf="control.get('zipCode').errors?.required">{{ 'errorMessages.required' | translate }}</div>
    </div>
  </div>
  <div
    class="form-element input-text city"
    [class.errors]="!control.get('city').pristine && control.get('city').invalid"
  >
    <label for="input-street-number">
      {{ 'general.address.city' | translate }}
    </label>
    <input [formControl]="control.controls.city" id="input-city" name="city" type="text" autocomplete="off" />
    <div class="message">
      <div *ngIf="control.get('city').errors?.required">{{ 'errorMessages.required' | translate }}</div>
    </div>
  </div>
</div>
