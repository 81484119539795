<div class="card te-border-gradient-home">
  <span class="mb-2 text-grey-dark60 font-medium text-sm">
    {{ 'components.homeProfile.sidebar.eMove.boxTitle' | translate }}
  </span>
  <h3>{{ 'components.homeProfile.sidebar.eMove.title' | translate }}</h3>
  <div class="my-8 text-sm">
    {{ 'components.homeProfile.sidebar.eMove.description' | translate }}
  </div>
  <a class="btn btn-secondary" [href]="moveUrl">
    {{ 'components.homeProfile.sidebar.eMove.readMore' | translate }}
  </a>
</div>
